/*
 Copyright 2015, 2016 OpenMarket Ltd

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {MatrixClient} from 'matrix-js-sdk';
import sdk from '../../../index';
import Flair from '../elements/Flair.js';
import { _t } from '../../../languageHandler';
import UserStore from '../../../stores/UserStore';
import {isUserMxId} from '../../../UserAddress';
import MatrixClientPeg from "../../../MatrixClientPeg";

export default class extends React.Component {
    static displayName = 'SenderProfile';

    static propTypes = {
        forExport: PropTypes.bool,
        mxEvent: PropTypes.object.isRequired, // event whose sender we're showing
        text: PropTypes.string, // Text to show. Defaults to sender name
        onClick: PropTypes.func,
    };

    static contextTypes = {
        matrixClient: PropTypes.instanceOf(MatrixClient),
    };

    state = {
        userGroups: null,
        relatedGroups: [],
    };

    componentDidMount() {
        this.unmounted = false;
        this._updateRelatedGroups();

        /*
        // Citadel dont use groups yet, we better avoid useless requests
        FlairStore.getPublicisedGroupsCached(
            this.context.matrixClient, this.props.mxEvent.getSender(),
        ).then((userGroups) => {
            if (this.unmounted) return;
            this.setState({userGroups});
        });
        */

        MatrixClientPeg.get().on('RoomState.events', this.onRoomStateEvents);
    }

    componentWillUnmount() {
        this.unmounted = true;
        MatrixClientPeg.get()?.removeListener('RoomState.events', this.onRoomStateEvents);
    }

    onRoomStateEvents = (event) => {
        if (event.getType() === 'm.room.related_groups' &&
            event.getRoomId() === this.props.mxEvent.getRoomId()
        ) {
            this._updateRelatedGroups();
        }
    };

    _updateRelatedGroups = () => {
        if (this.unmounted) return;
        const room = MatrixClientPeg.get().getRoom(this.props.mxEvent.getRoomId());
        if (!room) return;

        const relatedGroupsEvent = room.currentState.getStateEvents('m.room.related_groups', '');
        this.setState({
            relatedGroups: relatedGroupsEvent ? relatedGroupsEvent.getContent().groups || [] : [],
        });
    };

    _getDisplayedGroups = (userGroups, relatedGroups) => {
        let displayedGroups = userGroups || [];
        if (relatedGroups && relatedGroups.length > 0) {
            displayedGroups = relatedGroups.filter((groupId) => {
                return displayedGroups.includes(groupId);
            });
        } else {
            displayedGroups = [];
        }
        return displayedGroups;
    };

    render() {
        const EmojiText = sdk.getComponent('elements.EmojiText');
        const {mxEvent} = this.props;
        const {msgtype} = mxEvent.getContent();

        const senderUserId = mxEvent.getSender();
        const senderFromStore = UserStore.getUserById(senderUserId).displayName;
        const name = (senderFromStore && !isUserMxId(senderFromStore)) ? senderFromStore :
            (mxEvent.sender && mxEvent.sender.name) ||
            senderUserId;

        if (msgtype === 'm.emote') {
            return <span />; // emote message must include the name so don't duplicate it
        }

        let flair = <div />;
        if (this.props.enableFlair) {
            const displayedGroups = this._getDisplayedGroups(
                this.state.userGroups, this.state.relatedGroups,
            );

            flair = <Flair key='flair'
                userId={senderUserId}
                groups={displayedGroups}
            />;
        }

        const nameElem = <EmojiText key='name'>{ name || '' }</EmojiText>;

        // Name + flair
        const nameFlair = <span>
            <span className={`mx_SenderProfile_name`}>
                { nameElem }
            </span>
            { flair }
        </span>;

        const content = this.props.text ?
            <span className="mx_SenderProfile_aux">
                { _t(this.props.text, { senderName: () => nameElem }) }
            </span> : nameFlair;

        return (
            <div className="mx_SenderProfile" dir="auto" onClick={this.props.onClick}>
                { content }
            </div>
        );
    }
}
