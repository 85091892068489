/*
Copyright 2015, 2016 OpenMarket Ltd
Copyright 2017, 2018, 2019 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React from 'react';
import PropTypes from 'prop-types';
import Promise from 'bluebird';
import classNames from 'classnames';

import { _t, _td } from '../../../languageHandler';
import sdk from '../../../index';
import MatrixClientPeg from '../../../MatrixClientPeg';
import dis from '../../../dispatcher';
import { addressTypes, getAddressType } from '../../../UserAddress.js';
import GroupStore from '../../../stores/GroupStore';
import { validateEmail, getEmailsFromSpreadSheet, spreadSheetFileTypes } from '../../../utils/CitadelUtils';
import { HintedComponent } from '../elements/HintedComponent';
import E2eQualifiedToggle from "../../structures/E2eQualifiedToggle";
import { isValid3pidInvite } from '../../../RoomInvite';

const TRUNCATE_QUERY_LIST = 40;
const QUERY_USER_DIRECTORY_DEBOUNCE_MS = 200;

const massInviteErrorMessagesOnLoad = {
    'WRONG_FORMAT_FILE': 'Format is not supported. Please import a .xls, .xlsx or a .csv file',
    'EMPTY_FILE': 'Empty file. Please add at least one email address',
    'UNKNOWN': 'UNKNOWN',
};

const addressTypeName = {
    'mx-user-id': _td('Citadel ID'),
    'mx-room-id': _td('Citadel Room ID'),
    'email': _td('email address'),
};

class AddressInputContainer extends React.Component {
    componentDidMount() {
        document.addEventListener("keydown", this.onKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyDown);
    }

    onKeyDown = () => {
        if (this.props.isCurrentlyHinted) this.props.closeHint();
    }

    render() {
        const { query, isCurrentlyHinted, highlightStyle, hasError } = this.props;
        const shouldHighlight = isCurrentlyHinted && highlightStyle;

        const inputClassNames = classNames('mx_AddressPickerDialog_inputContainer', {
            'mx_AddressPickerDialog_inputContainer_hinted': shouldHighlight,
            'error': hasError,
        });

        return <div className={inputClassNames}>
            {query}
            {isCurrentlyHinted && <span className="highlight" style={highlightStyle} onClick={this.props.closeHint} />}
        </div>;
    }
}

module.exports = class extends React.Component {
    static displayName = 'AddressPickerDialog';

    static propTypes = {
        title: PropTypes.string.isRequired,
        description: PropTypes.node,
        // Extra node inserted after picker input, dropdown and errors
        extraNode: PropTypes.node,
        value: PropTypes.string,
        placeholder: PropTypes.string,
        roomId: PropTypes.string,
        button: PropTypes.string,
        focus: PropTypes.bool,
        validAddressTypes: PropTypes.arrayOf(PropTypes.oneOf(addressTypes)),
        onFinished: PropTypes.func.isRequired,
        groupId: PropTypes.string,
        // The type of entity to search for. Default: 'user'.
        pickerType: PropTypes.oneOf(['user', 'room']),
        // Tells if the dialog is either to create a new chat or to invite users in an existent private / public room.
        // Empty string is meant to avoid possible conflicts with GroupView and GroupAddressPicker usages of AddressPickerDialog.
        dialogType: PropTypes.oneOf(['chat', 'room', '']),
        // Whether the current user should be included in the addresses returned. Only
        // applicable when pickerType is `user`. Default: false.
        includeSelf: PropTypes.bool,
    };

    static defaultProps = {
        value: '',
        focus: true,
        validAddressTypes: addressTypes,
        pickerType: 'user',
        dialogType: '',
        includeSelf: false,
    };

    state = {
        error: false,
        massInviteError: null,
        massInviteErrorOnLoad: null,

        // List of UserAddressType objects representing
        // the list of addresses we're going to invite
        selectedList: [],
        massInviteSelectedList: [],
        massInvitePanelExpanded: false,

        // Whether a search is ongoing
        busy: false,
        // An error message generated during the user directory search
        searchError: null,
        // Whether the server supports the user_directory API
        serverSupportsUserDirectory: true,
        // The query being searched for
        query: '',
        // List of UserAddressType objects representing the set of
        // auto-completion results for the current search query.
        suggestedList: [],

        isHintDisplayed: false,
        isEncrypted: false,
        isLoading: false,
        expectedNumberOfMembers: null,
        e2eWarningMembers: null,
        e2eMaxMembers: null,
        roomIsEncrypted: false,
    };

    async componentDidMount() {
        const { focus, value, roomId } = this.props;
        if (focus) {
            // Set the cursor at the end of the text input
            this.refs.textinput.value = value;
        }

        this.calculateIsHintDisplayed();
        const cli = MatrixClientPeg.get();
        const roomIsEncrypted = cli.isRoomEncrypted(roomId);
        const { e2e: { users_limit: e2eMaxMembers, users_warning: e2eWarningMembers } } = await cli.getServerConfig();
        if (roomIsEncrypted && e2eMaxMembers) {
            this.getExpectedNumberOfMembers();
            cli.on('RoomState.events', this.onMemberUpdate);
        }
        this.setState({
            e2eMaxMembers,
            e2eWarningMembers,
            roomIsEncrypted,
        });
    }

    componentWillUnmount() {
        const cli = MatrixClientPeg.get();
        const { roomIsEncrypted, e2eMaxMembers } = this.state;
        if (cli && roomIsEncrypted && e2eMaxMembers) {
            cli.removeListener('RoomState.events', this.onMemberUpdate);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.roomIsEncrypted &&
            this.state.e2eMaxMembers &&
            prevState.selectedList.length !== this.state.selectedList.length ||
            prevState.massInviteSelectedList.length !== this.state.massInviteSelectedList.length
        ) {
                this.getExpectedNumberOfMembers();
        }
    }

    calculateIsHintDisplayed = () => {
        const isHintDisplayed = !MatrixClientPeg.get()
            .getInAppMessageFlag('has_shown_invite_address_bar') && this.props.dialogType === 'room';
        const addressInputContainer = document.querySelector('.mx_AddressPickerDialog_inputContainer');

        if (isHintDisplayed && addressInputContainer) {
            const { top, left, width, height } = addressInputContainer.getBoundingClientRect();

            const hintStyle = {
                position: 'fixed',
                top: top + 59,
            };

            const highlightStyle = {
                position: 'fixed',
                top: top - 3,
                left: left - 3,
                width,
                height,
            };

            this.setState({
                isHintDisplayed,
                hintStyle,
                highlightStyle,
            });
        }
    }

    _sendMassInvites = async () => {
        const list = this.state.massInviteSelectedList.slice();

        for (const item of this.state.selectedList) {
            list.push(item.address);
        }

        await MatrixClientPeg.get().sendMassInvites(this.props.roomId, list)
            .then(() => this.props.onFinished(false))
            .catch(error => this.setState({ massInviteError: error, isLoading: false }));
    };

    onButtonClick = () => {
        if (this.state.massInviteSelectedList.length) {
            this.setState({ isLoading: true });
            return this._sendMassInvites();
        }

        let selectedList = this.state.selectedList.slice();
        // Check the text input field to see if user has an unconverted address
        // If there is and it's valid add it to the local selectedList
        if (this.props.dialogType !== 'chat' && this.refs.textinput.value !== '') {
            selectedList = this._addInputToList();
            if (selectedList === null) return;
        }
        this.props.onFinished(true, selectedList, this.state.isEncrypted);
    };

    onKeyDown = (e) => {
        if (e.keyCode === 27) { // escape
            e.stopPropagation();
            e.preventDefault();
            this.props.onFinished(false);
        } else if (e.keyCode === 38) { // up arrow
            e.stopPropagation();
            e.preventDefault();
            if (this.addressSelector) this.addressSelector.moveSelectionUp();
        } else if (e.keyCode === 40) { // down arrow
            e.stopPropagation();
            e.preventDefault();
            if (this.addressSelector) this.addressSelector.moveSelectionDown();
        } else if (this.state.suggestedList.length > 0 && (e.keyCode === 188 || e.keyCode === 13 || e.keyCode === 9)) { // comma or enter or tab
            e.stopPropagation();
            e.preventDefault();
            if (this.addressSelector) this.addressSelector.chooseSelection();
        } else if (this.refs.textinput.value.length === 0 && this.state.selectedList.length && e.keyCode === 8) { // backspace
            e.stopPropagation();
            e.preventDefault();
            this.onDismissed(this.state.selectedList.length - 1)();
        } else if (e.keyCode === 13) { // enter
            e.stopPropagation();
            e.preventDefault();
            if (this.refs.textinput.value === '') {
                // if there's nothing in the input box, submit the form
                this.onButtonClick();
            } else if (validateEmail(this.refs.textinput.value)) {
                this._addInputToList();
            }
        } else if (e.keyCode === 188 || e.keyCode === 9) { // comma or tab
            e.stopPropagation();
            e.preventDefault();
            this._addInputToList();
        }
    };

    onQueryChanged = (ev) => {
        const query = ev.target.value;
        if (this.queryChangedDebouncer) {
            clearTimeout(this.queryChangedDebouncer);
        }

        // Only do search if there is something to search
        if (query.length > 0 && query !== '@' && query.length >= 2) {
            this.queryChangedDebouncer = setTimeout(() => {
                const { pickerType, groupId } = this.props;
                if (pickerType === 'user') {
                    if (groupId) {
                        this._doNaiveGroupSearch(query);
                    } else if (this.state.serverSupportsUserDirectory) {
                        this._doUserDirectorySearch(query);
                    } else {
                        this._doLocalSearch(query);
                    }
                } else if (pickerType === 'room') {
                    if (groupId) {
                        this._doNaiveGroupRoomSearch(query);
                    } else {
                        this._doRoomSearch(query);
                    }
                } else {
                    console.error('Unknown pickerType', pickerType);
                }
            }, QUERY_USER_DIRECTORY_DEBOUNCE_MS);
        } else {
            this.setState({
                suggestedList: [],
                query: '',
                searchError: null,
            });
        }
    };

    onDismissed = (index) => {
        return () => {
            const selectedList = this.state.selectedList.slice();
            selectedList.splice(index, 1);
            this.setState({
                selectedList,
                suggestedList: [],
                query: '',
            });
            if (this._cancelThreepidLookup) this._cancelThreepidLookup();
        };
    };

    onClick = (index) => {
        return () => {
            this.onSelected(index);
        };
    };

    onSelected = (index) => {
        const selectedList = this.state.selectedList.slice();
        selectedList.push(this._getFilteredSuggestions()[index]);
        this.setState({
            selectedList,
            suggestedList: [],
            query: '',
        });
        if (this._cancelThreepidLookup) this._cancelThreepidLookup();
    };

    onMemberUpdate = ({event}) => {
        if (!event) return;
        if (event.room_id !== this.props.roomId) {
            return;
        }
        this.getExpectedNumberOfMembers();
    }

    _doNaiveGroupSearch = (query) => {
        const lowerCaseQuery = query.toLowerCase();
        this.setState({
            busy: true,
            query,
            searchError: null,
        });
        MatrixClientPeg.get().getGroupUsers(this.props.groupId).then((resp) => {
            const results = [];
            resp.chunk.forEach((u) => {
                const userIdMatch = u.user_id.toLowerCase().includes(lowerCaseQuery);
                const displayNameMatch = (u.displayname || '').toLowerCase().includes(lowerCaseQuery);
                if (!(userIdMatch || displayNameMatch)) {
                    return;
                }
                results.push({
                    user_id: u.user_id,
                    avatar_url: u.avatar_url,
                    display_name: u.displayname,
                });
            });
            this._processResults(results, query);
        }).catch((err) => {
            console.error('Error whilst searching group rooms: ', err);
            this.setState({
                searchError: err.errcode ? err.message : _t('Something went wrong!'),
            });
        }).done(() => {
            this.setState({
                busy: false,
            });
        });
    };

    _doNaiveGroupRoomSearch = (query) => {
        const lowerCaseQuery = query.toLowerCase();
        const results = [];
        GroupStore.getGroupRooms(this.props.groupId).forEach((r) => {
            const nameMatch = (r.name || '').toLowerCase().includes(lowerCaseQuery);
            const topicMatch = (r.topic || '').toLowerCase().includes(lowerCaseQuery);
            const aliasMatch = (r.canonical_alias || '').toLowerCase().includes(lowerCaseQuery);
            if (!(nameMatch || topicMatch || aliasMatch)) {
                return;
            }
            results.push({
                room_id: r.room_id,
                avatar_url: r.avatar_url,
                name: r.name || r.canonical_alias,
            });
        });
        this._processResults(results, query);
        this.setState({
            busy: false,
        });
    };

    _doRoomSearch = (query) => {
        const lowerCaseQuery = query.toLowerCase();
        const rooms = MatrixClientPeg.get().getRooms();
        const results = [];
        rooms.forEach((room) => {
            let rank = Infinity;
            const nameEvent = room.currentState.getStateEvents('m.room.name', '');
            const name = nameEvent ? nameEvent.getContent().name : '';
            const canonicalAlias = room.getCanonicalAlias();
            const aliasEvents = room.currentState.getStateEvents('m.room.aliases');
            const aliases = aliasEvents.map((ev) => ev.getContent().aliases).reduce((a, b) => {
                return a.concat(b);
            }, []);

            const nameMatch = (name || '').toLowerCase().includes(lowerCaseQuery);
            let aliasMatch = false;
            let shortestMatchingAliasLength = Infinity;
            aliases.forEach((alias) => {
                if ((alias || '').toLowerCase().includes(lowerCaseQuery)) {
                    aliasMatch = true;
                    if (shortestMatchingAliasLength > alias.length) {
                        shortestMatchingAliasLength = alias.length;
                    }
                }
            });

            if (!(nameMatch || aliasMatch)) {
                return;
            }

            if (aliasMatch) {
                // A shorter matching alias will give a better rank
                rank = shortestMatchingAliasLength;
            }

            const avatarEvent = room.currentState.getStateEvents('m.room.avatar', '');
            const avatarUrl = avatarEvent ? avatarEvent.getContent().url : undefined;

            results.push({
                rank,
                room_id: room.roomId,
                avatar_url: avatarUrl,
                name: name || canonicalAlias || aliases[0] || _t('Unnamed Room'),
            });
        });

        // Sort by rank ascending (a high rank being less relevant)
        const sortedResults = results.sort((a, b) => {
            return a.rank - b.rank;
        });

        this._processResults(sortedResults, query);
        this.setState({
            busy: false,
        });
    };

    _doUserDirectorySearch = (query) => {
        this.setState({
            busy: true,
            query,
            searchError: null,
        });
        MatrixClientPeg.get().searchUserDirectory({
            term: query,
        }).then((resp) => {
            // The query might have changed since we sent the request, so ignore
            // responses for anything other than the latest query.
            if (this.state.query !== query) {
                return;
            }
            this._processResults(resp.results, query);
        }).catch((err) => {
            console.error('Error whilst searching user directory: ', err);
            this.setState({
                searchError: err.errcode ? err.message : _t('Something went wrong!'),
            });
            if (err.errcode === 'M_UNRECOGNIZED') {
                this.setState({
                    serverSupportsUserDirectory: false,
                });
                // Do a local search immediately
                this._doLocalSearch(query);
            }
        }).done(() => {
            this.setState({
                busy: false,
            });
        });
    };

    _doLocalSearch = (query) => {
        this.setState({
            query,
            searchError: null,
        });
        const queryLowercase = query.toLowerCase();
        const results = [];
        MatrixClientPeg.get().getUsers().forEach((user) => {
            if (user.userId.toLowerCase().indexOf(queryLowercase) === -1 &&
                user.displayName.toLowerCase().indexOf(queryLowercase) === -1
            ) {
                return;
            }

            // Put results in the format of the new API
            results.push({
                user_id: user.userId,
                display_name: user.displayName,
                avatar_url: user.avatarUrl,
            });
        });
        this._processResults(results, query);
    };

    _processResults = (results, query) => {
        const suggestedList = [];
        const usersToUpdateInStore = [];

        results.forEach((result) => {
            if (result.room_id) {
                const client = MatrixClientPeg.get();
                const room = client.getRoom(result.room_id);
                if (room) {
                    const tombstone = room.currentState.getStateEvents('m.room.tombstone', '');
                    if (tombstone && tombstone.getContent() && tombstone.getContent()['replacement_room']) {
                        const replacementRoom = client.getRoom(tombstone.getContent()['replacement_room']);

                        // Skip rooms with tombstones where we are also aware of the replacement room.
                        if (replacementRoom) return;
                    }
                }
                suggestedList.push({
                    addressType: 'mx-room-id',
                    address: result.room_id,
                    displayName: result.name,
                    avatarMxc: result.avatar_url,
                    isKnown: true,
                });
                return;
            }
            if (!this.props.includeSelf &&
                result.user_id === MatrixClientPeg.get().credentials.userId
            ) {
                return;
            }

            // Return objects, structure of which is defined
            // by UserAddressType
            suggestedList.push({
                addressType: 'mx-user-id',
                address: result.user_id,
                emailAddress: result.address || '',
                displayName: result.display_name,
                avatarMxc: result.avatar_url,
                isKnown: true,
            });

            usersToUpdateInStore.push({
                userId: result.user_id,
                displayName: result.display_name,
                email: result.address || '',
            });
        });

        dis.dispatch({ action: 'USER_STORE_SAVE_USERS', users: usersToUpdateInStore });

        // If the query is a valid address, add an entry for that
        // This is important, otherwise there's no way to invite
        // a perfectly valid address if there are close matches.
        const addrType = getAddressType(query);
        if (this.props.validAddressTypes.includes(addrType)) {
            if (addrType === 'email' && !validateEmail(query)) {
                this.setState({ searchError: _t('That doesn\'t look like a valid email address') });
                return;
            }
            suggestedList.unshift({
                addressType: addrType,
                address: query,
                isKnown: false,
            });
            if (this._cancelThreepidLookup) this._cancelThreepidLookup();
            if (addrType === 'email') {
                this._lookupThreepid(addrType, query).done();
            }
        }
        this.setState({
            suggestedList,
            error: false,
        }, () => {
            if (this.addressSelector) this.addressSelector.moveSelectionTop();
        });
    };

    _addInputToList = () => {
        const addressText = this.refs.textinput.value.trim();
        const addrType = getAddressType(addressText);
        const addrObj = {
            addressType: addrType,
            address: addressText,
            isKnown: false,
        };
        if (!this.props.validAddressTypes.includes(addrType)) {
            this.setState({ error: true });
            return null;
        } else if (addrType === 'mx-user-id') {
            const user = MatrixClientPeg.get().getUser(addrObj.address);
            if (user) {
                addrObj.displayName = user.displayName;
                addrObj.avatarMxc = user.avatarUrl;
                addrObj.isKnown = true;
            }
        } else if (addrType === 'mx-room-id') {
            const room = MatrixClientPeg.get().getRoom(addrObj.address);
            if (room) {
                addrObj.displayName = room.name;
                addrObj.avatarMxc = room.avatarUrl;
                addrObj.isKnown = true;
            }
        }

        const selectedList = this.state.selectedList.slice();
        selectedList.push(addrObj);
        this.setState({
            selectedList,
            suggestedList: [],
            query: '',
        });
        if (this._cancelThreepidLookup) this._cancelThreepidLookup();
        return selectedList;
    };

    _lookupThreepid = (medium, address) => {
        let cancelled = false;
        // Note that we can't safely remove this after we're done
        // because we don't know that it's the same one, so we just
        // leave it: it's replacing the old one each time so it's
        // not like they leak.
        this._cancelThreepidLookup = function() {
            cancelled = true;
        };

        // wait a bit to let the user finish typing
        return Promise.delay(500).then(() => {
            if (cancelled) return null;
            return MatrixClientPeg.get().lookupThreePid(medium, address);
        }).then((res) => {
            if (res === null || !res.mxid) return null;
            if (cancelled) return null;

            return MatrixClientPeg.get().getProfileInfo(res.mxid);
        }).then((res) => {
            if (res === null) return null;
            if (cancelled) return null;
            this.setState({
                suggestedList: [{
                    // a UserAddressType
                    addressType: medium,
                    address: address,
                    displayName: res.displayname,
                    avatarMxc: res.avatar_url,
                    isKnown: true,
                }],
            });
        });
    };

    _onUploadClick = () => {
        this.refs.uploadInput.click();
    };

    _onUploadFile = (files) => {
        if (!files) return;

        const file = files.target.files[0];
        if (!file) return;

        getEmailsFromSpreadSheet(file)
            .then(massInviteSelectedList => this.setState({
                massInviteSelectedList,
                massInviteErrorOnLoad: null,
                massInviteError: null,
            }))
            .catch(massInviteErrorOnLoad => this.setState({ massInviteErrorOnLoad, massInviteSelectedList: [] }))
            .finally(() => this.refs.uploadInput.value = null);
    };

    _renderMassInviteLink = () => {
        const fileTypes = spreadSheetFileTypes.join(',');

        return (
            <div className="mx_AddressPickerDialog_massInvite_container">
                <div className="mx_AddressPickerDialog_massInvite_text">
                    {_t('Or would you like to import a list of members?')}
                </div>
                <div className="mx_AddressPickerDialog_link mx_AddressPickerDialog_massInvite"
                    onClick={this._onUploadClick}>
                {_t('Import a file')}
                    <input
                        ref="uploadInput"
                        type="file"
                        accept={fileTypes}
                        onChange={this._onUploadFile} />
                </div>
              </div>
        );
    };

    _toggleMassInvitePanelExpansion = () => {
        this.setState(currentState => ({ massInvitePanelExpanded: !currentState.massInvitePanelExpanded }));
    };

    _renderMassInviteImportSuccess = () => {
        if (!this.state.massInviteSelectedList.length) return null;

        const { massInvitePanelExpanded } = this.state;

        return (
            <div className="mx_AddressPickerDialog_success">
                <span className="mx_AddressPickerDialog_mainMessage">
                    {_t('File successfully loaded!')}
                </span>
                <span className="mx_AddressPickerDialog_link" onClick={this._toggleMassInvitePanelExpansion}>
                    {_t(massInvitePanelExpanded ? 'see less' : 'see more')}
                </span>
            </div>
        );
    };

    _removeEmailFromMassInviteList = (email) => {
        const massInviteSelectedList = this.state.massInviteSelectedList.filter(e => e !== email);

        this.setState({
            massInviteSelectedList,
        });
    };

    _renderMassInviteError = () => {
        const { massInviteError, massInviteErrorOnLoad } = this.state;

        if (!massInviteErrorOnLoad && !massInviteError) return null;

        let errorValue = massInviteErrorMessagesOnLoad[massInviteErrorOnLoad];
        if (massInviteError) {
            errorValue = massInviteError.message;

            if (massInviteError.errcode === 'M_LIMIT_EXCEEDED' && !!massInviteError?.data?.retry_after_ms) {
                errorValue = _t('Too many requests');
                const milliseconds = massInviteError.data.retry_after_ms;
                const seconds = Math.floor((milliseconds / 1000) % 60);
                const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
                const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
                const days = Math.floor((milliseconds / (1000 * 60 * 60)) / 24);

                errorValue += ', ' + _t('please try again in ') +
                    `${days ? `${days} ${_t('day')}${days > 1 ? 's' : ''}, ` : ''} ` +
                    `${hours ? `${hours} ${_t('hour')}${hours > 1 ? 's' : ''}, ` : ''} ` +
                    `${minutes ? `${minutes} ${_t('minute')}${minutes > 1 ? 's' : ''}, ` : ''} ` +
                    `${seconds ? `${seconds} ${_t('second')}${seconds > 1 ? 's' : ''}` : ''} `;
            }
            if (errorValue.includes('threepids')) errorValue = errorValue.replace('threepids', '');
        }

        const errorMessage = errorValue === massInviteErrorOnLoad ?
            _t('An error occurred (%(errorDetails)s). Please contact us at support@citadel.team',
                { errorDetails: (sub) => <i>{errorValue}</i> },
            ) :
            errorValue;

        return (
            <div className="mx_AddressPickerDialog_error">{errorMessage}</div>
        );
    };

    _renderMassInviteDetails = () => {
        const { massInvitePanelExpanded, massInviteSelectedList } = this.state;

        if (!massInvitePanelExpanded || !massInviteSelectedList.length) return null;

        const AddressTile = sdk.getComponent('elements.AddressTile');
        const accessToken = localStorage.getItem('mx_access_token');


        const { roomIsEncrypted, expectedNumberOfMembers, e2eMaxMembers } = this.state;

        const addressTiles = this.state.massInviteSelectedList.map((email) => {
            const address = { addressType: 'email', isKnown: false, address: email };
            return (
                <AddressTile
                    key={email}
                    address={address}
                    canDismiss={true}
                    onDismissed={() => this._removeEmailFromMassInviteList(email)}
                    showAddress={this.props.pickerType === 'user'}
                    accessToken={accessToken}
                />
            );
        });
        const inputErrorClasses = classNames('mx_AddressPickerDialog_inputContainer', {
            error: roomIsEncrypted && e2eMaxMembers && expectedNumberOfMembers > e2eMaxMembers,
        });
        return (
            <div className="mx_AddressPickerDialog_details">
                <div className={inputErrorClasses}>
                    {addressTiles}
                </div>
            </div>
        );
    };

    _getFilteredSuggestions = () => {
        // map addressType => set of addresses to avoid O(n*m) operation
        const selectedAddresses = {};
        this.state.selectedList.forEach(({ address, addressType }) => {
            if (!selectedAddresses[addressType]) selectedAddresses[addressType] = new Set();
            selectedAddresses[addressType].add(address);
        });

        // Filter out any addresses in the above already selected addresses (matching both type and address)
        return this.state.suggestedList.filter(({ address, addressType }) => {
            return !(selectedAddresses[addressType] && selectedAddresses[addressType].has(address));
        });
    };

    onEncryptionChange = (val) => this.setState({ isEncrypted: val });

    getNumberOfRoomMembers = () => {
        const room = MatrixClientPeg.get().getRoom(this.props.roomId);
        const members = Object.values(room.currentState.members)
            .filter(member => member.membership === 'join' || member.membership === 'invite');
        const invitations = room.currentState.getStateEvents('m.room.third_party_invite').filter((e) => {
                if (!isValid3pidInvite(e)) return false;
                // discard all invites which have a m.room.member event since we've
                // already added them.
                const memberEvent = room.currentState.getInviteForThreePidToken(e.getStateKey());
                if (memberEvent) return false;
                return true;
            });
        return members.length + invitations.length;
    }

    getExpectedNumberOfMembers = () => {
        const currentMembers = this.getNumberOfRoomMembers();
        const { selectedList, massInviteSelectedList } = this.state;
        this.setState({
            expectedNumberOfMembers: currentMembers + selectedList.length + massInviteSelectedList.length,
        });
    }
    renderE2ENumber = () => {
        const { roomIsEncrypted, expectedNumberOfMembers, e2eMaxMembers, e2eWarningMembers } = this.state;
        if (!roomIsEncrypted || !e2eMaxMembers ) return null;
        const e2eCountClasses = classNames('mx_AddressPickerDialog_e2e_count', {
            warning: e2eMaxMembers &&
                (expectedNumberOfMembers < e2eMaxMembers && expectedNumberOfMembers >= e2eWarningMembers),
            error: e2eMaxMembers && expectedNumberOfMembers >= e2eMaxMembers,
        });
        const e2eText = e2eMaxMembers && expectedNumberOfMembers < e2eMaxMembers ?
            _t('Number of members: %(noOfMembers)s/%(maxMembers)s.', {
                noOfMembers: expectedNumberOfMembers,
                maxMembers: e2eMaxMembers,
            }) :
            _t('You have reached the limit of members in an end-to-end encrypted room (%(noOfMembers)s/%(maxMembers)s).', {
                noOfMembers: expectedNumberOfMembers,
                maxMembers: e2eMaxMembers,
            });
        return <div className={e2eCountClasses}>
            {e2eText}
        </div>;
    }

    hasMaxUsersError = (submit) => {
        const { roomIsEncrypted, expectedNumberOfMembers, e2eMaxMembers } = this.state;
        if (submit) {
            return roomIsEncrypted && e2eMaxMembers && expectedNumberOfMembers > e2eMaxMembers;
        }
        return roomIsEncrypted && e2eMaxMembers && expectedNumberOfMembers >= e2eMaxMembers;
    }

    render() {
        const BaseDialog = sdk.getComponent('views.dialogs.BaseDialog');
        const DialogButtons = sdk.getComponent('views.elements.DialogButtons');
        const AddressSelector = sdk.getComponent('elements.AddressSelector');
        this.scrollElement = null;
        const { dialogType } = this.props;
        const { isHintDisplayed, highlightStyle, selectedList, isLoading } = this.state;
        let query = [];
        // create the invite list
        if (selectedList.length > 0) {
            const AddressTile = sdk.getComponent('elements.AddressTile');
            query = selectedList.map((address, i) => {
                return (
                    <AddressTile
                        key={i}
                        address={address}
                        canDismiss={true}
                        onDismissed={this.onDismissed(i)}
                        showAddress={this.props.pickerType === 'user'} />
                );
            });
        }

        if (selectedList.length === 0 || selectedList.length > 0 && dialogType !== 'chat') {
            // Add the query at the end
            query.push(
                <textarea
                    key={selectedList.length}
                    rows="1"
                    id="textinput"
                    ref="textinput"
                    className="mx_AddressPickerDialog_input"
                    onChange={this.onQueryChanged}
                    placeholder={this.props.placeholder}
                    defaultValue={this.props.value}
                    autoFocus={this.props.focus}
                    >
                </textarea>,
            );
        }
        const filteredSuggestedList = this._getFilteredSuggestions();

        let error;
        let addressSelector;
        if (this.state.error) {
            const validTypeDescriptions = this.props.validAddressTypes.map((t) => _t(addressTypeName[t]));
            error = <div className="mx_AddressPickerDialog_error">
                {_t('You have entered an invalid address.')}
                <br />
                {_t('Try using one of the following valid address types: %(validTypesList)s.', {
                    validTypesList: validTypeDescriptions.join(', '),
                })}
            </div>;
        } else if (this.state.searchError) {
            error = <div className="mx_AddressPickerDialog_error">{this.state.searchError}</div>;
        } else if (this.state.query.length > 0 && filteredSuggestedList.length === 0 && !this.state.busy) {
            error = <div className="mx_AddressPickerDialog_error">{_t('No results')}</div>;
        } else {
            addressSelector = (
                <AddressSelector
                    ref={ref => this.addressSelector = ref}
                    addressList={filteredSuggestedList}
                    showAddress={this.props.pickerType === 'user'}
                    onSelected={this.onSelected}
                    truncateAt={TRUNCATE_QUERY_LIST}
                />
            );
        }

        const primaryDisabled =
            this.state.massInviteSelectedList.length + selectedList.length === 0 || this.hasMaxUsersError(true);

        const E2eToggle = dialogType === 'chat'
            ? <E2eQualifiedToggle onChange={this.onEncryptionChange} withAdvancedSection isVisible={false} />
            : null;

        return (
            <BaseDialog className="mx_AddressPickerDialog" isFocusable={false} onKeyDown={this.onKeyDown}
                        isHintDisplayed={isHintDisplayed}
                        onFinished={this.props.onFinished} title={this.props.title}>
                <div className="mx_AddressPickerDialog_label">
                    <label htmlFor="textinput">{this.props.description}</label>
                </div>
                <div className="mx_Dialog_content mx_AddressPickerDialog_content">
                    <HintedComponent
                        flag='has_shown_invite_address_bar'
                        isHintDisplayed={isHintDisplayed}
                        hintInfo={{
                            title: _t('Your colleagues aren\'t on Citadel yet?'),
                            description: _t('Invite them by entering their email addresses in the search bar'),
                        }}
                        hintArrow={{
                            isDisplayed: true,
                            position: 'top_left',
                        }}
                        hintStyle={this.state.hintStyle}
                    >
                        <AddressInputContainer query={query} highlightStyle={highlightStyle} hasError={this.hasMaxUsersError()} />
                    </HintedComponent>
                    {error}
                    {addressSelector}
                    {this.props.extraNode}
                    {this.renderE2ENumber()}
                    {dialogType === 'room' &&
                        <div className="mass-invite">
                            {this._renderMassInviteLink()}
                            {this._renderMassInviteImportSuccess()}
                            {this._renderMassInviteDetails()}
                            {this._renderMassInviteError()}
                        </div>
                    }
                    {E2eToggle}
                </div>
                <DialogButtons
                    className="mx_AddressPickerDialog_buttons"
                    disabled={isLoading}
                    hasCancel={false}
                    onPrimaryButtonClick={this.onButtonClick}
                    primaryButton={this.props.button}
                    primaryDisabled={primaryDisabled}
                />
            </BaseDialog>
        );
    }
};
