/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// this module implements a set of utilities for establish a WebRTC connection
import { noop } from 'lodash';

import CitadexStore from '../stores/CitadexStore';

export const StreamAPI = {
    TYPES: {
        AUDIO: 'audio',
        VIDEO: 'video',
        REMOTEVIDEO: 'subscriber-video',
    },
    MESSAGES: {
        TRICKLE: 'trickle',
        PUBLISH: 'publish',
        PUBLISHED: 'published',
        UNPUBLISH: 'unpublish',
        UNPUBLISHED: 'unpublished',
        START: 'start',
        OFFER: 'offer',
        CONFIGURED: 'configured',
    },
};

StreamAPI.attachMediaStream = (element, stream) => {
    try {
        element.srcObject = stream;
    } catch (e) {
        CitadexStore.error('Error attaching stream to element', e);
    }
};

StreamAPI.stopTracks = (tracks) => {
    tracks.forEach((track) => track.stop());
};

StreamAPI.stopElementStream = (element) => {
    if (!element) return;
    try {
        const stream = element.srcObject;
        if (stream) {
            StreamAPI.stopTracks(stream.getTracks());
        }
        element.srcObject = null;
    } catch (e) {
        CitadexStore.error('Error stopping stream from element', e);
    }
};

// Attach audio output device to the provided media element using the deviceId.
StreamAPI.attachSinkId = async (element, sinkId) => {
    if (typeof element.sinkId !== 'undefined') {
        try {
            CitadexStore.log('Try to attach output device to element');
            const resp = await element.setSinkId(sinkId);
            CitadexStore.log(`Success, audio output device attached: ${sinkId} to element`);
            return resp; // setSinkId promise will always resolve to undefined
        } catch (error) {
            let errorMessage = error;
            if (error.name === 'SecurityError') {
                errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
            }
            throw new Error(errorMessage);
        }
    } else {
        throw new Error('Browser does not support output device selection');
    }
};

StreamAPI.isWebRtcSupported = () => {
    const getUserMedia = window.navigator.getUserMedia
        || window.navigator.webkitGetUserMedia
        || window.navigator.mozGetUserMedia;
    return !(RTCPeerConnection === null || getUserMedia === null);
};

export const WebRTCMedia = (room, type, pub_id, name, bitrate, iceTransportPolicy) => {
    'use strict';
    CitadexStore.log(`iceTransportPolicy=${iceTransportPolicy}`);
    const pc = new RTCPeerConnection({ iceServers: CitadexStore.getTurnServer(), iceTransportPolicy });

    const media = {
        type: type,
        name: name || 'anonymous',
        display: name || 'anonymous',
        pc: pc,
        room: room,
        pub_id: pub_id || room,
    };

    media.set_media = (media_stream) => {
        media.stream = media_stream;
    };

    media.get_media = () => media.stream;

    media.close = () => {
        if (media.pc) { media.pc.close(); }
        if (media.stream) {
            const tracks = media.stream.getTracks();
            // eslint-disable-next-line guard-for-in
            for (const i in tracks) {
                tracks[i].stop();
            }
            delete media['stream'];
        }
    };

    media.configure = (transportCallback) => {
        media.transport = transportCallback;
        media.pc.onicecandidate = (event) => {
            const trickle = { candidate: event.candidate, room: media.room, id: media.pub_id, stream: media.pub_id };
            media.transport(_trickleMessage(media), trickle);
        };
        media.pc.oniceconnectionstatechange = (event) => {
            if (media.pc.iceConnectionState === 'failed') {
                media.close();
            }
        };
    };

    media.handle_media = (media_callback) => {
        media_callback = media_callback || noop;
        media.pc.ontrack = media_callback;
    };

    media.start_offer = () => {
        media.pc.addStream(media.stream);

        return media.pc.createOffer()
            .then(media.set_local_description)
            .then(media.send_offer);
    };

    media.set_local_description = (desc) => {
        media.jsep = desc;
        return media.pc.setLocalDescription(desc);
    };

    media.set_remote_description = (desc) => {
        return media.pc.setRemoteDescription(new RTCSessionDescription(desc));
    };

    media.send_answer = () => media.pc.createAnswer();

    media.send_offer = () => {
        media.transport(
            _publishMessage(media),
            {
                room: media.room,
                handler: media.pub_id,
                jsep: media.jsep,
                display: media.display,
                bitrate: bitrate || 1200000,
            },
        );
    };

    media.start_subscriber_media = () => {
        CitadexStore.log('starting subscriber media', media);
        CitadexStore.log({ room: media.room, id: media.pub_id, jsep: media.jsep });
        media.transport(_startMessage(media), { room: media.room, id: media.pub_id, jsep: media.jsep });
    };

    media.start_media = () => {
        CitadexStore.log('starting media');
        CitadexStore.log({ stream: media.pub_id, jsep: media.jsep });
        media.transport(_startMessage(media), { stream: media.pub_id, jsep: media.jsep });
    };

    media.unpublish = () => {
        media.transport(_unpublishMessage(media), { room: media.room, id: media.pub_id });
    };

    media.mute = (mute, videoSessionId) => {
        media.send_media_configured(media.pub_id, !mute, videoSessionId);
        media.stream.getTracks()[0].enabled = !mute;
    };

    media.sendState = (videoSessionId, isShareScreenEnabled) => {
        media.send_media_configured(
            media.pub_id, media.stream.getTracks()[0].enabled, videoSessionId, isShareScreenEnabled,
        );
    };

    media.send_media_configured = (id, enabled, videoSessionId, isShareScreenEnabled) => {
        const data = { id: parseInt(id), room: parseInt(media.room), enabled, display: media.name };

        if (videoSessionId) data.videoSessionId = videoSessionId;
        if (isShareScreenEnabled) data.isShareScreenEnabled = true;

        media.transport(_configuredMessage(media), data);
    };

    const _configuredMessage = (media) => StreamAPI.MESSAGES.CONFIGURED + '-' + media.type;
    const _publishMessage = (media) => StreamAPI.MESSAGES.PUBLISH + '-' + media.type;
    const _publishedMessage = (media) => StreamAPI.MESSAGES.PUBLISHED + '-' + media.type;
    const _unpublishMessage = (media) => StreamAPI.MESSAGES.UNPUBLISH + '-' + media.type;
    const _unpublishedMessage = (media) => StreamAPI.MESSAGES.UNPUBLISHED + '-' + media.type;
    const _trickleMessage = (media) => StreamAPI.MESSAGES.TRICKLE + '-' + media.type;
    const _offereMessage = (media) => StreamAPI.MESSAGES.OFFER + '-' + media.type;
    const _startMessage = (media) => StreamAPI.MESSAGES.START + '-' + media.type;

    return media;
};
