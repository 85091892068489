import React, { Fragment, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { isEmpty } from "lodash";

import { _t, getCurrentLanguage } from '../../../../languageHandler';

import CitadelButton from "../../elements/CitadelButton";
import Slider from "../../elements/Slider";
import { Spinner } from '../../../structures/animations/Spinner';

import placeHolder from '../../../../../res/img/logo.png';

const PromoteNewFeaturesDialog = ({ onFinished, slides }) => {
    const [position, setPosition] = useState(0);
    const [isBusy, setIsBusy] = useState(true);
    const isLast = position === slides.length - 1;
    const currentLanguage = getCurrentLanguage();
    const data = slides.length ? slides[position][currentLanguage] : {};

    const onNext = (newPosition) => {
        setIsBusy(true);
        if (typeof newPosition === 'number') {
            setPosition(newPosition);
        } else {
            if (position < slides.length - 1) setPosition(position + 1);
        }
    };

    let img = slides[position]?.img;

    if (!isEmpty(img) && !img.startsWith('http')) {
        try {
            img = require(`../../../../../res/newFeaturesData/${img}`);
        } catch (e) {
            img = placeHolder;
        }
    }

    const portalUrl = localStorage.getItem('mx_portal_url');

    return (
        <Fragment>
            <div className={cx('mx_Dialog_content')}>
                {isBusy
                    ? <div className="spinner_container"><div className="spinner"><Spinner /></div></div>
                    : null
                }
                <div className={'mx_Dialog_content_title'}>
                    <p>{data.title}</p>
                </div>
                <div className={'mx_Dialog_content_content'}>
                    {data.description}
                </div>
                <img
                    src={img}
                    alt={data.title}
                    key={position}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = placeHolder;
                        setIsBusy(false);
                    }}
                    onLoad={() => setIsBusy(false)}
                />
                <div className='see_more'>{
                    _t(
                        "<url>See more</url>",
                        {},
                        {url: urlString => <a target='blank' href={`${portalUrl}/releases`}>{urlString}</a>,
                    })}
                </div>
            </div>
            {slides.length > 1 && <Slider position={position} length={slides.length} onPositionChange={onNext} /> }
            <CitadelButton
                onClick={isLast ? onFinished : onNext}
                text={isLast ? _t('Got it!'): _t("Next")}
            />
        </Fragment>
    );
};

PromoteNewFeaturesDialog.propTypes = {
    onFinished: PropTypes.func.isRequired,
    slides: PropTypes.arrayOf(Object).isRequired,
};

export default PromoteNewFeaturesDialog;
