import React, { useEffect, useMemo, useState } from 'react';

import UserStore from '../../../../stores/UserStore';
import MatrixClientPeg from '../../../../MatrixClientPeg';
import { _t } from '../../../../languageHandler';
import { getLocalisedMoment } from '../../../../DateUtils';
import BaseDialog from '../../dialogs/BaseDialog';

import CitadelInput from '../CitadelInput';
import CitadelButton from '../CitadelButton';

import { getPlanConferenceCalendarTimeFormat } from '../../../../utils/TimeUtils';
import ConferenceAudioVideoOptions from './components/ConferenceAudioVideoOptions';
import ConferenceDatePickers from './components/ConferenceDatePickers';

const planConferenceCalendarTimeFormat = getPlanConferenceCalendarTimeFormat();

const MAX_LENGTH = {
    DESCRIPTION: 1000,
    TITLE: 100,
};

const ConferencePlanDialogDetails = (props) => {
    const moment = getLocalisedMoment();
    const {
        calendarDetails,
        maxParticipants,
        membersToInvite,
        onChangeCalendarDetails,
        onChangeStep,
        onFinished,
        roomId,
    } = props;
    const [state, setState] = useState({
        calendarConferenceType: calendarDetails.calendarConferenceType || 'Audio',
        calendarDate: calendarDetails.calendarDate || moment(),
        calendarDescription: calendarDetails.calendarDescription || '',
        calendarEndTime: calendarDetails.calendarEndTime || '',
        calendarRoomName: calendarDetails.calendarRoomName || '',
        calendarStartTime: calendarDetails.calendarStartTime || '',
        calendarTitle: calendarDetails.calendarTitle || '',
        calendarUserEmail: calendarDetails.calendarUserEmail || '',
        calendarUserName: calendarDetails.calendarUserName || '',
        disabledAudioTooltipLabel: calendarDetails.disabledAudioTooltipLabel || '',
        disabledVideoTooltipLabel: calendarDetails.disabledVideoTooltipLabel || '',
        endTimeArray: calendarDetails.endTimeArray || [],
        isEndTimeStringError: calendarDetails.isEndTimeStringError || false,
        isStartTimeStringError: calendarDetails.isStartTimeStringError || false,
        isTimePickerOptionDisabled: calendarDetails.isTimePickerOptionDisabled || false,
        lastCorrectCalendarEndTime: calendarDetails.lastCorrectCalendarEndTime || '',
        lastCorrectCalendarStartTime: calendarDetails.lastCorrectCalendarStartTime || '',
        startTimeArray: calendarDetails.startTimeArray || [],
        url: calendarDetails.url || '',
    });

    const isSubmitEnabled = useMemo(() => {
        const {
            calendarConferenceType,
            calendarDate,
            calendarDescription,
            calendarEndTime,
            calendarStartTime,
            calendarTitle,
        } = state;
        return (
            calendarConferenceType &&
            calendarDate &&
            calendarDescription &&
            calendarEndTime &&
            calendarStartTime &&
            calendarTitle &&
            membersToInvite.length !== 0
        );
    }, [membersToInvite,
        state.calendarConferenceType,
        state.calendarDate,
        state.calendarDescription,
        state.calendarEndTime,
        state.calendarStartTime,
        state.calendarTitle],
    );

    const maxCharsReached = useMemo(() =>
        state.calendarDescription.length >= MAX_LENGTH.DESCRIPTION,
    [state.calendarDescription]);

    const maxCharsReachedTitle = useMemo(() => state.calendarTitle.length >= MAX_LENGTH.TITLE,
    [state.calendarTitle]);

    const onChange = (e) => {
        setState((prevState) => ({...prevState, [e.target.name]: e.target.value }));
    };

    const getRoundedTimeToStep = () => {
        const start = moment();
        const step = 15; // rounding to 15 minutes
        const minutesLeftTillRounded = step - (start.minute() % step);
        return { start, minutesLeftTillRounded };
    };

    const getCalendarStartRoundedTime = () => {
        const { start, minutesLeftTillRounded } = getRoundedTimeToStep();

        const calendarStartTime = moment(start).add(minutesLeftTillRounded, "minutes");
        if (calendarStartTime.hour() < 10) {
            return '0' + calendarStartTime.format("H:mm");
        }
        return calendarStartTime.format("H:mm");
    };

    const getStartAndEndTimeArray = (startTime) => {
        const startTimeArray = [...planConferenceCalendarTimeFormat];
        const endTimeArray = [...planConferenceCalendarTimeFormat];
        const indexToKeep = startTimeArray.findIndex((item) => startTime === item);
        startTimeArray.splice(0, indexToKeep);
        endTimeArray.splice(0, indexToKeep + 1);
        return { startTimeArray, endTimeArray };
    };

    const handleDateChange = (date) => {
        const isSelectedToToday = moment().isSame(date, 'D');
        const startTime = getCalendarStartRoundedTime();
        const { startTimeArray, endTimeArray } = getStartAndEndTimeArray(isSelectedToToday ? startTime : '00:00');
        setState(prevState => ({
            ...prevState,
            calendarDate: date,
            calendarEndTime: endTimeArray[3],
            calendarStartTime: startTimeArray[0],
            lastCorrectCalendarEndTime: endTimeArray[0],
            lastCorrectCalendarStartTime: startTimeArray[0],
            startTimeArray, endTimeArray,
        }));
    };

    const getCalendarEndRoundedTime = () => {
        const { start, minutesLeftTillRounded } = getRoundedTimeToStep();
        let calendarEndTime;
        const roundedTime = moment(start).add(minutesLeftTillRounded, "minutes");
        if (roundedTime.hour() < 23) {
            calendarEndTime = roundedTime.add(1, 'hour').format("H:mm");
        } else {
            calendarEndTime = roundedTime.format("H:mm");
        }
        if (roundedTime.hour() < 10) {
            return '0' + calendarEndTime;
        }
        return calendarEndTime;
    };

    const getTooManyInCallText = (max) => (
        _t('Sorry, the room has exceeded the maximum number of participants (%(max)s)', { max })
    );

    const getIncremenetedEndTime = (time) => {
        const splittedStartTime = time.map(Number);
        const startTimeHour = splittedStartTime[0];
        const startTimeMinute = splittedStartTime[1];
        if (startTimeHour === 23) {
            if (startTimeMinute <= 30) {
                return `${startTimeHour}:${startTimeMinute + 15}`;
            } else {
                return `${startTimeHour}:${startTimeMinute + 5}`;
            }
        } else {
            const finalStartTime = startTimeHour + 1;

            return `${finalStartTime < 10 ? '0' + finalStartTime.toString() : finalStartTime}:${time[1]}`;
        }
    };

    const changeTimeOption = (type) => (time) => {
        if (type === 'startTime') {
            const endTime = getIncremenetedEndTime(time.split(':'));
            setState((prevState) => ({
                ...prevState,
                calendarEndTime: endTime,
                calendarStartTime: time,
                lastCorrectCalendarEndTime: endTime,
                lastCorrectCalendarStartTime: time,
            }));
            const startTimeIndex = planConferenceCalendarTimeFormat.findIndex((element) => time === element);
            const newEndTimeArray = [...planConferenceCalendarTimeFormat];
            newEndTimeArray.splice(0, startTimeIndex + 1);
            setState((prevState) => ({...prevState, endTimeArray: newEndTimeArray }));
        } else {
            setState((prevState) => ({...prevState, calendarEndTime: time }));
        }
    };

    const handleInputBlur = (type) => () => {
        const {
            calendarEndTime,
            calendarStartTime,
            isEndTimeStringError,
            isStartTimeStringError,
            lastCorrectCalendarEndTime,
            lastCorrectCalendarStartTime,
        } = state;
        let newCalendarEndTime = calendarEndTime;
        let newCalendarStartTime = calendarStartTime;
        let newIsEndTimeStringError;
        let newIsStartTimeStringError;
        let newLastCorrectCalendarEndTime = lastCorrectCalendarEndTime;
        let newLastCorrectCalendarStartTime = lastCorrectCalendarStartTime;
        if (isStartTimeStringError || isEndTimeStringError) {
            if (type === 'startTime') {
                newCalendarStartTime = lastCorrectCalendarStartTime;
                newIsStartTimeStringError = false;
            } else {
                newCalendarEndTime = lastCorrectCalendarEndTime;
                newIsEndTimeStringError = false;
            }
        } else {
            if (type === 'startTime') {
                newLastCorrectCalendarStartTime = calendarStartTime;
                newIsStartTimeStringError = false;
            } else {
                newLastCorrectCalendarEndTime = calendarEndTime;
                newIsEndTimeStringError = false;
            }
        }
        setState((prevState) => ({
            ...prevState,
            calendarEndTime: newCalendarEndTime,
            calendarStartTime: newCalendarStartTime,
            isEndTimeStringError: newIsEndTimeStringError,
            isStartTimeStringError: newIsStartTimeStringError,
            lastCorrectCalendarEndTime: newLastCorrectCalendarEndTime,
            lastCorrectCalendarStartTime: newLastCorrectCalendarStartTime,
        }));
        changeTimeOption(type)(type === 'startTime' ? newCalendarStartTime : newCalendarEndTime);
    };

    const onChangeTimeInput = (type) => (e) => {
        let isStartTimeStringError; let isEndTimeStringError;
        type === 'startTime' ?
            setState(prevState => ({...prevState, calendarStartTime: e.target.value })) :
            setState((prevState) => ({...prevState, calendarEndTime: e.target.value }));
        const elements = Array.from(document.getElementsByClassName("mx_ConferencePlanDialog_timePicker"));
        const testExpressions = /(?=.{5}$)([0-9][0-9]:[0-9][0-9])/;

        if (testExpressions.test(e.target.value)) {
            const splitArray = e.target.value.split(':');
            const hour = parseInt(splitArray[0]);
            const minute = parseInt(splitArray[1]);
            const formattedDate = moment(calendarDate).set({
                hour,
                millisecond: 0,
                minute,
                second: 0,
            });
            const isDisabled = moment().isAfter(formattedDate) || hour >= 24 || minute >= 60;
            if (isDisabled) {
                type === 'startTime' ?
                    isStartTimeStringError = true :
                    isEndTimeStringError = true;
            } else {
                type === 'startTime' ?
                    isStartTimeStringError = false :
                    isEndTimeStringError = false;
            }
            if (type === 'endTime') {
                const startTime = calendarStartTime.split(":");
                const formattedStartTimeDate = moment(calendarDate).set({
                    hour: startTime[0],
                    millisecond: 0,
                    minute: startTime[1],
                    second: 0,
                });
                if (formattedStartTimeDate.isAfter(formattedDate)) {
                    isEndTimeStringError = true;
                }
            }
        } else {
            type === 'startTime' ?
                isStartTimeStringError = true :
                isEndTimeStringError = true;
        }
        setState((prevState) => ({...prevState, isStartTimeStringError, isEndTimeStringError }));
        elements.forEach(element => {
            if (element.innerHTML === e.target.value) {
                element.scrollIntoView();
            }
        });
    };

    const onCangeConferenceType = (type, disabledType) => () => {
        if (disabledType) return;

        setState((prevState) => ({...prevState, calendarConferenceType: type }));
    };

    const onCloseDialog = () => {
        onFinished();
        MatrixClientPeg.get().trackUserAction({
            formId: 'planConference',
            version: 1,
            action: 'cancel',
        });
    };

    useEffect(() => {
        if (calendarDetails.calendarDescription.length === 0) {
            const { email, displayName } = UserStore.getMe();
            const idBaseUrl = localStorage.getItem('mx_portal_url');
            const cli = MatrixClientPeg.get();
            const { summary: { info: { title } } } = cli.getRoom(roomId);
            const url = `${idBaseUrl}/#/room/${roomId}?deeplink=true`;
            const startTime = getCalendarStartRoundedTime();
            const endTime = getCalendarEndRoundedTime();
            const { startTimeArray, endTimeArray } = getStartAndEndTimeArray(startTime);
            const {
                video: videoMaxParticipants,
                audio: audioMaxParticipants,
            } = props.maxParticipants;
            const disabledAudioTooltipLabel =
                props.joinedMembersCount > audioMaxParticipants ?
                    getTooManyInCallText(audioMaxParticipants) : '';
            const disabledVideoTooltipLabel =
                props.joinedMembersCount > videoMaxParticipants ?
                    getTooManyInCallText(videoMaxParticipants) : '';
            let calendarConferenceType = 'Audio';
            if (disabledAudioTooltipLabel && !disabledVideoTooltipLabel) {
                calendarConferenceType = 'Video';
            }
            if (!disabledAudioTooltipLabel && disabledVideoTooltipLabel) {
                calendarConferenceType = 'Audio';
            }
            if (disabledAudioTooltipLabel && disabledVideoTooltipLabel) {
                calendarConferenceType = '';
            }
            setState((prevState) => ({
                ...prevState,
                calendarConferenceType: calendarConferenceType,
                calendarEndTime: endTime,
                calendarRoomName: title,
                calendarStartTime: startTime,
                calendarUserEmail: email,
                calendarUserName: displayName,
                disabledAudioTooltipLabel: disabledAudioTooltipLabel,
                disabledVideoTooltipLabel: disabledVideoTooltipLabel,
                endTimeArray: endTimeArray,
                lastCorrectCalendarEndTime: endTime,
                lastCorrectCalendarStartTime: startTime,
                startTimeArray: startTimeArray,
                url,
            }));
        }
    }, []);

    useEffect(() => {
        onChangeCalendarDetails(state);
    }, [state]);

    const {
        calendarConferenceType,
        calendarDate,
        calendarDescription,
        calendarEndTime,
        calendarStartTime,
        calendarTitle,
        disabledAudioTooltipLabel,
        disabledVideoTooltipLabel,
        endTimeArray,
        isEndTimeStringError,
        isStartTimeStringError,
        isTimePickerOptionDisabled,
        startTimeArray,
    } = state;

    return (
        <BaseDialog
            className="mx_ConferencePlanDialog conferenceDetails"
            title={_t('Plan a conference')}
            onFinished={onCloseDialog}
        >
            <div className="mx_ConferencePlanDialog_container">
                <ConferenceDatePickers
                    calendarDate={calendarDate}
                    calendarEndTime={calendarEndTime}
                    calendarStartTime={calendarStartTime}
                    changeTimeOption={changeTimeOption}
                    endTimeArray={endTimeArray}
                    handleDateChange={handleDateChange}
                    handleInputBlur={handleInputBlur}
                    isEndTimeStringError={isEndTimeStringError}
                    isStartTimeStringError={isStartTimeStringError}
                    isTimePickerOptionDisabled={isTimePickerOptionDisabled}
                    onChangeTimeInput={onChangeTimeInput}
                    startTimeArray={startTimeArray}
                />
                <ConferenceAudioVideoOptions
                    calendarConferenceType={calendarConferenceType}
                    disabledAudioTooltipLabel={disabledAudioTooltipLabel}
                    disabledVideoTooltipLabel={disabledVideoTooltipLabel}
                    onCangeConferenceType={onCangeConferenceType}
                    maxParticipants={maxParticipants}
                />
                <div className="mx_ConferencePlanDialog_row">
                    <h2 className="mx_ConferencePlanDialog_heading">{_t('Meeting title')}</h2>
                    <div className="mx_ConferencePlanDialog_line">
                        <CitadelInput
                            className="mx_ConferencePlanDialog_title"
                            label={""}
                            maxInputLength={MAX_LENGTH.TITLE}
                            name="calendarTitle"
                            onChange={onChange}
                            placeholder={_t('Put a title')}
                            value={calendarTitle}
                        />
                    </div>
                    {maxCharsReachedTitle && <div className="mx_ConferencePlanDialog_error">
                        {_t('You reached the limit number of characters') + ` (${MAX_LENGTH.TITLE}).`}
                    </div>}
                </div>
                <div className="mx_ConferencePlanDialog_row">
                    <h2 className="mx_ConferencePlanDialog_heading">{_t('Meeting description')}</h2>
                    <textarea
                        className="mx_ConferencePlanDialog_textarea"
                        maxLength={MAX_LENGTH.DESCRIPTION}
                        name="calendarDescription"
                        onChange={onChange}
                        placeholder={_t('Add a description')}
                        value={calendarDescription}
                    />
                    {maxCharsReached && <div className="mx_ConferencePlanDialog_error">
                        {_t('You reached the limit number of characters') + ` (${MAX_LENGTH.DESCRIPTION}).`}
                    </div>}
                </div>
                <div className="mx_ConferencePlanDialog_row align-right">
                    <CitadelButton
                        className="mx_ConferencePlanDialog_nextButton"
                        isDisabled={!isSubmitEnabled}
                        onClick={onChangeStep(1)}
                        isLoading={membersToInvite.length === 0}
                        text={_t('Next')}
                    />
                </div>
            </div>
        </BaseDialog>
    );
};

export default ConferencePlanDialogDetails;
