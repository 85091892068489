import React, { Component, Fragment } from 'react';
import cx from 'classnames';

import { _t } from '../../../languageHandler';

class CopyTextButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isClicked: false,
        };
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    setClickState = () => this.setState({ isClicked: true });

    resetClickState = () => this.setState({ isClicked: false });

    handleClick = () => {
        const { autoReset = true, callback } = this.props;
        const copyText = document.getElementById('copy-text-input').textContent;
        navigator.clipboard.writeText(copyText);
        if (autoReset) {
            clearInterval(this.interval);
            this.interval = setTimeout(this.resetClickState, 3000);
        }
        this.setClickState();
        callback && callback(copyText);
    }

    render() {
        const { isClicked } = this.state;
        const {
            copyLabel = _t('COPY'),
            copiedLabel = _t('Copied!').slice(0, -1),
            shouldDisplayCopyText = false,
            text = '',
            type = 'text',
        } = this.props;

        if (!text) return null;

        const containerClassName = cx('copy-text__container', { 'clicked': isClicked }, `type-${type}`);
        const inputClassName = `copy-text${!shouldDisplayCopyText ? '__hidden' : '__visible'}-input`;
        return (
            <div className={`copy-text__wrapper`}>
                <div id="copy-text-input" className={inputClassName}>{text}</div>
                <div className={containerClassName} onClick={this.handleClick}>
                    {isClicked ?
                        <Fragment>
                            <div className="copy-text__icon copy-text__icon--check" />
                            {copiedLabel}
                        </Fragment>
                        :
                        <Fragment>
                            <div className="copy-text__icon copy-text__icon--copy" />
                            {copyLabel}
                        </Fragment>
                    }
                </div>
            </div>
        );
    }
}

export default CopyTextButton;
