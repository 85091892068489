import { useState, useEffect } from 'react';
import cx from 'classnames';

import dis from '../../dispatcher';
import { _t } from "../../languageHandler";
import LinearLoader from "../views/elements/LinearLoader";
import cancelIcon from "../../../res/img/cancel.svg";
const RoomExportBar = ({ exportProgressText: propExportProgressText, isCompleted, roomId }) => {
    const [exportProgressText, setExportProgressText] = useState(propExportProgressText);
    let dispatcherRef;

    const onAction = (payload) => {
        if (payload.action === 'update_room_export_progress_text' && payload.roomId === roomId) {
            setExportProgressText(payload.exportProgressText);
        }
    };

    useEffect(() => {
        dispatcherRef = dis.register(onAction);

        // returned function will be called on component unmount
        return () => {
            dis.unregister(dispatcherRef);
        };
    }, []);

    return (
        <div className={cx('export_room_bar', { isCompleted})}>
            {!isCompleted && <LinearLoader />}
            <div className='content'>
                <div className="left">
                    <div className={cx('export-icon', { isCompleted })} />
                    <div className={cx('label', { isCompleted})} >
                        {isCompleted ? _t('export_chat|export_successful') : exportProgressText.title}
                    </div>
                </div>
                <div className="right">
                    <div className="progress">{exportProgressText.description}</div>
                    <img
                        className="download_cancel mx_filterFlipColor"
                        src={cancelIcon}
                        width="18"
                        height="18"
                        alt=""
                        onClick={() => dis.dispatch({ action: 'room_export_cancel', roomId })}
                    />
                </div>
            </div>
        </div>
    );
};

export default RoomExportBar;
