import audioOff from '../../../../../res/img/conference/mic-off.svg';
import audioOffHover from '../../../../../res/img/conference/mic-off-hover.svg';
import audioOn from '../../../../../res/img/conference/mic-on.svg';
import audioOnHover from '../../../../../res/img/conference/mic-on-hover.svg';
import endCall from '../../../../../res/img/conference/end-call.svg';
import endCallHover from '../../../../../res/img/conference/end-call-hover.svg';
import fullscreenOff from "../../../../../res/img/conference/fullscreen-off.svg";
import fullscreenOffHover from "../../../../../res/img/conference/fullscreen-off-hover.svg";
import fullscreenOn from "../../../../../res/img/conference/fullscreen-on.svg";
import fullscreenOnHover from "../../../../../res/img/conference/fullscreen-on-hover.svg";
import parameters from "../../../../../res/img/conference/parameters.svg";
import parametersHover from "../../../../../res/img/conference/parameters-hover.svg";
import shareOff from '../../../../../res/img/conference/share-off.svg';
import shareOffHover from '../../../../../res/img/conference/share-off-hover.svg';
import shareOn from '../../../../../res/img/conference/share-on.svg';
import shareOnHover from '../../../../../res/img/conference/share-on-hover.svg';
import videoOff from '../../../../../res/img/conference/camera-off.svg';
import videoOffHover from '../../../../../res/img/conference/camera-off-hover.svg';
import videoOn from '../../../../../res/img/conference/camera-on.svg';
import videoOnHover from '../../../../../res/img/conference/camera-on-hover.svg';

export default {
    audioOff, audioOffHover, audioOn, audioOnHover, endCall, endCallHover, fullscreenOff, fullscreenOffHover,
    fullscreenOn, fullscreenOnHover, parameters, parametersHover, shareOff, shareOffHover, shareOn, shareOnHover,
    videoOff, videoOffHover, videoOn, videoOnHover,
};
