import videoOff from '../../../../../res/img/conference/bottomBar/light/minimized-camera-off.svg';
import videoOffHover from '../../../../../res/img/conference/bottomBar/light/minimized-camera-off-hover.svg';
import videoOn from '../../../../../res/img/conference/bottomBar/light/minimized-camera-on.svg';
import videoOnHover from '../../../../../res/img/conference/bottomBar/light/minimized-camera-on-hover.svg';
import endCall from '../../../../../res/img/conference/bottomBar/light/minimized-end-call.svg';
import endCallHover from '../../../../../res/img/conference/bottomBar/light/minimized-end-call-hover.svg';
import audioOff from '../../../../../res/img/conference/bottomBar/light/minimized-mic-off.svg';
import audioOffHover from '../../../../../res/img/conference/bottomBar/light/minimized-mic-off-hover.svg';
import audioOn from '../../../../../res/img/conference/bottomBar/light/minimized-mic-on.svg';
import audioOnHover from '../../../../../res/img/conference/bottomBar/light/minimized-mic-on-hover.svg';
import shareOn from '../../../../../res/img/conference/bottomBar/light/minimized-screen-share-turn-off.svg';
import shareOnHover from '../../../../../res/img/conference/bottomBar/light/minimized-screen-share-turn-off-hover.svg';
import shareOff from '../../../../../res/img/conference/bottomBar/light/minimized-screen-share-turn-on.svg';
import shareOffHover from '../../../../../res/img/conference/bottomBar/light/minimized-screen-share-turn-on-hover.svg';

export default {
    audioOff, audioOffHover, audioOn, audioOnHover, endCall, endCallHover, shareOff, shareOffHover, shareOn,
    shareOnHover, videoOff, videoOffHover, videoOn, videoOnHover,
};
