/*
Copyright 2019 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { _t } from '../../../languageHandler';
import MatrixClientPeg from '../../../MatrixClientPeg';
import BaseAvatar from '../../views/avatars/BaseAvatar';
import CitadelInput from '../elements/CitadelInput';
import CitadelInfo from '../elements/CitadelInfo';
import classNames from 'classnames';
import UserStore from '../../../stores/UserStore';
import { canSendEvent } from '../../../Rooms';

// TODO: Merge with ProfileSettings?
export default class RoomProfileSettings extends React.Component {
    static propTypes = {
        roomId: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);

        const client = MatrixClientPeg.get();
        const room = client.getRoom(props.roomId);
        if (!room) throw new Error("Expected a room for ID: ", props.roomId);
        const myUserId = UserStore.getMe().userId;

        const avatarEvent = room.currentState.getStateEvents("m.room.avatar", "");
        let avatarUrl = avatarEvent && avatarEvent.getContent() ? avatarEvent.getContent()["url"] : null;
        if (avatarUrl) avatarUrl = client.mxcUrlToHttp(avatarUrl, 96, 96, 'crop', false);

        const topicEvent = room.currentState.getStateEvents("m.room.topic", "");
        const topic = topicEvent && topicEvent.getContent() ? topicEvent.getContent()['topic'] : '';

        const nameEvent = room.currentState.getStateEvents('m.room.name', '');
        const name = nameEvent && nameEvent.getContent() ? nameEvent.getContent()['name'] : '';

        this.state = {
            originalDisplayName: name,
            displayName: name,
            originalAvatarUrl: avatarUrl,
            avatarUrl: avatarUrl,
            avatarFile: null,
            originalTopic: topic,
            topic: topic,
            enableProfileSave: false,
            canSetName: canSendEvent('m.room.name', myUserId),
            canSetTopic: canSendEvent('m.room.topic', myUserId),
            canSetAvatar: canSendEvent('m.room.avatar', myUserId),
        };
    }

    _uploadAvatar = (e) => {
        e.stopPropagation();
        e.preventDefault();

        this.refs.avatarUpload.click();
    };

    _saveProfile = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!this.state.enableProfileSave) return;
        this.setState({enableProfileSave: false});

        const client = MatrixClientPeg.get();
        const newState = {};

        // TODO: What do we do about errors?

        if (this.state.originalDisplayName !== this.state.displayName) {
            await client.setRoomName(this.props.roomId, this.state.displayName);
            newState.originalDisplayName = this.state.displayName;
        }

        if (this.state.originalTopic !== this.state.topic) {
            await client.setRoomTopic(this.props.roomId, this.state.topic);
            newState.originalTopic = this.state.topic;
        }

        this.setState(newState);
    };

    _onDisplayNameChanged = (e) => {
        this.setState({
            displayName: e.target.value,
            enableProfileSave: true,
        });
    };

    _onTopicChanged = (e) => {
        this.setState({
            topic: e.target.value,
            enableProfileSave: true,
        });
    };

     _saveAvatar = async (avatarFile) => {
         const client = MatrixClientPeg.get();
         const newState = {};

         const uri = await client.uploadContent(avatarFile, {
             endpoint: `/upload/room/${this.props.roomId}`,
             prefix: '/_matrix/media/r0',
             includeFilename: true,
             name: avatarFile.name,
             sendUsingFormData: true,
         });
         await client.sendStateEvent(this.props.roomId, 'm.room.avatar', {url: uri}, '');
         newState.avatarUrl = client.mxcUrlToHttp(uri, 96, 96, 'crop', false);
         newState.originalAvatarUrl = newState.avatarUrl;
         newState.avatarFile = null;

         this.setState(newState);
    };

    _onAvatarChanged = (e) => {
        if (!e.target.files || !e.target.files.length) {
            this.setState({
                avatarUrl: this.state.originalAvatarUrl,
                avatarFile: null,
                enableProfileSave: false,
            });
            return;
        }

        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (ev) => {
            this._saveAvatar(file);
        };
        reader.readAsDataURL(file);
    };

    render() {
        // TODO: Why is rendering a box with an overlay so complicated? Can the DOM be reduced?

        let showOverlayAnyways = true;
        let avatarElement = <div className="mx_ProfileSettings_avatarPlaceholder" />;
        if (this.state.avatarUrl) {
            showOverlayAnyways = false;
            avatarElement =
                <BaseAvatar
                    idName={this.props.roomId}
                    name={this.state.displayName}
                    url={this.state.avatarUrl}
                    width={28}
                    height={28}
                    resizeMethod="crop"
                />;
        }

        const avatarOverlayClasses = classNames({
            "mx_ProfileSettings_avatarOverlay": true,
            "mx_ProfileSettings_avatarOverlay_show": showOverlayAnyways,
        });
        let avatarHoverElement = (
            <div className={avatarOverlayClasses} onClick={this._uploadAvatar}>
                <span className="mx_ProfileSettings_avatarOverlayText">{_t("Upload room avatar")}</span>
                <div className="mx_ProfileSettings_avatarOverlayImgContainer">
                    <div className="mx_ProfileSettings_avatarOverlayImg" />
                </div>
            </div>
        );
        if (!this.state.canSetAvatar) {
            if (!showOverlayAnyways) {
                avatarHoverElement = null;
            } else {
                const disabledOverlayClasses = classNames({
                    "mx_ProfileSettings_avatarOverlay": true,
                    "mx_ProfileSettings_avatarOverlay_show": true,
                    "mx_ProfileSettings_avatarOverlay_disabled": true,
                });
                avatarHoverElement = (
                    <div className={disabledOverlayClasses}>
                        <span className="mx_ProfileSettings_noAvatarText">{_t("No room avatar")}</span>
                    </div>
                );
            }
        }

        let displayNameField =
            <CitadelInput
                label={_t('Room Name')}
                onChange={this._onDisplayNameChanged}
                onBlur={this._saveProfile}
                value={this.state.displayName}
            />;

        if (!this.state.canSetName) {
            displayNameField =
                <CitadelInfo
                    label={_t('Room Name')}
                    value={this.state.displayName}
                />;
        }

        let topicField =
            <CitadelInput
                label={_t('Room Topic')}
                onChange={this._onTopicChanged}
                onBlur={this._saveProfile}
                value={this.state.topic}
            />;


        if (!this.state.canSetTopic) {
            topicField =
                <CitadelInfo
                    label={_t('Room Topic')}
                    value={this.state.topic}
                />;
        }

        return (
            <form onSubmit={this._saveProfile} autoComplete={false} noValidate={true}>
                <input type="file" ref="avatarUpload" className="mx_ProfileSettings_avatarUpload"
                       onChange={this._onAvatarChanged} accept="image/*" />
                <div className="mx_ProfileSettings_profile">
                    <div className="mx_ProfileSettings_avatar">
                        {avatarElement}
                        {avatarHoverElement}
                    </div>
                    <div className="mx_ProfileSettings_controls">
                        { displayNameField }
                        { topicField }
                    </div>
                </div>
            </form>
        );
    }
}
