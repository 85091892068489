import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CitadelToggle = ({ className, type, label, description, checked, disabled, onChange, id }) => {
    const classes = classNames(className, {
        mx_CitadelToggle: true,
        mx_CitadelToggle_disabled: disabled,
        checked: checked,
    });

    const nameClasses = classNames({
        name: true,
        name_description: description,
    });

    const onClick = (disabled) ? null : onChange;

    let input = (
        <div className="radio" onClick={onClick} />
    );

    if (type === 'checkbox') {
        input = (
            <div className="checkbox" onClick={onClick} />
        );
    }

    return (
        <div className={classes} id={id}>
            { input }
            <div className="label" onClick={onClick}>
                <div className={nameClasses}>
                    { label }
                </div>
                { description && (
                    <div className="description" onClick={onClick}>
                        { description }
                    </div>
                )}
            </div>
        </div>
    );
};

CitadelToggle.propTypes = {
    type: PropTypes.string,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

export default CitadelToggle;
