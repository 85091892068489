import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { debounce } from 'lodash';

import { _t } from '../../../languageHandler';
import Login from '../../../Login';
import { validateEmail, citadelUrls } from '../../../utils/CitadelUtils';
import { getDomain } from '../../../utils/CitadelRequests';
import CitadelInput from '../elements/CitadelInput';
import CitadelButton from '../elements/CitadelButton';
import MfaManager from '../../views/dialogs/mfa/MfaManager';
import dis from '../../../dispatcher';
import { KeyCode } from '../../../Keyboard';
import CitadexStore from '../../../stores/CitadexStore';

class CitadelLoginForm extends React.Component {
    constructor(props) {
        super(props);

        const { defaultUrls, initialEmail } = props;
        this.state = {
            busy: false,
            error: null,
            email: initialEmail,
            portalUrl: defaultUrls.portal,
            servicesUrl: defaultUrls.services,
            isEmailValid: validateEmail(initialEmail),
        };

        // if the onLogout function is missing it means that we need to clear the
        // test mode value from local storage because this is not soft logout
        if (!props.onLogout) {
            CitadexStore.setTestMode(false);
        }
    }

    componentDidMount() {
       this.setInitialConfig();
       this.debouncer = debounce(this.getLoginConfiguration, 1000);
    }

    componentDidUpdate({ initialEmail: prevInitialEmail, onLogout: prevOnLogout}) {
        const { initialEmail, onLogout } = this.props;
        if (initialEmail !== prevInitialEmail) this.setState({ email: initialEmail, isEmailValid: validateEmail(initialEmail) }, this.setInitialConfig);

        // if the onLogout function is missing it means that we need to clear the
        // test mode value from local storage because this is not soft logout
        // and also we need to change the urls to the default ones
        if (prevOnLogout && !onLogout) {
            CitadexStore.setTestMode(false);
            this.changeUrls('whiterabbit');
        }
    }

    componentWillUnmount() {
        this.debouncer = null;
    }

    setInitialConfig = () => {
        const isTestMode = CitadexStore.getTestMode();

        if (isTestMode) {
            this.changeUrls('thereisnospoon');
        } else {
            this.getLoginConfiguration();
        }
    }

    getLoginConfiguration = async () => {
        const { defaultDeviceDisplayName } = this.props;
        const { email, portalUrl } = this.state;
        let error = '';

        const ERROR = {
            INVALID_EMAIL: _t("This doesn't appear to be a valid email address"),
            UNKNOWN_ACCOUNT: _t("Your Citadel account cannot be found"),
            NO_NETWORK: _t("No network, check your connectivity."),
        };

        if (!email || !validateEmail(email)) return;

        if (validateEmail(email)) {
            this.setState({ busy: true });
            try {
                const res = await getDomain(portalUrl, email);
                const { domain: homeServerDomain } = res || {};

                if (homeServerDomain) {
                    const homeServerUrl = `https://${homeServerDomain}`;
                    const loginLogic = new Login(homeServerUrl, defaultDeviceDisplayName);

                    try {
                        const mfaConfig = await loginLogic.getMfaConfiguration(email);
                        this.setState({ homeServerUrl, mfaConfig });
                    } catch (err) {
                        if (err.errcode !== 'M_MISSING_PARAM') {
                            error = ERROR.UNKNOWN_ACCOUNT;
                        }
                    }
                } else error = ERROR.UNKNOWN_ACCOUNT;
            } catch (err) {
                if (err.message === 'Failed to fetch') {
                    error = ERROR.NO_NETWORK;
                } else {
                    error = ERROR.INVALID_EMAIL;
                }
            }
        }

        this.setState({ busy: false });
        error && this.setState({ error });
    };

    onInputChange = (ev) => {
        const { name, value } = ev.target;

        this.setState({
            [name]: (value === 'thereisnospoon' || value === 'whiterabbit') ? '' : value.trim(),
            error: null,
            isEmailValid: validateEmail(value),
        });
        this.changeUrls(value);
    };

    changeUrls = (value) => {
        const { defaultUrls, devUrls, onUrlsChange } = this.props;
        let { portalUrl, servicesUrl } = this.state;

        switch (value) {
            case 'thereisnospoon':
                portalUrl = devUrls.portal;
                servicesUrl = devUrls.services;
                CitadexStore.setTestMode(true);
                break;
            case 'whiterabbit':
                portalUrl = defaultUrls.portal;
                servicesUrl = defaultUrls.services;
                CitadexStore.setTestMode(false);
                break;
        }

        this.setState({ portalUrl, servicesUrl, mfaConfig: null });
        onUrlsChange({ portal: portalUrl, services: servicesUrl });
    }

    onSubmitForm = async () => {
        await this.getLoginConfiguration();

        const { busy, email, error, homeServerUrl, mfaConfig } = this.state;
        const { param, phoneNumber, phoneCountryInfo } = mfaConfig || {};


        if (!mfaConfig || busy || error) return;

        const mfaStatus = param === 'password' ? null : param;
        MfaManager.setTemporaryValues({
            homeServerUrl,
            mfaStatus,
            phoneNumber,
            phoneCountryInfo,
            email,
        });

        dis.dispatch({ action: 'start_login', isNotInitialForm: true });
    };

    renderTestModeSection = () => {
        const { devUrls } = this.props;
        const { portalUrl } = this.state;

        const testModeClassNames = classNames('test-mode', {
            'hide': (portalUrl !== devUrls.portal),
        });

        return (
            <div className={testModeClassNames}>
                <p>{_t('Test mode enabled')}</p>
            </div>
        );
    };

    onKeyDown = (e) => {
        const { email } = this.state;

        if (e.keyCode === KeyCode.ENTER) {
            e.stopPropagation();
            e.preventDefault();

            if (validateEmail(email)) {
                this.onSubmitForm();
            }
        }
    };

    render() {
        const { email, busy, error, isEmailValid } = this.state;
        const { onLogout } = this.props;
        const emailCaption = error ? { type: 'error', text: error } : {};

        return (
            <div className='mx_CitadelLoginForm'>
                { this.renderTestModeSection() }
                <form>
                    <CitadelInput
                        autoFocus={true}
                        caption={emailCaption}
                        disabled={!!onLogout}
                        coveredWithOpacity={!!onLogout}
                        label={_t('Email address')}
                        name='email'
                        onChange={this.onInputChange}
                        onKeyDown={this.onKeyDown}
                        value={email}
                    />
                    <CitadelButton
                        isDisabled={!isEmailValid}
                        isLoading={busy}
                        minWidth={200}
                        onClick={this.onSubmitForm}
                        text={_t('Sign in')}
                    />
                </form>
            </div>
        );
    }
}

CitadelLoginForm.propTypes = {
    defaultDeviceDisplayName: PropTypes.string.isRequired,
    defaultUrls: citadelUrls,
    devUrls: citadelUrls,
    onLoggedIn: PropTypes.func.isRequired,
    onLogout: PropTypes.func, // we have this function only if we are in soft logout state
    onUrlsChange: PropTypes.func.isRequired,
};

export default CitadelLoginForm;
