/*
Copyright 2015, 2016 OpenMarket Ltd
Copyright 2017 Vector Creations Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

'use strict';

import Promise from 'bluebird';
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import sdk from '../../../index';
import { _t, _td } from '../../../languageHandler';
import MatrixClientPeg from '../../../MatrixClientPeg';
import dis from '../../../dispatcher';
import DMRoomMap from '../../../utils/DMRoomMap';
import * as Rooms from '../../../Rooms';
import * as RoomNotifs from '../../../RoomNotifs';
import Modal from '../../../Modal';
import RoomListActions from '../../../actions/RoomListActions';
import RoomListStore from '../../../stores/RoomListStore';
import GenericToolTip from '../../structures/GenericToolTip';
import { isRoomOnHomeServer } from '../../../utils/CitadelUtils';
import { getPowerLevels } from '../../../Rooms';
import UserStore from '../../../stores/UserStore';

module.exports = class extends React.Component {
    static displayName = 'RoomTileContextMenu';

    static propTypes = {
        room: PropTypes.object.isRequired,
        /* callback called when the menu is dismissed */
        onFinished: PropTypes.func,
    };

    constructor(props) {
        super(props);
        const dmRoomMap = new DMRoomMap(MatrixClientPeg.get());

        this.state = {
            roomNotifState: RoomNotifs.getRoomNotifsState(props.room.roomId),
            isFavourite: props.room.tags.hasOwnProperty("m.favourite"),
            isLowPriority: props.room.tags.hasOwnProperty("m.lowpriority"),
            canDelete: false,
            isDirectMessage: Boolean(dmRoomMap.getUserIdForRoomId(props.room.roomId)),
        };
    }

    componentDidMount() {
        this._unmounted = false;
        const me = this.props.room.getMember(localStorage.getItem('mx_user_id'));
        const canDelete = me.powerLevel === 100;
        this.setState({ canDelete });
        const joinedOrInvitedMembersCount = Object.values(this.props.room.currentState.members)
            .filter((member) => member.membership === 'join' || member.membership === 'invite').length;
        this.setState({ joinedOrInvitedMembersCount });
    }

    componentWillUnmount() {
        this._unmounted = true;
    }

    _toggleTag = (tagNameOn, tagNameOff) => {
        if (!MatrixClientPeg.get().isGuest()) {
            Promise.delay(500).then(() => {
                dis.dispatch(RoomListActions.tagRoom(
                    MatrixClientPeg.get(),
                    this.props.room,
                    tagNameOff, tagNameOn,
                    undefined, 0,
                ), true);

                this.props.onFinished();
            });
        }
    };

    _onClickFavourite = () => {
        // Tag room as 'Favourite'
        if (!this.state.isFavourite && this.state.isLowPriority) {
            this.setState({
                isFavourite: true,
                isLowPriority: false,
            });
            this._toggleTag("m.favourite", "m.lowpriority");
        } else if (this.state.isFavourite) {
            this.setState({ isFavourite: false });
            this._toggleTag(null, "m.favourite");
        } else if (!this.state.isFavourite) {
            this.setState({ isFavourite: true });
            this._toggleTag("m.favourite");
        }
    };

    _onClickLowPriority = () => {
        // Tag room as 'Low Priority'
        if (!this.state.isLowPriority && this.state.isFavourite) {
            this.setState({
                isFavourite: false,
                isLowPriority: true,
            });
            this._toggleTag("m.lowpriority", "m.favourite");
        } else if (this.state.isLowPriority) {
            this.setState({ isLowPriority: false });
            this._toggleTag(null, "m.lowpriority");
        } else if (!this.state.isLowPriority) {
            this.setState({ isLowPriority: true });
            this._toggleTag("m.lowpriority");
        }
    };

    _onClickDelete = () => {
        const { room, onFinished } = this.props;
        // Leave room
        MatrixClientPeg.get().trackUserAction({
            formId: 'closeRoom',
            version: 1,
            action: 'list',
            room: room.roomId,
        });
        dis.dispatch({
            action: 'delete_room',
            room_id: room.roomId,
        });

        // Close the context menu
        onFinished && onFinished();
    };

    getOtherJoinedMemberUserId = (myUserId) => {
        const currentMembers = this.props.room.getJoinedMembers();
        if (currentMembers.length > 1) { // the other user has joined the room
            const otherMember = currentMembers.filter(member => member.userId !== myUserId)[0];

            // check that the other user is not an admin, 100 means admin
            if (otherMember && otherMember.powerLevel < 100) {
                return otherMember.userId;
            }
        }
    };

    _onClickDM = async () => {
        if (MatrixClientPeg.get().isGuest()) return;
        const myUserId = UserStore.getMe().userId;
        const powerLevels = getPowerLevels(this.props.room);

        if (!this.state.isDirectMessage && this.state.joinedOrInvitedMembersCount > 2) return;
        // we're sure that we only have 2 users in the room if we get to this point

        if (!this.state.isDirectMessage) {
            const otherMemberUserId = this.getOtherJoinedMemberUserId(myUserId);

            // I'm admin and the other user isn't
            if (!this.state.isDirectMessage && powerLevels.users[myUserId] && !powerLevels.users[otherMemberUserId] && otherMemberUserId) {
                // make the other user admin
                const powerLevelEvent = this.props.room.currentState.getStateEvents('m.room.power_levels', '');
                await MatrixClientPeg.get().setPowerLevel(this.props.room.roomId, otherMemberUserId, 100, powerLevelEvent);
            }
        }

        const newIsDirectMessage = !this.state.isDirectMessage;
        this.setState({
            isDirectMessage: newIsDirectMessage,
        });

        Rooms.guessAndSetDMRoom(
            this.props.room, newIsDirectMessage,
        ).delay(500).finally(() => {
            // Close the context menu
            if (this.props.onFinished) {
                this.props.onFinished();
            }
        }, (err) => {
            const ErrorDialog = sdk.getComponent("dialogs.ErrorDialog");
            Modal.createTrackedDialog('Failed to set Direct Message status of room', '', ErrorDialog, {
                title: _t('Failed to set Direct Message status of room'),
                description: ((err && err.message) ? err.message : _t('Operation failed')),
            });
        });
    };

    _onClickLeave = () => {
        const { room, onFinished } = this.props;
        // Leave room
        MatrixClientPeg.get().trackUserAction({
            formId: 'leaveRoom',
            version: 1,
            action: 'list',
            room: room.roomId,
        });
        dis.dispatch({
            action: 'leave_room',
            room_id: room.roomId,
        });
        // Close the context menu
        onFinished && onFinished();
    };

    _onClickReject = () => {
        dis.dispatch({
            action: 'reject_invite',
            room_id: this.props.room.roomId,
        });

        // Close the context menu
        if (this.props.onFinished) {
            this.props.onFinished();
        }
    };

    _onClickForget = () => {
        // FIXME: duplicated with RoomSettings (and dead code in RoomView)
        MatrixClientPeg.get().forget(this.props.room.roomId).done(function() {
            dis.dispatch({ action: 'view_next_room' });
        }, function(err) {
            const errCode = err.errcode || _td("unknown error code");
            const ErrorDialog = sdk.getComponent("dialogs.ErrorDialog");
            Modal.createTrackedDialog('Failed to forget room', '', ErrorDialog, {
                title: _t('Failed to forget room %(errCode)s', { errCode: errCode }),
                description: ((err && err.message) ? err.message : _t('Operation failed')),
            });
        });

        // Close the context menu
        if (this.props.onFinished) {
            this.props.onFinished();
        }
    };

    _saveNotifState = (newState) => {
        if (MatrixClientPeg.get().isGuest()) return;

        const oldState = this.state.roomNotifState;
        const roomId = this.props.room.roomId;

        this.setState({
            roomNotifState: newState,
        });
        RoomNotifs.setRoomNotifsState(roomId, newState).done(() => {
            // delay slightly so that the user can see their state change
            // before closing the menu
            return Promise.delay(500).then(() => {
                if (this._unmounted) return;
                // Close the context menu
                if (this.props.onFinished) {
                    this.props.onFinished();
                }
            });
        }, (error) => {
            // TODO: some form of error notification to the user
            // to inform them that their state change failed.
            // For now we at least set the state back
            if (this._unmounted) return;
            this.setState({
                roomNotifState: oldState,
            });
        });
    };

    _onClickAlertMe = () => {
        this._saveNotifState(RoomNotifs.ALL_MESSAGES_LOUD);
    };

    _onClickAllNotifs = () => {
        this._saveNotifState(RoomNotifs.ALL_MESSAGES);
    };

    _onClickMentions = () => {
        this._saveNotifState(RoomNotifs.MENTIONS_ONLY);
    };

    _onClickMute = () => {
        this._saveNotifState(RoomNotifs.MUTE);
    };

    _renderNotifMenu = () => {
        const alertMeClasses = classNames({
            'mx_RoomTileContextMenu_notif_field': true,
            'mx_RoomTileContextMenu_notif_fieldSet': this.state.roomNotifState == RoomNotifs.ALL_MESSAGES_LOUD,
        });

        const allNotifsClasses = classNames({
            'mx_RoomTileContextMenu_notif_field': true,
            'mx_RoomTileContextMenu_notif_fieldSet': this.state.roomNotifState == RoomNotifs.ALL_MESSAGES,
        });

        const mentionsClasses = classNames({
            'mx_RoomTileContextMenu_notif_field': true,
            'mx_RoomTileContextMenu_notif_fieldSet': this.state.roomNotifState == RoomNotifs.MENTIONS_ONLY,
        });

        const muteNotifsClasses = classNames({
            'mx_RoomTileContextMenu_notif_field': true,
            'mx_RoomTileContextMenu_notif_fieldSet': this.state.roomNotifState == RoomNotifs.MUTE,
        });

        return (
            <div className="mx_RoomTileContextMenu">
                <div className="mx_RoomTileContextMenu_notif_picker" >
                    <img src={require("../../../../res/img/notif-slider.svg")} width="20" height="107" />
                </div>
                <div className={alertMeClasses} onClick={this._onClickAlertMe} >
                    <div className="mx_RoomTileContextMenu_notif_activeIcon" />
                    <div className="mx_RoomTileContextMenu_notif_icon icon-context-mute-off-copy" />
                    {_t('All messages (noisy)')}
                </div>
                <div className={allNotifsClasses} onClick={this._onClickAllNotifs} >
                    <div className="mx_RoomTileContextMenu_notif_activeIcon" />
                    <div className="mx_RoomTileContextMenu_notif_icon icon-context-mute-off" />
                    {_t('All messages')}
                </div>
                <div className={mentionsClasses} onClick={this._onClickMentions} >
                    <div className="mx_RoomTileContextMenu_notif_activeIcon" />
                    <div className="mx_RoomTileContextMenu_notif_icon icon-context-mute-mentions" />
                    {_t('Mentions only')}
                </div>
                <div className={muteNotifsClasses} onClick={this._onClickMute} >
                    <div className="mx_RoomTileContextMenu_notif_activeIcon" />
                    <div className="mx_RoomTileContextMenu_notif_icon icon-context-mute" />
                    {_t('Mute')}
                </div>
            </div>
        );
    };

    _onClickSettings = () => {
        if (RoomListStore.getStickyRoomId() !== this.props.room.roomId) {
            dis.dispatch({
                action: 'view_room',
                room_id: this.props.room.roomId,
            });
        }
        dis.dispatch({
            action: 'open_room_settings',
        });
        if (this.props.onFinished) {
            this.props.onFinished();
        }
    };

    _renderSettingsMenu = () => {
        return (
            <div>
                <div className="mx_RoomTileContextMenu_tag_field" onClick={this._onClickSettings} >
                    <img className="mx_RoomTileContextMenu_tag_icon" src={require("../../../../res/img/icons-settings-room.svg")} width="15" height="15" />
                    {_t('Settings')}
                </div>
            </div>
        );
    };

    _renderLeaveMenu = (membership) => {
        if (!membership) {
            return null;
        }

        let leaveClickHandler = null;
        let leaveText = null;

        switch (membership) {
            case "join":
                leaveClickHandler = this._onClickLeave;
                leaveText = _t('Leave room');
                break;
            case "leave":
            case "ban":
                leaveClickHandler = this._onClickForget;
                leaveText = _t('Forget');
                break;
            case "invite":
                leaveClickHandler = this._onClickReject;
                leaveText = _t('Reject');
                break;
        }

        return (
            <div>
                <div className="mx_RoomTileContextMenu_leave" onClick={leaveClickHandler} >
                    <div className="mx_RoomTileContextMenu_tag_icon leave" />
                    {leaveText}
                </div>
            </div>
        );
    };

    _renderDeleteMenu = () => {
        const { canDelete } = this.state;
        const { room } = this.props;
        const isDeleteRoomDisabled = !isRoomOnHomeServer(room.roomId);
        const deleteRoomClassName = classNames('mx_RoomTileContextMenu_leave', {
            'disabled': isDeleteRoomDisabled,
        });
        return (
            canDelete ?
            <GenericToolTip
                label={isDeleteRoomDisabled ? _t("You are not the owner of the room") : ''}
                labelStyle={"delete_tooltiptext"}
            >
                <div>
                    <div className={deleteRoomClassName} onClick={isDeleteRoomDisabled ? null : this._onClickDelete} >
                        <div className="mx_RoomTileContextMenu_tag_icon delete" />
                        {_t('Delete room')}
                    </div>
                </div>
            </GenericToolTip> : null
        );
    }

    _renderRoomTagMenu = () => {
        const favouriteClasses = classNames({
            'mx_RoomTileContextMenu_tag_field': true,
            'mx_RoomTileContextMenu_tag_fieldSet': this.state.isFavourite,
            'mx_RoomTileContextMenu_tag_fieldDisabled': false,
        });

        const lowPriorityClasses = classNames({
            'mx_RoomTileContextMenu_tag_field': true,
            'mx_RoomTileContextMenu_tag_fieldSet': this.state.isLowPriority,
            'mx_RoomTileContextMenu_tag_fieldDisabled': false,
        });

        const dmClasses = classNames({
            'mx_RoomTileContextMenu_tag_field': true,
            'mx_RoomTileContextMenu_tag_fieldSet': this.state.isDirectMessage,
            'mx_RoomTileContextMenu_tag_fieldDisabled': false,
        });

        const isDMToggleVisible = this.state.joinedOrInvitedMembersCount === 2 && !this.state.isLowPriority && !this.state.isFavourite;

        return (
            <div>
                <div className={favouriteClasses} onClick={this._onClickFavourite} >
                    <img className="mx_RoomTileContextMenu_tag_icon" src={require("../../../../res/img/icon_context_fave.svg")} width="15" height="15" />
                    <img className="mx_RoomTileContextMenu_tag_icon_set" src={require("../../../../res/img/icon_context_fave_on.svg")} width="15" height="15" />
                    {_t('Favourite')}
                </div>
                {isDMToggleVisible && <div className={dmClasses} onClick={this._onClickDM} >
                    <img className="mx_RoomTileContextMenu_tag_icon" src={require("../../../../res/img/arrow-right-new.svg")} width="15" height="15" />
                    <img className="mx_RoomTileContextMenu_tag_icon_set" src={require("../../../../res/img/arrow-right-new.svg")} width="15" height="15" />
                    {this.state.isDirectMessage ? _t('Move to rooms') : _t('Move to people')}
                </div>}
                <div className={lowPriorityClasses} onClick={this._onClickLowPriority} >
                    <img className="mx_RoomTileContextMenu_tag_icon" src={require("../../../../res/img/icon_context_low.svg")} width="15" height="15" />
                    <img className="mx_RoomTileContextMenu_tag_icon_set" src={require("../../../../res/img/icon_context_low_on.svg")} width="15" height="15" />
                    {_t('Low Priority')}
                </div>
            </div>
        );
    };

    render() {
        const myMembership = this.props.room.getMyMembership();

        // Can't set notif level or tags on non-join rooms
        if (myMembership !== 'join') {
            return <div>
                {this._renderLeaveMenu(myMembership)}
            </div>;
        }

        return (
            <div>
                {this._renderNotifMenu()}
                <hr className="mx_RoomTileContextMenu_separator" />
                {this._renderLeaveMenu(myMembership)}
                {this._renderDeleteMenu()}
                <hr className="mx_RoomTileContextMenu_separator" />
                {this._renderRoomTagMenu()}
                <hr className="mx_RoomTileContextMenu_separator" />
                {this._renderSettingsMenu()}
            </div>
        );
    }
};
