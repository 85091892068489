import React from 'react';
import cx from 'classnames';

import SettingsStore, { SettingLevel } from "../../../settings/SettingsStore";

import { getCurrentLanguage } from '../../../languageHandler';

import macMobileEn from '../../../../res/img/app-download/en/mac-mobile.svg';
import macMobileEnDark from '../../../../res/img/app-download/en/mac-mobile-dark.svg';
import macDesktopEn from '../../../../res/img/app-download/en/mac-desktop.svg';
import macDesktopEnDark from '../../../../res/img/app-download/en/mac-desktop-dark.svg';
import androidEn from '../../../../res/img/app-download/en/android.svg';
import androidEnDark from '../../../../res/img/app-download/en/android-dark.svg';
import windowsDesktopEn from '../../../../res/img/app-download/en/win-desktop.svg';
import windowsDesktopEnDark from '../../../../res/img/app-download/en/win-desktop-dark.svg';
import macMobileFr from '../../../../res/img/app-download/fr/mac-mobile.svg';
import macMobileFrDark from '../../../../res/img/app-download/fr/mac-mobile-dark.svg';
import macDesktopFr from '../../../../res/img/app-download/fr/mac-desktop.svg';
import macDesktopFrDark from '../../../../res/img/app-download/fr/mac-desktop-dark.svg';
import androidFr from '../../../../res/img/app-download/fr/android.png';
import androidFrDark from '../../../../res/img/app-download/fr/android-dark.svg';
import windowsDesktopFr from '../../../../res/img/app-download/fr/win-desktop.svg';
import windowsDesktopFrDark from '../../../../res/img/app-download/fr/win-desktop-dark.svg';

const icons = {
    light: {
        en: {
            macMobile: macMobileEn,
            macDesktop: macDesktopEn,
            android: androidEn,
            windowsDesktop: windowsDesktopEn,
        },
        fr: {
            macMobile: macMobileFr,
            macDesktop: macDesktopFr,
            android: androidFr,
            windowsDesktop: windowsDesktopFr,
        },
    },
    dark: {
        en: {
            macMobile: macMobileEnDark,
            macDesktop: macDesktopEnDark,
            android: androidEnDark,
            windowsDesktop: windowsDesktopEnDark,
        },
        fr: {
            macMobile: macMobileFrDark,
            macDesktop: macDesktopFrDark,
            android: androidFrDark,
            windowsDesktop: windowsDesktopFrDark,
        },
    },
};

const links = [
    { name: 'macMobile', url: 'https://itunes.apple.com/app/citadelteam/id1132246597', alt: 'iOS Mobile Application' },
    { name: 'macDesktop', url: 'https://download.citadel.team/download/latest/osx', alt: 'macOS Desktop Application' },
    { name: 'android', url: 'https://play.google.com/store/apps/details?id=im.team', alt: 'Android Mobile Application'},
    { name: 'windowsDesktop', url: 'https://download.citadel.team/download/latest/windows_64', alt: 'Windows Desktop Application'},
];

module.exports = class extends React.Component {
    static displayName = 'AuthFooterDownload';

    render() {
        const { displayOnlyDesktopApps, description } = this.props;
        const lang = getCurrentLanguage();
        const newLinks = displayOnlyDesktopApps ? [links[1], links[3]] : links;
        const theme = SettingsStore.getValueAt(SettingLevel.ACCOUNT, "theme");

        return (
            <div className={cx('auth-footer-apps-container', { 'only-desktop': displayOnlyDesktopApps, 'withDescription': !!description })} >
                {description ? <span className='auth-footer-description'>{description}</span> : null}
                <div className='auth-footer-apps'>
                    {
                        newLinks.map(({ alt, name, url }) => (
                            <div className={name} key={name}>
                                <a href={url}>
                                    <img src={icons[theme][lang][name]} alt={alt} />
                                </a>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
};
