/*
Copyright 2018, 2019 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React from 'react';
import Modal from '../../../Modal';
import sdk from '../../../index';
import dis from '../../../dispatcher';
import { _t, getCurrentLanguage } from '../../../languageHandler';
import MatrixClientPeg from '../../../MatrixClientPeg';

const FAQLinkEN = 'https://support.citadel.team/kb/guide/en/end-to-end-encryption-information-hU9Xi8mfcU/Steps/33982';
const FAQLinkFR = 'https://support.citadel.team/kb/guide/fr/informations-sur-le-chiffrement-de-bout-en-bout-hU9Xi8mfcU/Steps/33982';

export default class LogoutDialog extends React.Component {
    static defaultProps = {
        onFinished: function() {},
    };

    _onExportE2eKeysClicked = () => {
        Modal.createTrackedDialogAsync('Export E2E Keys', '',
            import('../../../async-components/views/dialogs/ExportE2eKeysDialog'),
            {
                matrixClient: MatrixClientPeg.get(),
                onExport: this._onFinished,
                isLogout: true,
            },
        );
    }

    _onFinished = (confirmed) => {
        if (confirmed) {
            dis.dispatch({action: 'logout'});
        }
        // close dialog
        this.props.onFinished();
    }

    _onLogoutConfirm = () => {
        dis.dispatch({action: 'logout'});

        // close dialog
        this.props.onFinished(true);
    }

    render() {
        const FAQLink = getCurrentLanguage() === 'fr' ? FAQLinkFR : FAQLinkEN;
        const description = <div>
            <p>{_t("Encrypted messages are secured with end-to-end encryption. " +
                "Only you and the recipient(s) have the keys to read these messages.",
            )}</p>
            <p>{_t("Back up your keys before signing out to avoid losing them.")}</p>
            <p>
                {_t('Would you like to know more? <FAQLink>See our FAQ</FAQLink>',
                {},
                { 'FAQLink': (sub) => <a href={FAQLink} target="_blank">{sub}</a> },
                )}
            </p>
        </div>;

        const BaseDialog = sdk.getComponent('views.dialogs.BaseDialog');

        const dialogContent = <div>
            <div className="mx_Dialog_content" id='mx_Dialog_content'>
                { description }
            </div>
            <div className="mx_Dialog_buttons">
                <button onClick={this._onLogoutConfirm} className="mx_Dialog_secondary">
                    {_t("Log out without export")}
                </button>
                <button onClick={this._onExportE2eKeysClicked} className="mx_Dialog_primary">
                    {_t("Manually export keys")}
                </button>
            </div>
        </div>;
        // Not quite a standard question dialog as the primary button cancels
        // the action and does something else instead, whilst non-default button
        // confirms the action.
        return (<BaseDialog
            title={_t("You'll lose access to your encrypted messages")}
            contentId='mx_Dialog_content'
            hasCancel={true}
            onFinished={this._onFinished}
            fixedWidth={false}
        >
            {dialogContent}
        </BaseDialog>);
    }
}
