import React from 'react';
import BaseDialog from './BaseDialog';
import CitadelInput from '../elements/CitadelInput';
import DialogButtons from '../elements/DialogButtons';
import {_t} from '../../../languageHandler';
import MatrixClientPeg from '../../../MatrixClientPeg';

class NewTopicDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            topic: '',
        };
    }

    onTopicClear = () => {
        this.setState({
            topic: '',
        });
    };

    onTopicChange = (ev) => {
        this.setState({
            topic: ev.target.value,
        });
    };

    onConfirm = async () => {
        const { roomId } = this.props;
        const { topic } = this.state;

        await MatrixClientPeg.get().setRoomTopic(roomId, topic);
        this.props.onFinished(topic);
    };

    render() {
        const { topic } = this.state;

        const title = _t('Add a description');
        const inputCaption = {
            type: 'description',
            text: _t('Help users understand the theme of this room'),
        };

        return (
            <BaseDialog title={title} hasCancel={true} onFinished={()=>this.props.onFinished()}>
                <CitadelInput
                    name='topic'
                    caption={inputCaption}
                    onChange={this.onTopicChange}
                    autoFocus={true}
                    value={topic}
                    onClear={this.onTopicClear}
                />
                <DialogButtons primaryButton={_t('Save description')}
                               onPrimaryButtonClick={this.onConfirm}
                               disabled={!topic}
                               hasCancel={false}
                />
            </BaseDialog>
        );
    }
}

export default NewTopicDialog;
