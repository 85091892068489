import React from 'react';
import { noop, isEmpty } from 'lodash';

import Modal from '../../../Modal';
import MatrixClientPeg from '../../../MatrixClientPeg';
import MfaSmsActivationDialog from '../dialogs/mfa/MfaSmsActivationDialog';
import MfaOnboardingDialog from '../dialogs/mfa/MfaOnboardingDialog';
import MfaAppActivationDialog from '../dialogs/mfa/MfaAppActivationDialog';
import MfaPasswordDialog from '../dialogs/mfa/MfaPasswordDialog';
import { _t } from '../../../languageHandler';
import MfaManager from '../dialogs/mfa/MfaManager';
import dis from '../../../dispatcher';
import CitadelButton from '../elements/CitadelButton';

import enabledMFASvg from '../../../../res/img/mfa-enabled.svg';
import disabledMFASvg from '../../../../res/img/mfa-disabled.svg';

export default class MfaSection extends React.Component {
    static displayName = "MfaSection";
    state = {
        mfaStatus: [],
        phoneNumber: '',
        mfa_methods: {},
    };

    async componentDidMount() {
        this.dispatcherRef = dis.register(this.onAction);

        const { mfaStatus, phoneNumber } = MfaManager.getLocalValues();
        const { mfa_mandatory_mode, mfa_methods } = await MatrixClientPeg.get().getServerConfig();

        this.setState({ mfaStatus, phoneNumber, mfa_mandatory_mode, mfa_methods });
        // Check remote state in case mfaStatus have changed,
        // mfa_updated will be triggered to retrieve updated local values again
        MfaManager.getMfaStatus();
    }

    componentWillUnmount() { dis.unregister(this.dispatcherRef); }

    onAction = async ({ action }) => {
        if (action === 'mfa_updated') {
            const { mfaStatus, phoneNumber } = MfaManager.getLocalValues();
            this.setState({ mfaStatus, phoneNumber });
        }
    }

    openSmsMfaDialog = (password = null) => {
        const { mfaStatus = [] } = this.state;
        MatrixClientPeg.get().trackUserAction({
            formId: 'statusMFA',
            version: 1,
            action: 'enable',
            type: 'msisdn',
            past: isEmpty(mfaStatus) ? 'None' : mfaStatus.join('_'),
        });
        Modal.createDialog(MfaSmsActivationDialog, {
            mfaStatus,
            onContinue: this.toggleMfaStatus('msisdn', password),
            onFinished: noop,
        }, 'mx_MfaSmsActivationDialog_wrapper');
    };

    openAppMfaDialog = () => {
        const { mfaStatus } = this.state;
        MatrixClientPeg.get().trackUserAction({
            formId: 'statusMFA',
            version: 1,
            action: 'enable',
            type: 'authapp',
            past: isEmpty(mfaStatus) ? 'None' : mfaStatus.join('_'),
        });
        Modal.createDialog(MfaAppActivationDialog, {
            onFinished: noop,
            onEnd: this.onActivateMfaAppFinished,
            mfaStatus,
            hasCancelButton: true,
        }, 'mx_MfaAppActivationDialog_wrapper');
    };

    onActivateMfaAppFinished = (mfaType, isActivated = true) => {
       MfaManager.showTotpFinalDialog(isActivated);
    }

    onActivateMfaFinished = async (mfaType, isActivated = true) => {
        const { mfaStatus } = this.state;
        if (!isActivated) {
            MatrixClientPeg.get().trackUserAction({
                formId: 'statusMFA',
                version: 1,
                action: 'cancel',
            });
        }
        switch (mfaType) {
            case 'email': {
                MatrixClientPeg.get().trackUserAction({
                    formId: 'statusMFA',
                    version: 1,
                    action: 'enable',
                    type: 'email',
                    past: isEmpty(mfaStatus) ? 'None' : mfaStatus.join('_'),
                });
                this.toggleMfaStatus(mfaType)(true, async () => {
                    await MatrixClientPeg.get().setAccountData('citadel.mfa', { mfaActivated: true });
                });
                break;
            }
            case 'totp': {
                this.openAppMfaDialog();
                break;
            }
            case 'msisdn': {
                this.openSmsMfaDialog();
                break;
            }
        }
    }

    sendMetricsAndSetUserData = async (type) => {
        const { mfaStatus } = this.state;
        switch (type) {
            case 'email':
                MatrixClientPeg.get().trackUserAction({
                    formId: 'statusMFA',
                    version: 1,
                    action: 'disable',
                    type: 'all',
                    past: mfaStatus.join('_'),
                });
                break;
            case 'totp':
                MatrixClientPeg.get().trackUserAction({
                    formId: 'statusMFA',
                    version: 1,
                    action: 'disable',
                    type: 'authapp',
                    past: mfaStatus.join('_'),
                });
                break;
            case 'msisdn':
                MatrixClientPeg.get().trackUserAction({
                    formId: 'statusMFA',
                    version: 1,
                    action: 'disable',
                    type: 'msisdn',
                    past: mfaStatus.join('_'),
                });
                break;
        }
        await MatrixClientPeg.get().setAccountData('citadel.mfa', { mfaActivated: false });
    }

    showMfaOptions = () => {
        MatrixClientPeg.get().trackUserAction({
            formId: 'statusMFA',
            version: 1,
            action: 'request',
        });
        Modal.createDialog(MfaOnboardingDialog, {
            onFinished: this.onActivateMfaFinished,
        }, 'mx_MfaOnboardingDialog');
    };

    toggleMfaStatus = (type, changePhoneNumberPassword) => (
        activate = false, callback = noop, isChangePhoneNumber = false, isCancel = false,
    ) => {
        if (!type || isCancel) return;
        const onFinished = ({ type, isCancel, password }) => {
            !activate && this.sendMetricsAndSetUserData(type);
            callback && callback();
            if (type === 'msisdn' && !isCancel) {
                MfaManager.delete3PidSmsBind();
                isChangePhoneNumber && this.openSmsMfaDialog(password);
                MfaManager.getMfaStatus();
                return;
            }
        };
        if (changePhoneNumberPassword) {
            try {
                const resp = MfaManager.setMfaStatus(changePhoneNumberPassword, 'enable', type, isChangePhoneNumber);
                if (resp && resp.success) {
                    onFinished({type});
                }
            } catch (e) {
                console.error(e);
            }
        } else {
            Modal.createDialog(MfaPasswordDialog, {
                isChangePhoneNumber: isChangePhoneNumber,
                isDeactivation: !activate,
                onFinished,
                password: changePhoneNumberPassword,
                type,
            }, 'mx_MfaPasswordDialog');
        }
    }

    renderSubSection = ({ label, button, recommended }) => {
        return <div className="sub-section">
            <div className="label">
                <div className='title-label-container'>
                    <span className="title">{label.title}</span>
                    {recommended && <span className='recommended'>{_t('Recommended')}</span>}
                </div>
                {label.description}
            </div>
            <CitadelButton
                isDisabled={button.disabled}
                isSecondary={button.isSecondary}
                minWidth={117}
                onClick={button.onClick}
                text={button.text}
            />
        </div>;
    };

    render() {
        const { mfaStatus = [], phoneNumber, mfa_mandatory_mode, mfa_methods } = this.state;

        const title = _t('Multi-factor authentication');
        const enableText = _t('Activate');
        const disableText = _t('Deactivate');
        const isMfaEnabled = !isEmpty(mfaStatus);
        const isSMSMfaEnabled = mfaStatus.includes('msisdn');
        const isAppMfaEnabled = mfaStatus.includes('totp');
        const isEmailMfaEnabled = mfaStatus.includes('email');
        const smsSubSection = {
            label: {
                title: _t('Verification by SMS'),
                description: isSMSMfaEnabled ? <span className="description">
                    <span>
                        {_t('Registered mobile phone number:')}
                        <span className="phone-number">{phoneNumber}</span>
                    </span>
                    <span
                        className="phone-number-link"
                        onClick={() => this.toggleMfaStatus('msisdn')(true, noop, true)}
                    >
                        {_t('Modify number')}
                    </span>
                </span> : '',
            },
            button: {
                isSecondary: isSMSMfaEnabled,
                text: isSMSMfaEnabled ? disableText : enableText,
                onClick: () => { isSMSMfaEnabled ? this.toggleMfaStatus('msisdn')() : this.openSmsMfaDialog(); },
            },
            recommended: mfa_methods?.msisdn?.recommendation_level > 0,
        };

        const appSubSection = {
            label: {
                title: _t('Authentication App verification') + (isAppMfaEnabled ? ` ${_t('enabled')}` : ''),
                description: '',
            },
            button: {
                isSecondary: isAppMfaEnabled,
                text: isAppMfaEnabled ? disableText : enableText,
                onClick: () => { isAppMfaEnabled ? this.toggleMfaStatus('totp')() : this.openAppMfaDialog(); },
            },
            recommended: mfa_methods?.totp?.recommendation_level > 0,
        };

        const emailSubSection = {
            label: {
                title: `${_t('Email verification')} ${_t('enabled')}`,
                description: <span className="description">
                    {mfa_mandatory_mode
                        ? <span>
                            {_t('For security reasons, the two-step verification has been activated by your ' +
                                'administrator.')}
                            {' '}
                            {_t('You cannot deactivate it.')}
                          </span>
                        : _t('The deactivation of the email verification automatically leads to the deactivation by SMS and authenticator app.')
                    }
                </span>,
            },
            button: {
                isSecondary: true,
                text: disableText,
                disabled: mfa_mandatory_mode,
                onClick: () => { !mfa_mandatory_mode && this.toggleMfaStatus('email')(); },
            },
            recommended: mfa_methods?.email?.recommendation_level > 0,
        };

        return <div className="mx_MfaSection">
            <div className="header">
                <span>
                    <img src={!isMfaEnabled ? enabledMFASvg : disabledMFASvg} alt="" />
                    <div className="title">{title}</div>
                </span>
                {!isMfaEnabled && <CitadelButton text={enableText} onClick={this.showMfaOptions} minWidth={108} />}
            </div>
            {isMfaEnabled && <div className="sub-sections">
                {(mfa_methods?.email || isEmailMfaEnabled) && this.renderSubSection(emailSubSection)}
                {(mfa_methods?.totp || isAppMfaEnabled) && this.renderSubSection(appSubSection)}
                {(mfa_methods?.msisdn || isSMSMfaEnabled) && this.renderSubSection(smsSubSection)}
            </div>}
        </div>;
    }
}
