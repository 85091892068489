import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import BaseDialog from '../BaseDialog';
import { _t } from '../../../../languageHandler';

export default class KickParticipantDialog extends React.Component {
    static propTypes = {
        onFinished: PropTypes.func.isRequired,
        onKickUser: PropTypes.func.isRequired,
        displayName: PropTypes.string.isRequired,
    };

    static defaultProps = {
        onFinished: noop,
        onKickUser: noop,
        displayName: 'unknown',
    };

    handleOnKickUser = () => {
        const { onFinished, onKickUser } = this.props;

        onKickUser();
        onFinished();
    }

    render() {
        const { displayName, onFinished } = this.props;
        const firstName = displayName.split(' ')[0];
        return (
            <BaseDialog
                onFinished={this.props.onFinished}
                title={_t('conference:Remove user')}
            >
                <div className="mx_Dialog_content" id='mx_Dialog_content'>
                    {_t('Are you sure you want to remove %(firstName)s from this conference ?', { firstName }) }
                </div>
                <div className="mx_Dialog_buttons">
                    <button className="mx_Dialog_secondary" onClick={onFinished} >
                        { _t('Cancel') }
                    </button>
                    <button className="mx_Dialog_primary" onClick={this.handleOnKickUser} >
                        { _t('conference:Remove') }
                    </button>
                </div>
            </BaseDialog>
        );
    }
}
