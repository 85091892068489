import React from "react";
import PropTypes from "prop-types";

import MatrixClientPeg from "../../MatrixClientPeg";
import { _t } from "../../languageHandler";
import LabelledToggleSwitch from "../views/elements/LabelledToggleSwitch";
import CitadelAccordion from "./accordion/CitadelAccordion";
import CitadelAccordionSection from "./accordion/CitadelAccordionSection";

class E2eQualifiedToggle extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            preferredMode: false,
            isEncrypted: false,
            e2eMaxMembers: null,
        };
    }

    async componentDidMount() {
        const { onChange } = this.props;
        const serverConf = await MatrixClientPeg.get().getServerConfig();
        const { e2e: { preferred_mode: preferredMode, users_limit: e2eMaxMembers } } = serverConf || {};
        if (preferredMode) onChange(preferredMode);
        this.setState({
            preferredMode,
            isEncrypted: preferredMode,
            e2eMaxMembers,
        });
    }

    onEncryptionChange = (val) => {
        const { onChange } = this.props;
        this.setState({ isEncrypted: val });
        onChange(val);
    }

    renderWithAdvanced = () => {
        return (
            <CitadelAccordion allowMultipleOpen={false} title="">
                <CitadelAccordionSection label={_t('Advanced options')} id="e2e-section" isOpen>
                {this.renderE2EToggle()}
                </CitadelAccordionSection>
            </CitadelAccordion>
        );
    }

    renderE2EToggle = () => {
        const { classname = '' } = this.props;
        const { isEncrypted, e2eMaxMembers } = this.state;

        return (
            <div className={`e2e-qualified e2e-toggle ${classname}`}>
                <div className="toggle">
                    <LabelledToggleSwitch
                        toggleInFront
                        value={isEncrypted}
                        onChange={this.onEncryptionChange}
                        label={_t('End-to-end encryption')}
                    />
                </div>
                <div className="description e2e">
                    <span>
                        {e2eMaxMembers ?
                            _t('End-to-end conversation encryption is used to add an extra layer of security. ' +
                            'Once activated, you will no longer be able to disable it, ' +
                            'and your room will be limited to up to %(maxMembers)s members.', {
                                maxMembers: e2eMaxMembers,
                            }) :
                            _t('End-to-end conversation encryption is used to add an extra layer of security. ' +
                            'Once activated, you will no longer be able to disable it.')
                        }
                    </span>
                </div>
            </div>
        );
    }

    render() {
        const { withAdvancedSection = false, isVisible = true } = this.props;
        const { preferredMode } = this.state;

        if (!preferredMode || !isVisible) return null;

        return (
            <div className="e2e-qualified">
                {withAdvancedSection ? this.renderWithAdvanced() : this.renderE2EToggle()}
            </div>
        );
    }
}

export default E2eQualifiedToggle;

E2eQualifiedToggle.propTypes = {
    classname: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    isVisible: PropTypes.bool,
    withAdvancedSection: PropTypes.bool,
};
