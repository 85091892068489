import React, { useState, useRef } from 'react';
import Datetime from "react-datetime";

import { getLocalisedMoment } from '../../../DateUtils';
import CalendarIcon from '../../../../res/img/calendar-icon.svg';


const DatePicker = ({ date, onChangeDate, isValidDate, inputProps, withIcon, timeFormat = true }) => {
  const moment = getLocalisedMoment();
  const datePickerRef = useRef();
  const [currentDate, setCurrentDate] = useState(date);
  const [currentView, setCurrentView] = useState(date ? date : moment());
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const toggleCalendar = (openCalendar, closeCalendar) => () => {
    if (isCalendarOpen) {
      closeCalendar();
      setIsCalendarOpen(false);
    } else {
      openCalendar();
      setIsCalendarOpen(true);
    }
  };

  const renderInput = (props, openCalendar, closeCalendar) => {
    return (
      <div className='calendarInput' onClick={toggleCalendar(openCalendar, closeCalendar)}>
          {withIcon ? <img src={CalendarIcon} height={16} width={16} /> : null}
          <span>{currentDate ? currentDate.format("DD MMMM YYYY") : props.placeholder}</span>
      </div>
    );
  };

  const onChangeMonth = (date) => {
    if (date.format('MMMM') !== currentView.format('MMMM')) {
      setCurrentView(moment(currentView.month(date.format('MMMM'))));
    }
  };

  const checkForPresentOrFuturePlanning = (currentDate) => {
    const today = moment();
    return (moment(currentDate).isAfter(today) || moment(currentDate).diff(today, 'days') === 0);
  };

  const renderView = (mode, renderDefault) => {
    return (
      <div className="wrapper">
        <div className='titleContainer'>
          <span className='title'>{currentView ? currentView.format('MMMM YYYY') : ''}</span>
          <div className='arrowsContainer'>
            <div className='leftArrow' onClick={goBack} />
            <div className='rightArrow' onClick={goForth} />
          </div>
        </div>
        {mode === 'days' ? renderDefault() : null}
      </div>
    );
  };

  const goForth = () => {
    // Reset
    const nextMonth = moment(currentView).add(1, 'months');
    datePickerRef.current.setViewDate(nextMonth);
    setCurrentView(moment(nextMonth));
  };

  const goBack = () => {
    const previousMonth = moment(currentView).subtract(1, 'months');
    datePickerRef.current.setViewDate(previousMonth);
    setCurrentView(moment(previousMonth));
  };

  const handleOnChange = (date) => {
    onChangeMonth(date);
    setCurrentDate(date);
    setIsCalendarOpen(false);
    onChangeDate(date);
  };

  const onClose = () => {
    setIsCalendarOpen(false);
  };

  return (
    <div className='calendarWrapper'>
      <Datetime
        closeOnSelect
        initialViewMode='days'
        inputProps={inputProps ? inputProps : {}}
        isValidDate={isValidDate ? isValidDate : checkForPresentOrFuturePlanning}
        locale={moment.locale()}
        onChange={handleOnChange}
        onClose={onClose}
        ref={datePickerRef}
        renderInput={renderInput}
        renderMonth={() => null}
        renderView={renderView}
        renderYear={() => null}
        value={currentDate}
        timeFormat={false}
      />
    </div>
  );
};


export default DatePicker;
