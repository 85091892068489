/*
Copyright 2015, 2016 OpenMarket Ltd
Copyright 2017 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

'use strict';

import ReplyThread from '../elements/ReplyThread';

import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import classNames from 'classnames';
import { EventStatus } from 'matrix-js-sdk';

import { _t, _td } from '../../../languageHandler';
import Modal from '../../../Modal';
import DMRoomMap from '../../../utils/DMRoomMap';

import sdk from '../../../index';
import TextForEvent from '../../../TextForEvent';
import withMatrixClient from '../../../wrappers/withMatrixClient';

import dis from '../../../dispatcher';
import SettingsStore from '../../../settings/SettingsStore';

import UserStore from '../../../stores/UserStore';
import Avatar from '../../../Avatar';
import KeyRequesterDialog from '../dialogs/device_verification/KeyRequesterDialog';
import MatrixClientPeg from '../../../MatrixClientPeg';

import ObjectUtils from '../../../ObjectUtils';
import { checkMessageIntegrity } from '../../../utils/MessageType';

import { ReadReceiptWrapper, getElementPosHintAgainstReadMarkers } from '../elements/ReadReceiptWrapper';
import { ARROW_POS, HintedComponent } from '../elements/HintedComponent';
import AboutE2EButton from '../messages/AboutE2EButton';
import MessageTimestamp from '../messages/MessageTimestamp';
import SenderProfile from '../messages/SenderProfile';
import MemberAvatar from '../avatars/MemberAvatar';
import MessageActionBar from '../messages/MessageActionBar';
import EmojiText from '../elements/EmojiText';
import CitadelButton from "../elements/CitadelButton";

import doneSvg from '../../../../res/img/done.svg';
import * as Rooms from "../../../Rooms";
import checkDark from "../../../../res/img/check-dark.svg";

const eventTileTypes = {
    'm.room.message': 'messages.MessageEvent',
    'm.sticker': 'messages.MessageEvent',
    'm.call.invite': 'messages.TextualEvent',
    'm.call.answer': 'messages.TextualEvent',
    'm.call.hangup': 'messages.TextualEvent',
};

const stateEventTileTypes = {
    'm.room.aliases': 'messages.TextualEvent',
    // 'm.room.aliases': 'messages.RoomAliasesEvent', // too complex
    'm.room.canonical_alias': 'messages.TextualEvent',
    'm.room.create': 'messages.TextualEvent',
    'm.room.member': 'messages.TextualEvent',
    'm.room.name': 'messages.TextualEvent',
    'm.room.avatar': 'messages.RoomAvatarEvent',
    'm.room.third_party_invite': 'messages.TextualEvent',
    'm.room.history_visibility': 'messages.TextualEvent',
    'm.room.encryption': 'messages.TextualEvent',
    'm.room.topic': 'messages.TextualEvent',
    'm.room.power_levels': 'messages.TextualEvent',
    'm.room.pinned_events': 'messages.TextualEvent',
    'm.room.server_acl': 'messages.TextualEvent',
    'im.vector.modular.widgets': 'messages.TextualEvent',
    'm.room.tombstone': 'messages.TextualEvent',
    'm.room.join_rules': 'messages.TextualEvent',
    // 'm.room.guest_access': 'messages.TextualEvent',
    'm.room.related_groups': 'messages.TextualEvent',
    'citadel.conference': 'messages.ConferenceEvent',
};

function getHandlerTile(ev) {
    const type = ev.getType();
    return ev.isState() ? stateEventTileTypes[type] : eventTileTypes[type];
}

// Our component structure for EventTiles on the timeline is:
//
// .-EventTile------------------------------------------------.
// | MemberAvatar (SenderProfile)                   TimeStamp |
// |    .-{Message,Textual}Event---------------. Read Avatars |
// |    |   .-MFooBody-------------------.     |              |
// |    |   |  (only if MessageEvent)    |     |              |
// |    |   '----------------------------'     |              |
// |    '--------------------------------------'              |
// '----------------------------------------------------------'

const EventTile = withMatrixClient(class extends React.Component {
    static displayName = 'EventTile';

    static propTypes = {
        /* MatrixClient instance for sender verification etc */
        matrixClient: PropTypes.object.isRequired,

        /* the MatrixEvent to show */
        mxEvent: PropTypes.object.isRequired,

        /* true if mxEvent is redacted. This is a prop because using mxEvent.isRedacted()
         * might not be enough when deciding shouldComponentUpdate - prevProps.mxEvent
         * references the same this.props.mxEvent.
         */
        isRedacted: PropTypes.bool,

        /* true if this is a continuation of the previous event (which has the
         * effect of not showing another avatar/displayname
         */
        continuation: PropTypes.bool,

        /* true if this is the last event in the timeline (which has the effect
         * of always showing the timestamp)
         */
        isLastEvent: PropTypes.bool,

        /* true if this is search context (which has the effect of greying out
         * the text
         */
        contextual: PropTypes.bool,

        /* a list of words to highlight, ordered by longest first */
        highlights: PropTypes.array,

        /* link URL for the highlights */
        highlightLink: PropTypes.string,

        /* should show URL previews for this event */
        showUrlPreview: PropTypes.bool,

        /* is this the focused event */
        isSelectedEvent: PropTypes.bool,

        /* callback called when dynamic content in events are loaded */
        onHeightChanged: PropTypes.func,

        /* a list of read-receipts we should show. Each object has a 'roomMember' and 'ts'. */
        readReceipts: PropTypes.arrayOf(PropTypes.object),

        /* opaque readreceipt info for each userId; used by ReadReceiptMarker
         * to manage its animations. Should be an empty object when the room
         * first loads
         */
        readReceiptMap: PropTypes.object,

        /* A function which is used to check if the parent panel is being
         * unmounted, to avoid unnecessary work. Should return true if we
         * are being unmounted.
         */
        checkUnmounting: PropTypes.func,

        /* the status of this event - ie, mxEvent.status. Denormalised to here so
         * that we can tell when it changes. */
        eventSendStatus: PropTypes.string,

        forExport: PropTypes.bool,

        /* the shape of the tile. by default, the layout is intended for the
         * normal room timeline.  alternative values are: "file_list", "file_grid"
         * and "notif".  This could be done by CSS, but it'd be horribly inefficient.
         * It could also be done by subclassing EventTile, but that'd be quite
         * boiilerplatey.  So just make the necessary render decisions conditional
         * for now.
         */
        tileShape: PropTypes.string,

        // show twelve hour timestamps
        isTwelveHour: PropTypes.bool,

        /* tell us if this event is one of the very first events displayed when a room is created
         * for the moment, we suppose that followsCreateEvent is true if previous event type is equal to 'm.room.create'
         */
        followsCreateEvent: PropTypes.bool,

        // true if a request was made from this device
        hasSentE2eRetrievalEvent: PropTypes.bool,

        // number of joined members
        joinedMemberCount: PropTypes.number,
    };

    static defaultProps = {
        // no-op function because onHeightChanged is optional yet some sub-components assume its existence
        onHeightChanged: function() {},
        hasSentE2eRetrievalEvent: false,
        forExport: false,
    };

    state = {
        // Whether the action bar is focused.
        actionBarFocused: false,
        // Whether the event's sender has been verified.
        verified: null,
        // Whether onRequestKeysClick has been called since mounting.
        previouslyRequestedKeys: false,
        // Avatar url
        memberAvatar: null,
        // next values are moved to the component State because the calculation of memberAvatar requires
        // avatarSize and the same logic as needsSenderProfile, so by doing this there will be no duplicated
        // code and logic
        // Avatar size
        avatarSize: 0,
        // Whether the event has a sender or is a room event
        needsSenderProfile: null,
        // Info messages are basically information about commands processed on a room
        isInfoMessage: null,
        // Whether the read markers related to the event (if existent) are hinted or not (event must be the last one)
        isHintDisplayed: false,
        isIntegral: false,
    };

    async componentDidMount() {
        this._suppressReadReceiptAnimation = true;
        this.unmounted = false;
        this._suppressReadReceiptAnimation = false;
        const { matrixClient, mxEvent } = this.props;

        matrixClient && matrixClient.on("deviceVerificationChanged", this.onDeviceVerificationChanged);
        mxEvent && mxEvent.on("Event.decrypted", this._onDecrypted);

        this.userStoreToken = UserStore.addListener(this.setEventPartialInfo);
        this.setEventPartialInfo();
        this.dispatcherRef = dis.register(this.onAction);
        this.hintDebouncer = debounce(this.calculateIsHintDisplayed, 2000);

        if (mxEvent.isDecryptionFailure()) {
            this.loadDevices();
        }
        await this._verifyEvent(this.props.mxEvent);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!ObjectUtils.shallowEqual(this.state, nextState)) {
            return true;
        }

        return !this._propsEqual(this.props, nextProps);
    }

    componentDidUpdate(prevProps) {
        // re-check the sender verification as outgoing events progress through
        // the send process.
        if (prevProps.eventSendStatus !== this.props.eventSendStatus) {
            this._verifyEvent(this.props.mxEvent);
        }
        const isIntegral = checkMessageIntegrity(this.props.mxEvent);
        if (isIntegral !== this.state.isIntegral) {
            this.setState({
                isIntegral: isIntegral,
            });
        }
    }

    componentWillUnmount() {
        this.unmounted = true;
        const { matrixClient, mxEvent } = this.props;
        matrixClient.removeListener("deviceVerificationChanged", this.onDeviceVerificationChanged);
        mxEvent.removeListener("Event.decrypted", this._onDecrypted);

        this.userStoreToken.remove();
        dis.unregister(this.dispatcherRef);
        this.hintDebouncer = null;
    }

    onAction = (payload) => {
        if (payload.action === 'message_panel_update' &&
            payload.roomId === this.props.mxEvent.getRoomId() &&
            this.props.isLastEvent) {
            this.hintDebouncer();
        }
    };

    onRoomStateEvents = (event) => {
        const { room, roomId } = this.state;
        if (!room || event.getRoomId() !== roomId) {
            return;
        }

        if (event.getType() === 'citadel.keyrequest'
            && room.getMyMembership() === 'join'
            && this.props.mxEvent.isDecryptionFailure()
        ) {
            const lastEvent = Rooms.getLastEventByType(room, event.getType());
            this.setState({ encryptionKeysRequest: lastEvent ? lastEvent.getContent(): event.getContent()});
        }
    }

    setEventPartialInfo = () => {
        const { continuation, mxEvent, tileShape } = this.props;
        const tileHandler = getHandlerTile(this.props.mxEvent);
        const eventType = this.props.mxEvent.getType();
        // Info messages are basically information about commands processed on a room
        const isInfoMessage = (
            eventType !== 'm.room.message' && eventType !== 'm.sticker'
        );
        let avatarSize;
        let needsSenderProfile;

        if (tileShape === "notif") {
            avatarSize = 24;
            needsSenderProfile = true;
        } else if (tileHandler === 'messages.RoomCreate') {
            avatarSize = 0;
            needsSenderProfile = false;
        } else if (isInfoMessage) {
            // a small avatar, with no sender profile, for
            // joins/parts/etc
            avatarSize = 14;
            needsSenderProfile = false;
        } else if (continuation && tileShape !== "file_grid") {
            // no avatar or sender profile for continuation messages
            avatarSize = 0;
            needsSenderProfile = false;
        } else {
            avatarSize = 24;
            needsSenderProfile = true;
        }

        const memberFromStore = UserStore.getUserById(mxEvent.getSender());
        const memberAvatar = memberFromStore && memberFromStore.avatarUrl &&
            Avatar.avatarUrlForUser({avatarUrl: memberFromStore.avatarUrl}, avatarSize, avatarSize);

        if (!this.unmounted && (this.state.avatarSize !== avatarSize || this.state.memberAvatar !== memberAvatar
            || this.state.needsSenderProfile !== needsSenderProfile || this.state.isInfoMessage !== isInfoMessage)) {
            this.setState({ avatarSize, memberAvatar, needsSenderProfile, isInfoMessage });
        }
    };

    loadDevices = () => {
        const { matrixClient } = this.props;

        matrixClient.getDevices().done(
            res => {
                const myDeviceId = matrixClient.getDeviceId();

                const filteredDevices = res.devices
                    .filter(({ display_name: displayName,
                                 device_id: deviceId }) => (deviceId !== myDeviceId && displayName))
                    .sort((firstEl, secondEl) => secondEl.last_seen_ts - firstEl.last_seen_ts);
                if (!this.unmounted) {
                    this.setState({devices: filteredDevices || []});
                }
            },
            () => {console.log('Unable to load device list');},
        );
    };

    /** called when the event is decrypted after we show it.
     */
    _onDecrypted = () => {
        // we need to re-verify the sending device.
        // (we call onHeightChanged in _verifyEvent to handle the case where decryption
        // has caused a change in size of the event tile)
        this._verifyEvent(this.props.mxEvent);
        this.forceUpdate();
    };

    onDeviceVerificationChanged = (userId, device) => {
        if (userId === this.props.mxEvent.getSender()) {
            this._verifyEvent(this.props.mxEvent);
        }
    };

    _verifyEvent = async (mxEvent) => {
        if (!mxEvent.isEncrypted()) {
            return;
        }

        const verified = await this.props.matrixClient.isEventSenderVerified(mxEvent);
        if (!this.unmounted) {
            this.setState({
                verified: verified,
            }, () => {
                // Decryption may have caused a change in size
                this.props.onHeightChanged();
            });
        }
    };

    _propsEqual = (objA, objB) => {
        const keysA = Object.keys(objA);
        const keysB = Object.keys(objB);

        if (keysA.length !== keysB.length) {
            return false;
        }

        for (let i = 0; i < keysA.length; i++) {
            const key = keysA[i];

            if (!objB.hasOwnProperty(key)) {
                return false;
            }

            // need to deep-compare readReceipts
            if (key === 'readReceipts') {
                const rA = objA[key];
                const rB = objB[key];
                if (rA === rB) {
                    continue;
                }

                if (!rA || !rB) {
                    return false;
                }

                if (rA.length !== rB.length) {
                    return false;
                }
                for (let j = 0; j < rA.length; j++) {
                    if (rA[j].userId !== rB[j].userId) {
                        return false;
                    }
                    // one has a member set and the other doesn't?
                    if (rA[j].roomMember !== rB[j].roomMember) {
                        return false;
                    }
                }
            } else {
                if (objA[key] !== objB[key]) {
                    return false;
                }
            }
        }
        return true;
    };

    shouldHighlight = () => {
        const actions = this.props.matrixClient.getPushActionsForEvent(this.props.mxEvent);
        if (!actions || !actions.tweaks || this.props.forExport) { return false; }

        // don't show self-highlights from another of our clients
        if (this.props.mxEvent.getSender() === this.props.matrixClient.credentials.userId) {
            return false;
        }

        return actions.tweaks.highlight;
    };

    calculateIsHintDisplayed = () => {
        // depends on related flags AND absolute pos of highlighted read markers (should be in the visible part
        // of the timeline)
        const { mxEvent, isLastEvent, matrixClient } = this.props;

        const registrationDateEvent = matrixClient.getAccountData('citadel.registration');
        const registrationDate = registrationDateEvent && registrationDateEvent.getContent().date;
        const hoursSinceRegistration = Math.abs(new Date(registrationDate).getTime() - new Date().getTime())
            / 1000 / 3600;

        const initialConditions = hoursSinceRegistration > 24 &&
            !matrixClient.getInAppMessageFlag('has_shown_read_receipt_web');
        const { isReadMarkerVisible, shouldElementBeAbove } = getElementPosHintAgainstReadMarkers(mxEvent.getTs());

        const isHintDisplayed = initialConditions && isLastEvent && isMessageEvent(mxEvent) && isReadMarkerVisible;
        if (isHintDisplayed) {
            const bottom = shouldElementBeAbove ? 20 : 'unset';
            const top = !shouldElementBeAbove ? 40 : 'unset';

            const hintArrow = { isDisplayed: true, position: shouldElementBeAbove ? ARROW_POS.BOTTOM_RIGHT :
                    ARROW_POS.TOP_RIGHT };
            if (!this.unmounted) {
                this.setState({
                    isHintDisplayed,
                    hintStyle: { top, bottom, right: -136, position: 'absolute' },
                    hintArrow,
                });
            }
        }
    };

    getReadAvatars = () => {
        const { mxEvent, readReceipts, readReceiptMap,
            checkUnmounting, isTwelveHour } = this.props;
        const { allReadAvatars, isHintDisplayed, hintStyle, hintArrow } = this.state;
        const eventTs = mxEvent.getTs();

        // return early if there are no read receipts
        if (!readReceipts || readReceipts.length === 0) {
            return <span className="mx_EventTile_readAvatars" />;
        }

        return <HintedComponent
            className={`hinted_read_marker read_marker_${eventTs}`}
            flag='has_shown_read_receipt_web'
            isHintDisplayed={isHintDisplayed}
            hintInfo={{
                title: _t('Wondering if your colleague read your message correctly?'),
                description: _t('If your colleague has read your message, his or her profile picture will be displayed in thumbnail below your last message.'),
            }}
            hintStyle={hintStyle}
            hintArrow={hintArrow}
        >
            <ReadReceiptWrapper
                eventTs={eventTs}
                receipts={readReceipts}
                readReceiptMap={readReceiptMap}
                checkUnmounting={checkUnmounting}
                isTwelveHour={isTwelveHour}
                allReadAvatars={allReadAvatars}
            />
        </HintedComponent>;
    };

    onSenderProfileClick = (event) => {
        const mxEvent = this.props.mxEvent;
        dis.dispatch({
            action: 'insert_mention',
            user_id: mxEvent.getSender(),
        });
    };

    onCryptoClicked = (e) => {
        const event = this.props.mxEvent;

        Modal.createTrackedDialogAsync('Encrypted Event Dialog', '',
            import('../../../async-components/views/dialogs/EncryptedEventDialog'),
            {event},
        );
    };

    onRequestKeysClick = () => {
        const { mxEvent } = this.props;
        const { devices } = this.state;

        MatrixClientPeg.get().trackUserAction({
            formId: 'requestE2EKeys',
            version: 1,
            action: 'ask',
        });
        MatrixClientPeg.get().trackUserAction({
            formId: 'E2EKeysSharingInRoom',
            version: 1,
            action: 'mydevice',
            room: mxEvent.getRoomId(),
        });
        devices.length && Modal.createDialog(
            KeyRequesterDialog,
            { devices, mxEvent: this.props.mxEvent },
            'key_verification_modal',
            /*isPriority=*/false,
            /*isStatic=*/true,
        );
    };

    onRequestRoomKeysClick = async () => {
        const { mxEvent } = this.props;
        const cli = MatrixClientPeg.get();
        const myDeviceId = cli.getDeviceId();
        try {
            const roomId = mxEvent.getRoomId();
            await cli.sendRoomKeyRequest(roomId, myDeviceId, mxEvent.getId());
            dis.dispatch({
                action: 'add_toast',
                value: {
                    hideCloseButton: true,
                    icon: checkDark,
                    message: _t('Requests have been sent for all the encrypted messages'),
                },
            });
            MatrixClientPeg.get().trackUserAction({
                formId: 'E2EKeysSharingInRoom',
                version: 1,
                action: 'request',
                room: roomId,
            });
        } catch (e) {
            console.log('Can not request encryption keys', e.message);
        }
    };

    onPermalinkClicked = (e) => {
        // This allows the permalink to be opened in a new tab/window or copied as
        // matrix.to, but also for it to enable routing within Riot when clicked.
        e.preventDefault();
        dis.dispatch({
            action: 'view_room',
            event_id: this.props.mxEvent.getId(),
            highlighted: true,
            room_id: this.props.mxEvent.getRoomId(),
        });
    };

    _renderE2EPadlock = () => {
        const ev = this.props.mxEvent;
        const props = {onClick: this.onCryptoClicked};

        // event could not be decrypted
        if (ev.getContent().msgtype === 'm.bad.encrypted') {
            return <E2ePadlockUndecryptable {...props} />;
        }

        // event is encrypted, display padlock corresponding to whether or not it is verified
        if (ev.isEncrypted()) {
            if (this.state.verified) {
                return; // no icon for verified
            } else {
                return (<E2ePadlockUnverified {...props} />);
            }
        }

        if (this.props.matrixClient.isRoomEncrypted(ev.getRoomId())) {
            // else if room is encrypted
            // and event is being encrypted or is not_sent (Unknown Devices/Network Error)
            if (ev.status === EventStatus.ENCRYPTING) {
                return;
            }
            if (ev.status === EventStatus.NOT_SENT) {
                return;
            }
            // if the event is not encrypted, but it's an e2e room, show the open padlock
            return <E2ePadlockUnencrypted {...props} />;
        }

        // no padlock needed
        return null;
    };

    onActionBarFocusChange = (focused) => {
        if (!this.unmounted) {
            this.setState({
                actionBarFocused: focused,
            });
        }
    };

    getTile = () => {
        return this.refs.tile;
    };

    getReplyThread = () => {
        return this.refs.replyThread;
    };

    render() {
        const { avatarSize, needsSenderProfile, memberAvatar, isInfoMessage, isIntegral } = this.state;
        const { forExport, joinedMemberCount } = this.props;
        if (!isIntegral && !forExport) {
            return null;
        }
        const room = this.props.matrixClient.getRoom(this.props.mxEvent.getRoomId());
        const dmRoomMap = DMRoomMap.shared();
        const otherUserId = dmRoomMap.getUserIdForRoomId(this.props.mxEvent.getRoomId());
        const userData = UserStore.getUserById(otherUserId);

        //console.log("EventTile showUrlPreview for %s is %s", this.props.mxEvent.getId(), this.props.showUrlPreview);

        const content = this.props.mxEvent.getContent();
        const msgtype = content.msgtype;

        const tileHandler = getHandlerTile(this.props.mxEvent);
        // This shouldn't happen: the caller should check we support this type
        // before trying to instantiate us
        if (!tileHandler) {
            const {mxEvent} = this.props;
            console.warn(`Event type not supported: type:${mxEvent.getType()} isState:${mxEvent.isState()}`);
            return <div className="mx_EventTile mx_EventTile_info mx_MNoticeBody">
                     <div className="mx_EventTile_line">
                         { _t('This event could not be displayed') }
                     </div>
            </div>;
        }
        const EventTileType = sdk.getComponent(tileHandler);

        const isSending = (['sending', 'queued', 'encrypting'].indexOf(this.props.eventSendStatus) !== -1);
        const isRedacted = isMessageEvent(this.props.mxEvent) && this.props.isRedacted;
        const isEncryptionFailure = this.props.mxEvent.isDecryptionFailure();
        const isConference = this.props.mxEvent.getType() === 'citadel.conference';

        const classes = classNames({
            mx_EventTile: true,
            mx_EventTile_info: isInfoMessage,
            mx_EventTile_12hr: this.props.isTwelveHour,
            mx_EventTile_sending: isSending,
            mx_EventTile_notSent: this.props.eventSendStatus === 'not_sent',
            mx_EventTile_highlight: false,
            mx_EventTile_selected: this.props.isSelectedEvent,
            mx_EventTile_continuation: this.props.tileShape ? '' : this.props.continuation,
            mx_EventTile_last: this.props.isLastEvent,
            mx_EventTile_contextual: this.props.contextual,
            mx_EventTile_actionBarFocused: this.state.actionBarFocused,
            mx_EventTile_bad: isEncryptionFailure,
            mx_EventTile_emote: msgtype === 'm.emote',
            mx_EventTile_redacted: isRedacted,
            mx_eventTile_conference: isConference,
        });

        let permalink = "#";
        if (this.props.permalinkCreator) {
            permalink = this.props.permalinkCreator.forEvent(this.props.mxEvent.getId());
        }

        const readAvatars = this.getReadAvatars();
        let sender;
        const avatar = this.props.mxEvent.sender && avatarSize && !isInfoMessage && !isConference ? (
                <div className="mx_EventTile_avatar">
                    <MemberAvatar member={this.props.mxEvent.sender}
                                  memberAvatar={memberAvatar}
                                  width={avatarSize} height={avatarSize}
                                  viewUserOnClick={true}
                                  forExport={forExport}
                    />
                </div>) : null;

        if (needsSenderProfile || (forExport && ['m.image', 'm.video', 'm.file'].includes(msgtype))) {
            let text = null;
            if (!this.props.tileShape || this.props.tileShape === 'reply' || this.props.tileShape === 'reply_preview') {
                if (msgtype === 'm.image') text = _td('%(senderName)s sent an image');
                else if (msgtype === 'm.video') text = _td('%(senderName)s sent a video');
                else if (msgtype === 'm.file') text = _td('%(senderName)s uploaded a file');
                sender = <SenderProfile onClick={this.onSenderProfileClick}
                                        mxEvent={this.props.mxEvent}
                                        enableFlair={!text}
                                        text={text} />;
            } else {
                sender = <SenderProfile mxEvent={this.props.mxEvent} enableFlair={true} />;
            }
        }
        const showMessageActionBar = !forExport;
        const actionBar = showMessageActionBar ? <MessageActionBar
            mxEvent={this.props.mxEvent}
            permalinkCreator={this.props.permalinkCreator}
            getTile={this.getTile}
            getReplyThread={this.getReplyThread}
            onFocusChange={this.onActionBarFocusChange}
        /> : null;

        const timestamp = this.props.mxEvent.getTs() ?
            <MessageTimestamp showTwelveHour={this.props.isTwelveHour} ts={this.props.mxEvent.getTs()} /> : null;

        // This message is encrypted.
        const { devices = [] } = this.state;

        // todo: change value based on event

        const requestFromMyDevicesBtn = devices.length
            ? <CitadelButton
                isSecondary
                onClick={this.onRequestKeysClick}
                text={_t("Use my devices")}
              />
            : null;

        const keyRequestInfoContent = (
            <div className={
                classNames(
                    "ctdl_encrypted_message",
                    { can_not_req_keys: (!devices.length || joinedMemberCount === 1)})
                }
            >
                <div className="label">
                    {`${_t('This message is encrypted.')} `
                        + `${devices.length || joinedMemberCount > 1 ? _t('To decrypt it :') : ''}` }
                </div>
                <div className="button_container">
                    {requestFromMyDevicesBtn}
                    {joinedMemberCount > 1
                        ? this.props.hasSentE2eRetrievalEvent
                            ? <div className="room_request_sent">
                                <img className="done_icon" src={doneSvg} alt="request-sent-icon" />
                                {_t("Request sent")}
                              </div>
                            : <span className='request_keys_from_room' onClick={this.onRequestRoomKeysClick}>{userData?.displayName ? _t("Make a request to %(userName)s", { userName: userData.displayName }) :
                            _t("Make a request to the room")}</span>
                        : null
                    }
                </div>
            </div>
        );

        const keyRequestInfo = isEncryptionFailure ?
            <div className="mx_EventTile_keyRequestInfo">
                <span className="mx_EventTile_keyRequestInfo_text">
                    { keyRequestInfoContent }
                    <AboutE2EButton eventTs={this.props.mxEvent.getTs()} roomId={room.roomId} />
                </span>
            </div> : null;

        switch (this.props.tileShape) {
            case 'notif': {
                const room = this.props.matrixClient.getRoom(this.props.mxEvent.getRoomId());
                return (
                    <div className={classes}>
                        <div className="mx_EventTile_roomName">
                            <EmojiText element="a" href={permalink} onClick={this.onPermalinkClicked}>
                                { room ? room.name : '' }
                            </EmojiText>
                        </div>
                        <div className="mx_EventTile_senderDetails">
                            { avatar }
                            <a href={permalink} onClick={this.onPermalinkClicked}>
                                { sender }
                                { timestamp }
                            </a>
                        </div>
                        <div className="mx_EventTile_line" >
                            <EventTileType
                                forExport={forExport}
                                highlightLink={this.props.highlightLink}
                                highlights={this.props.highlights}
                                mxEvent={this.props.mxEvent}
                                onHeightChanged={this.props.onHeightChanged}
                                ref="tile"
                                showUrlPreview={this.props.showUrlPreview}
                            />
                        </div>
                    </div>
                );
            }
            case 'file_grid': {
                return (
                    <div className={classes}>
                        <div className="mx_EventTile_line" >
                            <EventTileType
                                forExport={forExport}
                                highlightLink={this.props.highlightLink}
                                highlights={this.props.highlights}
                                mxEvent={this.props.mxEvent}
                                onHeightChanged={this.props.onHeightChanged}
                                ref="tile"
                                showUrlPreview={this.props.showUrlPreview}
                                tileShape={this.props.tileShape}
                            />
                        </div>
                        <a
                            className="mx_EventTile_senderDetailsLink"
                            href={permalink}
                            onClick={this.onPermalinkClicked}
                        >
                            <div className="mx_EventTile_senderDetails">
                                { sender }
                                { timestamp }
                            </div>
                        </a>
                    </div>
                );
            }

            case 'reply':
            case 'reply_preview': {
                let thread;
                if (this.props.tileShape === 'reply_preview') {
                    thread = ReplyThread.makeThread(
                        this.props.mxEvent,
                        this.props.onHeightChanged,
                        this.props.permalinkCreator,
                        'replyThread',
                    );
                }
                return (
                    <div className={classes}>
                        { avatar }
                        { sender }
                        <div className="mx_EventTile_reply">
                            <a href={permalink} onClick={this.onPermalinkClicked}>
                                { timestamp }
                            </a>
                            { thread }
                            <EventTileType
                                forExport={forExport}
                                highlightLink={this.props.highlightLink}
                                highlights={this.props.highlights}
                                mxEvent={this.props.mxEvent}
                                onHeightChanged={this.props.onHeightChanged}
                                ref="tile"
                                showUrlPreview={false}
                            />
                        </div>
                    </div>
                );
            }
            default: {
                const thread = ReplyThread.makeThread(
                    this.props.mxEvent,
                    this.props.onHeightChanged,
                    this.props.permalinkCreator,
                    'replyThread',
                );
                return (
                    <div className={classes}>
                        { readAvatars }
                        { sender }
                        <div className="mx_EventTile_line">
                            <a href={permalink} onClick={this.onPermalinkClicked}>
                                { !isInfoMessage && !isEncryptionFailure && timestamp }
                            </a>
                            { thread }
                            {!isEncryptionFailure &&
                                <EventTileType
                                    followsCreateEvent={this.props.followsCreateEvent}
                                    forExport={forExport}
                                    highlightLink={this.props.highlightLink}
                                    highlights={this.props.highlights}
                                    isTwelveHour={this.props.isTwelveHour}
                                    mxEvent={this.props.mxEvent}
                                    onHeightChanged={this.props.onHeightChanged}
                                    ref="tile"
                                    showUrlPreview={this.props.showUrlPreview}
                                />
                            }
                            { keyRequestInfo }
                            { !isInfoMessage && !isEncryptionFailure && actionBar }
                        </div>
                        {
                            // The avatar goes after the event tile as it's absolutly positioned to be over the
                            // event tile line, so needs to be later in the DOM so it appears on top (this avoids
                            // the need for further z-indexing chaos)
                        }
                        { avatar }
                    </div>
                );
            }
        }
    }
});

// XXX this'll eventually be dynamic based on the fields once we have extensible event types
const messageTypes = ['m.room.message', 'm.sticker'];
function isMessageEvent(ev) {
    return (messageTypes.includes(ev.getType()));
}

EventTile.haveTileForEvent = function(e) {
    // Only messages have a tile (black-rectangle) if redacted
    if (e.isRedacted() && !isMessageEvent(e)) return false;

    const handler = getHandlerTile(e);
    if (handler === undefined) return false;
    if (handler === 'messages.TextualEvent') {
        return TextForEvent.textForEvent(e) !== '';
    } else if (handler === 'messages.RoomCreate') {
        return Boolean(e.getContent()['predecessor']);
    } else {
        return true;
    }
};

function E2ePadlockUndecryptable(props) {
    return (
        <E2ePadlock title={_t("Undecryptable")} icon="undecryptable" {...props} />
    );
}

function E2ePadlockUnverified(props) {
    return (
        <E2ePadlock title={_t("Encrypted by an unverified device")} icon="unverified" {...props} />
    );
}

function E2ePadlockUnencrypted(props) {
    return (
        <E2ePadlock title={_t("Unencrypted message")} icon="unencrypted" {...props} />
    );
}

function E2ePadlock(props) {
    if (SettingsStore.getValue("alwaysShowEncryptionIcons")) {
        return (<div
                    className={`mx_EventTile_e2eIcon mx_EventTile_e2eIcon_${props.icon}`}
                    title={props.title} onClick={props.onClick} />);
    } else {
        return (<div
                    className={`mx_EventTile_e2eIcon mx_EventTile_e2eIcon_hidden mx_EventTile_e2eIcon_${props.icon}`}
                    onClick={props.onClick} />);
    }
}

export default EventTile;
