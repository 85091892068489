/*
Copyright 2015, 2016 OpenMarket Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Truncate from 'react-truncate';
import { isEmpty } from 'lodash';

import sdk from '../../../index';
import { _t } from '../../../languageHandler';
import MatrixClientPeg from '../../../MatrixClientPeg';
import PlatformPeg from '../../../PlatformPeg';
import rateLimitedFunc from '../../../ratelimitedfunc';
import dis from "../../../dispatcher";

import { linkifyElement } from '../../../HtmlUtils';
import AccessibleButton from '../elements/AccessibleButton';
import { CancelButton } from './SimpleRoomHeader';
import SettingsStore from '../../../settings/SettingsStore';
import RoomHeaderButtons from '../right_panel/RoomHeaderButtons';
import { isDirectMessageRoom } from '../../../Rooms';
import UserStore from '../../../stores/UserStore';
import CitadexStore, { TYPE_AUDIO, TYPE_VIDEO } from '../../../stores/CitadexStore';
import Modal from '../../../Modal';
import RequestDialog from '../dialogs/conference/RequestDialog';
import { getLastEventByType } from '../../../Rooms';
import { ERROR_TYPES, errorDialog } from "../janus/ErrorDialogsHelper";
import ConferenceOptions from '../janus/ConferenceOptions';
import ConferencePlanDialog from '../janus/ConferencePlanDialog';
import GenericToolTip from '../../structures/GenericToolTip';


import searchIcon from '../../../../res/img/feather-customised/search.svg';
import lockIcon from '../../../../res/img/e2e-lock.svg';
import audioIconSmall from '../../../../res/img/conference/audio-small-white.svg';
import videoIconSmall from '../../../../res/img/conference/video-small-white.svg';
import audioConferenceIcon from '../../../../res/img/conference/conference-audio-icon.svg';
import videoConferenceIcon from '../../../../res/img/conference/conference-video-icon.svg';
import calendarIcon from '../../../../res/img/conference/calendar-icon.svg';

const CONFERENCE_ENABLED = ['enabled', 'trial'];

export default class RoomHeader extends Component {
    static displayName = 'RoomHeader'

    static propTypes = {
        room: PropTypes.object,
        oobData: PropTypes.object,
        collapsedRhs: PropTypes.bool,
        onSettingsClick: PropTypes.func,
        onPinnedClick: PropTypes.func,
        onSearchClick: PropTypes.func,
        onLeaveClick: PropTypes.func,
        onCancelClick: PropTypes.func,
        e2eStatus: PropTypes.string,
        myMembership: PropTypes.string,
        isDmRoom: PropTypes.bool,
    }

    static defaultProps = {
        editing: false,
        onCancelClick: null,
    }

    state = {
        shouldDisplayRedMarkerVideo: false,
        shouldDisplayRedMarkerAudio: false,
        callInProgress: null,
        isWebRtcSupported: CitadexStore.checkIfWebRtcIsSupported(),
        conferenceIsOpen: false,
        syncState: MatrixClientPeg.get().getSyncState(),
    }

    componentDidMount() {
        const cli = MatrixClientPeg.get();
        cli.on('RoomState.events', this._onRoomStateEvents);
        cli.on('Room.accountData', this._onRoomAccountData);
        cli.on('accountData', this._onAccountData);
        cli.on("sync", this.onSyncStateChange);
        this.dispatcherRef = dis.register(this.onAction);

        const conferenceRightsEvent = cli.getAccountData('citadex.conference_rights');
        if (conferenceRightsEvent) {
            const content = conferenceRightsEvent.getContent();
            content && this.setState({
                videoRights: CONFERENCE_ENABLED.includes(content.visio),
                audioRights: CONFERENCE_ENABLED.includes(content.bridge),
            });
        }

        // When a room name occurs, RoomState.events is fired *before*
        // room.name is updated. So we have to listen to Room.name as well as
        // RoomState.events.
        if (this.props.room) {
            this.props.room.on('Room.name', this._onRoomNameChange);
        }

        window.addEventListener('storage', this.onStorage);
        this._setConferenceRedMarkersVisibility();
        this._setCallInProgress();
    }

    componentDidUpdate() {
        if (this.refs.topic) {
            linkifyElement(this.refs.topic);
        }
    }

    componentWillUnmount() {
        if (this.props.room) {
            this.props.room.removeListener('Room.name', this._onRoomNameChange);
        }
        window.removeEventListener('storage', this.onStorage);

        const cli = MatrixClientPeg.get();
        if (cli) {
            cli.removeListener('RoomState.events', this._onRoomStateEvents);
            cli.removeListener('Room.accountData', this._onRoomAccountData);
            cli.removeListener('accountData', this._onAccountData);
            cli.removeListener("sync", this.onSyncStateChange);
        }
        if (this.dispatcherRef) {
            dis.unregister(this.dispatcherRef);
        }
    }

    onAction = (payload) => {
        if (payload.action === 'toggle_conference') {
            this.setState({ conferenceIsOpen: payload.isOpen });
        }
    }

    onSyncStateChange = (state, prevState) => {
        if (state === "SYNCING" && prevState === "SYNCING") {
            return;
        }
        this.setState({
            syncState: state,
        });
    };

    _onAccountData = ev => {
        const { event } = ev;
        const content = ev.getContent();
        if (event.type === 'citadel.conference') this._setConferenceRedMarkersVisibility(content);
        if (event.type === 'citadex.conference_rights') {
            content && this.setState({
                videoRights: CONFERENCE_ENABLED.includes(content.visio),
                audioRights: CONFERENCE_ENABLED.includes(content.bridge),
            });
        }
    }

    onStorage = e => {
        if (e.key === 'citadex.isCitadexOpened') this.forceUpdate();
    }
    _setConferenceRedMarkersVisibility = content => {
        const {
            has_clicked_video_call: hasClickedVideoButton = false,
            has_clicked_audio_call: hasClickedAudioButton = false,
        } = content || this.getRedDotConferenceVisibility();

        this.setState({
            shouldDisplayRedMarkerVideo: !hasClickedVideoButton,
            shouldDisplayRedMarkerAudio: !hasClickedAudioButton,
        });
    }

    _setCallInProgress = async () => {
        const { room } = this.props;

        if (!room) return;
        const callInProgressEvent = getLastEventByType(room, 'citadel.conference');
        const content = callInProgressEvent ? callInProgressEvent.getContent() : {};

        if (['open', 'empty'].includes(content.status)) {
            this.setState({ callInProgress: callInProgressEvent });
            CitadexStore.log('call in progress', content);
        }
    }

    _onRoomStateEvents = event => {
        if (!this.props.room || event.getRoomId() !== this.props.room.roomId) {
            return;
        }

        if (event.getType() === 'citadel.conference') {
            const content = event.getContent();
            let callInProgress = null;
            if (['open', 'empty'].includes(content.status)) {
                callInProgress = event;
                CitadexStore.log('call in progress', content);
            }
            this.setState({ callInProgress }, this._rateLimitedUpdate);
            return;
        }

        this._rateLimitedUpdate();
    }

    _onRoomAccountData = (event, room) => {
        if (!this.props.room || room.roomId !== this.props.room.roomId) return;
        if (event.getType() !== 'im.vector.room.read_pins') return;

        this._rateLimitedUpdate();
    }

    _rateLimitedUpdate = rateLimitedFunc(function() {
        /* eslint-disable babel/no-invalid-this */
        this.forceUpdate();
    }, 500)

    _onRoomNameChange = () => {
        this.forceUpdate();
    }

    _hasUnreadPins = () => {
        const currentPinEvent = this.props.room.currentState.getStateEvents('m.room.pinned_events', '');
        if (!currentPinEvent) return false;
        if (currentPinEvent.getContent().pinned && currentPinEvent.getContent().pinned.length <= 0) {
            return false; // no pins == nothing to read
        }

        const readPinsEvent = this.props.room.getAccountData('im.vector.room.read_pins');
        if (readPinsEvent && readPinsEvent.getContent()) {
            const readStateEvents = readPinsEvent.getContent().event_ids || [];
            if (readStateEvents) {
                return !readStateEvents.includes(currentPinEvent.getId());
            }
        }

        // There's pins, and we haven't read any of them
        return true;
    }

    _hasPins = () => {
        const currentPinEvent = this.props.room.currentState.getStateEvents('m.room.pinned_events', '');
        if (!currentPinEvent) return false;

        return !(currentPinEvent.getContent().pinned && currentPinEvent.getContent().pinned.length <= 0);
    }

    getRedDotConferenceVisibility = (eventType = "citadel.conference") => {
        const cli = MatrixClientPeg.get();
        if (!cli) return null;

        const event = cli.getAccountData(eventType);
        if (!event || !event.getContent()) return {};
        return event.getContent();
    }

    setRedMarker = property => {
        const { shouldDisplayRedMarkerAudio, shouldDisplayRedMarkerVideo } = this.state;

        if ((property === TYPE_AUDIO && !shouldDisplayRedMarkerAudio)
            || (property === TYPE_VIDEO && !shouldDisplayRedMarkerVideo)) return;

        const conferenceRedDotVisibility = {
            ...this.getRedDotConferenceVisibility(),
            has_clicked_video_call: property === TYPE_VIDEO ? true : !shouldDisplayRedMarkerVideo,
            has_clicked_audio_call: property === TYPE_AUDIO ? true : !shouldDisplayRedMarkerAudio,
        };

        MatrixClientPeg.get().setAccountData('citadel.conference', conferenceRedDotVisibility);
    }

    checkRightsElectronMac = async type => {
        const platform = PlatformPeg.get();

        if (!platform.isDesktop() || platform.getPlatformFriendlyName() !== 'macOS') return true;

        return await platform.askForMediaAccess(type);
    }

    openConference = () => {
        const conferenceInNewTab = SettingsStore.getValue("Conference.openConferenceInTab");
        if (conferenceInNewTab) {
            let windowReference;
            if (PlatformPeg.get().isDesktop() && !conferenceInNewTab) {
                windowReference = window.open('', 'citadex');
            }
            CitadexStore.onOpenCitadexWindow(windowReference);
        }
    }

    joinCall = async () => {
        const { callInProgress } = this.state;
        const { isDmRoom } = this.props;
        if (CitadexStore.getIsCitadexOpened()) {
            CitadexStore.focusConferenceWindow();
            return;
        }
        const content = callInProgress.getContent();
        const { type } = content;
        const hasNecessaryRights = await this.checkRightsElectronMac(type);
        if (hasNecessaryRights) {
            CitadexStore.prepareDataForConference(
                type, this.props.room.roomId, this.getRoomName(), callInProgress, isDmRoom, type,
            );
            this.openConference();
        } else {
            errorDialog({ type: ERROR_TYPES.NOT_ALLOWED });
        }
    }

    startCall = (type) => async () => {
        const { videoRights, audioRights } = this.state;
        const { isDmRoom } = this.props;
        const accountRights = type === 'audio' ? audioRights : videoRights;
        this.setRedMarker(type);
        if (!accountRights) {
            const { visio, bridge } = await MatrixClientPeg.get().getServices();
            const serviceRights = type === 'audio' ? bridge : visio;
            if (!CONFERENCE_ENABLED.includes(serviceRights)) {
                this.openConferenceRequestModal(type);
                return;
            }
        }
        const hasNecessaryRights = await this.checkRightsElectronMac(type);
        if (hasNecessaryRights) {
            if (type === TYPE_AUDIO) {
                MatrixClientPeg.get().trackUserAction({
                    formId: 'audioConference',
                    version: 1,
                    action: 'start',
                });
            } else if (type === TYPE_VIDEO) {
                MatrixClientPeg.get().trackUserAction({
                    formId: 'videoConference',
                    version: 1,
                    action: 'start',
                });
            }
            CitadexStore.prepareDataForConference(
                type, this.props.room.roomId, this.getRoomName(), undefined, isDmRoom, type,
            );
            this.openConference();
        } else {
            errorDialog({ type: ERROR_TYPES.NOT_ALLOWED });
        }
    }
    getTooManyInCallText = (max) => _t('Sorry, the room has exceeded the maximum number of participants (%(max)s)', { max })

    openConferenceRequestModal = async (type) => {
        const serverConf = await MatrixClientPeg.get().getServerConfig();
        const { trial_license: isTrialLicenseEnabled } = serverConf;

        Modal.createTrackedDialog('Conference Request', '', RequestDialog, {
            maxParticipants: CitadexStore.getMaxParticipants(),
            type,
            isTrialLicenseEnabled,
            onFinished: () => {
                MatrixClientPeg.get().trackUserAction({
                    formId: 'rightsConference',
                    version: 1,
                    action: 'cancel',
                });
            },
        },
            classNames('conferenceRequestDialog', { 'no_trial': !isTrialLicenseEnabled }),
        );
    }

    openConferencePlanModal = () => async () => {
        const { videoRights, audioRights } = this.state;
        if (!videoRights && !audioRights) {
            this.openConferenceRequestModal('conference');
        } else {
            Modal.createTrackedDialog(
                'Conference Planning',
                '',
                ConferencePlanDialog,
                {
                    roomId: this.props.room.roomId,
                    joinedMembersCount: this.props.room.getJoinedMemberCount(),
                    maxParticipants: CitadexStore.getMaxParticipants(),
                },
                'mx_ConferencePlanDialog_wrapper',
            );
            MatrixClientPeg.get().trackUserAction({
                formId: 'planConference',
                version: 1,
                action: 'start',
            });
        }
    }

    renderConferenceButtons = members => {
        const conferenceInNewTab = SettingsStore.getValue("Conference.openConferenceInTab");
        const { video: videoMaxParticipants, audio: audioMaxParticipants } = CitadexStore.getMaxParticipants();

        const { callInProgress, isWebRtcSupported } = this.state;
        const me = members.find(({ user }) => !!user && user.isMe);

        if (!me) return null;

        const janusServer = CitadexStore.getVisioServer();

        const joinedMembersCount = this.props.room.getJoinedMemberCount();

        const { type } = callInProgress ? callInProgress.getContent() : {};
        const isCitadexOpened = CitadexStore.getIsCitadexOpened();
        const isCallOngoing = (isCitadexOpened || this.state.conferenceIsOpen)
            && CitadexStore.getMatrixRoomId();
        const isAnotherCallOngoing = isCallOngoing && isCallOngoing !== this.props.room.roomId;
        const alreadyCallingText = _t('A call is already in progress');
        const conferenceContainerClass = classNames(
            'conference_button_container',
            {
                disabled: isAnotherCallOngoing,
                planAConferenceEnabled: (joinedMembersCount < videoMaxParticipants)
                    || (joinedMembersCount < audioMaxParticipants),
             },
        );

        const conferenceJoinClass = classNames(
            'mx_AccessibleButton_kind_primary',
            'mx_RoomHeader_conference_join_button',
            { disabled: conferenceInNewTab ? isAnotherCallOngoing : isCallOngoing },
        );

        if (
            (isWebRtcSupported && callInProgress && !isAnotherCallOngoing)
            || (isCitadexOpened && !isAnotherCallOngoing)
        ) {
            return <button
                className={conferenceJoinClass}
                disabled={conferenceInNewTab ? isAnotherCallOngoing : isCallOngoing}
                onClick={this.joinCall}
                title={_t(`${isAnotherCallOngoing
                    ? alreadyCallingText
                    : isCallOngoing ? 'Switch to conference' : 'Join the call'}`,
                )}
            >
                <img src={type === TYPE_VIDEO ? videoIconSmall : audioIconSmall} alt="" />
                {_t(`${((!isAnotherCallOngoing && conferenceInNewTab) && isCallOngoing) ? 'Switch to conference' : 'Join the call'}`)}
            </button>;
        }

        if (isWebRtcSupported && janusServer && isEmpty(callInProgress) && joinedMembersCount > 1) {
            const audioCallText = _t('Voice call');
            const videoCallText = _t('Video call');
            const isTheVideoCallDisabled =
                (isCallOngoing && isCallOngoing !== this.props.room.roomId) ||
                joinedMembersCount > videoMaxParticipants;
            const isTheAudioCallDisabled =
                isCallOngoing && isCallOngoing !== this.props.room.roomId ||
                joinedMembersCount > audioMaxParticipants;
            const audioTitle = !isTheAudioCallDisabled ? audioCallText :
                joinedMembersCount > audioMaxParticipants ? this.getTooManyInCallText(audioMaxParticipants) : isAnotherCallOngoing ? alreadyCallingText : "";
            const videoTitle = !isTheVideoCallDisabled ? videoCallText :
                joinedMembersCount > videoMaxParticipants ? this.getTooManyInCallText(videoMaxParticipants) : isAnotherCallOngoing ? alreadyCallingText : "";
            const disabledAudioTooltipLabel =
                joinedMembersCount > audioMaxParticipants ?
                    this.getTooManyInCallText(audioMaxParticipants) : (this.isInternetConnectionError() ? _t('No network connection') : '');
            const disabledVideoTooltipLabel =
                joinedMembersCount > videoMaxParticipants ?
                    this.getTooManyInCallText(videoMaxParticipants) : (this.isInternetConnectionError() ? _t('No network connection') : '');

            return <ConferenceOptions>
                <div>

                    <div className={conferenceContainerClass} title={audioTitle}>
                        <GenericToolTip label={disabledAudioTooltipLabel} labelStyle={"tooltip_start_conference"}>
                            <AccessibleButton
                                onClick={ this.startCall(TYPE_AUDIO)}
                                disabled={joinedMembersCount > audioMaxParticipants || isTheAudioCallDisabled || this.isInternetConnectionError()}
                            >
                                <img src={audioConferenceIcon} />
                                {_t('Audioconference')}
                            </AccessibleButton>
                        </GenericToolTip>
                    </div>

                    <div className={conferenceContainerClass} title={videoTitle}>
                        <GenericToolTip label={disabledVideoTooltipLabel} labelStyle={"tooltip_start_conference"}>
                            <AccessibleButton
                                onClick={this.startCall(TYPE_VIDEO)}
                                disabled={joinedMembersCount > videoMaxParticipants || isTheVideoCallDisabled || this.isInternetConnectionError()}
                            >
                                <img src={videoConferenceIcon} />
                                {_t('Videoconference')}
                            </AccessibleButton>
                        </GenericToolTip>
                    </div>

                    <div className={conferenceContainerClass} >
                        <AccessibleButton
                            onClick={this.openConferencePlanModal()}
                            disabled={(joinedMembersCount > audioMaxParticipants) &&
                                (joinedMembersCount > videoMaxParticipants)}
                        >
                            <img src={calendarIcon} />
                            {_t('Plan a conference')}
                        </AccessibleButton>
                    </div>
                </div>
            </ConferenceOptions>;
        }
    }

    getRoomName = () => {
        const { room, oobData, isDmRoom } = this.props;
        const { dmUserIdOrEmail } = room ? isDirectMessageRoom(room) : {};

        let roomName = _t('Join Room');
        if (oobData && oobData.name) {
            roomName = oobData.name;
        } else {
            if (room) {
                roomName = (isDmRoom && dmUserIdOrEmail[0] === "@") ?
                    UserStore.getDisambiguatedNameWithEmail(dmUserIdOrEmail, room.name) : room.name;
            }
        }

        return roomName;
    }

    isInternetConnectionError = () => {
        return this.state.syncState === 'ERROR';
    }

    render() {
        const RoomAvatar = sdk.getComponent('avatars.RoomAvatar');
        const EmojiText = sdk.getComponent('elements.EmojiText');

        let searchStatus = null;
        let cancelButton = null;
        let pinnedEventsButton = null;

        const { room, e2eStatus, isDmRoom } = this.props;

        if (this.props.onCancelClick) {
            cancelButton = <CancelButton onClick={this.props.onCancelClick} />;
        }

        // don't display the search count until the search completes and
        // gives us a valid (possibly zero) searchCount.
        if (this.props.searchInfo &&
            this.props.searchInfo.searchCount !== undefined &&
            this.props.searchInfo.searchCount !== null) {
            searchStatus = <div className="mx_RoomHeader_searchStatus">&nbsp;
                {_t('(~%(count)s results)', { count: this.props.searchInfo.searchCount })}
            </div>;
        }

        // XXX: this is a bit inefficient - we could just compare room.name for 'Empty room'...
        let settingsHint = false;
        const members = room ? room.getJoinedMembers() : undefined;
        if (members) {
            if (members.length === 1 && members[0].userId === MatrixClientPeg.get().credentials.userId) {
                const nameEvent = room.currentState.getStateEvents('m.room.name', '');
                if (!nameEvent || !nameEvent.getContent().name) {
                    settingsHint = true;
                }
            }
        }
        const roomName = this.getRoomName();
        const emojiTextClasses = classNames('mx_RoomHeader_nametext', { mx_RoomHeader_settingsHint: settingsHint });
        const name =
            <div className="mx_RoomHeader_name" onClick={this.props.onSettingsClick}>
                <Truncate lines={1} width={780}>
                    <EmojiText dir="auto" element="div" className={emojiTextClasses}
                        title={roomName}>{roomName}</EmojiText>
                </Truncate>
                {searchStatus}
            </div>;

        let subName = '';
        if (room) {
            const joinRuleEvent = room.currentState.getStateEvents('m.room.join_rules', '');
            let roomType = joinRuleEvent && joinRuleEvent.getContent().join_rule;

            if (roomType) {
                if (roomType === 'invite') {
                    roomType = isDmRoom ? 'direct' : 'private';
                }

                switch (roomType) {
                    case 'public':
                        subName = e2eStatus ? _t('Encrypted public room') : _t('Public room');
                        break;
                    case 'private':
                        subName = e2eStatus ? _t('Encrypted private room') : _t('Private room');
                        break;
                    case 'direct':
                        subName = e2eStatus ? _t('Encrypted private chat') : _t('Private chat');
                        break;
                }
            }

            const topicEvent = room.currentState.getStateEvents('m.room.topic', '');
            const topic = topicEvent && topicEvent.getContent().topic;

            if (topic) {
                if (subName.length && topic.length) {
                    subName += ' | ';
                }
                subName += topic;
            }
        }

        const topicElement =
            <div className="mx_RoomHeader_topic" ref="topic" title={subName} dir="auto">
                <Truncate lines={1} width={780}>
                    {subName}
                </Truncate>
            </div>;

        const avatarSize = 40;
        let roomAvatar;
        if (room) {
            roomAvatar = (<RoomAvatar
                room={room}
                width={avatarSize}
                height={avatarSize}
                oobData={this.props.oobData}
                viewAvatarOnClick={true} />);
        }

        if (this.props.onPinnedClick && SettingsStore.isFeatureEnabled('feature_pinning')) {
            let pinsIndicator = null;
            if (this._hasUnreadPins()) {
                pinsIndicator = (<div className="mx_RoomHeader_pinsIndicator mx_RoomHeader_pinsIndicatorUnread" />);
            } else if (this._hasPins()) {
                pinsIndicator = (<div className="mx_RoomHeader_pinsIndicator" />);
            }

            pinnedEventsButton =
                <AccessibleButton className="mx_RoomHeader_button mx_RoomHeader_pinnedButton"
                    onClick={this.props.onPinnedClick} title={_t('Pinned Messages')}>
                    {pinsIndicator}
                </AccessibleButton>;
        }

        let searchButton;
        if (this.props.onSearchClick) {
            searchButton =
                <AccessibleButton className="mx_RoomHeader_button mx_RoomHeader_searchButton"
                    onClick={this.props.onSearchClick}
                    title={_t('Search')}
                    disabled={(this.props.myMembership !== 'join')}
                >
                    <img src={searchIcon} className="themed-svg mx_RoomHeader_search_icon" alt="Search" />
                </AccessibleButton>;
        }

        const rightRow =
            <div className="mx_RoomHeader_buttons">
                {pinnedEventsButton}
                {this.renderConferenceButtons(members)}
                {(!e2eStatus) && searchButton}
            </div>;

        const wrapperClasses = classNames('mx_RoomHeader_wrapper', {
            mx_RoomHeader_wrapper_disabled: (this.props.myMembership !== 'join'),
        });
        return (
            <div className="mx_RoomHeader light-panel">
                <div className={wrapperClasses}>
                    <div className="mx_RoomHeader_avatar">
                        {roomAvatar}
                        {e2eStatus && <img src={lockIcon} className="lock" width="16" height="16" alt="" />}
                    </div>
                    <div className="mx_RoomHeader_info">
                        {name}
                        {topicElement}
                    </div>
                    {cancelButton}
                    {rightRow}
                    <RoomHeaderButtons
                        collapsedRhs={this.props.collapsedRhs}
                        onRoomSettingsClick={this.props.onSettingsClick}
                        myMembership={this.props.myMembership}
                    />
                </div>
            </div>
        );
    }
}
