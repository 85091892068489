/*
Copyright 2019 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React from 'react';
import {_t} from "../../../../../languageHandler";
import {SettingLevel} from "../../../../../settings/SettingsStore";
import LabelledToggleSwitch from "../../../elements/LabelledToggleSwitch";
const sdk = require("../../../../..");
const PlatformPeg = require("../../../../../PlatformPeg");

export default class PreferencesUserSettingsTab extends React.Component {
    static COMPOSER_SETTINGS = [
        'MessageComposerInput.autoReplaceEmoji',
        'MessageComposerInput.suggestEmoji',
        // 'sendTypingNotifications',
    ];

    static TIMELINE_SETTINGS = [
        'autoplayGifsAndVideos',
        'urlPreviewsEnabled',
        // 'TextualBody.enableBigEmoji',
        // 'showReadReceipts',
        // 'showTwelveHourTimestamps',
        'alwaysShowTimestamps',
        // 'showRedactions',
        // 'enableSyntaxHighlightLanguageDetection',
        'showJoinLeaves',
        // 'showAvatarChanges',
        // 'showDisplaynameChanges',
    ];

    static ACTIVITY_MESSAGES_SETTINGS = [
        'enabled_private_rooms',
        'enabled_public_read_only_rooms',
    ]

    static ROOM_LIST_SETTINGS = [
        // 'RoomList.orderByImportance',
    ];

    static ADVANCED_SETTINGS = [
        // 'alwaysShowEncryptionIcons',
        // 'Pill.shouldShowPillAvatar',
        // 'TagPanel.enableTagPanel',
        // 'promptBeforeInviteUnknownUsers',
        // Start automatically after startup (electron-only)
        // Autocomplete delay (niche text box)
    ];

    static CONFERENCE_SETTINGS = [
        "Conference.openConferenceInTab",
    ]

    constructor() {
        super();

        this.state = {
            autoLaunch: false,
            autoLaunchSupported: false,
            minimizeToTray: false,
            minimizeToTraySupported: false,
        };
    }

    async componentDidMount(): void {
        const platform = PlatformPeg.get();

        const autoLaunchSupported = await platform.supportsAutoLaunch();
        let autoLaunch = false;

        if (autoLaunchSupported) {
            autoLaunch = await platform.getAutoLaunchEnabled();
        }

        const minimizeToTraySupported = await platform.supportsMinimizeToTray();
        let minimizeToTray = false;

        if (minimizeToTraySupported) {
            minimizeToTray = await platform.getMinimizeToTrayEnabled();
        }

        this.setState({autoLaunch, autoLaunchSupported, minimizeToTraySupported, minimizeToTray});
    }

    _onAutoLaunchChange = (checked) => {
        PlatformPeg.get().setAutoLaunchEnabled(checked).then(() => this.setState({autoLaunch: checked}));
    };

    _onMinimizeToTrayChange = (checked) => {
        PlatformPeg.get().setMinimizeToTrayEnabled(checked).then(() => this.setState({minimizeToTray: checked}));
    };

    _renderGroup(settingIds) {
        let label;
        if (settingIds === PreferencesUserSettingsTab.CONFERENCE_SETTINGS) {
            label = PlatformPeg.get().isDesktop() ? "Open conference in a new window" : "Open conference in a new tab";
        }
        const SettingsFlag = sdk.getComponent("views.elements.SettingsFlag");
        return settingIds.map(i => <SettingsFlag key={i} name={i} level={SettingLevel.ACCOUNT} label={label} />);
    }

    render() {
        let autoLaunchOption = null;
        if (this.state.autoLaunchSupported) {
            autoLaunchOption = <LabelledToggleSwitch value={this.state.autoLaunch}
                                                     onChange={this._onAutoLaunchChange}
                                                     label={_t('Start automatically after system login')} />;
        }

        let minimizeToTrayOption = null;
        if (this.state.minimizeToTraySupported) {
            minimizeToTrayOption = <LabelledToggleSwitch value={this.state.minimizeToTray}
                                                         onChange={this._onMinimizeToTrayChange}
                                                         label={_t('Close button should minimize window to tray')} />;
        }

        const composerSettingsIds = PreferencesUserSettingsTab.COMPOSER_SETTINGS;
        const timelineSettingsIds = PreferencesUserSettingsTab.TIMELINE_SETTINGS;
        const activityMessagesSettingsIds = PreferencesUserSettingsTab.ACTIVITY_MESSAGES_SETTINGS;
        const conferenceSettingsIds = PreferencesUserSettingsTab.CONFERENCE_SETTINGS;
        const roomListSettingsIds = PreferencesUserSettingsTab.ROOM_LIST_SETTINGS;
        const advancedSettingsIds = PreferencesUserSettingsTab.ADVANCED_SETTINGS;

        return (
            <div className="mx_SettingsTab mx_PreferencesUserSettingsTab">
                <div className="mx_SettingsTab_heading">{_t("Preferences")}</div>
                <div className="mx_SettingsTab_section">
                    {composerSettingsIds && composerSettingsIds.length > 0 &&
                    <div className="mx_SettingsTab_section">
                        <span className="mx_SettingsTab_subheading">{_t("Composer")}</span>
                        {this._renderGroup(composerSettingsIds)}
                    </div>
                    }

                    {timelineSettingsIds && timelineSettingsIds.length > 0 &&
                    <div className="mx_SettingsTab_section">
                        <span className="mx_SettingsTab_subheading">{_t("Timeline")}</span>
                        {this._renderGroup(timelineSettingsIds)}
                    </div>
                    }

                    {activityMessagesSettingsIds && activityMessagesSettingsIds.length > 0 &&
                    <div className="mx_SettingsTab_section">
                        <span className="mx_SettingsTab_subheading">{_t("Activity Messages")}</span>
                        <div className='activity-message-info'>
                            <span>{_t('Activity messages show actions happening in a room such as members joining and leaving.')}</span>
                        </div>
                        {this._renderGroup(activityMessagesSettingsIds)}
                    </div>
                    }

                    {conferenceSettingsIds && conferenceSettingsIds.length > 0 &&
                    <div className="mx_SettingsTab_section">
                        <span className="mx_SettingsTab_subheading">{_t("Conference")}</span>
                        {this._renderGroup(conferenceSettingsIds)}
                    </div>
                    }

                    {roomListSettingsIds && roomListSettingsIds.length > 0 &&
                    <div className="mx_SettingsTab_section">
                        <span className="mx_SettingsTab_subheading">{_t("Room list")}</span>
                        {this._renderGroup(roomListSettingsIds)}
                    </div>
                    }

                    {advancedSettingsIds && advancedSettingsIds.length > 0 &&
                    <div className="mx_SettingsTab_section">
                        <span className="mx_SettingsTab_subheading">{_t("Advanced")}</span>
                        {this._renderGroup(advancedSettingsIds)}
                    </div>
                    }

                    {minimizeToTrayOption}

                    {autoLaunchOption}
                </div>
            </div>
        );
    }
}
