import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';
import classNames from 'classnames';
import editIcon from '../../../../res/img/edit.svg';

class CitadelEditableText extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isEditing: false,
            hover: false,
            text: props.value,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                text: this.props.value,
            });
        }
    }

    stopEditing = () => {
        const { text } = this.state;
        this.setState({
            text: text.trim(),
            isEditing: false,
        });
        this.props.onStopEditing(text.trim());
    };

    startEditing = () => {
        const { length } = this.state.text;

        this.setState(
            { isEditing: true, hover: false },
            () => {
                // focus textarea and put the cursor at the end of text
                this.textArea.selectionEnd = length;
                this.textArea.selectionStart = length;
                this.textArea.focus();
            },
        );

        this.props.onStartEditing();
    };

    updateText = (e) => {
        const text = e.target.value;

        const {
            state: { text: oldText },
            props: { value: initialText, allowEmptyText },
        } = this;

        if (text !== oldText && text.trim().length > 0) {
            this.setState({ text: (allowEmptyText ? text : (text || initialText)) });
            this.props.onChange(text);
        }
    };

    onMouseEnter = () => {
        this.setState({ hover: true });
    };

    onMouseLeave = () => {
        this.setState({ hover: false });
    };

    renderEditingView = () => {
        const {
            state: { text },
            props: { isHeader, placeholder },
        } = this;

        const calculatedHeight = isHeader ? "80px" : "110px";
        const style = { height: calculatedHeight };

        return (
            <div className="edited-text" style={style}>
                <textarea
                    onBlur={this.stopEditing}
                    onChange={this.updateText}
                    ref={input => this.textArea = input}
                    defaultValue={text}
                    placeholder={placeholder}
                />
            </div>
        );
    };

    renderStaticText = () => {
        const {
            state: { text, hover },
            props: { editable, isHeader, placeholder },
        } = this;

        if (!text && !editable) return null;

        const editIconVisible = hover || !text;

        const staticTextClassNames = classNames({
            'static-text': true,
            'editable-text': editable,
        });

        const maxLines = isHeader ? 2 : 3;

        return (
            <div className={staticTextClassNames}
                 onClick={editable ? this.startEditing : () => {}}
                 onMouseEnter={this.onMouseEnter}
                 onMouseLeave={this.onMouseLeave}
            >
                <div className="text" title={text}>
                    { text ? <Truncate lines={maxLines}>{text}</Truncate> :
                        <div className="placeholder">{placeholder}</div> }
                </div>
                { editable && <img className="edit-icon" src={editIcon} alt="" style={{ visibility: editIconVisible ? 'visible' : 'hidden' }} /> }
            </div>
        );
    };

    render() {
        const {
            state: { isEditing },
            props: { isHeader },
        } = this;

        const wrapperClassNames = classNames({
            'CitadelEditableText': true,
            'CitadelEditableText_header': isHeader,
        });

        return (
            <div className={wrapperClassNames} >
                {isEditing ? this.renderEditingView() : this.renderStaticText()}
            </div>
        );
    }
}

CitadelEditableText.propTypes = {
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    editable: PropTypes.bool.isRequired,
    isHeader: PropTypes.bool,
    allowEmptyText: PropTypes.bool,
    onChange: PropTypes.func,
    onStartEditing: PropTypes.func,
    onStopEditing: PropTypes.func,
};

CitadelEditableText.defaultProps = {
    placeholder: 'Add some text...',
    value: '',
    editable: true,
    isHeader: false,
    allowEmptyText: true,
    onChange: () => {},
    onStartEditing: () => {},
    onStopEditing: () => {},
};

export default CitadelEditableText;
