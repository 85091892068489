import React from 'react';

import { _t } from '../../../../languageHandler';

import BaseDialog from '../../dialogs/BaseDialog';
import AccessibleButton from '../AccessibleButton';

const ConferencePlanDialogInviteSuccessful = (props) => {
    const {
        onFinished,
    } = props;
    return (
        <BaseDialog
            onFinished={onFinished}
            className="mx_ConferencePlanDialog conferenceInvitees"
            title=""
        >
            <div className='mx_ConferencePlanDialog alignCenter'>
                <div className='mx_ConferencePlanDialog invitationSentImage' />
                <span className='mx_ConferencePlanDialog invitationSentText'>{_t("Invitation sent")}</span>
                <AccessibleButton
                    className="mx_ConferencePlanDialog"
                    onClick={onFinished}
                    kind="primary"
                >
                    {_t("Close")}
                </AccessibleButton>
            </div>
        </BaseDialog>
    );
};

export default ConferencePlanDialogInviteSuccessful;
