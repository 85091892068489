/*
Copyright 2017 Vector Creations Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React from 'react';
import PropTypes from 'prop-types';
import MatrixClientPeg from '../../../MatrixClientPeg';
import { ContentRepo } from 'matrix-js-sdk';
import { fetchUrlWithToken, getMxcFromUrl } from '../../../utils/MediaUtils';
import { _t } from '../../../languageHandler';
import sdk from '../../../index';
import Modal from '../../../Modal';
import AccessibleButton from '../elements/AccessibleButton';
import dis from '../../../dispatcher';
import MxcBlobStore from '../../../stores/MxcBlobStore';

module.exports = class extends React.Component {
    static displayName = 'RoomAvatarEvent';

    static propTypes = {
        /* the MatrixEvent to show */
        mxEvent: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        const ev = props.mxEvent;

        this.state = {
            url: ContentRepo.getHttpUriForMxc(
                        MatrixClientPeg.get().getHomeserverUrl(),
                        ev.getContent().url,
                        Math.ceil(14 * window.devicePixelRatio),
                        Math.ceil(14 * window.devicePixelRatio),
                        'crop',
                    ),
        };
    }

    onAvatarClick = (name) => {
        const ImageView = sdk.getComponent("elements.ImageView");
        const httpUrl = MatrixClientPeg.get().mxcUrlToHttp(this.props.mxEvent.getContent().url);

        const mxc = getMxcFromUrl(httpUrl);
        const blob = MxcBlobStore.getBlobFromMxc(mxc);

        if (blob) {
            const params = {
                src: blob,
                name: name,
            };
            Modal.createDialog(ImageView, params, "mx_Dialog_lightbox");
        } else {
            fetchUrlWithToken(httpUrl)
            .then((blob) => {
                if (blob) {
                    dis.dispatch({
                        action: 'update_blob_store',
                        mxc: mxc,
                        blob: blob,
                    });
                    const params = {
                        src: blob,
                        name: name,
                    };
                    Modal.createDialog(ImageView, params, "mx_Dialog_lightbox");
                }
            })
            .catch((err) => {
                console.error('Could not retrieve url content', err);
            });
        }
    };

    render() {
        const ev = this.props.mxEvent;
        const senderDisplayName = ev.sender && ev.sender.name ? ev.sender.name : ev.getSender();
        const BaseAvatar = sdk.getComponent("avatars.BaseAvatar");
        const { url } = this.state;

        const room = MatrixClientPeg.get().getRoom(this.props.mxEvent.getRoomId());
        const name = _t('%(senderDisplayName)s changed the avatar for %(roomName)s', {
                senderDisplayName: senderDisplayName,
                roomName: room ? room.name : '',
        });

        if (!ev.getContent().url || ev.getContent().url.trim().length === 0) {
            return (
                <div className="mx_TextualEvent">
                    { _t('%(senderDisplayName)s removed the room avatar.', {senderDisplayName: senderDisplayName}) }
                </div>
            );
        }

        return (
            <div className="mx_RoomAvatarEvent">
                { _t(this.props.forExport
                        ? 'export_chat|%(senderDisplayName)s changed the room avatar'
                        : '%(senderDisplayName)s changed the room avatar to <img/>',
                    { senderDisplayName: senderDisplayName },
                    {
                        'img': () =>
                            <AccessibleButton key="avatar" className="mx_RoomAvatarEvent_avatar"
                                onClick={this.onAvatarClick.bind(this, name)}>
                                {this.props.forExport ? null : <BaseAvatar width={14} height={14} url={url} name={name} />}
                            </AccessibleButton>,
                    })
                }
            </div>
        );
    }
};
