import React, { useCallback, useMemo } from 'react';

import sdk from '../../index';
import { _t } from '../../languageHandler';
import CitadelButton from '../views/elements/CitadelButton';

const CITADEL_DESKTOP_PROTOCOL = 'citadel.team://';

const DeeplinkIntermediary = () => {
    const AuthFooterDownload = useMemo(() => sdk.getComponent('auth.AuthFooterDownload'), []);

    const getDeeplinkProtocol = useCallback(() => {
        const newHash = window.location.hash.split("?")[0];
        return CITADEL_DESKTOP_PROTOCOL + newHash;
    }, []);

    const onContinueOnBrowser = () => {
        const newHash = window.location.hash.split("?")[0];
        window.location.hash = newHash;
        window.location.reload();
    };

    const onOpenCitadelTeam = () => {
        const newHash = window.location.hash.split("?")[0];
        const splitHash = newHash.split('/');
        const newRoomId = splitHash[splitHash.length - 1];
        localStorage.setItem("mx_last_room_id", newRoomId);
        const desktopLocation = getDeeplinkProtocol();
        window.location = desktopLocation;
    };

    return (
        <div className='mxDeeplink_container'>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <div className='mxDeeplink_inner'>
                <div className='mxDeeplink_horizontalLogo' />
                <CitadelButton onClick={onOpenCitadelTeam} text={_t("Open Citadel Team")} />
                <CitadelButton onClick={onContinueOnBrowser} isSecondary text={_t("Continue on browser")} />
                <div className='mxDeeplink_illustration' />
            </div>
            <AuthFooterDownload description={_t("Citadel Team application is available")} />
        </div>
    );
};

export default DeeplinkIntermediary;
