const numberToUInt = (val) => val < 0 ? val + 4294967296 : val;
const numberToBytes32 = (val) => [(val >>> 24) & 0xff, (val >>> 16) & 0xff, (val >>> 8) & 0xff, val & 0xff];
const numberToBytes16sw = (val) => [val & 0xff, (val >>> 8) & 0xff];

// https://en.wikipedia.org/wiki/Adler-32
const adler32 = (array, start, len) => {
    let newStart = start;
    let newLength = len;

    if (!newStart) newStart = 0;
    if (!newLength) newLength = array.length - newStart;

    let a = 1; let b = 0;
    for (let i = 0; i < newLength; i++) {
        a = (a + array[newStart + i]) % 65521; b = (b + a) % 65521;
    }
    return numberToUInt((b << 16) | a);
};

const createCRCTable = () => {
    let c;
    const crcTable = [];
    for (let n =0; n < 256; n++) {
        c = n;
        for (let k = 0; k < 8; k++) {
            c = ((c & 1) ? (0xEDB88320 ^ (c >>> 1)) : (c >>> 1));
        }
        crcTable[n] = c;
    }
    return crcTable;
};

// function converts a variable-length string into an 8-character string that is a text
// representation of the hexadecimal value of a 32 bit-binary sequence.
const crc32 = (array, start, len) => {
    let newStart = start;
    let newLength = len;

    if (!start) newStart = 0;
    if (!len) newLength = array.length - newStart;
    let table = array.crcTable;
    if (!table) {
        table = createCRCTable();
        array.crcTable = table;
    }
    let c = 0xffffffff;
    for (let i = 0; i < newLength; i++) {
        c = table[(c ^ array[newStart + i]) & 0xff] ^ (c >>> 8);
    }

    return numberToUInt(c ^ 0xffffffff);
};

export const createPNG = function(data = [], width, height) {
    const imageData = Array.prototype.slice.call(data);
    const w = width;
    const h = height;
    const stream = [
        0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a, // PNG signature
        0x00, 0x00, 0x00, 0x0d, 0x49, 0x48, 0x44, 0x52,
    ];
    /*
        The IHDR chunk must appear FIRST. It contains:
        Width:              4 bytes
        Height:             4 bytes
        Bit depth:          1 byte -> 0x08
        Color type:         1 byte -> 0x06
        Compression method: 1 byte -> 0x00
        Filter method:      1 byte -> 0x00
        Interlace method:   1 byte -> 0x00
    */
    stream.push(...numberToBytes32(w)); // width
    stream.push(...numberToBytes32(h)); // height
    stream.push(0x08, 0x06, 0x00, 0x00, 0x00); // bit depth, color type, compression method, filter method, interlace method
    stream.push(...numberToBytes32(crc32(stream, 12, 17)));
    const len = h * (w * 4 + 1);
    for (let y = 0; y < h; y++) {
        imageData.splice(y * (w * 4 + 1), 0, 0);
    }
    const blocks = Math.ceil(len / 32768);
    stream.push(...numberToBytes32(len + 5 * blocks + 6));
    const crcStart = stream.length;
    const crcLen = (len + 5 * blocks + 6 + 4);
    stream.push(0x49, 0x44, 0x41, 0x54, 0x78, 0x01);  // IDAT chunks
    for (let i = 0; i < blocks; i++) {
        const blockLen = Math.min(32768, len - (i * 32768));
        stream.push(i === (blocks - 1) ? 0x01 : 0x00);
        stream.push(...numberToBytes16sw(blockLen));
        stream.push(...numberToBytes16sw(~blockLen));
        const id = imageData.slice(i * 32768, i * 32768 + blockLen);
        stream.push(...id);
    }
    stream.push(...numberToBytes32(adler32(imageData)));
    stream.push(...numberToBytes32(crc32(stream, crcStart, crcLen)));

    stream.push(0x00, 0x00, 0x00, 0x00, 0x49, 0x45, 0x4e, 0x44); // IEND
    stream.push(...numberToBytes32(crc32(stream, stream.length - 4, 4)));
    const base64encoded = Buffer.from(stream).toString('base64');

    return "data:image/png;base64," + base64encoded;
};

export const downloadBase64Image = (data, filename) => {
    const a = document.createElement('a');
    a.href = data;
    a.download = filename || 'citadel-keys';
    a.click();
    a.remove();
};
