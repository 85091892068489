import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { _t } from '../../languageHandler';
import AuthFooterDownload from '../views/auth/AuthFooterDownload';
import CitadelButton from '../views/elements/CitadelButton';
import browserNotSupportedIcon from '../../../res/img/browser-not-supported.svg';

const BROWSER_TYPE = {
    ALLOWED: 0, // Chrome, Firefox, Safari
    ALLOWED_WITH_WARNING: 1, // chromium based
    NOT_ALLOWED: 2, // something else
};

const links = {
    chrome: (label) => <a href="https://www.google.com/chrome/" target="_blank">{label}</a>,
    firefox: (label) => <a href="https://www.mozilla.org/firefox/new/" target="_blank">{label}</a>,
    safari: (label) => <a href="https://support.apple.com/downloads/safari" target="_blank">{label}</a>,
};

class CompatibilityPage extends React.Component {
    state = { isLoading: false };

    handleContinueClick = () => {
        const { onContinue } = this.props;
        this.setState({ isLoading: true });
        onContinue();
    };

    renderContinueForm = () => {
        const { browserType } = this.props;
        const { isLoading } = this.state;

        if (browserType === BROWSER_TYPE.ALLOWED_WITH_WARNING) {
            return (
                <div className='continue-form' >
                    {_t("Click on the button below if you would like to proceed anyway.")}
                    <CitadelButton
                        isLoading={isLoading}
                        onClick={this.handleContinueClick}
                        primary
                        text={_t('Continue')}
                    />
                </div>
            );
        }
    };

    render() {
        return (
            <div className='browser-not-supported-page'>
                <div className='browser-not-supported-content'>
                    <div className='browser-not-supported-header'>
                        <img src={browserNotSupportedIcon} alt='not supported browser' />
                    </div>
                    <div className='browser-not-supported-body'>
                        <div className='title'>
                            {_t('Sorry your browser is not able to run %(newLine)s this version of Citadel.', { newLine: <br />})}
                        </div>
                        <div className='subtitle'>
                            {_t('Please install <chromeLink>Chrome</chromeLink>, ' +
                                '<firefoxLink>Firefox</firefoxLink>, or <safariLink>Safari</safariLink> ' +
                                'for the best experience.',
                                {},
                                { chromeLink: links.chrome, firefoxLink: links.firefox, safariLink: links.safari })}
                            <br /><br />
                            {_t('It is recommended to use Citadel on Firefox or Chrome.')}
                            <br />
                            {_t('You can also download our desktop version on Mac and Windows.')}
                            <br /><br />
                            {this.renderContinueForm()}
                        </div>
                        <AuthFooterDownload displayOnlyDesktopApps={true} />
                    </div>
                </div>
            </div>
        );
    }
}

CompatibilityPage.propTypes = {
    browserType: PropTypes.number.isRequired,
    onContinue: PropTypes.func.isRequired,
};

CompatibilityPage.defaultProps = {
    browserType: BROWSER_TYPE.ALLOWED,
    onContinue: noop,
};

export default CompatibilityPage;

