import React, { useEffect, useState, useMemo, useRef } from 'react';
import { QRCodeSVG } from "qrcode.react";
import { isEmpty } from 'lodash';

import MatrixClientPeg from "../../../../MatrixClientPeg";
import UserStore from "../../../../stores/UserStore";
import { _t } from "../../../../languageHandler";
import BaseDialog from "../BaseDialog";
import CopyTextButton from '../../../views/elements/CopyTextButton';
import DialogButtons from '../../../views/elements/DialogButtons';
import CitadelCodeInput from "../../elements/CitadelCodeInput";
import Spinner from '../../elements/Spinner';

import CitadelLogo from '../../../../../res/img/citadel_logo_white_bg_round.png';
import MfaManager from "./MfaManager";
import MfaPasswordDialogContent from "./MfaPasswordDialogContent";

const MfaAppActivationDialog = ({ onFinished, onEnd, mfaStatus }) => {
    const [step, setStep] = useState(0);
    const clientSecretRef = useRef(MatrixClientPeg.get().generateClientSecret());
    const [qrCodeData, setQrcodeData] = useState({});
    const [tokenData, setTokenData] = useState();
    const [token, setToken] = useState({ value: '' });
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(null);
    const [mfaStatusError, setMfaStatusError] = useState(false);
    const serverId = MatrixClientPeg.get().idBaseUrl.split("://")[1];

    const email = useMemo(() => UserStore.getMe().email, []);

    const setNormalizedToken = (value) => setToken({ value });
    const getProvisioningURIAndMfaStatus = async () => {
        try {
            setIsLoading(true);
            const cli = MatrixClientPeg.get();
            const data = await cli.getProvisioningURI(serverId);
            setQrcodeData(data);
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };
    const getMfaToken = async () => {
        try {
            const tokenData = await MatrixClientPeg.get().getMfaToken(
                clientSecretRef.current,
                email,
                serverId,
            );
            setTokenData(tokenData);
        } catch (e) {
            if (e.errcode === 'M_FORBIDDEN') {
                // case where the user canceled the flow and totp is already configured in the db
                if (e.message.includes('OTP 3pid already added for user')) {
                    const tokenData = await MatrixClientPeg.get().getMfaTokenAfterDelete3Pid(
                        clientSecretRef.current,
                        email,
                        serverId,
                    );
                    setTokenData(tokenData);
                }
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        step === 0 && getProvisioningURIAndMfaStatus();
        if (step === 1) {
            clientSecretRef.current = MatrixClientPeg.get().generateClientSecret();
        }
    }, [step]);

    useEffect(() => {
        clientSecretRef.current && step === 1 && getMfaToken();
    }, [clientSecretRef.current, step]);

    const gotoNextStep = () => setStep(step + 1);
    const copyButtonLabel = (s = _t("COPY").toLowerCase()) => s && s[0].toUpperCase() + s.slice(1);
    const onPasswordChange = (evt) => {
        setPasswordError(null);
        setPassword(evt.target.value);
    };

    const onContinueButton = async () => {
        switch (step) {
            case 0:
                MatrixClientPeg.get().trackUserAction({
                    formId: 'statusMFA',
                    version: 1,
                    action: 'continue',
                    type: 'authapp',
                });
                gotoNextStep();
                break;
            case 1:
                setIsLoadingButton(true);
                try {
                    const resp = await MatrixClientPeg.get().submitMfaToken(clientSecretRef.current, tokenData?.sid, token.value);
                    if (resp.success) {
                        MatrixClientPeg.get().trackUserAction({
                            formId: 'statusMFA',
                            version: 1,
                            action: 'confirm',
                            type: 'authapp',
                            past: isEmpty(mfaStatus) ? 'None' : mfaStatus.join('_'),
                        });
                        gotoNextStep();
                    } else {
                        clientSecretRef.current = MatrixClientPeg.get().generateClientSecret();
                        setToken({ value: token.value, error: _t('The code is wrong. Please try again.') });
                    }
                } catch (e) {
                    console.error(e);
                } finally {
                    setIsLoadingButton(false);
                }
                break;
            case 2:
                try {
                    if (!mfaStatusError) {
                        await MatrixClientPeg.get().submitOTPBind(serverId, clientSecretRef.current, tokenData?.sid);
                    }
                    const resp = await MfaManager.setMfaStatus(password, 'enable', 'totp', true);
                    if (resp && resp.success) {
                        setMfaStatusError(false);
                        onEnd(resp.success);
                        MatrixClientPeg.get().trackUserAction({
                            formId: 'statusMFA',
                            version: 1,
                            action: 'verity_pwd',
                            type: 'authapp',
                            step: 'enable',
                        });
                        onFinished();
                    }
                } catch (e) {
                    if (e && e.message === 'Invalid password') {
                        setPasswordError(_t('Wrong password.'));
                        setMfaStatusError(true);
                    }
                }
                break;
        }
    };

    const onCopyText = () => {
        MatrixClientPeg.get().trackUserAction({
            formId: 'statusMFA',
            version: 1,
            action: 'copylink',
            type: 'authapp',
        });
    };

    const handleOnFinish = () => {
        MatrixClientPeg.get().trackUserAction({
            formId: 'statusMFA',
            version: 1,
            action: 'cancel',
            type: 'authapp',
            past: isEmpty(mfaStatus) ? 'None' : mfaStatus.join('_'),
        });
        onFinished();
    };

    const handleOnFinishPassword = async () => {
        MatrixClientPeg.get().trackUserAction({
            formId: 'statusMFA',
            version: 1,
            action: 'cancel_pwd',
            type: 'authapp',
            step: 'enable',
        });
        try {
            await MatrixClientPeg.get().delete3PidCrendetials(email, serverId);
        } catch (error) {
            console.error(error);
        }
        onFinished();
    };

    const onHelpClick = () => {
        MatrixClientPeg.get().trackUserAction({
            formId: 'statusMFA',
            version: 1,
            action: 'help',
            type: 'authapp',
        });
    };

    const getContent = () => {
        switch (step) {
            case 0:
                return (
                    <div className="qrcode-dialog-content-wrapper">
                        {isLoading
                            ? <Spinner />
                            : <div className='qrcode-dialog-content-wrapper-inner'>
                                <div>
                                    {_t('Please use your authentication application (such as Google ' +
                                        'Authenticator or Microsoft Authenticator) on your mobile device to set ' +
                                        'up your two-step verification.')}
                                </div>
                                <div className="qrcode-content">
                                    <div className="qrcode-container">
                                        <div className='description'>
                                            {_t('Scan this QR code with your authentication app')}
                                        </div>
                                        <div className="qrcode" >
                                            <QRCodeSVG
                                                imageSettings={{
                                                    src: CitadelLogo,
                                                    width: 48,
                                                    height: 48,
                                                }}
                                                size={208}
                                                value={qrCodeData['provisioning_uri']}
                                            />
                                        </div>
                                    </div>
                                    <div className="qrcode-data-container">
                                        <div className='description'>
                                            {_t('Or enter this code in your authentication app')}
                                        </div>
                                        <CopyTextButton
                                            autoReset={false}
                                            copiedLabel={_t('Copied!').slice(0, -1)}
                                            copyLabel={copyButtonLabel()}
                                            shouldDisplayCopyText={true}
                                            text={qrCodeData.secret?.match(/.{1,2}/g).join(' ')}
                                            type={'text'}
                                            callback={onCopyText}
                                        />
                                    </div>
                                </div>
                                <div className="footer">
                                    {_t('We recommend you to name the app "Citadel Team" for account name.')}
                                    <div className='link' onClick={onHelpClick}>{_t('Need help?')}</div>
                                </div>
                                <DialogButtons
                                    onCancel={handleOnFinish}
                                    onPrimaryButtonClick={onContinueButton}
                                    primaryButton={_t('Continue')}
                                    primaryDisabled={!qrCodeData.secret}
                                />
                            </div>
                        }
                    </div>
                );
            case 1:
                return (
                    <div className="token-dialog-content-wrapper">
                        {isLoading
                            ? <Spinner />
                            : <>
                                <div className="description">
                                    {_t('Please enter the verification code displayed in your authentication app')}
                                </div>
                                <CitadelCodeInput
                                    className="verification_code"
                                    error={token.error}
                                    fields={6}
                                    label={_t('Verification code')}
                                    onChange={setNormalizedToken}
                                    type="tel"
                                    value={token.value}
                                />
                                <DialogButtons
                                    isLoading={isLoadingButton}
                                    onCancel={handleOnFinish}
                                    onPrimaryButtonClick={onContinueButton}
                                    primaryButton={_t('Verify')}
                                    primaryDisabled={isEmpty(token) || token.length < 6 || !!token.error}
                                />
                            </>
                        }
                    </div>
                );
            case 2:
                return (
                    <div className="password-dialog-content-wrapper">
                        {isLoading
                            ? <Spinner />
                            : <>
                                <MfaPasswordDialogContent
                                    error={passwordError}
                                    mfaType={'totp'}
                                    onChange={onPasswordChange}
                                    value={password}
                                />
                                <DialogButtons
                                    focus={true}
                                    isLoading={isLoadingButton}
                                    onCancel={handleOnFinishPassword}
                                    onPrimaryButtonClick={onContinueButton}
                                    primaryButton={_t('Verify')}
                                    primaryDisabled={isEmpty(password) || password.length < 6 || password.error}
                                />
                            </>
                        }
                    </div>
                );
        }
    };

    return (
        <BaseDialog
            className="mx_MfaAppActivationDialog"
            fixedWidth={false}
            onFinished={step === 2 ? handleOnFinishPassword : handleOnFinish}
            title={step !== 2
                ? _t('Two-step verification via an authentication application')
                : _t('Please verify your account')
            }
        >
            {getContent()}
        </BaseDialog>
    );
};

export default MfaAppActivationDialog;
