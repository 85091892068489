//@flow

import React from 'react';
import PropTypes from 'prop-types';
import filesize from 'filesize';
import { _t } from '../../languageHandler';
import CitadelFileDownloader from '../../utils/CitadelFileDownloader';

import dis from '../../dispatcher';

import downloadIcon from '../../../res/img/fileicon-download.svg';
import cancelIcon from '../../../res/img/cancel.svg';

type DownloadPayload = {
    action: string,
    roomId: string,
    files?: string[],
    totalReceived: number,
    totalToDownload: number,
}

class CitadelDownloadBar extends React.Component {
    state = {
        files: [],
        totalReceived: 0,
        totalToDownload: 0,
    };

    componentDidMount() {
        this.dispatcherRef = dis.register(this.onAction);
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
        dis.unregister(this.dispatcherRef);
    }

    onAction = (payload: DownloadPayload) => {
        if (!this.mounted || payload.roomId !== this.props.room.roomId) return;

        switch (payload.action) {
            case 'download_started':
            case 'download_progress':
                this.setState({
                    files: payload.files,
                    totalReceived: payload.totalReceived,
                    totalToDownload: payload.totalToDownload,
                });
                break;
            case 'download_finished':
                this.setState({
                    files: [],
                    totalReceived: 0,
                    totalToDownload: 0,
                });
                break;
        }
    };

    abortLatestDownload = () => {
        CitadelFileDownloader.abortLatestDownload(this.props.room.roomId);
    };

    render(): boolean | number | string | React$Element<*> | React$Portal | Iterable | null {
        const { files, totalReceived, totalToDownload } = this.state;

        if (!files.length || !totalToDownload) {
            return <div />;
        }

        const innerProgressStyle = {
            width: ((totalReceived / (totalToDownload || 1)) * 100) + '%',
        };
        let downloadedSize = filesize(totalReceived);
        const totalSize = filesize(totalToDownload);
        if (downloadedSize.replace(/^.* /, '') === totalSize.replace(/^.* /, '')) {
            downloadedSize = downloadedSize.replace(/ .*/, '');
        }

        const progressText = _t('Downloading %(filename)s and %(count)s others',
            { filename: files[0], count: (files.length - 1) });

        return (
            <div className="mx_UploadBar">
                <div className="mx_UploadBar_uploadProgressOuter">
                    <div className="mx_UploadBar_uploadProgressInner" style={innerProgressStyle} />
                </div>
                <img
                    className="mx_UploadBar_uploadIcon mx_filterFlipColor"
                    src={downloadIcon}
                    width="17"
                    height="22"
                    alt=""
                />
                <img
                    className="mx_UploadBar_uploadCancel mx_filterFlipColor"
                    src={cancelIcon}
                    width="18"
                    height="18"
                    alt=""
                    onClick={this.abortLatestDownload}
                />
                <div className="mx_UploadBar_uploadBytes">
                    {downloadedSize} / {totalSize}
                </div>
                <div className="mx_UploadBar_uploadFilename">{progressText}</div>
            </div>
        );
    }
}

CitadelDownloadBar.propTypes = {
    room: PropTypes.object,
};

export default CitadelDownloadBar;
