import React, { Component } from 'react';
import { _t } from '../../../languageHandler';
import { AuthSuccessAnimation } from '../animations/AuthSuccessAnimation';
import { Spinner } from '../animations/Spinner';

class CitadelPostAuth extends Component {
    constructor(props) {
        super(props);
        this.state = { isPaused: true, displayName: '' };
    }

    componentDidMount() {
        const { freshConnectionOrigin } = this.props;

        if (freshConnectionOrigin === 'login') {
            setTimeout(async () => {
                const firstName = await this.getFirstName();
                this.setState({ isPaused: false, firstName });
            }, 10);
        }
    }

    async getFirstName() {
        const userId = localStorage.getItem('mx_user_id');
        const homeserverUrl = localStorage.getItem('mx_hs_url');
        const token = localStorage.getItem('mx_access_token');

        const url = `${homeserverUrl}/_matrix/client/r0/profile/${userId}/displayname`;
        const res = await fetch(url,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
        const data = await res.json();

        if (data.errcode) return ' ';

        const parts = data.displayname.split(' ');
        let firstName = parts[0];
        for (let i = 1; i < parts.length; i ++) {
            if (parts[i] === parts[i].toUpperCase()) {
                break;
            }

            firstName = `${firstName} ${parts[i]}`;
        }

        return firstName;
    }

    render() {
        const { freshConnectionOrigin } = this.props;
        const { firstName } = this.state;

        let isPaused = this.state.isPaused;
        let title = '';
        let subtitle = '';

        if (freshConnectionOrigin === 'login') {
            if (!firstName) return <div className="mx_MatrixChat_splash" />;

            title = _t('Hi %(firstName)s!', { firstName });
            subtitle = _t('Successful authentication, connection in progress...');
        } else {
            isPaused = false;
            title = _t('Your account is ready!');
            subtitle = _t('Your account is now secured, you can start to contact your collaborators.');
        }

        return (
            <div className="mx_MatrixChat_splash">
                <div className="mx_CitadelPostAuth">
                    <AuthSuccessAnimation isPaused={isPaused} />
                    <div className="title">{title}</div>
                    <div className="subtitle">{subtitle}</div>
                    <div className="spinner">
                        <Spinner isPaused={isPaused} />
                    </div>
                </div>
            </div>
        );
    }
}

export default CitadelPostAuth;
