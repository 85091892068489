import React from 'react';
import cx from 'classnames';
import CitadelToggle from "./CitadelToggle";
const StyledRadioGroup = ({
    name,
    definitions,
    value,
    className,
    outlined,
    disabled,
    onChange,
}) => {
    const _onChange = (e) => {
        onChange(e.target.value);
    };

    return (
        <React.Fragment>
            {definitions.map((d) => {
                const id = `${name}-${d.value}`;
                return (
                    <React.Fragment key={d.value}>
                        <CitadelToggle
                            type="radio"
                            checked={d.checked !== undefined ? d.checked : d.value === value}
                            label={d.label}
                            id={id}
                            className={cx(className, d.className)}
                            onChange={() => _onChange({ target: { value: d.value }})}
                            name={name}
                            value={d.value}
                            disabled={d.disabled ?? disabled}
                            outlined={outlined}
                            aria-describedby={d.description ? `${id}-description` : undefined}
                        />
                        {d.description ? <span id={`${id}-description`}>{d.description}</span> : null}
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

export default StyledRadioGroup;
