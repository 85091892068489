'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import sdk from '../../../index';
import dis from '../../../dispatcher';
import { _t } from '../../../languageHandler';
import { citadelUrls } from '../../../utils/CitadelUtils';
import CitadelLoginForm from '../../views/auth/CitadelLoginForm';
import CitadelRegistrationForm from '../../views/auth/CitadelRegistrationForm';

const VIEWS = {
    WELCOME: {
        LOGIN: 1,
        REGISTER: 2,
    },
};

class CitadelWelcome extends React.Component {
    constructor(props) {
        super(props);

        const { initialView } = props;
        this.state = {
            view: initialView || VIEWS.WELCOME.LOGIN,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.initialView !== this.props.initialView) {
            this.setState({
                view: this.props.initialView,
            });
        }
    }

    onTitleClick = (ev) => {
        const { view } = this.state;
        const title = ev.target.id;

        if (title === 'login' && view === VIEWS.WELCOME.LOGIN) return;
        if (title === 'registration' && view === VIEWS.WELCOME.REGISTER) return;

        dis.dispatch({ action: `start_${title}` });
    };

    renderLoginForm = () => {
        const {
            onLoggedIn,
            onLogout,
            onUrlsChange,
            defaultDeviceDisplayName,
            defaultUrls,
            devUrls,
            initialEmail,
        } = this.props;

        return (
            <CitadelLoginForm
                defaultDeviceDisplayName={defaultDeviceDisplayName}
                defaultUrls={defaultUrls}
                devUrls={devUrls}
                initialEmail={initialEmail}
                onLoggedIn={onLoggedIn}
                onLogout={onLogout}
                onUrlsChange={onUrlsChange}
            />
        );
    };

    renderRegisterForm = () => {
        const { defaultUrls, devUrls, initialEmail } = this.props;

        return (
            <CitadelRegistrationForm
                defaultUrls={defaultUrls}
                devUrls={devUrls}
                initialEmail={initialEmail}
            />
        );
    };

    renderHeader = () => {
        const { isSoftLogoutDueToPassword, onLogout } = this.props;
        const { view } = this.state;
        const loginTitleClasses = classNames({
            'login-tab': true,
            'title': true,
            'selected': (view === VIEWS.WELCOME.LOGIN),
        });
        const registerTitleClasses = classNames({
            'register-tab': true,
            'title': true,
            'selected': (view === VIEWS.WELCOME.REGISTER),
        });

        return !onLogout ? (
            <div className="titles">
                <div className="title_wrapper">
                    <div id="login" className={loginTitleClasses} onClick={this.onTitleClick}>
                        {_t('Login')}
                    </div>
                </div>
                <div className="title_wrapper">
                    <div id="registration" className={registerTitleClasses} onClick={this.onTitleClick}>
                        {_t('Registration')}
                    </div>
                </div>
            </div>
        ) : (
            <div>
                <div className="login_again_title">
                    {_t('Login again')}
                </div>
                <div className="login_again_subtitle">
                    {isSoftLogoutDueToPassword ?
                        _t('You have changed your password, please confirm your email address.'):
                        _t('Your session has encountered a problem, please login again.')}
                </div>
            </div>
        );
    }

    renderFooter = () => {
        const AuthFooterDownload = sdk.getComponent('auth.AuthFooterDownload');
        const { onLogout } = this.props;
        return !onLogout ? <AuthFooterDownload /> : null;
    }

    renderLogoutButton = () => {
        const { onLogout } = this.props;

        return onLogout ? <a className="log_out" onClick={onLogout} href="#">{_t('Log out')}</a> : null;
    }

    render() {
        const AuthPage = sdk.getComponent('auth.AuthPage');
        const AuthHeader = sdk.getComponent('auth.AuthHeader');
        const AuthBody = sdk.getComponent('auth.AuthBody');
        const { view } = this.state;

        const componentView = (view === VIEWS.WELCOME.LOGIN) ? this.renderLoginForm() : this.renderRegisterForm();

        return (
            <AuthPage>
                <AuthHeader />
                <AuthBody>
                    <div className="mx_CitadelWelcome">
                        {this.renderHeader()}
                        {componentView}
                        {this.renderLogoutButton()}
                    </div>
                </AuthBody>
                {this.renderFooter()}
            </AuthPage>
        );
    }
}

CitadelWelcome.propTypes = {
    defaultDeviceDisplayName: PropTypes.string.isRequired,
    defaultUrls: citadelUrls,
    devUrls: citadelUrls,
    initialEmail: PropTypes.string,
    initialView: PropTypes.number,
    onLoggedIn: PropTypes.func.isRequired,
    onLogout: PropTypes.func, // we have this function only if we are in soft logout state
    onUrlsChange: PropTypes.func.isRequired,
    isSoftLogoutDueToPassword: PropTypes.bool,
};

CitadelWelcome.defaultProps = {
    initialEmail: '',
};

export default CitadelWelcome;
