import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash';
import classNames from 'classnames';

import AccessibleButton from '../elements/AccessibleButton';
import { _t } from '../../../languageHandler';
import dis from '../../../dispatcher';
import GenericToolTip from '../../structures/GenericToolTip';

export class MemberInvite extends React.Component {
    onInviteButtonClick = () => {
        const { closeHint, roomId, isDisabled } = this.props;
        if (isDisabled) return;
        closeHint();
        dis.dispatch({
            action: 'view_invite',
            roomId,
        });
    };

    renderButton = () => {
        const className = classNames("invite", {
            "hinted-invite": this.props.isCurrentlyHinted,
            "disabled": this.props.isDisabled,
        });
        return <div className={className} onClick={this.onInviteButtonClick}>
            <AccessibleButton
                onClick={noop}
                className="mx_RoomSubList_addRoom"
            />
            <span>{_t('Invite to this room')}</span>
        </div>;
    }

    render() {
        const { isDisabled, expectedNumberOfMembers, maxMembers } = this.props;
        return isDisabled ? <GenericToolTip
            label={_t('You have reached the limit of members in an end-to-end encrypted room (%(noOfMembers)s/%(maxMembers)s).', {
                noOfMembers: expectedNumberOfMembers,
                maxMembers,
            })}
            labelStyle={'mx_InviteDisabledToolTip'}
        >
            { this.renderButton() }
        </GenericToolTip> :
        this.renderButton();
    }
}

MemberInvite.propTypes = {
    roomId: PropTypes.string.isRequired,
    isCurrentlyHinted: PropTypes.bool,
    closeHint: PropTypes.func,
};
