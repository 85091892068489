/*
Copyright 2015, 2016 OpenMarket Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import CitadelMembersLink from './components/views/elements/CitadelMembersLink';
import MatrixClientPeg from './MatrixClientPeg';
import CallHandler from './CallHandler';
import { _t } from './languageHandler';
import * as Roles from './Roles';
import { isValid3pidInvite } from './RoomInvite';
import React from 'react';
import dis from './dispatcher';
import DMRoomMap from './utils/DMRoomMap';
import UserStore from './stores/UserStore';
import { isUserMxId } from './UserAddress';

const getUserDisplayName = (userEvent, userId) => {
    const userDisplayName = UserStore.getUserById(userId).displayName;
    return (userDisplayName && !isUserMxId(userDisplayName)) ? userDisplayName :
           (userEvent && userEvent.name) ||
           userId;
};

function textForMemberEvent(ev, followsCreateEvent, extraProps) {
    const myUserId = localStorage.getItem('mx_user_id');

    // XXX: SYJS-16 "sender is sometimes null for join messages"
    const senderUserId = ev.getSender();
    const senderName = getUserDisplayName(ev.sender, senderUserId);

    const targetUserId = ev.getStateKey();
    const targetName = getUserDisplayName(ev.target, targetUserId);

    const prevContent = ev.getPrevContent();
    const content = ev.getContent();

    const ConferenceHandler = CallHandler.getConferenceHandler();
    const reason = content.reason ? (_t('Reason') + ': ' + content.reason) : '';
    switch (content.membership) {
        case 'invite': {
            const threePidContent = content.third_party_invite;
            if (threePidContent) {
                if (threePidContent.display_name) {
                    return _t('%(targetName)s accepted the invitation for %(displayName)s.', {
                        targetName,
                        displayName: threePidContent.display_name,
                    });
                } else {
                    return _t('%(targetName)s accepted an invitation.', { targetName });
                }
            } else {
                if (ConferenceHandler && ConferenceHandler.isConferenceUser(targetUserId)) {
                    return _t('%(senderName)s requested a VoIP conference.', { senderName });
                } else if (targetUserId && targetUserId === myUserId) {
                    const inviterName = (extraProps && extraProps.senderName) || senderName;

                    if (extraProps && extraProps.isDirectChat) {
                        // This is only displayed in notifications since we prevent the display of such events in a private chat.
                        return _t('%(senderName)s has invited you to a private chat', { senderName: inviterName });
                    } else {
                        const roomName = extraProps ? extraProps.roomName || '' : '';
                        return _t('%(senderName)s has invited you to join the room %(roomName)s',
                            { senderName: inviterName, roomName });
                    }
                } else {
                    return _t('%(senderName)s invited %(targetName)s.', { senderName, targetName });
                }
            }
        }
        case 'ban':
            return _t('%(senderName)s permanently removed %(targetName)s.', { senderName, targetName }) + ' ' + reason;
        case 'join':
            if (prevContent && prevContent.membership === 'join') {
                // displayName changes as events are never displayed in the timeline (shouldHideEvent)
                if (prevContent.displayname && content.displayname && prevContent.displayname !== content.displayname) {
                    return _t('%(oldDisplayName)s changed their display name to %(displayName)s.', {
                        oldDisplayName: prevContent.displayname,
                        displayName: content.displayname,
                    });
                } else if (!prevContent.displayname && content.displayname) {
                    return _t('%(senderName)s set their display name to %(displayName)s.', {
                        senderName,
                        displayName: content.displayname,
                    });
                } else if (prevContent.displayname && !content.displayname) {
                    return _t('%(senderName)s removed their display name (%(oldDisplayName)s).', {
                        senderName,
                        oldDisplayName: prevContent.displayname,
                    });
                } else if (prevContent.avatar_url && !content.avatar_url) {
                    return _t('%(senderName)s removed their profile picture.', { senderName });
                } else if (prevContent.avatar_url && content.avatar_url &&
                    prevContent.avatar_url !== content.avatar_url) {
                    return _t('%(senderName)s changed their profile picture.', { senderName });
                } else if (!prevContent.avatar_url && content.avatar_url) {
                    return _t('%(senderName)s set a profile picture.', { senderName });
                } else {
                    // suppress null rejoins
                    return '';
                }
            } else {
                if (!ev.target) console.warn('Join message has no target! -- ' + ev.getContent().state_key);
                const dmRoomMap = DMRoomMap.shared();
                const roomId = ev.getRoomId();

                if (ConferenceHandler && ConferenceHandler.isConferenceUser(targetUserId)) {
                    return _t('VoIP conference started.');
                } else if (!dmRoomMap.getUserIdForRoomId(roomId) && myUserId === targetUserId) {
                    const membersCountMessage =
                        <span>
                            {_t('You can see the list of')}
                            &nbsp;
                            <CitadelMembersLink roomId={roomId} />
                        </span>;

                    if (!extraProps || !extraProps.senderName) {
                        return membersCountMessage;
                    }

                    return (
                        <span>
                            {_t('You just joined this room after being invited by %(senderName)s',
                                { senderName: extraProps.senderName })}
                            <br />
                            {membersCountMessage}
                        </span>
                    );
                } else {
                    return _t('%(targetName)s joined the room.', { targetName });
                }
            }
        case 'leave':
            if (senderUserId === targetUserId) {
                if (ConferenceHandler && ConferenceHandler.isConferenceUser(targetUserId)) {
                    return _t('VoIP conference finished.');
                } else if (prevContent.membership === 'invite') {
                    return _t('%(targetName)s rejected the invitation.', { targetName });
                } else {
                    return _t('%(targetName)s left the room.', { targetName });
                }
            } else if (prevContent.membership === 'ban') {
                return _t('%(senderName)s unbanned %(targetName)s.', { senderName, targetName });
            } else if (prevContent.membership === 'join') {
                return _t('%(senderName)s removed %(targetName)s.', { senderName, targetName }) + ' ' + reason;
            } else if (prevContent.membership === 'invite') {
                return _t('%(senderName)s withdrew %(targetName)s\'s invitation.', {
                    senderName,
                    targetName,
                }) + ' ' + reason;
            } else {
                return _t('%(targetName)s left the room.', { targetName });
            }
    }
}

function textForTopicEvent(ev) {
    return _t('%(senderDisplayName)s changed the topic to "%(topic)s".', {
        senderDisplayName: getUserDisplayName(ev.sender, ev.getSender()),
        topic: ev.getContent().topic,
    });
}

function textForRoomNameEvent(ev) {
    const senderDisplayName = getUserDisplayName(ev.sender, ev.getSender());

    if (!ev.getContent().name || ev.getContent().name.trim().length === 0) {
        return _t('%(senderDisplayName)s removed the room name.', { senderDisplayName });
    }
    return _t('%(senderDisplayName)s changed the room name to %(roomName)s.', {
        senderDisplayName,
        roomName: ev.getContent().name,
    });
}

function textForTombstoneEvent(ev) {
    return _t('%(senderDisplayName)s upgraded this room.', {
        senderDisplayName: getUserDisplayName(ev.sender, ev.getSender()),
    });
}

function textForJoinRulesEvent(ev, followsCreateEvent) {
    const myUserId = localStorage.getItem('mx_user_id');

    const senderUserId = ev.getSender();
    const senderDisplayName = getUserDisplayName(ev.sender, senderUserId);

    let initialText;
    switch (ev.getContent().join_rule) {
        case 'public':
            initialText = _t('%(senderDisplayName)s made the room public to whoever knows the link.', { senderDisplayName });
            break;
        case 'invite':
            if (myUserId === senderUserId) {
                initialText = _t('Only invited members will be able to join this room');
            } else {
                initialText = _t('%(senderDisplayName)s made the room invite only.', { senderDisplayName });
            }
            break;
        default:
            // The spec supports "knock" and "private", however nothing implements these.
            initialText = _t('%(senderDisplayName)s changed the join rule to %(rule)s', {
                senderDisplayName,
                rule: ev.getContent().join_rule,
            });
    }

    if (followsCreateEvent) {
        const openUserSettingsTab = (ev) => {
            ev.preventDefault();
            dis.dispatch({
                action: 'open_room_settings',
                initialTabIndex: 1,
            });
        };

        return <span>
            {initialText}
            <br />
            <span>
                {_t('You can change this parameter from the <a>room settings</a>.',
                    {},
                    { a: sub => <a onClick={ev => openUserSettingsTab(ev)}>{sub}</a> })
                }
            </span>
        </span>;
    } else {
        return initialText;
    }
}

function textForGuestAccessEvent(ev) {
    const senderDisplayName = getUserDisplayName(ev.sender, ev.getSender());
    switch (ev.getContent().guest_access) {
        case 'can_join':
            return _t('%(senderDisplayName)s has allowed guests to join the room.', { senderDisplayName });
        case 'forbidden':
            return _t('%(senderDisplayName)s has prevented guests from joining the room.', { senderDisplayName });
        default:
            // There's no other options we can expect, however just for safety's sake we'll do this.
            return _t('%(senderDisplayName)s changed guest access to %(rule)s', {
                senderDisplayName,
                rule: ev.getContent().guest_access,
            });
    }
}

function textForRelatedGroupsEvent(ev) {
    const senderDisplayName = getUserDisplayName(ev.sender, ev.getSender());
    const groups = ev.getContent().groups || [];
    const prevGroups = ev.getPrevContent().groups || [];
    const added = groups.filter((g) => !prevGroups.includes(g));
    const removed = prevGroups.filter((g) => !groups.includes(g));

    if (added.length && !removed.length) {
        return _t('%(senderDisplayName)s enabled flair for %(groups)s in this room.', {
            senderDisplayName,
            groups: added.join(', '),
        });
    } else if (!added.length && removed.length) {
        return _t('%(senderDisplayName)s disabled flair for %(groups)s in this room.', {
            senderDisplayName,
            groups: removed.join(', '),
        });
    } else if (added.length && removed.length) {
        return _t('%(senderDisplayName)s enabled flair for %(newGroups)s and disabled flair for ' +
            '%(oldGroups)s in this room.', {
            senderDisplayName,
            newGroups: added.join(', '),
            oldGroups: removed.join(', '),
        });
    } else {
        // Don't bother rendering this change (because there were no changes)
        return '';
    }
}

function textForServerACLEvent(ev) {
    const senderDisplayName = getUserDisplayName(ev.sender, ev.getSender());
    const prevContent = ev.getPrevContent();
    const changes = [];
    const current = ev.getContent();
    const prev = {
        deny: Array.isArray(prevContent.deny) ? prevContent.deny : [],
        allow: Array.isArray(prevContent.allow) ? prevContent.allow : [],
        allow_ip_literals: !(prevContent.allow_ip_literals === false),
    };
    let text = '';
    if (prev.deny.length === 0 && prev.allow.length === 0) {
        text = `${senderDisplayName} set server ACLs for this room: `;
    } else {
        text = `${senderDisplayName} changed the server ACLs for this room: `;
    }

    if (!Array.isArray(current.allow)) {
        current.allow = [];
    }
    /* If we know for sure everyone is banned, don't bother showing the diff view */
    if (current.allow.length === 0) {
        return text + '🎉 All servers are banned from participating! This room can no longer be used.';
    }

    if (!Array.isArray(current.deny)) {
        current.deny = [];
    }

    const bannedServers = current.deny.filter((srv) => typeof (srv) === 'string' && !prev.deny.includes(srv));
    const unbannedServers = prev.deny.filter((srv) => typeof (srv) === 'string' && !current.deny.includes(srv));
    const allowedServers = current.allow.filter((srv) => typeof (srv) === 'string' && !prev.allow.includes(srv));
    const unallowedServers = prev.allow.filter((srv) => typeof (srv) === 'string' && !current.allow.includes(srv));

    if (bannedServers.length > 0) {
        changes.push(`Servers matching ${bannedServers.join(', ')} are now banned.`);
    }

    if (unbannedServers.length > 0) {
        changes.push(`Servers matching ${unbannedServers.join(', ')} were removed from the ban list.`);
    }

    if (allowedServers.length > 0) {
        changes.push(`Servers matching ${allowedServers.join(', ')} are now allowed.`);
    }

    if (unallowedServers.length > 0) {
        changes.push(`Servers matching ${unallowedServers.join(', ')} were removed from the allowed list.`);
    }

    if (prev.allow_ip_literals !== current.allow_ip_literals) {
        const allowban = current.allow_ip_literals ? 'allowed' : 'banned';
        changes.push(`Participating from a server using an IP literal hostname is now ${allowban}.`);
    }

    return text + changes.join(' ');
}

function textForMessageEvent(ev) {
    const senderDisplayName = getUserDisplayName(ev.sender, ev.getSender());
    let message = senderDisplayName + ': ' + ev.getContent().body;
    const msgType = ev.getContent().msgtype;
    if (msgType === 'm.emote') {
        message = '* ' + senderDisplayName + ' ' + message;
    } else if (msgType === 'm.image') {
        message = _t('%(senderDisplayName)s sent an image.', { senderDisplayName });
    } else if (msgType === 'm.news') {
        message = senderDisplayName + ': News \nTitle:\n' + ev.getContent().body + '\nDescription:\n ' + ev.getContent().newsbody;
    }
    return message;
}

function textForRoomAliasesEvent(ev) {
    // An alternative implementation of this as a first-class event can be found at
    // https://github.com/matrix-org/matrix-react-sdk/blob/dc7212ec2bd12e1917233ed7153b3e0ef529a135/src/components/views/messages/RoomAliasesEvent.js
    // This feels a bit overkill though, and it's not clear the i18n really needs it
    // so instead it's landing as a simple textual event.

    const senderName = getUserDisplayName(ev.sender, ev.getSender());
    const oldAliases = ev.getPrevContent().aliases || [];
    const newAliases = ev.getContent().aliases || [];

    const addedAliases = newAliases.filter((x) => !oldAliases.includes(x));
    const removedAliases = oldAliases.filter((x) => !newAliases.includes(x));

    if (!addedAliases.length && !removedAliases.length) {
        return '';
    }

    if (addedAliases.length && !removedAliases.length) {
        return _t('%(senderName)s added %(count)s %(addedAddresses)s as addresses for this room.', {
            senderName: senderName,
            count: addedAliases.length,
            addedAddresses: addedAliases.join(', '),
        });
    } else if (!addedAliases.length && removedAliases.length) {
        return _t('%(senderName)s removed %(count)s %(removedAddresses)s as addresses for this room.', {
            senderName: senderName,
            count: removedAliases.length,
            removedAddresses: removedAliases.join(', '),
        });
    } else {
        return _t(
            '%(senderName)s added %(addedAddresses)s and removed %(removedAddresses)s as addresses for this room.', {
                senderName: senderName,
                addedAddresses: addedAliases.join(', '),
                removedAddresses: removedAliases.join(', '),
            },
        );
    }
}

function textForCanonicalAliasEvent(ev) {
    const senderName = getUserDisplayName(ev.sender, ev.getSender());
    const oldAlias = ev.getPrevContent().alias;
    const newAlias = ev.getContent().alias;

    if (newAlias) {
        return _t('%(senderName)s set the main address for this room to %(address)s.', {
            senderName: senderName,
            address: ev.getContent().alias,
        });
    } else if (oldAlias) {
        return _t('%(senderName)s removed the main address for this room.', {
            senderName: senderName,
        });
    }
}

function textForCallAnswerEvent(event) {
    const senderName = event.sender ? event.sender.name : _t('Someone');
    const supported = MatrixClientPeg.get().supportsVoip() ? '' : _t('(not supported by this browser)');
    return _t('%(senderName)s answered the call.', { senderName }) + ' ' + supported;
}

function textForCallHangupEvent(event) {
    const senderName = event.sender ? event.sender.name : _t('Someone');
    const eventContent = event.getContent();
    let reason = '';
    if (!MatrixClientPeg.get().supportsVoip()) {
        reason = _t('(not supported by this browser)');
    } else if (eventContent.reason) {
        if (eventContent.reason === 'ice_failed') {
            reason = _t('(could not connect media)');
        } else if (eventContent.reason === 'invite_timeout') {
            reason = _t('(no answer)');
        } else if (eventContent.reason === 'user hangup') {
            // workaround for https://github.com/vector-im/riot-web/issues/5178
            // it seems Android randomly sets a reason of "user hangup" which is
            // interpreted as an error code :(
            // https://github.com/vector-im/riot-android/issues/2623
            reason = '';
        } else {
            reason = _t('(unknown failure: %(reason)s)', { reason: eventContent.reason });
        }
    }
    return _t('%(senderName)s ended the call.', { senderName }) + ' ' + reason;
}

function textForCallInviteEvent(event) {
    const senderName = event.sender ? event.sender.name : _t('Someone');
    // FIXME: Find a better way to determine this from the event?
    let callType = 'voice';
    if (event.getContent().offer && event.getContent().offer.sdp &&
        event.getContent().offer.sdp.indexOf('m=video') !== -1) {
        callType = 'video';
    }
    const supported = MatrixClientPeg.get().supportsVoip() ? '' : _t('(not supported by this browser)');
    return _t('%(senderName)s placed a %(callType)s call.', { senderName, callType }) + ' ' + supported;
}

function textForThreePidInviteEvent(event) {
    const senderName = getUserDisplayName(event.sender, event.getSender());

    // FIXME: We have two different cases here :
    // 1. The target user has an account, the server should send us an userId so we can retrieve the associated displayName
    //    from the user store.
    // 2. The target user does not have any account, the server must send us an email address (either complete or not to
    //    match with security / GDPR requirements. We dont have anything to do than display the data as received.
    // We need the back to update the content (or prev_content) of the event according to this. For the moment, email
    // values are referred as display_name in the event object.

    if (!isValid3pidInvite(event)) {
        return _t('%(senderName)s revoked the invitation for %(targetDisplayName)s to join the room.', {
            senderName,
            targetDisplayName: event.getPrevContent().display_name || _t('Someone'),
        });
    }

    return _t('%(senderName)s sent an invitation to %(targetDisplayName)s to join the room.', {
        senderName,
        targetDisplayName: event.getContent().display_name,
    });
}

function textForHistoryVisibilityEvent(event) {
    const visibility = event.getContent().history_visibility;

    const senderId = event.getSender();
    const senderName = getUserDisplayName(event.sender, senderId);

    const myUserId = localStorage.getItem('mx_user_id');

    switch (visibility) {
        case 'invited':
            if (senderId === myUserId) {
                return _t('Future members will not be able to see the history that precedes their invitation date in this room.');
            } else {
                return _t('%(senderName)s has made the history that precedes their invitation in this room not available for future members.', {
                    senderName,
                });
            }
        case 'joined':
            if (senderId === myUserId) {
                return _t('Future members will not be able to view the history that preceded their arrival in this room.');
            } else {
                return _t('%(senderName)s made the history that preceded their arrival in this discussion not available to future members.', {
                    senderName,
                });
            }
        case 'shared':
            if (senderId === myUserId) {
                return _t('From now on, all the exchanges of this room are visible by the people who are members of it. '
                    + 'This change does not apply to previously sent messages.');
            } else {
                return _t('%(senderName)s has now made all the exchanges of this room visible by the people who are members of it. '
                    + 'This change does not apply to previously sent messages.', { senderName });
            }
        case 'world_readable':
            if (senderId === myUserId) {
                return _t('Discussions in this room are now visible to all members of your company. '
                    + 'This change does not apply to previously sent messages.');
            } else {
                return _t('%(senderName)s has now made the discussions in this room are visible to all members of your company. '
                    + 'This change does not apply to previously sent messages', { senderName });
            }
        default:
            return _t('%(senderName)s made future room history visible to unknown (%(visibility)s).', {
                senderName,
                visibility,
            });
    }
}

function textForEncryptionEvent(event) {
    return _t('%(senderName)s turned on end-to-end encryption.', {
        senderName: getUserDisplayName(event.sender, event.getSender()),
    });
}

// Currently will only display a change if a user's power level is changed
function textForPowerEvent(event) {
    if (!event.getPrevContent() || !event.getPrevContent().users) {
        return '';
    }
    const userDefault = event.getContent().users_default || 0;
    // Construct set of userIds
    const users = [];
    Object.keys(event.getContent().users).forEach(
        (userId) => {
            if (users.indexOf(userId) === -1) users.push(userId);
        },
    );
    Object.keys(event.getPrevContent().users).forEach(
        (userId) => {
            if (users.indexOf(userId) === -1) users.push(userId);
        },
    );
    const diff = [];
    // XXX: This is also surely broken for i18n
    users.forEach((userId) => {
        // Previous power level
        const from = event.getPrevContent().users[userId];
        // Current power level
        const to = event.getContent().users[userId];
        if (to !== from) {
            const userName = getUserDisplayName({}, userId);
            diff.push(
                _t('%(userId)s from %(fromPowerLevel)s to %(toPowerLevel)s', {
                    userId: userName,
                    fromPowerLevel: Roles.textualPowerLevel(from, userDefault),
                    toPowerLevel: Roles.textualPowerLevel(to, userDefault),
                }),
            );
        }
    });
    if (!diff.length) {
        return '';
    }
    return _t('%(senderName)s changed the power level of %(powerLevelDiffText)s.', {
        senderName: getUserDisplayName(event.sender, event.getSender()),
        powerLevelDiffText: diff.join(', '),
    });
}

function textForPinnedEvent(event) {
    return _t('%(senderName)s changed the pinned messages for the room.', {
        senderName: getUserDisplayName(event.sender, event.getSender()),
    });
}

function textForWidgetEvent(event) {
    const senderName = getUserDisplayName(event.sender, event.getSender());
    const { name: prevName, type: prevType, url: prevUrl } = event.getPrevContent();
    const { name, type, url } = event.getContent() || {};

    let widgetName = name || prevName || type || prevType || '';
    // Apply sentence case to widget name
    if (widgetName && widgetName.length > 0) {
        widgetName = widgetName[0].toUpperCase() + widgetName.slice(1) + ' ';
    }

    // If the widget was removed, its content should be {}, but this is sufficiently
    // equivalent to that condition.
    if (url) {
        if (prevUrl) {
            return _t('%(widgetName)s widget modified by %(senderName)s', {
                widgetName, senderName,
            });
        } else {
            return _t('%(widgetName)s widget added by %(senderName)s', {
                widgetName, senderName,
            });
        }
    } else {
        return _t('%(widgetName)s widget removed by %(senderName)s', {
            widgetName, senderName,
        });
    }
}

function textForRoomCreatedEvent(event) {
    const myUserId = localStorage.getItem('mx_user_id');
    const dmRoomMap = DMRoomMap.shared();

    const senderId = event.getSender();
    const senderName = getUserDisplayName(event.sender, senderId);

    if (dmRoomMap.getUserIdForRoomId(event.getRoomId())) {
        if (senderId === myUserId) {
            return _t('You started this chat.');
        } else {
            return _t('%(senderName)s started this chat.', { senderName });
        }
    } else {
        if (senderId === myUserId) {
            return _t('You just created this private room.');
        } else {
            return '';
        }
    }
}

const textForConferenceEvent = (event) => {
    const { status } = event.getContent();
    const { inviter_id: userId } = event.getContent();
    const userDisplayName = UserStore.getUserById(userId).displayName;
    const conferenceInviter = userDisplayName || userId;

    return status === 'open'
        ? _t('%(name)s started a conference', { name: conferenceInviter })
        : _t('The conference started by %(name)s is closed', { name: conferenceInviter });
};

const handlers = {
    'm.room.message': textForMessageEvent,
    'm.call.invite': textForCallInviteEvent,
    'm.call.answer': textForCallAnswerEvent,
    'm.call.hangup': textForCallHangupEvent,
};

const stateHandlers = {
    'm.room.create': textForRoomCreatedEvent,
    'm.room.aliases': textForRoomAliasesEvent,
    'm.room.canonical_alias': textForCanonicalAliasEvent,
    'm.room.name': textForRoomNameEvent,
    'm.room.topic': textForTopicEvent,
    'm.room.member': textForMemberEvent,
    'm.room.third_party_invite': textForThreePidInviteEvent,
    'm.room.history_visibility': textForHistoryVisibilityEvent,
    'm.room.encryption': textForEncryptionEvent,
    'm.room.power_levels': textForPowerEvent,
    'm.room.pinned_events': textForPinnedEvent,
    'm.room.server_acl': textForServerACLEvent,
    'm.room.tombstone': textForTombstoneEvent,
    'm.room.join_rules': textForJoinRulesEvent,
    'm.room.guest_access': textForGuestAccessEvent,
    'm.room.related_groups': textForRelatedGroupsEvent,
    'im.vector.modular.widgets': textForWidgetEvent,
    'citadel.conference': textForConferenceEvent,
};

module.exports = {
    textForEvent: function(ev, followsCreateEvent, extraProps) {
        const handler = (ev.isState() ? stateHandlers : handlers)[ev.getType()];
        if (handler) {
            return handler(ev, followsCreateEvent, extraProps);
        }
        return '';
    },
};
