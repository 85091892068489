import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { _t } from '../../../../languageHandler';
import { getDateAndTime } from '../../../../DateUtils';
import BaseDialog from '../BaseDialog';
import NewsDialogContent from './NewsDialogContent';

class NewsPreviewDialog extends Component {
    static displayName = "NewsPreviewDialog";

    static propTypes = {
        content: PropTypes.string.isRequired,
        imageUrl: PropTypes.string,
        onFinished: PropTypes.func.isRequired,
        title: PropTypes.string,
    };

    static defaultProps = {
        content: '',
        onFinished: noop,
        title: 'Title...',
    };

    state = { initialDate: new Date() };

    handleGoBack = () => {
        const { onGoBack } = this.props;
        onGoBack();
    };

    render() {
        const { title, imageUrl, content, onFinished, onSend } = this.props;
        const { initialDate } = this.state;

        return (
            <BaseDialog
                title={'title'}
                hasCancel={true}
                onFinished={this.handleGoBack}
            >
                <div>
                <NewsDialogContent
                    content={content}
                    imageUrl={imageUrl}
                    onGoBack={this.handleGoBack}
                    onPostNews={onSend(onFinished)}
                    subtitle={
                        <Fragment>
                            <span className="preview-info" >{_t('Preview')}</span>
                            <span>{` - ${getDateAndTime(initialDate)}`}</span>
                        </Fragment>
                    }
                    title={title}
                />
                </div>
            </BaseDialog>
        );
    }
}

export default NewsPreviewDialog;
