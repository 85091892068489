/**
 * Forked from https://github.com/40818419/react-code-input
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty, isEqual } from 'lodash';
import { uuidv4 } from '../../../utils/CitadelUtils';

const BACKSPACE_KEY = 8;
const LEFT_ARROW_KEY = 37;
const UP_ARROW_KEY = 38;
const RIGHT_ARROW_KEY = 39;
const DOWN_ARROW_KEY = 40;
const E_KEY = 69;

class CitadelCodeInput extends Component {
    constructor(props) {
        super(props);

        const { fields, type, isValid, disabled, filterKeyCodes, forceUppercase } = props;
        let { value } = props;

        if (forceUppercase) {
            value = value.toUpperCase();
        }

        this.state = {
            value,
            fields,
            type: type === 'number' ? 'tel' : type,
            input: [],
            isValid,
            disabled,
            filterKeyCodes,
            hasFocus: false,
            defaultInputStyle: {
                fontFamily: 'monospace',
                MozAppearance: 'textfield',
                borderRadius: '6px',
                border: '1px solid',
                boxShadow: '0px 0px 10px 0px rgba(0,0,0,.10)',
                margin: '4px',
                paddingLeft: '8px',
                paddingRight: 0,
                width: '36px',
                height: '42px',
                fontSize: '32px',
                boxSizing: 'border-box',
            },
        };

        for (let i = 0; i < Number(this.state.fields); i += 1) {
            if (i < 32) {
                const value = this.state.value[i] || '';
                this.state.input.push(value);
            }
        }
        this.textInput = [];

        this.uuid = uuidv4();
    }

    componentDidUpdate(prevProps): void {
        if (!isEqual(this.props, prevProps)) {
            this.setState({
                isValid: this.props.isValid,
                value: this.props.value,
                disabled: this.props.disabled,
            });
        }
        if (!this.props.value && this.props.value !== prevProps.value) {
            this.textInput = [];

            const input = [];
            for (let i = 0; i < Number(this.state.fields); i += 1) {
                if (i < 32) input.push('');
            }
            this.setState({ input, value: [] });
        }
    }

    handleFocus = (e) => {
        const length = e.target.length;
        if (e.target.setSelectionRange) {
            e.target.setSelectionRange(length, length);
        } else {
            e.target.select(e);
        }
        this.setState({ hasFocus: true });
    };

    handleBlur = (e) => {
        this.handleTouch(e.target.value);
        this.setState({ hasFocus: false });
    };

    handleTouch = (value) => {
        const { touch, untouch, name } = this.props;

        if (typeof touch === 'function' && typeof untouch === 'function') {
            if (value === '') {
                touch(name);
            } else {
                untouch(name);
            }
        }
    }

    normalizeValue = (value, length) => {
        const { filterChars, forceUppercase } = this.props;
        let normalizedValue = value;
        if (forceUppercase) {
            normalizedValue = normalizedValue.toUpperCase();
        }

        if (this.state.type === 'tel') {
            normalizedValue = normalizedValue.replace(/[^\d]/g, '');
        }

        /** Filter Chars */
        return normalizedValue.split('').filter(currChar => !filterChars.includes(currChar)).join('');
    }

    handleChange = (e) => {
        const { fields: noOfFields, onChange, type } = this.props;
        const target = Number(e.target.dataset.id);
        let value = String(e.target.value);

        if (type === 'tel') {
            const valueAsNumber = parseInt(this.normalizeValue(e.target.value));
            if (isNaN(valueAsNumber)) {
                e.preventDefault();
                return;
            } else {
                value = e.target.value;
            }
        }

        value = this.normalizeValue(value);

        // remove previous value from the string if there was one
        value = isEmpty(this.state.input[target]) ? value : value.slice(0, -1);
        // if the string length is greater-than the number of fields
        // we'll take only the necessary number of chars
        value = value.slice(0, noOfFields - target);
        let fullValue = value;
        if (value !== '') {
            const input = this.state.input.slice();
            if (value.length > 1) {
                const splittedValue = value.split('');
                for (let i = 0; i < splittedValue.length; i++) {
                    if (target + i < noOfFields) {
                        input[target + i] = splittedValue[i];
                    }
                }
            } else {
                input[Number(e.target.dataset.id)] = value[0];
            }
            for (let i = 0; i < input.length; i++) {
                if (this.textInput[i]) {
                    this.textInput[i].value = input[i];
                }
            }
            const newTargetIdx = target < input.length - 1
                ? ((target + value.length < input.length)
                    ? (target + value.length)
                    : input.length - 1)
                : target;
            if (newTargetIdx) {
                this.textInput[newTargetIdx].focus();
                this.textInput[newTargetIdx].select();
            }

            fullValue = input.join('');

            this.setState({ value: input.join(''), input });
        }

        if (onChange && fullValue) {
            onChange(fullValue);
        }

        this.handleTouch(fullValue);
    }

    handleKeyDown = (e) => {
        const target = Number(e.target.dataset.id);
        const nextTarget = this.textInput[target + 1];
        const prevTarget = this.textInput[target - 1];

        let input;
        let value;

        if (this.state.filterKeyCodes.length > 0) {
            for (const value of this.state.filterKeyCodes) {
                if (value === e.keyCode) {
                    e.preventDefault();
                }
            }
        }

        switch (e.keyCode) {
            case BACKSPACE_KEY:
                e.preventDefault();
                this.textInput[target].value = '';
                input = this.state.input.slice();
                input[target] = '';
                value = input.join('');

                this.setState({ value, input });
                if (this.textInput[target].value === '') {
                    if (prevTarget) {
                        prevTarget.focus();
                        prevTarget.select();
                    }
                }
                if (this.props.onChange) {
                    this.props.onChange(value);
                }
                break;

            case LEFT_ARROW_KEY:
                e.preventDefault();
                if (prevTarget) {
                    prevTarget.focus();
                    prevTarget.select();
                }
                break;

            case RIGHT_ARROW_KEY:
                e.preventDefault();
                if (nextTarget) {
                    nextTarget.focus();
                    nextTarget.select();
                }
                break;

            case UP_ARROW_KEY:
                e.preventDefault();
                break;

            case DOWN_ARROW_KEY:
                e.preventDefault();
                break;

            case E_KEY: // This case needs to be handled because of https://stackoverflow.com/questions/31706611/why-does-the-html-input-with-type-number-allow-the-letter-e-to-be-entered-in
                if (e.target.type === 'number') {
                    e.preventDefault();
                }
                break;

            default:
                break;
        }

        this.handleTouch(value);
    }

    render() {
        const {
            className,
            style = {},
            inputStyle = {},
            inputStyleInvalid = {},
            type, autoFocus, pattern, inputMode, groupSize, label, error,
        } = this.props;
        const { disabled, input, isValid, hasFocus } = this.state;
        const styles = {
            container: style,
            input: isValid ? inputStyle : inputStyleInvalid,
        };

        const codeInputComponents = [];
        const inputClassName = hasFocus ? 'focused' : '';

        for (let i = 0; i < input.length; i++) {
            const val = input[i];
            const showSeparator = groupSize > 0 && i > 0
                && (i + 1) % groupSize === 0
                && i < input.length - 1;

            codeInputComponents.push(
                <input
                    className={error ? 'error' : inputClassName}
                    ref={(ref) => {
                        this.textInput[i] = ref;
                    }}
                    id={`${this.uuid}-${i}`}
                    key={`input_${i}`}
                    data-id={i}
                    autoFocus={autoFocus && (i === 0) ? 'autoFocus' : ''}
                    value={val}
                    type={type}
                    style={styles.input}
                    autoComplete="off"
                    onSelect={this.handleFocus}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    disabled={disabled}
                    data-valid={isValid}
                    pattern={pattern}
                    inputMode={inputMode}
                />,
            );

            if (showSeparator) {
                codeInputComponents.push(<span key={`separator_${i}`}>-</span>);
            }
        }

        return (
            <div className={classNames("code-input-wrapper", { error })}>
                {label && <div className="label">{label}</div>}
                <div className={classNames(className, 'code-input')} style={styles.container}>
                    {codeInputComponents}
                    <div className={`x-error ${error ? 'displayed' : ''}`} />
                </div>
                {error && <div className="error">{error}</div>}
            </div>
        );
    }
}

CitadelCodeInput.defaultProps = {
    autoFocus: true,
    isValid: true,
    disabled: false,
    forceUppercase: false,
    fields: 4,
    groupSize: 3,
    label: '',
    value: '',
    type: 'text',
    filterKeyCodes: [189, 190],
    filterChars: ['-', '.'],
};

CitadelCodeInput.propTypes = {
    type: PropTypes.oneOf(['text', 'number', 'password', 'tel']),
    fields: PropTypes.number,
    groupSize: PropTypes.number,
    label: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    onChange: PropTypes.func,
    name: PropTypes.string,
    touch: PropTypes.func,
    untouch: PropTypes.func,
    className: PropTypes.string,
    isValid: PropTypes.bool,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    inputStyle: PropTypes.object,
    inputStyleInvalid: PropTypes.object,
    autoFocus: PropTypes.bool,
    forceUppercase: PropTypes.bool,
    filterKeyCodes: PropTypes.array,
    filterChars: PropTypes.array,
    pattern: PropTypes.string,
    inputMode: PropTypes.oneOf([
        'verbatim', 'latin', 'latin-name', 'latin-prose',
        'full-width-latin', 'kana', 'kana-name', 'katakana',
        'numeric', 'tel', 'email', 'url',
    ]),
};

export default CitadelCodeInput;
