import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ReadReceiptFullList from './ReadReceiptFullList';
import ReadReceiptMarker from '../rooms/ReadReceiptMarker';
import HintManager from '../../../HintManager';

const MAX_READ_AVATARS = 5;

export const getElementPosHintAgainstReadMarkers = (eventTs) => {
    let isReadMarkerVisible = false;
    let shouldElementBeAbove = true;

    const timelineElement = document.querySelector('.mx_RoomView_timeline');
    const { height: timelineHeight, top: topStart } =
        timelineElement ? timelineElement.getBoundingClientRect() : {};

    if (timelineHeight && topStart) {
        const topMid = timelineHeight / 2 + topStart;
        const topBottom = timelineHeight + topStart;

        const readMarkerElement = document.querySelector(`.read_marker_${eventTs}`);
        const readMarkerTop = readMarkerElement && readMarkerElement.getBoundingClientRect().top;

        const extraMargin = 10; // readMarkerReceiptHeight/2
        isReadMarkerVisible = ((topBottom - extraMargin) > readMarkerTop) &&
            (readMarkerTop + extraMargin > topStart);
        shouldElementBeAbove = topMid < readMarkerTop;
    }

    return { isReadMarkerVisible, shouldElementBeAbove };
};

export class ReadReceiptWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isFullListOpened: false,
        };
        this._suppressReadReceiptAnimation = true;
    }

    componentDidMount() {
        this._suppressReadReceiptAnimation = false;
    }

    displayReadReceiptFullList = () => {
        const { eventTs } = this.props;
        const { isFullListOpened } = this.state;

        let fullListStyle;
        if (isFullListOpened) HintManager.checkPendingHints();
        else {
            const { shouldElementBeAbove } = getElementPosHintAgainstReadMarkers(eventTs);
            fullListStyle = {
                bottom: shouldElementBeAbove ? 23 : 'unset',
                top: shouldElementBeAbove ? 'unset' : 43,
            };
        }

        this.props.closeHint();

        this.setState({
            isFullListOpened: !isFullListOpened,
            fullListStyle,
        });
    };

    render() {
        const { receipts, readReceiptMap, checkUnmounting, isTwelveHour,
            mightBeHinted, isCurrentlyHinted } = this.props;
        const { isFullListOpened, fullListStyle } = this.state;
        const activeReceipts = receipts.filter(receipt => receipt.roomMember);

        const avatars = [];
        const receiptOffset = 20;
        let left = 0;

        const remainder = activeReceipts.length - MAX_READ_AVATARS;
        let visibleAvatars = 0;
        for (let i = 0; i < activeReceipts.length; ++i) {
            const receipt = activeReceipts[i];

            let hidden = true;
            if (i < MAX_READ_AVATARS) {
                hidden = false;
                visibleAvatars++;
            }

            left = (receiptOffset * MAX_READ_AVATARS) - (i * receiptOffset);

            const userId = receipt.userId;
            let readReceiptInfo;

            if (readReceiptMap) {
                readReceiptInfo = readReceiptMap[userId];
                if (!readReceiptInfo) {
                    readReceiptInfo = {};
                    readReceiptMap[userId] = readReceiptInfo;
                }
            }

            // add to the start so the most recent is on the end (ie. ends up rightmost)
            avatars.unshift(
                <ReadReceiptMarker
                    key={userId} member={receipt.roomMember}
                    fallbackUserId={userId}
                    leftOffset={left} hidden={hidden}
                    readReceiptInfo={readReceiptInfo}
                    checkUnmounting={checkUnmounting}
                    suppressAnimation={this._suppressReadReceiptAnimation}
                    onClick={this.displayReadReceiptFullList}
                    timestamp={receipt.ts}
                    showTwelveHour={isTwelveHour}
                />,
            );
        }

        let remText;
        if (remainder > 0) {
            remText = <span className="mx_EventTile_readAvatarRemainder"
                            onClick={this.displayReadReceiptFullList}
                            style={{ right: -20 }}>+{remainder}
                </span>;
        }

        const className = classNames('mx_EventTile_readAvatars', {
            'might_be_hinted': mightBeHinted,
            'mx_EventTile_readAvatars_hinted': isCurrentlyHinted,
        });

        return <span className={className}>
            {remText}
            {avatars}
            {isCurrentlyHinted && <div
                style={{width: 20*visibleAvatars + 2, left: 16 + 20*(5-visibleAvatars)}}
                className='highlight'
            />}
            {isFullListOpened && <ReadReceiptFullList
                receipts={activeReceipts}
                closeReceiptList={this.displayReadReceiptFullList}
                style={fullListStyle}
                isTwelveHour={isTwelveHour}
            />}
        </span>;
    }
}

ReadReceiptWrapper.propTypes = {
    receipts: PropTypes.arrayOf(PropTypes.object),
    readReceiptMap: PropTypes.object,
    checkUnmounting: PropTypes.func,
    isTwelveHour: PropTypes.bool,
    allReadAvatars: PropTypes.bool,
    mightBeHinted: PropTypes.bool,
    isCurrentlyHinted: PropTypes.bool,
    closeHint: PropTypes.func,
};
