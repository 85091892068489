import utils from '../utils';

/**
 * Internal module. Management of the new room key sharing process
 * implemented through Citadel clients.
 * Key provider side.
 *
 * @module
 */

/** possible states for a Citadel room key request
 *
 *  The state machine looks like:
 *
 *     (init) <--------------------------.----------------------------------.
 *      |                                |                                  |
 *      |  (in: citadel.code_start)      |                                  |
 *      |                                |  (in: citadel.code_cancel)       |
 *      V                                |                                  |
 *  CODE_DISPLAYED                       |                                  |
 *      |                                |                                  |
 *      |--------------------------------'                                  |  (out: citadel.code_cancel)
 *      |                                                                   |  received code not valid and
 *      |  (out: citadel.code_ready)                                        |  maximum number of attempts reached
 *      |                                                                   |
 *      V                                                                   |
 *  CODE_WAITING <-----------------------.                                  |
 *      |                                |                                  |
 *      |                                |  (out: citadel.code_ready)       |
 *      |                                |  received code not valid but     |
 *      |                                |  maximum number of attempts      |
 *      |                                |  not reached yet                 |
 *      |                                |                                  |
 *      |--------------------------------'                                  |
 *      |-------------------------------------------------------------------'
 *      |
 *      |  (out: citadel.code_valid)
 *      V
 *     CODE_VALIDATED --> (out: citadel.device_keys_export) -> (init)
 */

const RETRY_LIMIT = 5;

const EASY_KEY_SEND_STATES = {
    // init, no request has been received yet
    INIT: null,

    // request has been received, a code has been generated and displayed
    CODE_DISPLAYED: 0,

    // waiting for code validation
    CODE_WAITING: 1,

    // code validated
    CODE_VALIDATED: 2,

    // waiting for external code validation
    EXTERNAL_CODE_WAITING: 3,
};

export default class EasyKeyProviderManager {
    constructor(crypto) {
        this._crypto = crypto;
        this.reset();
    }

    reset() {
        this._state = EASY_KEY_SEND_STATES.INIT;
        this._timestamp = '';
        this._sessionId = '';
        this._remoteDeviceInfo = null;
        this._code = '';
        this._retries = null;
    }

    verifySession(remoteSessionId) {
        return (remoteSessionId === this._sessionId);
    }

    async onEasyKeyRequestStartReceived(event) {
        const { sender_device: remoteDeviceId, content } = event;
        const { sessionId } = content;

        if (this._state) await this.sendCancel();

        this._remoteDeviceInfo = this._crypto.getDeviceInfo(remoteDeviceId);
        this._timestamp = utils.getTimestamp();
        this._sessionId = sessionId;

        // Generates 6-digit code to verify devices so the key sharing process
        // can proceed.
        const codeLength = 6;
        const array = new Uint8Array(codeLength);
        this._code = window.crypto.getRandomValues(array).join('').slice(0, codeLength);
        this._retries = RETRY_LIMIT;

        this._state = EASY_KEY_SEND_STATES.CODE_DISPLAYED;
        return this._code;
    }

    async onCodeSubmitReceived(event) {
        const { sender_device: remoteDeviceId, content } = event;
        const { sessionId, code } = content;

        // Verify that the requester device has sent this message within the ongoing session or not.
        if (!this.verifySession(sessionId)) {
            // At this point, the requester device might be different from the one who initially started
            // the ongoing session.
            const remoteDeviceInfo = this._crypto.getDeviceInfo(remoteDeviceId);
            await this.sendCancel(remoteDeviceInfo);

            return false;
        }

        if (code === this._code) {
            // Tell the requester that the code has been validated.
            // Keys are prepared (exported) to be sent.
            await this._crypto.encryptedSendToSingleDevice(
                this._remoteDeviceInfo,
                {
                    type: 'citadel.code_valid',
                    content: {
                        timestamp: this._timestamp,
                        sessionId: this._sessionId,
                    },
                },
            );

            this._state = EASY_KEY_SEND_STATES.CODE_VALIDATED;

            return true;
        } else {
            this._retries--;

            if (this._retries === 0) {
                await this.sendCancel();
                this.reset();
            } else {
                // Tell the requester that the submitted code was not valid
                // thus ask it to do another attempt.
                // TODO: make sure retry code type is shared between clients (web + mobile)
                await this._crypto.encryptedSendToSingleDevice(
                    this._remoteDeviceInfo,
                    {
                        type: 'citadel.code_retry',
                        content: {
                            timestamp: this._timestamp,
                            sessionId: this._sessionId,
                            triesLeft: this._retries,
                        },
                    },
                );
            }

            return false;
        }
    }

    onCancelReceived({ content: { sessionId } }) {
        if (this.verifySession(sessionId)) {
            this.reset();

            return true;
        }
        return false;
    }

    async sendCodeReady() {
        await this._crypto.encryptedSendToSingleDevice(
            this._remoteDeviceInfo,
            {
                type: 'citadel.code_ready',
                content: {
                    timestamp: this._timestamp,
                    sessionId: this._sessionId,
                },
            },
        );

        this._state = EASY_KEY_SEND_STATES.CODE_WAITING;
    }

    async sendExternalCodeReady() {
        await this._crypto.encryptedSendToSingleDevice(
            this._remoteDeviceInfo,
            {
                type: 'citadel.external_code_ready',
                content: {
                    timestamp: this._timestamp,
                    sessionId: this._sessionId,
                },
            },
        );

        this._state = EASY_KEY_SEND_STATES.EXTERNAL_CODE_WAITING;
    }


    async sendKeys(url, password) {
        await this._crypto.encryptedSendToSingleDevice(
            this._remoteDeviceInfo,
            {
                type: 'citadel.device_keys_export',
                content: {
                    url,
                    password,
                    sessionId: this._sessionId,
                    timestamp: this._timestamp,
                },
            },
        );

        this.reset();
        this._crypto.emit("crypto.easyKeyEvent", { type: "cancel" });
    }

    /**
     * Sends a cancel message to a requester device.
     *
     * @param {string} deviceInfo If undefined, the request will be sent
     *                 to the requester device the provider device is
     *                 having an ongoing session with.
     */
    async sendCancel(deviceInfo) {
        await this._crypto.encryptedSendToSingleDevice(
            deviceInfo || this._remoteDeviceInfo,
            {
                type: 'citadel.code_cancel',
                content: {
                    timestamp: this._timestamp,
                    sessionId: this._sessionId,
                },
            },
        );
    }
}
