/*
Copyright 2015, 2016 OpenMarket Ltd
Copyright 2017, 2018 Vector Creations Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

/*
 * State vars:
 * 'can': {
 *   kick: boolean,
 *   mute: boolean,
 *   modifyLevel: boolean
 * },
 * 'muted': boolean,
 * 'isTargetMod': boolean
 */
import React from 'react';
import PropTypes from 'prop-types';

import dis from '../../../dispatcher';
import Modal from '../../../Modal';
import sdk from '../../../index';
import { _t } from '../../../languageHandler';
import createRoom from '../../../createRoom';
import Unread from '../../../Unread';
import { findReadReceiptFromUserId } from '../../../utils/Receipt';
import withMatrixClient from '../../../wrappers/withMatrixClient';
import AccessibleButton from '../elements/AccessibleButton';
import RoomViewStore from '../../../stores/RoomViewStore';
import SdkConfig from '../../../SdkConfig';

import { fetchUrlWithToken, getMxcFromUrl } from '../../../utils/MediaUtils';
import MxcBlobStore from '../../../stores/MxcBlobStore';
import NewsStore from '../../../stores/NewsStore';

import MultiInviter from '../../../utils/MultiInviter';
import SettingsStore from '../../../settings/SettingsStore';
import AutoHideScrollbar from '../../structures/AutoHideScrollbar';
import UserStore from '../../../stores/UserStore';
import { getDirectMessageRooms } from '../../../RoomInvite';
import RoomTile from '../rooms/RoomTile';
import MatrixClientPeg from "../../../MatrixClientPeg";
import { getJoinRules, isDirectMessageRoom } from '../../../Rooms';


export default withMatrixClient(class extends React.Component {
    static displayName = 'MemberInfo';

    static propTypes = {
        matrixClient: PropTypes.object.isRequired,
        member: PropTypes.object.isRequired,
    };

    state = {
        can: {
            kick: false,
            mute: false,
            ban: false,
            modifyLevel: false,
        },
        muted: false,
        isTargetMod: false,
        updating: 0,
        isIgnoring: false,
        emailLoading: true,
        dmRooms: [],
    };

    componentDidMount() {
        const cli = this.props.matrixClient;
        cli.on("Room", this.onRoom);
        cli.on("deleteRoom", this.onDeleteRoom);
        cli.on("Room.timeline", this.onRoomTimeline);
        cli.on("Room.name", this.onRoomName);
        cli.on("Room.receipt", this.onRoomReceipt);
        cli.on("RoomState.events", this.onRoomStateEvents);
        cli.on("RoomMember.name", this.onRoomMemberName);
        cli.on("RoomMember.membership", this.onRoomMemberMembership);
        cli.on("RoomMember.powerLevel", this.onRoomMemberPowerlevel);
        cli.on("accountData", this.onAccountData);
        this.mxcBlobStoreToken = MxcBlobStore.addListener(this._getAvatar);

        this._checkIgnoreState();
        this._updateStateForNewMember(this.props.member);
    }

    componentDidUpdate(prevProps) {
        if (this.props.member.userId !== prevProps.member.userId) {
            this._updateStateForNewMember(this.props.member);
        }
    }

    componentWillUnmount() {
        const client = this.props.matrixClient;
        if (client) {
            client.removeListener("Room", this.onRoom);
            client.removeListener("deleteRoom", this.onDeleteRoom);
            client.removeListener("Room.timeline", this.onRoomTimeline);
            client.removeListener("Room.name", this.onRoomName);
            client.removeListener("Room.receipt", this.onRoomReceipt);
            client.removeListener("RoomState.events", this.onRoomStateEvents);
            client.removeListener("RoomMember.name", this.onRoomMemberName);
            client.removeListener("RoomMember.membership", this.onRoomMemberMembership);
            client.removeListener("RoomMember.powerLevel", this.onRoomMemberPowerlevel);
            client.removeListener("accountData", this.onAccountData);
        }
        this.mxcBlobStoreToken.remove();
    }

    _checkIgnoreState = () => {
        const isIgnoring = this.props.matrixClient.isUserIgnored(this.props.member.userId);
        this.setState({isIgnoring: isIgnoring});
    };

    _getAvatar = (paramMember) => {
        const member = paramMember || this.props.member;
        const avatarUrl = member.getMxcAvatarUrl();

        if (!avatarUrl) return;

        const httpUrl = this.props.matrixClient.mxcUrlToHttp(avatarUrl);
        const mxc = getMxcFromUrl(httpUrl);
        const blob = MxcBlobStore.getBlobFromMxc(mxc);

        if (blob) {
            this.setState({
                avatarBlob: blob,
            });
        } else {
            fetchUrlWithToken(httpUrl)
            .then((blob) => {
                if (blob) {
                    dis.dispatch({
                        action: 'update_blob_store',
                        mxc: mxc,
                        blob: blob,
                    });

                    this.setState({
                        avatarBlob: blob,
                    });
                }
            })
            .catch((err) => {
                console.error('Could not retrieve url content', err);
            });
        }
    };

    onRoom = (room) => {
        this.forceUpdate();
    };

    onDeleteRoom = (roomId) => {
        this.forceUpdate();
    };

    onRoomTimeline = (ev, room, toStartOfTimeline) => {
        if (toStartOfTimeline) return;
        this.forceUpdate();
    };

    onRoomName = (room) => {
        this.forceUpdate();
    };

    onRoomMemberPowerlevel = (ev, member) => {
        if (this.props.member.userId === member.userId) this.setState(this._calculateOpsPermissions(member));
    };

    onRoomReceipt = (receiptEvent, room) => {
        // because if we read a notification, it will affect notification count
        // only bother updating if there's a receipt from us
        if (findReadReceiptFromUserId(receiptEvent, this.props.matrixClient.credentials.userId)) {
            this.forceUpdate();
        }
    };

    onRoomStateEvents = (ev, state) => {
        this.forceUpdate();
    };

    onRoomMemberName = (ev, member) => {
        this.forceUpdate();
    };

    onRoomMemberMembership = (ev, member) => {
        if (this.props.member.userId === member.userId) this.forceUpdate();
    };

    onAccountData = (ev) => {
        if (ev.getType() === 'm.direct') {
            this.forceUpdate();
        }
    };

    _updateStateForNewMember = async (member) => {
        this._getAvatar(member);

        const opsPermissions = await this._calculateOpsPermissions(member);
        const dmRooms = await getDirectMessageRooms(member.userId);
        const email = UserStore.getUserProp(member.userId, 'email');

        this.setState({
            ...opsPermissions,
            dmRooms,
            email,
            emailLoading: false,
        });
    };

    onIgnoreToggle = () => {
        const ignoredUsers = this.props.matrixClient.getIgnoredUsers();
        if (this.state.isIgnoring) {
            const index = ignoredUsers.indexOf(this.props.member.userId);
            if (index !== -1) ignoredUsers.splice(index, 1);
        } else {
            ignoredUsers.push(this.props.member.userId);
        }

        this.props.matrixClient.setIgnoredUsers(ignoredUsers).then(() => {
            return this.setState({isIgnoring: !this.state.isIgnoring});
        });
    };

    onKick = () => {
        const membership = this.props.member.membership;
        const ConfirmUserActionDialog = sdk.getComponent("dialogs.ConfirmUserActionDialog");
        Modal.createTrackedDialog('Confirm User Action Dialog', 'onKick', ConfirmUserActionDialog, {
            member: this.props.member,
            action: membership === "invite" ? _t("Disinvite") : _t("Remove user"),
            title: membership === "invite" ?
                _t("Disinvite this user?") :
                _t("Are you sure you want to remove this user from this room?"),
            askReason: membership === "join",
            danger: true,
            onFinished: (proceed, reason) => {
                if (!proceed) return;

                this.setState({ updating: this.state.updating + 1 });
                this.props.matrixClient.kick(
                    this.props.member.roomId, this.props.member.userId,
                    reason || undefined,
                ).then(function() {
                        // NO-OP; rely on the m.room.member event coming down else we could
                        // get out of sync if we force setState here!
                        console.log("Kick success");
                    }, function(err) {
                        const ErrorDialog = sdk.getComponent("dialogs.ErrorDialog");
                        console.error("Kick error: " + err);
                        Modal.createTrackedDialog('Failed to kick', '', ErrorDialog, {
                            title: _t("Failed to remove"),
                            description: ((err && err.message) ? err.message : "Operation failed"),
                        });
                    },
                ).finally(()=>{
                    this.setState({ updating: this.state.updating - 1 });
                });
            },
        });
    };

    onMuteToggle = async () => {
        const ErrorDialog = sdk.getComponent("dialogs.ErrorDialog");
        const roomId = this.props.member.roomId;
        const target = this.props.member.userId;
        const room = this.props.matrixClient.getRoom(roomId);
        if (!room) return;

        const powerLevelEvent = room.currentState.getStateEvents("m.room.power_levels", "");
        if (!powerLevelEvent) return;

        const isMuted = this.state.muted;
        const powerLevels = powerLevelEvent.getContent();
        const levelToSend = (
            (powerLevels.events ? powerLevels.events["m.room.message"] : null) ||
            powerLevels.events_default
        );
        let level;
        if (isMuted) { // unmute
            level = levelToSend;
        } else { // mute
            level = levelToSend - 1;
        }
        level = parseInt(level);

        if (!isNaN(level)) {
            this.setState({ updating: this.state.updating + 1 });
            this.props.matrixClient.setPowerLevel(roomId, target, level, powerLevelEvent).then(
                function() {
                    // NO-OP; rely on the m.room.member event coming down else we could
                    // get out of sync if we force setState here!
                    console.log("Mute toggle success");
                }, function(err) {
                    console.error("Mute error: " + err);
                    Modal.createTrackedDialog('Failed to mute user', '', ErrorDialog, {
                        title: _t("Error"),
                        description: _t("Failed to mute user"),
                    });
                },
            ).finally(()=>{
                this.setState({ updating: this.state.updating - 1 });
            });
        }
    };

    onModToggle = () => {
        const ErrorDialog = sdk.getComponent("dialogs.ErrorDialog");
        const roomId = this.props.member.roomId;
        const target = this.props.member.userId;
        const room = this.props.matrixClient.getRoom(roomId);
        if (!room) return;

        const powerLevelEvent = room.currentState.getStateEvents("m.room.power_levels", "");
        if (!powerLevelEvent) return;

        const me = room.getMember(this.props.matrixClient.credentials.userId);
        if (!me) return;

        const defaultLevel = powerLevelEvent.getContent().users_default;
        let modLevel = me.powerLevel - 1;
        if (modLevel > 50 && defaultLevel < 50) modLevel = 50; // try to stick with the vector level defaults
        // toggle the level
        const newLevel = this.state.isTargetMod ? defaultLevel : modLevel;
        this.setState({ updating: this.state.updating + 1 });
        this.props.matrixClient.setPowerLevel(roomId, target, parseInt(newLevel), powerLevelEvent).then(
            function() {
                // NO-OP; rely on the m.room.member event coming down else we could
                // get out of sync if we force setState here!
                console.log("Mod toggle success");
            }, function(err) {
                if (err.errcode === 'M_GUEST_ACCESS_FORBIDDEN') {
                    dis.dispatch({action: 'require_registration'});
                } else {
                    console.error("Toggle moderator error:" + err);
                    Modal.createTrackedDialog('Failed to toggle moderator status', '', ErrorDialog, {
                        title: _t("Error"),
                        description: _t("Failed to toggle moderator status"),
                    });
                }
            },
        ).finally(()=>{
            this.setState({ updating: this.state.updating - 1 });
        });
    };

    _applyPowerChange = (roomId, target, powerLevel, powerLevelEvent) => {
        this.setState({ updating: this.state.updating + 1 });
        this.props.matrixClient.setPowerLevel(roomId, target, parseInt(powerLevel), powerLevelEvent).then(
            function() {
                // NO-OP; rely on the m.room.member event coming down else we could
                // get out of sync if we force setState here!
                console.log("Power change success");
            }, function(err) {
                const ErrorDialog = sdk.getComponent("dialogs.ErrorDialog");
                console.error("Failed to change power level " + err);
                Modal.createTrackedDialog('Failed to change power level', '', ErrorDialog, {
                    title: _t("Error"),
                    description: _t("Failed to change power level"),
                });
            },
        ).finally(()=>{
            this.setState({ updating: this.state.updating - 1 });
        }).done();
    };

    onPowerChange = async (powerLevel) => {
        const roomId = this.props.member.roomId;
        const target = this.props.member.userId;
        const room = this.props.matrixClient.getRoom(roomId);
        if (!room) return;

        const powerLevelEvent = room.currentState.getStateEvents("m.room.power_levels", "");
        if (!powerLevelEvent) return;

        if (!powerLevelEvent.getContent().users) {
            this._applyPowerChange(roomId, target, powerLevel, powerLevelEvent);
            return;
        }

        const myUserId = this.props.matrixClient.getUserId();
        const QuestionDialog = sdk.getComponent("dialogs.QuestionDialog");

        const myPower = powerLevelEvent.getContent().users[myUserId];
        if (parseInt(myPower) === parseInt(powerLevel)) {
            Modal.createTrackedDialog('Promote to PL100 Warning', '', QuestionDialog, {
                title: _t("Warning!"),
                description:
                    <div>
                        { _t("You will not be able to undo this change as you are promoting the user " +
                            "to have the same power level as yourself.") }<br />
                        { _t("Are you sure?") }
                    </div>,
                button: _t("Continue"),
                onFinished: (confirmed) => {
                    if (confirmed) {
                        this._applyPowerChange(roomId, target, powerLevel, powerLevelEvent);
                    }
                },
            });
            return;
        }
        this._applyPowerChange(roomId, target, powerLevel, powerLevelEvent);
    };

    onNewDMClick = async () => {
        this.setState({ updating: this.state.updating + 1 });

        const userId = this.props.member.userId;
        const serverConf = await MatrixClientPeg.get().getServerConfig();
        const { e2e: { preferred_mode: e2ePreferred = false }} = serverConf || {};

        createRoom({ dmUserId: userId, createOpts: { isEncrypted: e2ePreferred }}).finally(() => {
            this.setState({ updating: this.state.updating - 1 });
        });
    };

    onLeaveClick = () => {
        dis.dispatch({
            action: 'leave_room',
            room_id: this.props.member.roomId,
        });
    };

    _calculateOpsPermissions = async (member) => {
        const defaultPerms = {
            can: {},
            muted: false,
        };
        const room = this.props.matrixClient.getRoom(member.roomId);
        if (!room) return defaultPerms;

        const powerLevels = room.currentState.getStateEvents("m.room.power_levels", "");
        if (!powerLevels) return defaultPerms;

        const isRoomPublic = getJoinRules(room) === 'public';

        const me = room.getMember(this.props.matrixClient.credentials.userId);
        if (!me) return defaultPerms;

        const them = member;
        return {
            can: await this._calculateCanPermissions(
                me, them, powerLevels.getContent(), isRoomPublic,
            ),
            muted: this._isMuted(them, powerLevels.getContent()),
            isTargetMod: them.powerLevel > powerLevels.getContent().users_default,
        };
    };

    _calculateCanPermissions = (me, them, powerLevels, isRoomPublic) => {
        const isMe = me.userId === them.userId;
        const can = {
            kick: false,
            mute: false,
            modifyLevel: false,
            modifyLevelMax: 0,
        };

        const canAffectUser = them.powerLevel < me.powerLevel || isMe;
        if (!canAffectUser) {
            //console.log("Cannot affect user: %s >= %s", them.powerLevel, me.powerLevel);
            return can;
        }

        const editPowerLevel = (
            (powerLevels.events ? powerLevels.events["m.room.power_levels"] : null) ||
            powerLevels.state_default
        );

        can.kick = me.powerLevel >= powerLevels.kick && !isMe;
        can.ban = isRoomPublic ? me.powerLevel >= powerLevels.ban && !isMe : false;
        can.mute = me.powerLevel >= editPowerLevel && !isMe;
        can.modifyLevel = me.powerLevel >= editPowerLevel && (isMe || me.powerLevel > them.powerLevel);
        can.modifyLevelMax = me.powerLevel;

        return can;
    };

    _isMuted = (member, powerLevelContent) => {
        if (!powerLevelContent || !member) return false;

        const levelToSend = (
            (powerLevelContent.events ? powerLevelContent.events["m.room.message"] : null) ||
            powerLevelContent.events_default
        );
        return member.powerLevel < levelToSend;
    };

    onCancel = (e) => {
        dis.dispatch({
            action: "view_user",
            member: null,
        });
    };

    onMemberAvatarClick = () => {
        const ImageView = sdk.getComponent("elements.ImageView");
        const params = {
            src: this.state.avatarBlob,
            name: this.props.member.name,
        };
        Modal.createDialog(ImageView, params, "mx_Dialog_lightbox");
    };

    onRoomTileClick = (roomId) => {
        dis.dispatch({
            action: 'view_room',
            room_id: roomId,
        });
    };

    onStartChatClick = (tiles, roomId) => {
        if (tiles.length === 0) {
            MatrixClientPeg.get().trackUserAction({
                formId: 'chatCreation',
                version: 1,
                action: 'start2',
            });
            NewsStore.showLeaveDialogIfNeeded(this.onNewDMClick);
        } else {
            this.onRoomTileClick(roomId);
        }
    }

    onBanOrUnban = () => {
        const ConfirmUserActionDialog = sdk.getComponent("dialogs.ConfirmUserActionDialog");
        Modal.createTrackedDialog('Confirm User Action Dialog', 'onBanOrUnban', ConfirmUserActionDialog, {
            member: this.props.member,
            action: this.props.member.membership === 'ban' ? _t("Unban") : _t("Permanently remove"),
            title: this.props.member.membership === 'ban' ?
                _t("Unban this user?") :
                _t("Are you sure you want to permanently remove this user from this room?"),
            askReason: this.props.member.membership !== 'ban',
            danger: this.props.member.membership !== 'ban',
            onFinished: (proceed, reason) => {
                if (!proceed) return;

                this.setState({ updating: this.state.updating + 1 });
                let promise;
                if (this.props.member.membership === 'ban') {
                    promise = this.props.matrixClient.unban(
                        this.props.member.roomId, this.props.member.userId,
                    );
                } else {
                    promise = this.props.matrixClient.ban(
                        this.props.member.roomId, this.props.member.userId,
                        reason || undefined,
                    );
                }
                promise.then(
                    function() {
                        // NO-OP; rely on the m.room.member event coming down else we could
                        // get out of sync if we force setState here!
                        console.log("Ban success");
                    }, function(err) {
                        const ErrorDialog = sdk.getComponent("dialogs.ErrorDialog");
                        console.error("Ban error: " + err);
                        Modal.createTrackedDialog('Failed to permanently remove user', '', ErrorDialog, {
                            title: _t("Error"),
                            description: _t("Failed to permanently remove user"),
                        });
                    },
                ).finally(()=>{
                    this.setState({ updating: this.state.updating - 1 });
                });
            },
        });
    };


    _renderUserOptions = () => {
        const cli = this.props.matrixClient;
        const member = this.props.member;

        let unIgnoreButton = null;
        let insertPillButton = null;
        let inviteUserButton = null;
        let readReceiptButton = null;

        // Only allow the user to ignore the user if its not ourselves
        // same goes for jumping to read receipt
        if (member.userId !== cli.getUserId()) {
            if (this.state.isIgnoring) {
                unIgnoreButton = (
                    <AccessibleButton onClick={this.onIgnoreToggle} className="mx_MemberInfo_field">
                        {_t("Unignore")}
                    </AccessibleButton>
                );
            }

            if (member.roomId) {
                const room = cli.getRoom(member.roomId);
                const eventId = room.getEventReadUpTo(member.userId);

                const onReadReceiptButton = function() {
                    dis.dispatch({
                        action: 'view_room',
                        highlighted: true,
                        event_id: eventId,
                        room_id: member.roomId,
                    });
                };

                const onInsertPillButton = function() {
                    dis.dispatch({
                        action: 'insert_mention',
                        user_id: member.userId,
                    });
                };

                readReceiptButton = (
                    <AccessibleButton onClick={onReadReceiptButton} className="mx_MemberInfo_field">
                        { _t('Jump to read receipt') }
                    </AccessibleButton>
                );

                insertPillButton = (
                    <AccessibleButton onClick={onInsertPillButton} className={"mx_MemberInfo_field"}>
                        { _t('Mention') }
                    </AccessibleButton>
                );
            }

            if (!member || !member.membership || member.membership === 'leave') {
                const roomId = member && member.roomId ? member.roomId : RoomViewStore.getRoomId();
                const onInviteUserButton = async () => {
                    try {
                        // We use a MultiInviter to re-use the invite logic, even though
                        // we're only inviting one user.
                        const inviter = new MultiInviter(roomId);
                        await inviter.invite([member.userId]).then(() => {
                            if (inviter.getCompletionState(member.userId) !== "invited") {
                                throw new Error(inviter.getErrorText(member.userId));
                            }
                        });
                    } catch (err) {
                        const ErrorDialog = sdk.getComponent('dialogs.ErrorDialog');
                        Modal.createTrackedDialog('Failed to invite', '', ErrorDialog, {
                            title: _t('Failed to invite'),
                            description: ((err && err.message) ? err.message : _t("Operation failed")),
                        });
                    }
                };

                inviteUserButton = (
                    <AccessibleButton onClick={onInviteUserButton} className="mx_MemberInfo_field">
                        { _t('Invite') }
                    </AccessibleButton>
                );
            }
        }

        if (readReceiptButton || insertPillButton || unIgnoreButton || inviteUserButton) {
            return (
                <div>
                    <h3>{ _t("User Options") }</h3>
                    <div className="mx_MemberInfo_buttons">
                        { readReceiptButton }
                        { insertPillButton }
                        { unIgnoreButton }
                        { inviteUserButton }
                    </div>
                </div>
            );
        } else return null;
    };

    render() {
        const { matrixClient, member } = this.props;
        const { email, emailLoading, dmRooms } = this.state;

        let startChat;
        let kickButton;
        let banButton;
        let muteButton;
        let giveModButton;
        let spinner;
        let mailComponent;

        if (emailLoading === true) {
            const Spinner = sdk.getComponent('elements.Spinner');
            mailComponent = <Spinner w={16} h={16} />;
        } else if (email) {
            mailComponent = email;
        }

        if (member.userId !== matrixClient.credentials.userId) {
            const tiles = [];
            let dmRoomId;
            for (const roomId of dmRooms) {
                const dmRoom = matrixClient.getRoom(roomId);
                if (dmRoom) {
                    const highlight = dmRoom.getUnreadNotificationCount('highlight') > 0;
                    dmRoomId = roomId;
                    tiles.push(
                        <RoomTile key={dmRoom.roomId} room={dmRoom}
                            transparent={true}
                            collapsed={false}
                            selected={false}
                            unread={Unread.doesRoomHaveUnreadMessages(dmRoom)}
                            highlight={highlight}
                            isInvite={false}
                            onClick={this.onRoomTileClick}
                        />,
                    );
                }
            }

            const startNewChat =
                <div className="invite" onClick={() => this.onStartChatClick(tiles, dmRoomId)}>
                    <AccessibleButton onClick={()=> {}} className="mx_RoomSubList_addRoom" />
                    <span>{ _t("Start a chat") }</span>
                </div>;

            startChat = <div>
                <h3>{ _t("Direct chats") }</h3>
                { tiles }
                { startNewChat }
            </div>;
        }

        if (this.state.updating) {
            const Loader = sdk.getComponent("elements.Spinner");
            spinner = <Loader imgClassName="mx_ContextualMenu_spinner" />;
        }

        if (this.state.can.kick) {
            const membership = member.membership;
            const kickLabel = membership === "invite" ? _t("Disinvite") : _t("Remove user");
            kickButton = (
                <AccessibleButton className="mx_MemberInfo_field"
                        onClick={this.onKick}>
                    { kickLabel }
                </AccessibleButton>
            );
        }
        if (this.state.can.ban) {
            let label = _t("Permanently remove");
            if (member.membership === 'ban') {
                label = _t("Unban");
            }
            banButton = (
                <AccessibleButton className="mx_MemberInfo_field"
                        onClick={this.onBanOrUnban}>
                    { label }
                </AccessibleButton>
            );
        }
        if (this.state.can.mute) {
            const muteLabel = this.state.muted ? _t("Unmute") : _t("Mute");
            muteButton = (
                <AccessibleButton className="mx_MemberInfo_field"
                        onClick={this.onMuteToggle}>
                    { muteLabel }
                </AccessibleButton>
            );
        }
        if (this.state.can.toggleMod) {
            const giveOpLabel = this.state.isTargetMod ? _t("Revoke Moderator") : _t("Make Moderator");
            giveModButton = <AccessibleButton className="mx_MemberInfo_field" onClick={this.onModToggle}>
                { giveOpLabel }
            </AccessibleButton>;
        }

        let adminTools;
        if (kickButton || banButton || muteButton || giveModButton) {
            adminTools =
                <div>
                    <h3>{ _t("Admin Tools") }</h3>

                    <div className="mx_MemberInfo_buttons">
                        { muteButton }
                        { kickButton }
                        { banButton }
                        { giveModButton }
                    </div>
                </div>;
        }

        let presenceState;
        let presenceLastActiveAgo;
        let presenceCurrentlyActive;
        let statusMessage;

        if (member.user) {
            presenceState = member.user.presence;
            presenceLastActiveAgo = member.user.lastActiveAgo;
            presenceCurrentlyActive = member.user.currentlyActive;

            if (SettingsStore.isFeatureEnabled("feature_custom_status")) {
                statusMessage = member.user._unstable_statusMessage;
            }
        }

        const room = matrixClient.getRoom(member.roomId);
        const powerLevelEvent = room ? room.currentState.getStateEvents("m.room.power_levels", "") : null;
        const powerLevelUsersDefault = powerLevelEvent ? powerLevelEvent.getContent().users_default : 0;

        const enablePresenceByHsUrl = SdkConfig.get()["enable_presence_by_hs_url"];
        const hsUrl = matrixClient.baseUrl;
        let showPresence = false;
        let isOnlyOneAdmin = true;
        let iAmAdmin = false;

        for (const roomMember of Object.values(room.currentState.members)) {
            if (roomMember.powerLevel === 100 && roomMember.user.isMe) {
                iAmAdmin = true;
            }
            if (roomMember.powerLevel === 100 && !roomMember.user.isMe) {
                isOnlyOneAdmin = false;
            }
          }

        if (enablePresenceByHsUrl && enablePresenceByHsUrl[hsUrl] !== undefined) {
            showPresence = enablePresenceByHsUrl[hsUrl];
        }

        let presenceLabel = null;
        if (showPresence) {
            const PresenceLabel = sdk.getComponent('rooms.PresenceLabel');
            presenceLabel = (
                <PresenceLabel
                    activeAgo={presenceLastActiveAgo}
                    currentlyActive={presenceCurrentlyActive}
                    presenceState={presenceState}
                />
            );
        }

        let statusLabel = null;
        if (statusMessage) {
            statusLabel = <span className="mx_MemberInfo_statusMessage">{ statusMessage }</span>;
        }

        let roomMemberDetails = null;

        const { isDmRoom } = isDirectMessageRoom(room);

        if (member.roomId) { // is in room
            const PowerSelector = sdk.getComponent('elements.PowerSelector');
            roomMemberDetails = (
                <div>
                {
                    ((member.user && member.user.isMe && iAmAdmin && !isOnlyOneAdmin)
                    || (iAmAdmin && member.user && !member.user.isMe && member.user.powerLevel !== 100))
                    ? <div className="mx_MemberInfo_profileField">
                       <PowerSelector
                           value={parseInt(member.powerLevel)}
                           maxValue={this.state.can.modifyLevelMax}
                           disabled={!this.state.can.modifyLevel || (isDmRoom && member.user.isMe && iAmAdmin)}
                           usersDefault={powerLevelUsersDefault}
                           onChange={this.onPowerChange}
                        />
                      </div>
                    : (member.user && member.user.isMe && iAmAdmin && isOnlyOneAdmin)
                       ? <div className='mx_MemberInfo_LastAdmin'>
                           <h2>{_t("Admin")}</h2>
                           <div>{_t("You are the last administrator")}</div>
                           <div>{_t("You cannot change your rights")}</div>
                         </div>
                       : null
                }
                <div className="mx_MemberInfo_profileField">
                    {presenceLabel}
                    {statusLabel}
                </div>
            </div>);
        }

        const MemberAvatar = sdk.getComponent('avatars.MemberAvatar');
        const EmojiText = sdk.getComponent('elements.EmojiText');

        let backButton;
        if (member.roomId) {
            const cancelSvg = require('../../../../res/img/back.svg');
            backButton = (
                <div className="mx_MemberInfo_cancel" onClick={this.onCancel}>
                    <img src={cancelSvg} alt={_t('Close')} />
                </div>
            );
        }

        // We dont need to display a disambiguated name here, since we already display the email address below
        return (
            <div className="mx_MemberInfo">
                    <div className="mx_MemberInfo_avatar">
                        { member.getMxcAvatarUrl() &&
                            <MemberAvatar
                                height={48}
                                member={member}
                                memberAvatar={this.state.avatarBlob}
                                onClick={this.onMemberAvatarClick}
                                width={48}
                            />
                        }
                        { backButton }
                    </div>
                    <div className="mx_MemberInfo_container">

                        <div className="mx_MemberInfo_profile">
                            <div className="mx_MemberInfo_profileField">
                                <EmojiText element="h2">{ member.rawDisplayName }</EmojiText>
                            </div>
                            <div className="mx_MemberInfo_profileField">
                                { mailComponent }
                            </div>
                            <div className="mx_MemberInfo_profileField">
                                { member.userId }
                            </div>
                            { roomMemberDetails }
                        </div>
                    </div>
                    <AutoHideScrollbar className="mx_MemberInfo_scrollContainer">
                        <div className="mx_MemberInfo_container">
                            { this._renderUserOptions() }

                            { adminTools }

                            { startChat }

                            { spinner }
                        </div>
                    </AutoHideScrollbar>
            </div>
        );
    }
});
