import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { getInfraMessages } from '../../../utils/CitadelRequests';
import { getCurrentLanguage } from '../../../languageHandler';

import AlertOctagon from "../../../../res/img/alert-octagon.svg";
import AlertTriangle from "../../../../res/img/alert-triangle.svg";
import Info from "../../../../res/img/info.svg";
import CancelRed from '../../../../res/img/x-red.svg';
import CancelBlue from '../../../../res/img/x-blue.svg';
import CancelOrange from '../../../../res/img/x-orange.svg';

// sorted by importance
const BACKEND_MESSAGE_TYPES = {
    'e2e': 'e2e',
    'critical': 'critical',
    'warning': 'error',
    'info': 'info',
    'other': 'other',
};

const BackendMaintenanceMessages = ({ onInfraMessageChanged }) => {
    const [infraMessageToDisplay, setInfraMessageToDisplay] = useState(null);
    const dismissedMaintenanceMessages = useRef(JSON.parse(localStorage.getItem('dismissedMaintenanceMessages')) || []);
    const localInfraMessages = useRef({});


    const onDismissMaintenanceMessage = () => {
        const messageId = infraMessageToDisplay.id;
        dismissedMaintenanceMessages.current.push(messageId);
        localStorage.setItem('dismissedMaintenanceMessages', JSON.stringify(dismissedMaintenanceMessages.current));
        setInfraMessageToDisplay(null); // not showing the current message anymore
        onInfraMessageChanged(null);
        // get the next message to be displayed, if there is one
        if (localInfraMessages.current.length !== 0) {
            getInfraMessageToDisplay(localInfraMessages.current);
        }
    };


    const getInfraMessageToDisplay = (infraMessages) => {
        if (infraMessages.length > 0) {
            let highestSeverity; let closestEndDate; let title; let subtitle; let unhidableTs; let id; let dontDisplayInfo;
            const currentLanguage = getCurrentLanguage();
            infraMessages.map(infraMessage => {
                const timeToShowMessage = infraMessage.from_ts * 1000; // convert seconds in miliseconds
                const endTimeOfMessage = infraMessage.until_ts * 1000;
                const validMessageTimeline = timeToShowMessage <= Date.now() && endTimeOfMessage > Date.now(); // message not expired
                if (dismissedMaintenanceMessages.current.includes(infraMessage.id)) {
                    // don't display info while we have a dismissed warning that is still valid
                    if (infraMessage.severity === 'error' && validMessageTimeline) {
                        dontDisplayInfo = true;
                    }
                    // redisplay the dismissed error message when the unhidable_ts has elapsed
                    if ((new Date(infraMessage.unhidable_ts * 1000) < Date.now()) && infraMessage.severity === 'error' && validMessageTimeline) {
                        highestSeverity = 'error';
                        closestEndDate = endTimeOfMessage;
                        title = infraMessage.msg[currentLanguage].title;
                        subtitle = infraMessage.msg[currentLanguage].content;
                        unhidableTs = infraMessage.unhidable_ts;
                        id = infraMessage.id;
                    }
                    return;
                }
                switch (infraMessage.severity) {
                    case 'critical':
                        if (validMessageTimeline) {
                            highestSeverity = 'critical';
                            if (closestEndDate && closestEndDate > endTimeOfMessage) {
                                closestEndDate = endTimeOfMessage;
                                title = infraMessage.msg[currentLanguage].title;
                                subtitle = infraMessage.msg[currentLanguage].content;
                                id = infraMessage.id;
                            } else if (!closestEndDate && endTimeOfMessage > Date.now()) {
                                closestEndDate = endTimeOfMessage;
                                title = infraMessage.msg[currentLanguage].title;
                                subtitle = infraMessage.msg[currentLanguage].content;
                                id = infraMessage.id;
                            }
                        }
                    break;
                    case 'error':
                        if (highestSeverity !== 'critical') {
                            if (validMessageTimeline) {
                                highestSeverity = 'error';
                                if (closestEndDate && closestEndDate > endTimeOfMessage) {
                                    closestEndDate = endTimeOfMessage;
                                    title = infraMessage.msg[currentLanguage].title;
                                    subtitle = infraMessage.msg[currentLanguage].content;
                                    unhidableTs = infraMessage.unhidable_ts;
                                    id = infraMessage.id;
                                } else if (!closestEndDate && endTimeOfMessage > Date.now()) {
                                    closestEndDate = endTimeOfMessage;
                                    title = infraMessage.msg[currentLanguage].title;
                                    subtitle = infraMessage.msg[currentLanguage].content;
                                    unhidableTs = infraMessage.unhidable_ts;
                                    id = infraMessage.id;
                                }
                            }
                        }
                    break;
                    case 'other':
                    case 'info':
                        if (highestSeverity !== 'critical' && highestSeverity !== 'error') {
                            if (validMessageTimeline) {
                                highestSeverity = 'info';
                                if (closestEndDate && closestEndDate > endTimeOfMessage) {
                                    closestEndDate = endTimeOfMessage;
                                    title = infraMessage.msg[currentLanguage].title;
                                    subtitle = infraMessage.msg[currentLanguage].content;
                                    id = infraMessage.id;
                                    unhidableTs = Date.now() + 1000; // added this so info unhidableTs is always bigger than date.now so it's always closable
                                } else if (!closestEndDate && endTimeOfMessage > Date.now()) {
                                    closestEndDate = endTimeOfMessage;
                                    title = infraMessage.msg[currentLanguage].title;
                                    subtitle = infraMessage.msg[currentLanguage].content;
                                    id = infraMessage.id;
                                    unhidableTs = Date.now() + 1000; // added this so info unhidableTs is always bigger than date.now so it's always closable
                                }
                            }
                        }
                    break;
                }
            });
            if (id) {
                if (highestSeverity === 'info' && dontDisplayInfo) {
                    return null;
                }
                return {
                id,
                title,
                subtitle,
                type: highestSeverity,
                stopDispalyingFromTs: closestEndDate,
                isDismissable: unhidableTs ? Date.now() < new Date(unhidableTs * 1000) : false,
                };
            }
        }
        return null;
    };

    const checkInfraMessages = async () => {
        const userid = localStorage.getItem('mx_user_id');
        const tenant = userid.split(/:(.*?)\./)[1];
        const infraMessages = await getInfraMessages(tenant);
        localInfraMessages.current = infraMessages.chunk;
        const infraMessageToDisplay = getInfraMessageToDisplay(infraMessages.chunk);
        onInfraMessageChanged(infraMessageToDisplay);
        setInfraMessageToDisplay(infraMessageToDisplay);
    };

    useEffect(() => {
        let maintenanceMessages;
        const fetchInfraMessages = async () => {
            await checkInfraMessages();
            maintenanceMessages = setInterval(async () => {
                await checkInfraMessages();
            }, 60 * 60 * 1000);
        };
        fetchInfraMessages();
        return () => clearInterval(maintenanceMessages);
    }, []);

    if (infraMessageToDisplay && new Date(infraMessageToDisplay.stopDispalyingFromTs * 1000) <= Date.now()) {
        return null;
    }

    if (!infraMessageToDisplay) {
        return null;
    }

    const { type, title, subtitle, isDismissable } = infraMessageToDisplay;

    switch (type) {
        case BACKEND_MESSAGE_TYPES.e2e:
        case BACKEND_MESSAGE_TYPES.critical:
            return <div className={cx("mx_BackendMessages_container", {critical: true})}>
                        <img src={AlertOctagon} />
                        <div className='mx_BackendMessages_messageContainer'>
                            <span className={cx('mx_BackendMessages_title', {critical: true})}>
                                {title}
                            </span>
                            <span className={cx('mx_BackendMessages_subtitle', {critical: true})}>
                                {subtitle}
                            </span>
                        </div>
                        {isDismissable && <img onClick={onDismissMaintenanceMessage} className='mx_BackendMessages_closeImg' src={CancelRed} />}
                    </div>;
        case BACKEND_MESSAGE_TYPES.warning:
            return <div className={cx("mx_BackendMessages_container", {warning: true})}>
                        <img src={AlertTriangle} />
                        <div className='mx_BackendMessages_messageContainer'>
                            <span className={cx('mx_BackendMessages_title', {warning: true})}>
                                {title}
                            </span>
                            <span className={cx('mx_BackendMessages_subtitle', {warning: true})}>
                                {subtitle}
                            </span>
                        </div>
                        {isDismissable && <img onClick={onDismissMaintenanceMessage} className='mx_BackendMessages_closeImg' src={CancelOrange} />}
                    </div>;
        case BACKEND_MESSAGE_TYPES.other:
        case BACKEND_MESSAGE_TYPES.info:
            return <div className={cx("mx_BackendMessages_container", {info: true})}>
                    <img src={Info} />
                    <div className='mx_BackendMessages_messageContainer'>
                        <span className={cx('mx_BackendMessages_title', {info: true})}>
                            {title}
                        </span>
                        <span className={cx('mx_BackendMessages_subtitle', {info: true})}>
                            {subtitle}
                        </span>
                    </div>
                    {isDismissable && <img onClick={onDismissMaintenanceMessage} className='mx_BackendMessages_closeImg' src={CancelBlue} />}
                </div>;
        default:
            return null;
    }
};
BackendMaintenanceMessages.proptypes = {
    onInfraMessageChanged: PropTypes.func,
};

export default BackendMaintenanceMessages;
