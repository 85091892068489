/*
Copyright 2015, 2016 OpenMarket Ltd
Copyright 2017 Vector Creations Ltd
Copyright 2018, 2019 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

// TODO: This component is enormous! There's several things which could stand-alone:
//  - Search results component
//  - Drag and drop

import shouldHideEvent from '../../shouldHideEvent';

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Promise from 'bluebird';
import classNames from 'classnames';
import { isEmpty } from "lodash";

import { _t } from '../../languageHandler';
import { RoomPermalinkCreator } from '../../matrix-to';

import MatrixClientPeg from '../../MatrixClientPeg';
import ContentMessages from '../../ContentMessages';
import Modal from '../../Modal';
import sdk from '../../index';
import CallHandler from '../../CallHandler';
import dis from '../../dispatcher';
import Tinter from '../../Tinter';
import rate_limited_func from '../../ratelimitedfunc';
import ObjectUtils from '../../ObjectUtils';
import * as Rooms from '../../Rooms';
import EncryptionKeysRequestBar from './EncryptionKeysRequestBar';

import { KeyCode, isOnlyCtrlOrCmdKeyEvent } from '../../Keyboard';
import CitadelFileDownloader from '../../utils/CitadelFileDownloader';
import CitadelDownloadBar from './CitadelDownloadBar';

import MainSplit from './MainSplit';
import RightPanel from './RightPanel';
import RoomViewStore from '../../stores/RoomViewStore';
import RoomListStore from '../../stores/RoomListStore';
import RoomScrollStateStore from '../../stores/RoomScrollStateStore';
import WidgetEchoStore from '../../stores/WidgetEchoStore';
import SettingsStore, { SettingLevel } from '../../settings/SettingsStore';
import WidgetUtils from '../../utils/WidgetUtils';
import AccessibleButton from '../views/elements/AccessibleButton';
import CitadelExternalBar from '../views/rooms/CitadelExternalBar';
import { isDirectMessageRoom } from '../../Rooms';
import UserStore from '../../stores/UserStore';
import { isUserExternal } from '../../utils/CitadelUtils';

import RoomHeader from '../views/rooms/RoomHeader';
import MessageComposer from '../views/rooms/MessageComposer';
import ForwardMessage from '../views/rooms/ForwardMessage';
import AuxPanel from '../views/rooms/AuxPanel';
import SearchBar from '../views/rooms/SearchBar';
import PinnedEventsPanel from '../views/rooms/PinnedEventsPanel';
import ScrollPanel from '../structures/ScrollPanel';
import TimelinePanel from '../structures/TimelinePanel';
import RoomUpgradeWarningBar from '../views/rooms/RoomUpgradeWarningBar';
import EventTile from '../views/rooms/EventTile';
import SearchResultTile from '../views/rooms/SearchResultTile';
import Spinner from '../views/elements/Spinner';
import TopUnreadMessagesBar from '../views/rooms/TopUnreadMessagesBar';
import JumpToBottomButton from '../views/rooms/JumpToBottomButton';
import RoomStatusBar from '../structures/RoomStatusBar';
import RoomPreviewBar from "../views/rooms/RoomPreviewBar";
import NewsStore from "../../stores/NewsStore";
import MinifiedNewsEdition from "../views/elements/MinifiedNewsEdition";
import RoomExportStore from '../../stores/RoomExportStore';
import RoomExportBar from "./RoomExportBar";

const DEBUG = false;
let debuglog = function() {};

const BROWSER_SUPPORTS_SANDBOX = 'sandbox' in document.createElement('iframe');

if (DEBUG) {
    // using bind means that we get to keep useful line numbers in the console
    debuglog = console.log.bind(console);
}

class RoomView extends React.Component {
    displayName = 'RoomView'

    constructor(props) {
        super(props);
        const llMembers = MatrixClientPeg.get().hasLazyLoadMembersEnabled();
        this.state = {
            room: null,
            roomId: null,
            roomLoading: true,
            peekLoading: false,
            shouldPeek: true,
            isDmRoom: false,

            // Media limits for uploading.
            mediaConfig: undefined,

            // used to trigger a rerender in TimelinePanel once the members are loaded,
            // so RR are rendered again (now with the members available), ...
            membersLoaded: !llMembers,
            // The event to be scrolled to initially
            initialEventId: null,
            // The offset in pixels from the event with which to scroll vertically
            initialEventPixelOffset: null,
            // Whether to highlight the event scrolled to
            isInitialEventHighlighted: null,

            forwardingEvent: null,
            numUnreadMessages: 0,
            draggingFile: false,
            searching: false,
            searchResults: null,
            callState: null,
            guestsCanJoin: false,
            canPeek: false,
            showApps: false,
            isAlone: false,
            isPeeking: false,
            showingPinned: false,

            // error object, as from the matrix client/server API
            // If we failed to load information about the room,
            // store the error here.
            roomLoadError: null,

            // Have we sent a request to join the room that we're waiting to complete?
            joining: false,

            // this is true if we are fully scrolled-down, and are looking at
            // the end of the live timeline. It has the effect of hiding the
            // 'scroll to bottom' knob, among a couple of other things.
            atEndOfLiveTimeline: true,
            atEndOfLiveTimelineInit: false, // used by componentDidUpdate to avoid unnecessary checks

            showTopUnreadMessagesBar: false,

            auxPanelMaxHeight: undefined,

            statusBarVisible: false,

            // We load this later by asking the js-sdk to suggest a version for us.
            // This object is the result of Room#getRecommendedVersion()
            upgradeRecommendation: null,

            expandedPreviewBar: true,
            ignoreCollapsedRhsPropsValue: true,

            haveExternalMembers: false,
            showExternalBar: false,

            // event for retrieving the E2E keys from another member of a room
            // encryptionKeysRequest: null,
            encryptionKeysRequest: null,
            isEncryptionKeysRequestBarMinimized: false,
        };
    }

    _onRoomListStoreUpdate = () => {
        const { room, isDmRoom: prevIsDmRoom } = this.state;

        if (!room) return;

        const { isDmRoom } = isDirectMessageRoom(room);

        if (isDmRoom !== prevIsDmRoom) this.setState({ isDmRoom });
    }

    _onRoomViewStoreUpdate = (initial) => {
        if (this.unmounted) {
            return;
        }

        if (!initial && this.state.roomId !== RoomViewStore.getRoomId()) {
            // RoomView explicitly does not support changing what room
            // is being viewed: instead it should just be re-mounted when
            // switching rooms. Therefore, if the room ID changes, we
            // ignore this. We either need to do this or add code to handle
            // saving the scroll position (otherwise we end up saving the
            // scroll position against the wrong room).

            // Given that doing the setState here would cause a bunch of
            // unnecessary work, we just ignore the change since we know
            // that if the current room ID has changed from what we thought
            // it was, it means we're about to be unmounted.
            return;
        }

        const roomId = RoomViewStore.getRoomId();
        const cli = MatrixClientPeg.get();
        const room = cli.getRoom(roomId);
        const encryptionKeysRequestEvent = Rooms.getLastEventByType(room, 'citadel.keyrequest');

        const newState = {
            roomId,
            roomAlias: RoomViewStore.getRoomAlias(),
            roomLoading: RoomViewStore.isRoomLoading(),
            roomLoadError: RoomViewStore.getRoomLoadError(),
            joining: RoomViewStore.isJoining(),
            initialEventId: RoomViewStore.getInitialEventId(),
            isInitialEventHighlighted: RoomViewStore.isInitialEventHighlighted(),
            forwardingEvent: RoomViewStore.getForwardingEvent(),
            shouldPeek: RoomViewStore.shouldPeek(),
            showingPinned: SettingsStore.getValue("PinnedEvents.isOpen", roomId),
            isDmRoom: room && isDirectMessageRoom(room).isDmRoom,
            encryptionKeysRequest: encryptionKeysRequestEvent ? encryptionKeysRequestEvent.getContent() : null,
        };

        // Temporary logging to diagnose https://github.com/vector-im/riot-web/issues/4307
        console.log(
            'RVS update:',
            newState.roomId,
            newState.roomAlias,
            'loading?', newState.roomLoading,
            'joining?', newState.joining,
            'initial?', initial,
            'shouldPeek?', newState.shouldPeek,
        );

        // NB: This does assume that the roomID will not change for the lifetime of
        // the RoomView instance
        if (initial) {
            newState.room = room;
            if (newState.room) {
                newState.showApps = this._shouldShowApps(newState.room);
                this._onRoomLoaded(newState.room);
            }
        }

        if (this.state.roomId === null && newState.roomId !== null) {
            // Get the scroll state for the new room

            // If an event ID wasn't specified, default to the one saved for this room
            // in the scroll state store. Assume initialEventPixelOffset should be set.
            if (!newState.initialEventId) {
                const roomScrollState = RoomScrollStateStore.getScrollState(newState.roomId);
                if (roomScrollState) {
                    newState.initialEventId = roomScrollState.focussedEvent;
                    newState.initialEventPixelOffset = roomScrollState.pixelOffset;
                }
            }
        }

        // Clear the search results when clicking a search result (which changes the
        // currently scrolled to event, this.state.initialEventId).
        if (this.state.initialEventId !== newState.initialEventId) {
            newState.searchResults = null;
        }

        this.setState(newState);
        // At this point, newState.roomId could be null (e.g. the alias might not
        // have been resolved yet) so anything called here must handle this case.

        // We pass the new state into this function for it to read: it needs to
        // observe the new state but we don't want to put it in the setState
        // callback because this would prevent the setStates from being batched,
        // ie. cause it to render RoomView twice rather than the once that is necessary.
        if (initial) {
            this._setupRoom(newState.room, newState.roomId, newState.joining, newState.shouldPeek);
        }
    }

    _getRoomId() {
        // According to `_onRoomViewStoreUpdate`, `state.roomId` can be null
        // if we have a room alias we haven't resolved yet. To work around this,
        // first we'll try the room object if it's there, and then fallback to
        // the bare room ID. (We may want to update `state.roomId` after
        // resolving aliases, so we could always trust it.)
        return this.state.room ? this.state.room.roomId : this.state.roomId;
    }

    _getPermalinkCreatorForRoom = (room) => {
        if (!this._permalinkCreators) this._permalinkCreators = {};
        if (this._permalinkCreators[room.roomId]) return this._permalinkCreators[room.roomId];

        this._permalinkCreators[room.roomId] = new RoomPermalinkCreator(room);
        if (this.state.room && room.roomId === this.state.room.roomId) {
            // We want to watch for changes in the creator for the primary room in the view, but
            // don't need to do so for search results.
            this._permalinkCreators[room.roomId].start();
        } else {
            this._permalinkCreators[room.roomId].load();
        }
        return this._permalinkCreators[room.roomId];
    }

    _stopAllPermalinkCreators = () => {
        if (!this._permalinkCreators) return;
        for (const roomId of Object.keys(this._permalinkCreators)) {
            this._permalinkCreators[roomId].stop();
        }
    }

    _onWidgetEchoStoreUpdate = () => {
        this.setState({
            showApps: this._shouldShowApps(this.state.room),
        });
    }

    _setupRoom = (room, roomId, joining, shouldPeek) => {
        const invitedPeek = room && room.getMyMembership() === "invite";
        // if this is an unknown room then we're in one of three states:
        // - This is a room we can peek into (search engine) (we can /peek)
        // - This is a room we can publicly join or were invited to. (we can /join)
        // - This is a room we cannot join at all. (no action can help us)
        // We can't try to /join because this may implicitly accept invites (!)
        // We can /peek though. If it fails then we present the join UI. If it
        // succeeds then great, show the preview (but we still may be able to /join!).
        // Note that peeking works by room ID and room ID only, as opposed to joining
        // which must be by alias or invite wherever possible (peeking currently does
        // not work over federation).

        // NB. We peek if we have never seen the room before (i.e. js-sdk does not know
        // about it). We don't peek in the historical case where we were joined but are
        // now not joined because the js-sdk peeking API will clobber our historical room,
        // making it impossible to indicate a newly joined room.
        if (!joining && roomId) {
            if (this.props.autoJoin) {
                this.onJoinButtonClicked();
            } else if ((!room && shouldPeek) || invitedPeek) {
                console.log("Attempting to peek into room %s", roomId);
                this.setState({
                    peekLoading: true,
                    isPeeking: true, // this will change to false if peeking fails
                });
                MatrixClientPeg.get().peekInRoom(roomId).then((room) => {
                    if (this.unmounted) {
                        return;
                    }
                    this.setState({
                        room: room,
                        peekLoading: false,
                    });
                    this._onRoomLoaded(room);
                }, (err) => {
                    if (this.unmounted) {
                        return;
                    }

                    // Stop peeking if anything went wrong
                    this.setState({
                        isPeeking: false,
                    });

                    // This won't necessarily be a MatrixError, but we duck-type
                    // here and say if it's got an 'errcode' key with the right value,
                    // it means we can't peek.
                    if (err.errcode === "M_GUEST_ACCESS_FORBIDDEN") {
                        // This is fine: the room just isn't peekable (we assume).
                        this.setState({
                            canPeek: false,
                            peekLoading: false,
                        });
                    } else {
                        throw err;
                    }
                });
            } else if (room) {
                //viewing a previously joined room, try to lazy load members

                // Stop peeking because we have joined this room previously
                MatrixClientPeg.get().stopPeeking();
                this.setState({isPeeking: false});
            }
        }
    }

    _shouldShowApps = (room) => {
        if (!BROWSER_SUPPORTS_SANDBOX) return false;

        // Check if user has previously chosen to hide the app drawer for this
        // room. If so, do not show apps
        const hideWidgetDrawer = localStorage.getItem(
            room.roomId + "_hide_widget_drawer");

        if (hideWidgetDrawer === "true") {
            return false;
        }

        const widgets = WidgetEchoStore.getEchoedRoomWidgets(room.roomId, WidgetUtils.getRoomWidgets(room));

        return widgets.length > 0 || WidgetEchoStore.roomHasPendingWidgets(room.roomId, WidgetUtils.getRoomWidgets(room));
    }

    componentDidMount = () => {
        this.dispatcherRef = dis.register(this.onAction);
        const cli = MatrixClientPeg.get();
        cli.on("Room", this.onRoom);
        cli.on("Room.timeline", this.onRoomTimeline);
        cli.on("Room.name", this.onRoomName);
        cli.on("Room.accountData", this.onRoomAccountData);
        cli.on("RoomState.members", this.onRoomStateMember);
        cli.on("Room.myMembership", this.onMyMembership);
        cli.on("accountData", this.onAccountData);
        cli.on("deviceVerificationChanged", this.onDeviceVerificationChanged);
        cli.on('RoomState.events', this.onRoomStateEvents);
        // Start listening for RoomViewStore updates
        this._roomStoreToken = RoomViewStore.addListener(this._onRoomViewStoreUpdate);
        this._roomListStoreToken = RoomListStore.addListener(this._onRoomListStoreUpdate);
        this._newsStoreToken = NewsStore.addListener(this.onNewsStoreUpdate);
        this._onRoomViewStoreUpdate(true);
        WidgetEchoStore.on('update', this._onWidgetEchoStoreUpdate);
        const call = this._getCallForRoom();
        const callState = call ? call.call_state : "ended";
        this.setState({
            callState: callState,
        });

        this._updateConfCallNotification();

        window.addEventListener('storage', this.onStorage);
        window.addEventListener('beforeunload', this.onPageUnload);
        if (this.props.resizeNotifier) {
            this.props.resizeNotifier.on("middlePanelResized", this.onResize);
        }
        this.onResize();

        document.addEventListener("keydown", this.onKeyDown);

        // XXX: EVIL HACK to autofocus inviting on empty rooms.
        // We use the setTimeout to avoid racing with focus_composer.
        if (this.state.room &&
            this.state.room.getJoinedMemberCount() === 1 &&
            this.state.room.getLiveTimeline() &&
            this.state.room.getLiveTimeline().getEvents() &&
            this.state.room.getLiveTimeline().getEvents().length <= 6) {
            const inviteBox = document.getElementById("mx_SearchableEntityList_query");
            setTimeout(function() {
                if (inviteBox) {
                    inviteBox.focus();
                }
            }, 50);
        }
        const e2eKeySharedList = JSON.parse(localStorage.getItem("e2eKeySharedList")) || {};
        if (this.state.room?.roomId && e2eKeySharedList[this.state.room?.roomId]) {
            this.setState({
                isEncryptionKeysRequestBarMinimized: e2eKeySharedList[this.state.room.roomId]?.isMinimized,
                isKeySharingHidden: e2eKeySharedList[this.state.room.roomId]?.isKeyDenied,
                hasSentE2eKeys: e2eKeySharedList[this.state.room.roomId]?.isKeySent,
            });
        }
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        return (!ObjectUtils.shallowEqual(this.props, nextProps) ||
                !ObjectUtils.shallowEqual(this.state, nextState));
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.refs.roomView) {
            const roomView = ReactDOM.findDOMNode(this.refs.roomView);
            if (!roomView.ondrop) {
                roomView.addEventListener('drop', this.onDrop);
                roomView.addEventListener('dragover', this.onDragOver);
                roomView.addEventListener('dragleave', this.onDragLeaveOrEnd);
                roomView.addEventListener('dragend', this.onDragLeaveOrEnd);
            }
        }

        // Note: We check the ref here with a flag because componentDidMount, despite
        // documentation, does not define our messagePanel ref. It looks like our spinner
        // in render() prevents the ref from being set on first mount, so we try and
        // catch the messagePanel when it does mount. Because we only want the ref once,
        // we use a boolean flag to avoid duplicate work.
        if (this.refs.messagePanel && !this.state.atEndOfLiveTimelineInit) {
            this.setState({
                atEndOfLiveTimelineInit: true,
                atEndOfLiveTimeline: this.refs.messagePanel.isAtEndOfLiveTimeline(),
            });
        }
        if (!prevState.room?.roomId && this.state.room?.roomId) {
            const e2eKeySharedList = JSON.parse(localStorage.getItem("e2eKeySharedList")) || {};
            if (e2eKeySharedList[this.state.room.roomId]) {
                this.setState({
                    isEncryptionKeysRequestBarMinimized: e2eKeySharedList[this.state.room.roomId]?.isMinimized,
                    isKeySharingHidden: e2eKeySharedList[this.state.room.roomId]?.isKeyDenied,
                    hasSentE2eKeys: e2eKeySharedList[this.state.room.roomId]?.isKeySent,
                });
            }
        }
    }

    componentWillUnmount = () => {
        // set a boolean to say we've been unmounted, which any pending
        // promises can use to throw away their results.
        //
        // (We could use isMounted, but facebook have deprecated that.)
        this.unmounted = true;

        // update the scroll map before we get unmounted
        if (this.state.roomId) {
            RoomScrollStateStore.setScrollState(this.state.roomId, this._getScrollState());
            CitadelFileDownloader.abortEncryptedDownloads(this.state.roomId);
        }

        // stop tracking room changes to format permalinks
        this._stopAllPermalinkCreators();

        if (this.refs.roomView) {
            // disconnect the D&D event listeners from the room view. This
            // is really just for hygiene - we're going to be
            // deleted anyway, so it doesn't matter if the event listeners
            // don't get cleaned up.
            const roomView = ReactDOM.findDOMNode(this.refs.roomView);
            roomView.removeEventListener('drop', this.onDrop);
            roomView.removeEventListener('dragover', this.onDragOver);
            roomView.removeEventListener('dragleave', this.onDragLeaveOrEnd);
            roomView.removeEventListener('dragend', this.onDragLeaveOrEnd);
        }
        dis.unregister(this.dispatcherRef);
        if (MatrixClientPeg.get()) {
            MatrixClientPeg.get().removeListener("Room", this.onRoom);
            MatrixClientPeg.get().removeListener("Room.timeline", this.onRoomTimeline);
            MatrixClientPeg.get().removeListener("Room.name", this.onRoomName);
            MatrixClientPeg.get().removeListener("Room.accountData", this.onRoomAccountData);
            MatrixClientPeg.get().removeListener("Room.myMembership", this.onMyMembership);
            MatrixClientPeg.get().removeListener("RoomState.members", this.onRoomStateMember);
            MatrixClientPeg.get().removeListener("accountData", this.onAccountData);
            MatrixClientPeg.get().removeListener("deviceVerificationChanged", this.onDeviceVerificationChanged);
        }

        window.removeEventListener('storage', this.onStorage);
        window.removeEventListener('beforeunload', this.onPageUnload);
        if (this.props.resizeNotifier) {
            this.props.resizeNotifier.removeListener("middlePanelResized", this.onResize);
        }

        document.removeEventListener("keydown", this.onKeyDown);

        // Remove RoomStore listener
        if (this._roomStoreToken) {
            this._roomStoreToken.remove();
        }

        WidgetEchoStore.removeListener('update', this._onWidgetEchoStoreUpdate);

        // cancel any pending calls to the rate_limited_funcs
        this._updateRoomMembers.cancelPendingCall();

        // no need to do this as Dir & Settings are now overlays. It just burnt CPU.
        // console.log("Tinter.tint from RoomView.unmount");
        // Tinter.tint(); // reset colourscheme

        if (this.resizeObserver) this.resizeObserver.disconnect();
        if (this._newsStoreToken) this._newsStoreToken.remove();
    }

    onRoomStateEvents = (event) => {
        const { room, roomId, hasSentE2eKeys } = this.state;
        if (!room || event.getRoomId() !== roomId) {
            return;
        }

        const myUserId = localStorage.getItem("mx_user_id");
        if (event.getType() === 'citadel.keyrequest' && room.getMyMembership() === 'join') {
            const lastEvent = Rooms.getLastEventByType(room, event.getType());
            const e2eKeySharedList = JSON.parse(localStorage.getItem("e2eKeySharedList")) || {};
            const encryptionKeysRequest = lastEvent ? lastEvent.getContent() : event.getContent();
            if ((e2eKeySharedList[roomId] || encryptionKeysRequest.requesters.length >= 0) &&
                (event.event.sender !== myUserId)
            ) {
                if (encryptionKeysRequest.requesters.length === 0) {
                    this.setState({ hasSentE2eKeys: false });
                }
                delete e2eKeySharedList[roomId];
                localStorage.setItem("e2eKeySharedList", JSON.stringify(e2eKeySharedList));
                window.dispatchEvent(new Event('storage'));
            }
            this.setState({ encryptionKeysRequest });
            if (event.event.sender !== myUserId && !hasSentE2eKeys) {
                this.setState({ hasSentE2eKeys: false });
            }
        }
    }

    handleOpenNewsDialog = () => {
        dis.dispatch({ action: 'update_news_visibility', visibility: 'main' });
    };

    onNewsStoreUpdate = () => {
        const newsStore = NewsStore.getNewsCreationStore();
        const { visibility, news: { title = '' } } = newsStore;

        this.setState({ shouldDisplayMinified: visibility === 'reduced', title });
        const news = visibility === 'reduced'
                ? <MinifiedNewsEdition onClick={this.handleOpenNewsDialog} title={title} key="MinifiedNewsEdition" />
                : null;

        this.setState({ news });
    };

    onPageUnload = (event) => {
        if (ContentMessages.sharedInstance().getCurrentUploads().length > 0) {
            return event.returnValue =
                _t("You seem to be uploading files, are you sure you want to quit?");
        } else if (this._getCallForRoom() && this.state.callState !== 'ended') {
            return event.returnValue =
                _t("You seem to be in a call, are you sure you want to quit?");
        }
    }

    onStorage = () => {
        const e2eKeySharedList = JSON.parse(localStorage.getItem("e2eKeySharedList")) || {};
        if (e2eKeySharedList[this.state.room.roomId]?.isKeySent) {
            this.setState({ hasSentE2eKeys: true, isKeySharingHidden: false });
        } else if (e2eKeySharedList[this.state.room.roomId]?.isKeyDenied) {
            this.setState({ isKeySharingHidden: true });
        } else {
            this.setState({ isKeySharingHidden: false });
        }

        const allLaterKeysToDecrypt = JSON.parse(localStorage.getItem("allLaterKeysToDecrypt") || "{}");
        if (allLaterKeysToDecrypt[this.state.room.roomId]?.length !== 0) {
            this.forceUpdate();
        }
    }

    onKeyDown = (ev) => {
        let handled = false;
        const ctrlCmdOnly = isOnlyCtrlOrCmdKeyEvent(ev);

        switch (ev.keyCode) {
            case KeyCode.KEY_D:
                if (ctrlCmdOnly) {
                    this.onMuteAudioClick();
                    handled = true;
                }
                break;

            case KeyCode.KEY_E:
                if (ctrlCmdOnly) {
                    this.onMuteVideoClick();
                    handled = true;
                }
                break;
        }

        if (handled) {
            ev.stopPropagation();
            ev.preventDefault();
        }
    }

    onAction = (payload) => {
        switch (payload.action) {
            case 'post_sticker_message':
              this.injectSticker(
                  payload.data.content.url,
                  payload.data.content.info,
                  payload.data.description || payload.data.name);
              break;
            case 'picture_snapshot':
                return ContentMessages.sharedInstance().sendContentListToRoom(
                    [payload.file], this.state.room.roomId, MatrixClientPeg.get(),
                );
            case 'notifier_enabled':
            case 'upload_started':
            case 'upload_finished':
            case 'upload_canceled':
            case 'download_started':
            case 'download_finished':
            case 'room_export_start':
            case 'room_export_success':
            case 'room_export_cancel_success':
            case 'update_room_export_progress_text':
                this.forceUpdate();
                break;
            case 'call_state': {
                // don't filter out payloads for room IDs other than props.room because
                // we may be interested in the conf 1:1 room

                if (!payload.room_id) {
                    return;
                }

                const call = this._getCallForRoom();
                let callState;

                if (call) {
                    callState = call.call_state;
                } else {
                    callState = "ended";
                }

                // possibly remove the conf call notification if we're now in
                // the conf
                this._updateConfCallNotification();

                this.setState({
                    callState: callState,
                });

                break;
            }
            case 'show_right_panel':
                this.setState({
                    ignoreCollapsedRhsPropsValue: false,
                });
                break;
            case 'hide_right_panel':
                this.setState({
                    ignoreCollapsedRhsPropsValue: true,
                });
                break;
            case 'appsDrawer':
                this.setState({
                    showApps: payload.show,
                });
                break;
        }
    }

    onRoomTimeline = (ev, room, toStartOfTimeline, removed, data) => {
        if (this.unmounted) return;

        // ignore events for other rooms
        if (!room) return;
        if (!this.state.room || room.roomId !== this.state.room.roomId) return;

        // ignore events from filtered timelines
        if (data.timeline.getTimelineSet() !== room.getUnfilteredTimelineSet()) return;

        if (ev.getType() === "org.matrix.room.preview_urls") {
            this._updatePreviewUrlVisibility(room);
        }

        if (ev.getType() === "m.room.encryption") {
            this._updateE2EStatus(room);
        }

        // ignore anything but real-time updates at the end of the room:
        // updates from pagination will happen when the paginate completes.
        if (toStartOfTimeline || !data || !data.liveEvent) return;

        // no point handling anything while we're waiting for the join to finish:
        // we'll only be showing a spinner.
        if (this.state.joining) return;

        if (ev.getSender() !== MatrixClientPeg.get().credentials.userId) {
            // update unread count when scrolled up
            if (!this.state.searchResults && this.state.atEndOfLiveTimeline) {
                // no change
            } else if (!shouldHideEvent(ev)) {
                this.setState((state, props) => {
                    return {numUnreadMessages: state.numUnreadMessages + 1};
                });
            }
        }
    }

    onRoomName = (room) => {
        if (this.state.room && room.roomId === this.state.room.roomId) {
            this.forceUpdate();
        }
    }

    canResetTimeline = () => {
        if (!this.refs.messagePanel) {
            return true;
        }
        return this.refs.messagePanel.canResetTimeline();
    }

    // called when state.room is first initialised (either at initial load,
    // after a successful peek, or after we join the room).
    _onRoomLoaded = (room) => {
        this._calculatePeekRules(room);
        this._updatePreviewUrlVisibility(room);
        this._loadMembersIfJoined(room);
        this._calculateRecommendedVersion(room);
        this._updateE2EStatus(room);
        this._checkIfAlone(room);
        this._checkExternalMembers(room);
    }

    _calculateRecommendedVersion = async (room) => {
        const recommendedVersion = await room.getRecommendedVersion();
        if (this.unmounted) return;
        this.setState({ upgradeRecommendation: recommendedVersion });
    }

    _loadMembersIfJoined = async (room) => {
        // lazy load members if enabled
        const cli = MatrixClientPeg.get();
        if (cli.hasLazyLoadMembersEnabled()) {
            if (room && room.getMyMembership() === 'join') {
                try {
                    await room.loadMembersIfNeeded();
                    if (!this.unmounted) {
                        this.setState({membersLoaded: true});
                    }
                } catch (err) {
                    const errorMessage = `Fetching room members for ${room.roomId} failed.` +
                        " Room members will appear incomplete.";
                    console.error(errorMessage);
                    console.error(err);
                }
            }
        }
    }

    _calculatePeekRules = (room) => {
        const guestAccessEvent = room.currentState.getStateEvents("m.room.guest_access", "");
        if (guestAccessEvent && guestAccessEvent.getContent().guest_access === "can_join") {
            this.setState({
                guestsCanJoin: true,
            });
        }

        const historyVisibilityEvent = room.currentState.getStateEvents("m.room.history_visibility", "");
        const historyVisibility = historyVisibilityEvent && historyVisibilityEvent.getContent().history_visibility;
        const myMembership = room.getMyMembership();

        if (historyVisibility === "world_readable" || myMembership === "invite") {
            this.setState({
                canPeek: true,
            });
        }
    }

    _updatePreviewUrlVisibility = ({roomId}) => {
        // URL Previews in E2EE rooms can be a privacy leak so use a different setting which is per-room explicit
        const key = MatrixClientPeg.get().isRoomEncrypted(roomId) ? 'urlPreviewsEnabled_e2ee' : 'urlPreviewsEnabled';
        this.setState({
            showUrlPreview: SettingsStore.getValue(key, roomId),
        });
    }

    onRoom = (room) => {
        if (!room || room.roomId !== this.state.roomId) {
            return;
        }
        this.setState({
            room: room,
        }, () => {
            this._onRoomLoaded(room);
        });
    }

    onDeviceVerificationChanged = (userId, device) => {
        const room = this.state.room;
        if (!room.currentState.getMember(userId)) {
            return;
        }
        this._updateE2EStatus(room);
    }

    _updateE2EStatus = (room) => {
        if (!MatrixClientPeg.get().isRoomEncrypted(room.roomId)) {
            return;
        }
        if (!MatrixClientPeg.get().isCryptoEnabled()) {
            // If crypto is not currently enabled, we aren't tracking devices at all,
            // so we don't know what the answer is. Let's error on the safe side and show
            // a warning for this case.
            this.setState({
                e2eStatus: "warning",
            });
            return;
        }

        this.setState({
            e2eStatus: "verifying",
        });
        room.hasUnverifiedDevices().then((hasUnverifiedDevices) => {
            this.setState({
                e2eStatus: hasUnverifiedDevices ? "warning" : "verified",
            });
        });
    }

    updateTint = () => {
        const room = this.state.room;
        if (!room) return;

        console.log("Tinter.tint from updateTint");
        const colorScheme = SettingsStore.getValue("roomColor", room.roomId);
        Tinter.tint(colorScheme.primary_color, colorScheme.secondary_color);
    }

    onAccountData = (event) => {
        const type = event.getType();
        if ((type === "org.matrix.preview_urls" || type === "im.vector.web.settings") && this.state.room) {
            // non-e2ee url previews are stored in legacy event type `org.matrix.room.preview_urls`
            this._updatePreviewUrlVisibility(this.state.room);
        }
    }

    onRoomAccountData = (event, room) => {
        if (room.roomId === this.state.roomId) {
            const type = event.getType();
            if (type === "org.matrix.room.color_scheme") {
                const colorScheme = event.getContent();
                // XXX: we should validate the event
                console.log("Tinter.tint from onRoomAccountData");
                Tinter.tint(colorScheme.primary_color, colorScheme.secondary_color);
            } else if (type === "org.matrix.room.preview_urls" || type === "im.vector.web.settings") {
                // non-e2ee url previews are stored in legacy event type `org.matrix.room.preview_urls`
                this._updatePreviewUrlVisibility(room);
            }
        }
    }

    onRoomStateMember = (ev, state, member) => {
        // ignore if we don't have a room yet
        if (!this.state.room) {
            return;
        }

        // ignore members in other rooms
        if (member.roomId !== this.state.room.roomId) {
            return;
        }

        this._updateRoomMembers(member);
    }

    onMyMembership = (room, membership, oldMembership) => {
        if (room.roomId === this.state.roomId) {
            this.forceUpdate();
            this._loadMembersIfJoined(room);
        }
    }

    // rate limited because a power level change will emit an event for every
    // member in the room.
    _updateRoomMembers = rate_limited_func(function(dueToMember) {
        const { room } = this.state;

        // a member state changed in this room
        // refresh the conf call notification state
        this._updateConfCallNotification();
        this._updateDMState();

        let memberCountInfluence = 0;
        if (dueToMember && dueToMember.membership === 'invite' && room.getInvitedMemberCount() === 0) {
            // A member got invited, but the room hasn't detected that change yet. Influence the member
            // count by 1 to counteract this.
            memberCountInfluence = 1;
        }
        this._checkIfAlone(room, memberCountInfluence);

        this._checkExternalMembers(room);
        this._updateE2EStatus(room);
    }, 500)

    _checkIfAlone = (room, countInfluence) => {
        let warnedAboutLonelyRoom = false;
        if (localStorage) {
            warnedAboutLonelyRoom = localStorage.getItem('mx_user_alone_warned_' + room.roomId);
        }
        if (warnedAboutLonelyRoom) {
            if (this.state.isAlone) this.setState({isAlone: false});
            return;
        }

        let joinedOrInvitedMemberCount = room.getJoinedMemberCount() + room.getInvitedMemberCount();
        if (countInfluence) joinedOrInvitedMemberCount += countInfluence;
        this.setState({isAlone: joinedOrInvitedMemberCount === 1});
    }

    _checkExternalMembers = (room) => {
        const allMembers = Object.values(room.currentState.members);

        const isJoinOrInviteMember = (membership) => { return (membership === 'join' || membership === 'invite'); };
        const haveExternalMembers = allMembers.some(member => (
            isUserExternal(member.userId) && isJoinOrInviteMember(member.membership)
        ));
        const userClosedExternalBar = localStorage.getItem('mx_external_members_warned_' + room.roomId);

        this.setState({
            haveExternalMembers,
            showExternalBar: room.getMyMembership() === 'join' && haveExternalMembers && !userClosedExternalBar,
        });
    }

    _updateConfCallNotification = () => {
        const room = this.state.room;
        if (!room || !this.props.ConferenceHandler) {
            return;
        }
        const confMember = room.getMember(
            this.props.ConferenceHandler.getConferenceUserIdForRoom(room.roomId),
        );

        if (!confMember) {
            return;
        }
        const confCall = this.props.ConferenceHandler.getConferenceCallForRoom(confMember.roomId);

        // A conf call notification should be displayed if there is an ongoing
        // conf call but this cilent isn't a part of it.
        this.setState({
            displayConfCallNotification: (
                (!confCall || confCall.call_state === "ended") &&
                confMember.membership === "join"
            ),
        });
    }

    _updateDMState() {
        const room = this.state.room;
        if (room.getMyMembership() !== "join") {
            return;
        }
        const dmInviter = room.getDMInviter();
        if (dmInviter) {
            Rooms.setDMRoom(room.roomId, dmInviter);
        }
    }

    onSearchResultsFillRequest = (backwards) => {
        if (!backwards) {
            return Promise.resolve(false);
        }

        if (this.state.searchResults.next_batch) {
            debuglog("requesting more search results");
            const searchPromise = MatrixClientPeg.get().backPaginateRoomEventsSearch(
                this.state.searchResults);
            return this._handleSearchResult(searchPromise);
        } else {
            debuglog("no more search results");
            return Promise.resolve(false);
        }
    }

    onInviteButtonClick = () => {
        // call AddressPickerDialog
        dis.dispatch({
            action: 'view_invite',
            roomId: this.state.room.roomId,
        });
        this.setState({isAlone: false}); // there's a good chance they'll invite someone
    }

    onStopAloneWarningClick = () => {
        if (localStorage) {
            localStorage.setItem('mx_user_alone_warned_' + this.state.room.roomId, true);
        }
        this.setState({isAlone: false});
    }

    onJoinButtonClicked = () => {
        const cli = MatrixClientPeg.get();

        if (cli && !cli.isGuest()) {
            Promise.resolve().then(() => {
                cli.stopPeeking();
                this.setState({isPeeking: false});
                const signUrl = this.props.thirdPartyInvite ?
                    this.props.thirdPartyInvite.inviteSignUrl : undefined;
                dis.dispatch({
                    action: 'join_room',
                    opts: { inviteSignUrl: signUrl, viaServers: this.props.viaServers },
                });
                return Promise.resolve();
            });
        }
    }

    onMessageListScroll = (ev) => {
        if (this.refs.messagePanel.isAtEndOfLiveTimeline()) {
            this.setState({
                numUnreadMessages: 0,
                atEndOfLiveTimeline: true,
            });
        } else {
            this.setState({
                atEndOfLiveTimeline: false,
            });
        }
        this._updateTopUnreadMessagesBar();
    }

    onDragOver = (ev) => {
        ev.stopPropagation();
        ev.preventDefault();

        ev.dataTransfer.dropEffect = 'none';

        const items = [...ev.dataTransfer.items];
        if (items.length >= 1) {
            const isDraggingFiles = items.every(function(item) {
                return item.kind == 'file';
            });

            if (isDraggingFiles) {
                this.setState({ draggingFile: true });
                ev.dataTransfer.dropEffect = 'copy';
            }
        }
    }

    onDrop = (ev) => {
        ev.stopPropagation();
        ev.preventDefault();
        ContentMessages.sharedInstance().sendContentListToRoom(
            ev.dataTransfer.files, this.state.room.roomId, MatrixClientPeg.get(),
        );
        this.setState({ draggingFile: false });
        dis.dispatch({action: 'focus_composer'});
    }

    onDragLeaveOrEnd = (ev) => {
        ev.stopPropagation();
        ev.preventDefault();
        this.setState({ draggingFile: false });
    }

    injectSticker = (url, info, text) => {
        if (MatrixClientPeg.get().isGuest()) {
            dis.dispatch({action: 'require_registration'});
            return;
        }

        ContentMessages.sharedInstance().sendStickerContentToRoom(url, this.state.room.roomId, info, text, MatrixClientPeg.get())
            .done(undefined, (error) => {
                if (error.name === "UnknownDeviceError") {
                    // Let the staus bar handle this
                    return;
                }
            });
    }

    onSearch = (term, scope) => {
        this.setState({
            searchTerm: term,
            searchScope: scope,
            searchResults: {},
            searchHighlights: [],
        });

        // if we already have a search panel, we need to tell it to forget
        // about its scroll state.
        if (this.refs.searchResultsPanel) {
            this.refs.searchResultsPanel.resetScrollState();
        }

        // make sure that we don't end up showing results from
        // an aborted search by keeping a unique id.
        //
        // todo: should cancel any previous search requests.
        this.searchId = new Date().getTime();

        let filter;
        if (scope === "Room") {
            filter = {
                // XXX: it's unintuitive that the filter for searching doesn't have the same shape as the v2 filter API :(
                rooms: [
                    this.state.room.roomId,
                ],
            };
        }

        debuglog("sending search request");

        const searchPromise = MatrixClientPeg.get().searchRoomEvents({
            filter: filter,
            term: term,
        });
        this._handleSearchResult(searchPromise).done();
    }

    _handleSearchResult = (searchPromise) => {
        const self = this;

        // keep a record of the current search id, so that if the search terms
        // change before we get a response, we can ignore the results.
        const localSearchId = this.searchId;

        this.setState({
            searchInProgress: true,
        });

        return searchPromise.then(function(results) {
            debuglog("search complete");
            if (self.unmounted || !self.state.searching || self.searchId !== localSearchId) {
                console.error("Discarding stale search results");
                return;
            }

            // postgres on synapse returns us precise details of the strings
            // which actually got matched for highlighting.
            //
            // In either case, we want to highlight the literal search term
            // whether it was used by the search engine or not.

            let highlights = results.highlights;
            if (highlights.indexOf(self.state.searchTerm) < 0) {
                highlights = highlights.concat(self.state.searchTerm);
            }

            // For overlapping highlights,
            // favour longer (more specific) terms first
            highlights = highlights.sort(function(a, b) {
                return b.length - a.length;
            });

            self.setState({
                searchHighlights: highlights,
                searchResults: results,
            });
        }, function(error) {
            const ErrorDialog = sdk.getComponent("dialogs.ErrorDialog");
            console.error("Search failed: " + error);
            Modal.createTrackedDialog('Search failed', '', ErrorDialog, {
                title: _t("Search failed"),
                description: ((error && error.message) ? error.message : _t("Server may be unavailable, overloaded, or search timed out :(")),
            });
        }).finally(function() {
            self.setState({
                searchInProgress: false,
            });
        });
    }

    getSearchResultTiles = () => {
        const cli = MatrixClientPeg.get();

        // XXX: todo: merge overlapping results somehow?
        // XXX: why doesn't searching on name work?

        const ret = [];

        if (this.state.searchInProgress) {
            ret.push(<li key="search-spinner">
                         <Spinner />
                     </li>);
        }

        if (!this.state.searchResults.next_batch) {
            if (this.state.searchResults.results.length == 0) {
                ret.push(<li key="search-top-marker">
                         <h2 className="mx_RoomView_topMarker">{ _t("No results") }</h2>
                         </li>,
                        );
            } else {
                ret.push(<li key="search-top-marker">
                         <h2 className="mx_RoomView_topMarker">{ _t("No more results") }</h2>
                         </li>,
                        );
            }
        }

        // once dynamic content in the search results load, make the scrollPanel check
        // the scroll offsets.
        const onHeightChanged = () => {
            const scrollPanel = this.refs.searchResultsPanel;
            if (scrollPanel) {
                scrollPanel.checkScroll();
            }
        };

        let lastRoomId;

        for (let i = this.state.searchResults.results.length - 1; i >= 0; i--) {
            const result = this.state.searchResults.results[i];

            const mxEv = result.context.getEvent();
            const roomId = mxEv.getRoomId();
            const room = cli.getRoom(roomId);

            if (!EventTile.haveTileForEvent(mxEv)) {
                // XXX: can this ever happen? It will make the result count
                // not match the displayed count.
                continue;
            }

            if (this.state.searchScope === 'All') {
                if (roomId !== lastRoomId) {
                    // XXX: if we've left the room, we might not know about
                    // it. We should tell the js sdk to go and find out about
                    // it. But that's not an issue currently, as synapse only
                    // returns results for rooms we're joined to.
                    const roomName = room ? room.name : _t("Unknown room %(roomId)s", { roomId: roomId });

                    ret.push(<li key={mxEv.getId() + "-room"}>
                                 <h2>{ _t("Room") }: { roomName }</h2>
                             </li>);
                    lastRoomId = roomId;
                }
            }

            const resultLink = "#/room/"+roomId+"/"+mxEv.getId();

            ret.push(<SearchResultTile key={mxEv.getId()}
                     searchResult={result}
                     searchHighlights={this.state.searchHighlights}
                     resultLink={resultLink}
                     permalinkCreator={this._getPermalinkCreatorForRoom(room)}
                     onHeightChanged={onHeightChanged} />);
        }
        return ret;
    }

    onPinnedClick = () => {
        const nowShowingPinned = !this.state.showingPinned;
        const roomId = this.state.room.roomId;
        this.setState({showingPinned: nowShowingPinned, searching: false});
        SettingsStore.setValue("PinnedEvents.isOpen", roomId, SettingLevel.ROOM_DEVICE, nowShowingPinned);
    }

    onSettingsClick = () => {
        if (this.state.forwardingEvent) {
            dis.dispatch({
                action: 'forward_event',
                event: null,
            });
        }
        dis.dispatch({ action: 'open_room_settings' });
    }

    onCancelClick = () => {
        console.log("updateTint from onCancelClick");
        this.updateTint();
        if (this.state.forwardingEvent) {
            dis.dispatch({
                action: 'forward_event',
                event: null,
            });
        }
        dis.dispatch({action: 'focus_composer'});
    }

    onLeaveClick = () => {
        dis.dispatch({
            action: 'leave_room',
            room_id: this.state.room.roomId,
        });
    }

    onForgetClick = () => {
        MatrixClientPeg.get().forget(this.state.room.roomId).done(function() {
            dis.dispatch({ action: 'view_next_room' });
        }, function(err) {
            const errCode = err.errcode || _t("unknown error code");
            const ErrorDialog = sdk.getComponent("dialogs.ErrorDialog");
            Modal.createTrackedDialog('Failed to forget room', '', ErrorDialog, {
                title: _t("Error"),
                description: _t("Failed to forget room %(errCode)s", { errCode: errCode }),
            });
        });
    }

    onRejectButtonClicked = (ev) => {
        const self = this;
        this.setState({
            rejecting: true,
        });
        MatrixClientPeg.get().leave(this.state.roomId).done(function() {
            dis.dispatch({ action: 'view_next_room' });
            self.setState({
                rejecting: false,
            });
        }, function(error) {
            console.error("Failed to reject invite: %s", error);

            const msg = error.message ? error.message : JSON.stringify(error);
            const ErrorDialog = sdk.getComponent("dialogs.ErrorDialog");
            Modal.createTrackedDialog('Failed to reject invite', '', ErrorDialog, {
                title: _t("Failed to reject invite"),
                description: msg,
            });

            self.setState({
                rejecting: false,
                rejectError: error,
            });
        });
    }

    onRejectThreepidInviteButtonClicked = (ev) => {
        // We can reject 3pid invites in the same way that we accept them,
        // using /leave rather than /join. In the short term though, we
        // just ignore them.
        // https://github.com/vector-im/vector-web/issues/1134
        dis.dispatch({
            action: 'view_room_directory',
        });
    }

    onSearchClick = () => {
        this.setState({
            searching: !this.state.searching,
            showingPinned: false,
        });
    }

    onCancelSearchClick = () => {
        this.setState({
            searching: false,
            searchResults: null,
        });
    }

    onForgetExternalBarClick = () => {
        localStorage.setItem('mx_external_members_warned_' + this.state.room.roomId, true);
        this.setState({
            showExternalBar: false,
        });
    }

    // jump down to the bottom of this room, where new events are arriving
    jumpToLiveTimeline = () => {
        this.refs.messagePanel.jumpToLiveTimeline();
        dis.dispatch({action: 'focus_composer'});
    }

    // jump up to wherever our read marker is
    jumpToReadMarker = () => {
        this.refs.messagePanel.jumpToReadMarker();
        this.refs.messagePanel.forgetReadMarker();
    }

    // update the read marker to match the read-receipt
    forgetReadMarker = (ev) => {
        ev.stopPropagation();
        this.refs.messagePanel.forgetReadMarker();
    }

    // decide whether or not the top 'unread messages' bar should be shown
    _updateTopUnreadMessagesBar = () => {
        if (!this.refs.messagePanel) {
            return;
        }

        const showBar = this.refs.messagePanel.canJumpToReadMarker();
        if (this.state.showTopUnreadMessagesBar != showBar) {
            this.setState({showTopUnreadMessagesBar: showBar});
        }
    }

    // get the current scroll position of the room, so that it can be
    // restored when we switch back to it.
    //
    _getScrollState = () => {
        const messagePanel = this.refs.messagePanel;
        if (!messagePanel) return null;

        // if we're following the live timeline, we want to return null; that
        // means that, if we switch back, we will jump to the read-up-to mark.
        //
        // That should be more intuitive than slavishly preserving the current
        // scroll state, in the case where the room advances in the meantime
        // (particularly in the case that the user reads some stuff on another
        // device).
        //
        if (this.state.atEndOfLiveTimeline) {
            return null;
        }

        const scrollState = messagePanel.getScrollState();

        if (scrollState.stuckAtBottom) {
            // we don't really expect to be in this state, but it will
            // occasionally happen when no scroll state has been set on the
            // messagePanel (ie, we didn't have an initial event (so it's
            // probably a new room), there has been no user-initiated scroll, and
            // no read-receipts have arrived to update the scroll position).
            //
            // Return null, which will cause us to scroll to last unread on
            // reload.
            return null;
        }

        return {
            focussedEvent: scrollState.trackedScrollToken,
            pixelOffset: scrollState.pixelOffset,
        };
    }

    onResize = () => {
        // It seems flexbox doesn't give us a way to constrain the auxPanel height to have
        // a minimum of the height of the video element, whilst also capping it from pushing out the page
        // so we have to do it via JS instead.  In this implementation we cap the height by putting
        // a maxHeight on the underlying remote video tag.

        // header + footer + status + give us at least 120px of scrollback at all times.
        let auxPanelMaxHeight = window.innerHeight -
                (83 + // height of RoomHeader
                 36 + // height of the status area
                 72 + // minimum height of the message compmoser
                 120); // amount of desired scrollback

        // XXX: this is a bit of a hack and might possibly cause the video to push out the page anyway
        // but it's better than the video going missing entirely
        if (auxPanelMaxHeight < 50) auxPanelMaxHeight = 50;

        this.setState({auxPanelMaxHeight: auxPanelMaxHeight});
    }

    onFullscreenClick = () => {
        dis.dispatch({
            action: 'video_fullscreen',
            fullscreen: true,
        }, true);
    }

    onMuteAudioClick = () => {
        const call = this._getCallForRoom();
        if (!call) {
            return;
        }
        const newState = !call.isMicrophoneMuted();
        call.setMicrophoneMuted(newState);
        this.forceUpdate(); // TODO: just update the voip buttons
    }

    onMuteVideoClick = () => {
        const call = this._getCallForRoom();
        if (!call) {
            return;
        }
        const newState = !call.isLocalVideoMuted();
        call.setLocalVideoMuted(newState);
        this.forceUpdate(); // TODO: just update the voip buttons
    }

    onStatusBarVisible = () => {
        if (this.unmounted) return;
        this.setState({
            statusBarVisible: true,
        });
    }

    onStatusBarHidden = () => {
        // This is currently not desired as it is annoying if it keeps expanding and collapsing
        if (this.unmounted) return;
        this.setState({
            statusBarVisible: false,
        });
    }

    /**
     * called by the parent component when PageUp/Down/etc is pressed.
     *
     * We pass it down to the scroll panel.
     */
    handleScrollKey = (ev) => {
        let panel;
        if (this.refs.searchResultsPanel) {
            panel = this.refs.searchResultsPanel;
        } else if (this.refs.messagePanel) {
            panel = this.refs.messagePanel;
        }

        if (panel) {
            panel.handleScrollKey(ev);
        }
    }

    /**
     * get any current call for this room
     */
    _getCallForRoom = () => {
        if (!this.state.room) {
            return null;
        }
        return CallHandler.getCallForRoom(this.state.room.roomId);
    }

    /**
     * get current encryption key request for this room
     */
    getEncryptionKeysRequest = () => {
        const { room, encryptionKeysRequest, e2eStatus } = this.state;

        if (!!room && !!e2eStatus && !!encryptionKeysRequest && !isEmpty(encryptionKeysRequest.requesters)) {
            return encryptionKeysRequest;
        }

        return null;
    }

    hasSentE2eRetrievalEvent = () => {
        const { requesters } = this.getEncryptionKeysRequest() || { requesters: [] };
        const myUserId = UserStore.getMe().userId;

        return !isEmpty(requesters.filter(req => req.user_id === myUserId));
    }

    // this has to be a proper method rather than an unnamed function,
    // otherwise react calls it with null on each update.
    _gatherTimelinePanelRef = (r) => {
        this.refs.messagePanel = r;
        if (r) {
            console.log("updateTint from RoomView._gatherTimelinePanelRef");
            this.updateTint();
        }
    }

    _getOldRoom = () => {
        const createEvent = this.state.room.currentState.getStateEvents("m.room.create", "");
        if (!createEvent || !createEvent.getContent()['predecessor']) return null;

        return MatrixClientPeg.get().getRoom(createEvent.getContent()['predecessor']['room_id']);
    }

    _getHiddenHighlightCount = () => {
        const oldRoom = this._getOldRoom();
        if (!oldRoom) return 0;
        return oldRoom.getUnreadNotificationCount('highlight');
    }

    _onHiddenHighlightsClick = () => {
        const oldRoom = this._getOldRoom();
        if (!oldRoom) return;
        dis.dispatch({action: "view_room", room_id: oldRoom.roomId});
    }

    onRoomPreviewBarCollapseOrExpand = () => {
        this.setState((prevState) => ({
            expandedPreviewBar: !prevState.expandedPreviewBar,
        }));
    }

    isLoading = () => {
        const { roomLoading, peekLoading } = this.state;
        return Boolean(roomLoading || peekLoading);
    }

    isBusy = () => {
        const { joining, rejecting } = this.state;
        const loading = this.isLoading();

        return Boolean(loading || joining || rejecting);
    }

    canPreview = () => {
        const { room, canPeek } = this.state;
        const busy = this.isBusy();

        return Boolean(room) && !busy && canPeek;
    }

    onMinimizeEncryptionKeysRequestBar = () => {
        const { isEncryptionKeysRequestBarMinimized } = this.state;
        const e2eKeySharedList = JSON.parse(localStorage.getItem("e2eKeySharedList")) || {};
        e2eKeySharedList[this.state.room.roomId] = {
            ...e2eKeySharedList[this.state.room.roomId],
            isMinimized: !isEncryptionKeysRequestBarMinimized,
        };
        localStorage.setItem("e2eKeySharedList", JSON.stringify(e2eKeySharedList));
        this.setState(
            { isEncryptionKeysRequestBarMinimized: !isEncryptionKeysRequestBarMinimized },
            this.forceUpdate,
        );
    }

    renderMinimizedStatusBar = (encryptionKeysRequest) => {
        const { news, isEncryptionKeysRequestBarMinimized } = this.state;
        const statusBar = [];

        if (encryptionKeysRequest && isEncryptionKeysRequestBarMinimized) {
            statusBar.push(
                <EncryptionKeysRequestBar
                    encryptionKeysRequest={encryptionKeysRequest}
                    onMinimize={this.onMinimizeEncryptionKeysRequestBar}
                    isMinimized={isEncryptionKeysRequestBarMinimized}
                    key="EncryptionKeysRequestBar-minimized"
                    roomId={this.state.room.roomId}
                    hasSentE2eRetrievalEvent={this.hasSentE2eRetrievalEvent()}
                />,
            );
        }
        if (news) {
            statusBar.push(news);
        }

        return (
            <div className="mx_RoomView_statusArea_minimized" >
                {statusBar}
            </div>
        );
    };

    renderStatusBar = (call, inCall, memberShip, encryptionKeysRequest) => {
        const { room, searchResults, isEncryptionKeysRequestBarMinimized } = this.state;
        let statusBar = [];

        const uploadsInProgress = ContentMessages.sharedInstance().getCurrentUploads().length > 0;
        const downloadsInProgress = CitadelFileDownloader.isDownloading(room.roomId);
        const roomExportStatus = RoomExportStore.getRoomExportStatus(room.roomId);
        const roomExportInProgress = ['started', 'pending', 'completed'].includes(roomExportStatus);

        if (uploadsInProgress || downloadsInProgress || roomExportInProgress) {
            if (uploadsInProgress) {
                const UploadBar = sdk.getComponent('structures.UploadBar');
                statusBar.push(<UploadBar room={room} key="upload-bar" onCancel={this.forceUpdate} />);
            }
            if (downloadsInProgress) {
                if (uploadsInProgress) {
                    statusBar.push(
                        <div
                            className="mx_UploadBar_separator"
                            key="progress-bars-separator"
                        />,
                    );
                }
                statusBar.push(<CitadelDownloadBar room={room} key="download-bar" />);
            }
            if (roomExportInProgress) {
                const exportProgressText = RoomExportStore.getRoomExportProgressText(room.roomId);
                statusBar.push(
                    <div
                        className="mx_UploadBar_separator"
                        key="progress-bars-separator-export"
                    />,
                );
                statusBar.push(
                    <RoomExportBar
                        exportProgressText={exportProgressText}
                        isCompleted={roomExportStatus === 'completed'}
                        key="export-room"
                        roomId={room.roomId}
                    />,
                );
            }
        } else if (!searchResults) {
            statusBar = <RoomStatusBar
                room={room}
                hasActiveCall={inCall}
                isPeeking={memberShip !== "join"}
                onInviteClick={this.onInviteButtonClick}
                onStopWarningClick={this.onStopAloneWarningClick}
                onVisible={this.onStatusBarVisible}
                onHidden={this.onStatusBarHidden}
            />;
        }

        if (inCall) {
            const TintableSvg = sdk.getComponent("elements.TintableSvg");
            let zoomButton;
            let videoMuteButton;

            if (call.type === "video") {
                zoomButton = (
                    <div className="mx_RoomView_voipButton" onClick={this.onFullscreenClick} title={_t("Fill screen")}>
                        <TintableSvg src={require("../../../res/img/fullscreen.svg")} width="29" height="22" style={{ marginTop: 1, marginRight: 4 }} />
                    </div>
                );

                videoMuteButton =
                    <div className="mx_RoomView_voipButton" onClick={this.onMuteVideoClick}>
                        <TintableSvg src={call.isLocalVideoMuted() ? require("../../../res/img/video-unmute.svg") : require("../../../res/img/video-mute.svg")}
                                     alt={call.isLocalVideoMuted() ? _t("Click to unmute video") : _t("Click to mute video")}
                                     width="31" height="27" />
                    </div>;
            }

            const voiceMuteButton =
                <div className="mx_RoomView_voipButton" onClick={this.onMuteAudioClick}>
                    <TintableSvg src={call.isMicrophoneMuted() ? require("../../../res/img/voice-unmute.svg") : require("../../../res/img/voice-mute.svg")}
                                 alt={call.isMicrophoneMuted() ? _t("Click to unmute audio") : _t("Click to mute audio")}
                                 width="21" height="26" />
                </div>;

            // wrap the existing status bar into a 'callStatusBar' which adds more knobs.
            statusBar =
                <div className="mx_RoomView_callStatusBar">
                    { voiceMuteButton }
                    { videoMuteButton }
                    { zoomButton }
                    { statusBar }
                    <TintableSvg className="mx_RoomView_voipChevron" src={require("../../../res/img/voip-chevron.svg")} width="22" height="17" />
                </div>;
        }

        const allLaterKeysToDecrypt = JSON.parse(localStorage.getItem("allLaterKeysToDecrypt") || "{}");
        const laterKeysToDecrypt = allLaterKeysToDecrypt[this.state.room.roomId];

        return (
            <div className="mx_RoomView_statusArea">
                    <div className="mx_RoomView_statusAreaBox">
                        {encryptionKeysRequest
                            && !isEncryptionKeysRequestBarMinimized
                            && memberShip === "join"
                            && !this.state.isKeySharingHidden
                            &&
                            <>
                                <EncryptionKeysRequestBar
                                    encryptionKeysRequest={encryptionKeysRequest}
                                    onMinimize={this.onMinimizeEncryptionKeysRequestBar}
                                    isMinimized={isEncryptionKeysRequestBarMinimized}
                                    roomId={this.state.room.roomId}
                                    hasSentE2eKeysToMembers={this.state.hasSentE2eKeys}
                                    hasSentE2eRetrievalEvent={this.hasSentE2eRetrievalEvent()}
                                    laterKeysToDecrypt={laterKeysToDecrypt}
                                />
                                <div className="mx_RoomView_statusAreaBox_line" />
                            </>
                        }
                        {statusBar}
                    </div>
            </div>
        );
    }

    renderRoomPreviewBar = (memberShip, collapsedRhs) => {
        const {
            room,
            roomAlias,
            roomLoadError,
            joining,
            rejecting,
            expandedPreviewBar,
            e2eStatus,
        } = this.state;

        const loading = this.isLoading();
        const busy = this.isBusy();
        const canPreview = this.canPreview();

        const onJoinClick = !busy ? this.onJoinButtonClicked : () => {};
        const onForgetClick = !busy ? this.onForgetClick : () => {};
        let onRejectClick = () => {};

        let inviter = {};
        if (!busy) {
            if (memberShip === 'invite') {
                onRejectClick = this.onRejectButtonClicked;
                const myUserId = MatrixClientPeg.get().getUserId();
                const myMember = room.getMember(myUserId);
                const inviterMemberEvent = myMember.events.member;
                inviter = inviterMemberEvent ? {
                    userId: inviterMemberEvent.getSender(),
                    displayName: (inviterMemberEvent.sender && inviterMemberEvent.sender.name) || '',
                } : {};
            } else {
                onRejectClick = this.onRejectThreepidInviteButtonClicked;
            }
        }

        return (
            <RoomPreviewBar
                onJoinClick={onJoinClick}
                onRejectClick={onRejectClick}
                onForgetClick={onForgetClick}
                inviter={inviter}
                invitedEmail={''}
                error={roomLoadError}
                canPreview={canPreview}
                room={room}
                loading={loading}
                joining={joining}
                rejecting={rejecting}
                roomAlias={roomAlias}
                onCollapseOrExtend={this.onRoomPreviewBarCollapseOrExpand}
                expanded={expandedPreviewBar}
                collapsedRhs={collapsedRhs}
                e2eStatus={e2eStatus}
            />
        );
    }

    render() {
        const { room, searchResults, callState, forwardingEvent, showExternalBar } = this.state;
        const { isHPS } = this.props;
        const canPreview = this.canPreview();
        let collapsedRhs = this.props.collapsedRhs;

        let previewBar;
        if (!room) {
            previewBar = this.renderRoomPreviewBar(null, collapsedRhs);
            collapsedRhs = this.state.ignoreCollapsedRhsPropsValue;
            return (
                <div className="mx_RoomView">
                    {previewBar}
                </div>
            );
        }

        const myMembership = room.getMyMembership();
        if (myMembership === 'invite') {
            previewBar = this.renderRoomPreviewBar(myMembership, collapsedRhs);
            collapsedRhs = this.state.ignoreCollapsedRhsPropsValue;
            if (!canPreview) {
                return previewBar;
            }
        }

        const roomVersionRecommendation = this.state.upgradeRecommendation;
        const showRoomUpgradeBar = (
            roomVersionRecommendation &&
            roomVersionRecommendation.needsUpgrade &&
            room.userMayUpgradeRoom(MatrixClientPeg.get().credentials.userId)
        );

        const hiddenHighlightCount = this._getHiddenHighlightCount();

        let aux;
        let hideCancel = false;

        const renderExternalBar = () => {
            const { isDmRoom } = this.state;
            const otherMember = Rooms.getOnlyOtherMember(room, UserStore.getMe().userId);
            const displayName = isDmRoom && !!otherMember && UserStore.getUserProp(otherMember.userId, 'displayName') || '';

            return <CitadelExternalBar
                isDmRoom={isDmRoom}
                displayName={displayName}
                onCloseClick={this.onForgetExternalBarClick}
                collapsedRhs={this.props.collapsedRhs}
            />;
        };

        if (forwardingEvent !== null) {
            hideCancel = true;
            const forwardMessage = <ForwardMessage onCancelClick={this.onCancelClick} />;
            if (showExternalBar) {
                aux = (
                    <div>
                        {forwardMessage}
                        {renderExternalBar()}
                    </div>
                );
            } else {
                aux = forwardMessage;
            }
        } else if (this.state.searching) {
            hideCancel = true; // has own cancel
            aux = <SearchBar ref="search_bar" searchInProgress={this.state.searchInProgress}
                             onCancelClick={this.onCancelSearchClick} onSearch={this.onSearch} />;
        } else if (showExternalBar) {
            hideCancel = true;
            aux = renderExternalBar();
        } else if (showRoomUpgradeBar) {
            aux = <RoomUpgradeWarningBar room={room} recommendation={roomVersionRecommendation} />;
            hideCancel = true;
        } else if (this.state.showingPinned) {
            hideCancel = true; // has own cancel
            aux = <PinnedEventsPanel room={room} onCancelClick={this.onPinnedClick} />;
        } else if (myMembership !== "join" && myMembership !== "invite") {
            // We do have a room object for this room, but we're not currently in it.
            hideCancel = true;
            previewBar = this.renderRoomPreviewBar(myMembership, collapsedRhs);
            collapsedRhs = this.state.ignoreCollapsedRhsPropsValue;
            if (!canPreview) {
                return (
                    <div className="mx_RoomView">
                        { previewBar }
                    </div>
                );
            }
        } else if (hiddenHighlightCount > 0) {
            aux = (
                <AccessibleButton element="div" className="mx_RoomView_auxPanel_hiddenHighlights"
                                  onClick={this._onHiddenHighlightsClick}>
                    {_t("You have %(count)s unread notifications in a prior version of this room.",
                        {count: hiddenHighlightCount},
                    )}
                </AccessibleButton>
            );
        }

        const auxPanel = (
            <AuxPanel ref="auxPanel" room={room}
              fullHeight={false}
              userId={MatrixClientPeg.get().credentials.userId}
              conferenceHandler={this.props.ConferenceHandler}
              draggingFile={this.state.draggingFile}
              displayConfCallNotification={this.state.displayConfCallNotification}
              maxHeight={this.state.auxPanelMaxHeight}
              showApps={this.state.showApps}
              hideAppsDrawer={false} >
                { aux }
            </AuxPanel>
        );

        let messageComposer;
        if (myMembership === 'join' && !searchResults) {
            messageComposer =
                <MessageComposer
                    room={room}
                    callState={this.state.callState}
                    disabled={this.props.disabled}
                    showApps={this.state.showApps}
                    e2eStatus={this.state.e2eStatus}
                    permalinkCreator={this._getPermalinkCreatorForRoom(room)}
                />;
        }

        // TODO: Why aren't we storing the term/scope/count in this format
        // in this.state if this is what RoomHeader desires?
        let searchInfo;
        if (searchResults) {
            searchInfo = {
                searchTerm: this.state.searchTerm,
                searchScope: this.state.searchScope,
                searchCount: searchResults.count,
            };
        }

        // if we have search results, we keep the messagepanel (so that it preserves its
        // scroll state), but hide it.
        let searchResultsPanel;
        let hideMessagePanel = false;
        if (searchResults) {
            // show searching spinner
            if (searchResults.results === undefined) {
                searchResultsPanel = (<div className="mx_RoomView_messagePanel mx_RoomView_messagePanelSearchSpinner" />);
            } else {
                searchResultsPanel = (
                    <ScrollPanel ref="searchResultsPanel"
                        className="mx_RoomView_messagePanel mx_RoomView_searchResultsPanel"
                        onFillRequest={this.onSearchResultsFillRequest}
                        resizeNotifier={this.props.resizeNotifier}
                    >
                        <li className="mx_RoomView_scrollheader" />
                        { this.getSearchResultTiles() }
                    </ScrollPanel>
                );
            }
            hideMessagePanel = true;
        }

        const shouldHighlight = this.state.isInitialEventHighlighted;
        let highlightedEventId = null;
        if (forwardingEvent) {
            highlightedEventId = forwardingEvent.getId();
        } else if (shouldHighlight) {
            highlightedEventId = this.state.initialEventId;
        }

        // console.log("ShowUrlPreview for %s is %s", this.state.room.roomId, this.state.showUrlPreview);
        const messagePanel = (
            <TimelinePanel
                className="mx_RoomView_messagePanel"
                eventId={this.state.initialEventId}
                eventPixelOffset={this.state.initialEventPixelOffset}
                expandedPreviewBar={this.props.expandedPreviewBar}
                hidden={hideMessagePanel}
                highlightedEventId={highlightedEventId}
                isAlone={this.state.isAlone}
                manageReadMarkers={!this.state.isPeeking}
                manageReadReceipts={!this.state.isPeeking}
                membersLoaded={this.state.membersLoaded}
                onReadMarkerUpdated={this._updateTopUnreadMessagesBar}
                onScroll={this.onMessageListScroll}
                onStopAloneWarningClick={this.onStopAloneWarningClick}
                permalinkCreator={this._getPermalinkCreatorForRoom(room)}
                previewBarDisplayed={Boolean(previewBar)}
                ref={this._gatherTimelinePanelRef}
                resizeNotifier={this.props.resizeNotifier}
                showReadReceipts={SettingsStore.getValue('showReadReceipts')}
                showUrlPreview = {this.state.showUrlPreview}
                timelineSet={room.getUnfilteredTimelineSet()}
                hasSentE2eRetrievalEvent={this.hasSentE2eRetrievalEvent()}
            />);

        let topUnreadMessagesBar;
        if (this.state.showTopUnreadMessagesBar) {
            topUnreadMessagesBar = (<TopUnreadMessagesBar
                                       onScrollUpClick={this.jumpToReadMarker}
                                       onCloseClick={this.forgetReadMarker}
                                    />);
        }

        let jumpToBottom;
        if (!this.state.atEndOfLiveTimeline && !(previewBar && this.state.expandedPreviewBar)) {
            jumpToBottom = (<JumpToBottomButton
                numUnreadMessages={this.state.numUnreadMessages}
                onScrollToBottomClick={this.jumpToLiveTimeline}
            />);
        }

        const call = this._getCallForRoom();
        const inCall = (call && callState !== 'ended' && callState !== 'ringing');

        const rightPanel = room &&
            <RightPanel roomId={room.roomId} resizeNotifier={this.props.resizeNotifier} />;
        const encryptionKeysRequestFiltered = this.getEncryptionKeysRequest();
        const statusBar = this.renderStatusBar(call, inCall, myMembership, encryptionKeysRequestFiltered);
        const statusBarMinimized = this.renderMinimizedStatusBar(encryptionKeysRequestFiltered);
        const fadableSectionClasses = classNames(
            "mx_RoomView_body", "mx_fadable",
            {
                "mx_fadable_faded": this.props.disabled,
            },
        );
        const hpsIcon = isHPS
            ? <div className='mx_RoomView_branding_container'> <div className='mx_RoomView_branding' /> </div>
            : null;

        return (
            <main className={"mx_RoomView" + (inCall ? " mx_RoomView_inCall" : "")} ref="roomView">
                <RoomHeader ref="header" room={room} searchInfo={searchInfo}
                    oobData={this.props.oobData}
                    collapsedRhs={collapsedRhs}
                    onSearchClick={(myMembership === 'join') ? this.onSearchClick : () => {}}
                    onSettingsClick={(myMembership === 'join') ? this.onSettingsClick : () => {}}
                    onPinnedClick={this.onPinnedClick}
                    onCancelClick={(aux && !hideCancel) ? this.onCancelClick : null}
                    onForgetClick={(myMembership === "leave") ? this.onForgetClick : null}
                    onLeaveClick={(myMembership === "join") ? this.onLeaveClick : null}
                    e2eStatus={this.state.e2eStatus}
                    myMembership={myMembership}
                    isDmRoom={this.state.isDmRoom}
                />
                <MainSplit
                    panel={rightPanel}
                    collapsedRhs={collapsedRhs}
                    resizeNotifier={this.props.resizeNotifier}
                >
                    <div className={fadableSectionClasses}>
                        { auxPanel }
                        <div className="mx_RoomView_timeline">
                            { topUnreadMessagesBar }
                            { jumpToBottom }
                            { messagePanel }
                            { searchResultsPanel }
                            { hpsIcon }
                        </div>
                        { statusBarMinimized }
                        { statusBar }
                        { previewBar }
                        { messageComposer }
                    </div>
                </MainSplit>
            </main>
        );
    }
}

RoomView.propTypes = {
    ConferenceHandler: PropTypes.any,

    // Called with the credentials of a registered user (if they were a ROU that
    // transitioned to PWLU)
    onRegistered: PropTypes.func,

    // An object representing a third party invite to join this room
    // Fields:
    // * inviteSignUrl (string) The URL used to join this room from an email invite
    //                          (given as part of the link in the invite email)
    // * invitedEmail (string) The email address that was invited to this room
    thirdPartyInvite: PropTypes.object,

    // Any data about the room that would normally come from the homeserver
    // but has been passed out-of-band, eg. the room name and avatar URL
    // from an email invite (a workaround for the fact that we can't
    // get this information from the HS using an email invite).
    // Fields:
    //  * name (string) The room's name
    //  * avatarUrl (string) The mxc:// avatar URL for the room
    //  * inviterName (string) The display name of the person who
    //  *                      invited us tovthe room
    oobData: PropTypes.object,

    // is the RightPanel collapsed?
    collapsedRhs: PropTypes.bool,

    // Servers the RoomView can use to try and assist joins
    viaServers: PropTypes.arrayOf(PropTypes.string),
    isHPS: PropTypes.bool.isRequired,
};

export default RoomView;
