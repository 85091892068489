/*
 Copyright 2016 OpenMarket Ltd

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

'use strict';

import React from 'react';
import MFileBody from './MFileBody';

import MatrixClientPeg from '../../../MatrixClientPeg';
import { decryptFile } from '../../../utils/DecryptFile';
import { _t } from '../../../languageHandler';
import { fetchUrlWithToken, getMxcFromUrl } from '../../../utils/MediaUtils';
import dis from '../../../dispatcher';
import MxcBlobStore from '../../../stores/MxcBlobStore';

export default class MAudioBody extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            playing: false,
            decryptedUrl: null,
            decryptedBlob: null,
            error: null,
        };
    }
    onPlayToggle() {
        this.setState({
            playing: !this.state.playing,
        });
    }

    _getContentUrl() {
        const content = this.props.mxEvent.getContent();
        return MatrixClientPeg.get().mxcUrlToHttp(content.url);
    }

    componentDidMount() {
        const content = this.props.mxEvent.getContent();
        if (content.file !== undefined && this.state.decryptedUrl === null) {
            let decryptedBlob;
            decryptFile(content.file).then((blob) => {
                decryptedBlob = blob;
                const url = URL.createObjectURL(decryptedBlob);
                this.setState({
                    decryptedUrl: url,
                    decryptedBlob: decryptedBlob,
                });
            }, (err) => {
                console.warn("Unable to decrypt attachment: ", err);
                this.setState({
                error: err,
                });
            });
        } else {
            const contentUrl = this._getContentUrl();
            const mxc = getMxcFromUrl(contentUrl);
            const blob = MxcBlobStore.getBlobFromMxc(mxc);

            if (blob) {
                this.setState({
                    tokenizedUrl: blob,
                });
            } else {
                fetchUrlWithToken(contentUrl)
                .then((blob) => {
                    if (blob) {
                        dis.dispatch({
                            action: 'update_blob_store',
                            mxc: mxc,
                            blob: blob,
                        });
                        this.setState({
                            tokenizedUrl: blob,
                        });
                    }
                })
                .catch((err) => {
                    console.error('Could not retrieve url content', err);
                });
            }
        }
    }

    componentWillUnmount() {
        if (this.state.decryptedUrl) {
            URL.revokeObjectURL(this.state.decryptedUrl);
        }
    }

    render() {
        const content = this.props.mxEvent.getContent();
        const { decryptedUrl, tokenizedUrl } = this.state;

        if (this.state.error !== null) {
            return (
                <span className="mx_MAudioBody" ref="body">
                    {/*<img src={require("../../../../res/img/warning.svg")} width="16" height="16" />*/}
                    { _t("Error decrypting audio") }
                </span>
            );
        }

        if (content.file !== undefined && this.state.decryptedUrl === null) {
            // Need to decrypt the attachment
            // The attachment is decrypted in componentDidMount.
            // For now add an img tag with a 16x16 spinner.
            // Not sure how tall the audio player is so not sure how tall it should actually be.
            return (
                <span className="mx_MAudioBody">
                    <img src={require("../../../../res/img/spinner.gif")} alt={content.body} width="16" height="16" />
                </span>
            );
        }

        const contentUrl = (content.file !== undefined) ? decryptedUrl : tokenizedUrl;

        return (
            <span className="mx_MAudioBody">
                <audio src={contentUrl} controls />
                <MFileBody {...this.props} decryptedBlob={this.state.decryptedBlob} />
            </span>
        );
    }
}
