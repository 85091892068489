import * as React from 'react';

import Svg from './Svg';
import MatrixClientPeg from '../../../MatrixClientPeg';
import dis from '../../../dispatcher';

import redDot from '../../../../res/img/new.svg';

class CreateNewsButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = { shouldDisplayRedMarker: false };
    }

    componentDidMount() {
        this.hasBeenClicked();
        MatrixClientPeg.get().on('accountData', this._onAccountData);
    }

    componentWillUnmount() {
        const cli = MatrixClientPeg.get();
        cli && cli.removeListener('accountData', this._onAccountData);
    }

    hasBeenClicked = async ev => {
        const cli = MatrixClientPeg.get();
        const createNewsDataEvent = ev || cli.getAccountData('citadel.news');
        const mfaAccountDataEventContent = createNewsDataEvent ? createNewsDataEvent.getContent() : {};
        const hasCheckedCreateNewsFeature = mfaAccountDataEventContent['has_clicked_news_creation'];
        this.setState({
            shouldDisplayRedMarker: !hasCheckedCreateNewsFeature,
        });
    };

    onClick = () => {
        const { shouldDisplayRedMarker } = this.state;
        if ( shouldDisplayRedMarker ) {
            MatrixClientPeg.get().setAccountData('citadel.news', {
                ['has_clicked_news_creation']: true,
            });
        }
        dis.dispatch({ action: 'update_news_visibility', visibility: 'main' });
    };

    _onAccountData = ev => {
        const { shouldDisplayRedMarker } = this.state;
        const { event } = ev;
        const content = ev.getContent();

        if (event.type === 'citadel.news' && content.has_clicked_news_creation === shouldDisplayRedMarker) {
            this.hasBeenClicked(ev);
        }
    };

    render() {
        const { shouldDisplayRedMarker } = this.state;

        return (
            <React.Fragment>
                <div className="mx_CreateNewsButton" onClick={this.onClick} >
                    <div className="mx_CreateNewsButton_icon" />
                    {shouldDisplayRedMarker && <Svg
                        className="mx_CreateNewsButton_new-feature"
                        src={redDot}
                    />}
                </div>
            </React.Fragment>
        );
    }
}

export default CreateNewsButton;
