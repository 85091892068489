import React from 'react';
import PropTypes from 'prop-types';
import cx from "classnames";

import dis from '../../../dispatcher';
import { _t } from "../../../languageHandler";
import MatrixClientPeg from "../../../MatrixClientPeg";
import InConferenceFullList from './InConferenceFullList';
import MuteAllDialog from './MuteAllDialog';
import Modal from '../../../Modal';
import CitadexStore, { TYPE_AUDIO } from "../../../stores/CitadexStore";
import ConferenceButton from "../elements/ConferenceButton";
import { ARROW_POS, HintedComponent } from "../elements/HintedComponent";
import HintManager from "../../../HintManager";

import audioIcon from "../../../../res/img/conference/audio-small-white.svg";
import videoIcon from "../../../../res/img/conference/video-small-white.svg";
import CitadelRoomName from "../elements/CitadelRoomName";
import shareScreenOn from '../../../../res/img/conference/share-screen-on-bubble.svg';
import callList from '../../../../res/img/conference/call-list-icon.svg';
import callListActive from '../../../../res/img/conference/call-list-active.svg';
import maximize from "../../../../res/img/conference/maximize.svg";
import maximizeHover from "../../../../res/img/conference/maximize-hover.svg";
import maximizeReduce from "../../../../res/img/conference/maximize-reduce.svg";
import maximizeReduceHover from "../../../../res/img/conference/maximize-reduce-hover.svg";
import minimize from "../../../../res/img/conference/minimize.svg";
import minimizeHover from "../../../../res/img/conference/minimize-hover.svg";
import minimized from "../../../../res/img/conference/minimized.svg";
import minimizedHover from "../../../../res/img/conference/minimized-hover.svg";
import SettingsStore from '../../../settings/SettingsStore';


const FLAGS = {
    MAXIMIZED: 'has_shown_conference_maximize',
    MINIMIZE: 'has_shown_conference_minimize',
};

export default class ConferenceTopBar extends React.Component {
    state = {
        isFullScreen: false,
        isMaximized: false,
        isMinimized: false,
        isParticipantsListVisible: false,
        presenter: null,
    };

    componentDidMount() {
        this.dispatcherRef = dis.register(this.onAction);
        const cli = MatrixClientPeg.get();
        const isMaximizedHintDisplayed = !cli.getInAppMessageFlag(FLAGS.MAXIMIZED);
        const isMinimizeHintDisplayed = !cli.getInAppMessageFlag(FLAGS.MINIMIZE);
        this.setState({ isMaximizedHintDisplayed, isMinimizeHintDisplayed });
        const conferenceInNewTab = SettingsStore.getValue("Conference.openConferenceInTab");
        this.setState({ isMaximized: conferenceInNewTab });
    }

    componentWillUnmount() {
        dis.unregister(this.dispatcherRef);
    }

    onAction = (payload) => {
        if (payload.action === 'conference_full_screen') {
            this.setState({ isFullScreen: payload.isFullScreen });
        }

        if (payload.action === 'minimize_conference') {
            const { isMinimized } = this.state;
            if (isMinimized !== payload.isMinimized) {
                this.setState({isMinimized: payload.isMinimized});
            }
        }
    }

    handleMuteAll = () => {
        this.setState({ isParticipantsListVisible: false });
        Modal.createTrackedDialog('Mute all participants', '', MuteAllDialog, {
            onFinished: () => { },
            onMute: this.props.onMute(),
        }, 'citadex_mute_all_dialog');
        this.closeContext();
    }

    toggleParticipantsList = () => this.setState({
        isParticipantsListVisible: !this.state.isParticipantsListVisible,
    });

    renderListButton = () => {
        const { participants } = this.props;
        const { isParticipantsListVisible } = this.state;
        const numberOfParticipants = Object.keys(participants).length;
        const listButtonClassname = cx("extra_joined_participants_container", { open: isParticipantsListVisible });
        return <div className={listButtonClassname} onClick={this.toggleParticipantsList}>
            <div className="extra_joined_participants_number">{`${numberOfParticipants}`}</div>
            <div
                className={"extra_joined_participants"}
                key='number'
            >
                <img className="extra_joined_participants_icon"
                    src={isParticipantsListVisible ? callListActive : callList}
                />
            </div>
        </div>;
    };

    getPresenter = () => {
        const { screenSharerStreamId, participants = {} } = this.props;
        return Object.values(participants).find(({ videoStreamId }) => videoStreamId === screenSharerStreamId);
    }

    toggleDisplayState = (propName) => () => {
        if (propName === 'maximize') {
            const { isMaximized } = this.state;
            this.setState({ isMaximized: !isMaximized }, () => {
                dis.dispatch({ action: 'maximize_conference', isMaximized: this.state.isMaximized });
            });
        }
        if (propName === 'minimize') {
            const { isMinimized } = this.state;

            if (isMinimized) HintManager.checkPendingHints();

            this.setState({ isMinimized: !isMinimized }, () => {
                dis.dispatch({ action: 'minimize_conference', isMinimized: this.state.isMinimized });
            });
        }
    }

    closeHint = (variableName) => () => this.setState({[variableName]: false });

    render() {
        const {
            amISharingMyScreen,
            isVisible,
            onKick,
            onMute,
            participants,
            roomName,
            screenSharerStreamId,
            type,
        } = this.props;

        const {
            isFullScreen,
            isMaximized,
            isMinimized,
            isMaximizedHintDisplayed,
            isMinimizeHintDisplayed,
            isParticipantsListVisible,
        } = this.state;

        const presenter = this.getPresenter() || {};
        const callEvent = CitadexStore.getCallInProgress();
        const isInviter = callEvent && localStorage.getItem('mx_user_id') === callEvent.event.content.inviter_id;
        const conferenceTopBarClassname = cx('citadex_visio_header', { hide: !isVisible && isFullScreen, isFullScreen });
        const conferenceInNewTab = SettingsStore.getValue("Conference.openConferenceInTab");

        return <div className={conferenceTopBarClassname}>
            <div className='citadex_room_info_container'>
                <div className='citadex_room_info_container_left'>
                    <img className='citadex_room_type_icon' src={type === TYPE_AUDIO ? audioIcon : videoIcon} alt='' />
                    <div className='citadex_room_header_name'>
                        <CitadelRoomName
                            className='citadex_room_name'
                            roomName={roomName}
                            width={isFullScreen ? 165 : 780}
                        />
                    </div>
                    {amISharingMyScreen
                        ? <div className='share_screen_bubble'>
                            <img className='icon' src={shareScreenOn} />
                            {_t('You are sharing your screen')}
                        </div>
                        : null
                    }
                    {!amISharingMyScreen && screenSharerStreamId && presenter.displayName
                        ? <div className='share_screen_bubble'>
                            <img className='icon' src={shareScreenOn} />
                            {_t("%(name)s's screen", { name: presenter.displayName })}
                        </div>
                        : null
                    }
                </div>
                <div className='citadex_room_info_container_right'>
                    {this.renderListButton()}
                    {isParticipantsListVisible && <InConferenceFullList
                        onClose={this.toggleParticipantsList}
                        handleMuteAll={this.handleMuteAll}
                        isInviter={isInviter}
                        participants={participants}
                        onMute={onMute}
                        onKick={onKick}
                    />}
                    {!conferenceInNewTab && !isFullScreen
                        ? (
                            <HintedComponent
                                className={'hinted_conference_minimize'}
                                flag={FLAGS.MINIMIZE}
                                isHintDisplayed={isMinimizeHintDisplayed && !isMaximizedHintDisplayed}
                                hintInfo={{
                                    title: _t('New layout opportunities with conference'),
                                    description: _t('You can choose the layout you prefer according to your ' +
                                        'usage, This “minimize” icon allows you to reduce the conference and anchor' +
                                        ' it to the bottom left of Citadel.'),
                                }}
                                hintStyle={{ top: 50, right: 50, position: 'absolute' }}
                                hintArrow={{ isDisplayed: true, position: ARROW_POS.TOP_RIGHT }}
                                targetId='conference_HintOverlayContainer'
                                onHintClose={this.closeHint('isMinimizeHintDisplayed')}
                            >
                                <ConferenceButton
                                    className="minimize"
                                    onClick={this.toggleDisplayState('minimize')}
                                    icon={isMinimized ? minimized: minimize}
                                    iconHover={isMinimized ? minimizedHover : minimizeHover}
                                />
                            </HintedComponent>
                        )
                        : null
                    }
                    {!conferenceInNewTab && !isFullScreen ?
                        <HintedComponent
                            className={'hinted_conference_maximize'}
                            flag={FLAGS.MAXIMIZED}
                            isHintDisplayed={isMaximizedHintDisplayed}
                            hintInfo={{
                                title: _t('New layout opportunities with conference'),
                                description: _t('This “expand” icon allows you to put the conference in full ' +
                                    'screen. To leave this mode, you can click again on the icon which will change ' +
                                    'direction.'),
                            }}
                            hintStyle={{top: 50, right: -7, position: 'absolute'}}
                            hintArrow={{isDisplayed: true, position: ARROW_POS.TOP_RIGHT}}
                            targetId='conference_HintOverlayContainer'
                            onHintClose={this.closeHint('isMaximizedHintDisplayed')}
                        >
                            <ConferenceButton
                                className="maximize"
                                onClick={this.toggleDisplayState('maximize')}
                                icon={isMaximized ? maximizeReduce : maximize}
                                iconHover={isMaximized ? maximizeReduceHover : maximizeHover}
                            />
                        </HintedComponent>
                        : null
                    }
                </div>
            </div>
        </div>;
    }
}

ConferenceTopBar.propTypes = {
    amISharingMyScreen: PropTypes.bool.isRequired,
    isVisible: PropTypes.bool.isRequired,
    onKick: PropTypes.func.isRequired,
    onMute: PropTypes.func.isRequired,
    participants: PropTypes.object.isRequired,
    roomName: PropTypes.string.isRequired,
    screenSharerStreamId: PropTypes.number,
    type: PropTypes.string.isRequired,
};
