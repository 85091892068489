import React from 'react';
import Truncate from 'react-truncate';

import MemberAvatar from '../avatars/MemberAvatar';
import { formatRichDate } from '../../../DateUtils';
import MatrixClientPeg from '../../../MatrixClientPeg';
import { _t } from '../../../languageHandler';

export default class ReadReceiptFullList extends React.Component {
    constructor(props) {
        super(props);
        this.readReceiptRef = React.createRef();
    }

    componentDidMount() {
        const scrollPanel = document.querySelector('.mx_ScrollPanel');
        if (scrollPanel) scrollPanel.style.overflow = 'hidden';
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        const scrollPanel = document.querySelector('.mx_ScrollPanel');
        if (scrollPanel) scrollPanel.style.overflow = '';
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    getOrCreateOverlayContainer = () => {
        const DIALOG_CONTAINER_ID = "mx_ReadReceiptFullListContainer";
        let container = document.getElementById(DIALOG_CONTAINER_ID);

        if (!container) {
            container = document.createElement("div");
            container.id = DIALOG_CONTAINER_ID;
            document.body.appendChild(container);
        }

        return container;
    };

    handleClickOutside = (e) => {
        if (this.readReceiptRef && !this.readReceiptRef.current.contains(e.target)) {
          this.props.closeReceiptList();
        }
      }

    renderReadTiles = () => {
        const { isTwelveHour, receipts } = this.props;

        return receipts.map((receipt) =>
            <div key={receipt.userId} className="read-receipt">
                <div className="avatar">
                    <MemberAvatar
                        member={receipt.roomMember}
                        fallbackUserId={receipt.userId}
                        aria-hidden="true"
                        width={40} height={40}
                        resizeMethod="crop"
                    />
                </div>
                <div className="info">
                    <Truncate className="name" lines={1} title={receipt.roomMember.rawDisplayName}>
                        {receipt.roomMember.rawDisplayName}
                    </Truncate>
                    <div className="timestamp">
                        {formatRichDate(new Date(receipt.ts), isTwelveHour)}
                    </div>
                </div>
            </div>);
    }

    render() {
        const { receipts, style } = this.props;

        const readReceiptsCount = receipts.length;

        const roomId = receipts[0].roomMember.roomId;
        const room = MatrixClientPeg.get().getRoom(roomId);
        const joinedMembersCount = room.getJoinedMemberCount();

        return <div ref={this.readReceiptRef} style={style} className="mx_ReadReceiptFullList">
            <div className="header">
                <span className="title">{_t('Read by')}</span>
                <span className="count">{`(${readReceiptsCount}/${joinedMembersCount-1})`}</span>
            </div>
            <div className="receipts">
                {this.renderReadTiles()}
            </div>
        </div>;
    }
}
