import React from 'react';

import { _t } from '../../../../languageHandler';
import Editor from '../../../structures/editor/Editor';

const NewsEditor = ({ onChange, content }) => {
    return (
        <div className="citadel-news-editor-container">
            <div className="label">
                {_t("Write your message")}
                <span className="required_mark">*</span>
            </div>
            <Editor content={content} onChange={onChange} id='news-editor' />
        </div>
    );
};

export default NewsEditor;
