import React from 'react';
import PropTypes from 'prop-types';

const CitadelInfo = ({ label, value }) => (
    <div className='mx_CitadelInfo'>
        <div className='label'>
            { label }
        </div>
        <div className='value'>
            { value }
        </div>
    </div>
);

CitadelInfo.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
};

CitadelInfo.defaultProps = {
    value: '',
};

export default CitadelInfo;
