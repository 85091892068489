/*
Copyright 2015, 2016 OpenMarket Ltd
Copyright 2017 New Vector Ltd.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React from 'react';
import PropTypes from 'prop-types';
import sdk from '../../../index';
import { _t } from '../../../languageHandler';

export default class extends React.Component {
    static displayName = 'ChangePasswordDialog';

    static propTypes = {
        onFinished: PropTypes.func.isRequired,
    };

    onOk = () => {
        this.props.onFinished(true);
    };

    onCancel = () => {
        this.props.onFinished(false);
    }

    renderDescription = () => {
        return (
            <div>
                <div>
                    {_t('Changing your password will not disconnect you from this session but your sessions on other devices will be locked.')}
                </div>
                <div>
                    {_t('You will unlock them using your new password without losing your conversation history.')}
                </div>
            </div>
        );
    };

    renderButtons = () => {
        return (
            <div className="mx_Dialog_buttons">
                <div>
                    <button key="button" className="mx_Dialog_secondary"
                            onClick={this.onCancel}
                    >
                        {_t('Cancel')}
                    </button>
                </div>
                <div>
                    <button
                        className="mx_Dialog_primary"
                        onClick={this.onOk}
                        autoFocus={true}
                        disabled={false}
                    >
                        {_t('Continue')}
                    </button>
                </div>
            </div>
        );
    };

    render() {
        const BaseDialog = sdk.getComponent('views.dialogs.BaseDialog');

        return (
            <BaseDialog
                className="mx_QuestionDialog change_password_info"
                onFinished={this.props.onFinished}
                title={_t('Change Password')}
                contentId="mx_Dialog_content"
                hasCancel={true}
            >
                <div className="mx_Dialog_content" id="mx_Dialog_content">
                    {this.renderDescription()}
                </div>
                {this.renderButtons()}
            </BaseDialog>
        );
    }
}
