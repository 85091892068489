import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { _t } from '../../../../../languageHandler';
import SettingsStore from '../../../../../settings/SettingsStore';

import GenericToolTip from '../../../../structures/GenericToolTip';

import audioIconDisabled from '../../../../../../res/img/conference/audio.svg';
import audioIconEnabled from '../../../../../../res/img/conference/audio-small-white.svg';
import videoIconDisabled from '../../../../../../res/img/conference/video.svg';
import videoIconEnabled from '../../../../../../res/img/conference/video-small-white.svg';
import videoDarkIconDisabled from '../../../../../../res/img/conference/video-dark-disabled.svg';
import audioDarkIconDisabled from '../../../../../../res/img/conference/audio-dark-disabled.svg';

const ConferenceAudioVideoOptions = (props) => {
    const {
        calendarConferenceType,
        onCangeConferenceType,
        disabledAudioTooltipLabel,
        disabledVideoTooltipLabel,
        maxParticipants: {
            video: videoMaxParticipants,
            audio: audioMaxParticipants,
        },
    } = props;
    const audioClassname = cx('conference-audio', {
        enabled: disabledAudioTooltipLabel ? false : calendarConferenceType === 'Audio',
        disabled: disabledAudioTooltipLabel,
    });
    const videoClassname = cx('conference-video', {
        enabled: disabledVideoTooltipLabel ? false : calendarConferenceType === 'Video',
        disabled: disabledVideoTooltipLabel,
    });
    const theme = SettingsStore.getValue("theme");
    return (
        <div className="mx_ConferencePlanDialog_row">
            <h2 className="mx_ConferencePlanDialog_heading">{_t('Conference type')}</h2>
            <div className="mx_ConferencePlanDialog_line">
                <div className="mx_ConferencePlanDialog_radio">
                    <GenericToolTip label={disabledAudioTooltipLabel} labelStyle={"tooltip_conference_audio"}>
                        <div
                            className={audioClassname}
                            onClick={onCangeConferenceType('Audio', disabledAudioTooltipLabel)}
                        >
                            <img
                                src={calendarConferenceType === 'Audio' ?
                                    audioIconEnabled : theme === 'dark' ? audioDarkIconDisabled : audioIconDisabled}
                                className='conference-audio-icon'
                            />
                            <span className='conference-audio-text'>{_t("Audio")}</span>
                        </div>
                    </GenericToolTip>
                    <GenericToolTip label={disabledVideoTooltipLabel} labelStyle={"tooltip_conference_video"}>
                        <div
                            className={videoClassname}
                            onClick={onCangeConferenceType('Video', disabledVideoTooltipLabel)}
                        >
                            <img
                                src={calendarConferenceType === 'Video' ?
                                    videoIconEnabled : theme === 'dark' ? videoDarkIconDisabled : videoIconDisabled}
                                className='conference-video-icon'
                            />
                            <span className='conference-video-text'>{_t("Video")}</span>
                        </div>
                    </GenericToolTip>
                </div>
                <span className='mx_ConferencePlanDialog_maxParticipants'>
                    {_t('%(conferenceType)s limited to %(maxParticipants)s participants.', {
                        conferenceType: calendarConferenceType === 'Audio' ?
                            _t('Audioconference') : _t('Videoconference'),
                        maxParticipants: calendarConferenceType === 'Audio' ?
                            audioMaxParticipants : videoMaxParticipants,
                    })}
                </span>
            </div>
        </div>
    );
};

ConferenceAudioVideoOptions.propTypes = {
    calendarConferenceType: PropTypes.string.isRequired,
    onCangeConferenceType: PropTypes.func.isRequired,
    disabledAudioTooltipLabel: PropTypes.string.isRequired,
    disabledVideoTooltipLabel: PropTypes.string.isRequired,
    maxParticipants: PropTypes.object,
};

export default ConferenceAudioVideoOptions;
