/*
Copyright 2019 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { _t } from '../../../../../languageHandler';
import MatrixClientPeg from '../../../../../MatrixClientPeg';
import DMRoomMap from '../../../../../utils/DMRoomMap';
import LabelledToggleSwitch from '../../../elements/LabelledToggleSwitch';
import CitadelToggle from '../../../elements/CitadelToggle';
import Modal from '../../../../../Modal';
import QuestionDialog from '../../../dialogs/QuestionDialog';

export default class SecurityRoomSettingsTab extends React.Component {
    static propTypes = {
        roomId: PropTypes.string.isRequired,
    };

    constructor() {
        super();

        this.state = {
            joinRule: 'invite',
            guestAccess: 'can_join',
            history: '',
            isEncrypted: false,
        };
    }

    componentDidMount(): void {
        MatrixClientPeg.get().on('RoomState.events', this._onStateEvent);

        const room = MatrixClientPeg.get().getRoom(this.props.roomId);
        const state = room.currentState;

        const joinRule = this._pullContentPropertyFromEvent(
            state.getStateEvents('m.room.join_rules', ''),
            'join_rule',
            'invite',
        );
        const guestAccess = this._pullContentPropertyFromEvent(
            state.getStateEvents('m.room.guest_access', ''),
            'guest_access',
            'forbidden',
        );
        const history = this._pullContentPropertyFromEvent(
            state.getStateEvents('m.room.history_visibility', ''),
            'history_visibility',
            '',
        );
        const isEncrypted = MatrixClientPeg.get().isRoomEncrypted(this.props.roomId);
        this.setState({ joinRule, guestAccess, history, isEncrypted });

        MatrixClientPeg.get().getRoomDirectoryVisibility(this.props.roomId).then((result => {
            this.setState({ isRoomPublished: result.visibility === 'public' });
        }));
    }

    _pullContentPropertyFromEvent(event, key, defaultValue) {
        if (!event || !event.getContent()) return defaultValue;
        return event.getContent()[key] || defaultValue;
    }

    componentWillUnmount(): void {
        MatrixClientPeg.get().removeListener('RoomState.events', this._onStateEvent);
    }

    _onStateEvent = (e) => {
        const refreshWhenTypes = [
            'm.room.join_rules',
            'm.room.guest_access',
            'm.room.history_visibility',
            'm.room.encryption',
        ];
        if (refreshWhenTypes.includes(e.getType())) this.forceUpdate();
    };

    _onEncryptionChange = () => {
        Modal.createTrackedDialog('Enable encryption', '', QuestionDialog, {
            title: _t('Enabling end-to-end encryption'),
            description: _t('End-to-end encryption is designed to protect your most confidential exchanges. ' +
                'Once activated in this conversation, the option cannot be unset.',
            ),
            onFinished: (confirm) => {
                if (!confirm) {
                    this.setState({ isEncrypted: false });
                    return;
                }

                const beforeEncrypted = this.state.isEncrypted;
                const beforeHistory = this.state.history;
                this.setState({
                    isEncrypted: true,
                    history: 'joined',
                });
                MatrixClientPeg.get().sendStateEvent(
                    this.props.roomId, 'm.room.encryption',
                    { algorithm: 'm.megolm.v1.aes-sha2' },
                ).catch((e) => {
                    console.error(e);
                    this.setState({
                        isEncrypted: beforeEncrypted,
                        history: beforeHistory,
                    });
                });
            },
        });
    };

    _onRoomAccessRadioToggle = (name) => {
        //                         join_rule
        //                      INVITE  |  PUBLIC
        //        ----------------------+----------------
        // guest  CAN_JOIN   | inv_only | pub_with_guest
        // access ----------------------+----------------
        //        FORBIDDEN  | inv_only | pub_no_guest
        //        ----------------------+----------------

        // we always set guests forbidden since we don't allow guests on Citadel
        let joinRule = 'invite';
        let guestAccess = 'forbidden';

        switch (name) {
            case 'invite_only':
                // no change - use defaults above
                break;
            case 'public_no_guests':
                joinRule = 'public';
                guestAccess = 'forbidden';
                break;
            case 'public_with_guests':
                joinRule = 'public';
                guestAccess = 'can_join';
                break;
        }

        const beforeJoinRule = this.state.joinRule;
        const beforeGuestAccess = this.state.guestAccess;
        const beforeHistory = this.state.history;
        const beforeIsRoomPublished = this.state.isRoomPublished;

        this.setState({
            joinRule,
            guestAccess,
            history: (joinRule === 'public') ? 'world_readable' : 'shared',
            isRoomPublished: (joinRule === 'public'),
        });

        const client = MatrixClientPeg.get();
        client.sendStateEvent(this.props.roomId, 'm.room.join_rules', { join_rule: joinRule }, '').catch((e) => {
            console.error(e);
            this.setState({
                joinRule: beforeJoinRule,
                history: beforeHistory,
                isRoomPublished: beforeIsRoomPublished,
            });
        });
        client.sendStateEvent(this.props.roomId, 'm.room.guest_access', { guest_access: guestAccess }, '').catch((e) => {
            console.error(e);
            this.setState({ guestAccess: beforeGuestAccess });
        });
    };

    _onHistoryRadioToggle = (name) => {
        const beforeHistory = this.state.history;

        this.setState({ history: name });

        MatrixClientPeg.get().sendStateEvent(this.props.roomId, 'm.room.history_visibility', {
            history_visibility: name,
        }, '').catch((e) => {
            console.error(e);
            this.setState({ history: beforeHistory });
        });
    };

    _renderEncryption() {
        const { isEncrypted, isRoomPublished } = this.state;

        const client = MatrixClientPeg.get();
        const room = client.getRoom(this.props.roomId);
        const hasEncryptionPermission = room.currentState.mayClientSendStateEvent('m.room.encryption', client);
        const canEnableEncryption = !isEncrypted && hasEncryptionPermission && (isRoomPublished === false);

        if (!hasEncryptionPermission) {
            const encryptionTextValue = isEncrypted ? _t('End-to-end encryption is enabled') : _t('End-to-end encryption is disabled');
            return (
                <div className="mx_SettingsTab_subsectionText">
                    {encryptionTextValue}
                </div>
            );
        } else {
            const encryptionLabelValue = isEncrypted ? _t('End-to-end encryption is enabled') : _t('Enable end-to-end encryption');
            return (
                <div>
                    <div className="mx_SettingsTab_subsectionText">
                        <span>{_t('Once enabled, encryption cannot be disabled.')}</span>
                    </div>
                    <LabelledToggleSwitch value={isEncrypted} onChange={this._onEncryptionChange}
                                          label={encryptionLabelValue} disabled={!canEnableEncryption} />
                </div>
            );
        }
    }

    _renderRoomAccess() {
        const client = MatrixClientPeg.get();
        const room = client.getRoom(this.props.roomId);
        const joinRule = this.state.joinRule;

        const canChangeAccess = room.currentState.mayClientSendStateEvent('m.room.join_rules', client)
            && room.currentState.mayClientSendStateEvent('m.room.guest_access', client);

        return (
            <div>
                <CitadelToggle
                    type="radio"
                    label={_t('Private : Only people who have been invited')}
                    checked={joinRule !== 'public'}
                    disabled={!canChangeAccess}
                    onChange={() => this._onRoomAccessRadioToggle('invite_only')}
                />
                <CitadelToggle
                    type="radio"
                    label={_t('Public : All members of your company')}
                    checked={joinRule === 'public'}
                    disabled={!canChangeAccess || this.state.isEncrypted}
                    onChange={() => this._onRoomAccessRadioToggle('public_no_guests')}
                />
            </div>
        );
    }

    _renderHistory() {
        const { isEncrypted, isRoomPublished } = this.state;
        const client = MatrixClientPeg.get();
        const room = client.getRoom(this.props.roomId);

        const history = this.state.history;
        const canChangeHistory = room.currentState.mayClientSendStateEvent('m.room.history_visibility', client);

        const worldReadable = {
            disabled: canChangeHistory ? isEncrypted || !isRoomPublished : true,
            checked: history === 'world_readable',
        };

        const shared = {
            disabled: canChangeHistory ? isEncrypted || isRoomPublished : true,
            checked: history === 'shared',
        };

        const invited = {
            disabled: canChangeHistory ? isEncrypted || isRoomPublished : true,
            checked: history === 'invited',
        };

        const joined = {
            disabled: canChangeHistory ? isRoomPublished : true,
            checked: history === 'joined',
        };

        return (
            <div>
                <div className="history_text">
                    {_t('Changes to who can read history will only apply to future messages in this room. ' +
                        'The visibility of existing history will be unchanged.')}
                </div>
                <CitadelToggle
                    type="radio"
                    label={_t('Anyone')}
                    checked={worldReadable.checked}
                    disabled={worldReadable.disabled}
                    onChange={() => this._onHistoryRadioToggle('world_readable')}
                />
                <CitadelToggle
                    type="radio"
                    label={_t('Members only (since the point in time of selecting this option)')}
                    checked={shared.checked}
                    disabled={shared.disabled}
                    onChange={() => this._onHistoryRadioToggle('shared')}
                />
                <CitadelToggle
                    type="radio"
                    label={_t('Members only (since they were invited)')}
                    checked={invited.checked}
                    disabled={invited.disabled}
                    onChange={() => this._onHistoryRadioToggle('invited')}
                />
                <CitadelToggle
                    type="radio"
                    label={_t('Members only (since they joined)')}
                    checked={joined.checked}
                    disabled={joined.disabled}
                    onChange={() => this._onHistoryRadioToggle('joined')}
                />
            </div>
        );
    }

    render() {
        const dmRoomMap = DMRoomMap.shared();
        const isRoom = !dmRoomMap.getUserIdForRoomId(this.props.roomId);

        return (
            <div className="mx_SettingsTab mx_SecurityRoomSettingsTab">
                <div className="mx_SettingsTab_heading">{_t('Security & Privacy')}</div>

                <span className="mx_SettingsTab_subheading">{_t('Encryption')}</span>
                <div className="mx_SettingsTab_section mx_SecurityRoomSettingsTab_encryptionSection">
                    {this._renderEncryption()}
                </div>

                {isRoom && <span className="mx_SettingsTab_subheading">{_t('Room accessibility')}</span>}
                {isRoom &&
                    <div className="mx_SettingsTab_section mx_SettingsTab_subsectionText">
                        {this._renderRoomAccess()}
                    </div>
                }

                <span className="mx_SettingsTab_subheading">{_t('Who can read history?')}</span>
                <div className="mx_SettingsTab_section mx_SettingsTab_subsectionText">
                    {this._renderHistory()}
                </div>
            </div>
        );
    }
}
