import React from 'react';
import PropTypes from 'prop-types';
import CitadelToggle from '../elements/CitadelToggle';
import { _t } from '../../../languageHandler';
import * as RoomNotifs from '../../../RoomNotifs';

export const NotificationToggle = ({ notificationLevel, checked, disabled, onChange }) => {
    const notificationLabels = {
        [RoomNotifs.ALL_MESSAGES]: _t('All messages'),
        [RoomNotifs.MENTIONS_ONLY]: _t('Mentions only'),
        [RoomNotifs.MUTE]: _t('Mute'),
    };

    return (
        <div className={checked ? "selected-toggle" : ""}>
            <CitadelToggle
                type="radio"
                label={notificationLabels[notificationLevel]}
                checked={checked}
                disabled={disabled}
                onChange={onChange}
            />
        </div>
    );
};

NotificationToggle.propTypes = {
    notificationLevel: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

NotificationToggle.defaultProps = {
    disabled: false,
    onChange: () => {},
};
