'use strict';

import PropTypes from 'prop-types';
import React from 'react';

import sdk from '../../../index';
import dis from '../../../dispatcher';
import { _t, getCurrentLanguage } from '../../../languageHandler';
import Modal from '../../../Modal';

const typeDetails = {
    "terms-of-use": {
        src: 'terms-of-use',
    },
    "data-protection": {
        src: 'personal-data',
    },
};

export default class TermsOfUseDialog extends React.Component {
    static defaultProps = {
        displayOnly: false,
        type: 'terms-of-use',
    };

    onRefused = () => {
        const LogoutDialog = sdk.getComponent('dialogs.LogoutDialog');
        Modal.createTrackedDialog('Logout E2E Export', '', LogoutDialog, {
            onFinished: (confirmed, recoveryMethod) => {
                if (confirmed) {
                    if (recoveryMethod) dis.dispatch({ action: 'logout' });
                    this.props.onFinished(false);
                }
            },
        });
    };

    onAccepted = () => {
        this.props.onFinished(true);
    };

    render() {
        const BaseDialog = sdk.getComponent('views.dialogs.BaseDialog');
        const DialogButtons = sdk.getComponent('views.elements.DialogButtons');

        const {
            displayOnly,
            homeserverUrl,
            onFinished,
            type,
        } = this.props;
        const lang = getCurrentLanguage();
        const content = (
            <div className="mx_Dialog_content">
                <iframe
                    title={_t('Terms of service and the privacy notice')}
                    src={`${homeserverUrl}/_matrix/client/v1/citadel/consent/${typeDetails[type].src}?lang=${lang}`}
                />
                {!displayOnly &&
                <div>
                    {_t('By clicking on "I agree", I agree to the terms of service including the privacy notice.')}
                </div>
                }
            </div>
        );

        return (
            <BaseDialog
                title=""
                hasCancel={displayOnly}
                onFinished={displayOnly ? onFinished : () => {}}
                fixedWidth={false}
            >
                <div className="mx_TermsOfUse">
                    {content}
                </div>
                {displayOnly ?
                    <DialogButtons
                        primaryButton={_t('OK')}
                        onPrimaryButtonClick={this.onAccepted}
                        hasCancel={false}
                        onCancel={() => {}}
                    /> :
                    <DialogButtons
                        primaryButton={_t('I agree')}
                        cancelButton={_t('I decline')}
                        onPrimaryButtonClick={this.onAccepted}
                        onCancel={this.onRefused}
                    />
                }
            </BaseDialog>
        );
    }
}

TermsOfUseDialog.propTypes = {
    homeserverUrl: PropTypes.string,
    onFinished: PropTypes.func.isRequired,
    displayOnly: PropTypes.bool,
    type: PropTypes.string,
    portalUrl: PropTypes.string,
};
