import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import sdk from '../../../index';
import dis from '../../../dispatcher';
import { _t } from '../../../languageHandler';
import Modal from '../../../Modal';
import { reset } from '../../../utils/CitadelRequests';
import ChangePasswordSuccessDialog from '../../views/dialogs/ChangePasswordSuccessDialog';
import EmailValidationStep from './EmailValidationStep';
import TokenValidationStep from './TokenValidationStep';
import PasswordValidationStep from './PasswordValidationStep';

const PHASE_EMAIL = 0;
const PHASE_TOKEN = 1;
const PHASE_PASSWORD = 2;

class CitadelForgotPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errorText: '',
            sid: '',
            clientSecret: '',
            token: '',
            email: '',
            phase: PHASE_EMAIL,
        };
    }

    handleError = (err) => {
        console.error(err);
        this.setState({
            errorText: _t('An error has occurred.'),
        });
    };

    onEmailValidationSuccess = (email, homeserverUrl, clientSecret, sid) => {
        this.setState({
            email,
            homeserverUrl,
            clientSecret,
            sid,
            phase: PHASE_TOKEN,
        });
    };

    onTokenValidationSuccess = (clientSecret, sid) => {
        this.setState({
            clientSecret,
            sid,
            phase: PHASE_PASSWORD,
        });
    };

    onPasswordValidationSuccess = (password) => {
        const { homeserverUrl, clientSecret, sid, email } = this.state;

        reset(homeserverUrl, clientSecret, sid, password)
            .then(() => {
                Modal.createTrackedDialog('Email token has been sent', '', ChangePasswordSuccessDialog, {
                    onFinished: () => {
                        dis.dispatch({ action: 'start_login', params: { email } });
                    },
                }, 'mx_ChangePasswordSuccessDialog_wrapper');
            })
            .catch((err) => {
                // not supposed to happen since error cases are :
                // 401 : the homeserver requires additional authentification information
                // 429 : the request was rate-limited
                this.handleError(err);
            });
    };

    onBack = () => {
        switch (this.state.phase) {
            case PHASE_EMAIL:
                dis.dispatch({ action: 'start_login' });
                break;
            case PHASE_TOKEN:
                this.setState({
                    token: '',
                    phase: PHASE_EMAIL,
                });
                break;
            default:
                break;
        }
    };

    renderStepBar = () => {
        const { phase } = this.state;

        const emailStepClasses = classNames({
            email: true,
            highlight: (phase >= PHASE_EMAIL),
        });

        const tokenStepClasses = classNames({
            token: true,
            highlight: (phase >= PHASE_TOKEN),
        });

        const passwordStepClasses = classNames({
            password: true,
            highlight: (phase >= PHASE_PASSWORD),
        });

        return (
            <div className="steps">
                <div className={emailStepClasses} />
                <div className={tokenStepClasses} />
                <div className={passwordStepClasses} />
            </div>
        );
    };

    renderForms = () => {
        const { phase, email, homeserverUrl, sid, clientSecret } = this.state;
        const { defaultPortalUrl, devPortalUrl, initialEmail, defaultDeviceDisplayName } = this.props;

        switch (phase) {
            case PHASE_EMAIL:
                return <EmailValidationStep
                    onNext={this.onEmailValidationSuccess}
                    onBack={this.onBack}
                    defaultPortalUrl={defaultPortalUrl}
                    devPortalUrl={devPortalUrl}
                    initialEmail={initialEmail}
                />;
            case PHASE_TOKEN:
                return <TokenValidationStep
                    defaultDeviceDisplayName={defaultDeviceDisplayName}
                    onNext={this.onTokenValidationSuccess}
                    onBack={this.onBack}
                    type="resetpwd"
                    email={email}
                    homeserverUrl={homeserverUrl}
                    sid={sid}
                    clientSecret={clientSecret}
                />;
            case PHASE_PASSWORD:
                return <PasswordValidationStep
                    onNext={this.onPasswordValidationSuccess}
                />;
        }
    };

    renderError = () => {
        const { errorText } = this.state;

        return (
            <div className='common'>
                <div className='load-or-error'>
                    <div className='error'>
                        {errorText}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { errorText } = this.state;
        const AuthPage = sdk.getComponent('auth.AuthPage');

        return (
            <AuthPage>
                <div className="mx_CitadelRegistration">
                    {this.renderStepBar()}
                    {errorText && this.renderError()}
                    {this.renderForms()}
                </div>
            </AuthPage>
        );
    }
}

CitadelForgotPassword.propTypes = {
    defaultDeviceDisplayName: PropTypes.string.isRequired,
    defaultPortalUrl: PropTypes.string,
    devPortalUrl: PropTypes.string,
    initialEmail: PropTypes.string,
};

export default CitadelForgotPassword;
