import React, { useState } from 'react';
import { isEmpty } from "lodash";

import { _t } from "../../../../languageHandler";
import MfaManager from "./MfaManager";
import BaseDialog from "../BaseDialog";
import MfaPasswordDialogContent from "./MfaPasswordDialogContent";
import DialogButtons from "../../elements/DialogButtons";
import MatrixClientPeg from "../../../../MatrixClientPeg";

const MfaPasswordDialog = ({ isChangePhoneNumber, isDeactivation, onFinished, password: propPassword, type }) => {
    const [password, setPassword] = useState(propPassword || '');
    const [passwordError, setPasswordError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const onPasswordChange = (evt) => {
        setPasswordError(null);
        setPassword(evt.target.value);
    };

    const onSubmit = async () => {
        setIsLoading(true);
        try {
            let newMfaStatus = isDeactivation ? 'disable' : 'enable';
            if (isChangePhoneNumber) newMfaStatus = 'disable';
            const resp = await MfaManager.setMfaStatus(password, newMfaStatus, type, isChangePhoneNumber);
            if (resp && resp.success) {
                MatrixClientPeg.get().trackUserAction({
                    formId: 'statusMFA',
                    version: 1,
                    action: 'verity_pwd',
                    type: type === 'totp' ? 'authapp' : type,
                    step: newMfaStatus,
                });
                onFinished({ type, password });
            }
        } catch (e) {
            if (e && e.message === 'Invalid password') {
                setPasswordError(_t('Wrong password.'));
            }
            setIsLoading(false);
        }
    };

    const handleOnFinished = (isCancel) => () => {
        const step = isDeactivation ? 'disable' : 'enable';

        MatrixClientPeg.get().trackUserAction({
            formId: 'statusMFA',
            version: 1,
            action: 'cancel_pwd',
            type: type === 'totp' ? 'authapp' : type,
            step,
        });
        onFinished({ type, isCancel });
    };

    return (
        <BaseDialog
            className="mx_MfaPasswordDialog"
            fixedWidth={false}
            onFinished={handleOnFinished(true)}
            title={
                isDeactivation && !isChangePhoneNumber
                    ? _t('Are you sure you want to deactivate your two-step authentication ?')
                    : _t('Please verify your account')
            }
        >
            <MfaPasswordDialogContent
                error={passwordError}
                isDeactivation={isDeactivation && !isChangePhoneNumber}
                mfaType={type}
                onChange={onPasswordChange}
                value={password}
            />
            <DialogButtons
                isLoading={isLoading}
                onCancel={handleOnFinished(true)}
                onPrimaryButtonClick={onSubmit}
                primaryButton={_t('Verify')}
                primaryDisabled={isEmpty(password) || !!passwordError}
            />
        </BaseDialog>
    );
};

export default MfaPasswordDialog;
