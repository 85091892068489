import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { _t } from '../../../languageHandler';
import Modal from "../../../Modal";
import AuthBody from '../../views/auth/AuthBody';
import { requestToken, submitToken } from '../../../utils/CitadelRequests';
import { generateClientSecret, validateToken } from '../../../utils/CitadelUtils';
import CitadelCodeInput from '../../views/elements/CitadelCodeInput';
import EmailTokenSentDialog from '../../views/dialogs/EmailTokenSentDialog';
import Login from "../../../Login";

const DURATION = 59;

class TokenValidationStep extends Component {
    constructor(props) {
        super(props);

        this.state = {
            token: '',
            error: { type: '', text: '' },
            sid: this.props.sid,
            clientSecret: this.props.clientSecret,
            countDown: DURATION,
        };

        this.interval = setInterval(() => {
            if (this.state.countDown > 0) {
                this.setState((prevState) => ({
                    countDown: prevState.countDown - 1,
                }));
            }
        }, 1000);
    }

    async componentDidMount() {
        const { homeserverUrl, defaultDeviceDisplayName, email, type = 'register' } = this.props;

        if (type === 'register') return;

        const loginLogic = new Login(homeserverUrl, defaultDeviceDisplayName);
        let mfaTemporaryConfig = null;
        try {
            const mfaConfig = await loginLogic.getMfaConfiguration(email);
            if (mfaConfig.param === 'msisdn' ) {
                mfaTemporaryConfig = {
                    phoneNumber: `${mfaConfig.phoneCountryInfo.code}${mfaConfig.phoneNumber}`,
                    mfaStatus: mfaConfig.param,
                };
            } else {
                mfaTemporaryConfig = { mfaStatus: mfaConfig.param };
            }
        } catch (err) {
            console.log(err);
        }
        this.setState({ mfaConfig: mfaTemporaryConfig });
    }

    componentWillUnmount(): void {
        clearInterval(this.interval);
    }

    onCodeChange = (token) => {
        this.setState({ token, error: { type: '', text: '' } });
    };

    handleError = (err) => {
        let error = {
            type: 'system',
            text: _t('An error has occurred.'),
        };

        if (err.message) {
            console.error(err);
        } else {
            // submitToken always send us 200 OK even if an error occured ...
            // in case of error, res.error and errcode are filled when M_SESSION_EXPIRED
            if (!err.success && !err.error) {
                error = {
                    type: 'token',
                    text: _t('Incorrect verification code. A new code has been sent to your email address.'),
                };
                this.retryRequestToken(false);
            }

            this.setState({ error });
        }
    };

    requestNewToken = () => {
        this.setState({
            token: '',
            error: { type: '', text: '' },
        }, this.retryRequestToken);
    };

    retryRequestToken = (showNewTokenPopup = true) => {
        const { homeserverUrl, email, type } = this.props;

        const clientSecret = generateClientSecret();

        requestToken(homeserverUrl, clientSecret, email, 1, type || 'register')
            .then((res) => {
                if (res.success) {
                    this.setState({
                        clientSecret,
                        sid: res.sid,
                        countDown: DURATION,
                    });

                    if (showNewTokenPopup) {
                        Modal.createTrackedDialog('Email token has been sent', '', EmailTokenSentDialog, {
                            email,
                            onFinished: () => {},
                        }, 'mx_EmailTokenSentDialog_wrapper');
                    }
                } else {
                    this.handleError(res);
                }
            })
            .catch((err) => {
                this.handleError(err);
            });
    };

    onSubmit = (ev) => {
        ev.preventDefault();
        const { homeserverUrl } = this.props;
        const { token, clientSecret, sid } = this.state;

        submitToken(homeserverUrl, clientSecret, sid, token)
            .then((res) => {
                if (res.success) {
                    this.props.onNext(clientSecret, sid);
                } else {
                    this.handleError(res);
                }
            })
            .catch((err) => {
                this.handleError(err);
            });
    };

    renderError = () => {
        const { error } = this.state;

        if (!error.text) return null;

        if (error.type === 'system') {
            return error.text;
        }

        return [error.text, <span key="error-email" className="email"> {this.props.email}</span>];
    };

    render() {
        const { type = 'register' } = this.props;
        const { token, countDown, mfaConfig } = this.state;
        const validInputs = validateToken(token);
        let title = _t('Check your emails');
        let subtitle = _t('We just sent a 6 digit verification code to your email address ');
        let emailAddress = <span className="email">{this.props.email}</span>;

        if (type === 'resetpwd') {
            if (mfaConfig && mfaConfig.mfaStatus.includes('msisdn')) {
                title = _t('Check your emails or SMS');
                subtitle = _t(
                    'We just sent a verification code to your email %(email)s or by SMS.',
                    { email: emailAddress },
                );
            } else {
                subtitle = _t(
                    'We just sent a verification code to your email %(email)s.',
                    { email: emailAddress },
                );
            }
            emailAddress = null;
        }

        return (
            <div className="mx_TokenValidationStep">
                <span onClick={this.props.onBack} className="back-button" />
                <AuthBody>
                    <div className="token-validation wrapper">
                        <div className="content">
                            <div className="header">
                                <div className="title">
                                    {title}
                                </div>
                                <div className="subtitle">
                                    {subtitle}
                                    {emailAddress}
                                </div>
                            </div>
                            <form onSubmit={this.onSubmit}>
                                <CitadelCodeInput
                                    className="verification_code"
                                    type="tel"
                                    fields={6}
                                    onChange={this.onCodeChange}
                                    value={token}
                                    label={_t('Verification code')}
                                    error={this.renderError()}
                                />

                                <div className="button_wrapper">
                                    <div className="button">
                                        <input className="mx_Login_submit"
                                               type="submit"
                                               value={_t('Confirm')}
                                               disabled={!validInputs}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="footer">
                            <div className="no-token">
                                { countDown > 0 ?
                                    <div className="new-code">
                                        {`${_t('Send me a new verification code in %(delay)ss', { delay: countDown })} `}
                                    </div> :
                                    <a onClick={this.requestNewToken}>{_t('Send me a new verification code')}</a>
                                }
                            </div>
                        </div>
                    </div>
                </AuthBody>
            </div>
        );
    }
}

TokenValidationStep.propTypes = {
    defaultDeviceDisplayName: PropTypes.string.isRequired,
    onNext: PropTypes.func,
    onBack: PropTypes.func,
    email: PropTypes.string,
    type: PropTypes.string, // register or resetpwd
    homeserverUrl: PropTypes.string,
    sid: PropTypes.string,
    clientSecret: PropTypes.string,
};

export default TokenValidationStep;
