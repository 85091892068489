import React, { useMemo } from 'react';
import cx from 'classnames';

import { _t } from '../../../../../languageHandler';
import GenericToolTip from '../../../../structures/GenericToolTip';
import DatePicker from '../../DatePicker';
import Dropdown from '../../Dropdown';

import tooltip from '../../../../../../res/img/conference/about-icon.svg';

const TIME_MENU_WIDTH = 80;

const ConferenceDatePickers = (props) => {
    const {
        calendarDate,
        calendarEndTime,
        calendarStartTime,
        changeTimeOption,
        endTimeArray,
        handleDateChange,
        handleInputBlur,
        isEndTimeStringError,
        isStartTimeStringError,
        isTimePickerOptionDisabled,
        onChangeTimeInput,
        startTimeArray,
    } = props;

    const dropDownClassName = useMemo(() => cx("mx_ConferencePlanDialog timePickers",
        { isTimePickerOptionDisabled }), [isTimePickerOptionDisabled]);

    const gmtValue = useMemo(() => {
        let gmt = new Date().toString().split(' ').find(x => x.includes('GMT'));
        gmt = gmt.slice(0, 3) + ' ' + gmt.slice(3);
        gmt = gmt.slice(0, 7) + ':' + gmt.slice(7);
        return gmt;
    }, []);

    const renderTimeInput = (type) => () => {
        const inputClassName = cx('mx_ConfertarencePlanDialog timePickers mx_Dropdown_input',
            {
                'error': type === 'startTime' ?
                    isStartTimeStringError :
                    isEndTimeStringError,
            });
        return (
            <input
                className={inputClassName}
                maxLength={5}
                onBlur={handleInputBlur(type)}
                onChange={onChangeTimeInput(type)}
                type={"text"}
                value={type === 'startTime' ? calendarStartTime : calendarEndTime}
            />
        );
    };

    const getTimePickerOptions = (timeArray) => timeArray.map(time =>
        <div key={time} className='mx_ConferencePlanDialog_timePicker'>
            {time}
        </div>);

     return (
        <div className="mx_ConferencePlanDialog_row date">
        <DatePicker date={calendarDate} onChangeDate={handleDateChange} />
        <div className="text-container" >{` ${_t('from')} `}</div>
        <Dropdown
            className={dropDownClassName}
            hasArrow={false}
            isDefaultInputClassNameDisabled
            isLabelActive={isStartTimeStringError}
            labelClassName={"mx_ConferencePlanDialog_calendarTimeErrorLabel"}
            labelText={_t("Invalid time (HH:MM)")}
            menuWidth={TIME_MENU_WIDTH}
            onOptionChange={changeTimeOption('startTime')}
            renderInput={renderTimeInput('startTime')}
            useValue={true}
            value={calendarStartTime}
        >
            {getTimePickerOptions(startTimeArray)}
        </Dropdown>
        <div className="text-container" >{` ${_t('to')} `}</div>
        <Dropdown
            className={dropDownClassName}
            hasArrow={false}
            isDefaultInputClassNameDisabled
            isLabelActive={isEndTimeStringError}
            labelClassName={"mx_ConferencePlanDialog_calendarTimeErrorLabel"}
            labelText={_t("Invalid time (HH:MM)")}
            menuWidth={TIME_MENU_WIDTH}
            onOptionChange={changeTimeOption('endTime')}
            renderInput={renderTimeInput('endTime')}
            useValue={true}
            value={calendarEndTime}
        >
            {getTimePickerOptions(endTimeArray)}
        </Dropdown>
        <div className="text-container gmt" >{gmtValue}</div>
        <GenericToolTip label={_t('The event time is based on your time zone') + ` ${gmtValue}`}
            labelStyle={"mx_ConferencePlanDialog_plan_conference"}>
            <img src={tooltip} />
        </GenericToolTip>
    </div>
     );
};

export default ConferenceDatePickers;
