/*
Copyright 2017 Vector Creations Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React from 'react';
import PropTypes from 'prop-types';
import * as TextEncodingUtf8 from 'text-encoding-utf-8';
import { _t } from '../../../languageHandler';

import * as Matrix from 'matrix-js-sdk';
import * as MegolmExportEncryption from '../../../utils/MegolmExportEncryption';
import sdk from '../../../index';

import { validatePassword } from '../../../utils/CitadelUtils';
import { encodeDataToImage } from '../../../utils/E2eKeysUtils';
import CitadelInput from '../../../components/views/elements/CitadelInput';
import PasswordValidation from '../../../components/views/auth/PasswordValidation';

const PHASE_EDIT = 1;
const PHASE_EXPORTING = 2;
const MAX_INPUT_LENGTH = 27;
export default class extends React.Component {
    static displayName = 'ExportE2eKeysDialog';

    static propTypes = {
        isLogout: PropTypes.bool,
        matrixClient: PropTypes.instanceOf(Matrix.MatrixClient).isRequired,
        onExport: PropTypes.func,
        onFinished: PropTypes.func.isRequired,
        roomId: PropTypes.string,
        type: PropTypes.string,
        onExportError: PropTypes.func,
    };

    state = {
        phase: PHASE_EDIT,
        errorText: null,
        password: '',
        passwordConfirm: '',
    };

    componentDidMount() {
        this._unmounted = false;
    }

    componentWillUnmount() {
        this._unmounted = true;
    }

    onInputChange = (ev) => {
        const { name, value } = ev.target;

        this.setState({
            [name]: value,
            errorText: null,
        });
    };

    onInputBlur = () => {
        const { password, passwordConfirm } = this.state;

        if (password) {
            if (!validatePassword(password).valid) {
                this.setState({
                    errorText: _t('Invalid password'),
                });
            } else if (passwordConfirm && password !== passwordConfirm) {
                this.setState({
                    errorText: _t("Passwords don't match."),
                });
            }
        }
    };

    _onPassphraseFormSubmit = (ev) => {
        ev.preventDefault();
        const { password, hint } = this.state;

        this._startExport(password, hint);
        return false;
    };

    _startExport = (password, hint) => {
        const { roomId, onExport, onFinished, onExportError } = this.props;
        const newPassword = roomId ? password : `${password}${this.props.matrixClient.getUserId()}`;
        // extra Promise.resolve() to turn synchronous exceptions into
        // asynchronous ones.
        Promise.resolve().then(() => {
            return this.props.matrixClient.exportRoomKeys(roomId);
        }).then((k) => {
            return MegolmExportEncryption.encryptMegolmKeyFile(
                JSON.stringify(k), newPassword,
            );
        }).then((f) => {
            let TextDecoder = window.TextDecoder;
            if (!TextDecoder) {
                TextDecoder = TextEncodingUtf8.TextDecoder;
            }
            const decodedText = new TextDecoder().decode(new Uint8Array(f));
            encodeDataToImage(decodedText, hint, !!roomId);
            onExport && onExport(true);
            onFinished(true);
        }).catch((e) => {
            console.error("Error exporting e2e keys:", e);
            if (this._unmounted) {
                return;
            }
            const msg = e.friendlyText || _t('Unknown error');
            this.setState({
                phase: PHASE_EDIT,
                errorText: msg,
            });
            onExportError && onExportError(e);
        });

        this.setState({
            phase: PHASE_EXPORTING,
            errorText: null,
        });
    }

    _onCancelClick = (ev) => {
        ev.preventDefault();
        this.props.onFinished(false, true);
        return false;
    };

    renderInfo = () => {
        const { roomId, type: typeProps, isLogout } = this.props;
        const type = typeProps === 'room' || typeProps === 'chat' ? 'room' : '';
        if (roomId) {
            _t('This backup file will allow you to read this end-to-end encrypted room history.');
        }
        let info1 = '';
        let info2 = '';

        switch (type) {
            case 'room':
                info1 = _t(`This action will backup your ${typeProps} encryption key protecting it specifically. ` +
                    'For more confidentiality, Citadel will secure this backup file thanks to the password you ' +
                    'are about to choose.',
                );
                info2 = _t(`This backup file will allow you to read this end-to-end encrypted ${typeProps} history.`);
                break;
            default:
                info1 = _t('This action will backup all encryption keys that protect your conversations in a ' +
                    'file that will be turned into an image saved to a location of your choice. For more ' +
                    'confidentiality, Citadel will secure this backup file thanks to the password and an optional ' +
                    'hint you are about to choose.',
                );
                info2 = _t(
                    'If you ever need to re-initialize the password of your Citadel account, ' +
                    'this backup file will allow you to read all the history of your end-to-end ' +
                    'encrypted conversations.',
                );
        }

        return (
            <React.Fragment>
                <p>{info1}</p>
                <p>{info2}</p>
                {isLogout ? (
                    <p>
                        {_t('<span>Warning</span> : if you lose this backup or forget the password choosen below, ' +
                            'your conversations will no longer be accessible.',
                            {},
                            { 'span': (sub) => <span className="underline">{sub}</span> },
                        )}
                    </p>
                ) : null}
            </React.Fragment>
        );
    }

    render() {
        const BaseDialog = sdk.getComponent('views.dialogs.BaseDialog');
        const { phase, password, passwordConfirm, hint } = this.state;

        const exporting = (phase === PHASE_EXPORTING);
        const validPassword = (validatePassword(password).valid && (password === passwordConfirm));

        return (
            <BaseDialog className='mx_ExportE2eKeysDialog'
                onFinished={this.props.onFinished}
                title={_t('Export encryption keys')}
            >
                <form onSubmit={this._onPassphraseFormSubmit} autoComplete="off">
                    <div className="mx_Dialog_content">
                        {this.renderInfo()}
                        <div className='error errorText'>
                            {this.state.errorText}
                        </div>
                        <div>
                            <div>
                                <CitadelInput
                                    label={_t('Please enter a password to secure your encryption keys')}
                                    name='password'
                                    type='password'
                                    onChange={this.onInputChange}
                                    onBlur={this.onInputBlur}
                                    value={password}
                                    autoFocus={true}
                                    autoComplete='false'
                                />
                            </div>
                            <div>
                                <CitadelInput
                                    label={_t('Please confirm your password')}
                                    name='passwordConfirm'
                                    type='password'
                                    onChange={this.onInputChange}
                                    onBlur={this.onInputBlur}
                                    value={passwordConfirm}
                                    autoComplete='false'
                                />
                            </div>
                            <PasswordValidation
                                passwordPolicy={validatePassword(password)}
                            />
                            <div>
                                <CitadelInput
                                    label={_t('We recommend using a hint for your password')}
                                    name='hint'
                                    type='text'
                                    onChange={this.onInputChange}
                                    onBlur={this.onInputBlur}
                                    value={hint}
                                    autoComplete='false'
                                    maxInputLength={MAX_INPUT_LENGTH}
                                    showNumberOfChar={true}
                                    placeholder={_t('Optional hint of 27 characters max')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mx_Dialog_buttons">
                        <input
                            className="mx_Dialog_primary"
                            type="submit"
                            value={_t('Export encryption keys')}
                            disabled={!validPassword || exporting}
                        />
                        <button onClick={this._onCancelClick}>
                            {_t('Cancel')}
                        </button>
                    </div>
                </form>
            </BaseDialog>
        );
    }
}
