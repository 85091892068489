import SettingController from "./SettingController";
import dis from "../../dispatcher";

class AccountGlobalSettingsController extends SettingController {
    getValueOverride(level, roomId, calculatedValue, calculatedAtLevel) {
        return null; // no override
    }

    onChange(level, roomId, newValue, settingName ) {
        // Dispatch setting change so that some components that are still visible when the
        if (!!settingName && ["enabled_public_read_only_rooms", "enabled_private_rooms"].includes(settingName)) {
            dis.dispatch({
                action: "setting_updated",
                settingName,
                value: newValue,
            });
        }
    }
}

if (global.AccountGlobalSettingsController === undefined) {
    global.AccountGlobalSettingsController = new AccountGlobalSettingsController();
}

export default global.AccountGlobalSettingsController;
