
const m = {
    text: {
        content: {
            body: 'string',
            msgtype: 'string',
            format: 'org.matrix.custom.html',
            formatted_body: 'string',
            required: ['body', 'msgtype'],
        },
        event_id: 'string',
        origin_server_ts: 'number',
        room_id: 'string',
        sender: 'string',
        type: 'm.room.message',
        unsigned: {
            age: 'number',
        },
    },
    emote: {
        content: {
            body: 'string',
            format: 'org.matrix.custom.html',
            formatted_body: 'string',
            msgtype: 'm.emote',
            required: ['body', 'msgtype'],
        },
        event_id: 'string',
        origin_server_ts: 'number',
        room_id: 'string',
        sender: 'string',
        type: 'm.room.message',
        unsigned: {
            age: 'number',
        },
    },
    notice: {
        content: {
            body: 'string',
            format: 'org.matrix.custom.html',
            formatted_body: 'string',
            msgtype: 'm.notice',
            required: ['body', 'msgtype'],
        },
        event_id: 'string',
        origin_server_ts: 'number',
        room_id: 'string',
        sender: 'string',
        type: 'm.room.message',
        unsigned: {
            age: 'number',
        },
    },
    image: {
        content: {
            body: 'string',
            info: {
                h: 'number',
                mimetype: 'string',
                size: 'number',
                w: 'number',
                thumbnail_info: {
                    h: 'number',
                    w: 'number',
                    mimetype: 'string',
                    size: 'number',
                },
            },
            msgtype: 'm.image',
            url: 'string',
            required: ['body', 'msgtype'],
        },
        event_id: 'string',
        origin_server_ts: 'number',
        room_id: 'string',
        sender: 'string',
        type: 'm.room.message',
        unsigned: {
            age: 'number',
        },
    },
    file: {
        content: {
            body: 'string',
            filename: 'string',
            info: {
                mimetype: 'string',
                size: 'number',
                thumbnail_info: {
                    h: 'number',
                    w: 'number',
                    mimetype: 'string',
                    size: 'number',
                },
            },
            msgtype: 'm.file',
            url: 'string',
            required: ['body', 'msgtype'],
        },
        event_id: 'string',
        origin_server_ts: 'number',
        room_id: 'string',
        sender: 'string',
        type: 'm.room.message',
        unsigned: {
            age: 'number',
        },
    },
    audio: {
        content: {
            body: 'string',
            info: {
                duration: 'number',
                mimetype: 'string',
                size: 'number',
            },
            msgtype: 'm.audio',
            url: 'string',
            required: ['body', 'msgtype'],
        },
        event_id: 'string',
        origin_server_ts: 'number',
        room_id: 'string',
        sender: 'string',
        type: 'm.room.message',
        unsigned: {
            age: 'number',
        },
    },
    location: {
        content: {
            body: 'string',
            geo_uri: 'string',
            info: {
                thumbnail_info: {
                    h: 'number',
                    mimetype: 'string',
                    size: 'number',
                    w: 'number',
                },
                thumbnail_url: 'string',
            },
            msgtype: 'm.location',
            required: ['body', 'msgtype'],
        },
        event_id: 'string',
        origin_server_ts: 'number',
        room_id: 'string',
        sender: 'string',
        type: 'm.room.message',
        unsigned: {
            age: 'number',
        },
    },
    video: {
        content: {
            body: 'string',
            info: {
                duration: 'number',
                h: 'number',
                mimetype: 'string',
                size: 'number',
                thumbnail_info: {
                    h: 'number',
                    mimetype: 'string',
                    size: 'number',
                    w: 'number',
                },
                thumbnail_url: 'string',
                w: 'number',
            },
            msgtype: 'm.video',
            url: 'string',
            required: ['body', 'msgtype'],
        },
        event_id: 'string',
        origin_server_ts: 'number',
        room_id: 'string',
        sender: 'string',
        type: 'm.room.message',
        unsigned: {
            age: 'number',
        },
    },
    news: {
        content: {
            body: 'string',
            msgtype: 'm.news',
            newsbody: 'string',
            newsimg: 'string',
            newstitle: 'string',
        },
        event_id: 'string',
        origin_server_ts: 'number',
        room_id: 'string',
        sender: 'string',
        type: 'm.room.message',
        unsigned: {
            age: 'number',
            transaction_id: 'string',
        },
    },
};

const runCheckFunction = (event, type) => {
    let integrity = true;
    for (const key in event) {
        if (
            !!event[key] &&
            typeof event[key] !== 'object' &&
            typeof event[key] !== type[key] &&
            event[key] !== type[key] &&
            typeof type[key] !== 'undefined' ||
            (
                (
                    event[key] === null ||
                    typeof event[key] === 'undefined'
                ) &&
                (type['required'] && (type['required'].indexOf(event[key]) >= 0))
            )
        ) {
            integrity = false;
            break;
        } else if (typeof event[key] === 'object' && typeof type[key] !== 'undefined' && integrity) {
            integrity = runCheckFunction(event[key], type[key]);
        }
    }
    return integrity;
};

export const checkMessageIntegrity = (mxEvent) => {
    let event;
    if (mxEvent.event.type === 'm.room.encrypted' && Object.keys(mxEvent._clearEvent).length !== 0) {
        event = mxEvent._clearEvent;
    } else {
        event = mxEvent.event;
    }
    const msgType = event && event.content && event.content.msgtype;
    if (!msgType) return true;
    const type = m[msgType.split('.')[1]];
    if (!type) return true;
    const checkResult = runCheckFunction(event, type);
    return checkResult;
};
