import Exporter from "./Exporter";
import { _t } from "../../languageHandler";
import { textForEvent } from "../../TextForEvent";
import SettingsStore from "../../settings/SettingsStore";
import { formatFullDate } from "../../DateUtils";
import EventTile from "../../components/views/rooms/EventTile";
import RoomExportStore from "../../stores/RoomExportStore";

export default class PlainTextExporter extends Exporter {
    constructor(room, exportType, exportOptions, setProgressText) {
        super(room, exportType, exportOptions, setProgressText);
        this.room = room;
        this.exportType = exportType;
        this.exportOptions = exportOptions;
        this.setProgressText = setProgressText;
        this.totalSize = 0;
        this.mediaOmitText = exportOptions.attachmentsIncluded
            ? _t("export_chat|media_omitted")
            : _t("export_chat|media_omitted_file_size");
    }

    destinationFileName() {
        return this.makeFileNameNoExtension() + ".txt";
    }

    textForReplyEvent = content => {
        const REPLY_REGEX = /> <(.*?)>(.*?)\n\n(.*)/s;
        const REPLY_SOURCE_MAX_LENGTH = 32;

        const match = REPLY_REGEX.exec(content.body);

        // if the reply format is invalid, then return the body
        if (!match) return content.body;

        let rplSource;
        const rplName = match[1];
        const rplText = match[3];

        rplSource = match[2].substring(1);
        // Get the first non-blank line from the source.
        const lines = rplSource.split("\n").filter(line => !/^\s*$/.test(line));
        if (lines.length > 0) {
            // Cut to a maximum length.
            rplSource = lines[0].substring(0, REPLY_SOURCE_MAX_LENGTH);
            // Ellipsis if needed.
            if (lines[0].length > REPLY_SOURCE_MAX_LENGTH) {
                rplSource = rplSource + "...";
            }
            // Wrap in formatting
            rplSource = ` "${rplSource}"`;
        } else {
            // Don't show a source because we couldn't format one.
            rplSource = "";
        }

        return `<${rplName}${rplSource}> ${rplText}`;
    }

    plainTextForEvent = async mxEv => {
        const senderDisplayName =
            mxEv.sender && mxEv.sender.name ? mxEv.sender.name : mxEv.getSender();
        let mediaText = "";
        if (this.isAttachment(mxEv)) {
            if (this.exportOptions.attachmentsIncluded) {
                try {
                    const blob = await this.getMediaBlob(mxEv);
                    if (this.totalSize + blob.size > this.exportOptions.maxSize) {
                        mediaText = ` (${this.mediaOmitText})`;
                    } else {
                        this.totalSize += blob.size;
                        const filePath = this.getFilePath(mxEv);
                        mediaText = " (" + _t("export_chat|file_attached") + ")";
                        this.addFile(filePath, blob);
                        if (this.totalSize === this.exportOptions.maxSize) {
                            this.exportOptions.attachmentsIncluded = false;
                        }
                    }
                } catch (error) {
                    mediaText = " (" + _t("export_chat|error_fetching_file") + ")";
                    RoomExportStore.logger.log("Error fetching file " + error);
                }
            } else mediaText = ` (${this.mediaOmitText})`;
        }
        if (this.isReply(mxEv)) {
            return (
                senderDisplayName +
                ": " +
                this.textForReplyEvent(mxEv.getContent()) +
                mediaText
            );
        } else {
            return textForEvent(mxEv, this.room.client) + mediaText;
        }
    }

    async createOutput(events) {
        let content = "";
        if (events.length) {
            for (let i = 0; i < events.length; i++) {
                const event = events[i];
                this.updateProgress({
                        title: _t('export_chat|started_part_2'),
                        description: _t("export_chat|processing_event_n", {
                            number: i + 1,
                            total: events.length,
                        }),
                    },
                    false,
                    true,
                );
                if (this.cancelled) return this.cleanUp();
                if (!EventTile.haveTileForEvent(event, this.room.client, false)) continue;

                const textForEvent = await this.plainTextForEvent(event);
                content +=
                    textForEvent &&
                    `${formatFullDate(
                        new Date(event.getTs()),
                        SettingsStore.getValue("showTwelveHourTimestamps"),
                    )} - ${textForEvent}\n`;
            }
        } else {
            content = _t("export_chat|no_messages_for_period");
            if (this.cancelled) return this.cleanUp();
        }
        return content;
    }

    async export() {
        this.updateProgress({
            title: _t('export_chat|started_part_1'),
            description: _t("export_chat|starting_export"),
        });
        this.updateProgress({
            title: _t('export_chat|started_part_1'),
            description: _t("export_chat|fetching_events"),
        });

        const fetchStart = performance.now();
        const res = await this.getRequiredEvents();
        const fetchEnd = performance.now();

        RoomExportStore.logger.log(
            `Fetched ${res.length} events in ${(fetchEnd - fetchStart) / 1000}s`,
        );

        this.updateProgress({
            title: _t('export_chat|started_part_2'),
            description: _t("export_chat|creating_output"),
        });
        const text = await this.createOutput(res);

        if (this.files && this.files.length) {
            this.addFile("export.txt", new Blob([text]));
            await this.downloadZIP();
        } else {
            const fileName = this.destinationFileName();
            this.downloadPlainText(fileName, text);
        }

        const exportEnd = performance.now();

        if (this.cancelled) {
            RoomExportStore.logger.info("Export cancelled successfully");
        } else {
            RoomExportStore.logger.info("Export successful!");
            RoomExportStore.logger.log(
                `Exported ${res.length} events in ${(exportEnd - fetchStart) /
                1000} seconds`,
            );
            this.updateProgress({
                title: _t("export_chat|export_successful"),
                description: '',
            });
        }

        this.cleanUp();
    }
}
