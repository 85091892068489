/*
 Copyright 2016 Aviral Dasgupta
 Copyright 2017 New Vector Ltd

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {emojifyText, containsEmoji} from '../../../HtmlUtils';

export default function EmojiText(props) {
    const {element, children, addAlt, ...restProps} = props;

    // fast path: simple regex to detect strings that don't contain
    // emoji and just return them
    if (containsEmoji(children)) {
        restProps.dangerouslySetInnerHTML = emojifyText(children, addAlt);
        return React.createElement(element, restProps);
    } else {
        return React.createElement(element, restProps, children);
    }
}

EmojiText.propTypes = {
    element: PropTypes.string,
};

EmojiText.defaultProps = {
    element: 'span',
    addAlt: true,
};
