/*
Copyright 2018, 2019 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React from 'react';
import PropTypes from 'prop-types';
import dis from '../../../dispatcher';

import { _t } from '../../../languageHandler';
import LogoutDialog from '../dialogs/LogoutDialog';
import Modal from '../../../Modal';
import SdkConfig from '../../../SdkConfig';
import NewsStore from '../../../stores/NewsStore';

import redDotSvg from '../../../../res/img/red-dot.svg';

export class TopLeftMenu extends React.Component {
    static propTypes = {
        displayName: PropTypes.string.isRequired,
        email: PropTypes.string,
        isHPS: PropTypes.bool.isRequired,
        onFinished: PropTypes.func,
        redMarker: PropTypes.object,
    };

    hasHomePage = () => {
        const config = SdkConfig.get();
        const pagesConfig = config.embeddedPages;
        if (pagesConfig && pagesConfig.homeUrl) {
            return true;
        }
        // This is a deprecated config option for the home page
        // (despite the name, given we also now have a welcome
        // page, which is not the same).
        return !!config.welcomePageUrl;
    }

    viewHomePage = () => {
        dis.dispatch({action: 'view_home_page'});
        this.closeMenu();
    }

    openSettings = (e, initialTabIndex = 0) => {
        dis.dispatch({
            action: 'view_user_settings',
            redMarker: this.props.redMarker,
            initialTabIndex,
        });
        this.closeMenu();
    }

    openLegalInfo = () => this.openSettings(null, 5);

    openNewFeatures = () => {
        dis.dispatch({ action: 'open_new_features' });
        this.closeMenu();
    }

    signIn = () => {
        dis.dispatch({action: 'start_login'});
        this.closeMenu();
    }

    signOut = () => {
        Modal.createTrackedDialog('Logout E2E Export', '', LogoutDialog, {}, 'LogoutDialog');
        this.closeMenu();
    }

    closeMenu = () => {
        if (this.props.onFinished) this.props.onFinished();
    }

    render() {
        const { userId, displayName, email, redMarker, isHPS } = this.props;

        const emailOrUserId = email || userId;
        const profileInfoSection = <div className="mx_TopLeftMenu_section_noIcon">
            <div>{displayName}</div>
            { emailOrUserId && <div className="mx_TopLeftMenu_greyedText">{emailOrUserId}</div> }
        </div>;

        let homePageSection;
        if (this.hasHomePage()) {
            homePageSection = <ul className="mx_TopLeftMenu_section_withIcon">
                <li className="mx_TopLeftMenu_icon_home" onClick={this.viewHomePage}>{_t("Home")}</li>
            </ul>;
        }

        const settingsSection = <ul className="mx_TopLeftMenu_section_withIcon">
            <li className="mx_TopLeftMenu_icon_settings" onClick={this.openSettings}>{_t("Settings")}</li>
            {Object.keys(redMarker).length > 0 && <img src={redDotSvg} alt="" />}
        </ul>;

        const legalInfoSection = <ul className="mx_TopLeftMenu_section_withIcon">
            <li className="mx_TopLeftMenu_icon_legal_info" onClick={this.openLegalInfo}>{_t("Legal information")}</li>
        </ul>;

        const newFeatures = <ul className="mx_TopLeftMenu_section_withIcon">
            <li className="mx_TopLeftMenu_icon_new_features" onClick={this.openNewFeatures}>{_t("What's new?")}</li>
        </ul>;

        const signInOutSection = <ul className="mx_TopLeftMenu_section_withIcon">
            <li className="mx_TopLeftMenu_icon_signout" onClick={() => NewsStore.showLeaveDialogIfNeeded(this.signOut)}>{_t("Sign out")}</li>
        </ul>;

        const hpsVersion = isHPS
            ? <div className="hps_branding_version">Version : Secure Collaboration Hub</div>
            : null;

        return <div className="mx_TopLeftMenu">
            {profileInfoSection}
            {homePageSection}
            {settingsSection}
            {legalInfoSection}
            {newFeatures}
            {signInOutSection}
            {hpsVersion}
        </div>;
    }
}
