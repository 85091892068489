import React from 'react';
import PropTypes from 'prop-types';
import { _t } from '../../../languageHandler';
import { CancelButton } from './SimpleRoomHeader';
import { showMembersList, hideMembersList } from '../../../Rooms';

const CitadelExternalBar = ({ isDmRoom, displayName, onCloseClick, collapsedRhs }) => {
    const linkAction = collapsedRhs ? showMembersList : hideMembersList;
    const linkText = collapsedRhs ? _t('Show member list') : _t('Hide member list');

    return (
        <div className="mx_CitadelExternalBar">
            { isDmRoom && displayName ?
                <div className="dm-text">
                    <div className="name">{displayName}</div>
                    <span>{_t('is external to your organization.')}</span>
                </div> :
                <div className="room-text">
                    {_t('Some members of this room are external to your organization.')}
                    <div className="link" onClick={linkAction}>{linkText}</div>
                </div>
            }
            <CancelButton onClick={onCloseClick} />
        </div>
    );
};

CitadelExternalBar.propTypes = {
    isDMRoom: PropTypes.bool,
    displayName: PropTypes.string,
};

CitadelExternalBar.defaultProps = {
    isDMRoom: false,
    displayName: '',
};

export default CitadelExternalBar;
