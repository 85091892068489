/*
Copyright 2019 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React from 'react';
import PropTypes from 'prop-types';
import Promise from 'bluebird';

import sdk from '../../../../..';
import MatrixClientPeg from '../../../../../MatrixClientPeg';
import UserStore from "../../../../../stores/UserStore";
import { _t } from '../../../../../languageHandler';
import AccessibleButton from '../../../elements/AccessibleButton';
import Modal from '../../../../../Modal';
import MfaSection from '../../MfaSection';
import { Spinner } from '../../../../structures/animations/Spinner';

export class IgnoredUser extends React.Component {
    static propTypes = {
        userId: PropTypes.string.isRequired,
        onUnignored: PropTypes.func.isRequired,
    };

    _onUnignoreClicked = (e) => {
        this.props.onUnignored(this.props.userId);
    };

    render() {
        const user = UserStore.getUserById(this.props.userId);
        const displayName = user ? user.displayName : this.props.userId;

        return (
            <div className='mx_SecurityUserSettingsTab_ignoredUser'>
                <span>{displayName}</span>
                <a onClick={this._onUnignoreClicked}>
                    {_t('Unignore')}
                </a>
            </div>
        );
    }
}

export default class SecurityUserSettingsTab extends React.Component {
    constructor() {
        super();

        // Get number of rooms we're invited to
        const invitedRooms = this._getInvitedRooms();

        this.state = {
            ignoredUserIds: MatrixClientPeg.get().getIgnoredUsers(),
            managingInvites: false,
            invitedRoomAmt: invitedRooms.length,
        };
    }

    _onExporteE2eKeysFinished = (exported, cancelClicked) => {
        if (exported) {
            MatrixClientPeg.get().trackUserAction({
                formId: 'exportE2EKeys',
                version: 1,
                action: 'confirm',
            });
        }
        if (!exported && !cancelClicked) {
            MatrixClientPeg.get().trackUserAction({
                formId: 'exportE2EKeys',
                version: 1,
                action: 'cancel1',
            });
        } else if (!exported && cancelClicked) {
            MatrixClientPeg.get().trackUserAction({
                formId: 'exportE2EKeys',
                version: 1,
                action: 'cancel2',
            });
        }
    }

    _onExportError = (error) => {
        MatrixClientPeg.get().trackUserAction({
            formId: 'exportE2EKeys',
            version: 1,
            action: 'error',
            step: 'export',
            code: error.httpStatus || 0,
            reason: error.friendlyText,
        });
    }

    _onExportE2eKeysClicked = () => {
        MatrixClientPeg.get().trackUserAction({
            formId: 'exportE2EKeys',
            version: 1,
            action: 'request',
        });
        Modal.createTrackedDialogAsync('Export E2E Keys', '',
            import('../../../../../async-components/views/dialogs/ExportE2eKeysDialog'),
            {
                matrixClient: MatrixClientPeg.get(),
                onFinished: this._onExporteE2eKeysFinished,
                onExportError: this._onExportError,
            },
        );
    };

    _importE2eKeysFinished = (imported, cancelClicked) => {
        if (imported) {
            MatrixClientPeg.get().trackUserAction({
                formId: 'importE2EKeys',
                version: 1,
                action: 'confirm1',
            });
        }
        if (!imported && cancelClicked) {
            MatrixClientPeg.get().trackUserAction({
                formId: 'importE2EKeys',
                version: 1,
                action: 'cancel2',
            });
        } else if (!imported && !cancelClicked) {
            MatrixClientPeg.get().trackUserAction({
                formId: 'importE2EKeys',
                version: 1,
                action: 'cancel1',
            });
        }
    }

    _onImportError = (error) => {
        MatrixClientPeg.get().trackUserAction({
            formId: 'importE2EKeys',
            version: 1,
            action: 'error',
            step: 'import',
            code: error.httpStatus || 0,
            reason: error.friendlyText,
        });
    }

    _onImportE2eKeysClicked = () => {
        MatrixClientPeg.get().trackUserAction({
            formId: 'importE2EKeys',
            version: 1,
            action: 'import',
        });
        Modal.createTrackedDialogAsync('Import E2E Keys', '',
            import('../../../../../async-components/views/dialogs/ImportE2eKeysDialog'),
            {
                matrixClient: MatrixClientPeg.get(),
                onFinished: this._importE2eKeysFinished,
                onImportError: this._onImportError,
            },
            'ImportE2eKeysDialog',
        );
    };

    _onUserUnignored = async (userId) => {
        // Don't use this.state to get the ignored user list as it might be
        // ever so slightly outdated. Instead, prefer to get a fresh list and
        // update that.
        const ignoredUsers = MatrixClientPeg.get().getIgnoredUsers();
        const index = ignoredUsers.indexOf(userId);
        if (index !== -1) {
            ignoredUsers.splice(index, 1);
            MatrixClientPeg.get().setIgnoredUsers(ignoredUsers);
        }
        this.setState({ignoredUserIds: ignoredUsers});
    };

    _getInvitedRooms = () => {
        return MatrixClientPeg.get().getRooms().filter((r) => {
            return r.hasMembershipState(MatrixClientPeg.get().getUserId(), "invite");
        });
    };

    _manageInvites = async (accept) => {
        this.setState({
            managingInvites: true,
        });

        // Compile array of invitation room ids
        const invitedRoomIds = this._getInvitedRooms().map((room) => {
            return room.roomId;
        });

        // Execute all acceptances/rejections sequentially
        const self = this;
        const cli = MatrixClientPeg.get();
        const action = accept ? cli.joinRoom.bind(cli) : cli.leave.bind(cli);
        for (let i = 0; i < invitedRoomIds.length; i++) {
            const roomId = invitedRoomIds[i];

            // Accept/reject invite
            await action(roomId).then(() => {
                // No error, update invited rooms button
                this.setState({invitedRoomAmt: self.state.invitedRoomAmt - 1});
            }, async (e) => {
                // Action failure
                if (e.errcode === "M_LIMIT_EXCEEDED") {
                    // Add a delay between each invite change in order to avoid rate
                    // limiting by the server.
                    await Promise.delay(e.retry_after_ms || 2500);

                    // Redo last action
                    i--;
                } else {
                    // Print out error with joining/leaving room
                    console.warn(e);
                }
            });
        }

        this.setState({
            managingInvites: false,
        });
    };

    _onAcceptAllInvitesClicked = (ev) => {
        this._manageInvites(true);
    };

    _onRejectAllInvitesClicked = (ev) => {
        this._manageInvites(false);
    };

    _renderCurrentDeviceInfo() {
        if (MatrixClientPeg.get().isCryptoEnabled()) {
            return (
                <div className='mx_SettingsTab_section'>
                    <span className='mx_SettingsTab_subheading'>{_t("Cryptography")}</span>
                    <div className='mx_SecurityUserSettingsTab_importExportButtons'>
                        <AccessibleButton kind='primary' onClick={this._onExportE2eKeysClicked}>
                            {_t('Export encryption keys')}
                        </AccessibleButton>
                        <AccessibleButton kind='primary' onClick={this._onImportE2eKeysClicked}>
                            {_t('Import encryption keys')}
                        </AccessibleButton>
                    </div>
                </div>
            );
        }

        return null;
    }

    _renderIgnoredUsers() {
        if (!this.state.ignoredUserIds || this.state.ignoredUserIds.length === 0) return null;

        const userIds = this.state.ignoredUserIds
            .map((u) => <IgnoredUser userId={u} onUnignored={this._onUserUnignored} key={u} />);

        return (
            <div className='mx_SettingsTab_section'>
                <span className='mx_SettingsTab_subheading'>{_t('Ignored users')}</span>
                <div className='mx_SettingsTab_subsectionText'>
                    {userIds}
                </div>
            </div>
        );
    }

    _renderManageInvites() {
        if (this.state.invitedRoomAmt === 0) {
            return null;
        }

        const invitedRooms = this._getInvitedRooms();
        const onClickAccept = this._onAcceptAllInvitesClicked.bind(this, invitedRooms);
        const onClickReject = this._onRejectAllInvitesClicked.bind(this, invitedRooms);
        return (
            <div className='mx_SettingsTab_section mx_SecurityUserSettingsTab_bulkOptions'>
                <span className='mx_SettingsTab_subheading'>{_t('Bulk options')}</span>
                <div className="buttons">
                    <AccessibleButton onClick={onClickAccept} kind='primary' disabled={this.state.managingInvites}>
                        {_t("Accept all %(invitedRooms)s invites", {invitedRooms: this.state.invitedRoomAmt})}
                    </AccessibleButton>
                    <AccessibleButton onClick={onClickReject} kind='danger' disabled={this.state.managingInvites}>
                        {_t("Reject all %(invitedRooms)s invites", {invitedRooms: this.state.invitedRoomAmt})}
                    </AccessibleButton>
                </div>
                {this.state.managingInvites ? <Spinner /> : <div />}
            </div>
        );
    }

    render() {
        const DevicesPanel = sdk.getComponent('views.settings.DevicesPanel');

        return (
            <div className="mx_SettingsTab mx_SecurityUserSettingsTab">
                <div className="mx_SettingsTab_heading">{_t("Security & Privacy")}</div>
                <MfaSection />
                {this._renderCurrentDeviceInfo()}
                <div className="mx_SettingsTab_section">
                    <span className="mx_SettingsTab_subheading">{_t("Devices")}</span>
                    <div className='mx_SettingsTab_subsectionText'>
                        <DevicesPanel />
                    </div>
                </div>
                {this._renderIgnoredUsers()}
                {this._renderManageInvites()}
            </div>
        );
    }
}
