import React from 'react';
import PropTypes from 'prop-types';

import { _t } from '../../../languageHandler';
import BaseDialog from './BaseDialog';
import DialogButtons from '../elements/DialogButtons';

import failure from '../../../../res/img/error-circle.svg';
import success from '../../../../res/img/delete-success.svg';

const ErrorDialogContent = (
    { failureIcon: propFailureImg, isFailure, message = '', onFinished, successImg: propSuccessImg, title, listOfRequesters },
) => {
    const failureImg = propFailureImg ? propFailureImg : failure;
    const successImg = propSuccessImg ? propSuccessImg : success;

    return (
        <BaseDialog
            className="success_error_dialog"
            fixedWidth={false}
            hasCancel={false}
            onFinished={onFinished}
            title=''
        >
            <div className='success_error_dialog_content'>
                <img src={isFailure ? failureImg : successImg} alt='image' height={80} />
                <p>{title}</p>
                <span>{message}</span>
            </div>
            <DialogButtons
                focus={true}
                hasCancel={false}
                onPrimaryButtonClick={onFinished}
                primaryButton={_t('Close')}
            />
        </BaseDialog>
    );
};

ErrorDialogContent.propTypes = {
    onFinished: PropTypes.func.isRequired,
};

export default ErrorDialogContent;
