import React from 'react';
import sdk from '../../../index';
import PropTypes from 'prop-types';
import { _t } from '../../../languageHandler';
import changePasswordIcon from '../../../../res/img/password-changed.svg';

class ChangePasswordSuccessDialog extends React.Component {
    componentDidMount() {
        setTimeout(this.props.onFinished, 3000);
    }

    render() {
        const BaseDialog = sdk.getComponent('views.dialogs.BaseDialog');

        const text = _t('Your password has been successfully reset.');

        const dialogContent = (
            <div className="mx_ChangePasswordSuccessDialog">
                <div className="icon">
                    <img src={changePasswordIcon} alt="" />
                </div>
                <div className="text">
                    { text }
                </div>
            </div>
        );

        return (
            <BaseDialog
                title=''
                onFinished={() => {}}
                hasCancel={false}
                isFocusable={false}
            >
                { dialogContent }
            </BaseDialog>
        );
    }
}

ChangePasswordSuccessDialog.propTypes = {
    onFinished: PropTypes.func.isRequired,
};

export default ChangePasswordSuccessDialog;
