import React from 'react';
import PropTypes from "prop-types";
import { isEqual } from 'lodash';
import { getBrowserName } from "../../../utils/Browser";

class Stats extends React.Component {
    state = { bitrateOut: 0, bitrateIn: 0, isFirefox: false };
    bytesPrevOut = 0;
    bytesPrevIn = 0;
    timestampPrevOut = 0;
    timestampPrevIn = 0;
    _isMounted = false;

    componentDidMount() {
        const { streamId } = this.props;
        this._isMounted = true;
        const isFirefox = getBrowserName() === 'firefox';
        if (isFirefox) {
            const videoElement = document.getElementById(`video-element-${streamId}`);
            if (videoElement) {
                videoElement.addEventListener("loadedmetadata", this.getVideoMetadata);
            }
            this.setState({ isFirefox });
        }
        this.statsIntervalRef = setInterval(this.getStats, 1000);
    }

    componentDidUpdate({ RTCPeerConnection: prevRTCPeerConnection }) {
        const { RTCPeerConnection } = this.props;

        if (!isEqual(RTCPeerConnection, prevRTCPeerConnection) && this._isMounted) {
            this.bytesPrevOut = 0;
            this.thisbytesPrevIn = 0;
            this.timestampPrevOut = 0;
            this.timestampPrevIn = 0;
            this.setState({ bitrateOut: 0, bitrateIn: 0 });
        }
    }

    componentWillUnmount() {
        const { streamId } = this.props;
        this._isMounted = false;
        if (this.statsIntervalRef !== null) {
            clearInterval(this.statsIntervalRef);
        }

        const videoElement = document.getElementById(`video-element-${streamId}`);
        if (videoElement) {
            videoElement.removeEventListener("loadedmetadata", this.getVideoMetadata);
        }
    }

    getVideoMetadata = (e) => {
        if (!this._isMounted) return;
        this.setState({ frameWidth: e.target.videoWidth, frameHeight: e.target.videoHeight });
    }

    // Dumping a stats variable as a string.
    // might be named toString?
    dumpStats = (results) => {
        let statsString = '';
        results.forEach(res => {
            statsString += '<h3>Report type=';
            statsString += res.type;
            statsString += '</h3>\n';
            statsString += `id ${res.id}<br>`;
            statsString += `time ${res.timestamp}<br>`;
            Object.keys(res).forEach(k => {
                if (k !== 'timestamp' && k !== 'type' && k !== 'id') {
                    statsString += `${k}: ${res[k]}<br>`;
                }
            });
        });
        return statsString;
    }

    getStats = async () => {
        const { RTCPeerConnection, streamId, onBitrateChange } = this.props;
        const { isFirefox } = this.state;

        if (!RTCPeerConnection) return;

        try {
            const reports = await RTCPeerConnection.getStats(null);
            if (!this._isMounted) return;
            // const statsString = this.dumpStats(reports);
            // this.setState({ statsString });

            reports.forEach(report => {
                const now = report.timestamp;
                let bitrateOut;
                if (report.type === 'outbound-rtp') {
                    const bytes = report.bytesSent;
                    if (this.timestampPrevOut) {
                        bitrateOut = 8 * (bytes - this.bytesPrevOut) / (now - this.timestampPrevOut);
                        bitrateOut = Math.floor(bitrateOut);
                    }
                    this.bytesPrevOut = bytes;
                    this.timestampPrevOut = now;
                }
                if (bitrateOut) {
                    if (streamId && onBitrateChange) onBitrateChange(streamId, bitrateOut, false);
                    bitrateOut += ' kbits/sec';
                    this._isMounted && this.setState({ bitrateOut, type: report.mediaType });
                }

                let bitrateIn;
                if (report.type === 'inbound-rtp') {
                    const bytes = report.bytesReceived;
                    if (this.timestampPrevIn) {
                        bitrateIn = 8 * (bytes - this.bytesPrevIn) / (now - this.timestampPrevIn);
                        bitrateIn = Math.floor(bitrateIn);
                    }
                    this.bytesPrevIn = bytes;
                    this.timestampPrevIn = now;
                }

                if (bitrateIn) {
                    if (streamId && onBitrateChange) onBitrateChange(streamId, bitrateIn, true);
                    this._isMounted && this.setState({ bitrateIn: `${bitrateIn} kbits/sec`, type: report.mediaType });
                }

                if (!isFirefox && report.type === 'track') { // type === 'media-source' || 'outbound-rtp'
                    const newFrameWidth = report.frameWidth;
                    const newFrameHeight = report.frameHeight;
                    this._isMounted && this.setState({ frameWidth: newFrameWidth, frameHeight: newFrameHeight });
                }
            });
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const { bitrateIn = 0, bitrateOut = 0, type = '', frameWidth, frameHeight } = this.state;

        if (!type) return null;

        const capitalizedType = `${type[0].toUpperCase()}${type.slice(1)}`;

        return <div className='stats'>
            {bitrateIn ? <div>{`${capitalizedType} bitrate in: ${bitrateIn}`}</div> : null}
            {bitrateOut ? <div>{`${capitalizedType} bitrate out: ${bitrateOut}`}</div> : null}
            {frameWidth ? <div>{`Resolution: ${frameWidth} x ${frameHeight}`}</div> : null}
        </div>;
    }
}

Stats.propTypes = {
    RTCPeerConnection: PropTypes.object,
    onBitrateChange: PropTypes.func,
    streamId: PropTypes.oneOfType([
        PropTypes.string.isRequired, // on IO selection screen it's set as string
        PropTypes.number.isRequired,
    ]),
};

export default Stats;
