import React from 'react';
import PropTypes from 'prop-types';
import BaseDialog from '../BaseDialog';
import { _t } from '../../../../languageHandler';
import AccessibleButton from '../../elements/AccessibleButton';

const SurveyDialog = props => <BaseDialog
    className="mx_CitadexSurveyDialog"
    title=""
    {...props}
>
    <div className="mx_CitadexSurveyDialog_container">
        <div className="mx_CitadexSurveyDialog_image" />
        <span className="mx_Dialog_title">
            {_t('We are interested in your opinion')}
        </span>
        <p className="mx_CitadexSurveyDialog_p">
            {_t("We'd love to hear about your experience with the Citadel Bridge and Citadel Visio features.")}
        </p>
        <p className="mx_CitadexSurveyDialog_p last">
            {_t("Would you mind filling out this short survey to help us improve our product? It will take less than 60 seconds!")}
            <br />
            {_t("So please : tell us everything, we're listening!")}
        </p>
        <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=BWl8c4bxz0uvs0PjSe4jo6QBasrJTxJDp5MAW2HvZWJUQ0pPTkNXUTI4RFYwWk1SNkY0N1A5Qlo4RS4u" target="_blank">
            <AccessibleButton
                className="mx_CitadexSurveyDialog_button mx_AccessibleButton mx_AccessibleButton_hasKind mx_AccessibleButton_kind_primary"
                onClick={props.onFinished}
            >
                {_t('Give your opinion!')}
            </AccessibleButton>
        </a>
    </div>
</BaseDialog>;

SurveyDialog.defaultProps = {
    onFinished: () => {},
};

SurveyDialog.propTypes = {
    onFinished: PropTypes.func,
};

export default SurveyDialog;
