import React from 'react';

import CitadexContextMenu from './CitadexContextMenu';

import { _t } from '../../../languageHandler';

export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contextOpen: false,
            x: '0',
            y: '100%',
        };
    }

    toggleContext = (e) => {
        this.setState(prevState => ({
            contextOpen: !prevState.contextOpen,
        }));
    };

    renderContextMenu = () => {
        const { contextOpen, mouseX, mouseY } = this.state;

        if (!contextOpen) return null;

        return (
            <div onClick={this.toggleContext}>
                <CitadexContextMenu
                    className="conference_button_context"
                    mouseX={mouseX}
                    mouseY={mouseY}
                    onClose={this.toggleContext}
                    upward
                >
                    {this.props.children}
                </CitadexContextMenu>
            </div>
        );
    }

    render() {
        return <div className="mx_RoomHeader_conference_button_container">
            <button
                className="mx_AccessibleButton_kind_secondary mx_RoomHeader_conference_start_button"
                title={_t(`Conference`)}
                onClick={this.toggleContext}
            >
                    <div className="mx_ConferenceOptions_image" />
                    {_t(`Conference`)}
            </button>
            {this.renderContextMenu()}
        </div>;
    }
}
