import { Store } from 'flux/utils';
import { isEqual, forEach, noop } from 'lodash';
import Md5 from 'md5.js';

import utils from 'matrix-js-sdk/lib/utils';
import dis from '../dispatcher';
import MatrixClientPeg from '../MatrixClientPeg';
import PlatformPeg from "../PlatformPeg";
import { StreamAPI } from '../utils/WebRtc';
import { errorDialog, ERROR_TYPES } from '../components/views/janus/ErrorDialogsHelper';
import { getMembers } from '../utils/Citadex';
import SettingsStore from '../settings/SettingsStore';

export const TYPE_AUDIO = 'audio';
export const TYPE_VIDEO = 'video';
export const TYPE_DESKTOP = 'desktop';
// local storage keys and prefix
const LS_KEYS = ['matrixRoomId', 'sessionName', 'sessionType', 'sessionUrl', 'callInProgress', 'sessionInitialType',
    'isDmRoom', "isCitadexOpened"];
const LS_PREFIX = 'citadex';

const ICE_SERVERS = {
    prod: [{
        urls: 'turns:conference-turn.citadel.team:443?transport=tcp',
        username: 'turncitadel',
        credential: '8PhBPIvvdexvjqIu',
    }],
    test: [{
        urls: 'turns:conference-turn-dev.citadel.team:443?transport=tcp',
        username: 'turncitadel',
        credential: '8PhBPIvvdexvjqIu',
    }],
};
const MAX_ROOM_ID = Number.MAX_SAFE_INTEGER; // 2^53 - 1

const INITIAL_STATE = {
    matrixRoomId: null,
    sessionName: null,
    sessionType: null,
    sessionUrl: null,
    callInProgress: null,
    sessionInitialType: null,
    isDmRoom: false,
};

class CitadexStore extends Store {
    constructor() {
        super(dis);

        this.visioServer = null;
        this.turnServer = null;
        this.displayConsoleLogs = true;
        this.maxParticipants = { video: 10, audio: 30 };
        if ('BroadcastChannel' in self) {
            // BroadcastChannel API supported!
            this.channel = new BroadcastChannel("conference");
            if (!this.isConferenceTab) {
                this.channel.addEventListener("message", e => {
                    if (e.data) {
                        const messages = Object.values(utils.jsonParseSafe(e.data.data));
                        this[e.data.isError ? 'error' : 'log']('Conference Tab - ', ...messages);
                    }
                });
            }
            this.log('Broadcast channel is supported, the logs from conference tab/window ' +
                'will be displayed also in the main tab');
        } else {
            this.log('Broadcast channel is not supported, ' +
                'the logs from conference will NOT be displayed in the main tab');
        }
        this._state = { ...INITIAL_STATE };
    }

    init = async () => {
        this.log('init');
        this.checkIfWebRtcIsSupported();
        const visioConfig = await MatrixClientPeg.get().getVisioServerConfig();
        this.isConferenceTab = PlatformPeg.get().isConferenceWindowOrTab();
        const { visioServer, turnServer, videoMaxParticipants, audioMaxParticipants } = visioConfig;
        if (visioServer) {
            this.visioServer = visioServer.endsWith('/') ? visioServer.slice(0, -1) : visioServer;
        } else {
            if (this.getTestMode()) {
                this.visioServer = "https://conference-dev.citadel.team";
            } else {
                this.visioServer = "https://conference.citadel.team";
            }
        }

        if (turnServer) {
            let normalizedTurnServerValue = turnServer;
            if (!turnServer.includes(':')) {
                normalizedTurnServerValue += ':443';
            }
            this.turnServer = [{
                urls: `turns:${normalizedTurnServerValue}?transport=tcp`,
                username: ICE_SERVERS.prod[0].username,
                credential: ICE_SERVERS.prod[0].credential,
            }];
        } else {
            if (this.getTestMode()) {
                this.turnServer = ICE_SERVERS.test;
            } else {
                this.turnServer = ICE_SERVERS.prod;
            }
        }

        if (videoMaxParticipants && audioMaxParticipants) {
            this.maxParticipants = {
                video: videoMaxParticipants,
                audio: audioMaxParticipants,
            };
        }
    };

    _setState = ({ ...newState }) => this._state = { ...this._state, ...newState };

    log = (...data) => {
        if (this.displayConsoleLogs) {
            console.log(`%cCitadex -`, 'color: #7b8ffe', ...data);
            if (this.channel && this.getIsCitadexOpened() && this.isConferenceTab) {
                this.channel.postMessage({ isError: false, data: JSON.stringify(data) });
            }
        }
    };

    error = (...data) => {
        console.error('Citadex -', ...data);
        if (this.channel && this.getIsCitadexOpened() && this.isConferenceTab) {
            this.channel.postMessage({ isError: true, data: JSON.stringify(data) });
        }
    };

    getMaxParticipants = () => this.maxParticipants;
    getVisioServer = () => this._state.callInProgress ? this._state.sessionUrl : this.visioServer;
    getTurnServer = () => this.turnServer;

    onOpenCitadexWindow = (windowReference) => {
        const basePath = window.location.origin + window.location.pathname;
        if (windowReference) {
            windowReference.target = 'citadex';
            windowReference.location = `${basePath}#/conference`;
            return;
        }

        window.open(`${basePath}#/conference`, 'citadex');
    };

    focusConferenceWindow = () => {
        const platform = PlatformPeg.get();
        if (platform.isDesktop()) {
            platform.showWindow('citadex');
            return;
        }
        window.open(`${window.location.origin + window.location.pathname}#/conference`, 'citadex');
    }
    setIsCitadexOpened = (value) => {
        value ? localStorage.setItem('citadex.isCitadexOpened', value) :
            localStorage.removeItem('citadex.isCitadexOpened');
        const platform = PlatformPeg.get();
        if (platform.isDesktop()) {
            platform.setConferenceOpenStatus(value);
        }
    }
    getIsCitadexOpened = () => !!localStorage.getItem("citadex.isCitadexOpened");

    getSessionName = () => this._state.sessionName ? this._state.sessionName : localStorage.getItem("citadex.sessionName");
    getSessionType = () => this._state.sessionType ? this._state.sessionType : localStorage.getItem("citadex.sessionType");
    getSessionInitialType = () => this._state.sessionInitialType ? this._state.sessionInitialType : localStorage.getItem("citadex.sessionInitialType");
    getMatrixRoomId = () => this._state.matrixRoomId ? this._state.matrixRoomId : localStorage.getItem("citadex.matrixRoomId");
    getIsDmRoom = () => this._state.isDmRoom ? !!this._state.isDmRoom : !!localStorage.getItem("citadex.isDmRoom");

    getCallInProgress = () => this._state.callInProgress ? this._state.callInProgress : utils.jsonParseSafe(localStorage.getItem("citadex.callInProgress"));

    getWrapperAddress = () => this.wrapperAddress;

    setTestMode = (isTestMode) => {
        if (isTestMode) {
            localStorage.setItem('citadex.testMode', isTestMode);
        } else {
            localStorage.removeItem('citadex.testMode');
        }
    };

    getTestMode = () => localStorage.getItem('citadex.testMode');

    prepareDataForConference = (type, matrixRoomId, roomName, callInProgress, isDmRoom, initialType) => {
        this.setIsCitadexOpened(true);
        const conferenceInNewTab = SettingsStore.getValue("Conference.openConferenceInTab");
        if (conferenceInNewTab) {
            if (this._state.callInProgress && !isEqual(callInProgress, this._state.callInProgress)) {
                this.clearSession();
                this.log('new session is prepared');
            }
            localStorage.setItem('citadex.matrixRoomId', matrixRoomId);
            localStorage.setItem('citadex.sessionName', roomName);
            localStorage.setItem('citadex.sessionType', type);
            isDmRoom && localStorage.setItem('citadex.isDmRoom', isDmRoom.toString());
            initialType && localStorage.setItem('citadex.sessionInitialType', initialType);
            const sessionUrl = callInProgress ? callInProgress.event.content.room_janus_url : this.visioServer;
            localStorage.setItem('citadex.sessionUrl', sessionUrl);
            if (callInProgress) {
                const newCallInProgress = { event: callInProgress.event };
                this.setIsCitadexOpened(true);
                localStorage.setItem('citadex.callInProgress', JSON.stringify(newCallInProgress));
            } else {
                localStorage.removeItem('citadex.callInProgress');
            }
            dis.dispatch({ action: 'toggle_conference', isOpen: true });
        } else {
            if (this._state.callInProgress && !isEqual(callInProgress, this._state.callInProgress)) {
                this.clearSession();
                this.log('new session is prepared');
            }
            const sessionUrl = callInProgress ? callInProgress.event.content.room_janus_url : this.visioServer;
            this._setState({
                isDmRoom: isDmRoom,
                matrixRoomId,
                sessionInitialType: initialType,
                sessionName: roomName,
                sessionType: type,
                sessionUrl,
            });
            if (callInProgress) {
                const newCallInProgress = { event: callInProgress.event };
                this._setState({ callInProgress: newCallInProgress });
            } else {
                this._setState({ callInProgress: null });
            }
            dis.dispatch({ action: 'toggle_conference', isOpen: true });
            this._setState({ isCitadexVisible: true });
        }
    }

    prepareSessionCreation = async (roomId, sessionType, sessionUrl, matrixRoomId, wrapperAddress, JWToken) => {
        const conferenceInNewTab = SettingsStore.getValue("Conference.openConferenceInTab");
        if (conferenceInNewTab) {
            localStorage.setItem('citadex.matrixRoomId', matrixRoomId);
            localStorage.setItem('citadex.sessionType', sessionType);
            localStorage.setItem('citadex.sessionUrl', sessionUrl);
            this._setState({ matrixRoomId, sessionType, sessionUrl });
        } else {
            this._setState({ matrixRoomId, sessionType, sessionUrl });
        }
        try {
            if (JWToken) {
                this.JWToken = JWToken;
            } else {
                await this.getConferenceJWToken();
            }
        } catch (e) {
            this.error(`Something went wrong (${e})`);
            throw ERROR_TYPES.GENERIC;
        }

        if (wrapperAddress) {
            this.wrapperAddress = wrapperAddress;
        } else {
            try {
                this.log('Wrapper address is missing, try to fetch the address');
                const cli = MatrixClientPeg.get();
                const { [roomId]: { ip } } = await cli.getAVRoomLocation(sessionUrl, roomId);
                this.log(`Got wrapper address`, ip);
                this.wrapperAddress = ip;
            } catch (e) {
                if (sessionType === TYPE_AUDIO) {
                    MatrixClientPeg.get().trackUserAction({
                        formId: 'audioConference',
                        version: 1,
                        action: 'error',
                        step: 'request',
                        code: e.httpStatus,
                    });
                } else if (sessionType === TYPE_VIDEO) {
                    MatrixClientPeg.get().trackUserAction({
                        formId: 'videoConference',
                        version: 1,
                        action: 'error',
                        step: 'request',
                        code: e.httpStatus,
                        reason: e.message,
                    });
                }
                this.error(`Can not get wrapper address ${e.httpStatus}`);
                if (e.httpStatus === 404) {
                    const callInProgressEvent = this.getCallInProgress();
                    const newConferenceEvent = {
                        ...callInProgressEvent.event.content,
                        status: 'close',
                    };
                    MatrixClientPeg.get().sendConferenceEvent(newConferenceEvent);
                    throw ERROR_TYPES.ROOM_CLOSED;
                }
                throw ERROR_TYPES.GENERIC;
            }
        }
        this.log('Session is prepared');
    };

    clearSession = () => {
        forEach(LS_KEYS, key => localStorage.removeItem(`${LS_PREFIX}.${key}`));
        this._setState({ ...INITIAL_STATE });
        this.JWToken = null;
        this.wrapperAddress = null;
        this.log('session cleared');
    };

    destroySession = () => {
        this.clearSession();
        const conferenceInNewTab = SettingsStore.getValue("Conference.openConferenceInTab");
        this.setIsCitadexOpened(false);
        if (conferenceInNewTab) {
            dis.dispatch({ action: 'toggle_conference', isOpen: false });
        } else {
            dis.dispatch({ action: 'toggle_conference', isOpen: false });
            dis.dispatch({ action: 'maximize_conference', isMaximized: false });
            dis.dispatch({ action: 'minimize_conference', isMinimized: false });
        }

        this.log('close session');
    };

    checkIfWebRtcIsSupported = () => {
        if (this.isWebRtcSupported === undefined) {
            this.isWebRtcSupported = StreamAPI.isWebRtcSupported();
            this.log(`WebRtc is${this.isWebRtcSupported ? '' : ' not'} supported`);
            this.log(`Audio and video calls are ${this.isWebRtcSupported ? 'enabled' : 'disabled'}`);
        }
        return this.isWebRtcSupported;
    };

    getPublishingToken = async (handler) => {
        const cli = MatrixClientPeg.get();
        const url = this.getVisioServer();
        const jwt = await this.getConferenceJWToken();
        return cli.getPublishingToken({
            roomId: handler.room,
            url,
            handler,
            jwt,
        });
    };

    getConferenceJWToken = async () => {
        if (this.JWToken) return this.JWToken;

        const cli = MatrixClientPeg.get();
        const url = this.getVisioServer();
        const myId = localStorage.getItem('mx_user_id');
        const homeserverUrl = localStorage.getItem('mx_hs_url');
        const tokenData = await cli.getOpenIdToken();
        const { success, jwt } = await cli.getConferenceJWToken({ userId: myId, url, homeserverUrl, tokenData });
        if (!success) throw new Error('ca not get token');
        this.JWToken = jwt;
        this.log('got publisher token');
        return jwt;
    };

    generateBroadcastId = (matrixRoomId) => {
        const md5RoomId = new Md5().update(matrixRoomId).digest('hex');
        const derivatedMd5RoomId = md5RoomId.replace(/[abcdef]/g, item => parseInt(item, 16));
        const truncatedRoomId = derivatedMd5RoomId.substring(0, 16);
        return truncatedRoomId > MAX_ROOM_ID ? parseInt(truncatedRoomId.substring(0, 15)) : parseInt(truncatedRoomId);
    }

    createRoomRequest = async (url, type, opaque, jwt, matrixRoomId) => {
        try {
            const broadcastId = this.generateBroadcastId(matrixRoomId);
            const cli = MatrixClientPeg.get();
            const data = await cli.createAVRoom(url, type, this.maxParticipants[type], opaque, jwt, broadcastId);
            this.log('Janus room created');
            this.log('Broadcast/Room id:', broadcastId);
            return { broadcastId, data };
        } catch (err) {
            const { message } = err;
            if (message === 'Failed to open videoroom channel: Broadcast id already in use') {
                return this.createRoomRequest(url, type, opaque, jwt);
            } else {
                throw err;
            }
        }
    }

    createRoom = async (type = TYPE_VIDEO, matrixRoomId) => {
        this.log('Try to create Janus room for matrix room with id', matrixRoomId);
        const url = this.getVisioServer();
        const myId = localStorage.getItem('mx_user_id');
        try {
            const [membersIdList, jwt] = await Promise.all([getMembers(matrixRoomId), this.getConferenceJWToken()]);
            const opaque = {
                attendeesId: membersIdList,
                citadelRoomId: matrixRoomId,
                inviterId: myId,
                janusUrl: url,
                type,
            };
            const { broadcastId, data } = await this.createRoomRequest(url, type, opaque, jwt, matrixRoomId);
            const { ip: wrapperAddress } = data[broadcastId];
            this.log('Wrapper address', wrapperAddress);
            await this.prepareSessionCreation(broadcastId, type, url, matrixRoomId, wrapperAddress, jwt);
        } catch (e) {
            // in case getMembers or getConferenceJWToken fails, the error should be displayed
            const errorMsg = e === ERROR_TYPES.ROOM_CLOSED ? null : 'Something went wrong';
            errorMsg && this.error(errorMsg, e.toString());
            errorDialog({ onContinue: noop, type: ERROR_TYPES.GENERIC });
        }
    };

    joinRoom = async (roomId, userId) => {
        const cli = MatrixClientPeg.get();
        const jwt = await this.getConferenceJWToken();
        const resp = await cli.joinAVRoom(this.getVisioServer(), roomId, userId, jwt);
        if (resp) {
            this.log(`Janus room ${roomId} was successfully joined`);
            return resp;
        } else {
            this.error(`Joining the room ${roomId} failed`, resp);
            return false;
        }
    };

    deleteConferenceUser = async (broadcastId, publisherId) => {
        const JWToken = await this.getConferenceJWToken();
        return await MatrixClientPeg.get().deleteConferenceUser(
            this._state.sessionUrl, broadcastId, publisherId, JWToken,
        );
    }

    deleteRoom = async (roomId) => {
        try {
            await MatrixClientPeg.get().deleteAVRoom(this.getVisioServer(), roomId);
            return true;
        } catch (e) {
            console.log('deletion error', e);
            return false;
        }
    };

    getAVRooms = async () => {
        const videoRooms = await MatrixClientPeg.get().getAVRooms(this.getVisioServer());
        this.log('video rooms', videoRooms);
        return videoRooms;
    };

    updateParticipantList = async (participants, inviterId, broadcastId, reason) => {
        const cli = MatrixClientPeg.get();
        if (!cli) return;
        try {
            this.log('New participants list: ', participants);
            const JWToken = await this.getConferenceJWToken();

            cli.updateConferenceParticipantList(this._state.sessionUrl, JWToken, {
                participants,
                inviterId,
                broadcastId,
                reason,
            });
        } catch (e) {
            this.error('Updating participants list failed: ', e);
        }
    }

    __onDispatch() { }
}

let singletonCitadexStore = null;
if (!singletonCitadexStore) {
    singletonCitadexStore = new CitadexStore();
}

export default singletonCitadexStore;

