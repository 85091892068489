import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

class CitadexContextMenu extends React.Component {
    componentDidMount() {
        document.getElementsByClassName('citadex_context_menu')[0].focus();
    }

    handleOnClose = (event) => {
        const { onClose } = this.props;
        if (!event.currentTarget.contains(event.relatedTarget)) onClose(event);
    }

    render() {
        const { center, children, className, mouseX, mouseY, upward } = this.props;
        const styleObject = upward ? {
            right: mouseX ? window.innerWidth - mouseX : 'auto',
            bottom: mouseY ? window.innerHeight - mouseY : '',
            left: 'auto',
            top: 'auto',
        } : center ? {
            right: mouseX > window.innerWidth / 2 ? window.innerWidth - mouseX : 'auto',
            bottom: mouseY ? window.innerHeight - mouseY : '',
            left: mouseX < window.innerWidth / 2 ? mouseX : 'auto',
            top: 'auto',
            translateY: null,
        } : {
            left: mouseX ? mouseX : 'auto',
            top: mouseY ? mouseY : '',
        };
        return (
            <div
                className={cx("citadex_context_menu", className)}
                style={styleObject}
                onBlur={this.handleOnClose}
                tabIndex="-1"
            >
                {children}
            </div>
        );
    }
}

CitadexContextMenu.propTypes = {
    mouseX: PropTypes.number,
    mouseY: PropTypes.number,
    className: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};

export default CitadexContextMenu;
