/*
Copyright 2018 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React from 'react';
import QuestionDialog from './QuestionDialog';
import { _t } from '../../../languageHandler';

export default (props) => {
    const description =
        _t("We wanted to announce to you our last novelty: a new interface, both simpler and faster,"
        + " more than ever in the service of your collaboration. And this is just the beginning,"
        + " many improvements will come in the next few months.");

    const additional =
        _t("Please wait a moment while we synchronize your data.");

    return (<QuestionDialog
        hasCancelButton={false}
        title={_t("Discover your new collaboration space.")}
        description={
            <div>
                <div className="description">
                    {description}
                </div>
                <div className="additional">
                    {additional}
                </div>
            </div>
        }
        button={_t("Let's go!")}
        onFinished={props.onFinished}
    />);
};
