import { remove } from 'lodash';
import dis from './dispatcher';

const PREFIX = 'has_shown';
const ORDERED_FLAGS = [
    `${PREFIX}_invite_address_bar`, // CTDL-8241
    `${PREFIX}_create_direct_web`, // CTDL-8243
    `${PREFIX}_create_room_web`, // CTDL-8244
    `${PREFIX}_invite_members_button`, // CTDL-8245
    `${PREFIX}_quick_first_message`, // CTDL-8242
    `${PREFIX}_read_receipt_web`, // CTDL-8246
];
// To be displayed on top of dialogs
export const DIALOG_FLAGS = [`${PREFIX}_invite_address_bar`];

export const INIT_IN_APP_CONTENT = ORDERED_FLAGS
    .reduce((acc, flag) => {
        return {
            ...acc,
            [flag]: false,
        };
    }, {});

class HintManager {
    constructor() {
        this.pending = [];
        this.old = [];
        this.currentlyDisplayed = '';
    }

    _addPendingHint = flag => {
        if (!this.pending.includes(flag)) {
            this.pending.push(flag);
        }
    };

    prepareHintDisplay(flag) {
        if (!this.old.includes(flag)) {
            if (this.currentlyDisplayed && this.currentlyDisplayed !== flag) {
                this._addPendingHint(flag);
                return false;
            } else {
                this.currentlyDisplayed = flag;
                return true;
            }
        }
    }

    checkPendingHints() {
        if (this.pending.length) {
            const nextFlagToDisplay = ORDERED_FLAGS.find(flag => this.pending.includes(flag));
            this.pending = remove(this.pending, flag => {
                return flag !== nextFlagToDisplay;
            });

            this.old.push(this.currentlyDisplayed);
            this.currentlyDisplayed = nextFlagToDisplay;

            setTimeout(() => {
                dis.dispatch({
                    action: 'allow_hint_display',
                    flag: nextFlagToDisplay,
                });
            }, 1000);
        } else {
            this.currentlyDisplayed = '';
        }
    }
}

if (!global.singletonHintManager) global.singletonHintManager = new HintManager();
export default global.singletonHintManager;
