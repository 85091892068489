/*
Copyright 2017 Vector Creations Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import MessageTimestamp from './MessageTimestamp';
import UserStore from "../../../stores/UserStore";
import { _t } from '../../../languageHandler';

import confAudioOpened from '../../../../res/img/conference/audio-status-opened.svg';
import confAudioClosed from '../../../../res/img/conference/audio-status-closed.svg';
import confVideoOpened from '../../../../res/img/conference/video-status-opened.svg';
import confVideoClosed from '../../../../res/img/conference/video-status-closed.svg';

module.exports = class extends React.Component {
    static displayName = 'ConferenceEvent';

    static propTypes = {
        mxEvent: PropTypes.object.isRequired,
        isTwelveHour: PropTypes.bool.isRequired,
    };

    getConferenceInviterDisplayName = (event) => {
        const { inviter_id: userId } = event.getContent();
        const userDisplayName = UserStore.getUserById(userId).displayName;
        return userDisplayName || userId;
    };

    render() {
        const { mxEvent: event } = this.props;
        const { type, status } = event.getContent();
        const eventTimeStamp = event.getTs();
        const timestamp = eventTimeStamp ?
            <MessageTimestamp showTwelveHour={this.props.isTwelveHour} ts={eventTimeStamp} /> : null;
        const conferenceInviter = this.getConferenceInviterDisplayName(event);
        const icon = type === 'audio'
            ? (status === 'open' ? confAudioOpened : confAudioClosed)
            : (status === 'open' ? confVideoOpened : confVideoClosed);

        return (
            <div className={cx('mx_RoomConferenceEvent', type, status)}>
                <img src={icon} className={cx('icon_container')} />
                <div className={cx('conference-text')}>
                    {status === 'open'
                        ? _t('%(name)s started a conference', { name: conferenceInviter })
                        : _t('The conference started by %(name)s is closed', { name: conferenceInviter })
                    }
                </div>
                {timestamp ? <div className={'conference-separator'}>{'-'}</div> : null}
                {timestamp}
            </div>
        );
    }
};
