import React from 'react';
import propTypes from 'prop-types';

const VolumeCell = ({ volume, index }) => {
    const state = volume > -80 + (index + 1) * 5 ? 'active' : '';
    return <div className={`mx_VolumeMeter_cell ${state}`} />;
};

const VolumeMeter = props => {
    const { volume } = props;
    const cells = new Array(11).fill("1");
    return <div className="mx_VolumeMeter">
        {cells.map((cell, i) => <VolumeCell key={i} volume={volume} index={i} />)}
    </div>;
};

VolumeMeter.defaultProps = {
    volume: -100,
};

VolumeMeter.propTypes = {
    volume: propTypes.number.isRequired,
};

export default VolumeMeter;
