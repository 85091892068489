import * as React from 'react';
import PropTypes from 'prop-types';

import { _t } from '../../../../languageHandler';
import BaseDialog from '../BaseDialog';
import PhoneNumberForm from './PhoneNumberForm';
import CodeVerificationForm from './CodeVerificationForm';
import MatrixClientPeg from "../../../../MatrixClientPeg";
import { isEmpty } from "lodash";

const STEPS = { NUMBER: 'NUMBER-SECTION', TOKEN: 'TOKEN-SECTION' };

export default class MfaSmsActivationDialog extends React.Component {
    static displayName = "MfaSmsActivationDialog";

    static propTypes = {
        mfaStatus: PropTypes.arrayOf(String),
        onContinue: PropTypes.func,
        onFinished: PropTypes.func,
    }

    state = { step: STEPS.NUMBER };

    onChangeStep = async () => {
        const { step } = this.state;
        const { mfaStatus } = this.props;

        this.setState({
            step: (step === STEPS.TOKEN) ?
                STEPS.NUMBER : STEPS.TOKEN,
        });

        if (step === STEPS.NUMBER) {
            MatrixClientPeg.get().trackUserAction({
                formId: 'statusMFA',
                version: 1,
                action: 'send',
                type: 'msisdn',
                past: isEmpty(mfaStatus) ? 'None' : mfaStatus.join('_'),
            });
        }
        if (step === STEPS.TOKEN) {
            MatrixClientPeg.get().trackUserAction({
                formId: 'statusMFA',
                version: 1,
                action: 'modify',
                type: 'msisdn',
                past: isEmpty(mfaStatus) ? 'None' : mfaStatus.join('_'),
            });
        }
    };

    onCodeVerificationSuccess = () => {
        const { onContinue, onFinished, mfaStatus } = this.props;
        MatrixClientPeg.get().trackUserAction({
            formId: 'statusMFA',
            version: 1,
            action: 'confirm',
            type: 'msisdn',
            past: isEmpty(mfaStatus) ? 'None' : mfaStatus.join('_'),
        });
        onContinue(true);
        onFinished();
    }

    handleDialogClose = () => {
        const { mfaStatus, onFinished } = this.props;
        MatrixClientPeg.get().trackUserAction({
            formId: 'statusMFA',
            version: 1,
            action: 'cancel',
            type: 'msisdn',
            past: isEmpty(mfaStatus) ? 'None' : mfaStatus.join('_'),
        });
        onFinished();
    }

    handleSendNewToken = () => {
        const { mfaStatus } = this.props;
        MatrixClientPeg.get().trackUserAction({
            formId: 'statusMFA',
            version: 1,
            action: 'new',
            type: 'msisdn',
            past: isEmpty(mfaStatus) ? 'None' : mfaStatus.join('_'),
        });
    }

    render() {
        const { step } = this.state;

        let content = null;
        switch (step) {
            case STEPS.NUMBER:
                content = <PhoneNumberForm onSuccess={this.onChangeStep} />;
                break;
            case STEPS.TOKEN:
                content = (
                    <CodeVerificationForm
                        onChangePhoneNumber={this.onChangeStep}
                        onSuccess={this.onCodeVerificationSuccess}
                        onSendNewToken={this.handleSendNewToken}
                    />
                );
        }

        return <BaseDialog
            className="mx_MfaSmsActivationDialog"
            onFinished={this.handleDialogClose}
            title=''
        >
            <div>
                <div className="title">{_t('Multi-factor authentication by SMS')}</div>
                <div className="content">
                    {content}
                </div>
            </div>
        </BaseDialog>;
    }
}
