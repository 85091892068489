import React from 'react';
import PropTypes from 'prop-types';
import { _t } from '../../../languageHandler';

const PasswordValidation = ({ passwordPolicy }) => {
    const lowerCaseMet = (passwordPolicy.lower) ? 'met' : '';
    const upperCaseMet = (passwordPolicy.upper) ? 'met' : '';
    const digitMet = (passwordPolicy.digit) ? 'met' : '';
    const specialCharMet = (passwordPolicy.special) ? 'met' : '';
    const minLengthMet = (passwordPolicy.length) ? 'met' : '';

    return (
        <div className="mx_PasswordValidation">
            <div className="labels">
                <div className={`label ${lowerCaseMet}`}>
                    {_t('Lowercase')}
                </div>
                <div className={`label ${upperCaseMet}`}>
                    {_t('Uppercase')}
                </div>
                <div className={`label ${digitMet}`}>
                    {_t('Digit')}
                </div>
                <div className={`label ${specialCharMet}`}>
                    {_t('Special character')}
                </div>
                <div className={`label ${minLengthMet}`}>
                    {_t('12 characters')}
                </div>
                {
                    passwordPolicy.unauthorized &&
                    <div className={`label error`}>
                        {_t('Unauthorized character')}
                    </div>
                }
            </div>
            { passwordPolicy.unauthorized &&
                <div className="description">
                    {_t('Special characters allowed - / : ; ( ) € & @ . , ? ! \' [ ] { } # % ^ * + = _ | ~ < > $ £ ¥ ` ° "')}
                </div>
            }
        </div>
    );
};

PasswordValidation.propTypes = {
    passwordPolicy: PropTypes.object.isRequired,
};

export default PasswordValidation;
