/*
 * Usage:
 * Modal.createTrackedDialog('An Identifier', 'some detail', ErrorDialog, {
 *   title: "some text", (default: "Error")
 *   description: "some more text",
 *   button: "Button Text",
 *   onFinished: someFunction,
 *   focus: true|false (default: true)
 *   error: { httpStatus, message }
 * });
 */

import React from 'react';
import PropTypes from 'prop-types';
import BaseDialog from './BaseDialog';
import { _t } from '../../../languageHandler';
import errorSvg from '../../../../res/img/error-icon.svg';
import MatrixClientPeg from "../../../MatrixClientPeg";
import dis from "../../../dispatcher";
import sdk from "../../../index";
import Modal from "../../../Modal";
import { isDirectMessageRoom } from "../../../Rooms";

export default class DeleteErrorDialog extends React.Component {
    static propTypes = {
        error: PropTypes.object,
        onFinished: PropTypes.func.isRequired,
        room: PropTypes.object.isRequired,
    };

    static defaultProps = {
        error: {},
        onFinished: () => {},
    };

    forgetRoom = async () => {
        const { room, onFinished } = this.props;
        try {
            await MatrixClientPeg.get().forget(room.roomId);
        } catch (err) {
            const errCode = err.errcode || _t("unknown error code");
            const ErrorDialog = sdk.getComponent("dialogs.ErrorDialog");
            Modal.createTrackedDialog('Failed to forget room', '', ErrorDialog, {
                title: _t("Error"),
                description: _t("Failed to forget room %(errCode)s", { errCode: errCode }),
            });
        } finally {
            room.updateMyMembership('leave');
            dis.dispatch({ action: 'view_next_room' });
            onFinished();
        }
    }

    render() {
        const {
            error: { httpStatus, message },
            room,
        } = this.props;
        let title = _t('Oops, something went wrong');
        let status = _t("Error '%(status)s' :", { status: httpStatus});
        let errMessage = message;

        if (httpStatus === 400) {
            title = _t(
                'This %(room)s was already deleted.',
                { room: isDirectMessageRoom(room) ? 'room' : 'discussion' },
            );
            status = _t("It will be removed from your list.");
            errMessage = '';
        }

        return (
            <BaseDialog
                className="mx_DeleteErrorDialog"
                onFinished={this.props.onFinished}
                title=""
                contentId='mx_Dialog_content'
            >
                <img className="mx_DeleteErrorDialog_image" src={errorSvg} />
                <div className="mx_Dialog_content" id='mx_Dialog_content'>
                    <div className="mx_Dialog_title">
                        {title}
                    </div>
                    <div className="mx_Dialog_status">
                        {status}
                    </div>
                    <div className="mx_Dialog_message">
                        {errMessage}
                    </div>
                </div>
                <div className="mx_Dialog_buttons">
                    <button className="mx_Dialog_primary" onClick={this.forgetRoom} >
                        { _t('Continue') }
                    </button>
                </div>
            </BaseDialog>
        );
    }
}
