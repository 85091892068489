import { noop } from 'lodash';
import utils from 'matrix-js-sdk/lib/utils';

import Modal from './Modal';
import UserStore from './stores/UserStore';
import ReceiverCodeDialog from './components/views/dialogs/device_verification/ReceiverCodeDialog';
import ExternalReceiverCodeDialog from './components/views/dialogs/device_verification/ExternalReceiverCodeDialog';
import SuccessErrorDialog from './components/views/dialogs/SuccessErrorDialog';
import * as MegolmExportEncryption from './utils/MegolmExportEncryption';

import { _t } from './languageHandler';

export default class EasyKeyRequestHandler {
    constructor(matrixClient) {
        this.client = matrixClient;
        this.receiverCodeDialog = null;
    }

    closeReceiverCodeDialog = () => {
        this.receiverCodeDialog.close();
        this.receiverCodeDialog = null;
    };

    showReceiverCodeDialog = (code) => {
        this.receiverCodeDialog = Modal.createDialog(
            ReceiverCodeDialog,
            { code },
            'ReceiverCodeDialog_wrapper',
        );
    };

    onExternalReceiverCodeDialogFinished = (successful = true, intentionalClose) => {
        if (!successful) {
            Modal.createDialog(
                SuccessErrorDialog,
                {
                    onFinished: noop,
                    title: _t("Decryption failure"),
                    message: _t("The messages could not be decrypted."),
                    isFailure: true,
                },
                'success_error_dialog',
            );
        } else if (!intentionalClose) {
            Modal.closeAll(true);
        }
    }

    showExternalReceiverCodeDialog = (mxc, secret, sender, senderDisplayName, eventId, roomId, timestamp) => {
        this.externalReceiverCodeDialog = Modal.createDialog(ExternalReceiverCodeDialog, {
            eventId,
            mxc,
            onFinished: this.onExternalReceiverCodeDialogFinished,
            roomId,
            secret,
            sender,
            senderDisplayName,
            timestamp,
        });
    }

    handleEasyKeyEvent = async (props) => {
        const { type, code, mxc, secret, sender, eventId, roomId, timestamp } = props;
        if (type === 'start') {
            this.receiverCodeDialog && this.closeReceiverCodeDialog();

            this.showReceiverCodeDialog(code);
        } else if (type === 'cancel') {
            this.receiverCodeDialog && this.closeReceiverCodeDialog();
        } else if (type === 'share') {
            try {
                const password = utils.generateAlphaNumString(30);
                const roomKeys = await this.client.exportRoomKeys();
                const file = await MegolmExportEncryption.encryptMegolmKeyFile(
                    JSON.stringify(roomKeys),
                    password,
                );
                const blob = new Blob(
                    [file],
                    {type: 'text/plain;charset=us-ascii'},
                );
                const url = await this.client.uploadContent(blob, { includeFilename: false });
                await this.client.sendKeysUrl(url, password);
            } catch (e) {
                console.error(e);
            }
        } else if (type === 'external') {
            const { displayName } = UserStore.getUserById(sender);
            this.showExternalReceiverCodeDialog(mxc, secret, sender, displayName, eventId, roomId, timestamp);
        }
    }
}
