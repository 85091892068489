import * as React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

const Svg = (props) => {
    const { className, src, fill, stroke, alt } = props;
    const classes = cx('svg-image', className);
    return <object
        className={classes}
        fill={fill}
        stroke={stroke}
        alt={alt}
        data={src}
    />;
};

Svg.propTypes = {
    fill: PropTypes.string,
    stroke: PropTypes.string,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    data: PropTypes.string,
};

export default Svg;
