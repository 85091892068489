import React from 'react';
import PropTypes from 'prop-types';

import { _t } from '../../../languageHandler';
import BaseDialog from '../../views/dialogs/BaseDialog';
import DialogButtons from '../../views/elements/DialogButtons';

import forwardForbiddenImg from '../../../../res/img/e2e/forward_forbidden.svg';

const ForwardForbiddenDialog = (props) => {
    const { onFinished } = props;

    const content = (
        <React.Fragment>
            <img src={forwardForbiddenImg} alt='forward forbidden' height={80} />
            <p>
                {_t('Unable to forward')}
            </p>
            {_t('Sorry, we do not currently authorize forwarding to an encrypted room.')}
        </React.Fragment>
    );

    return (
        <BaseDialog onFinished={onFinished} title='' hasCancel={false} fixedWidth={false}>
            <div className='mx_Dialog_content'>
                {content}
            </div>
            <DialogButtons
                focus={true}
                hasCancel={false}
                onPrimaryButtonClick={onFinished}
                primaryButton={_t('OK')}
            />
        </BaseDialog>
    );
};

ForwardForbiddenDialog.propTypes = {
    onFinished: PropTypes.func.isRequired,
};

export default ForwardForbiddenDialog;
