/*
Copyright 2015, 2016 OpenMarket Ltd
Copyright 2017 New Vector Ltd
Copyright 2018 Michael Telatynski <7t3chguy@gmail.com>

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/


import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEqual, debounce } from 'lodash';
import dis from '../../../dispatcher';
import MatrixClientPeg from '../../../MatrixClientPeg';
import { getCallInProgressType, isDirectMessageRoom, getCallInProgress } from '../../../Rooms';
import sdk from '../../../index';
import { createMenu } from '../../structures/ContextualMenu';
import * as RoomNotifs from '../../../RoomNotifs';
import * as FormattingUtils from '../../../utils/FormattingUtils';
import AccessibleButton from '../elements/AccessibleButton';
import ActiveRoomObserver from '../../../ActiveRoomObserver';
import RoomViewStore from '../../../stores/RoomViewStore';
import SettingsStore from '../../../settings/SettingsStore';
import UserStore from '../../../stores/UserStore';
import NewsStore from '../../../stores/NewsStore';

import videoIconBlue from '../../../../res/img/conference/video-status-opened.svg';
import videoIconGrey from '../../../../res/img/conference/video-status-closed.svg';
import audioIconBlue from '../../../../res/img/conference/audio-status-opened.svg';
import audioIconGrey from '../../../../res/img/conference/audio-status-closed.svg';
import CitadexStore from "../../../stores/CitadexStore";
import RoomExportStore from "../../../stores/RoomExportStore";

module.exports = class extends React.Component {
    static displayName = 'RoomTile';

    static propTypes = {
        collapsed: PropTypes.bool.isRequired,
        hasE2eKeyNotification: PropTypes.bool.isRequired,
        hideContextMenuButton: PropTypes.bool,
        highlight: PropTypes.bool.isRequired,
        incomingCall: PropTypes.object,
        isInvite: PropTypes.bool.isRequired,
        onClick: PropTypes.func,
        room: PropTypes.object.isRequired,
        // If true, apply mx_RoomTile_transparent class
        transparent: PropTypes.bool,
        unread: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        hasE2eKeyNotification: false,
        hideContextMenuButton: false,
        isDragging: false,
    };

    _shouldShowNotifBadge = () => {
        return RoomNotifs.BADGE_STATES.includes(this.state.notifState);
    };

    _shouldShowMentionBadge = () => {
        return RoomNotifs.MENTION_BADGE_STATES.includes(this.state.notifState);
    };

    _shouldShowStatusMessage = () => {
        if (!SettingsStore.isFeatureEnabled("feature_custom_status")) {
            return false;
        }
        const isInvite = this.props.room.getMyMembership() === "invite";
        const isJoined = this.props.room.getMyMembership() === "join";
        const looksLikeDm = this.props.room.getInvitedAndJoinedMemberCount() === 2;
        return !isInvite && isJoined && looksLikeDm;
    };

    _getStatusMessageUser = () => {
        const selfId = MatrixClientPeg.get().getUserId();
        const otherMember = this.props.room.currentState.getMembersExcept([selfId])[0];
        if (!otherMember) {
            return null;
        }
        return otherMember.user;
    };

    _getStatusMessage = () => {
        const statusUser = this._getStatusMessageUser();
        if (!statusUser) {
            return "";
        }
        return statusUser._unstable_statusMessage;
    };

    onRoomName = (room) => {
        if (room !== this.props.room) return;
        this.setState({
            roomName: this.props.room.name,
        });
    };

    onRoomStateEvents = (roomStateEvent) => {
        const room = this.props.room;

        if (room.roomId === roomStateEvent.getRoomId()) {
            if (roomStateEvent.getType() === 'citadel.conference') {
                this.setState({
                    callInProgressType: getCallInProgressType(room),
                });
            }
            this.setConferenceParticipantListIfNeeded(roomStateEvent, room);
        }
    };

    setConferenceParticipantListIfNeeded = async (roomStateEvent, room) => {
        const { callInProgressType } = this.state;
        if (
            callInProgressType &&
            roomStateEvent.getType() === 'm.room.member' &&
            ['invite', 'join', 'leave'].includes(roomStateEvent.getContent().membership)
        ) {
            const callInProgress = getCallInProgress(room);
            const myId = MatrixClientPeg.get().credentials.userId;
            if (callInProgress.inviter_id === myId) {
                try {
                    // TODO: get members from room.currentState after it will be fixed
                    // TODO: currently we are using a hot fix on matrix-js-sdk commit: 713114dedfdedd458119db98f164b1b2f5ed1bca
                    const allRoomMembers = await room._loadMembersFromServer();

                    const allMembersIds = (allRoomMembers ? allRoomMembers.map(({ state_key: userId }) => userId) : [])
                        .sort()
                        .filter((id, idx, list) => !idx || id !== list[idx - 1]);

                    if (!isEqual(callInProgress.attendees_id, allMembersIds)) {
                        this.setConferenceParticipantList(allMembersIds, callInProgress);
                    }
                } catch (e) {
                    CitadexStore.error('can not update conference members: ', e);
                }
            }
        }
    }

    setConferenceParticipantList = debounce((participants, callInProgress) => {
        const { room_janus_id: broadcastId, inviter_id: inviterId } = callInProgress;
        CitadexStore.updateParticipantList(participants, inviterId, broadcastId);
    }, 500);

    onAccountData = (accountDataEvent) => {
        if (accountDataEvent.getType() === 'm.push_rules') {
            this.setState({
                notifState: RoomNotifs.getRoomNotifsState(this.props.room.roomId),
            });
        }
    };

    onAction = (payload) => {
        switch (payload.action) {
            // XXX: slight hack in order to zero the notification count when a room
            // is read. Ideally this state would be given to this via props (as we
            // do with `unread`). This is still better than forceUpdating the entire
            // RoomList when a room is read.
            case 'on_room_read':
                if (payload.roomId !== this.props.room.roomId) break;
                this.setState({
                    notificationCount: this.props.room.getUnreadNotificationCount(),
                });
                break;
            // RoomTiles are one of the few components that may show custom status and
            // also remain on screen while in Settings toggling the feature.  This ensures
            // you can clearly see the status hide and show when toggling the feature.
            case 'feature_custom_status_changed':
            case 'room_export_start':
            case 'room_export_cancel_success':
            case 'room_export_success':
                this.forceUpdate();
                break;
        }
    };

    _onActiveRoomChange = () => {
        this.setState({
            selected: this.props.room.roomId === RoomViewStore.getRoomId(),
        });
    };

    componentDidMount() {
        const cli = MatrixClientPeg.get();
        cli.on('RoomState.events', this.onRoomStateEvents);
        cli.on("accountData", this.onAccountData);
        cli.on("Room.name", this.onRoomName);
        window.addEventListener('storage', this.trackE2eKeySharing);
        this.trackE2eKeySharing();
        ActiveRoomObserver.addListener(this.props.room.roomId, this._onActiveRoomChange);
        this.dispatcherRef = dis.register(this.onAction);

        if (this._shouldShowStatusMessage()) {
            const statusUser = this._getStatusMessageUser();
            if (statusUser) {
                statusUser.on(
                    "User._unstable_statusMessage",
                    this._onStatusMessageCommitted,
                );
            }
        }
    }

    componentWillUnmount() {
        const cli = MatrixClientPeg.get();
        if (cli) {
            cli.removeListener('RoomSate.events', this.onRoomStateEvents);
            cli.removeListener("accountData", this.onAccountData);
            cli.removeListener("Room.name", this.onRoomName);
        }
        ActiveRoomObserver.removeListener(this.props.room.roomId, this._onActiveRoomChange);
        dis.unregister(this.dispatcherRef);
        window.removeEventListener('storage', this.trackE2eKeySharing);

        if (this._shouldShowStatusMessage()) {
            const statusUser = this._getStatusMessageUser();
            if (statusUser) {
                statusUser.removeListener(
                    "User._unstable_statusMessage",
                    this._onStatusMessageCommitted,
                );
            }
        }
    }

    componentDidUpdate(prevProps) {
        // XXX: This could be a lot better - this makes the assumption that
        // the notification count may have changed when the properties of
        // the room tile change.
        if (!isEqual(prevProps.room, this.props.room)) {
            this.setState({
                notificationCount: this.props.room.getUnreadNotificationCount(),
            });
        }
    }

    trackE2eKeySharing = () => {
        const e2eKeySharedList = JSON.parse(localStorage.getItem("e2eKeySharedList")) || {};
        if (e2eKeySharedList &&
            e2eKeySharedList[this.props.room.roomId]?.isKeySent ||
            e2eKeySharedList[this.props.room.roomId]?.isKeyDenied) {
            this.setState({ isKeyNotificationHidden: true });
        } else {
            this.setState({ isKeyNotificationHidden: false });
        }
    }

    // Do a simple shallow comparison of props and state to avoid unnecessary
    // renders. The assumption made here is that only state and props are used
    // in rendering this component and children.
    //
    // RoomList is frequently made to forceUpdate, so this decreases number of
    // RoomTile renderings.
    shouldComponentUpdate(newProps, newState) {
        if (Object.keys(newProps).some((k) => newProps[k] !== this.props[k])) {
            return true;
        }
        if (Object.keys(newState).some((k) => newState[k] !== this.state[k])) {
            return true;
        }
        return false;
    }

    _onStatusMessageCommitted = () => {
        // The status message `User` object has observed a message change.
        this.setState({
            statusMessage: this._getStatusMessage(),
        });
    };

    onClick = (ev) => {
        if (this.props.onClick) {
            NewsStore.showLeaveDialogIfNeeded(() => this.props.onClick(this.props.room.roomId, ev));
        }
    };

    onMouseEnter = () => {
        this.setState( { hover: true });
        this.badgeOnMouseEnter();
    };

    onMouseLeave = () => {
        this.setState( { hover: false });
        this.badgeOnMouseLeave();
    };

    _showContextMenu = (x, y, chevronOffset) => {
        const RoomTileContextMenu = sdk.getComponent('context_menus.RoomTileContextMenu');

        createMenu(RoomTileContextMenu, {
            chevronOffset,
            left: x,
            top: y,
            room: this.props.room,
            onFinished: () => {
                this.setState({ menuDisplayed: false });
                this.props.refreshSubList();
            },
        });
        this.setState({ menuDisplayed: true });
    };

    onContextMenu = (e) => {
        // Prevent the RoomTile onClick event firing as well
        e.preventDefault();
        // Only allow non-guests to access the context menu
        if (MatrixClientPeg.get().isGuest() || this.props.hideContextMenuButton) return;

        const chevronOffset = 12;
        this._showContextMenu(e.clientX, e.clientY - (chevronOffset + 8), chevronOffset);
    };

    badgeOnMouseEnter = () => {
        // Only allow non-guests to access the context menu
        // and only change it if it needs to change
        if (!MatrixClientPeg.get().isGuest() && !this.state.badgeHover) {
            this.setState( { badgeHover: true } );
        }
    };

    badgeOnMouseLeave = () => {
        this.setState( { badgeHover: false } );
    };

    onCallIconClick = (e) => {
        // Prevent the RoomTile onClick event firing as well
        e.stopPropagation();
        if (CitadexStore.getMatrixRoomId() === this.props.room.roomId && SettingsStore.getValue('Conference.openConferenceInTab')) {
            CitadexStore.focusConferenceWindow();
        }
        document.activeElement.blur();
    }
    onOpenMenu = (e) => {
        // Prevent the RoomTile onClick event firing as well
        e.stopPropagation();
        // Only allow non-guests to access the context menu
        if (MatrixClientPeg.get().isGuest()) return;

        // If the badge is clicked, then no longer show tooltip
        if (this.props.collapsed) {
            this.setState({ hover: false });
        }

        const elementRect = e.target.getBoundingClientRect();

        // The window X and Y offsets are to adjust position when zoomed in to page
        const x = elementRect.right + window.pageXOffset + 3;
        const chevronOffset = 12;
        let y = (elementRect.top + (elementRect.height / 2) + window.pageYOffset);
        y = y - (chevronOffset + 8); // where 8 is half the height of the chevron

        this._showContextMenu(x, y, chevronOffset);
    };

    state = {
        hover: false,
        badgeHover: false,
        menuDisplayed: false,
        roomName: this.props.room.name,
        notifState: RoomNotifs.getRoomNotifsState(this.props.room.roomId),
        notificationCount: this.props.room.getUnreadNotificationCount(),
        selected: this.props.room.roomId === RoomViewStore.getRoomId(),
        statusMessage: this._getStatusMessage(),
        callInProgressType: getCallInProgressType(this.props.room),
    };

    render() {
        const { room, notificationCount, unread, transparent, collapsed, hideContextMenuButton } = this.props;
        const { callInProgressType, statusMessage, selected, menuDisplayed, badgeHover, roomName, hover } = this.state;

        const isInvite = room.getMyMembership() === "invite";

        const notifBadges = notificationCount > 0 && this._shouldShowNotifBadge() ||
            (this.props.hasE2eKeyNotification && !this.state.isKeyNotificationHidden);
        const mentionBadges = this.props.highlight && this._shouldShowMentionBadge();
        const badges = notifBadges || mentionBadges;

        let subtext = null;
        if (this._shouldShowStatusMessage()) {
            subtext = statusMessage;
        }

        const classes = classNames({
            'mx_RoomTile': true,
            'mx_RoomTile_selected': selected,
            'mx_RoomTile_unread': unread,
            'mx_RoomTile_unreadNotify': notifBadges,
            'mx_RoomTile_highlight': mentionBadges,
            'mx_RoomTile_invited': isInvite,
            'mx_RoomTile_menuDisplayed': menuDisplayed,
            'mx_RoomTile_noBadges': !badges,
            'mx_RoomTile_transparent': transparent,
            'mx_RoomTile_hasSubtext': subtext && !collapsed,
        });

        const avatarClasses = 'mx_RoomTile_avatar';

        const badgeClasses = classNames({
            'mx_RoomTile_badge': true,
            'mx_RoomTile_badgeButton': badgeHover || menuDisplayed,
            'mx_RoomTile_e2eBadge': this.props.hasE2eKeyNotification,
        });

        const { isDmRoom, dmUserIdOrEmail } = isDirectMessageRoom(room);
        let name = (isDmRoom && dmUserIdOrEmail[0] === "@") ?
            UserStore.getDisambiguatedNameWithEmail(dmUserIdOrEmail, roomName) : roomName || '';
        name = name.replace(":", ":\u200b"); // add a zero-width space to allow linewrapping after the colon

        let badge;
        if (badges) {
            const limitedCount = FormattingUtils.formatCount(notificationCount);
            const badgeContent = notificationCount && !this.props.hasE2eKeyNotification ? limitedCount : '!';
            badge = <div className={badgeClasses}>{ badgeContent }</div>;
        }

        const EmojiText = sdk.getComponent('elements.EmojiText');
        let label;
        let subtextLabel;
        let tooltip;
        if (!collapsed) {
            const nameClasses = classNames({
                'mx_RoomTile_name': true,
                'mx_RoomTile_invite': this.props.isInvite,
                'mx_RoomTile_badgeShown': badges || badgeHover || menuDisplayed,
            });

            subtextLabel = subtext ? <span className="mx_RoomTile_subtext">{ subtext }</span> : null;

            if (selected) {
                const nameSelected = <EmojiText>{ name }</EmojiText>;
                label = <div title={name} className={nameClasses} dir="auto">{ nameSelected }</div>;
            } else {
                label = <EmojiText element="div" title={name} className={nameClasses} dir="auto">{ name }</EmojiText>;
            }
        } else if (hover) {
            const Tooltip = sdk.getComponent("elements.Tooltip");
            tooltip = <Tooltip className="mx_RoomTile_tooltip" label={room.name} dir="auto" />;
        }

        let contextMenuButton;
        if (!MatrixClientPeg.get().isGuest() && !hideContextMenuButton) {
            contextMenuButton = <AccessibleButton className="mx_RoomTile_menuButton" onClick={this.onOpenMenu} />;
        }

        const RoomAvatar = sdk.getComponent('avatars.RoomAvatar');
        const e2eStatus = MatrixClientPeg.get().isRoomEncrypted(room.roomId);
        const hasCallInProgress = CitadexStore.getMatrixRoomId() === room.roomId;
        const roomExportStatus = RoomExportStore.getRoomExportStatus(room.roomId);
        const roomExportInProgress = ['started', 'pending', 'completed'].includes(roomExportStatus);
        const isExportCompleted = roomExportStatus === 'completed';
        let roomExportIcon = roomExportInProgress && (
            <div className={classNames('export_room_icon', { done: isExportCompleted })} />
        );
        if (isExportCompleted && selected) roomExportIcon = null;

        return <AccessibleButton tabIndex="0"
                                 className={classes}
                                 onClick={this.onClick}
                                 onMouseEnter={this.onMouseEnter}
                                 onMouseLeave={this.onMouseLeave}
                                 onContextMenu={this.onContextMenu}
        >
            <div className={avatarClasses}>
                <div className="mx_RoomTile_avatar_container">
                    <RoomAvatar room={room} width={24} height={24} />
                    { e2eStatus && <div className="lock" />}
                </div>
            </div>
            <div className="mx_RoomTile_nameContainer">
                <div className="mx_RoomTile_labelContainer">
                    { label }
                    { subtextLabel }
                </div>
                { contextMenuButton }
                { roomExportIcon }
                { callInProgressType && <div className={
                    classNames(
                        'mx_RoomTile_conference_button',
                        { callInProgress: CitadexStore.getMatrixRoomId() === this.props.room.roomId})
                }>
                    <img
                        alt={callInProgressType}
                        onClick={this.onCallIconClick}
                        src={
                            callInProgressType === 'video'
                            ? hasCallInProgress ? videoIconBlue : videoIconGrey
                            : hasCallInProgress ? audioIconBlue : audioIconGrey
                        }
                    />
                </div>}
                { badge }
            </div>
            { tooltip }
        </AccessibleButton>;
    }
};
