import tinymce from 'tinymce';

export const iconPackName = 'citadel-icon-pack';
export const iconPack = tinymce.IconManager.add(iconPackName, {
    icons: {
        'align-center': '<svg width="24" height="16" viewBox="0 0 24 16"><g fill="none" fill-rule="evenodd"><rect width="23" height="15" x=".5" y=".5" stroke="#737576" rx="7.5"/><path fill="#737576" fill-rule="nonzero" d="M16 10.5v1H8v-1h8zm-2-3v1h-4v-1h4zm2-3v1H8v-1h8z"/></g></svg>',
        'align-justify': '<svg width="24" height="16" viewBox="0 0 24 16"><g fill="none" fill-rule="evenodd"><rect width="23" height="15" x=".5" y=".5" stroke="#737576" rx="7.5"/><path fill="#737576" fill-rule="nonzero" d="M16 10.5v1H8v-1h8zm0-3v1H8v-1h8zm0-3v1H8v-1h8z"/></g></svg>',
        'align-left': '<svg width="24" height="16" viewBox="0 0 24 16"><g fill="none" fill-rule="evenodd"><rect width="23" height="15" x=".5" y=".5" stroke="#737576" rx="7.5"/><path fill="#737576" fill-rule="nonzero" d="M15 10.5v1H8v-1h7zm-3-3v1H8v-1h4zm4-3v1H8v-1h8z"/></g></svg>',
        'align-right': '<svg width="24" height="16" viewBox="0 0 24 16"><g fill="none" fill-rule="evenodd"><rect width="23" height="15" x=".5" y=".5" stroke="#737576" rx="7.5"/><path fill="#737576" fill-rule="nonzero" d="M9 10.5v1h7v-1H9zm3-3v1h4v-1h-4zm-4-3v1h8v-1H8z"/></g></svg>',
        'bold': '<svg width="24" height="16" viewBox="0 0 24 16"><g fill="none" fill-rule="evenodd"><rect width="23" height="15" x=".5" y=".5" stroke="#737576" rx="7.5"/><path fill="#737576" fill-rule="nonzero" d="M12.653 6.289c.479 0 .901.116 1.268.348.366.232.652.56.858.987.206.426.309.924.309 1.492 0 .569-.103 1.072-.309 1.51-.206.437-.494.776-.864 1.015-.37.24-.79.359-1.262.359-.397 0-.75-.086-1.06-.258-.31-.172-.548-.411-.713-.718v.886H9.5V4h1.403v3.22c.164-.299.4-.529.706-.69.307-.16.655-.241 1.044-.241zm-.37 4.623c.44 0 .785-.157 1.032-.472.247-.314.37-.755.37-1.324 0-.56-.121-.99-.365-1.29-.243-.3-.589-.449-1.037-.449-.45 0-.795.152-1.038.455-.243.303-.365.738-.365 1.307 0 .568.122 1.006.365 1.313.243.306.589.46 1.038.46z"/></g>',
        'indent': '<svg width="24" height="16" viewBox="0 0 24 16"><g fill="none" fill-rule="evenodd"><rect width="23" height="15" x=".5" y=".5" stroke="#737576" rx="7.5"/><path fill="#737576" fill-rule="nonzero" d="M16 10.5H8v1h8v-1zm-5-2v1h5v-1h-5zm-1.414-2L8.172 7.914l1.414 1.414V6.5zM11 6.5v1h5v-1h-5zm-3-2v1h8v-1H8z"/></g></svg>',
        'italic': '<svg width="24" height="16" viewBox="0 0 24 16"><g fill="none" fill-rule="evenodd"><rect width="23" height="15" x=".5" y=".5" stroke="#737576" rx="7.5"/><path fill="#737576" fill-rule="nonzero" d="M12.736 4.738c0 .198-.074.371-.22.518-.147.146-.32.22-.518.22-.199 0-.371-.074-.518-.22-.147-.147-.22-.32-.22-.518 0-.199.073-.371.22-.518.147-.147.32-.22.518-.22s.371.073.518.22c.146.147.22.32.22.518zM12.632 12h-1.255V6.887h1.255V12z"/></g></svg>',
        'link': '<svg width="24" height="16" viewBox="0 0 24 16"><g fill="none" fill-rule="evenodd"><rect width="23" height="15" x=".5" y=".5" stroke="#737576" rx="7.5"/><path fill="#737576" fill-rule="nonzero" d="M8.934 4.017l1.837.02c.297 0 .52.225.52.526 0 .302-.223.527-.52.527l-1.837-.018c-.78 0-1.484.3-2.041.866-.557.564-.854 1.28-.854 2.07 0 1.62 1.3 2.938 2.895 2.938h1.819c.297 0 .52.226.52.527 0 .301-.223.527-.52.527H8.934c-1.076 0-2.078-.452-2.783-1.167C5.445 10.117 5 9.1 5 8.009c0-1.055.408-2.071 1.15-2.824.743-.754 1.745-1.168 2.784-1.168zM13.247 4l1.831.019C17.224 4.019 19 5.82 19 7.997c0 1.051-.407 2.065-1.147 2.815-.74.751-1.739 1.164-2.775 1.164h-1.85c-.147 0-.277-.056-.37-.15-.092-.094-.147-.225-.147-.376 0-.3.222-.525.518-.525l1.83.019c.76-.02 1.5-.32 2.054-.882.555-.563.851-1.276.851-2.065 0-1.614-1.295-2.927-2.886-2.927l-1.83-.02c-.297 0-.519-.224-.519-.525 0-.3.222-.525.518-.525zm-4.09 3.468l5.406.002c.304 0 .533.232.533.54 0 .31-.247.56-.571.541L9.158 8.55c-.153 0-.286-.058-.381-.155-.095-.097-.152-.232-.152-.386 0-.31.228-.54.532-.54z"/></g></svg>',
        'outdent': '<svg width="24" height="16" viewBox="0 0 24 16"><g fill="none" fill-rule="evenodd"><rect width="23" height="15" x=".5" y=".5" stroke="#737576" rx="7.5"/><path fill="#737576" fill-rule="nonzero" d="M8 10.5h8v1H8v-1zm5-2v1H8v-1h5zm1.414-2l1.414 1.414-1.414 1.414V6.5zM13 6.5v1H8v-1h5zm3-2v1H8v-1h8z"/></g></svg>',
        'redo': '<svg width="24" height="16" viewBox="0 0 24 16"><g fill="none" fill-rule="evenodd"><rect width="23" height="15" x=".5" y=".5" stroke="#737576" rx="7.5"/><path fill="#737576" d="M8.036 5.536L13.036 10.536 8.036 10.536z" transform="rotate(-135 10.536 8.036)"/></g></svg>',
        'sourcecode': '<svg width="24" height="16" viewBox="0 0 24 16"><g fill="none" fill-rule="evenodd"><rect width="23" height="15" x=".5" y=".5" stroke="#737576" rx="7.5"/><path fill="#737576" fill-rule="nonzero" d="M6.119 7.871l3.246 1.95v.802l-.151.087L5 8.172l.05-.742L9.214 5l.151.086-.049.872L6.12 7.871zM14.134 4l.093.136-3.413 7.805-.092.059h-.819l-.092-.136 3.396-7.804.092-.06h.835zm.501 1.873v-.787L14.786 5 19 7.516v.656l-.05.086-4.164 2.452-.151-.087.05-.889L17.88 7.87l-3.246-1.996z"/></g></svg>',
        'strike-through': '<svg width="24" height="16" viewBox="0 0 24 16"><g fill="none" fill-rule="evenodd"><rect width="23" height="15" x=".5" y=".5" stroke="#737576" rx="7.5"/><path fill="#737576" fill-rule="nonzero" d="M12.136 4.5c.439 0 .857.076 1.253.228.396.152.728.371.995.657l-.35.817c-.576-.526-1.21-.789-1.898-.789-.44 0-.782.09-1.027.27-.246.18-.369.431-.369.754 0 .267.076.482.226.643.151.161.394.284.73.367l.43.11H17v.923l-2.854-.001.028.032c.217.282.326.633.326 1.052 0 .59-.222 1.06-.665 1.411-.444.35-1.049.526-1.816.526-1.06 0-1.899-.29-2.519-.872l.349-.83c.336.286.678.492 1.027.616.349.125.743.187 1.182.187.465 0 .82-.086 1.066-.256.246-.17.368-.417.368-.74 0-.268-.082-.48-.245-.637-.164-.156-.435-.28-.814-.373l-.466-.116H7v-.922h3.055l-.009-.01c-.218-.29-.326-.638-.326-1.041 0-.6.22-1.084.659-1.453.439-.369 1.025-.553 1.757-.553z"/></g></svg>',
        'table': '<svg width="24" height="16" viewBox="0 0 24 16"><g fill="none" fill-rule="evenodd"><rect width="23" height="15" x=".5" y=".5" stroke="#737576" rx="7.5"/><path fill="#A7A9A9" d="M16.621 5.621L19.621 8.621 16.621 8.621z" transform="rotate(-45 18.121 7.121)"/><path fill="#737576" fill-rule="nonzero" d="M5 11.5v-7h8v7H5zm2.25-2H6v1h1.25v-1zm2.5 0h-1.5v1h1.5v-1zm2.25 0h-1.25v1H12v-1zM7.25 7.499H6V8.5h1.25V7.499zm1 0V8.5h1.5V7.499h-1.5zm2.5.001v1H12v-1h-1.25zm1.25-2H6v.999l6 .001v-1z"/></g></svg>',
        'underline': '<svg width="24" height="16" viewBox="0 0 24 16"><g fill="none" fill-rule="evenodd"><rect width="23" height="15" x=".5" y=".5" stroke="#737576" rx="7.5"/><path fill="#737576" fill-rule="nonzero" d="M8 11.199h8V12H8v-.801zm4-.918c-1.007 0-1.81-.26-2.406-.782l-.025-.116.324-.721.153-.037c.303.241.611.414.924.518.313.104.668.157 1.066.157.414 0 .726-.07.935-.206.2-.13.297-.312.297-.56 0-.204-.065-.361-.195-.478-.137-.123-.374-.224-.712-.301l-.983-.228c-.514-.117-.911-.318-1.19-.605-.282-.292-.424-.653-.424-1.08 0-.55.218-1 .65-1.34.427-.335.993-.502 1.694-.502.42 0 .819.068 1.198.204.381.137.702.335.96.594l.021.113-.324.709-.156.034c-.518-.441-1.083-.66-1.699-.66-.39 0-.688.073-.897.216-.201.138-.299.325-.299.573 0 .206.06.367.18.488.126.125.335.223.631.292l.996.24c.56.132.98.334 1.26.609.283.28.425.644.425 1.087 0 .544-.221.983-.659 1.306-.43.318-1.013.476-1.745.476z"/></g></svg>',
        'undo': '<svg width="24" height="16" viewBox="0 0 24 16"><g fill="none" fill-rule="evenodd"><rect width="23" height="15" x=".5" y=".5" stroke="#737576" rx="7.5"/><path fill="#737576" d="M11.036 5.536L16.036 10.536 11.036 10.536z" transform="scale(1 -1) rotate(45 32.935 0)"/></g></svg>',
    },
});
