/*
Copyright 2015, 2016 OpenMarket Ltd
Copyright 2017 Vector Creations Ltd
Copyright 2017, 2018 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import * as Matrix from 'matrix-js-sdk';
import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import cx from 'classnames';
import { isEmpty, noop } from 'lodash';
import { KeyCode, isOnlyCtrlOrCmdKeyEvent } from '../../Keyboard';
import PageTypes from '../../PageTypes';
import CallMediaHandler from '../../CallMediaHandler';
import sdk from '../../index';
import dis from '../../dispatcher';
import sessionStore from '../../stores/SessionStore';
import MatrixClientPeg from '../../MatrixClientPeg';
import SettingsStore from "../../settings/SettingsStore";
import RoomListStore from "../../stores/RoomListStore";
import { getHomePageUrl } from '../../utils/pages';
import CitadexStore from '../../stores/CitadexStore';
// eslint-disable-next-line no-unused-vars
import RoomExportStore from '../../stores/RoomExportStore';

import TagOrderActions from '../../actions/TagOrderActions';
import RoomListActions from '../../actions/RoomListActions';
import {Resizer, CollapseDistributor} from '../../resizer';
// We need to fetch each pinned message individually (if we don't already have it)
// so each pinned message may trigger a request. Limit the number per room for sanity.
// NB. this is just for server notices rather than pinned messages in general.
const MAX_PINNED_NOTICES_PER_ROOM = 2;
const RETRY_TIMEOUT_NETWORK_RECONNECT_CONFERENCE = 10000; // 10 seconds

import { getCurrentTermsVersion, getAcceptedTermsVersion, updateTerms } from '../../utils/CitadelRequests';
import Modal from '../../Modal';
import NewVersionDialog from '../views/dialogs/NewVersionDialog';
import PlatformPeg from '../../PlatformPeg';
import RoomView from '../structures/RoomView';
import MfaMandatoryChecker from '../views/dialogs/mfa/MfaMandatoryChecker';
import ForwardForbiddenDialog from "../views/dialogs/ForwardForbiddenDialog";
import CitadexPage from '../views/janus/CitadexPage';
import {_t} from "../../languageHandler";
import { ARROW_POS, HintedComponent } from "../views/elements/HintedComponent";
import Toast from "../views/toast";
import PromoteNewFeatures from '../../PromoteNewFeatures';
import BackendMaintenanceMessages from '../views/globals/BackendMaintenanceMessages';

import newFeatureDataDC from '../../../res/newFeaturesData/NewFeaturesDataDC.json';
import newFeatureDataWC from '../../../res/newFeaturesData/NewFeaturesDataWC.json';
import { displayVersionObsoleteDialogIfNeeded } from '../views/elements/SupportedVersionCheck';
import { errorDialog, ERROR_TYPES } from '../views/janus/ErrorDialogsHelper';

/**
 * This is what our MatrixChat shows when we are logged in. The precise view is
 * determined by the page_type property.
 *
 * Currently it's very tightly coupled with MatrixChat. We should try to do
 * something about that.
 *
 * Components mounted below us can access the matrix client via the react context.
 */

const MAX_RETRIES = 5;

class LoggedInView extends React.Component {
    static displayName = 'LoggedInView';

    static propTypes = {
        matrixClient: PropTypes.instanceOf(Matrix.MatrixClient).isRequired,
        page_type: PropTypes.string.isRequired,
        onRoomCreated: PropTypes.func,

        // Called with the credentials of a registered user (if they were a ROU that
        // transitioned to PWLU)
        onRegistered: PropTypes.func,
        collapsedRhs: PropTypes.bool,

        // Used by the RoomView to handle joining rooms
        viaServers: PropTypes.arrayOf(PropTypes.string),

        // and lots and lots of other stuff.
    };

    static childContextTypes = {
        matrixClient: PropTypes.instanceOf(Matrix.MatrixClient),
        authCache: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            forwardForbiddenVisibility: false,
            // use compact timeline view
            useCompactLayout: SettingsStore.getValue('useCompactLayout'),
            // any currently active server notice events
            serverNoticeEvents: [],
            isHPS: false,
            displayConference: false,
            isMinimizedHintDisplayed: false,
            isConferenceOpen: false,
            infraMessageExists: false,
            citadexKey: 0,
            isOffline: false,
            lastCitadexIndexKey: 0,
            retryCount: 0,
            isConferencePreview: true,
            isConferenceAudioEnabled: true,
        };
        // stash the MatrixClient in case we log out before we are unmounted
        this._matrixClient = this.props.matrixClient;

        CallMediaHandler.loadDevices();

        document.addEventListener('keydown', this._onKeyDown);

        this._sessionStore = sessionStore;
        this.retryProcessStarted = false;
    }

    getChildContext() {
        return {
            matrixClient: this._matrixClient,
            authCache: {
                auth: {},
                lastUpdate: 0,
            },
        };
    }

    async componentDidMount() {
        const { hasNewVersion, isHelpOpen } = this.props;
        this._sessionStoreToken = this._sessionStore.addListener(
            this._setStateFromSessionStore,
        );
        this._setStateFromSessionStore();

        this._updateServerNoticeEvents();

        this._matrixClient.on("accountData", this.onAccountData);
        this._matrixClient.on("sync", this.onSync);
        this._matrixClient.on("RoomState.events", this.onRoomStateEvents);

        window.addEventListener('storage', this.onStorage);

        this.dispatcherRef = dis.register(this.onAction);

        this.checkTermsOfUse();
        this.termsCheckup = setInterval(() => {
            this.checkTermsOfUse();
        }, 12 * 60 * 60 * 1000);

        this.resizer = this._createResizer();
        this.resizer.attach();
        this._loadResizerPreferences();
        hasNewVersion && !isHelpOpen && this.displayUpdateDialog();
        this.setHPS();

        const isMinimizedHintDisplayed = !MatrixClientPeg.get().getInAppMessageFlag('has_shown_conference_minimized');
        this.setState({ isMinimizedHintDisplayed });
        SettingsStore.monitorSetting('enabled_public_read_only_rooms', null);
        SettingsStore.monitorSetting('enabled_private_rooms', null);

        this.displayPromoteNewFeatures();
        displayVersionObsoleteDialogIfNeeded();
        this.obsoleteVersionCheckup = setInterval(() => {
            displayVersionObsoleteDialogIfNeeded();
        }, 12 * 60 * 60 * 1000);
    }

    componentDidUpdate(prevProps) {
        const { showCookieBar, userHasGeneratedPassword,
            showNotifierToolbar, resizeNotifier, hasNewVersion, isHelpOpen } = this.props;

        // attempt to guess when a banner was opened or closed
        if (
            (prevProps.showCookieBar !== showCookieBar) ||
            (prevProps.userHasGeneratedPassword !== userHasGeneratedPassword) ||
            (prevProps.showNotifierToolbar !== showNotifierToolbar)
        ) {
            resizeNotifier.notifyBannersChanged();
        }

        if (prevProps.hasNewVersion !== hasNewVersion &&
            hasNewVersion && !isHelpOpen) {
            this.displayUpdateDialog();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this._onKeyDown);
        this._matrixClient.removeListener("accountData", this.onAccountData);
        this._matrixClient.removeListener("sync", this.onSync);
        this._matrixClient.removeListener("RoomState.events", this.onRoomStateEvents);
        window.removeEventListener('storage', this.onStorage);
        if (this._sessionStoreToken) {
            this._sessionStoreToken.remove();
        }
        this.resizer.detach();
        dis.unregister(this.dispatcherRef);
        if (this.termsCheckup) clearInterval(this.termsCheckup);
        if (this.obsoleteVersionCheckup) clearInterval(this.obsoleteVersionCheckup);
    }

    // Child components assume that the client peg will not be null, so give them some
    // sort of assurance here by only allowing a re-render if the client is truthy.
    //
    // This is required because `LoggedInView` maintains its own state and if this state
    // updates after the client peg has been made null (during logout), then it will
    // attempt to re-render and the children will throw errors.
    shouldComponentUpdate() {
        return Boolean(MatrixClientPeg.get());
    }

    onStorage(event) {
        if (event.key === 'mx_access_token' && MatrixClientPeg.get().getAccessToken() !== event.newValue) {
            MatrixClientPeg.get().setAccessToken(event.newValue);
        }
    }

    setHPS = async () => {
        const cli = MatrixClientPeg.get();
        const { hps } = await cli.getServerConfig();
        this.setState({ isHPS: !!hps });
    }

    onAction = (payload) => {
        if (payload.action === 'audio-toggle') {
            this.setState({
                isConferenceAudioEnabled: payload.value,
            });
        }
        if (payload.action === 'toggle-preview-conference') {
            this.setState({ isConferencePreview: payload.value });
        }
        if (payload.action === 'start_retry_process') {
            if (payload.value && CitadexStore.getIsCitadexOpened()) {
                this.startRetryProcess();
                this.retryProcessStarted = true;
                this.setState({ isOffline: true });
            } else {
                this.setState({ isOffline: false });
            }
        }

        if (payload.action === 'reset-retry-process') {
            this.resetRetryProcess(payload.intentional);
        }
        if (payload.action === 'forward_forbidden') {
            const { forwardForbiddenVisibility } = this.state;
            if (!forwardForbiddenVisibility) {
                this.setState({ forwardForbiddenVisibility: true }, () =>{
                    Modal.createTrackedDialog(
                        '',
                        '',
                        ForwardForbiddenDialog,
                        { onFinished: this.onForwardForbiddenClose },
                        'forward_forbidden_dialog',
                    );
                });
            }
        }
        const conferenceInNewTab = SettingsStore.getValue("Conference.openConferenceInTab");
        if (payload.action === 'toggle_conference' && !conferenceInNewTab) {
            this.setState({ displayConference: payload.isOpen });
        }
        // this part is to handle electron quit event coming from ElectronPlatform
        if (payload.action === 'toggle_conference' && !conferenceInNewTab) {
            if (!payload.isOpen && this.state.displayConference) {
                CitadexStore.destroySession();
            }
            if (!payload.isOpen) {
                CitadexStore.setIsCitadexOpened(false);
            }
        }

        if (payload.action === 'maximize_conference') {
            this.setState({ isMaximized: payload.isMaximized });
        }
        if (payload.action === 'minimize_conference') {
            this.setState({ isMinimized: payload.isMinimized });
        }
        if (payload.action === 'open_new_features') {
            this.displayPromoteNewFeatures(true);
        }
    }

    displayPromoteNewFeatures = (forceDisplay) => {
        const platform = PlatformPeg.get();
        const newFeatureData = platform.isDesktop() ? newFeatureDataDC : newFeatureDataWC;
        const PromoteNewFeaturesClass = new PromoteNewFeatures(newFeatureData);
        PromoteNewFeaturesClass.showIfNeeded(forceDisplay);
    }

    onForwardForbiddenClose = () => this.setState({ forwardForbiddenVisibility: false });

    checkTermsOfUse = () => {
      getCurrentTermsVersion()
      .then(res => {
        if (res.version) {
          const currentVersion = res.version;
          getAcceptedTermsVersion()
          .then(res => {
            if (res.version) {
              const acceptedVersion = res.version;
              if (currentVersion !== acceptedVersion) {
                this.displayTerms(currentVersion);
              }
            } else {
              console.error(`Checking Terms of Use: accepted version is not valid`, res);
            }
          })
          .catch(err => {
            console.error(`Checking Terms of Use: couldn't fetch accepted version`, err);
          });
        } else {
          console.error(`Checking Terms of Use: current version is not valid`, res);
        }
      })
      .catch(err => {
        console.error(`Checking Terms of Use: couldn't fetch current version`, err);
      });
    };

    displayTerms = (currentVersion) => {
      const TermsOfUseDialog = sdk.getComponent('dialogs.TermsOfUseDialog');
      const homeserverUrl = localStorage.getItem('mx_hs_url');
      Modal.createTrackedDialog('Display Terms of Use', '', TermsOfUseDialog, {
        onFinished: termsAreAccepted => {
          if (termsAreAccepted) {
            updateTerms(currentVersion)
            .then(() => {})
            .catch(err => {
              console.error(`Updating Terms of Use: something went wrong!`, err);
            });
          }
        },
        homeserverUrl,
      });
    };

    displayUpdateDialog = () => {
        if (this.obsoleteVersionCheckup) clearInterval(this.obsoleteVersionCheckup);
        const className = 'mx_NewVersionDialog';
        Modal.createDialog(NewVersionDialog, {
            className,
            onFinished: () => {
                PlatformPeg.get().installUpdate();
            },
        }, className);
    };

    canResetTimelineInRoom = (roomId) => {
        if (!this.refs.roomView) {
            return true;
        }
        return this.refs.roomView.canResetTimeline();
    };

    _setStateFromSessionStore = () => {
        this.setState({
            userHasGeneratedPassword: Boolean(this._sessionStore.getCachedPassword()),
        });
    };

    _createResizer = () => {
        const classNames = {
            handle: "mx_ResizeHandle",
            vertical: "mx_ResizeHandle_vertical",
            reverse: "mx_ResizeHandle_reverse",
        };
        const collapseConfig = {
            toggleSize: 260 - 50,
            onCollapsed: (collapsed) => {
                if (collapsed) {
                    dis.dispatch({action: "hide_left_panel"}, true);
                    window.localStorage.setItem("mx_lhs_size", '0');
                } else {
                    dis.dispatch({action: "show_left_panel"}, true);
                }
            },
            onResized: (size) => {
                window.localStorage.setItem("mx_lhs_size", '' + size);
                this.props.resizeNotifier.notifyLeftHandleResized();
            },
        };
        const resizer = new Resizer(
            this.resizeContainer,
            CollapseDistributor,
            collapseConfig);
        resizer.setClassNames(classNames);
        return resizer;
    };

    _loadResizerPreferences = () => {
        let lhsSize = window.localStorage.getItem("mx_lhs_size");
        if (lhsSize !== null) {
            lhsSize = parseInt(lhsSize, 10);
        } else {
            lhsSize = 350;
        }
        if (this.resizer.forHandleAt(0)) {
            this.resizer.forHandleAt(0).resize(lhsSize);
        }
    };

    onAccountData = (event) => {
        if (event.getType() === "im.vector.web.settings") {
            this.setState({
                useCompactLayout: event.getContent().useCompactLayout,
            });
        }
        if (event.getType() === "m.ignored_user_list") {
            dis.dispatch({action: "ignore_state_changed"});
        }
    };

    onSync = (syncState, oldSyncState, data) => {
        const oldErrCode = (
            this.state.syncErrorData &&
            this.state.syncErrorData.error &&
            this.state.syncErrorData.error.errcode
        );
        const newErrCode = data && data.error && data.error.errcode;
        if (syncState === oldSyncState && oldErrCode === newErrCode) return;

        if (syncState === 'ERROR') {
            this.setState({
                syncErrorData: data,
            });
        } else {
            this.setState({
                syncErrorData: null,
            });
        }

        if (oldSyncState === 'PREPARED' && syncState === 'SYNCING') {
            this._updateServerNoticeEvents();
        }
    };

    onRoomStateEvents = (ev, state) => {
        const roomLists = RoomListStore.getRoomLists();
        if (roomLists['m.server_notice'] && roomLists['m.server_notice'].some(r => r.roomId === ev.getRoomId())) {
            this._updateServerNoticeEvents();
        }

        if (ev.getType() === 'citadel.conference') {
            const callInProgressContent = ev.getContent();
            if (!['open', 'empty'].includes(callInProgressContent.status) && this.retryProcessStarted) {
                CitadexStore.setIsCitadexOpened(false);
                this.setState({
                    isConferenceAudioEnabled: true,
                });
                CitadexStore.destroySession();
                this.resetRetryProcess(true);
                errorDialog({ onContinue: noop, type: ERROR_TYPES.GENERIC });
            }
        }
    };

    _updateServerNoticeEvents = async () => {
        const roomLists = RoomListStore.getRoomLists();
        if (!roomLists['m.server_notice']) return [];

        const pinnedEvents = [];
        for (const room of roomLists['m.server_notice']) {
            const pinStateEvent = room.currentState.getStateEvents("m.room.pinned_events", "");

            if (!pinStateEvent || !pinStateEvent.getContent().pinned) continue;

            const pinnedEventIds = pinStateEvent.getContent().pinned.slice(0, MAX_PINNED_NOTICES_PER_ROOM);
            for (const eventId of pinnedEventIds) {
                const timeline = await this._matrixClient.getEventTimeline(room.getUnfilteredTimelineSet(), eventId, 0);
                const ev = timeline.getEvents().find(ev => ev.getId() === eventId);
                if (ev) pinnedEvents.push(ev);
            }
        }
        this.setState({
            serverNoticeEvents: pinnedEvents,
        });
    };

    _onKeyDown = (ev) => {
            /*
            // Remove this for now as ctrl+alt = alt-gr so this breaks keyboards which rely on alt-gr for numbers
            // Will need to find a better meta key if anyone actually cares about using this.
            if (ev.altKey && ev.ctrlKey && ev.keyCode > 48 && ev.keyCode < 58) {
                dis.dispatch({
                    action: 'view_indexed_room',
                    roomIndex: ev.keyCode - 49,
                });
                ev.stopPropagation();
                ev.preventDefault();
                return;
            }
            */

        let handled = false;
        const ctrlCmdOnly = isOnlyCtrlOrCmdKeyEvent(ev);

        switch (ev.keyCode) {
            case KeyCode.UP:
            case KeyCode.DOWN:
                if (ev.altKey && !ev.shiftKey && !ev.ctrlKey && !ev.metaKey) {
                    const action = ev.keyCode == KeyCode.UP ?
                        'view_prev_room' : 'view_next_room';
                    dis.dispatch({action: action});
                    handled = true;
                }
                break;

            case KeyCode.PAGE_UP:
            case KeyCode.PAGE_DOWN:
                if (!ev.ctrlKey && !ev.shiftKey && !ev.altKey && !ev.metaKey) {
                    this._onScrollKeyPressed(ev);
                    handled = true;
                }
                break;

            case KeyCode.HOME:
            case KeyCode.END:
                if (ev.ctrlKey && !ev.shiftKey && !ev.altKey && !ev.metaKey) {
                    this._onScrollKeyPressed(ev);
                    handled = true;
                }
                break;
            case KeyCode.KEY_K:
                if (ctrlCmdOnly) {
                    dis.dispatch({
                        action: 'focus_room_filter',
                    });
                    handled = true;
                }
                break;
        }

        if (handled) {
            ev.stopPropagation();
            ev.preventDefault();
        }
    };

    /**
     * dispatch a page-up/page-down/etc to the appropriate component
     * @param {Object} ev The key event
     */
    _onScrollKeyPressed = (ev) => {
        if (this.refs.roomView) {
            this.refs.roomView.handleScrollKey(ev);
        }
    };

    _onDragEnd = (result) => {
        // Dragged to an invalid destination, not onto a droppable
        if (!result.destination) {
            return;
        }

        const dest = result.destination.droppableId;

        if (dest === 'tag-panel-droppable') {
            // Could be "GroupTile +groupId:domain"
            const draggableId = result.draggableId.split(' ').pop();

            // Dispatch synchronously so that the TagPanel receives an
            // optimistic update from TagOrderStore before the previous
            // state is shown.
            dis.dispatch(TagOrderActions.moveTag(
                this._matrixClient,
                draggableId,
                result.destination.index,
            ), true);
        } else if (dest.startsWith('room-sub-list-droppable_')) {
            this._onRoomTileEndDrag(result);
        }
    };

    _onRoomTileEndDrag = (result) => {
        let newTag = result.destination.droppableId.split('_')[1];
        let prevTag = result.source.droppableId.split('_')[1];
        if (newTag === 'undefined') newTag = undefined;
        if (prevTag === 'undefined') prevTag = undefined;

        const roomId = result.draggableId.split('_')[1];

        const oldIndex = result.source.index;
        const newIndex = result.destination.index;

        dis.dispatch(RoomListActions.tagRoom(
            this._matrixClient,
            this._matrixClient.getRoom(roomId),
            prevTag, newTag,
            oldIndex, newIndex,
        ), true);
    };

    _onMouseDown = (ev) => {
        // When the panels are disabled, clicking on them results in a mouse event
        // which bubbles to certain elements in the tree. When this happens, close
        // any settings page that is currently open (user/room/group).
        if (this.props.leftDisabled && this.props.rightDisabled) {
            const targetClasses = new Set(ev.target.className.split(' '));
            if (
                targetClasses.has('mx_MatrixChat') ||
                targetClasses.has('mx_MatrixChat_middlePanel') ||
                targetClasses.has('mx_RoomView')
            ) {
                this.setState({
                    mouseDown: {
                        x: ev.pageX,
                        y: ev.pageY,
                    },
                });
            }
        }
    };

    _onMouseUp = (ev) => {
        if (!this.state.mouseDown) return;

        const deltaX = ev.pageX - this.state.mouseDown.x;
        const deltaY = ev.pageY - this.state.mouseDown.y;
        const distance = Math.sqrt((deltaX * deltaX) + (deltaY + deltaY));
        const maxRadius = 5; // People shouldn't be straying too far, hopefully

        // Note: we track how far the user moved their mouse to help
        // combat against https://github.com/vector-im/riot-web/issues/7158

        if (distance < maxRadius) {
            // This is probably a real click, and not a drag
            dis.dispatch({ action: 'close_settings' });
        }

        // Always clear the mouseDown state to ensure we don't accidentally
        // use stale values due to the mouseDown checks.
        this.setState({mouseDown: null});
    };

    _setResizeContainerRef = (div) => {
        this.resizeContainer = div;
    };

    closeMinimizedConferenceHint = () => this.setState({ isMinimizedHintDisplayed: false });

    onInfraMessageChanged = (infraMessage) => {
        this.setState({ infraMessageExists: !!infraMessage });
    }

    resetRetryProcess = (intentional = false) => {
        if ((this.state.lastCitadexIndexKey !== this.state.citadexKey && this.state.retryCount > 0) || intentional) {
            clearInterval(this.retryInterval);
            this.retryProcessStarted = false;
            this.setState({ retryCount: 0, isOffline: false, isConferencePreview: intentional ? intentional : this.state.isConferencePreview});
        }
    };

    startRetryProcess = () => {
        this.retryInterval = setInterval(() => {
            const retryCount = this.state.retryCount;
            if (retryCount <= MAX_RETRIES) {
                this.setState({
                    lastCitadexIndexKey: this.state.citadexKey,
                    citadexKey: this.state.citadexKey + 1,
                    retryCount: this.state.retryCount + 1,
                });
            } else {
                // resetting everything after we closed the conference
                this.setState({ citadexKey: 0, retryCount: 0, isOffline: false, isConferencePreview: true });
                this.resetRetryProcess(true);
                clearInterval(this.retryInterval);
                CitadexStore.destroySession();
                CitadexStore.setIsCitadexOpened(false);
            }
        }, RETRY_TIMEOUT_NETWORK_RECONNECT_CONFERENCE); // Retry every 10 seconds, enough so when we have internet we reconnect to the room
    };

    render() {
        const LeftPanel = sdk.getComponent('structures.LeftPanel');
        const UserView = sdk.getComponent('structures.UserView');
        const EmbeddedPage = sdk.getComponent('structures.EmbeddedPage');
        const GroupView = sdk.getComponent('structures.GroupView');
        const MyGroups = sdk.getComponent('structures.MyGroups');
        const MatrixToolbar = sdk.getComponent('globals.MatrixToolbar');
        const CookieBar = sdk.getComponent('globals.CookieBar');
        const PasswordNagBar = sdk.getComponent('globals.PasswordNagBar');
        const ServerLimitBar = sdk.getComponent('globals.ServerLimitBar');

        const {
            ConferenceHandler,
            autoJoin,
            collapseLhs,
            collapsedRhs,
            config,
            currentGroupId,
            currentGroupIsNew,
            currentRoomId,
            currentUserId,
            hideToSRUsers,
            initialEventPixelOffset,
            leftDisabled,
            middleDisabled,
            onRegistered,
            page_type,
            resizeNotifier,
            roomOobData,
            showCookieBar,
            showNotifierToolbar,
            thirdPartyInvite,
            viaServers,
        } = this.props;

        const {
            displayConference,
            infraMessageExists,
            isHPS,
            isMaximized,
            isMinimized,
            isMinimizedHintDisplayed,
            serverNoticeEvents,
            syncErrorData,
            userHasGeneratedPassword,
        } = this.state;

        let pageElement;
        const conferenceInNewTab = SettingsStore.getValue("Conference.openConferenceInTab");

        switch (page_type) {
            case PageTypes.RoomView:
                pageElement = (
                    <RoomView
                        ConferenceHandler={ConferenceHandler}
                        autoJoin={autoJoin}
                        collapsedRhs={collapsedRhs}
                        disabled={middleDisabled}
                        eventPixelOffset={initialEventPixelOffset}
                        isHPS={isHPS}
                        key={currentRoomId || 'roomview'}
                        onRegistered={onRegistered}
                        oobData={roomOobData}
                        ref='roomView'
                        resizeNotifier={resizeNotifier}
                        thirdPartyInvite={thirdPartyInvite}
                        viaServers={viaServers}
                        toggleConference={this.toggleConference}
                    />
                );
                break;

            case PageTypes.MyGroups:
                pageElement = <MyGroups />;
                break;

            case PageTypes.RoomDirectory:
                // handled by MatrixChat for now
                break;

            case PageTypes.HomePage:
                {
                    const pageUrl = getHomePageUrl(config);
                    pageElement = <EmbeddedPage className="mx_HomePage"
                        url={pageUrl}
                        scrollbar={true}
                        isHPS={isHPS}
                    />;
                }
                break;

            case PageTypes.UserView:
                pageElement = <UserView userId={currentUserId} />;
                break;
            case PageTypes.GroupView:
                pageElement = <GroupView
                    groupId={currentGroupId}
                    isNew={currentGroupIsNew}
                    collapsedRhs={collapsedRhs}
                />;
                break;
        }

        const usageLimitEvent = serverNoticeEvents.find((e) => {
            return (
                e && e.getType() === 'm.room.message' &&
                e.getContent()['server_notice_type'] === 'm.server_notice.usage_limit_reached'
            );
        });

        let topBar;
        if (syncErrorData && syncErrorData.error.errcode === 'M_RESOURCE_LIMIT_EXCEEDED') {
            topBar = <ServerLimitBar kind='hard'
                adminContact={syncErrorData.error.data.admin_contact}
                limitType={syncErrorData.error.data.limit_type}
            />;
        } else if (usageLimitEvent) {
            topBar = <ServerLimitBar kind='soft'
                adminContact={usageLimitEvent.getContent().admin_contact}
                limitType={usageLimitEvent.getContent().limit_type}
            />;
        } else if (showCookieBar && config.piwik) {
            const policyUrl = config.piwik.policyUrl || null;
            topBar = <CookieBar policyUrl={policyUrl} />;
        } else if (userHasGeneratedPassword) {
            topBar = <PasswordNagBar />;
        } else if (showNotifierToolbar) {
            topBar = <MatrixToolbar />;
        }

        if (infraMessageExists) {
            topBar = null;
        }

        const isPublicRoomButtonVisible = isEmpty(
            document.getElementsByClassName('publicRoomButton'),
        );

        const conferenceElement = (this.props.forceDisplayConference || displayConference)
            ? <React.Fragment>
                <HintedComponent
                    className={'hinted_conference_minimized'}
                    flag={'has_shown_conference_minimized'}
                    isHintDisplayed={isMinimized && isMinimizedHintDisplayed}
                    hintInfo={{
                        title: _t('Minimized conference'),
                        description: _t('You will find the conference at the bottom of the room list. You' +
                            ' can go back to the standard size by clicking on the icon on the top right' +
                            ' or directly on the conference preview.'),
                    }}
                    hintStyle={{ bottom: 80, left: 249, position: 'absolute' }}
                    hintArrow={{ isDisplayed: true, position: ARROW_POS.CENTER_LEFT }}
                    renderWithoutChildren={true}
                    onHintClose={this.closeMinimizedConferenceHint}
                />
                <div className={cx(
                    'conference-element',
                    { 'no-right-panel': collapsedRhs, "isMaximized": conferenceInNewTab ? true : isMaximized, isMinimized, 'bottom': isPublicRoomButtonVisible },
                )}>
                    <CitadexPage
                        citadexKey={this.state.citadexKey}
                        conferenceInNewTab={conferenceInNewTab}
                        isConferenceAudioEnabled={this.state.isConferenceAudioEnabled}
                        isConferencePreview={this.state.isConferencePreview}
                        isMinimized={conferenceInNewTab ? false : isMinimized}
                        isOffline={this.state.isOffline}
                        key={this.state.citadexKey}
                        retryProcessStarted={this.retryProcessStarted}
                    />
                </div>
            </React.Fragment>
            : null;
        let bodyClasses = 'mx_MatrixChat';
        if (topBar) {
            bodyClasses += ' mx_MatrixChat_toolbarShowing';
        }

        return (
            <div className='mx_MatrixChat_wrapper' aria-hidden={hideToSRUsers} onMouseDown={this._onMouseDown} onMouseUp={this._onMouseUp}>
                <BackendMaintenanceMessages onInfraMessageChanged={this.onInfraMessageChanged} />
                { topBar }
                <DragDropContext onDragEnd={this._onDragEnd}>
                    <MfaMandatoryChecker />
                    <div ref={this._setResizeContainerRef} className={bodyClasses}>
                        <LeftPanel
                            collapsed={collapseLhs || false}
                            disabled={leftDisabled}
                            isHPS={isHPS}
                            mutualizedHsWithPublicRoomList={config.mutualizedHsWithPublicRoomList}
                            resizeNotifier={resizeNotifier}
                        />
                        { conferenceElement }
                        { pageElement }
                    </div>
                </DragDropContext>
                <Toast />
            </div>
        );
    }
}

export default LoggedInView;
