import React from 'react';
import PropTypes from 'prop-types';
import BaseDialog from '../BaseDialog';
import DialogButtons from '../../elements/DialogButtons';
import { _t } from '../../../../languageHandler';

const citadelSvg = require('../../../../../res/img/horizontal-logo.svg');

const ReceiverCodeDialog = ({ code, onFinished }) => {
    const formattedCode = code.slice(0, 3) + ' ' + code.slice(3, 6);
    return (
        <BaseDialog
            title=''
            hasCancel={false}
            onFinished={onFinished}
            fixedWidth={false}
        >
            <div className="mx_ReceiverCodeDialog">
                <div className="logo">
                    <img src={citadelSvg} alt="" />
                </div>
                <div className="text">
                    <div className="title">
                        {_t('Your verification code')}
                    </div>
                    <div className="description">
                        {_t('Enter this code to synchronize your encryption keys.')}
                    </div>
                </div>
                <div className="code">{formattedCode}</div>
                <DialogButtons
                    primaryButton={_t('OK')}
                    onPrimaryButtonClick={onFinished}
                    hasCancel={false}
                />
            </div>
        </BaseDialog>
    );
};

ReceiverCodeDialog.propTypes = {
    code: PropTypes.string.isRequired,
    onFinished: PropTypes.func,
};

ReceiverCodeDialog.defaultProps = {
    onFinished: () => {},
};

export default ReceiverCodeDialog;
