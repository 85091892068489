'use strict';

import React from 'react';
import classNames from 'classnames';
import { _t } from '../../../languageHandler';
import dis from '../../../dispatcher';
import sdk from '../../../index';
import Modal from '../../../Modal';
import { generateClientSecret, validateEmail, citadelUrls } from '../../../utils/CitadelUtils';
import { getDomain, requestToken } from '../../../utils/CitadelRequests';
import CitadelToggle from '../elements/CitadelToggle';
import CitadexStore from '../../../stores/CitadexStore';
import TermsOfUseDialog from '../dialogs/TermsOfUseDialog';

class CitadelRegistrationForm extends React.Component {
    constructor(props) {
        super(props);

        const { defaultUrls, initialEmail } = props;
        this.state = {
            busy: false,
            error: { type: '', text: '' },
            email: initialEmail || '',
            termsChecked: false,
            portalUrl: defaultUrls.portal,
            servicesUrl: defaultUrls.services,
            homeserverUrl: '',
            termsDisabled: true,
        };
        CitadexStore.setTestMode(false);
    }

    onInputChange = (ev) => {
        const { name, value } = ev.target;

        const { devUrls, defaultUrls } = this.props;
        let { portalUrl, servicesUrl } = this.state;

        switch (value) {
            case 'thereisnospoon':
                portalUrl = devUrls.portal;
                servicesUrl = devUrls.services;
                CitadexStore.setTestMode(true);
                break;
            case 'whiterabbit':
                portalUrl = defaultUrls.portal;
                servicesUrl = defaultUrls.services;
                CitadexStore.setTestMode(false);
                break;
        }

        this.setState({
            [name]: (value === 'thereisnospoon' || value === 'whiterabbit') ? '' : value.trim(),
            portalUrl,
            servicesUrl,
            error: { type: '', text: '' },
            termsChecked: false,
            termsDisabled: false,
        });
    };

    onInputBlur = async (ev) => {
        const { lastCheckedEmailDomain } = this.state;
        const email = ev.target.value;
        const emailDomain = email.slice(email.indexOf('@')+1, email.length);

        if (!lastCheckedEmailDomain || lastCheckedEmailDomain !== emailDomain) {
            if (email.length > 0) {
                const { portalUrl } = this.state;

                if (validateEmail(email)) {
                    try {
                        const res = await getDomain(portalUrl, email);
                        if (res.domain) {
                            this.homeServerURL = `https://${res.domain}`;
                            this.setState({
                                homeserverUrl: `https://${res.domain}`,
                                termsDisabled: false,
                            });
                        } else {
                            this.setState({
                                error: {
                                    type: 'email',
                                    text: _t('Couldn\'t find any related homeserver'),
                                },
                                termsChecked: false,
                                termsDisabled: true,
                            });
                        }
                    } catch (error) {
                        this.setState({
                            error: {
                                type: 'email',
                                text: _t('Couldn\'t find any related homeserver'),
                            },
                            termsChecked: false,
                            termsDisabled: true,
                        });
                    }
                } else {
                    this.setState({
                        error: {
                            type: 'email',
                            text: _t('This doesn\'t appear to be a valid email address'),
                        },
                        termsChecked: false,
                        termsDisabled: true,
                    });
                }
            }
        }
    };

    onTermsCheckboxChange = () => {
        if (this.isEmailValid() && !this.state.termsDisabled) {
        this.setState({
            termsChecked: true,
        });
        } else {
            this.setState({
                termsChecked: false,
            });
        }
    };

    isEmailValid = () => validateEmail(this.state.email);

    onTermsLinkClick = async () => {
        const { termsDisabled, portalUrl, homeserverUrl } = this.state;
        if (!this.isEmailValid() || termsDisabled) {
            this.handleError(new Error('Please enter a valid email to see the page'));
            return;
        }
        let homeServerUrl;
        if (!homeserverUrl) {
            const res = await getDomain(portalUrl, this.state.email);
            homeServerUrl = `https://${res.domain}`;
        }
        Modal.createTrackedDialog('Display Terms of Use', '', TermsOfUseDialog, {
            onFinished: () => {},
            displayOnly: true,
            portalUrl: this.state.portalUrl,
            homeserverUrl: homeServerUrl || this.state.homeserverUrl,
        });
    };

    onDataLinkClick = async () => {
        const { termsDisabled, homeserverUrl, portalUrl } = this.state;
        if (!this.isEmailValid() || termsDisabled) {
            this.handleError(new Error('Please enter a valid email to see the page'));
            return;
        }
        let homeServerUrl;
        if (!homeserverUrl) {
            const res = await getDomain(portalUrl, this.state.email);
            homeServerUrl = `https://${res.domain}`;
        }
        Modal.createTrackedDialog('Display Terms of Use', '', TermsOfUseDialog, {
            onFinished: () => {},
            displayOnly: true,
            type: 'data-protection',
            portalUrl: this.state.portalUrl,
            homeserverUrl: homeServerUrl || this.state.homeserverUrl,
        });
    };

    onLoginLinkClick = () => {
        const { email } = this.state;

        dis.dispatch({ action: 'start_login', params: { email } });
    };

    onSubmitForm = (ev) => {
        ev.preventDefault();
        const { portalUrl, servicesUrl, homeserverUrl, email, error } = this.state;

        if (!error.text) {
            const clientSecret = generateClientSecret();

            this.setState({
                busy: true,
            });

            requestToken(homeserverUrl, clientSecret, email, 1, 'register')
            .then((res) => {
                if (res.success) {
                    const params = {
                        portalUrl,
                        servicesUrl,
                        email: email,
                        homeserverUrl,
                        clientSecret,
                        sid: res.sid,
                    };
                    localStorage.setItem('mx_user_email', email);
                    dis.dispatch({
                        action: 'start_registration',
                        params,
                    });
                } else {
                    this.handleError(res);
                }
            })
            .catch((err) => {
                this.handleError(err);
            })
            .finally(() => {
                this.setState({
                    busy: false,
                });
            });
        }
    };

    handleError = (err) => {
        if (err.message) {
            switch (err.message) {
                case 'Email is already in use': {
                    const text = _t('This email address is already in use. <a>Login?</a>',
                    {},
                    { a: sub => <a onClick={this.onLoginLinkClick}>{sub}</a> });

                    this.setState({
                        error: {
                            type: 'email',
                            text,
                        },
                        termsChecked: false,
                        termsDisabled: true,
                    });
                    break;
                }
                case 'Invalid email address':
                    this.setState({
                        error: {
                            type: 'email',
                            text: _t('This doesn\'t appear to be a valid email address'),
                        },
                        termsChecked: false,
                        termsDisabled: true,
                    });
                    break;
                case 'Please enter a valid email to see the page':
                    this.setState({
                        error: {
                            type: 'email',
                            text: _t('Please enter a valid email to see the page'),
                        },
                    });
                    break;
                default:
                    console.error(err);
                    this.setState({
                        error: {
                            type: 'system',
                            text: _t('An error has occurred.'),
                        },
                        termsChecked: false,
                        termsDisabled: true,
                    });
                    break;
            }
        } else {
            console.error(err);
            this.setState({
                error: {
                    type: 'system',
                    text: _t('An error has occurred.'),
                },
                termsChecked: false,
                termsDisabled: true,
            });
        }
    };

    renderCommonSection = () => {
        const { devUrls } = this.props;
        const { portalUrl, busy, error } = this.state;
        const Spinner = sdk.getComponent("elements.Spinner");

        const loaderSection = busy ? (
            <div className='loader'>
                <Spinner />
            </div>
        ) : null;

        const errorTextSection = (error.type === '') ? (
            <div className='error'>
                { error.text }
            </div>
        ) : null;

        const testModeClassNames = classNames({
            'test-mode': true,
            'hide': (portalUrl !== devUrls.portal),
        });

        return (
            <div className='common'>
                <div className='load-or-error'>
                  { loaderSection || errorTextSection }
                </div>
                <div className={testModeClassNames}>
                    <p>{_t('Test mode enabled')}</p>
                </div>
            </div>
        );
    };

    renderTermsSection = () => {
        const { termsChecked, termsDisabled } = this.state;

        const termsLabel = _t('By clicking, you certify that you have read and accepted without reserves the <a>Terms Of Use</a>',
        {},
        { a: sub => <a onClick={this.onTermsLinkClick}>{sub}</a> });

        const dataProtection = _t(`Personal data are collected and processed for the purposes of operation of the Citadel Service and for compliance with applicable legal requirements. You have the right to access, and to request that your personal data be rectified or deleted. You are also entitled to object to the processing of your personal data or to request restriction thereof. In case of any request or complaint, please send an email to support@citadel.team. <a>For more information</a>`,
        {},
        { a: sub => <a onClick={this.onDataLinkClick}>{sub}</a> });

        return (
            <React.Fragment>
                <div className='terms'>
                    <CitadelToggle
                        type='checkbox'
                        checked={termsChecked}
                        onChange={this.onTermsCheckboxChange}
                        label={termsLabel}
                        disabled={!this.isEmailValid() || termsDisabled}
                    />
                </div>
                <div className="data-protection">
                    {dataProtection}
                </div>
            </React.Fragment>
        );
    };

    getCaption = () => {
        const { error } = this.state;

        if (error.type === 'email') {
            return { type: 'error', text: error.text };
        }

        return {
            type: 'description',
            text: _t('Use your professional email address to find your co-workers.'),
        };
    };

    render() {
        const CitadelInput = sdk.getComponent('elements.CitadelInput');
        const { email, termsChecked } = this.state;

        const commonSection = this.renderCommonSection();
        const termsSection = this.renderTermsSection();

        const validInputs = (validateEmail(email) && termsChecked);

        return (
            <div className='mx_CitadelRegistrationForm'>
                { commonSection }
                <form onSubmit={this.onSubmitForm}>
                    <CitadelInput
                        label={_t('Email address')}
                        name='email'
                        caption={this.getCaption()}
                        onChange={this.onInputChange}
                        onBlur={this.onInputBlur}
                        value={email}
                        autoFocus={true}
                    />
                    { termsSection }
                    <div className='button_wrapper'>
                        <div className='button'>
                            <input className='mx_Login_submit'
                            type='submit'
                            value={_t('Sign up for free')}
                            disabled={!validInputs}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

CitadelRegistrationForm.propTypes = {
    defaultUrls: citadelUrls,
    devUrls: citadelUrls,
};

export default CitadelRegistrationForm;
