import * as React from 'react';
import cx from 'classnames';

import AccessibleButton from '../elements/AccessibleButton';
import { _t } from '../../../languageHandler';
import Spinner from './Spinner';
import dis from '../../../dispatcher';

import warningIcon from '../../../../res/img/warning.svg';
import PlatformPeg from '../../../PlatformPeg';

class UpdateHandler extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            canUpdate: false,
            isChecking: false,
            checkStatus: {},
        };
        this.platform = PlatformPeg.get();
        this.statuses = this.platform.getUpdateCheckStatusEnum();
    }

    componentDidMount() {
        this.platform.canSelfUpdate().then((v) => this.setState({ canUpdate: v })).catch((e) => {
            console.error('Error getting self updatability: ', e);
        });
        this.dispatchRef = dis.register(this.onAction);
    }

    componentWillUnmount() {
        dis.unregister(this.dispatchRef);
        this.platform.stopUpdateCheck();
    }

    onAction = (payload) => {
        if (payload.action === 'check_updates') {
            this.setState({ checkStatus: payload.value });
        }
        if (payload.action === 'new_version') {
            this.setState({ checkStatus: { status: this.statuses.READY } });
        }
    };

    getStatusText = () => {
        // we can't import the enum from riot-web as we don't want matrix-react-sdk
        // to depend on riot-web. so we grab it as a normal object via API instead.
        const { status, detail } = this.state.checkStatus;

        switch (status) {
            case this.statuses.ERROR:
                return _t('Error encountered (%(errorDetail)s).', { errorDetail: detail });
            case this.statuses.CHECKING:
                return _t('Checking for an update...');
            case this.statuses.NOTAVAILABLE:
                return _t('No update available.');
            case this.statuses.DOWNLOADING:
                return _t('Downloading update...');
            case this.statuses.READY:
                return _t('Update available.');
        }
    };

    _isDisabled = () => {
        const { checkStatus: { status }} = this.state;
        return this._isUpdating() || status === this.statuses.NOTAVAILABLE || status === this.statuses.DOWNLOADING;
    };

    _isUpdating = () => this.state.checkStatus.status === this.statuses.CHECKING;

    render() {
        const { startUpdateCheck, installUpdate } = this.platform;
        const { checkStatus: { status } } = this.state;

        let button = (
            <AccessibleButton onClick={installUpdate} kind="primary" disabled={this._isDisabled()}>
                {_t('Update')}
            </AccessibleButton>
        );

        if (status !== this.statuses.READY) {
            button = (
                <AccessibleButton
                    className={cx({ 'no-click': this._isUpdating() })}
                    onClick={startUpdateCheck}
                    kind="primary"
                    disabled={this._isDisabled()}
                >
                    {this._isUpdating() ? <Spinner width={22} height={22} /> : _t('Check for update')}
                </AccessibleButton>
            );
        }

        return (
            <div className="update-handler">
                {button}
                {status && <div className="update-handler__message">
                    <img className="update-handler__warning" src={warningIcon} width="16" height="16" alt="" />
                    {this.getStatusText()}
                </div>}
            </div>
        );
    }
}

export default UpdateHandler;
