import { _t } from "../../languageHandler";

export const ExportFormat = {
    Html: "Html",
    PlainText: "PlainText",
};

export const ExportType = {
    Beginning: "Beginning",
    BetweenDates: "BetweenDates",
};

export const textForFormat = (format) => {
    switch (format) {
        case ExportFormat.Html:
            return _t("export_chat|html");
        case ExportFormat.PlainText:
            return _t("export_chat|text");
        default:
            throw new Error("Unknown format");
    }
};

export const textForType = (type) => {
    switch (type) {
        case ExportType.Beginning:
            return _t("export_chat|from_the_beginning");
        case ExportType.BetweenDates:
            return _t("export_chat|Between_dates");
        default:
            throw new Error("Unknown type: " + type);
    }
};

export const normalizeDate = (date) => date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
