import React, { useState } from 'react';
import { noop } from "lodash";
import utils from 'matrix-js-sdk/lib/utils';
import PropTypes from "prop-types";

import * as MegolmExportEncryption from '../../../../utils/MegolmExportEncryption';
import MatrixClientPeg from '../../../../MatrixClientPeg';
import UserStore from "../../../../stores/UserStore";
import { _t } from "../../../../languageHandler";
import BaseDialog from "../BaseDialog";
import DialogButtons from '../../elements/DialogButtons';
import Modal from "../../../../Modal";
import SuccessErrorDialog from '../SuccessErrorDialog';
import E2eSendFailureDialog from "./E2eSendFailureDialog";

import success from '../../../../../res/img/e2e/share_key_success.svg';

const E2eKeyShareDialog = ({ onFinished, roomId, requesters }) => {
    const [isLoading, setIsLoading] = useState(false);

    const getRoomKeys = async () => {
        try {
            const cli = MatrixClientPeg.get();
            const password = utils.generateAlphaNumString(30);
            const roomKeys = await cli.exportRoomKeys(roomId);
            const file = await MegolmExportEncryption.encryptMegolmKeyFile(
                JSON.stringify(roomKeys),
                password,
            );
            const blob = new Blob(
                [file],
                { type: 'text/plain;charset=us-ascii' },
            );
            const serverConfig = await cli.getServerConfig();
            return { cli, serverConfig, blob, password };
        } catch (error) {
            throw new Error(_t('Failed to retrieve keys'));
        }
    };

    const getSentKeysUserList = (arrayOfPromises, resultsAfterSendingKeys) => {
        const finalResult = [];
        for (let i = 0; i < requesters.length; i++) {
            const user = UserStore.getUserById(requesters[i].user_id);
            finalResult[i] = {
                displayName: user.displayName,
            };
            for (let j = 0; j < arrayOfPromises.length; j++) {
                if (requesters[i]?.user_id === resultsAfterSendingKeys[j]?.user_id) {
                    finalResult[i] = {
                        ...finalResult[i],
                        isSent: true,
                    };
                }
            }
        }
        return finalResult;
    };

    const sendKeysToListOfRequesters = async (cli, serverConfig, blob, password) => {
            const arrayOfPromisesSentKeys = requesters.map(async (requester) => {
                try {
                    const responseWithMXC = await cli.uploadContent(blob, {
                        roomId,
                        mxId: requester.user_id,
                        includeFilename: false,
                        rawResponse: true,
                        prefix: '/_matrix/media/r0',
                        endpoint: `/upload/secret/${roomId}/${requester.user_id}`,
                    });
                    const { mxc } = JSON.parse(responseWithMXC);
                    cli.startEasyKeyRequest({
                        remoteDeviceId: requester.device_id,
                        type: 'citadel.external.code',
                        remoteUserId: requester.user_id,
                        mxc,
                        secret: password,
                        infra: localStorage.getItem("mx_user_id").split(':')[1],
                        eventId: requester.event_id,
                        roomId: roomId,
                    });
                    return requester;
                } catch (error) {
                    MatrixClientPeg.get().trackUserAction({
                        formId: 'E2EKeysSharingInRoom',
                        version: 1,
                        action: 'error',
                        step: 'share2',
                        code: error.httpStatus,
                        reason: error.message,
                        room: roomId,
                    });
                    return error;
                }
            });
            let requestersAndSentsKeys = [];
            await Promise.all(arrayOfPromisesSentKeys).catch(() => {
                    return arrayOfPromisesSentKeys;
            }).then(resultsAfterSendingKeys => {
                requestersAndSentsKeys = getSentKeysUserList(arrayOfPromisesSentKeys, resultsAfterSendingKeys);
            });
        return requestersAndSentsKeys;
    };

    const getModalDetails = (displayName, listOfRequesters) => {
        const listOfReceivers = listOfRequesters.filter(requester => requester.isSent);
        localStorage.setItem("listOfE2eReceivers", JSON.stringify(listOfReceivers));
        if (listOfReceivers.length === requesters.length && requesters.length === 1) {
            MatrixClientPeg.get().trackUserAction({
                formId: 'E2EKeysSharingInRoom',
                version: 1,
                action: 'success',
                nbrecipients: listOfReceivers.length,
                room: roomId,
            });
            Modal.createDialog(
                SuccessErrorDialog,
                {
                    title: `${displayName} ${_t("successfully received your encryption keys")}`,
                    successImg: success,
                    message: null,
                    isFailure: false,
                    onFinished: noop,
                },
                'success_error_dialog',
            );
        } else if (listOfReceivers.length === requesters.length && requesters.length > 1) {
            MatrixClientPeg.get().trackUserAction({
                formId: 'E2EKeysSharingInRoom',
                version: 1,
                action: 'success',
                nbrecipients: listOfReceivers.length,
                room: roomId,
            });
            Modal.createDialog(
                SuccessErrorDialog,
                {
                    title: _t("You successfully shared your encryption keys with your recipients"),
                    successImg: success,
                    message: null,
                    isFailure: false,
                    onFinished: noop,
                },
                'success_error_dialog',
            );
        } else if (listOfReceivers.length < requesters.length && listOfReceivers.length !== 0) {
            MatrixClientPeg.get().trackUserAction({
                formId: 'E2EKeysSharingInRoom',
                version: 1,
                action: 'partialFailure',
                nbrecipients: listOfReceivers.length,
                room: roomId,
            });
            Modal.createDialog(
                E2eSendFailureDialog,
                {
                    title: _t("Some encryption keys failed to be shared"),
                    isFailure: true,
                    listOfRequesters,
                },
                'E2eSendFailureDialog',
            );
        } else if (listOfReceivers.length === 0) {
            MatrixClientPeg.get().trackUserAction({
                formId: 'E2EKeysSharingInRoom',
                version: 1,
                action: 'failure',
                nbrecipients: listOfReceivers.length,
                room: roomId,
            });
            Modal.createDialog(
                SuccessErrorDialog,
                {
                    onFinished: noop,
                    title: _t("Fail to share"),
                    message: _t("We could not share your encryption keys to your recipients, please try again later."),
                    isFailure: true,
                },
                'success_error_dialog',
            );
        }
    };

    const onShare = async () => {
        try {
            if (isLoading) return;
            setIsLoading(true);
            const { cli, serverConfig, blob, password } = await getRoomKeys();
            const listOfRequesters = await sendKeysToListOfRequesters(cli, serverConfig, blob, password);
            const displayName = requesters.length > 1 ? _t("All users") : UserStore.getUserProp(requesters[0].user_id, 'displayName');
            getModalDetails(displayName, listOfRequesters);
            const e2eKeySharedList = JSON.parse(localStorage.getItem("e2eKeySharedList")) || {};
            e2eKeySharedList[roomId] = { isKeySent: true };
            localStorage.setItem("e2eKeySharedList", JSON.stringify(e2eKeySharedList));
            window.dispatchEvent(new Event('storage'));
            MatrixClientPeg.get().trackUserAction({
                formId: 'E2EKeysSharingInRoom',
                version: 1,
                action: 'share2',
                nbrecipients: listOfRequesters.length,
                room: roomId,
            });
            onFinished();
        } catch (error) {
            console.log(error); // if a request fails it shouldn't enter here
        }
    };

    const getTitle = () => {
        const requestersNames = requesters.map(requester => UserStore.getUserProp(requester.user_id, 'displayName'));

        switch (requesters.length) {
            case 1:
                return _t('Do you want to share your encryption keys to %(userName)s', { userName: requestersNames[0] });
            case 2:
                return _t('Do you want to share your encryption keys to %(firstUserName)s and %(secondUserName)s ?', {
                    firstUserName: requestersNames[0],
                    secondUserName: requestersNames[1],
                });
            case 3:
                return _t('Do you want to share your encryption keys to %(userName)s and %(numberOfRequesters)s other people ?', {
                    userName: requestersNames[0],
                    numberOfRequesters: requesters.length - 1,
                });
        }
    };

    const onCancelHandler = () => {
        MatrixClientPeg.get().trackUserAction({
            formId: 'E2EKeysSharingInRoom',
            version: 1,
            action: 'cancel',
            room: roomId,
            nbrecipients: requesters.length,
        });
        onFinished();
    };

    return (
        <BaseDialog className="E2eKeyShareDialog" onFinished={onCancelHandler}
            title={getTitle()}
            contentId='mx_Dialog_content'
        >
            <div className="mx_Dialog_content" id='mx_Dialog_content'>
                {_t("If you share your encryption keys, your recipient will be able to decrypt all " +
                    "the messages you are able to read.")
                }
            </div>
            <DialogButtons
                isLoading={isLoading}
                onCancel={onCancelHandler}
                onPrimaryButtonClick={onShare}
                primaryButton={_t('Share my encryption keys')}
            />
        </BaseDialog>
    );
};

E2eKeyShareDialog.prototype = {
    onFinsihed: PropTypes.func.isRequired,
    roomId: PropTypes.string.isRequired,
    requesters: PropTypes.array.isRequired,
};

export default E2eKeyShareDialog;
