import React from 'react';
import { filter, isEmpty, noop, uniq } from 'lodash';
import Matrix from 'matrix-js-sdk';

import { _t } from '../../../../languageHandler';
import MfaSuccessErrorDialog from './MfaSuccessErrorDialog';
import AddThreepid from '../../../../AddThreepid';
import Modal from '../../../../Modal';
import MatrixClientPeg from '../../../../MatrixClientPeg';
import dis from '../../../../dispatcher';
import { generateClientSecret } from '../../../../utils/CitadelUtils';

import activationSuccessIcon from '../../../../../res/img/code-valid.svg';
import deactivationSuccessIcon from '../../../../../res/img/code-invalid.svg';
import errorIcon from '../../../../../res/img/error-occured.svg';
import smsSent from '../../../../../res/img/sms-sended.svg';

const MfaDialogTypes = {
    activationSuccess: {
        email: 'activationEmailSuccess',
        msisdn: 'activationPhoneSuccess',
        totp: 'activationAppSuccess',
    },
    deactivationSuccess: {
        email: 'deactivationEmailSuccess',
        msisdn: 'deactivationPhoneSuccess',
        totp: 'deactivationAppSuccess',
    },
    error: 'error',
    newTokenSent: 'newTokenSent',
};

const MfaDialogs = ({ dialogType, onFinished = noop, error, email, phoneNumber }) => {
    switch (dialogType) {
        case MfaDialogTypes.activationSuccess.email:
            return (
                <MfaSuccessErrorDialog
                    icon={activationSuccessIcon}
                    title={`${_t('Email verification')} ${_t('enabled')}` }
                    text={_t('You will receive a verification code by email the next time you connect.')}
                    buttonText={_t('Continue')}
                    onFinished={onFinished}
                />
            );
        case MfaDialogTypes.activationSuccess.msisdn:
            return (
                <MfaSuccessErrorDialog
                    icon={activationSuccessIcon}
                    title={_t('Your device has been verified')}
                    text={_t('You can now use Citadel safely')}
                    buttonText={_t('Continue')}
                    onFinished={onFinished}
                />
            );
        case MfaDialogTypes.activationSuccess.totp:
            return (
                <MfaSuccessErrorDialog
                    icon={activationSuccessIcon}
                    title={_t('Verification successful')}
                    text={_t('You can now use Citadel in complete safety.')}
                    buttonText={_t('Continue')}
                    onFinished={onFinished}
                />
            );
        case MfaDialogTypes.deactivationSuccess.email:
            return (
                <MfaSuccessErrorDialog
                    icon={deactivationSuccessIcon}
                    title={_t('Multi-factor authentication deactivated')}
                    buttonText={_t('Continue')}
                    onFinished={onFinished}
                />
            );
        case MfaDialogTypes.deactivationSuccess.msisdn:
            return (
                <MfaSuccessErrorDialog
                    icon={deactivationSuccessIcon}
                    title={_t('Verification by SMS deactivated')}
                    buttonText={_t('Continue')}
                    onFinished={onFinished}
                />
            );
        case MfaDialogTypes.deactivationSuccess.totp:
            return (
                <MfaSuccessErrorDialog
                    icon={deactivationSuccessIcon}
                    title={_t('Verification by authentication app deactivated')}
                    buttonText={_t('Continue')}
                    onFinished={onFinished}
                />
            );
        case MfaDialogTypes.error:
            return (
                <MfaSuccessErrorDialog
                    icon={errorIcon}
                    title={_t('An error occurred')}
                    text={_t('Error: %(error)s', { error: error || '500' })}
                    buttonText={_t('Continue')}
                    onFinished={onFinished}
                />
            );
        case MfaDialogTypes.newTokenSent:
            return (
                <MfaSuccessErrorDialog
                    autoCloseAfter={5000}
                    icon={smsSent}
                    title={_t('New code sent')}
                    text={<div className="text-phone">
                        {_t('A new verification code has just sent to you at')}
                        <span>{` ${phoneNumber || email}`}</span>
                    </div>}
                    onFinished={onFinished}
                />
            );
        default:
            return null;
    }
};

const createMfaDialog = dialogProps => {
    Modal.createDialog(
        MfaDialogs,
        dialogProps,
        'mfa_activation_success_failure',
        /*isPriority=*/false,
        /*isStatic=*/false,
        /*extraPriority=*/true,
    );
};


// FIXME: (*) MFA MSISDN ACTIVATION
// For now the server answers with an error on /3pid
// when we try to change the phone number (MFA MSISDN already activated).
// To prevent this, a first version was produces to :
// - call /enable 'email' aka disable MFA MSISDN (this would automatically unbind and delete the number)
//   at the start of the process
// - call /submitToken, /requestToken, /3pid
// - always end the process with /enable 'msisdn'
// The server should be updated to improve the behaviour of /3pid to allow phone number replacement,
// so in a second version :
// - no longer call /enable 'email' at the start of the process
// - call /submitToken, /requestToken, /3pid
// - only call /enable 'msisdn' is the MFA MSISDN is not already activated (not a phone number replacement)

class MfaManager {
    constructor() {
       this._setDefaultValues();
    }

    _setDefaultValues() {
        this.mfaStatus = [];
        this.addThreepid = null;
        this.email = '';
        this.phoneNumber = '';
        this.phoneCountryInfo = {};
        this.temp = {};
    }

    _createTemporaryClient = () => {
        return Matrix.createClient({
            baseUrl: this.temp.homeServerUrl,
            idBaseUrl: this.temp.homeServerUrl,
        });
    }

    _getThreepid = async (type) => {
        const res = await MatrixClientPeg.get().getThreePids();
        const { threepids } = res || {};

        if (threepids) {
            const filteredThreepids = filter(threepids, ({medium}) => medium === type);
            if (!isEmpty(filteredThreepids)) {
                if (type === 'msisdn') {
                    this.phoneNumber = `+${filteredThreepids[0].address}`;
                    return this.phoneNumber;
                } else {
                    this.email = filteredThreepids[0].address;
                    return this.email;
                }
            }
        }

        return '';
    }

    _updateMfaStatus = (mfaStatus, quiet) => {
        if (mfaStatus !== this.mfaStatus) {
            // Update any view using the mfa status
            !quiet && dis.dispatch({ action: 'mfa_updated' });
            this.mfaStatus = mfaStatus;
        }
    }

    _calculateFullPhoneNumber = (phoneNumber, phoneCountryCode) => {
        return `${phoneCountryCode}${phoneNumber}`;
    }

    getTemporaryValues = () => { return this.temp; };
    setTemporaryValues = (values) => { this.temp = { ...this.temp, ...values }; }
    resetTemporaryValues = () => { this.temp = {}; }

    getLocalValues = () => {
        return { mfaStatus: this.mfaStatus, phoneNumber: this.phoneNumber };
    };

    getPhoneNumber = async () => await this._getThreepid('msisdn');

    getMfaStatus = async () => {
        const cli = MatrixClientPeg.get();
        const res = await cli.getMFAStatus();
        if (res.mfa) {
            this._updateMfaStatus(res.mfa);
            // FIXME: (*) If mfaStatus equals email, do not try to getPhoneNumber.
            // Usually if mfaStatus === 'msisdn' we'd like to display the related
            // phone number as well so it's a good thing to fetch its value here.
            await this.getPhoneNumber();
        }

        return this.mfaStatus;
    }

    setMfaStatus = async (password, status, type, quiet = false) => {
        let mfa = this.mfaStatus;

        switch (type) {
            case 'email':
                mfa = status === 'disable' ? null : [type];
                break;
            // totp or msisdn
            default:
                mfa = status === 'disable'
                    ? mfa.filter((status) => status !== type)
                    : uniq([...this.mfaStatus, 'email', type], (val) => val);
                break;
          }

        let error;
        let wasSuccessfully = false;
        let resp;

        try {
            resp = await MatrixClientPeg.get().setMFAStatus(password, mfa);
            const { success } = resp;
            wasSuccessfully = success;
        } catch (e) {
            MatrixClientPeg.get().trackUserAction({
                formId: 'statusMFA',
                version: 1,
                action: 'error',
                step: isEmpty(mfa) ? 'enable' : 'disable',
                code: e.httpStatus,
                reason: e.error,
            });
            error = e.toString();
            if (e && e.message === 'Invalid password') throw e;
        }

        let dialogType;
        if (!wasSuccessfully) {
            dialogType = MfaDialogTypes.error;
        } else {
            dialogType = status === 'enable' ? MfaDialogTypes.activationSuccess[type] :
                MfaDialogTypes.deactivationSuccess[type];
            this._updateMfaStatus(mfa ? mfa : [], quiet);
        }

        !quiet && createMfaDialog({ dialogType, error });
        return resp;
    }

    // FIXME: (*) Remove the following function.
    // it's used for modifying the phone number after the mfa msisdn is enabled
    resetMsisdn = async () => {
        if (this.phoneNumber) {
            // Quietly downgrade MFA status
            try {
                await MatrixClientPeg.get().setAccountData('citadel.mfa', { mfaActivated: true });
                this.phoneNumber = '';
            } catch (e) {

            }
        }
    }

    requestAddToken = async (data = {}) => {
        !data.isResend && await this.resetMsisdn();

        let phoneNumber = data.phoneNumber || this.temp.phoneNumber;
        phoneNumber = phoneNumber.split(' ').join('');
        const phoneCountryInfo = {
            code: data.phoneCountryCode || this.temp.phoneCountryInfo.code,
            iso: data.phoneCountryIso || this.temp.phoneCountryInfo.iso,
        };

        this.setTemporaryValues({ phoneNumber, phoneCountryInfo });

        try {
            this.addThreepid = new AddThreepid();
            const { success, error } = await this.addThreepid.addMsisdn(
                phoneCountryInfo.iso,
                phoneNumber,
                true,
            );

            if (success) {
                createMfaDialog({
                    dialogType: MfaDialogTypes.newTokenSent,
                    phoneNumber: this._calculateFullPhoneNumber(
                        phoneNumber,
                        phoneCountryInfo.code,
                    ),
                });
                return true;
            } else if (error) throw error;
        } catch (e) {
            createMfaDialog({ dialogType: MfaDialogTypes.error });
            return false;
        }
    }

    requestLoginToken = async (mfa, data) => {
        this.temp.client = this._createTemporaryClient();
        this.temp.clientSecret = generateClientSecret();

        try {
            const resp = await this.temp.client.requestLoginToken(mfa, data.email, this.temp.clientSecret);
            this.temp.sid = resp.sid;

            if (data.isResend) {
                createMfaDialog({
                    dialogType: MfaDialogTypes.newTokenSent,
                    phoneNumber: data.phoneNumber,
                    email: data.email,
                });
            }
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    submitAddToken = async (token) => {
        try {
            const resp = await this.addThreepid.haveMsisdnToken(token);
            const { success } = resp;

            if (success) {
                this.phoneNumber = this._calculateFullPhoneNumber(
                    this.temp.phoneNumber,
                    this.temp.phoneCountryInfo.code,
                );

                this.phoneCountryInfo = this.temp.phoneCountryInfo;

                this.resetTemporaryValues();

                await MatrixClientPeg.get().setAccountData('citadel.mfa', { mfaActivated: true });
            }
            return success;
        } catch (e) {
            return false;
        }
    }

    delete3PidSmsBind = () => {
        MatrixClientPeg.get().deleteThreePid('msisdn', this.phoneNumber.split('+')[1]);
        this.resetTemporaryValues();
    }

    submitToken = async (mfa, token, shouldResetTemp: true) => {
        try {
            const resp = await this.temp.client.submitToken(
                mfa,
                this.temp.sid,
                this.temp.clientSecret,
                token,
            );
            const { success } = resp;

            if (success) {
                shouldResetTemp && this.resetTemporaryValues();
                return true;
            } else return false;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    showTotpFinalDialog = (enabled = true) => {
        if (enabled) {
            createMfaDialog({ dialogType: MfaDialogTypes.activationSuccess.totp });
            dis.dispatch({ action: 'mfa_updated' });
        } else {
            createMfaDialog({ dialogType: MfaDialogTypes.error });
        }
    };
}

if (!global.singletonMfaManager) global.singletonMfaManager = new MfaManager();
export default global.singletonMfaManager;
