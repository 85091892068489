import React from 'react';

import DialogButtons from '../elements/DialogButtons';
import BaseDialog from './BaseDialog';
import { _t } from '../../../languageHandler';
import updateSvg from '../../../../res/img/update.svg';

const NewVersionDialog = ({className, onFinished}) => {
    const onRerunClick = async () => {
        onFinished(true);
    };
    return <BaseDialog
        className={className}
        fixedWidth={false}
        hasCancel={false}
        onFinished={onFinished}
        title=""
    >
        <div className="content">
            <div className="header">
                <img src={updateSvg} alt="" />
                <div className="title">
                    {_t('A new version of Citadel is available')}
                </div>
            </div>
            <div className="description">
                {_t('New features and better performance are waiting for you!')}
            </div>
            <DialogButtons primaryButton={_t('Refresh Citadel')}
                        onPrimaryButtonClick={onRerunClick}
                        hasCancel={false} />
        </div>
    </BaseDialog>;
};

export default NewVersionDialog;
