/*
Copyright 2017 Aidan Gauland
Copyright 2018 New Vector Ltd.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Spinner from '../elements/Spinner';

import { _t } from '../../../languageHandler';

/**
 * Basic container for buttons in modal dialogs.
 */
module.exports = class extends React.Component {
    static displayName = "DialogButtons";

    static propTypes = {
        // The primary button which is styled differently and has default focus.
        primaryButton: PropTypes.node.isRequired,

        // A node to insert into the cancel button instead of default "Cancel"
        cancelButton: PropTypes.node,

        // onClick handler for the primary button.
        onPrimaryButtonClick: PropTypes.func.isRequired,

        // should there be a cancel button? default: true
        hasCancel: PropTypes.bool,

        // onClick handler for the cancel button.
        onCancel: PropTypes.func,

        focus: PropTypes.bool,

        // disables the primary and cancel buttons
        disabled: PropTypes.bool,

        // disables only the primary button
        primaryDisabled: PropTypes.bool,

        classNames: PropTypes.string,

        // displays a spinner on the primary button instead of text
        isLoading: PropTypes.bool,
    };

    static defaultProps = {
        hasCancel: true,
        disabled: false,
        isLoading: false,
    };

    _onCancelClick = () => {
        this.props.onCancel();
    };

    render() {
        const {
            cancelButton: cancelButtonProps,
            children,
            className,
            disabled,
            focus,
            hasCancel,
            isLoading,
            onPrimaryButtonClick,
            primaryButton,
            primaryButtonClass,
            primaryDisabled,
        } = this.props;
        let primaryButtonClassName = "mx_Dialog_primary";
        if (primaryButtonClass) {
            primaryButtonClassName += " " + primaryButtonClass;
        }
        let cancelButton;
        if (cancelButtonProps || hasCancel) {
            cancelButton = <button onClick={this._onCancelClick} disabled={disabled}>
                { cancelButtonProps || _t("Cancel") }
            </button>;
        }
        return (
            <div className={cx("mx_Dialog_buttons", className)}>
                <div>
                { cancelButton }
                { children }
                </div>
                <div>
                  <button className={primaryButtonClassName}
                      autoFocus={focus}
                      disabled={disabled || primaryDisabled || isLoading}
                      onClick={onPrimaryButtonClick}
                  >
                      <div className={cx("primary_button_label", { isLoading: isLoading })}>
                          {primaryButton}
                      </div>
                      { isLoading === true ? <Spinner /> : null }
                  </button>
                </div>
            </div>
        );
    }
};
