/*
Copyright 2015, 2016 OpenMarket Ltd
Copyright 2017 Vector Creations Ltd
Copyright 2018 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import Matrix from 'matrix-js-sdk';
import url from 'url';

export default class Login {
    constructor(tenantUrl, initialDeviceDisplayName) {
        this._hsUrl = tenantUrl;
        this._isUrl = tenantUrl;
        this._initialDeviceDisplayName = initialDeviceDisplayName;
    }

    /**
     * Get a temporary MatrixClient, which can be used for login or register
     * requests.
     * @returns {MatrixClient}
     */
    _createTemporaryClient() {
        return Matrix.createClient({
            baseUrl: this._hsUrl,
            idBaseUrl: this._isUrl,
        });
    }

    getMfaConfiguration = async (address) => {
        const res = await this.loginViaPassword(address, {}, true);
        if (res && res.errcode === 'M_MISSING_PARAM') {
            const { param, phone_number: fullPhoneNumber } = res.data;

            let phoneNumber;
            let phoneCountryInfo;
            if (fullPhoneNumber) {
                phoneNumber = fullPhoneNumber.slice(fullPhoneNumber.indexOf(' '), fullPhoneNumber.length);
                phoneNumber = phoneNumber.split(' ').join('');
                phoneCountryInfo = { code: fullPhoneNumber.slice(0, fullPhoneNumber.indexOf(' '))};
            }

            return { param, phoneNumber, phoneCountryInfo };
        } else throw res;
    }

    loginViaPassword(address, credentials, quiet = false) {
        const loginParams = {
            medium: 'email',
            address,
            password: credentials.password,
            identifier: {
                type: 'm.id.thirdparty',
                medium: 'email',
                address,
            },
            mfa_creds: credentials.mfa,
            initial_device_display_name: this._initialDeviceDisplayName,
        };

        return sendLoginRequest(
            this._hsUrl, this._isUrl, 'm.login.password', loginParams,
        ).catch((error) => {
            if (!quiet) {
                console.error("Login failed", error);
                throw error;
            } else return error;
        });
    }

    getSsoLoginUrl(loginType) {
      const client = this._createTemporaryClient();
      const parsedUrl = url.parse(window.location.href, true);

      // XXX: at this point, the fragment will always be #/login, which is no
      // use to anyone. Ideally, we would get the intended fragment from
      // MatrixChat.screenAfterLogin so that you could follow #/room links etc
      // through an SSO login.
      parsedUrl.hash = "";

      parsedUrl.query["homeserver"] = client.getHomeserverUrl();
      parsedUrl.query["identityServer"] = client.getIdentityServerUrl();
      return client.getSsoLoginUrl(url.format(parsedUrl), loginType);
    }
}


/**
 * Send a login request to the given server, and format the response
 * as a MatrixClientCreds
 *
 * @param {string} hsUrl   the base url of the Homeserver used to log in.
 * @param {string} isUrl   the base url of the default identity server
 * @param {string} loginType the type of login to do
 * @param {object} loginParams the parameters for the login
 *
 * @returns {MatrixClientCreds}
 */
export async function sendLoginRequest(hsUrl, isUrl, loginType, loginParams) {
    const client = Matrix.createClient({
        baseUrl: hsUrl,
        idBaseUrl: isUrl,
    });

    const data = await client.login(loginType, loginParams);

    const wellknown = data.well_known;
    if (wellknown) {
        if (wellknown["m.homeserver"] && wellknown["m.homeserver"]["base_url"]) {
            hsUrl = wellknown["m.homeserver"]["base_url"];
            console.log(`Overrode homeserver setting with ${hsUrl} from login response`);
        }
        if (wellknown["m.identity_server"] && wellknown["m.identity_server"]["base_url"]) {
            // TODO: should we prompt here?
            isUrl = wellknown["m.identity_server"]["base_url"];
            console.log(`Overrode IS setting with ${isUrl} from login response`);
        }
    }

    return {
        homeserverUrl: hsUrl,
        identityServerUrl: isUrl,
        userId: data.user_id,
        deviceId: data.device_id,
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
    };
}
