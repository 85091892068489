/*
Copyright 2015, 2016 OpenMarket Ltd
Copyright 2018-2019 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MatrixClientPeg from '../../../MatrixClientPeg';
import Modal from '../../../Modal';
import sdk from '../../../index';

import dis from '../../../dispatcher';
import Promise from 'bluebird';
import AccessibleButton from '../elements/AccessibleButton';
import { _t } from '../../../languageHandler';

import sessionStore from '../../../stores/SessionStore';

import { validatePassword } from '../../../utils/CitadelUtils';
import CitadelInput from '../elements/CitadelInput';
import PasswordValidation from '../auth/PasswordValidation';

export default class ChangePassword extends Component {
    static displayName = 'ChangePassword'

    static propTypes = {
        onFinished: PropTypes.func,
        onError: PropTypes.func,
        onCheckPassword: PropTypes.func,
        rowClassName: PropTypes.string,
        buttonClassName: PropTypes.string,
        buttonKind: PropTypes.string,
        confirm: PropTypes.bool,
        // Whether to autoFocus the new password input
        autoFocusNewPasswordInput: PropTypes.bool,
    }

    Phases = {
        Edit: 'edit',
        Uploading: 'uploading',
        Error: 'error',
    }

    static defaultProps = {
        onFinished: function() {},
        onError: function() {},
        onCheckPassword: function(oldPass, newPass, confirmPass) {
            if (newPass !== confirmPass) {
                return {
                    error: _t("New passwords don't match"),
                };
            } else if (!newPass || newPass.length === 0) {
                return {
                    error: _t("Passwords can't be empty"),
                };
            } else if (!validatePassword(newPass).valid) {
                // not supposed to happen
                return {
                    error: _t('Invalid password'),
                };
            }
        },
        confirm: true,
    }

    state = {
        phase: this.Phases.Edit,
        cachedPassword: null,
        currentPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
    }

    componentWillUnmount() {
        if (this._sessionStoreToken) {
            this._sessionStoreToken.remove();
        }
    }

    componentDidMount() {
        this._sessionStore = sessionStore;
        this._sessionStoreToken = this._sessionStore.addListener(
            this._setStateFromSessionStore,
        );

        this._setStateFromSessionStore();
        const client = MatrixClientPeg.get();
        const rooms = client.getRooms();
        const userId = localStorage.getItem('mx_user_id');
        let hasEncryptedRooms = false;

        for (const room of rooms) {
            const user = room.getMember(userId);
            if (user
                && user.membership === 'join'
                && client.isRoomEncrypted(room.roomId)
            ) {
                hasEncryptedRooms = true;
                break;
            }
        }

        this.setState({ hasEncryptedRooms });
    }

    _setStateFromSessionStore = () => {
        this.setState({
            cachedPassword: this._sessionStore.getCachedPassword(),
        });
    }

    changePassword = (oldPassword, newPassword) => {
        const cli = MatrixClientPeg.get();

        if (!this.props.confirm || !this.state.hasEncryptedRooms) {
            this._changePassword(cli, oldPassword, newPassword);
            return;
        }

        const ChangePasswordDialog = sdk.getComponent('dialogs.ChangePasswordDialog');
        Modal.createTrackedDialog('Change Password', '', ChangePasswordDialog, {
            onFinished: (confirmed) => {
                if (confirmed) {
                    this._changePassword(cli, oldPassword, newPassword);
                }
            },
        },
            "change_password_info_dialog",
        );
    }

    _changePassword = (cli, oldPassword, newPassword) => {
        const authDict = {
            type: 'm.login.password',
            user: cli.credentials.userId,
            password: oldPassword,
        };

        this.setState({
            phase: this.Phases.Uploading,
        });

        cli.setPassword(authDict, newPassword).then(() => {
            // Notify SessionStore that the user's password was changed
            dis.dispatch({ action: 'password_changed' });

            if (this.props.shouldAskForEmail) {
                return this._optionallySetEmail().then((confirmed) => {
                    this.props.onFinished({
                        didSetEmail: confirmed,
                    });
                });
            } else {
                this.props.onFinished();
            }
        }, (err) => {
            this.props.onError(err);
        }).finally(() => {
            this.setState({
                phase: this.Phases.Edit,
                oldPassword: '',
                newPassword: '',
                newPasswordConfirm: '',
            });
        }).done();
    }

    _optionallySetEmail = () => {
        const deferred = Promise.defer();
        // Ask for an email otherwise the user has no way to reset their password
        const SetEmailDialog = sdk.getComponent('dialogs.SetEmailDialog');
        Modal.createTrackedDialog('Do you want to set an email address?', '', SetEmailDialog, {
            title: _t('Do you want to set an email address?'),
            onFinished: (confirmed) => {
                // ignore confirmed, setting an email is optional
                deferred.resolve(confirmed);
            },
        });
        return deferred.promise;
    }

    onInputChange = (ev) => {
        const { name, value } = ev.target;

        this.setState({
            [name]: value,
        });
    }

    onClickChange = ev => {
        ev.preventDefault();

        const { cachedPassword, currentPassword, newPassword, newPasswordConfirm } = this.state;
        const oldPassword = cachedPassword || currentPassword;

        const err = this.props.onCheckPassword(oldPassword, newPassword, newPasswordConfirm);
        if (err) {
            this.props.onError(err);
        } else {
            this.changePassword(oldPassword, newPassword);
        }
    }

    render() {
        // TODO: Live validation on `new pw == confirm pw`

        const { rowClassName, buttonClassName, className, autoFocusNewPasswordInput, buttonKind } = this.props;
        const { currentPassword, newPassword, newPasswordConfirm, cachedPassword, phase } = this.state;

        const validPassword = validatePassword(newPassword).valid && newPassword === newPasswordConfirm;

        let currentPasswordField = null;
        if (!cachedPassword) {
            currentPasswordField = (
                <div className={rowClassName}>
                    <CitadelInput
                        autoComplete="none"
                        label={_t('Current password')}
                        name="currentPassword"
                        type="password"
                        onChange={this.onInputChange}
                        value={currentPassword}
                    />
                </div>
            );
        }

        switch (phase) {
            case this.Phases.Edit: {
                const passwordLabel = cachedPassword ? _t('Password') : _t('New Password');
                return (
                    <form className={className} onSubmit={this.onClickChange}>
                        {currentPasswordField}
                        <div className={rowClassName}>
                            <CitadelInput
                                autoComplete="none"
                                label={passwordLabel}
                                name="newPassword"
                                type="password"
                                onChange={this.onInputChange}
                                value={newPassword}
                                autoFocus={autoFocusNewPasswordInput}
                            />
                        </div>
                        <div className={rowClassName}>
                            <CitadelInput
                                autoComplete="none"
                                label={_t('Confirm password')}
                                name="newPasswordConfirm"
                                type="password"
                                onChange={this.onInputChange}
                                value={newPasswordConfirm}
                            />
                        </div>
                        <PasswordValidation
                            passwordPolicy={validatePassword(newPassword)}
                        />
                        <AccessibleButton
                            className={buttonClassName}
                            kind={buttonKind}
                            onClick={this.onClickChange}
                            disabled={!validPassword}
                        >
                            {_t('Change Password')}
                        </AccessibleButton>
                    </form>
                );
            }
            case this.Phases.Uploading: {
                const Loader = sdk.getComponent('elements.Spinner');
                return (
                    <div className="mx_Dialog_content">
                        <Loader />
                    </div>
                );
            }
        }
    }
}
