import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import cx from 'classnames';

import BaseDialog from '../BaseDialog';
import { _t } from '../../../../languageHandler';
import syncSuccessIcon from '../../../../../res/img/keys-sinc.svg';
import syncErrorIcon from '../../../../../res/img/keys-sinc-error.svg';

export const DIALOG_TYPE = {
    SUCCESS: 'success',
    ERROR: 'error',
    NO_KEYS: 'no-keys',
};

export default class KeySyncSuccessOrFailure extends Component {
    constructor(props) {
        super(props);

        this.unmounted = false;
    }

    componentDidMount() {
        const { type, onFinished } = this.props;
        const isSuccess = type === DIALOG_TYPE.SUCCESS;

        if (isSuccess) {
            this.closeHandler = setTimeout(onFinished, 3000);
        }
    }

    componentWillUnmount() {
        this.closeHandler && clearTimeout(this.closeHandler);
    }

    render() {
        const { type, onFinished } = this.props;
        const isSuccess = type === DIALOG_TYPE.SUCCESS;
        let title = _t('An error occurred');
        let text = _t('Your keys could not be synchronized');
        let icon = syncErrorIcon;
        let button = <button className="failure_continue" onClick={onFinished} autoFocus={true}>
            {_t('Continue')}
        </button>;

        switch (type) {
            case DIALOG_TYPE.SUCCESS:
                title = null;
                text = _t('Your keys have been synchronized');
                icon = syncSuccessIcon;
                button = null;
                break;
            case DIALOG_TYPE.NO_KEYS:
                title = _t('This device does not have the key required to decrypt the message');
                text = null;
                break;
        }

        return (
            <BaseDialog
                className="mx_KeySyncSuccessOrFailure"
                contentId="mx_Dialog_content"
                fixedWidth={false}
                hasCancel={false}
                isFocusable={!isSuccess}
                onFinished={this.props.onFinished}
                title=""
            >
                <img className="icon" src={icon} alt="" />
                <div className="title">{title}</div>
                <div className={cx('text', { success: isSuccess })}>{text}</div>
                {button}
            </BaseDialog>
        );
    }
}

KeySyncSuccessOrFailure.propTypes = {
    onFinished: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
};

KeySyncSuccessOrFailure.defaultProps = {
    onFinished: noop,
    type: DIALOG_TYPE.ERROR,
};
