/*
Copyright 2019 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React from 'react';
import {_t} from '../../../languageHandler';
import MatrixClientPeg from "../../../MatrixClientPeg";
import BaseAvatar from '../../views/avatars/BaseAvatar';
import classNames from 'classnames';
import CitadelInfo from '../elements/CitadelInfo';
import EmailAddresses from './EmailAddresses';
import UserStore from '../../../stores/UserStore';
import dis from '../../../dispatcher';
import { fetchUrlWithToken, getMxcFromUrl } from '../../../utils/MediaUtils';

export default class ProfileSettings extends React.Component {
    constructor() {
        super();

        const client = MatrixClientPeg.get();
        const { userId, avatarUrl, rawDisplayName, email } = UserStore.getMe();
        const httpAvatarUrl = client.mxcUrlToHttp(avatarUrl, 96, 96, 'crop', false);

        this.state = {
            userId,
            originalDisplayName: rawDisplayName,
            displayName: rawDisplayName,
            originalAvatarUrl: httpAvatarUrl,
            avatarUrl: httpAvatarUrl,
            avatarFile: null,
            enableProfileSave: false,
            email,
        };
    }

    _uploadAvatar = (e) => {
        e.stopPropagation();
        e.preventDefault();

        this.refs.avatarUpload.click();
    };

    _saveProfile = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!this.state.enableProfileSave) return;
        this.setState({enableProfileSave: false});

        const client = MatrixClientPeg.get();
        const newState = {};

        // TODO: What do we do about errors?

        if (this.state.originalDisplayName !== this.state.displayName) {
            await client.setDisplayName(this.state.displayName);
            newState.originalDisplayName = this.state.displayName;
        }

        this.setState(newState);
    };

    _saveAvatar = async (avatarFile) => {
        const client = MatrixClientPeg.get();
        const newState = {};

        const uri = await client.uploadContent(avatarFile, {
            endpoint: '/upload/avatar',
            prefix: '/_matrix/media/r0',
        });
        await client.setAvatarUrl(uri);
        newState.avatarUrl = client.mxcUrlToHttp(uri, 96, 96, 'crop', false);
        newState.originalAvatarUrl = newState.avatarUrl;
        newState.avatarFile = null;

        this.setState(newState);

        fetchUrlWithToken(client.mxcUrlToHttp(uri))
            .then((blob) => {
                if (blob) {
                    dis.dispatch({
                        action: 'update_blob_store',
                        mxc: getMxcFromUrl(newState.avatarUrl),
                        blob,
                        emitChange: true,
                    });
                }
            })
            .catch((err) => {
                console.error('Could not retrieve url content', err);
            });

        dis.dispatch({
            action: 'USER_STORE_SAVE_USER',
            user: {
                ...UserStore.getMe(),
                avatarUrl: uri,
            },
        });
    };

    _onAvatarChanged = (e) => {
        if (!e.target.files || !e.target.files.length) {
            this.setState({
                avatarUrl: this.state.originalAvatarUrl,
                avatarFile: null,
                enableProfileSave: false,
            });
            return;
        }

        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (ev) => {
            this._saveAvatar(file);
        };
        reader.readAsDataURL(file);
    };

    render() {
        // TODO: Why is rendering a box with an overlay so complicated? Can the DOM be reduced?
        const { userId, displayName, avatarUrl, email } = this.state;

        let showOverlayAnyways = true;
        let avatarElement = <div className="mx_ProfileSettings_avatarPlaceholder" />;
        if (this.state.avatarUrl) {
            showOverlayAnyways = false;
            avatarElement = (
                <BaseAvatar
                    idName={userId}
                    name={displayName}
                    url={avatarUrl}
                    width={28}
                    height={28}
                    resizeMethod="crop"
                />
            );
        }

        const avatarOverlayClasses = classNames({
            "mx_ProfileSettings_avatarOverlay": true,
            "mx_ProfileSettings_avatarOverlay_show": showOverlayAnyways,
        });
        const avatarHoverElement = (
            <div className={avatarOverlayClasses} onClick={this._uploadAvatar}>
                <span className="mx_ProfileSettings_avatarOverlayText">{_t("Upload profile picture")}</span>
                <div className="mx_ProfileSettings_avatarOverlayImgContainer">
                    <div className="mx_ProfileSettings_avatarOverlayImg" />
                </div>
            </div>
        );

        return (
            <form onSubmit={this._saveProfile} noValidate={true}>
                <input type="file" ref="avatarUpload" className="mx_ProfileSettings_avatarUpload"
                       onChange={this._onAvatarChanged} accept="image/*" />
                <div className="mx_ProfileSettings_profile">
                    <div className="mx_ProfileSettings_avatar">
                        {avatarElement}
                        {avatarHoverElement}
                    </div>
                    <div className="mx_ProfileSettings_controls">
                        <CitadelInfo
                            label={_t('Display Name')}
                            value={displayName}
                        />
                        <CitadelInfo
                            label={_t('User ID')}
                            value={userId}
                        />
                        <EmailAddresses email={email} />
                    </div>
                </div>
            </form>
        );
    }
}
