import React from 'react';
import PropTypes from 'prop-types';

import MatrixClientPeg from '../../../MatrixClientPeg';

import rateLimitedFunc from '../../../ratelimitedfunc';
import { showMembersList/*, hideMembersList*/ } from '../../../Rooms';
import { _t } from '../../../languageHandler';

class CitadelMembersLink extends React.Component {
    state = {
        membersCount: 0,
    };

    componentDidMount() {
        this.unmounted = false;
        this.cli = MatrixClientPeg.get();
        if (!this.cli) return;

        this.room = this.cli.getRoom(this.props.roomId);
        if (!this.room) return;

        if (this.cli.hasLazyLoadMembersEnabled()) {
            this.lazyLoadMembers();
        } else {
            this.listenForMembersChanges();
        }
    }

    componentWillUnmount() {
        this.unmounted = true;
        if (this.cli) {
            this.cli.removeListener('RoomState.members', this.onRoomStateMember);
            this.cli.removeListener('RoomMember.name', this.onRoomMemberName);
            this.cli.removeListener('RoomState.events', this.onRoomStateEvent);
        }
    }

    async lazyLoadMembers() {
        try {
            await this.room.loadMembersIfNeeded();
        } catch (ignore) {}

        const members = this._roomMembers();

        if (!this.unmounted) {
            this.setState({ membersCount: members.length });
        }

        this.listenForMembersChanges();
    }

    listenForMembersChanges() {
        this.cli.on('RoomState.members', this.onRoomStateMember);
        this.cli.on('RoomMember.name', this.onRoomMemberName);
        this.cli.on('RoomState.events', this.onRoomStateEvent);
    }

    onRoomStateMember = (ev, state, member) => {
        if (member.roomId !== this.props.roomId) {
            return;
        }
        this._updateList();
    };

    onRoomMemberName = (ev, member) => {
        if (member.roomId !== this.props.roomId) {
            return;
        }
        this._updateList();
    };

    onRoomStateEvent = (event) => {
        if (event.getRoomId() === this.props.roomId &&
            event.getType() === 'm.room.third_party_invite') {
            this._updateList();
        }
    };

    _updateList = rateLimitedFunc(() => {this._updateListNow();}, 500);

    _roomMembers = () => {
        const allMembers = Object.values(this.room.currentState.members);
        return allMembers.filter(m => ['join', 'invite'].includes(m.membership));
    };

    _updateListNow() {
        const members = this._roomMembers();
        if (!this.unmounted) {
            this.setState({ membersCount: members.length });
        }
    }

    render() {
        const { collapsedRhs } = this.props;

        const { membersCount } = this.state;
        const showOrHideMembersList = collapsedRhs ? showMembersList : showMembersList;

        return (
            <a className="mx_RoomView_messagePanelMembersCount" onClick={showOrHideMembersList}>
                {_t('%(membersCount)s members', { membersCount })}
            </a>
        );
    }
}

CitadelMembersLink.propTypes = {
    roomId: PropTypes.string.isRequired,
    collapsedRhs: PropTypes.bool,
};

export default CitadelMembersLink;
