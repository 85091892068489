import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import memoize from 'memoize-one';
import { isEqual } from 'lodash';

import UserStore from '../../../stores/UserStore';
import ParticipantAvatar from './ParticipantAvatar';
import micOff from '../../../../res/img/conference/mic-off-no-bg.svg';
import { getDisplayName } from '../../../utils/Citadex';
import CitadexContextButton from './CitadexContextButton';
import CitadexContextMenu from './CitadexContextMenu';
import CitadexContextOption from './CitadexContextOption';
import { _t } from '../../../languageHandler';
import CitadexStore from "../../../stores/CitadexStore";

const getDisplayNameMemo = memoize((user) => getDisplayName(user), isEqual);

class ParticipantRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contextOpen: false,
            mouseX: 0,
            mouseY: 0,
        };
    }

    openContext = (e) => {
        this.setState({
            contextOpen: true,
            mouseX: e.clientX,
            mouseY: e.clientY,
        });
    }

    closeContext = () => this.setState({contextOpen: false})

    handleMute = (e) => {
        e.stopPropagation();
        this.closeContext();
        this.props.onMute();
    }

    handleKick = (e) => {
        e.stopPropagation();
        this.closeContext();
        this.props.onKick();
    }

    render() {
        const { userId, userName, muted, isSmall = false, talking = false } = this.props;
        const user = UserStore.getUserById(userId);
        const { contextOpen, mouseX, mouseY } = this.state;
        const myId = localStorage.getItem('mx_user_id');
        const callEvent = CitadexStore.getCallInProgress();
        const shouldShowContext = callEvent && myId === callEvent.event.content.inviter_id
            && myId !== userId;
        const containerClassName = cx(
            'citadex_visio_audio_participant_row',
            { small: isSmall },
            { talking },
        );
        return (
            <div className={containerClassName} onClick={this.openContext}>
                <div className='citadex_visio_audio_participant_avatar_name'>
                    <ParticipantAvatar { ...this.props } size={isSmall ? 'small': 'medium'} />
                    <span>{userName || getDisplayNameMemo(user)}</span>
                </div>
                {muted ? <img className='audio_muted_icon' src={micOff} width="33px" alt="" /> : null}
                {shouldShowContext && <CitadexContextButton />}
                {shouldShowContext && contextOpen && <CitadexContextMenu
                    center={true}
                    className="citadex_context_menu_centered"
                    mouseX={mouseX}
                    mouseY={mouseY}
                    onClose={this.closeContext}
                >
                    <CitadexContextOption onClick={this.handleMute} className='mute'>
                        {_t('Mute participant')}
                    </CitadexContextOption>
                    <CitadexContextOption onClick={this.handleKick} className='kick'>
                        {_t('Remove from conference')}
                    </CitadexContextOption>
                </CitadexContextMenu>}
            </div>
        );
    }
}

ParticipantRow.propTypes = {
    userId: PropTypes.string.isRequired,
    userName: PropTypes.string,
    muted: PropTypes.bool.isRequired,
    isSmall: PropTypes.bool,
    onMute: PropTypes.func.isRequired,
    onKick: PropTypes.func.isRequired,
    talking: PropTypes.bool,
};

export default ParticipantRow;
