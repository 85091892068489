import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MatrixClientPeg from '../../../MatrixClientPeg';
import RoomAvatar from '../avatars/RoomAvatar';
import { isDirectMessageRoom } from '../../../Rooms';
import { _t } from '../../../languageHandler';
import Modal from '../../../Modal';
import NewTopicDialog from '../dialogs/NewTopicDialog';
import UserStore from '../../../stores/UserStore';
import dis from '../../../dispatcher';

import editIcon from '../../../../res/img/edit.svg';

class TimelineStarterCard extends React.Component {
    constructor(props) {
        super(props);
        this.unmounted = false;
        const client = MatrixClientPeg.get();
        this.state = {
            newTopic: '',
            hasCheckedDMStatus: false,
            dmUser: {
                email: '',
                displayName: '',
            },
            canSetTopic: props.room &&
                props.room.currentState.maySendStateEvent('m.room.topic', client.getUserId()) &&
                props.room.getMyMembership() === 'join',
        };
    }

    componentDidMount() {
        this.checkDMStatus();
    }

    componentDidUpdate() {
        this.checkDMStatus();
    }

    checkDMStatus = async () => {
        if (this.state.hasCheckedDMStatus) return;

        const { room } = this.props;
        if (!room) return;

        const { isDmRoom, dmUserIdOrEmail } = isDirectMessageRoom(room);
        if (isDmRoom && dmUserIdOrEmail[0] === '@') {
            const { displayName, email } = UserStore.getUserById(dmUserIdOrEmail);
            if (!email) {
                const client = MatrixClientPeg.get();
                const { address } = await client.getProfileInfo(dmUserIdOrEmail, '3pid');
                const user = {
                    userId: dmUserIdOrEmail,
                    displayName,
                    email: address || '',
                };
                if (user.email) {
                    dis.dispatch({
                        action: 'USER_STORE_SAVE_USER',
                        user,
                    });
                }
                if (!this.unmounted) {
                    this.setState({
                        isDmRoom,
                        dmUser: user,
                        hasCheckedDMStatus: true,
                    });
                }
            } else {
                if (!this.unmounted) {
                    this.setState({
                        isDmRoom,
                        dmUser: {
                            userId: dmUserIdOrEmail,
                            displayName,
                            email,
                        },
                        hasCheckedDMStatus: true,
                    });
                }
            }
        } else {
            // If isDmRoom === false, dmUserIdOrEmail === undefined which is ok.
            if (!this.unmounted) {
                this.setState({
                    isDmRoom,
                    dmUser: {
                        email: '',
                    },
                    hasCheckedDMStatus: true,
                });
            }
        }
    };

    addTopic = (ev) => {
        ev.preventDefault();
        const roomId = this.props.room.roomId;
        Modal.createTrackedDialog('Display News', '', NewTopicDialog, {
            roomId,
            onFinished: (topic) => {
                this.setState({
                    newTopic: topic,
                });
            },
        });
    };

    componentWillUnmount() {
        this.unmounted = true;
    }

    render() {
        const { room } = this.props;

        if (room) {
            const { isDmRoom, dmUser, newTopic, canSetTopic } = this.state;

            const avatarSize = 88;
            const avatar = <RoomAvatar
                room={room}
                width={avatarSize}
                height={avatarSize}
            />;

            let name = room.name;

            let additionalInfo = '';
            if (isDmRoom) {
                // We dont need to display a disambiguated name here, since we already display the email address below
                name = UserStore.getRawDisplayName(name);
                additionalInfo = dmUser.email || dmUser.userId || '';
            } else if (isDmRoom === false) {
                const ev = room.currentState.getStateEvents('m.room.topic', '');
                const topic = ev ? ev.getContent().topic : newTopic || '';

                if (canSetTopic) {
                    additionalInfo =
                        <div className="edit-topic" onClick={this.addTopic}>
                            {
                                !topic && <div className="icon">
                                    <img src={editIcon} alt="" />
                                </div>
                            }
                            {
                                topic || <div className="text">
                                    {_t('Add a description...')}
                                </div>
                            }
                        </div>;
                } else if (topic) {
                    additionalInfo =
                        <div>
                            {topic}
                        </div>;
                }
            }

            const additionalInfoClassNames = classNames({
                'additional-info': true,
                'is-dm': isDmRoom,
            });

            return (
                <div className="mx_TimelineStarterCard">
                    <div className="room-avatar">
                        {avatar}
                    </div>
                    <div className="room-info">
                        <div className="name">
                            {name}
                        </div>
                        {additionalInfo &&
                        <div className={additionalInfoClassNames}>
                            {additionalInfo}
                        </div>
                        }
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

TimelineStarterCard.propTypes = {
    room: PropTypes.object,
};

export default TimelineStarterCard;
