/*
Copyright 2015, 2016 OpenMarket Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import Promise from 'bluebird';
import {filter, isEmpty} from 'lodash';
import dis from './dispatcher';
import MatrixClientPeg from './MatrixClientPeg';
import DMRoomMap from './utils/DMRoomMap';
import UserStore from './stores/UserStore';
import SettingsStore from './settings/SettingsStore';

/**
 * Given a room object, return the alias we should use for it,
 * if any. This could be the canonical alias if one exists, otherwise
 * an alias selected arbitrarily but deterministically from the list
 * of aliases. Otherwise return null;
 */
export function getDisplayAliasForRoom(room) {
    return room.getCanonicalAlias() || room.getAliases()[0];
}

/**
 * If the room contains only two members including the logged-in user,
 * return the other one. Otherwise, return null.
 */
export const getOnlyOtherMember = (room, myUserId) => {
    if (room.currentState.getJoinedMemberCount() === 2) {
        const onlyOtherMembers = filter(room.getJoinedMembers(), ({ userId }) => userId !== myUserId);

        if (onlyOtherMembers[0]) return onlyOtherMembers[0];
    }

    return null;
};

function _isConfCallRoom(room, myUserId, conferenceHandler) {
    if (!conferenceHandler) return false;

    const myMembership = room.getMyMembership();
    if (myMembership !== 'join') {
        return false;
    }

    const otherMember = getOnlyOtherMember(room, myUserId);
    if (!otherMember) {
        return false;
    }

    if (conferenceHandler.isConferenceUser(otherMember.userId)) {
        return true;
    }

    return false;
}

// Cache whether a room is a conference call. Assumes that rooms will always
// either will or will not be a conference call room.
const isConfCallRoomCache = {
    // $roomId: bool
};

export function isConfCallRoom(room, myUserId, conferenceHandler) {
    if (isConfCallRoomCache[room.roomId] !== undefined) {
        return isConfCallRoomCache[room.roomId];
    }

    const result = _isConfCallRoom(room, myUserId, conferenceHandler);

    isConfCallRoomCache[room.roomId] = result;

    return result;
}

export function looksLikeDirectMessageRoom(room, myUserId) {
    const myMembership = room.getMyMembership();
    const me = room.getMember(myUserId);

    if (myMembership === 'join' || myMembership === 'ban' || (me && me.isKicked())) {
        // Used to split rooms via tags
        const tagNames = Object.keys(room.tags);
        // Used for 1:1 direct chats
        // Show 1:1 chats in seperate "Direct Messages" section as long as they haven't
        // been moved to a different tag section
        const totalMemberCount = room.currentState.getJoinedMemberCount() +
            room.currentState.getInvitedMemberCount();
        if (totalMemberCount === 2 && !tagNames.length) {
            return true;
        }
    }
    return false;
}

export function guessAndSetDMRoom(room, isDirect) {
    const newTarget = isDirect ? guessDMRoomTargetId(room, MatrixClientPeg.get().getUserId()) : null;

    return setDMRoom(room.roomId, newTarget);
}

/**
 * Marks or unmarks the given room as being as a DM room.
 * @param {string} roomId The ID of the room to modify
 * @param {string} userId The user ID of the desired DM
                   room target user or null to un-mark
                   this room as a DM room
 * @returns {object} A promise
 */
export function setDMRoom(roomId, userId) {
    if (MatrixClientPeg.get().isGuest()) {
        return Promise.resolve();
    }

    const mDirectEvent = MatrixClientPeg.get().getAccountData('m.direct');
    let dmRoomMap = {};

    if (mDirectEvent !== undefined) dmRoomMap = mDirectEvent.getContent();

    // remove it from the lists of any others users
    // (it can only be a DM room for one person)
    for (const thisUserId of Object.keys(dmRoomMap)) {
        const roomList = dmRoomMap[thisUserId];

        if (thisUserId !== userId) {
            const indexOfRoom = roomList.indexOf(roomId);
            if (indexOfRoom > -1) {
                roomList.splice(indexOfRoom, 1);
            }
        }
    }

    // now add it, if it's not already there
    if (userId) {
        const roomList = dmRoomMap[userId] || [];
        if (roomList.indexOf(roomId) === -1) {
            roomList.push(roomId);
        }
        dmRoomMap[userId] = roomList;
    }

    DMRoomMap.shared().addDMRoom(roomId, userId);
    return MatrixClientPeg.get().setAccountData('m.direct', dmRoomMap);
}

/**
 * Given a room, estimate which of its members is likely to
 * be the target if the room were a DM room and return that user.
 *
 * @param {Object} room Target room
 * @param {string} myUserId User ID of the current user
 * @returns {string} User ID of the user that the room is probably a DM with
 */
function guessDMRoomTargetId(room, myUserId) {
    let oldestTs;
    let oldestUser;

    // Pick the joined user who's been here longest (and isn't us),
    for (const user of room.getJoinedMembers()) {
        if (user.userId === myUserId) continue;

        if (oldestTs === undefined || (user.events.member && user.events.member.getTs() < oldestTs)) {
            oldestUser = user;
            oldestTs = user.events.member.getTs();
        }
    }
    if (oldestUser) return oldestUser.userId;

    // if there are no joined members other than us, use the oldest member
    for (const user of room.currentState.getMembers()) {
        if (user.userId === myUserId) continue;

        if (oldestTs === undefined || (user.events.member && user.events.member.getTs() < oldestTs)) {
            oldestUser = user;
            oldestTs = user.events.member.getTs();
        }
    }

    if (oldestUser === undefined) return myUserId;
    return oldestUser.userId;
}

export const showMembersList = () => {
    dis.dispatch({
        action: 'show_right_panel',
    });

    dis.dispatch({
        action: 'view_right_panel_phase',
        phase: 'RoomMemberList',
    });
};

export const hideMembersList = () => {
    dis.dispatch({
        action: 'hide_right_panel',
    });
};

export const isDirectMessageRoom = (room) => {
    const dmUserIdOrEmail = DMRoomMap.shared().getUserIdForRoomId(room.roomId) || '';
    return { isDmRoom: Boolean(dmUserIdOrEmail), dmUserIdOrEmail };
};

export const isFederatedRoom = (room) => {
    const createEvent = room.currentState.getStateEvents('m.room.create', '');
    const isFederatedRoom = (createEvent && createEvent.getContent() && createEvent.getContent()['m.federate']);

    return (typeof isFederatedRoom !== 'undefined') ? isFederatedRoom : true;
};

export const isEncryptedRoom = (room) => {
    return MatrixClientPeg.get().isRoomEncrypted(room.roomId);
};

export const getJoinRules = (room) => {
    const joinRuleEvent = room.currentState.getStateEvents('m.room.join_rules', '');
    return (joinRuleEvent && joinRuleEvent.getContent() && joinRuleEvent.getContent().join_rule);
};

export const getHistoryVisibility = (room) => {
    const historyVisibilityEvent = room.currentState.getStateEvents("m.room.history_visibility", "");
    return historyVisibilityEvent && historyVisibilityEvent.getContent().history_visibility;
};

export const getRoomName = (room) => {
    const { isDmRoom, dmUserIdOrEmail } = isDirectMessageRoom(room);
    return (isDmRoom && dmUserIdOrEmail[0] === "@") ?
        UserStore.getDisambiguatedNameWithEmail(dmUserIdOrEmail, room.name) : room.name;
};

export const getRoomTopic = (room) => {
    const nameEvent = room.currentState.getStateEvents('m.room.topic', '');
    return (nameEvent && nameEvent.getContent() && nameEvent.getContent()['topic']) || '';
};

export const getPowerLevels = (room) => {
    const powerLevelEvent = room.currentState.getStateEvents('m.room.power_levels', '');
    return powerLevelEvent && powerLevelEvent.getContent();
};

export const canSendEvent = (room, eventType) => {
    const client = MatrixClientPeg.get();
    return room.currentState.mayClientSendStateEvent(eventType, client);
};

export const getLastEventByType = (room, type) => {
    if (!room || !type) return undefined;
    const events = room.currentState.getStateEvents(type).sort(
        (ev1, ev2) => ev2.event.origin_server_ts - ev1.event.origin_server_ts,
    );
    const event = events[0];
    return event ? event : undefined;
};

export const getCallInProgress = room => {
    const conferenceMatrixEvent = getLastEventByType(room, 'citadel.conference');
    return conferenceMatrixEvent ? conferenceMatrixEvent.event.content : {};
};

export const getCallInProgressType = room => {
    const evt = getCallInProgress(room);
    let type = null;
    if (['open', 'empty'].includes(evt.status)) {
        type = evt.type;
    }
    return type;
};

export const getKeyRequest = room => {
    const keyRequestMatrixEvent = getLastEventByType(room, 'citadel.keyrequest');
    return keyRequestMatrixEvent ? keyRequestMatrixEvent.event.content : {};
};

export const containsNewKeyRequest = (ev) => {
    // there is no previous event or the list was empty
    if (!ev.getPrevContent() || isEmpty(ev.getPrevContent().requesters)) {
        return true;
    }

    // If the current list is bigger, then it clearly is a new request.
    if (ev.getContent().requesters > ev.getPrevContent().requesters) return true;

    return ev.getPrevContent().requesters.every(requester => {
        return ev.getContent().requesters.includes(requester);
    });
};

export const shouldHideServiceMessages = (room) => {
    if (!room) return false;
    const { roomId } = room;
    const joinRuleEvent = room.currentState.getStateEvents('m.room.join_rules', '');
    const powerLevelEvent = room.currentState.getStateEvents('m.room.power_levels', '');
    const writePowerLevel = powerLevelEvent && powerLevelEvent.getContent().events_default;
    const roomType = joinRuleEvent && joinRuleEvent.getContent().join_rule;
    const settingsValueForPublicAndReadOnlyRooms = SettingsStore.getValue('enabled_public_read_only_rooms', roomId);
    const settingsValueForPrivateRooms = SettingsStore.getValue('enabled_private_rooms', roomId);
    const showServiceMessagesData = JSON.parse(localStorage.getItem('show_service_messages_for_rooms') || '{}');

    // for public and readonly rooms
    if (roomType === 'public' || writePowerLevel === 100) {
        if (!settingsValueForPublicAndReadOnlyRooms) {
            if (showServiceMessagesData[roomId] === undefined) {
                return true;
            }
            return !showServiceMessagesData[roomId];
        } else {
            return false;
        }
    }

    // for private rooms
    if (roomType !== 'public') {
        if (!settingsValueForPrivateRooms) {
            if (showServiceMessagesData[roomId] === undefined) {
                return true;
            }
            return !showServiceMessagesData[roomId];
        } else {
            return false;
        }
    }

    // if there is no value, the message should be displayed
    if (showServiceMessagesData[roomId] === undefined) return false;

    return !showServiceMessagesData[roomId] || false;
};
