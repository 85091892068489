import React from 'react';
import PropTypes from 'prop-types';
import Truncate from "react-truncate";

import EmojiText from "./EmojiText";

const CitadelRoomName = ({ roomName, className, width, lines }) => {
    return (
        <Truncate lines={lines} width={width} className={className}>
            <EmojiText dir='auto' element='div' title={roomName}>
                {roomName}
            </EmojiText>
        </Truncate>
    );
};

CitadelRoomName.propTypes = {
    className: PropTypes.string,
    lines: PropTypes.number,
    roomName: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
};

CitadelRoomName.defaultProps = {
    className: '',
    lines: 1,
    roomName: 'unknown room',
    width: 300,
};

export default CitadelRoomName;
