import React from 'react';
import PropTypes from 'prop-types';

import { _t } from '../../../../languageHandler';
import CountryDropdown from '../../auth/CountryDropdown';
import CitadelPhoneInput from '../../elements/CitadelPhoneInput';
import MfaManager from './MfaManager';
import CitadelButton from '../../elements/CitadelButton';

export default class PhoneNumberForm extends React.Component {
    static displayName = "PhoneNumberForm";

    static propTypes = {
        onSuccess: PropTypes.func.isRequired,
    };

    state = {
        phoneNumber: '',
        phoneCountry: '',
        phoneCountryCode: '',
        phoneCountryIso: '',
        error: null,
    };

    onSubmit = async () => {
        const { phoneCountryCode, phoneCountryIso, phoneNumber } = this.state;
        const res = await MfaManager.requestAddToken({ phoneCountryCode, phoneCountryIso, phoneNumber });

        res && this.props.onSuccess();
    }

    onPhoneNumberChange = (phoneNumber, isPhoneValid) => {
        this.setState({
            phoneNumber,
            error: !isPhoneValid,
        });
    };

    onCountryChange = (country) => {
        this.setState({
            phoneCountry: country.name,
            phoneCountryCode: `+${country.prefix}`,
            phoneCountryIso: country.iso2,
        });
    };

    _renderCountryDropdown = () => {
        return <CountryDropdown
            value={this.state.phoneCountry}
            isSmall={false}
            useValue={true}
            noAuto={true}
            showPrefix={false}
            className="mx_CitadelCountryDropdown"
            onOptionChange={this.onCountryChange}
        />;
    }

    _renderPhoneNumber = () => {
        const { phoneNumber, phoneCountry, phoneCountryIso, phoneCountryCode } = this.state;
        const isPhoneDisabled = !phoneCountry ? 'disabled' : '';

        return <div className={`mx_CitadelPhoneInput_wrapper ${isPhoneDisabled}`}>
            <CitadelPhoneInput
                id='mfa-phone-number'
                className={`mx_CitadelPhoneInput`}
                label={_t('Phone number')}
                value={phoneNumber}
                prefix={phoneCountryCode}
                onChange={this.onPhoneNumberChange}
                disabled={!phoneCountry}
                code={phoneCountryIso}
            />
        </div>;
    }

    render() {
        const { phoneNumber, error } = this.state;

        return <div className="mx_PhoneNumberForm">
            <div className="subtitle">
                {_t('Enter your mobile phone number')}
            </div>
            <div className="description">
                {_t('A verification code will be sent to you to check your device.')}
            </div>
            <div className="label">
                {_t('Select your country')}
            </div>
            {this._renderCountryDropdown()}
            {this._renderPhoneNumber()}
            <CitadelButton
                text={_t('Send an SMS')}
                onClick={this.onSubmit}
                isDisabled={!phoneNumber || error}
                minWidth={152}
            />
        </div>;
    }
}
