/*
Copyright 2015, 2016 OpenMarket Ltd
Copyright 2017 Vector Creations Ltd
Copyright 2018, 2019 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { _t } from '../../languageHandler';
import sdk from '../../index';
import dis from '../../dispatcher';
import Unread from '../../Unread';
import * as RoomNotifs from '../../RoomNotifs';
import * as FormattingUtils from '../../utils/FormattingUtils';
import IndicatorScrollbar from './IndicatorScrollbar';
import { Group } from 'matrix-js-sdk';
import RoomTile from '../views/rooms/RoomTile';
import LazyRenderList from '../views/elements/LazyRenderList';
import AccessibleButton from '../views/elements/AccessibleButton';
import { HintedComponent } from '../views/elements/HintedComponent';
import MatrixClientPeg from '../../MatrixClientPeg';
import * as Rooms from '../../Rooms';
import UserStore from '../../stores/UserStore';

// turn this on for drop & drag console debugging galore
const debug = false;

class RoomSubList extends Component {
    static displayName = 'RoomSubList'

    static debug = debug

    static propTypes = {
        id: PropTypes.string.isRequired,
        list: PropTypes.arrayOf(PropTypes.object).isRequired,
        label: PropTypes.string.isRequired,
        tagName: PropTypes.string,
        type: PropTypes.string,

        order: PropTypes.string.isRequired,

        // passed through to RoomTile and used to highlight room with `!` regardless of notifications count
        isInvite: PropTypes.bool,

        startAsHidden: PropTypes.bool,
        showSpinner: PropTypes.bool, // true to show a spinner if 0 elements when expanded
        collapsed: PropTypes.bool.isRequired, // is LeftPanel collapsed?
        onHeaderClick: PropTypes.func,
        incomingCall: PropTypes.object,
        isFiltered: PropTypes.bool,
        headerItems: PropTypes.node, // content shown in the sublist header
        extraTiles: PropTypes.arrayOf(PropTypes.node), // extra elements added beneath tiles
    }

    state = {
        hidden: this.props.startAsHidden || false,
        // some values to get LazyRenderList starting
        scrollerHeight: 800,
        scrollTop: 0,
        isHintDisplayed: false,
    }

    static defaultProps = {
        onHeaderClick: function() {
        }, // NOP
        extraTiles: [],
        isInvite: false,
    }

    componentDidMount() {
        const cli = MatrixClientPeg.get();
        cli.on('RoomState.events', this.onRoomStateEvents);
        this.dispatcherRef = dis.register(this.onAction);
        this.calculateIsHintDisplayed();
    }

    componentWillUnmount() {
        if (MatrixClientPeg.get()) {
            MatrixClientPeg.get().removeListener("RoomState.events", this.onRoomStateEvents);
        }
        dis.unregister(this.dispatcherRef);
    }

    onRoomStateEvents = (event) => {
        if (event.getType() === 'citadel.keyrequest') {
            this.forceUpdate();
        }
    }

    calculateIsHintDisplayed = () => {
        const { id } = this.props;

        const isHintDisplayed = !MatrixClientPeg.get().getInAppMessageFlag(`has_shown_create_${id}_web`);

        const addRoomElement = document.getElementById(id);
        if (isHintDisplayed && addRoomElement) {
            const { top, left } = addRoomElement.getBoundingClientRect();
            const hintStyle = {
                top: top + 45,
                left: left - 15,
                position: 'absolute',
            };

            this.setState({
                isHintDisplayed,
                hintStyle,
            });
        }
    }

    // The header is collapsable if it is hidden or not stuck
    // The dataset elements are added in the RoomList _initAndPositionStickyHeaders method
    isCollapsableOnClick = () => {
        const stuck = this.refs.header.dataset.stuck;

        return !this.props.forceExpand && (
            this.state.hidden ||
            stuck === undefined ||
            stuck === "none"
        );
    }

    onAction = (payload) => {
        // XXX: Previously RoomList would forceUpdate whenever on_room_read is dispatched,
        // but this is no longer true, so we must do it here (and can apply the small
        // optimisation of checking that we care about the room being read).
        //
        // Ultimately we need to transition to a state pushing flow where something
        // explicitly notifies the components concerned that the notif count for a room
        // has change (e.g. a Flux store).
        if (payload.action === 'on_room_read' &&
            this.props.list.some((r) => r.roomId === payload.roomId)
        ) {
            this.forceUpdate();
        }
    }

    onClick = (ev) => {
        if (this.isCollapsableOnClick()) {
            // The header isCollapsable, so the click is to be interpreted as collapse and truncation logic
            const isHidden = !this.state.hidden;
            this.setState({hidden: isHidden}, () => {
                this.props.onHeaderClick(isHidden);
            });
        } else {
            // The header is stuck, so the click is to be interpreted as a scroll to the header
            this.props.onHeaderClick(this.state.hidden, this.refs.header.dataset.originalPosition);
        }
    }

    onRoomTileClick(roomId) {
        dis.dispatch({
            action: 'view_room',
            room_id: roomId,
            clear_search: false,
        });
    }

    _updateSubListCount = () => {
        // Force an update by setting the state to the current state
        // Doing it this way rather than using forceUpdate(), so that the shouldComponentUpdate()
        // method is honoured
        this.setState(this.state);
    }

    hasE2eRoomKeyRequestsFromRoomMembers(room) {
        const encryptionKeysRequestEvent = Rooms.getLastEventByType(room, 'citadel.keyrequest');
        const encryptionKeysRequest = encryptionKeysRequestEvent ? encryptionKeysRequestEvent.getContent() : null;
        const { requesters } = encryptionKeysRequest || { requesters: [] };
        const myUserId = UserStore.getMe().userId;
        const newRequesters = requesters.filter(req => req.user_id !== myUserId);
        const e2eKeySharedList = JSON.parse(localStorage.getItem("e2eKeySharedList")) || {};

        return !isEmpty(newRequesters) ? (newRequesters.length > 0) && (!e2eKeySharedList[room.roomId]?.isKeySent
            || !e2eKeySharedList[room.roomId]?.isKeyDenied) : false;
    }

    makeRoomTile = (room) => {
        return <RoomTile
            room={room}
            roomSubList={this}
            tagName={this.props.tagName}
            key={room.roomId}
            collapsed={this.props.collapsed || false}
            unread={Unread.doesRoomHaveUnreadMessages(room)}
            highlight={this.props.isInvite || RoomNotifs.getUnreadNotificationCount(room, 'highlight') > 0}
            notificationCount={RoomNotifs.getUnreadNotificationCount(room)}
            isInvite={this.props.isInvite}
            refreshSubList={this._updateSubListCount}
            incomingCall={null}
            onClick={this.onRoomTileClick}
            hasE2eKeyNotification={this.hasE2eRoomKeyRequestsFromRoomMembers(room)}
        />;
    }

    _onNotifBadgeClick = (e) => {
        // prevent the roomsublist collapsing
        e.preventDefault();
        e.stopPropagation();
        const room = this.props.list.find(room => RoomNotifs.getRoomHasBadge(room));
        if (room) {
            dis.dispatch({
                action: 'view_room',
                room_id: room.roomId,
            });
        }
    }

    _onInviteBadgeClick = (e) => {
        // prevent the roomsublist collapsing
        e.preventDefault();
        e.stopPropagation();
        // switch to first room in sortedList as that'll be the top of the list for the user
        if (this.props.list && this.props.list.length > 0) {
            dis.dispatch({
                action: 'view_room',
                room_id: this.props.list[0].roomId,
            });
        } else if (this.props.extraTiles && this.props.extraTiles.length > 0) {
            // Group Invites are different in that they are all extra tiles and not rooms
            // XXX: this is a horrible special case because Group Invite sublist is a hack
            if (this.props.extraTiles[0].props && this.props.extraTiles[0].props.group instanceof Group) {
                dis.dispatch({
                    action: 'view_group',
                    group_id: this.props.extraTiles[0].props.group.groupId,
                });
            }
        }
    }

    _getAddRoomButton = () => {
        const { id, onAddRoom } = this.props;
        if (!onAddRoom) return;

        const { isHintDisplayed, hintStyle } = this.state;

        let title;
        let description;
        if (id === 'direct') {
            title = _t('From now on, discuss with your colleagues');
            description = _t('Enter the email address or the name and surname of your colleague in the search bar.');
        } else if (id === 'room') {
            title = _t('Create a group with your teams, service providers and collaborators');
            description = _t('Enter a room name with a description if desired.');
        }
        const hintInfo = { title, description };

        return <HintedComponent
            flag={`has_shown_create_${id}_web`}
            isHintDisplayed={isHintDisplayed}
            hintInfo={hintInfo}
            hintStyle={hintStyle}
            hintArrow={{
                isDisplayed: true,
                position: 'top_left',
            }}
        >
            <AccessibleButton id={id} onClick={onAddRoom} className='mx_RoomSubList_addRoom' />
        </HintedComponent>;
    }

    _getHeaderJsx = (isCollapsed) => {
        const { isInvite, list, collapsed, label, incomingCall, isFiltered } = this.props;

        const subListNotifications = !isInvite ?
            RoomNotifs.aggregateNotificationCount(list) :
            {count: 0, highlight: true};
        const subListNotifCount = subListNotifications.count;
        const subListNotifHighlight = subListNotifications.highlight;

        let badge;
        if (!collapsed) {
            const badgeClasses = classNames({
                'mx_RoomSubList_badge': true,
                'mx_RoomSubList_badgeHighlight': subListNotifHighlight,
            });
            if (subListNotifCount > 0) {
                badge = <div className={badgeClasses} onClick={this._onNotifBadgeClick}>
                    { FormattingUtils.formatCount(subListNotifCount) }
                </div>;
            } else if (isInvite && list.length) {
                // no notifications but highlight anyway because this is an invite badge
                badge = <div className={badgeClasses} onClick={this._onInviteBadgeClick}>{list.length}</div>;
            }
        }

        // When collapsed, allow a long hover on the header to show user
        // the full tag name and room count
        let title;
        if (collapsed) {
            title = label;
        }

        let incomingCalBox;
        if (incomingCall) {
            // We can assume that if we have an incoming call then it is for this list
            const IncomingCallBox = sdk.getComponent("voip.IncomingCallBox");
            incomingCalBox =
                <IncomingCallBox className='mx_RoomSubList_incomingCall' incomingCall={incomingCall} />;
        }

        const chevronClasses = classNames(
            'mx_RoomSubList_chevron',
            {
                'mx_RoomSubList_chevronRight': isCollapsed,
                'mx_RoomSubList_chevronDown': !isCollapsed,
            },
        );

        const tabindex = isFiltered ? "0" : "-1";
        return (
            <div className="mx_RoomSubList_labelContainer" title={title} ref="header">
                <AccessibleButton onClick={this.onClick} className='mx_RoomSubList_label' tabIndex={tabindex}>
                    <div className={chevronClasses} />
                    <span>{label}</span>
                    {incomingCalBox}
                </AccessibleButton>
                {badge}
                {this._getAddRoomButton()}
            </div>
        );
    }

    checkOverflow = () => {
        this.calculateIsHintDisplayed();
        if (this.refs.scroller) {
            this.refs.scroller.checkOverflow();
        }
    }

    setHeight = (height) => {
        if (this.refs.subList) {
            this.refs.subList.style.height = `${height}px`;
        }
        this._updateLazyRenderHeight(height);
    }

    _updateLazyRenderHeight = (height) => {
        this.setState({scrollerHeight: height});
    }

    _onScroll = () => {
        this.setState({scrollTop: this.refs.scroller.getScrollTop()});
    }

    _canUseLazyListRendering = () => {
        // for now disable lazy rendering as they are already rendered tiles
        // not rooms like props.list we pass to LazyRenderList
        return !this.props.extraTiles || !this.props.extraTiles.length;
    }

    render() {
        const { list, extraTiles, forceExpand } = this.props;
        const { hidden } = this.state;

        const len = list.length + extraTiles.length;
        const isCollapsed = hidden && !forceExpand;


        if (len) {
            const subListClasses = classNames('mx_RoomSubList', {
                'mx_RoomSubList_hidden': isCollapsed,
                'mx_RoomSubList_nonEmpty': !isCollapsed,
            });

            if (isCollapsed) {
                return <div ref="subList" className={subListClasses}>
                    {this._getHeaderJsx(isCollapsed)}
                </div>;
            } else if (this._canUseLazyListRendering()) {
                return <div ref="subList" className={subListClasses}>
                    {this._getHeaderJsx(isCollapsed)}
                    <IndicatorScrollbar ref="scroller" className="mx_RoomSubList_scroll" onScroll={ this._onScroll }>
                        <LazyRenderList
                            scrollTop={this.state.scrollTop }
                            height={ this.state.scrollerHeight }
                            renderItem={ this.makeRoomTile }
                            itemHeight={34}
                            items={ this.props.list } />
                    </IndicatorScrollbar>
                </div>;
            } else {
                const roomTiles = this.props.list.map(r => this.makeRoomTile(r));
                const tiles = roomTiles.concat(this.props.extraTiles);
                return <div ref="subList" className={subListClasses}>
                    {this._getHeaderJsx(isCollapsed)}
                    <IndicatorScrollbar ref="scroller" className="mx_RoomSubList_scroll" onScroll={ this._onScroll }>
                        { tiles }
                    </IndicatorScrollbar>
                </div>;
            }
        } else {
            let content;
            if (this.props.showSpinner && !isCollapsed) {
                const Loader = sdk.getComponent("elements.Spinner");
                content = <Loader />;
            }

            return (
                <div ref="subList" className="mx_RoomSubList">
                    { this._getHeaderJsx(isCollapsed) }
                    { content }
                </div>
            );
        }
    }
}

export default RoomSubList;
