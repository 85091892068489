import React from "react";
import ReactDOM from "react-dom";
import { MatrixEvent } from "matrix-js-sdk";
import { renderToStaticMarkup } from "react-dom/server";
import escapeHtml from "escape-html";

import Exporter from "./Exporter";
import { shouldFormContinuation } from "../../components/structures/MessagePanel";
import { formatFullDateNoDayNoTime, wantsDateSeparator } from "../../DateUtils";
import { RoomPermalinkCreator } from "../../matrix-to";
import { _t } from "../../languageHandler";
import EventTile from "../../components/views/rooms/EventTile";
import DateSeparator from "../../components/views/messages/DateSeparator";
import BaseAvatar from "../../components/views/avatars/BaseAvatar";
import MatrixClientContext from "../../contexts/MatrixClientContext";
import getExportCSS from "./exportCSS";
import { textForEvent } from "../../TextForEvent";

import exportJS from "!!raw-loader!./exportJS";
import MatrixClientPeg from "../../MatrixClientPeg";
import { shouldShowEvent } from '../MessagePanelUtils';
import { fetchUrlWithTokenAsBlob } from "../MediaUtils";

import confAudioOpened from '../../../res/img/conference/audio-status-opened.svg';
import confAudioClosed from '../../../res/img/conference/audio-status-closed.svg';
import confVideoOpened from '../../../res/img/conference/video-status-opened.svg';
import confVideoClosed from '../../../res/img/conference/video-status-closed.svg';
import ffffff from '../../../res/img/ffffff.png';
import a4acd3 from '../../../res/img/a4acd3.png';
import RoomExportStore from "../../stores/RoomExportStore";

const RESOURCES = [ffffff, a4acd3, confAudioOpened, confAudioClosed, confVideoOpened, confVideoClosed];

const fetchAndCovertToBlob = (url) => fetch(url).then( response => response.blob());

class HTMLExporter extends Exporter {
    constructor(
        room,
        exportType,
        exportOptions,
        setProgressText,
    ) {
        super(room, exportType, exportOptions, setProgressText);
        this.room = room;
        this.exportType = exportType;
        this.exportOptions = exportOptions;
        this.setProgressText = setProgressText;
        this.avatars = new Map();
        this.permalinkCreator = new RoomPermalinkCreator(this.room);
        this.totalSize = 0;
        this.mediaOmitText = !exportOptions.attachmentsIncluded
            ? _t("export_chat|media_omitted")
            : _t("export_chat|media_omitted_file_size");
    }

    async getRoomAvatar() {
        let blob = undefined;
        const avatarUrl = this.room.getAvatarUrl(
            MatrixClientPeg.get().getHomeserverUrl(),
            Math.floor(32 * window.devicePixelRatio),
            Math.floor(32 * window.devicePixelRatio),
            "crop",
            false,
        );
        const avatarPath = "room.png";
        if (avatarUrl) {
            try {
                blob = await fetchUrlWithTokenAsBlob(avatarUrl);
                this.totalSize += blob.size;
                this.addFile(avatarPath, blob);
            } catch (err) {
                RoomExportStore.logger.log("Failed to fetch room's avatar" + err);
            }
        }
        const avatar = (
            <BaseAvatar size="32" name={this.room.name} title={this.room.name} url={blob ? avatarPath : ""} urls={blob ? [avatarPath] : []} forExport={true} />
        );
        return renderToStaticMarkup(avatar);
    }

    async wrapHTML(content: string, currentPage: number, nbPages: number): Promise<string> {
        const roomAvatar = await this.getRoomAvatar();
        const exportDate = formatFullDateNoDayNoTime(new Date());
        const creator = this.room.currentState.getStateEvents("m.room.create", "")?.getSender();
        const creatorName = (creator ? this.room.getMember(creator)?.rawDisplayName : creator) || creator;
        const exporter = MatrixClientPeg.get().getSafeUserId();
        const exporterName = this.room.getMember(exporter)?.rawDisplayName;
        const topic = this.room.currentState.getStateEvents("m.room.topic", "")?.getContent()?.topic || "";

        const safeCreatedText = escapeHtml(
            _t("export_chat|creator_summary", {
                creatorName,
            }),
        );
        const safeExporter = escapeHtml(exporter);
        const safeRoomName = escapeHtml(this.room.name);
        const safeTopic = escapeHtml(topic);
        const safeExportedText = renderToStaticMarkup(
              <span>
                {_t(
                    "export_chat|export_info",
                    {
                        exportDate,
                    },
                    {
                        roomName: () => <b>{safeRoomName}</b>,
                        exporterDetails: () => (
                            <span
                            >
                                {exporterName ? (
                                    <>
                                        <b>{escapeHtml(exporterName)}</b> {" (" + safeExporter + ")"}
                                    </>
                                ) : (
                                    <b>{safeExporter}</b>
                                )}
                            </span>
                        ),
                    },
                )}
              </span>,
        );

        const safeTopicText = topic ? _t("export_chat|topic", { topic: safeTopic }) : "";
        const previousMessagesLink = renderToStaticMarkup(
            currentPage !== 0 ? (
                <div style={{ textAlign: "center" }}>
                    <a href={`./messages${currentPage === 1 ? "" : currentPage}.html`} style={{ fontWeight: "bold" }}>
                        {_t("export_chat|previous_page")}
                    </a>
                </div>
            ) : (
                <></>
            ),
        );

        const nextMessagesLink = renderToStaticMarkup(
            currentPage < nbPages - 1 ? (
                <div style={{ textAlign: "center", margin: "10px" }}>
                    <a href={"./messages" + (currentPage + 2) + ".html"} style={{ fontWeight: "bold" }}>
                        {_t("export_chat|next_page")}
                    </a>
                </div>
            ) : (
                <></>
            ),
        );

        return `
          <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link href="css/style.css" rel="stylesheet" />
                <script src="js/script.js"></script>
                <title>${_t("export_chat|html_title")}</title>
            </head>
            <body style="height: 100vh;">
                <section
                id="matrixchat"
                style="height: 100%; overflow: auto"
                class="notranslate"
                >
                <div class="mx_MatrixChat_wrapper" aria-hidden="false">
                    <div class="mx_MatrixChat">
                    <main class="mx_RoomView">
                        <div class="mx_LegacyRoomHeader light-panel">
                        <div class="mx_LegacyRoomHeader_wrapper" aria-owns="mx_RightPanel">
                            <div class="mx_LegacyRoomHeader_avatar">
                            <div class="mx_DecoratedRoomAvatar">
                               ${roomAvatar}
                            </div>
                            </div>
                            <div class="mx_LegacyRoomHeader_name">
                            <div
                                dir="auto"
                                class="mx_LegacyRoomHeader_nametext"
                                title="${safeRoomName}"
                            >
                                ${safeRoomName}
                            </div>
                            </div>
                            <div class="mx_LegacyRoomHeader_topic" dir="auto"> ${safeTopic} </div>
                        </div>
                        </div>
                        ${previousMessagesLink}
                        <div class="mx_MainSplit">
                        <div class="mx_RoomView_body">
                            <div
                            class="mx_RoomView_timeline mx_RoomView_timeline_rr_enabled"
                            >
                            <div
                                class="
                                mx_AutoHideScrollbar
                                mx_ScrollPanel
                                mx_RoomView_messagePanel
                                "
                            >
                                <div class="mx_RoomView_messageListWrapper">
                                <ol
                                    class="mx_RoomView_MessageList"
                                    aria-live="polite"
                                    role="list"
                                >
                                ${
                                    currentPage === 0
                                        ? `<div class="mx_NewRoomIntro">
                                             ${roomAvatar}
                                             <h2> ${safeRoomName} </h2>
                                             <p>
                                                ${safeCreatedText} <br/><br/> ${safeExportedText}
                                             </p>
                                             <br/>
                                             ${safeTopic ? `<p> ${safeTopicText} </p>` : ''}
                                           </div>`
                                        : ""
                                }
                                ${content}
                                </ol>
                                </div>
                            </div>
                            </div>
                            <div class="mx_RoomView_statusArea">
                            <div class="mx_RoomView_statusAreaBox">
                                <div class="mx_RoomView_statusAreaBox_line"></div>
                            </div>
                            </div>
                        </div>
                        </div>
                        ${nextMessagesLink}
                    </main>
                    </div>
                </div>
                </section>
                <div id="snackbar"/>
            </body>
        </html>`;
    }

    getAvatarURL(event) {
        const member = event.sender;
        const avatarUrl = member?.getMxcAvatarUrl();
        return avatarUrl ? MatrixClientPeg.get().mxcUrlToHttp(avatarUrl, 30, 30, 'crop') : null;
    }

    async saveAvatarIfNeeded(event) {
        const member = event.sender ? event.member : null;
        if (!member) return;

        if (!this.avatars.has(member.userId)) {
            try {
                const avatarUrl = this.getAvatarURL(event.event);
                if (avatarUrl) {
                    this.avatars.set(member.userId, true);
                    const image = await fetch(avatarUrl);
                    const blob = await image.blob();
                    this.addFile(`users/${member.userId.replace(/:/g, "-")}.png`, blob);
                } else throw new Error('avatar url is missing');
            } catch (err) {
                console.log("Failed to fetch user's avatar" + err);
            }
        }
    }

    getDateSeparator(event) {
        const ts = event.getTs();
        const dateSeparator = (
            <li key={ts}>
                <DateSeparator forExport={true} key={ts} roomId={event.getRoomId()} ts={ts} />
            </li>
        );
        return renderToStaticMarkup(dateSeparator);
    }

    needsDateSeparator(event, prevEvent) {
        if (!prevEvent) return true;
        return wantsDateSeparator(prevEvent.getDate() || undefined, event.getDate() || undefined);
    }

    getEventTile(mxEv, continuation) {
        return (
            <div className="mx_Export_EventWrapper" id={mxEv.getId()}>
                <MatrixClientContext.Provider value={MatrixClientPeg.get()}>
                    <EventTile
                        checkUnmounting={() => false}
                        continuation={continuation}
                        forExport={true}
                        isRedacted={mxEv.isRedacted()}
                        isSelectedEvent={false}
                        isTwelveHour={false}
                        isLastEvent={false}
                        mxEvent={mxEv}
                        permalinkCreator={this.permalinkCreator}
                        replacingEventId={mxEv.replacingEventId()}
                        showReadReceipts={false}
                        showUrlPreview={false}
                    />
                </MatrixClientContext.Provider>
            </div>
        );
    }

    async getEventTileMarkup(mxEv, continuation, filePath) {
        const avatarUrl = this.getAvatarURL(mxEv);
        const hasAvatar = !!avatarUrl;
        if (hasAvatar) await this.saveAvatarIfNeeded(mxEv);
        const EventTile = this.getEventTile(mxEv, continuation);
        let eventTileMarkup;

        if (
            mxEv.getContent().msgtype === "m.emote" ||
            mxEv.getContent().msgtype === "m.notice" ||
            mxEv.getContent().msgtype === "m.text" ||
            mxEv.getContent().msgtype === "m.room.member"
        ) {
            // to linkify textual events, we'll need lifecycle methods which won't be invoked in renderToString
            // So, we'll have to render the component into a temporary root element
            const tempRoot = document.createElement("div");
            ReactDOM.render(EventTile, tempRoot);
            eventTileMarkup = tempRoot.innerHTML;
        } else {
            eventTileMarkup = renderToStaticMarkup(EventTile);
        }
        if (filePath) {
            const mxc = mxEv.getContent().url ?? mxEv.getContent().file?.url;
            eventTileMarkup = eventTileMarkup.split(mxc).join(filePath);
        }
        eventTileMarkup = eventTileMarkup.replace(/<span class="mx_MFileBody_info_icon".*?>.*?<\/span>/, "");
        if (hasAvatar) {
            eventTileMarkup = eventTileMarkup.replace(
                encodeURI(avatarUrl).replace(/&/g, "&amp;"),
                `users/${mxEv.sender ? mxEv.sender.userId.replace(/:/g, "-") : ''}.png`,
            );
        }
        return eventTileMarkup;
    }

    createModifiedEvent(text, mxEv, italic = true) {
        const modifiedContent = {
            msgtype: "m.text",
            body: `${text}`,
            format: "org.matrix.custom.html",
            formatted_body: `${text}`,
        };
        if (italic) {
            modifiedContent.formatted_body = "<em>" + modifiedContent.formatted_body + "</em>";
            modifiedContent.body = "*" + modifiedContent.body + "*";
        }
        const modifiedEvent = new MatrixEvent({});
        modifiedEvent.event = mxEv.event;
        modifiedEvent.sender = mxEv.sender;
        modifiedEvent.event.type = "m.room.message";
        modifiedEvent.event.content = modifiedContent;
        return modifiedEvent;
    }

    async createMessageBody(mxEv, joined = false) {
        let eventTile: string;
        try {
            if (this.isAttachment(mxEv)) {
                if (this.exportOptions.attachmentsIncluded) {
                    try {
                        const blob = await this.getMediaBlob(mxEv);
                        if (this.totalSize + blob.size > this.exportOptions.maxSize) {
                            eventTile = await this.getEventTileMarkup(
                                this.createModifiedEvent(this.mediaOmitText, mxEv),
                                joined,
                            );
                        } else {
                            this.totalSize += blob.size;
                            const filePath = this.getFilePath(mxEv);
                            eventTile = await this.getEventTileMarkup(mxEv, joined, filePath);
                            if (this.totalSize === this.exportOptions.maxSize) {
                                this.exportOptions.attachmentsIncluded = false;
                            }
                            this.addFile(filePath, blob);
                        }
                    } catch (e) {
                        RoomExportStore.logger.log("Error while fetching file" + e);
                        eventTile = await this.getEventTileMarkup(
                            this.createModifiedEvent(_t("export_chat|error_fetching_file"), mxEv),
                            joined,
                        );
                    }
                } else {
                    eventTile = await this.getEventTileMarkup(
                        this.createModifiedEvent(this.mediaOmitText, mxEv),
                        joined,
                    );
                }
            } else {
                eventTile = await this.getEventTileMarkup(mxEv, joined);
            }
        } catch (e) {
            // TODO: Handle callEvent errors
            RoomExportStore.logger.error(e);
            eventTile = await this.getEventTileMarkup(
                this.createModifiedEvent(textForEvent(mxEv, this.room.client), mxEv, false),
                joined,
            );
        }

        return eventTile;
    }

    async createHTML(
        events: MatrixEvent[],
        start: number,
        currentPage: number,
        nbPages: number,
    ): Promise<string> {
        let content = "";
        let prevEvent: MatrixEvent | null = null;
        if (events.length) {
            for (let i = start; i < Math.min(start + 1000, events.length); i++) {
                const event = events[i];
                this.updateProgress({ title: _t('export_chat|started_part_2'),
                        description:
                            _t("export_chat|processing_event_n", {
                                number: i + 1,
                                total: events.length,
                            })},
                    false,
                    true,
                );
                if (this.cancelled) return this.cleanUp();

                if (!shouldShowEvent(this.room, events, event, i, null)) continue;

                content += this.needsDateSeparator(event, prevEvent) ? this.getDateSeparator(event) : "";

                const shouldBeJoined =
                    !this.needsDateSeparator(event, prevEvent) &&
                    shouldFormContinuation(prevEvent, event, this.room.client, false);
                const body = await this.createMessageBody(event, shouldBeJoined);
                this.totalSize += Buffer.byteLength(body);
                content += body;
                prevEvent = event;
            }
        } else {
            content = _t("export_chat|no_messages_for_period");
            if (this.cancelled) return this.cleanUp();
        }
        return this.wrapHTML(content, currentPage, nbPages);
    }

    async addResources() {
        for (const file of RESOURCES) {
            const blob = await fetchAndCovertToBlob(file);
            this.addFile(file, blob);
        }
    }

    async export() {
        this.updateProgress({
            title: _t('export_chat|started_part_1'),
            description: _t("export_chat|starting_export"),
        });

        const fetchStart = performance.now();
        const res = await this.getRequiredEvents();
        const fetchEnd = performance.now();

        this.updateProgress(
            {
                title: _t('export_chat|started_part_1'),
                description: _t(`${res.length === 1
                    ? 'export_chat|fetched_n_events_in_time_one'
                    : 'export_chat|fetched_n_events_in_time_other'
                }`, {
                    count: res.length,
                    seconds: (fetchEnd - fetchStart) / 1000,
                }),
            },
            true,
            false,
        );

        this.updateProgress({
            title: _t('export_chat|started_part_2'),
            description: _t("export_chat|creating_html"),
        });

        const usedClasses = new Set();
        for (let page = 0; page < (res.length || 1) / 1000; page++) {
            const html = await this.createHTML(res, page * 1000, page, (res.length || 1)/ 1000);
            const document = new DOMParser().parseFromString(html, "text/html");
            document.querySelectorAll("*").forEach((element) => {
                element.classList.forEach((c) => usedClasses.add(c));
            });
            this.addFile(`messages${page ? page + 1 : ""}.html`, new Blob([html]));
        }

        const exportCSS = await getExportCSS(usedClasses);
        this.addFile("css/style.css", new Blob([exportCSS]));
        this.addFile("js/script.js", new Blob([exportJS]));

        // fetch the required resource and add them to the zip file
        await this.addResources();

        await this.downloadZIP();

        const exportEnd = performance.now();

        if (this.cancelled) {
            RoomExportStore.logger.info("Export cancelled successfully");
        } else {
            this.updateProgress({
                title: _t("export_chat|export_successful"),
                description: '',
            });
            this.updateProgress({
                    title: _t("export_chat|export_successful"),
                    description:
                        _t(`${res.length === 1
                            ? 'export_chat|exported_n_events_in_time_one'
                            : 'export_chat|exported_n_events_in_time_other'
                        }`, {
                            count: res.length,
                            seconds: (exportEnd - fetchStart) / 1000,
                        }),
                },
                true,
                false,
            );
        }
        this.cleanUp();
    }
}

export default HTMLExporter;
