import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { noop } from 'lodash';

import { _t } from '../../../languageHandler';

import errorIcon from '../../../../res/img/error.svg';
import removeIcon from '../../../../res/img/cancel.svg';

class CitadelInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hidePassword: true,
            coveredWithOpacity: props.coveredWithOpacity,
        };
    }

    changePasswordVisibility = () => {
        const { hidePassword } = this.state;
        this.setState({
            hidePassword: !hidePassword,
        });
    };

    clearInput = () => {
        const { onClear } = this.props;
        onClear();
    };

    removeOpacity = () => {
        this.setState({
            coveredWithOpacity: false,
        });
    };

    render() {
        const {
            autoComplete,
            autoFocus,
            caption,
            className,
            disabled,
            label,
            name,
            onBlur,
            onChange,
            onClear,
            onKeyDown,
            onRemove,
            placeholder,
            type,
            value = '',
            maxInputLength,
            showNumberOfChar,
            required,
        } = this.props;
        const { hidePassword, coveredWithOpacity } = this.state;

        const customType = (type !== 'password') ? type : (hidePassword) ? type : 'text';

        const eyeStatus = (hidePassword) ? 'hidden' : 'visible';
        const eyeIcon = require(`../../../../res/img/eye-${eyeStatus}.svg`);
        const eyeAlt = (hidePassword) ? _t('Hide Password') : _t('Show Password');

        const clearIcon = removeIcon; // don't ask me why ..

        const classes = cx(className, {
            mx_CitadelInput: true,
            mx_CitadelInput_error: (caption.type === 'error'),
            mx_CitadelInput_warning: (caption.type === 'warning'),
            mx_CitadelInput_opacity: coveredWithOpacity,
            disabled: disabled,
        });

        const inputClasses = cx('input', { password: (type === 'password')});

        return (
            <div className={classes}>
                { label && (<div className="label">
                        {label}
                        {required && (<span className="required_mark">*</span>)}
                    </div>)
                }
                <div className="input-wrapper">
                    <input
                        className={inputClasses}
                        type={customType}
                        name={name}
                        disabled={disabled}
                        onChange={(disabled) ? null : onChange}
                        onBlur={(disabled) ? null : onBlur}
                        onFocus={e => {
                            const val = e.target.value;
                            e.target.value = '';
                            e.target.value = val;
                            if (!disabled && coveredWithOpacity) this.removeOpacity();
                        }}
                        onKeyDown={onKeyDown}
                        placeholder={placeholder}
                        value={value}
                        autoFocus={autoFocus}
                        autoComplete={autoComplete}
                        maxLength={maxInputLength}
                        spellCheck={type !== 'password'}
                    />
                    { (type === 'password') && (
                        <div className="eye" onClick={this.changePasswordVisibility}>
                            <img src={eyeIcon} alt={eyeAlt} />
                        </div>
                    )}
                    {
                        onClear && value && <div className="clear" onClick={this.clearInput}>
                            <img src={clearIcon} alt="clear" />
                        </div>
                    }
                    {
                        (maxInputLength && showNumberOfChar) &&
                        <div className="char-utilisation">
                            {`${value ? value.length : 0}/${maxInputLength}`}
                        </div>
                    }

                    <div className="error-wrapper">
                        { (caption.type === 'error') && (<img src={errorIcon} alt="error" />) }
                        { (onRemove) && (<img className="remove" src={removeIcon} onClick={onRemove} alt="remove" />)}
                    </div>
                </div>
                <div className="info-wrapper">
                    { caption.text && (<div className="info">{caption.text}</div>)}
                    { caption.additionalText && (<div className="additional-info">{caption.additionalText}</div>)}
                </div>
            </div>
        );
    }
}

CitadelInput.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    caption: PropTypes.shape({
        type: PropTypes.string,
        text: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
        ]),
    }),
    autoComplete: PropTypes.string,
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    coveredWithOpacity: PropTypes.bool,
    disabled: PropTypes.bool,
    maxInputLength: PropTypes.number,
    maxLength: PropTypes.number,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    onRemove: PropTypes.func,
    required: PropTypes.bool,
    showNumberOfChar: PropTypes.bool,
    value: PropTypes.string,
};

CitadelInput.defaultProps = {
    autoFocus: false,
    caption: {
        type: 'description',
        text: '',
    },
    className: '',
    coveredWithOpacity: false,
    disabled: false,
    onKeyDown: noop,
    placeholder: '',
    required: false,
    type: 'text',
};

export default CitadelInput;
