import React from 'react';
import { map } from 'lodash';

import MatrixClientPeg from '../../../MatrixClientPeg';
import UserStore from '../../../stores/UserStore';
import { getLocalisedMoment } from '../../../DateUtils';
import dis from '../../../dispatcher';

import ConferencePlanDialogInvitees from '../elements/conferencePlanDialog/ConferencePlanDialogInvitees';
import ConferencePlanDialogDetails from '../elements/conferencePlanDialog/ConferencePlanDialogDetails';
import ConferencePlanDialogInviteSuccessful from '../elements/conferencePlanDialog/ConferencePlanDialogInviteSuccessful';
import ConferencePlanDialogInviteFailed from '../elements/conferencePlanDialog/ConferencePlanDialogInviteFailed';
export default class ConferencePlanDialog extends React.Component {
    moment = getLocalisedMoment();
    state = {
        calendarDetails: {
            calendarConferenceType: 'Audio',
            calendarDate: this.moment(),
            calendarDescription: '',
            calendarEndTime: '',
            calendarRoomName: '',
            calendarStartTime: '',
            calendarTitle: '',
            calendarUserEmail: '',
            calendarUserName: '',
            url: '',
        },
        membersToInvite: [],
        step: 0,
    }

    getMembersWithUser = async (roomId) => {
        const cli = MatrixClientPeg.get();
        const room = cli.getRoom(roomId);
        return await Promise.all(map(room.currentState.members, (member) => new Promise(async (resolve) => {
            let email = UserStore.getUserProp(member.userId, 'email');
            const newMember = member;
            if (!email) {
                try {
                    const response = await MatrixClientPeg.get().getProfileInfo(member.userId);
                    if (!newMember.user) {
                        const newUser = UserStore.getUserById(member.userId);
                        newMember.user = newUser;
                    }
                    email = response.address;
                    dis.dispatch({ action: 'USER_STORE_SAVE_USER', user: { ...newMember.user, email } });
                } catch (e) {
                    resolve();
                }
            }
            newMember.user.email = email;
            resolve({ invited: false, display: true, member: newMember });
        })));
    }

    filterMembers = (membersArray) => {
        // return only members that have join and have data retrived from Backend
        return membersArray.filter(item =>
            item && item.member.membership === "join",
        );
    }

    async componentDidMount() {
        const { roomId } = this.props;
        const membersArray = await this.getMembersWithUser(roomId);
        const activeMembers = this.filterMembers(membersArray);
        this.setState({ membersToInvite: activeMembers });
    }

    formatDateToICS = (dte, tme) => {
        const date = new Date(dte);
        const pre =
            date.getFullYear().toString() +
            ((date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString()) +
            ((date.getDate() + 1) < 10 ? "0" + date.getDate().toString() : date.getDate().toString());
        const post = tme.split(':').join('') + "00";
        return pre + "T" + post;
    }

    onChangeStep = (valueToAdd) => () => {
        this.setState((prevState) => ({ ...prevState, step: prevState.step + valueToAdd }));
    }

    onChangeCalendarDetails = (params) => {
        this.setState({ calendarDetails: params });
    }

    getFormattedOffset = (timeInMinutes) => {
        const hours = timeInMinutes / 60;
        if (hours > 9) {
            return `+${hours}00`;
        } else if (hours < -9) {
            return `${hours}00`;
        } else {
            if (hours < 0) {
                return `-0${Math.abs(hours)}00`;
            } else {
                return `+0${hours}00`;
            }
        }
    }

    onDownloadCalendarFile = () => {
        const {
            calendarConferenceType,
            calendarDate,
            calendarDescription,
            calendarEndTime,
            calendarRoomName,
            calendarStartTime,
            calendarTitle,
            calendarUserEmail,
            calendarUserName,
            url,
        } = this.state.calendarDetails;
        const dtStart = this.formatDateToICS(calendarDate, calendarStartTime);
        const dtEnd = this.formatDateToICS(calendarDate, calendarEndTime);
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const dstOffset = new Date(new Date(calendarDate).getFullYear(), 6, 1).getTimezoneOffset() * -1;
        const stdOffset = new Date(new Date(calendarDate).getFullYear(), 0, 1).getTimezoneOffset() * -1;
        const dstOffsetFormatted = this.getFormattedOffset(dstOffset);
        const stdOffsetFormatted = this.getFormattedOffset(stdOffset);
        const icsMSG = "BEGIN:VCALENDAR\n" +
            "VERSION:2.0\n" +
            "PRODID:\n" +
            "BEGIN:VTIMEZONE\n" +
            `TZID:${timezone}\n` +
            "BEGIN:DAYLIGHT\n" +
            `TZOFFSETFROM:${dstOffsetFormatted}\n` +
            `TZOFFSETTO:${stdOffsetFormatted}\n` +
            `DTSTART:${dtStart}\n` +
            "END:DAYLIGHT\n" +
            "BEGIN:STANDARD\n" +
            `TZOFFSETFROM:${stdOffsetFormatted}\n` +
            `TZOFFSETTO:${dstOffsetFormatted}\n` +
            `DTSTART:${dtStart}\n` +
            "END:STANDARD\n" +
            "END:VTIMEZONE\n" +
            "BEGIN:VEVENT\n" +
            `UID:${calendarRoomName}${calendarUserEmail}\n` +
            `ORGANIZER;CN=${calendarUserName}:MAILTO::${calendarUserEmail}\n` +
            `DTSTART;TZID=${timezone}:${dtStart}\n` +
            `DTEND;TZID=${timezone}:${dtEnd}\n` +
            `LOCATION:${calendarConferenceType}conference in room "${calendarRoomName}" - ${url}\n` +
            `SUMMARY:${calendarTitle}\n` +
            `DESCRIPTION:${calendarDescription.replace(/\n|\r/g, "\\n")}` +
            `\nEND:VEVENT\n` +
            `END:VCALENDAR`;
        const a = document.createElement('a');
        const blob = new Blob([icsMSG], { type: 'text/calendar' });
        a.href = URL.createObjectURL(blob);
        a.download = calendarTitle + '.ics';
        a.click();
        this.props.onFinished();
        MatrixClientPeg.get().trackUserAction({
            formId: 'planConference',
            version: 1,
            action: 'download',
        });
    }

    render() {
        const {
            joinedMembersCount,
            maxParticipants,
            onFinished,
            roomId,
            url,
        } = this.props;
        const {
            calendarDetails,
            membersToInvite,
            step,
        } = this.state;

        const {
            calendarConferenceType,
            calendarDate,
            calendarDescription,
            calendarEndTime,
            calendarRoomName,
            calendarStartTime,
            calendarTitle,
        } = calendarDetails;

        switch (step) {
            case 0:
                return (
                    <ConferencePlanDialogDetails
                        calendarDetails={calendarDetails}
                        joinedMembersCount={joinedMembersCount}
                        maxParticipants={maxParticipants}
                        membersToInvite={membersToInvite}
                        onChangeCalendarDetails={this.onChangeCalendarDetails}
                        onChangeStep={this.onChangeStep}
                        onFinished={onFinished}
                        roomId={roomId}
                    />
                );
            case 1:
                return (
                    <ConferencePlanDialogInvitees
                        calendarConferenceType={calendarConferenceType}
                        calendarDate={calendarDate}
                        calendarDescription={calendarDescription}
                        calendarEndTime={calendarEndTime}
                        calendarRoomName={calendarRoomName}
                        calendarStartTime={calendarStartTime}
                        calendarTitle={calendarTitle}
                        maxParticipants={maxParticipants}
                        membersToInvite={membersToInvite}
                        onChangeStep={this.onChangeStep}
                        onDownloadCalendarFile={this.onDownloadCalendarFile}
                        onFinished={onFinished}
                        roomId={roomId}
                        url={url}
                    />
                );
            case 2:
                return (
                    <ConferencePlanDialogInviteSuccessful onFinished={onFinished} />
                );
            case 3:
                return (
                    <ConferencePlanDialogInviteFailed
                        onDownloadCalendarFile={this.onDownloadCalendarFile}
                        onFinished={onFinished}
                    />
                );
        }
    }
}
