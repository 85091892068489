/*
Copyright 2015, 2016 OpenMarket Ltd
Copyright 2017 Vector Creations Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import sdk from '../../../index';
import MatrixClientPeg from '../../../MatrixClientPeg';
import dis from '../../../dispatcher';
import classNames from 'classnames';
import { _t } from '../../../languageHandler';
import { isDirectMessageRoom } from '../../../Rooms';
import UserStore from '../../../stores/UserStore';
import CitadelMembersLink from '../elements/CitadelMembersLink';
import DMRoomMap from '../../../utils/DMRoomMap';

const chevronIcon = require('../../../../res/img/feather-customised/blue-arrow.svg');

const MessageCase = Object.freeze({
    NotLoggedIn: "NotLoggedIn",
    Joining: "Joining",
    Loading: "Loading",
    Rejecting: "Rejecting",
    Kicked: "Kicked",
    Banned: "Banned",
    OtherThreePIDError: "OtherThreePIDError",
    InvitedEmailMismatch: "InvitedEmailMismatch",
    Invite: "Invite",
    ViewingRoom: "ViewingRoom",
    RoomNotFound: "RoomNotFound",
    OtherError: "OtherError",
    Null: "Null",
});

module.exports = class extends React.Component {
    static displayName = 'RoomPreviewBar';

    static propTypes = {
        onJoinClick: PropTypes.func,
        onRejectClick: PropTypes.func,
        onForgetClick: PropTypes.func,
        // if inviter is specified, the preview bar will shown an invite to the room.
        // You should also specify onRejectClick if specifiying inviter
        inviter: PropTypes.object,

        // If invited by 3rd party invite, the email address the invite was sent to
        invitedEmail: PropTypes.string,

        // A standard client/server API error object. If supplied, indicates that the
        // caller was unable to fetch details about the room for the given reason.
        error: PropTypes.object,

        canPreview: PropTypes.bool,
        room: PropTypes.object,

        loading: PropTypes.bool,
        joining: PropTypes.bool,
        rejecting: PropTypes.bool,
        // The alias that was used to access this room, if appropriate
        // If given, this will be how the room is referred to (eg.
        // in error messages).
        roomAlias: PropTypes.string,

        onCollapseOrExtend: PropTypes.func,
        expanded: PropTypes.bool,
        collapsedRhs: PropTypes.bool,
        e2eStatus: PropTypes.string,
    };

    static defaultProps = {
        inviter: {},
        onJoinClick: function() {},
    };

    componentDidMount() {
        const { invitedEmail } = this.props;
        const { inviter } = this.state;

        const cli = MatrixClientPeg.get();

        // If this is an invite and we've been told what email
        // address was invited, fetch the user's list of Threepids
        // so we can check them against the one that was invited
        if (inviter.userId && invitedEmail) {
            this.setState({busy: true});
            cli.lookupThreePid(
                'email', invitedEmail,
            ).finally(() => {
                this.setState({busy: false});
            }).done((result) => {
                this.setState({invitedEmailMxid: result.mxid});
            }, (err) => {
                this.setState({threePidFetchError: err});
            });
        }

        if (inviter.userId && !inviter.email) {
            this.setState({ waitingForEmail: true });
            cli.getProfileInfo(inviter.userId, '3pid')
                .finally(() => {
                    this.setState({
                        waitingForEmail: false,
                    });
                })
                .done(({ address: email }) => {
                    if (email) {
                        dis.dispatch({
                            action: 'USER_STORE_SAVE_USER',
                            user: {
                                userId: inviter.userId,
                                email,
                            },
                        });
                    }
                    this.setState({
                        inviter: email ? { ...inviter, email } : inviter,
                        inviterEmail: email || '',
                    });
                }, (err) => {
                    console.error(err);
                });
        }
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.inviter, this.props.inviter)) {
            this.setState({
                inviter: this._getCompletedInviter(),
            });
        }
    }

    _getCompletedInviter = () => {
        const { room, inviter } = this.props;
        let completedInviter = {};

        if (Object.keys(inviter).length) {
            const storedInviter = UserStore.getUserById(inviter.userId) || {};
            completedInviter = { ...inviter, ...storedInviter };
            if (!completedInviter.displayName) {
                completedInviter.displayName = this._isDMInvite() ? room.name || '' : '';
            }
        }

        return completedInviter;
    };

    _getMessageCase = () => {
        const { joining, rejecting, loading, invitedEmail, error, canPreview } = this.props;
        const { busy, waitingForEmail, inviter, threePidFetchError, invitedEmailMxid } = this.state;

        const isGuest = MatrixClientPeg.get().isGuest();

        if (isGuest) {
            return MessageCase.NotLoggedIn;
        }

        if (joining) {
            return MessageCase.Joining;
        } else if (rejecting) {
            return MessageCase.Rejecting;
        } else if (loading || busy) {
            return MessageCase.Loading;
        } else if (waitingForEmail) {
            if (!canPreview) {
                return MessageCase.Null;
            }
        }

        if (inviter.userId) {
            if (invitedEmail) {
                if (threePidFetchError) {
                    return MessageCase.OtherThreePIDError;
                } else if (invitedEmailMxid !== MatrixClientPeg.get().getUserId()) {
                    return MessageCase.InvitedEmailMismatch;
                }
            }
            return MessageCase.Invite;
        } else if (error) {
            if (error.errcode === 'M_NOT_FOUND') {
                return MessageCase.RoomNotFound;
            } else {
                return MessageCase.OtherError;
            }
        } else {
            const myMember = this._getMyMember();
            if (myMember) {
                if (myMember.isKicked()) {
                    return MessageCase.Kicked;
                } else if (myMember.membership === "ban") {
                    return MessageCase.Banned;
                }
            }

            return MessageCase.ViewingRoom;
        }
    };

    _getKickOrBanInfo = () => {
        const myMember = this._getMyMember();
        if (!myMember) {
            return {};
        }
        const kickerMember = this.props.room.currentState.getMember(
            myMember.events.member.getSender(),
        );
        const memberName = kickerMember ?
            kickerMember.name : myMember.events.member.getSender();
        const reason = myMember.events.member.getContent().reason;
        return {memberName, reason};
    };

    _joinRule = () => {
        const { room } = this.props;
        if (room) {
            const joinRules = room.currentState.getStateEvents('m.room.join_rules', '');
            if (joinRules) {
                return joinRules.getContent().join_rule;
            }
        }
    };

    _historyVisibility = () => {
        const { room } = this.props;
        if (room) {
            const historyVisibilityEvent = room.currentState.getStateEvents("m.room.history_visibility", "");
            if (historyVisibilityEvent) {
                return historyVisibilityEvent.getContent().history_visibility;
            }
        }
    };

    _roomName = (atStart = false) => {
        const { room } = this.props;
        if (name && room.name) {
            return name;
        } else if (atStart) {
            return _t("This room");
        } else {
            return _t("this room");
        }
    };

    _getMyMember = () => {
        const { room } = this.props;
        return room && room.getMember(MatrixClientPeg.get().getUserId());
    };

    _isDMInvite = () => {
        const { room } = this.props;
        if (!room) return false;

        const dmRoomMap = DMRoomMap.shared();
        const roomId = room && room.roomId;
        return dmRoomMap.getUserIdForRoomId(roomId);
    };

    onLoginClick = () => {
        dis.dispatch({ action: 'start_login' });
    };

    onRegisterClick = () => {
        dis.dispatch({ action: 'start_registration' });
    };

    state = {
        inviter: this._getCompletedInviter(),
        busy: false,
    };

    render() {
        const { onJoinClick, onForgetClick, onRejectClick,
            room, canPreview,
            invitedEmail,
            onCollapseOrExtend, expanded, e2eStatus } = this.props;
        const { inviter } = this.state;
        const inviterRawDisplayName = UserStore.getRawDisplayName(inviter.displayName);

        let showSpinner = false;
        let title;
        let subTitle;
        let primaryActionHandler;
        let primaryActionLabel;
        let secondaryActionHandler;
        let secondaryActionLabel;

        const messageCase = this._getMessageCase();
        const joinRule = this._joinRule();
        const historyVisibility = this._historyVisibility();

        switch (messageCase) {
            case MessageCase.Joining: {
                title = _t("Joining room …");
                showSpinner = true;
                break;
            }
            case MessageCase.Loading: {
                title = _t("Loading …");
                showSpinner = true;
                break;
            }
            case MessageCase.Rejecting: {
                title = _t("Rejecting invite …");
                showSpinner = true;
                break;
            }
            case MessageCase.NotLoggedIn: {
                // Guest mode : this will never happen on Citadel
                title = _t("Join the conversation with an account");
                primaryActionLabel = _t("Sign Up");
                primaryActionHandler = this.onRegisterClick;
                secondaryActionLabel = _t("Sign In");
                secondaryActionHandler = this.onLoginClick;
                break;
            }
            case MessageCase.Kicked: {
                const {memberName, reason} = this._getKickOrBanInfo();
                title = _t("You were removed from %(roomName)s by %(memberName)s",
                    {memberName, roomName: this._roomName()});
                subTitle = _t("Reason: %(reason)s", {reason});

                if (this._joinRule() === "invite") {
                    primaryActionLabel = _t("Forget this room");
                    primaryActionHandler = onForgetClick;
                } else {
                    primaryActionLabel = _t("Re-join");
                    primaryActionHandler = onJoinClick;
                    secondaryActionLabel = _t("Forget this room");
                    secondaryActionHandler = onForgetClick;
                }
                break;
            }
            case MessageCase.Banned: {
                const {memberName, reason} = this._getKickOrBanInfo();
                title = _t("You were permanently removed from %(roomName)s by %(memberName)s",
                    {memberName, roomName: this._roomName()});
                subTitle = _t("Reason: %(reason)s", {reason});
                primaryActionLabel = _t("Forget this room");
                primaryActionHandler = onForgetClick;
                break;
            }
            case MessageCase.OtherThreePIDError: {
                title = _t("Something went wrong with your invite to %(roomName)s",
                    {roomName: this._roomName()});
                const errCodeMessage = _t("%(errcode)s was returned while trying to valide your invite. You could try to pass this information on to a room admin.",
                    {errcode: this.state.threePidFetchError.errcode},
                );
                switch (joinRule) {
                    case "invite":
                        subTitle = [
                            _t("You can only join it with a working invite."),
                            errCodeMessage,
                        ];
                        break;
                    case "public":
                        subTitle = _t("You can still join it because this is a public room.");
                        primaryActionLabel = _t("Join the discussion");
                        primaryActionHandler = onJoinClick;
                        break;
                    default:
                        subTitle = errCodeMessage;
                        primaryActionLabel = _t("Try to join anyway");
                        primaryActionHandler = onJoinClick;
                        break;
                }
                break;
            }
            case MessageCase.InvitedEmailMismatch: {
                title = _t("This invite to %(roomName)s wasn't sent to your account",
                    {roomName: this._roomName()});
                if (joinRule === "public") {
                    subTitle = _t("You can still join it because this is a public room.");
                    primaryActionLabel = _t("Join the discussion");
                    primaryActionHandler = onJoinClick;
                } else {
                    subTitle = _t("Sign in with a different account, ask for another invite, or " +
                        "add the e-mail address %(email)s to this account.",
                        {email: invitedEmail},
                    );
                    if (joinRule !== "invite") {
                        primaryActionLabel = _t("Try to join anyway");
                        primaryActionHandler = onJoinClick;
                    }
                }
                break;
            }
            case MessageCase.Invite: {
                let inviterElement;
                if (inviter) {
                    inviterElement = <span>
                        <span className="mx_RoomPreviewBar_inviter">
                            {inviterRawDisplayName}
                        </span> ({inviter.email || inviter.userId})
                    </span>;
                } else {
                    inviterElement = (<span className="mx_RoomPreviewBar_inviter">{inviterRawDisplayName}</span>);
                }

                const isDM = this._isDMInvite();
                let subTitleMessage;
                if (canPreview) {
                    if (isDM) {
                        title = (
                            <div className="title">
                                <div>{_t("Do you want to chat with")}</div>
                                <div>{`${inviterRawDisplayName} ?`}</div>
                            </div>
                        );
                        subTitleMessage = inviter.email ||inviter.userId;
                    } else {
                        title = (
                            <div className="title">
                                <div>{_t("Do you want to join the room")}</div>
                                <div>{`${this._roomName()} ?`}</div>
                            </div>
                        );
                        subTitleMessage = _t("invited by <userName/>", {}, {userName: () => inviterElement});
                    }

                    subTitle = joinRule !== 'public' ? subTitleMessage : '';
                } else {
                    if (isDM) {
                            title = _t("Do you want to chat with %(userName)s?",
                            { userName: inviterRawDisplayName });
                        subTitleMessage = _t("<userName/> has invited you to this private chat.", {}, {userName: () => inviterElement});
                    } else {
                        title = _t("Do you want to join %(roomName)s?",
                            { roomName: this._roomName() });
                        subTitleMessage = _t("<userName/> has invited you to join the room %(roomName)s", { roomName: this._roomName() }, {userName: () => inviterElement});
                    }

                    const RoomAvatar = sdk.getComponent("views.avatars.RoomAvatar");
                    const avatar = <RoomAvatar room={room} />;
                    subTitle = [avatar, subTitleMessage];
                }

                primaryActionLabel = _t("Accept");
                primaryActionHandler = onJoinClick;
                secondaryActionLabel = _t("Decline");
                secondaryActionHandler = onRejectClick;
                break;
            }
            case MessageCase.ViewingRoom: {
                primaryActionLabel = _t("Join the discussion");
                primaryActionHandler = () => {
                    MatrixClientPeg.get().trackUserAction({
                        formId: 'publicRoom',
                        version: 1,
                        action: 'start',
                    });
                    onJoinClick();
                 };

                if (canPreview) {
                    if (joinRule === 'public') {
                        primaryActionLabel = _t("Join");
                        title =
                            <div className="title">
                                <div>{_t("Do you want to join")}</div>
                                <div>{_t("the public room %(roomName)s?", {roomName: this._roomName()})}</div>
                            </div>;
                    } else {
                        title = _t("You're previewing %(roomName)s. Want to join it?", {roomName: this._roomName()});
                    }
                } else {
                    title = _t("%(roomName)s can't be previewed. Do you want to join it?",
                        {roomName: this._roomName(true)});
                }

                break;
            }
            case MessageCase.RoomNotFound: {
                title = _t("%(roomName)s does not exist.", {roomName: this._roomName(true)});
                subTitle = _t("This room doesn't exist. Are you sure you're at the right place?");
                break;
            }
            case MessageCase.OtherError: {
                title = _t("%(roomName)s is not accessible at this time.", {roomName: this._roomName(true)});
                subTitle = _t("Try again later, or ask a room admin to check if you have access.");
                break;
            }
        }

        const Spinner = sdk.getComponent('elements.Spinner');

        let subTitleElements;
        if (subTitle) {
            if (!Array.isArray(subTitle)) {
                subTitle = [subTitle];
            }
            subTitleElements = subTitle.map((t, i) => <p key={`subTitle${i}`}>{t}</p>);
        }

        let titleElement;
        if (showSpinner) {
            titleElement = <h3 className="mx_RoomPreviewBar_spinnerTitle"><Spinner />{ title }</h3>;
        } else {
            titleElement = <h3>{ title }</h3>;
        }

        const AccessibleButton = sdk.getComponent('elements.AccessibleButton');

        let primaryButton;
        if (primaryActionHandler) {
            primaryButton = (
                <AccessibleButton kind="primary" onClick={primaryActionHandler}>
                    { primaryActionLabel }
                </AccessibleButton>
            );
        }

        let secondaryButton;
        if (secondaryActionHandler) {
            secondaryButton = (
                <AccessibleButton kind="secondary" onClick={secondaryActionHandler}>
                    { secondaryActionLabel }
                </AccessibleButton>
            );
        }

        if (!((messageCase === MessageCase.Invite ||
            messageCase === MessageCase.ViewingRoom) && canPreview)) {
            // Room is not previewable (encrypted public / private / dm rooms, joining / loading / rejecting,
            // kicked / banned, error)
            const classes = classNames(
                "mx_RoomPreviewBar",
                `mx_RoomPreviewBar_${messageCase}`,
                "mx_RoomPreviewBar_dialog",
            );

            return (
                <div className={classes}>
                    <div className="mx_RoomPreviewBar_message">
                        {titleElement}
                        {subTitleElements}
                    </div>
                    <div className="mx_RoomPreviewBar_actions">
                        {secondaryButton}
                        {primaryButton}
                    </div>
                </div>
            );
        }

        if ((messageCase === MessageCase.Invite || messageCase === MessageCase.ViewingRoom) && canPreview) {
            // Room is previewable (non encrypted public / private / dm rooms)
            const classes = classNames(
                `mx_RoomPreviewBar_${messageCase}`,
                "mx_RoomPreviewBar_panel", {
                    "mx_RoomPreviewBar_panel_expanded": expanded,
                },
            );

            const collapseLinkClasses = classNames({
                "collapsed-link": !expanded,
                "expanded-link": expanded,
            });

            const roomId = room && room.roomId;
            const { isDmRoom } = room ? isDirectMessageRoom(room) : {};

            return (
                <div className={classes}>
                    {((!e2eStatus && joinRule !== 'public') || (!e2eStatus && historyVisibility === 'world_readable')) ?
                        <div className={collapseLinkClasses} onClick={onCollapseOrExtend}>
                            {_t(expanded ? 'View the conversation' : 'Hide the conversation')}
                            <div className="chevron">
                                <img src={chevronIcon} alt="s" />
                            </div>
                        </div> : <div className="collapse-link-placeholder">{' '}</div>
                    }
                    <div className="titles">
                        {titleElement}
                        {subTitleElements}
                    </div>
                    { !isDmRoom &&
                    <div className="members-link">
                        {roomId && <CitadelMembersLink roomId={roomId} collapsedRhs={this.props.collapsedRhs} />}
                    </div>
                    }
                    <div className="buttons">
                        {secondaryButton}
                        {primaryButton}
                    </div>
                </div>
            );
        } else {
            // Room is not previewable (encrypted public / private / dm rooms, joining / loading / rejecting,
            // kicked / banned, error)
            const classes = classNames(
                "mx_RoomPreviewBar",
                `mx_RoomPreviewBar_${messageCase}`,
                "mx_RoomPreviewBar_dialog",
            );

            return (
                <div className={classes}>
                    <div className="mx_RoomPreviewBar_message">
                        {titleElement}
                        {subTitleElements}
                    </div>
                    <div className="mx_RoomPreviewBar_actions">
                        {secondaryButton}
                        {primaryButton}
                    </div>
                </div>
            );
        }
    }
};
