import React from 'react';
import PropTypes from 'prop-types';
import sdk from '../../../index';
import { _t } from '../../../languageHandler';

export default class extends React.Component {
    static displayName = 'CreditsDialog';

    static propTypes = {
        title: PropTypes.string,
        description: PropTypes.node,
        onFinished: PropTypes.func.isRequired,
    };

    static defaultProps = {
        title: '',
        description: '',
    };

    onOk = () => {
        this.props.onFinished(true);
    };

    onCancel = () => {
        this.props.onFinished(false);
    };

    render() {
        const BaseDialog = sdk.getComponent('views.dialogs.BaseDialog');
        const DialogButtons = sdk.getComponent('views.elements.DialogButtons');

        return (
            <BaseDialog
                className="mx_CreditsDialog"
                onFinished={this.props.onFinished}
                title={this.props.title}
                contentId="mx_Dialog_content"
                hasCancel={true}
            >
                <div className="mx_Dialog_content" id="mx_Dialog_content">
                    {this.props.description}
                </div>
                <DialogButtons
                    primaryButton={_t('OK')}
                    onPrimaryButtonClick={this.onOk}
                    hasCancel={false}
                />
            </BaseDialog>
        );
    }
}
