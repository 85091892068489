import { noop, compact } from "lodash";
import adapter from "webrtc-adapter";

import MatrixClientPeg from '../MatrixClientPeg';
import UserStore from '../stores/UserStore';
import PlatformPeg from "../PlatformPeg";
import { TYPE_AUDIO } from "../stores/CitadexStore";
import Modal from "../Modal";
import ScreenSharingElectronDialog from "../components/views/janus/ScreenSharingElectronDialog";

export const getDisplayName = (user) => {
    const cli = MatrixClientPeg.get();

    if (user) {
        return user.displayName;
    } else {
        return cli.getUserId();
    }
};

export const getProfileInfo = async (userId) => {
    const cli = MatrixClientPeg.get();

    if (!cli) return;

    let displayName = UserStore.getUserProp(userId, 'displayName');
    if (displayName === userId || displayName === null) {
        const { displayname } = await cli.getProfileInfo(userId);
        displayName = displayname;
    }

    return displayName;
};

export const getMembers = async (roomId) => {
    const cli = MatrixClientPeg.get();
    const room = cli.getRoom(roomId);
    const myId = localStorage.getItem('mx_user_id');
    if (!room) return [myId];

    // TODO: get members from room.currentState after it will be fixed
    // TODO: currently we are using a hot fix on matrix-js-sdk commit: 713114dedfdedd458119db98f164b1b2f5ed1bca

    try {
        const allMembers = await room._loadMembersFromServer();
        const membersList = allMembers ? allMembers.map(({ state_key: userId }) => userId) : [];
        membersList.push(myId);
        return membersList.sort().filter((id, idx, list) => {
            return !idx || id !== list[idx - 1];
        });
    } catch (error) {
        const allMembers = Object.values(room.currentState.members);
        allMembers.forEach((member) => {
            // work around a race where you might have a room member object
            // before the user object exists.  This may or may not cause
            // https://github.com/vector-im/vector-web/issues/186
            if (member.user === null) {
                member.user = cli.getUser(member.userId);
            }
        });

        const membersList = compact(allMembers.map(({ userId, membership }) => (membership === 'join' && userId)));
        membersList.push(myId);
        return membersList.sort().filter((id, idx, list) => {
            return !idx || id !== list[idx - 1];
        });
    }
};

const getConstraints = (props) => {
    const {
        requestingVideoRights,
        selectedDevices,
        selectedScreenId,
        type,
        withoutAudio,
    } = props;
    const { audioSourceId, videoSourceId } = selectedDevices || {};
    let constraints = {
        audio: type === 'audio' ? { deviceId: audioSourceId ? { exact: audioSourceId } : undefined } : false,
        video: false,
    };
    const isShareScreen = type === 'desktop';
    const platform = PlatformPeg.get();
    const isDesktop = platform.isDesktop();
    const isWindowsOs = isDesktop && platform.getPlatformFriendlyName() === 'Windows';
    if (type === 'video' || isShareScreen) {
        if (isDesktop && isShareScreen) {
            constraints = {
                video: {
                    mandatory: {
                        chromeMediaSource: 'desktop',
                        chromeMediaSourceId: selectedScreenId,
                        minWidth: 640,
                        maxWidth: 1920,
                        minHeight: 360,
                        maxHeight: 1080,
                    },
                },
            };
        } else {
            if (isShareScreen) {
                constraints.video = true;
            } else {
                constraints.video = { deviceId: videoSourceId ? { exact: videoSourceId } : undefined };
                if (!isWindowsOs) {
                    constraints.video = {
                        ...constraints.video,
                        width: { min: 640, ideal: 1920, max: 1920 },
                        height: { min: 360, ideal: 1080, max: 1080 },
                    };
                }
                constraints.audio = requestingVideoRights || withoutAudio
                    ? false
                    : { deviceId: audioSourceId ? { exact: audioSourceId } : undefined };
            }
        }
    }
    return constraints;
};

export const getStreams = async (params) => {
    const {
        type,
        callback = noop,
        onCancel = noop,
        onError = noop,
        selectedScreenId,
        isShareScreen = false,
        selectedDevices,
        requestingVideoRights = false,
        withoutAudio = false,
    } = params;
    const constraints = getConstraints({
        requestingVideoRights,
        withoutAudio,
        selectedDevices,
        selectedScreenId,
        type,
    });
    if (PlatformPeg.get().isDesktop() && isShareScreen && !selectedScreenId) {
        Modal.createTrackedDialog(
            '',
            'electronScreenSharing',
            ScreenSharingElectronDialog,
            {
                onContinue: (screenId) => {
                        getStreams({ ...params, selectedScreenId: screenId });
                        MatrixClientPeg.get().trackUserAction({
                            action: 'confirmScreenSharing',
                            formId: type === TYPE_AUDIO ? 'audioConference' : 'videoConference',
                            version: 1,
                            step: 'confirmScreenSharing',
                        });
                    },
                onCancel: () => onCancel(true),
            },
            'electron-screen-sharing-dialog',
        );
        return;
    }
    // request browser media
    try {
        let stream;
        if (isShareScreen && !PlatformPeg.get().isDesktop()) {
            stream = await navigator.mediaDevices.getDisplayMedia(constraints);
        } else {
            stream = await navigator.mediaDevices.getUserMedia(constraints);
            if (PlatformPeg.get().isDesktop() && isShareScreen) {
                stream.onended = () => onCancel(false, true);
            }
        }
        if (params.closeStream) {
            stream.getTracks().forEach(track => track.stop());
        }
        callback(stream, isShareScreen);
    } catch (error) {
        onError(error);
    }
};

const constraints = {
    video: [
        {
            width: { min: 352, ideal: 1920, max: 1920 },
            height: { min: 240, ideal: 1080, max: 1080 },
            bitrate: 1200,
        },
        {
            width: { min: 352, ideal: 1280, max: 1280 },
            height: { min: 240, ideal: 720, max: 720 },
            bitrate: 512,
        },
        {
            width: { min: 352, ideal: 704, max: 704 },
            height: { min: 240, ideal: 480, max: 480 },
            bitrate: 256,
        },
        {
            width: { min: 352, ideal: 352, max: 352 },
            height: { min: 240, ideal: 240, max: 240 },
            bitrate: 172,
        },
        {
            width: { min: 352, ideal: 352, max: 352 },
            height: { min: 240, ideal: 240, max: 240 },
            bitrate: 128,
        },
        {
            width: { min: 20, ideal: 20, max: 40 },
            height: { min: 20, ideal: 20, max: 40 },
            bitrate: 1,
        },
    ],
    screenShare: [
        {
            width: { min: 720, ideal: 1920, max: 1920 },
            height: { min: 576, ideal: 1080, max: 1080 },
            bitrate: 1200,
        },
        {
            width: { min: 720, ideal: 1920, max: 1920 },
            height: { min: 576, ideal: 1080, max: 1080 },
            bitrate: 1000,
        },
        {
            width: { min: 720, ideal: 1280, max: 1280 },
            height: { min: 576, ideal: 720, max: 720 },
            bitrate: 512,
        },
        {
            width: { min: 720, ideal: 1280, max: 1280 },
            height: { min: 576, ideal: 720, max: 720 },
            bitrate: 512,
        },
        {
            width: { min: 720, ideal: 1280, max: 1280 },
            height: { min: 576, ideal: 720, max: 720 },
            bitrate: 512,
        },
    ],
};

const indexAssociation = {
    high: 0,
    medium: 2,
    low: 4,
    screenShareOn: 5,
};

export const getCustomVideoConstraints = (
    participantsNo,
    isScreenSharing,
    qualitySetting,
    screensharingQualitySetting,
) => {
    if (participantsNo < 0) return {};
    let position;
    if (qualitySetting !== 'auto' && !isScreenSharing) {
        position = indexAssociation[qualitySetting];
        return constraints.video[position];
    }
    if (screensharingQualitySetting !== 'auto' && isScreenSharing) {
        position = indexAssociation[screensharingQualitySetting];
        return constraints.screenShare[position];
    }

    position = Math.floor(participantsNo / 2 - 0.5);
    if (position > 4) position = 4;

    if (!isScreenSharing) return constraints.video[position];
    return constraints.screenShare[position];
};

export const removeBandwidthRestriction = (sdp) => {
    return sdp.replace(/b=AS:.*\r\n/, '').replace(/b=TIAS:.*\r\n/, '');
};

export const updateBandwidthRestriction = (sdp, bandwidth) => {
    let modifier = 'AS';
    if (adapter.browserDetails.browser === 'firefox') {
        bandwidth = (bandwidth >>> 0) * 1000;
        modifier = 'TIAS';
    }
    if (sdp.indexOf('b=' + modifier + ':') === -1) {
        // insert b= after c= line.
        sdp = sdp.replace(/c=IN (.*)\r\n/, 'c=IN $1\r\nb=' + modifier + ':' + bandwidth + '\r\n');
    } else {
        sdp = sdp.replace(new RegExp('b=' + modifier + ':.*\r\n'), 'b=' + modifier + ':' + bandwidth + '\r\n');
    }
    return sdp;
};
