import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { noop } from 'lodash';

import dis from "../../../dispatcher";
import UserStore from '../../../stores/UserStore';
import CitadexStore from "../../../stores/CitadexStore";
import { _t } from '../../../languageHandler';
import ParticipantAvatar from './ParticipantAvatar';
import Spinner from '../elements/Spinner';
import CitadexContextButton from './CitadexContextButton';
import CitadexContextMenu from './CitadexContextMenu';
import CitadexContextOption from './CitadexContextOption';
import Stats from './Stats';
import { getBrowserName } from "../../../utils/Browser";

import audioOff from '../../../../res/img/conference/mic-off.svg';
import videoOff from '../../../../res/img/conference/camera-off.svg';
import ratio from '../../../../res/img/43ratio.png';

class ParticipantCard extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            contextOpen: false,
            mouseX: 0,
            mouseY: 0,
            isFullScreen: false,
            debugMode: false,
            width: 0,
            height: 0,
        };
    }

    componentDidMount() {
        this.dispatcherRef = dis.register(this.onAction);
    }

    componentWillUnmount() {
        const { onBitrateChange, streamId } = this.props;
        dis.unregister(this.dispatcherRef);
        onBitrateChange && onBitrateChange(streamId, 0, true);
    }

    onAction = (payload) => {
        if (payload.action === 'conference_full_screen') {
            this.setState({ isFullScreen: payload.isFullScreen });
        }
    }

    handleResolutionChange = (newWidth, newHeight) => {
        const { width, height } = this.state;
        if (width !== newWidth || height !== newHeight) {
            this.setState({ width: newWidth, height: newHeight });
        }
    }

    onVideoLoadedMetaData = (e) => {
        e.target.dataset.width = parseInt(e.target.videoWidth);
        e.target.dataset.height = parseInt(e.target.videoHeight);
    }

    onVideoTimeUpdate = (e) => {
        const width = parseInt(e.target.videoWidth);
        const height = parseInt(e.target.videoHeight);
        if (e.target.dataset.width !== width && e.target.dataset.height !== height) {
            e.target.dataset.width = parseInt(e.target.videoWidth);
            e.target.dataset.height = parseInt(e.target.videoHeight);
            this.handleResolutionChange(width, height);
        }
    }

    openContext = (e) => {
        this.setState({
            contextOpen: true,
            mouseX: e.clientX,
            mouseY: e.clientY,
        });
    }

    closeContext = () => this.setState({contextOpen: false})

    handleMute = (e) => {
        e.stopPropagation();
        this.closeContext();
        this.props.onMute();
    }

    handleKick = (e) => {
        const { onKick } = this.props;
        e.stopPropagation();
        this.closeContext();
        onKick();
    }

    handleScreenSharingDBClick = () => {
        const { isMe } = this.props;
        !isMe && dis.dispatch({ action: 'conference_toggle_fullscreen'});
    }

    render() {
        const {
            amISharingTheScreen,
            avatarSize,
            className,
            debugMode,
            display,
            isAudioEnabled,
            isContextMenuDisabled,
            isMe,
            isPreview,
            isScreenSharingLoading = false,
            isVideoEnabled,
            onBitrateChange,
            screenSharerStreamId,
            streamId,
            talking,
            userId,
        } = this.props;

        const { contextOpen, mouseX, mouseY, isFullScreen, width: videoWidth } = this.state;
        const isFirefox = getBrowserName() === 'firefox';
        let userName = display;
        if (!userName && userId) userName = UserStore.getUserProp(userId, 'displayName');
        const isSharingScreen = screenSharerStreamId === streamId;
        const isVideoLoading = isSharingScreen && isScreenSharingLoading;
        const size = avatarSize ? avatarSize : 'medium';
        const myId = localStorage.getItem('mx_user_id');
        const callEvent = CitadexStore.getCallInProgress();
        const shouldShowContext = callEvent && myId === callEvent.event.content.inviter_id
            && myId !== userId && !isFullScreen;
        const containerClassName = cx(
            'citadex_visio_video_participant_card',
            className,
            { 'preview': isPreview },
            { 'full_image': isSharingScreen && !amISharingTheScreen },
            // firefox is malfunctioning right now. it is a fix for: fix/CTDL-16464-video-feed-firefox-on-screen-share.
            // We disable firefox as the videoWidth stays 20 for some reason after the screen sharing is turned off.
            // Chrome based browser don't have this issue
            { 'video_hidden': !isVideoEnabled || (videoWidth <= 20 && !isFirefox) },
            { 'is_loading': isVideoLoading },
            { 'is_me': isMe },
            { sharing: amISharingTheScreen },
            { 'isFullScreen': isFullScreen },
            { talking },
        );

        return <div className={containerClassName} onClick={isContextMenuDisabled ? noop : this.openContext}>
            {isPreview
                ? null
                : <span className="muted_sources">
                    {!isAudioEnabled && <img src={audioOff} alt="Audio Off" />}
                    {!isVideoEnabled && !isSharingScreen && <img src={videoOff} alt="Video Off" />}
                    {shouldShowContext && <CitadexContextButton />}
                    {shouldShowContext && contextOpen && <CitadexContextMenu
                        center={true}
                        className="citadex_context_menu_centered"
                        mouseX={mouseX}
                        mouseY={mouseY}
                        onClose={this.closeContext}
                    >
                        <CitadexContextOption onClick={this.handleMute} className='mute'>
                            {_t('Mute participant')}
                        </CitadexContextOption>
                        <CitadexContextOption onClick={this.handleKick} className='kick'>
                            {_t('Remove from conference')}
                        </CitadexContextOption>
                    </CitadexContextMenu>}
                </span>
            }
            <Fragment>
                {debugMode &&
                    <Stats RTCPeerConnection={this.props.RTCPeerConnection} streamId={streamId} onBitrateChange={onBitrateChange} />
                }
                <video
                    controls={isSharingScreen}
                    key="video"
                    className={cx('video', { is_me: isMe, sharing: amISharingTheScreen })}
                    id={`video-element-${streamId}`}
                    autoPlay
                    playsInline
                    muted
                    onLoadedMetadata={this.onVideoLoadedMetaData}
                    onTimeUpdate={this.onVideoTimeUpdate}
                />
                {(!!isSharingScreen && !amISharingTheScreen) && <div
                        className='screen-sharing-overlay'
                        onDoubleClick={isContextMenuDisabled ? noop : this.handleScreenSharingDBClick}
                    />
                }
                {!isSharingScreen && <img src={ratio} width="200" height="150" className="visio_formatter" />}
                {isVideoLoading && <Spinner key='spinner' w={24} h={24} />}
            </Fragment>
            {(!isVideoEnabled || isSharingScreen || (videoWidth <= 20 && !isFirefox))
                ? <Fragment>
                    <div className="avatar">
                        {userId
                            ? <ParticipantAvatar
                                userId={userId}
                                userName={userName}
                                size={size}
                            />
                            : null
                        }
                    </div>
                    <img src={ratio} width="200" height="150" className="visio_formatter" />
                </Fragment>
                : null
            }
            <span className="display_name">{userName}</span>
        </div>;
    }
}

ParticipantCard.propTypes = {
    RTCPeerConnection: PropTypes.object,
    avatarSize: PropTypes.string,
    className: PropTypes.string,
    display: PropTypes.string,
    isAudioEnabled: PropTypes.bool.isRequired,
    isContextMenuDisabled: PropTypes.bool,
    isMe: PropTypes.bool.isRequired,
    isPreview: PropTypes.bool,
    isScreenSharingLoading: PropTypes.bool,
    isVideoEnabled: PropTypes.bool.isRequired,
    onBitrateChange: PropTypes.func,
    onKick: PropTypes.func,
    onMute: PropTypes.func,
    screenSharerStreamId: PropTypes.number,
    streamId: PropTypes.oneOfType([
        PropTypes.string.isRequired, // on IO selection screen it's set as string
        PropTypes.number.isRequired,
    ]),
    talking: PropTypes.bool,
    userId: PropTypes.string,
};

export default ParticipantCard;

