import React from 'react';
import cx from 'classnames';
import hark from 'hark';
import { noop } from 'lodash';
import PropTypes from 'prop-types';

import { _t } from '../../../languageHandler';
import Dropdown from '../elements/Dropdown';
import VolumeMeter from './VolumeMeter';

import speakerOn from '../../../../res/img/conference/speaker-on.svg';
import speakerOff from '../../../../res/img/conference/speaker-off.svg';
import { StreamAPI } from '../../../utils/WebRtc';
import CitadexStore from '../../../stores/CitadexStore';
import { getStreams } from '../../../utils/Citadex';

export default class ConferenceDeviceSettings extends React.Component {
    state = {
        audioDevices: [],
        audioSource: {},
        audioSourceId: null,
        videoDevices: [],
        isTestSoundPlaying: false,
        outputDevices: [],
        outputId: null,
        videoSource: {},
        videoSourceId: null,
        volume: Number.MIN_SAFE_INTEGER,
    }

    componentDidMount() {
        this._isMounted = true;
        const { selectedDevices } = this.props;
        navigator.mediaDevices.addEventListener('devicechange', this.updateDeviceList);
        // in case the user device has physical play/stop buttons
        // or something similar like macbook touch bar
        const audio = document.getElementById('citadel-bellAudio');
        audio.onplay = () => this._isMounted && this.changePlayButtonState(true);
        audio.onpause = () => this._isMounted && this.changePlayButtonState(false);

        this.setState({ ...selectedDevices }, () => {
            this.getAudioStream();
            this.updateDeviceList();
        });
    }

    componentWillUnmount() {
        const { isTestSoundPlaying } = this.state;
        navigator.mediaDevices.removeEventListener('devicechange', this.updateDeviceList);
        const { stopStream } = this.props;
        this.audioStreamAnalyzer && this.audioStreamAnalyzer.stop();
        stopStream(this.audioStream);
        this._isMounted = false;

        // since the audio element is in the main page, we need to stop it manually if is playing
        isTestSoundPlaying && this.toggleTestSound();
    }

    componentDidUpdate(prevProps, prevState) {
        if ((!prevProps.selectedDevices.audioSourceId && this.props.selectedDevices.audioSourceId) ||
            (!prevProps.selectedDevices.videoSourceId && this.props.selectedDevices.videoSourceId) ||
            !prevProps.selectedDevices.audioSourceLabel && this.props.selectedDevices.audioSourceLabel) {
            this.updateDeviceList();
        }
    }

    changePlayButtonState = (newState) => this.setState({ isTestSoundPlaying: newState })

    handleVolumeChange = (volume) => this.setState({ volume });

    toggleTestSound = () => {
        const { isTestSoundPlaying } = this.state;
        const audio = document.getElementById('citadel-bellAudio');

        if (audio) {
            if (!isTestSoundPlaying) {
                audio.load(); // audio.currentTime = 0;
                audio.play();
            }
            isTestSoundPlaying && audio.pause();

            this._isMounted && this.changePlayButtonState(!isTestSoundPlaying);
        }
    }

    handleAudioOutputSelect = async (outputId) => {
        const { changeDevices } = this.props;
        try {
            const audio = document.getElementById('citadel-bellAudio');
            await StreamAPI.attachSinkId(audio, outputId);
            this.setState({ outputId }, () => {
                const newSelectedDevices = this.createNewSelectedDeviceObject();
                changeDevices(newSelectedDevices);
            });
        } catch (e) {
            CitadexStore.error(e);
        }
    }

    updateDeviceList = async () => {
        const { getDeviceListsAndIds } = this.props;
        const deviceListsAndId = await getDeviceListsAndIds();
        this.setState({ ...deviceListsAndId });
    }

    createNewSelectedDeviceObject = () => {
        const { audioSourceId, videoSourceId, outputDevices, outputId } = this.state;
        const selectedDevices = { audioSourceId, videoSourceId };
        if (outputDevices.length) {
            selectedDevices.outputId = outputId;
        }
        return selectedDevices;
    }

    handleInputSelect = (type) => (sourceId) => {
        const { stopStream, changeDevices } = this.props;
        stopStream(this[`${type}Stream`]);

        if (type === 'audio') {
            this.audioStreamAnalyzer.stop();
        }
        this.setState({ [`${type}SourceId`]: sourceId }, () => {
            if (type === 'audio') {
                this.getAudioStream();
            }
            const newSelectedDevices = this.createNewSelectedDeviceObject();
            changeDevices(newSelectedDevices);
        });
    }

    getAudioStream = async () => {
        const { audioSourceId, videoSourceId } = this.state;
        await getStreams({
            callback: (stream) => this.attachAudioStream(stream),
            type: 'audio',
            selectedDevices: { audioSourceId, videoSourceId },
        });
    }

    attachAudioStream = (stream) => {
        if (!this._isMounted) {
            const { stopStream } = this.props;
            stopStream(stream);
            return;
    }
        this.audioStream = stream;
        this.audioStreamAnalyzer = hark(stream).on('volume_change', this.handleVolumeChange);
    }

    render() {
        const { isVideoCall, hasCameraPermissions } = this.props;
        const {
            audioDevices,
            audioSourceId,
            videoDevices,
            isTestSoundPlaying,
            outputDevices,
            outputId,
            videoSourceId,
            volume,
        } = this.state;
        const isOutputSelectionDisabled = !outputDevices.length;
        const isVideoSelectionDisabled = !isVideoCall || !videoSourceId || !hasCameraPermissions;
        return <div className="mx_ConferenceDeviceSettings">
            <div className="mx_ConferenceDeviceSettings_setting-row">
                <div className={
                    cx("mx_ConferenceDeviceSettings_label", { disabled: isVideoSelectionDisabled })}
                >
                    {_t('Camera')}
                </div>
                <Dropdown
                    onOptionChange={this.handleInputSelect('video')}
                    value={videoSourceId && hasCameraPermissions ? videoSourceId : 'noCamera'}
                    disabled={isVideoSelectionDisabled}
                >
                    {hasCameraPermissions && videoSourceId
                        ? videoDevices.map((device) => <div key={device.deviceId}>{device.label}</div>)
                        : [<div key={'noCamera'}>
                            {isVideoCall
                                ? _t(videoDevices.length ? 'camera not allowed' : 'no camera')
                                : `${_t('disabled')[0].toUpperCase()}${_t('disabled').slice(1)}`
                            }
                        </div>]
                    }
                </Dropdown>
            </div>
            <div className="mx_ConferenceDeviceSettings_setting-row">
                <div className="mx_ConferenceDeviceSettings_label">
                    {_t('Microphone')}
                </div>
                <Dropdown
                    onOptionChange={this.handleInputSelect('audio')}
                    value={audioSourceId}
                >
                    {audioDevices.map((device) => <div key={device.deviceId}>{device.label}</div>)}
                </Dropdown>
                <VolumeMeter volume={volume} />
            </div>
            <div className="mx_ConferenceDeviceSettings_setting-row">
                    <div className={
                            cx('mx_ConferenceDeviceSettings_label',
                            { disabled: isOutputSelectionDisabled })}
                    >
                        {_t('Speakers')}
                    </div>
                    <Dropdown
                        onOptionChange={this.handleAudioOutputSelect}
                        value={isOutputSelectionDisabled ? 'default' : outputId}
                        disabled={isOutputSelectionDisabled}
                    >
                        {!isOutputSelectionDisabled
                            ? outputDevices.map((device) => <div key={device.deviceId}>{device.label}</div>)
                            : [<div key={'default'}>{_t('Default')}</div>]
                        }
                    </Dropdown>
                <div
                    className="mx_ConferenceDeviceSettings_setting-test-sound"
                    onClick={this.toggleTestSound}
                >
                    <div className="image-container">
                        <img src={isTestSoundPlaying ? speakerOn : speakerOff} alt="speaker" />
                    </div>
                    {_t(`${isTestSoundPlaying ? 'Testing...' : 'Test Sound'}`)}
                </div>
            </div>
        </div>;
    }
}

ConferenceDeviceSettings.defaultProps = {
    getDeviceListsAndIds: noop,
    hasCameraPermissions: false,
    isVideoCall: false,
    changeDevices: noop,
    stopStream: noop,
    selectedDevices: {},
};

ConferenceDeviceSettings.propTypes = {
    getDeviceListsAndIds: PropTypes.func.isRequired,
    hasCameraPermissions: PropTypes.bool,
    isVideoCall: PropTypes.bool.isRequired,
    changeDevices: PropTypes.func.isRequired,
    selectedDevices: PropTypes.object,
    stopStream: PropTypes.func.isRequired,
};
