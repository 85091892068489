import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { getBrowserName } from '../../../utils/Browser';

import { _t } from '../../../languageHandler';
import BaseDialog from '../../views/dialogs/BaseDialog';
import DialogButtons from '../../views/elements/DialogButtons';

import networkErrorImg from '../../../../res/img/conference/network-error.svg';
import disconnectedImg from '../../../../res/img/conference/disconnected.svg';
import errorImg from '../../../../res/img/error-occured.svg';
import conferenceClosedImg from '../../../../res/img/conference/conference-closed.svg';
import kickedImg from '../../../../res/img/conference/kicked-icon.svg';

export const ERROR_TYPES = {
    FLOWS: 'FLOWS',
    GENERIC: 'GENERIC',
    NOT_ALLOWED: 'NOT_ALLOWED',
    ROOM_CLOSED: 'ROOM_CLOSED',
    CLOSE_CONFERENCE: 'CLOSE_CONFERENCE',
    KICKED: 'KICKED',
    NO_INTERNET: 'NO_INTERNET',
};

const flowErrorContent = () => (
    <React.Fragment>
        <img src={networkErrorImg} alt='network error' height={80} />
        <p>
            {_t('It seems that some flows/streams are not allowed on your IT configuration.')}
        </p>
        {_t('Please try on your mobile and/or contact your IT department.')}
    </React.Fragment>
);

const firefoxErrorContent = () => {
    const isMac = navigator && navigator.platform.indexOf('Mac') !== -1;
    const lang = JSON.parse(localStorage.getItem('mx_local_settings')).language;
    const portalUrl = localStorage.getItem('mx_portal_url');
    return <React.Fragment>
        <img src={networkErrorImg} alt='network error' height={80} />
        <p>
            {_t('Your browser seems not to be compatible.')}
        </p>
        {_t(
            "Please try to join the Conference from Edge or Chrome, or from our %(desktopLink)s, %(iosLink)s or %(androidLink)s applications.",
            {
                desktopLink: isMac ? <a target='blank' href='https://download.citadel.team/download/latest/osx'>desktop</a> : <a target='blank' href='https://download.citadel.team/download/latest/windows_64'>desktop</a>,
                iosLink: <a target='blank' href='https://itunes.apple.com/app/citadelteam/id1132246597'>iOS</a>,
                androidLink: <a target='blank' href='https://play.google.com/store/apps/details?id=im.team'>Android</a>,
            },
        )}
        <br />
        {_t(
            "Want to know more? <faq>Visit our FAQ</faq>",
            {},
            {
                faq: faqString => <a target='blank' href={`${portalUrl}/faq/${lang}/conference/iusefirefox/`}>{faqString}</a>,
            },
        )}
    </React.Fragment>;
};

const genericErrorContent = () => (
    <React.Fragment>
        <img src={errorImg} alt='network error' height={80} />
        <p>
            {_t('An error occurred')}
        </p>
    </React.Fragment>
);

const roomClosedContent = () => (
    <React.Fragment>
        <img src={errorImg} alt='network error' height={80} />
        <p>
            {_t('Conference was closed')}
        </p>
    </React.Fragment>
);

const notAllowed = () => (
    <React.Fragment>
        <img src={errorImg} alt='network error' height={80} />
        <p>
            {_t('To open the conference, Citadel Team needs access to the microphone')}
        </p>
        {_t('Please allow access to the microphone.')}
    </React.Fragment>
);

const closeConferenceContent = () => (
    <React.Fragment>
        <img src={conferenceClosedImg} alt='close conference' height={80} />
        <p>
            {_t('The conference has been closed')}
        </p>
        {_t('This conference was closed by an organizer.')}
    </React.Fragment>
);

const kickedFromConferenceContent = () => (
    <React.Fragment>
        <img src={kickedImg} alt='kicked' height={80} />
        <p>
            {_t('You were removed')}
        </p>
        {_t('You are no longer part of this conference.')}
    </React.Fragment>
);

const noInternetConnectionContent = () => (
    <React.Fragment>
        <img src={disconnectedImg} className="disconnected-image" alt='network error' width={80} height={80} />
        <p>
            {_t('Network error')}
        </p>
        {_t('It seems that you are not connected to the Internet. Citadel Conference will close automatically')}
    </React.Fragment>
);

const ErrorDialogContent = (props) => {
    const { onContinue = noop, onFinished, type, autoCloseAfter } = props;
    const isFirefox = getBrowserName() === 'firefox';
    let content;

    switch (type) {
        case ERROR_TYPES.FLOWS:
            content = isFirefox ? firefoxErrorContent() : flowErrorContent();
            break;
        case ERROR_TYPES.GENERIC:
            content = genericErrorContent();
            break;
        case ERROR_TYPES.ROOM_CLOSED:
            content = roomClosedContent();
            break;
        case ERROR_TYPES.NOT_ALLOWED:
            content = notAllowed();
            break;
        case ERROR_TYPES.CLOSE_CONFERENCE:
            content = closeConferenceContent();
            break;
        case ERROR_TYPES.KICKED: content = kickedFromConferenceContent();
            break;
        case ERROR_TYPES.NO_INTERNET:
            content = noInternetConnectionContent();
            break;
        default: content = flowErrorContent();
    }

    const buttonText = [ERROR_TYPES.KICKED, ERROR_TYPES.CLOSE_CONFERENCE].includes(type)
        ? _t('OK') : _t('Continue');

    const onPrimaryButtonClick = () => {
        onContinue();
        onFinished();
    };

    if (autoCloseAfter) {
        setTimeout(onPrimaryButtonClick, autoCloseAfter);
    }

    return (
        <BaseDialog onFinished={onFinished} title='' hasCancel={false} fixedWidth={false}>
            <div className='mx_Dialog_content'>
                {content}
            </div>
            <DialogButtons
                focus={true}
                hasCancel={false}
                onPrimaryButtonClick={onPrimaryButtonClick}
                primaryButton={buttonText}
            />
        </BaseDialog>
    );
};

ErrorDialogContent.propTypes = {
    onContinue: PropTypes.func.isRequired,
    onFinished: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
};

export default ErrorDialogContent;
