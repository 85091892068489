import React from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import cx from 'classnames';
import dis from "../../../dispatcher";

import BaseDialog from '../dialogs/BaseDialog';
import DialogButtons from '../elements/DialogButtons';
import Spinner from '../elements/Spinner';
import CitadexStore from '../../../stores/CitadexStore';
import PlatformPeg from '../../../PlatformPeg';
import { _t, getCurrentLanguage } from '../../../languageHandler';
import empty from '../../../../res/img/icon_context_delete-dark.svg';
import SdkConfig from "../../../SdkConfig";

const SCREEN_SHARE_TYPES = {
    SCREEN: 'SCREEN',
    WINDOW: 'WINDOW',
};

const MAX_ELEMENTS = 3;

class ScreenSharingElectronDialog extends React.Component {
    static propTypes = {
        onCancel: PropTypes.func.isRequired,
        onFinished: PropTypes.func.isRequired,
    };

    state = { screens: [], windows: [], isLoading: true };

    componentDidMount() {
        this.getSources();
        this.dispatcherRef = dis.register(this.onAction);
    }

    componentWillUnmount() {
        dis.unregister(this.dispatcherRef);
    }

    onAction = (payload) => {
        if (payload.action === 'conference-event') {
            const { data } = payload;
            const { type, status } = data || {};
            if (type === 'screenshare' && !status) {
                this.onCancel();
            }
        }
    }

    getSources = async () => {
        CitadexStore.log('request desktop sources');
        const sources = await PlatformPeg.get().getSources();
        const screens = [];
        const windows = [[]];
        CitadexStore.log('got sources: ', sources);
        for (const source of sources) {
            if (source.id.includes('screen')) {
                screens.push(source);
            } else {
                if (windows[windows.length - 1].length === MAX_ELEMENTS) {
                    windows.push([source]);
                } else {
                    windows[windows.length - 1].push(source);
                }
            }
        }

        this.setState({ screens, windows, isLoading: false });
    }

    onSelectClick = () => {
        const { onContinue, onFinished } = this.props;
        const { selected } = this.state;
        if (!selected) {
            CitadexStore.error('No screen selected');
            return;
        }
        CitadexStore.log('Screen selected', selected);
        onContinue(selected);
        onFinished();
    }

    onScreenSelect = (selected) => () => this.setState({ selected });

    onCancel = () => {
        const { onFinished, onCancel } = this.props;
        onCancel();
        onFinished();
    }

    screenElement = (type, { id, name, thumbnail }) => {
        const { selected } = this.state;
        // on MacOS if the screen recording rights is rejected or revoked, the thumbnail is empty
        // so we will disable the selection and a placeholder will be displayed instead of the thumbnail
        let imgSrc = empty;
        let onClick = noop;
        if (!thumbnail.isEmpty()) {
            imgSrc = thumbnail.toDataURL();
            onClick = this.onScreenSelect(id);
        }

        return (
            <div className='resource' key={id}>
                <div className='image-container'>
                    <div className={cx('overlay', { selected: selected === id})} onClick={onClick} />
                    <img src={imgSrc} />
                </div>
                {type === SCREEN_SHARE_TYPES.WINDOW ? <div className='label' title={name}>{name}</div> : null}
            </div>
        );
    }

    renderSources = (type) => {
        const { screens, windows } = this.state;

        if (type === SCREEN_SHARE_TYPES.SCREEN) return map(screens, (source) => this.screenElement(type, source));

        return map(
            windows,
            (row, idx) => (
                <div className='windows-row' key={`row-${idx}`}>
                    {map(row, (source) => this.screenElement(type, source))}
                </div>
            ),
        );
    }

    render() {
        const { isLoading } = this.state;

        const portalUrl = localStorage.getItem('mx_portal_url') || SdkConfig.get().default_portal_url;
        const language = getCurrentLanguage();
        const FAQLink = `${portalUrl}/faq/${language}/conference/powerpoint`;

        const content = isLoading
            ? <Spinner />
            : <React.Fragment>
                <div className='labels'>
                    <div>{_t('Desktop')}</div>
                    <span>{_t('Windows')}</span>
                </div>
                <div className='screen-container'>
                    {this.renderSources(SCREEN_SHARE_TYPES.SCREEN)}
                </div>
                <div className='window-container'>
                    <div className='window-list'>{this.renderSources(SCREEN_SHARE_TYPES.WINDOW)}</div>
                    <div className='info'>
                        <span className='info_icon'>i</span>
                        {_t('Check the <FAQLink>FAQ</FAQLink> to share a PowerPoint presentation in full screen.',
                            {},
                            { 'FAQLink': (sub) => <a href={FAQLink} target="_blank">{sub}</a> },
                        )}
                    </div>
                </div>

              </React.Fragment>;

        return (
            <BaseDialog
                fixedWidth={false}
                onFinished={this.onCancel}
                title={_t('Choose the screen you want to share')}
            >
                <div className='content'>
                    {content}
                </div>
                <DialogButtons
                    focus={true}
                    onCancel={this.onCancel}
                    onPrimaryButtonClick={this.onSelectClick}
                    primaryButton={_t('Start screen sharing')}
                    primaryDisabled={isLoading}
                />
            </BaseDialog>
        );
    }
}

export default ScreenSharingElectronDialog;
