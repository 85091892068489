import React from 'react';
import Lottie from 'react-lottie';
import loaderAnimationData from '../../../../res/animations/loader';
import PropTypes from "prop-types";

export const Spinner = ({ height, width }) => {
    const options = {
        loop: true,
        autoplay: true,
        animationData: loaderAnimationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <Lottie
            options={options}
            height={height}
            width={width}
        />
    );
};

Spinner.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
};

Spinner.defaultProps = {
    height: 24,
    width: 24,
};
