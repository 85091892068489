import { throttle } from 'lodash';
import dis from '../dispatcher';
import { _t } from '../languageHandler';

const HttpCodes = {
    success: 200,
    notFound: 404,
    forbidden: 403,
};
let _downloads = {};

const downloadProgress = throttle(() => {
    const downloads = Object.values(_downloads);

    const rooms = {};

    for (const d of downloads) {
        if (rooms[d.roomId]) {
            if (!d.aborted) {
                if (!d.finished) {
                    rooms[d.roomId].files.push(d.fileName);
                }
                rooms[d.roomId].totalToDownload += d.total;
                rooms[d.roomId].totalReceived += d.received;
            }
        } else {
            if (!d.aborted) {
                rooms[d.roomId] = {
                    roomId: d.roomId,
                    files: d.finished ? [] : [d.fileName],
                    totalToDownload: d.total,
                    totalReceived: d.received,
                };
            }
        }
    }

    for (const room of Object.values(rooms)) {
        const { roomId, files, totalReceived, totalToDownload } = room;

        if (totalReceived > 0 && totalReceived !== totalToDownload) {
            dis.dispatch({
                action: 'download_progress',
                roomId,
                files,
                totalReceived,
                totalToDownload,
            });
        } else {
            dis.dispatch({ action: 'download_finished', roomId });
        }
    }
}, 100);

const startDownload = (download) => {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('mx_access_token');
        const xhr = new XMLHttpRequest();
        download.cancel = () => xhr.abort();

        const timeoutFn = () => {
            xhr.abort();
            reject(new Error('Timeout'));
        };

        // set an initial timeout of 30s; we'll advance it each time we get
        // a progress notification
        xhr.timeout_timer = setTimeout(timeoutFn, 30000);

        xhr.onerror = () => clearTimeout(xhr.timeout_timer);

        xhr.onabort = () => clearTimeout(xhr.timeout_timer);

        xhr.onprogress = progressEvent => {
            clearTimeout(xhr.timeout_timer);
            xhr.timeout_timer = setTimeout(timeoutFn, 30000);
            download.received = progressEvent.loaded;
            download.total = progressEvent.total;
            downloadProgress();
        };
        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                clearTimeout(xhr.timeout_timer);
                if (xhr.status !== HttpCodes.success) {
                    if (download.aborted) {
                        reject(new Error('abort'));
                    } else {
                        console.error(`Error: ${xhr.statusText || 'Network error'}`);
                        download.finished = true;
                        if ([HttpCodes.notFound, HttpCodes.forbidden].includes(xhr.status)) {
                            reject(new Error(`${xhr.status} - ${xhr.statusText}`));
                        }
                        resolve(null);
                    }
                }
            }
        };
        xhr.onload = (e) => {
            download.finished = true;
            if (xhr.status === HttpCodes.success) {
                if (download.encrypted) {
                    resolve(e.currentTarget.response.arrayBuffer());
                } else {
                    resolve(URL.createObjectURL(e.currentTarget.response));
                }
            }
        };
        xhr.responseType="blob";
        xhr.open('GET', download.url);
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        xhr.send('');
    });
};

const addDownload = async (url, event, encrypted = false) => {
    const roomId = event.getRoomId();
    const eventId = event.getId();
    const content = event.getContent();
    const fileName = content.body && content.body.length > 0 ? content.body : _t('Attachment');

    const newDownload = {
        id: eventId,
        fileName,
        url,
        roomId,
        encrypted,
        total: 0,
        received: 0,
        finished: false,
        aborted: false,
    };

    // If this is the first download, clear the downloads store
    const currentDownloads = Object.values(_downloads).filter(d => !d.finished);
    if (!currentDownloads.length) {
        _downloads = {};
    }

    // If this is the first download in the room, dispatch download_started action
    const roomDownloads = currentDownloads.filter(d => d.roomId === roomId);
    if (!roomDownloads.length) {
        dis.dispatch({
            action: 'download_started',
            roomId,
            files: [fileName],
            totalReceived: 0,
            totalToDownload: 0,
        });
    }

    _downloads[eventId] = newDownload;

    try {
        return await startDownload(newDownload);
    } catch (err) {
        throw err;
    } finally {
        if (!isDownloading(roomId)) {
            dis.dispatch({
                action: 'download_finished',
                roomId,
            });
        }
    }
};

const abortOneDownload = (download) => {
    download.finished = true;
    download.aborted = true;
    download.cancel();
};

const abortLatestDownload = (roomId) => {
    const roomDownloads = Object.values(_downloads).filter(d => !d.finished && d.roomId === roomId);

    const count = roomDownloads.length;
    if (count) {
        abortOneDownload(roomDownloads[count - 1]);
    }
};

const abortEncryptedDownloads = (roomId) => {
    const roomDownloads = Object.values(_downloads)
        .filter(d => d.encrypted && !d.finished && d.roomId === roomId);

    for (const d of roomDownloads) {
        abortOneDownload(d);
    }
};

const isDownloading = (roomId) => Object.values(_downloads)
        .filter(d => !d.finished && d.roomId === roomId).length > 0;

export default { addDownload, abortOneDownload, abortLatestDownload, abortEncryptedDownloads, isDownloading };
