import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual, noop } from 'lodash';
import cx from 'classnames';

import MatrixClientPeg from '../../../../MatrixClientPeg';
import BaseDialog from '../BaseDialog';
import CitadelCodeInput from '../../../views/elements/CitadelCodeInput';
import { validateToken } from '../../../../utils/CitadelUtils';
import { _t } from '../../../../languageHandler';
import Spinner from '../../elements/Spinner';
import Modal from '../../../../Modal';
import KeySyncSuccessOrFailure, { DIALOG_TYPE } from './KeySyncSuccessOrFailure';
import KeyRequesterInfoDialog from './KeyRequesterInfoDialog';
import { fetchPureBlobWithToken, getMxcFromUrl } from '../../../../utils/MediaUtils';
import * as MegolmExportEncryption from '../../../../utils/MegolmExportEncryption';
import utils from 'matrix-js-sdk/lib/utils';

const REQUEST_TIMEOUT = 60000;

const FAIL_CASES = [DIALOG_TYPE.ERROR, DIALOG_TYPE.NO_KEYS];

export default class KeyRequesterDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            completedKeyShareCount: 0,
            currentDeviceIndex: 0,
            error: null,
            isGeneratingKey: false,
            isKeyReady: false,
            isSkipPopup: false,
            isSubmitting: false,
            key: '',
        };

        this.timeoutRef = null;
    }

    componentDidMount() {
        const cli = MatrixClientPeg.get();
        cli.on('crypto.easyKeyEvent', this.handleEasyKeyEvent);
        cli.on('Event.decrypted', this.handleClientDecryptedEvent);
        this.props.mxEvent.on('Event.decrypted', this.handleDecryptedEvent);

        this.renderInfoPopup();
    }

    componentDidUpdate({ devices: prevDevices}) {
        const { devices } = this.props;

        if (!isEqual(devices, prevDevices)) {
            const numberOfDevices = devices.length;

            this.setState({ currentDeviceIndex: numberOfDevices ? 0 : -1 });
        }
    }

    componentWillUnmount() {
        const { mxEvent } = this.props;
        const cli = MatrixClientPeg.get();

        cli.removeListener('crypto.easyKeyEvent', this.handleEasyKeyEvent);
        cli.removeListener('Event.decrypted', this.handleClientDecryptedEvent);
        mxEvent.removeListener('Event.decrypted', this.handleDecryptedEvent);
    }

    handleClientDecryptedEvent = async (evt, err) => {
        const { type, content } = evt._clearEvent;
        const { url: mxcUrl, password } = content;

        if (!err && type === 'citadel.device_keys_export') {
            const cli = MatrixClientPeg.get();
            const url = cli.mxcUrlToHttp(mxcUrl);
            const arrayBuffer = await fetchPureBlobWithToken(url);
            const keys = await MegolmExportEncryption.decryptMegolmKeyFile(arrayBuffer, password);
            const res = await cli.importRoomKeys(utils.jsonParseSafe(keys), this.props.mxEvent);

            await cli.deleteMedia(getMxcFromUrl(url));
            if (!res) {
                this.displaySuccessOrFailPopup(DIALOG_TYPE.NO_KEYS);
            }
        }
    };

    handleDecryptedEvent = (evt, err) => {
        const isPendingError = (err && err.code === 'MEGOLM_PENDING');
        const isSuccess = !isPendingError && evt && !evt.isDecryptionFailure();
        this.displaySuccessOrFailPopup(isSuccess ? DIALOG_TYPE.SUCCESS : DIALOG_TYPE.NO_KEYS);
    };

    handleEasyKeyEvent = (evt) => {
        const { type, triesLeft } = evt;

        clearTimeout(this.timeoutRef);
        switch (type) {
            case 'ready':
                this.setState({ isGeneratingKey: false, isKeyReady: true });
                break;

            case 'retry':
                this.setState({
                    triesLeft,
                    error: _t('The code entered does not match, you have %(triesLeft)s more attempts',
                        {triesLeft},
                        ),
                    isSubmitting: false,
                });
                break;

            case 'cancel':
                this.props.onFinished();
                break;

            case 'valid':
                break;

            default: break;
        }
    };

    onKeyChange = (key) => {
        this.setState({ key, error: null });
    };

    onSubmit = async (evt) => {
        evt.preventDefault();
        const { key } = this.state;
        if (key) {
            MatrixClientPeg.get().trackUserAction({
                formId: 'requestE2EKeys',
                version: 1,
                action: 'confirm',
            });
            this.setState({ isSubmitting: true });
            try {
                await MatrixClientPeg.get().submitCode(key);
            } catch (e) {
                MatrixClientPeg.get().trackUserAction({
                    formId: 'requestE2EKeys',
                    version: 1,
                    action: 'error',
                    step: 'confirm',
                    code: e.httpStatus,
                    reason: e.error,
                });
                this.displaySuccessOrFailPopup(DIALOG_TYPE.ERROR);
            }
        }
    };

    onRequestNewKey = (step) => async () => {
        const { devices = [] } = this.props;
        const { currentDeviceIndex, isGeneratingKey, isKeyReady } = this.state;

        if (isGeneratingKey || isKeyReady) this.cancelRequestForDevice(currentDeviceIndex);

        this.setState({ isGeneratingKey: true, key: '', error: null, isKeyReady: false });
        if (currentDeviceIndex >= 0) {
            try {
                if (this.timeoutRef) clearTimeout(this.timeoutRef);
                this.timeoutRef = setTimeout(this.displaySuccessOrFailPopup, REQUEST_TIMEOUT);
                await MatrixClientPeg.get().startEasyKeyRequest({ remoteDeviceId: devices[currentDeviceIndex].device_id, type: 'citadel.code_start' });
            } catch (e) {
                MatrixClientPeg.get().trackUserAction({
                    formId: 'requestE2EKeys',
                    version: 1,
                    action: 'error',
                    step: step,
                    code: e.httpStatus,
                    reason: e.error,
                });
                this.setState({ isGeneratingKey: false });
                this.displaySuccessOrFailPopup(false);
                clearTimeout(this.timeoutRef);
           }
        }
    };

    cancelRequestForDevice = async (deviceIndex) => {
        const { devices = [] } = this.props;
        const { device_id: deviceId, name } = devices[deviceIndex];

        clearTimeout(this.timeoutRef);
        try {
            await MatrixClientPeg.get().cancelEasyKeyRequest(deviceId);
        } catch (e) {
            console.log('There was an error cancelling the key request for device: ', name, e);
        }
    };

    onCloseForm = () => {
        clearTimeout(this.timeoutRef);
        this.setState({ isSkipPopup: true });
        MatrixClientPeg.get().trackUserAction({
            formId: 'requestE2EKeys',
            version: 1,
            action: 'cancel',
        });
    };

    onCancelSkip = () => {
        MatrixClientPeg.get().trackUserAction({
            formId: 'requestE2EKeys',
            version: 1,
            action: 'return',
        });
        this.setState({ isSkipPopup: false });
    };

    handleNextDevice = () => {
        MatrixClientPeg.get().trackUserAction({
            formId: 'requestE2EKeys',
            version: 1,
            action: 'switch',
        });
        const { devices = [] } = this.props;
        const { currentDeviceIndex, isGeneratingKey, isKeyReady } = this.state;

        if (isGeneratingKey || isKeyReady) this.cancelRequestForDevice(currentDeviceIndex);

        if (currentDeviceIndex < devices.length - 1) {
            this.setState(
                {
                    currentDeviceIndex: currentDeviceIndex + 1,
                    isSkipPopup: false,
                    isSubmitting: false,
                    isGeneratingKey: false,
                    isKeyReady: false,
                    key: '',
                },
                () => this.onRequestNewKey('new')(),
            );

            return;
        }
        this.props.onFinished();
    };

    handleCancelRequest = async () => {
        const { devices = [] } = this.props;
        const { currentDeviceIndex } = this.state;

        try {
            await MatrixClientPeg.get().cancelEasyKeyRequest(devices[currentDeviceIndex].device_id);
            MatrixClientPeg.get().trackUserAction({
                formId: 'requestE2EKeys',
                version: 1,
                action: 'skip',
            });
        } catch (e) {
            MatrixClientPeg.get().trackUserAction({
                formId: 'requestE2EKeys',
                version: 1,
                action: 'error',
                step: 'skip',
                code: e.httpStatus,
                reason: e.error,
            });
            this.displaySuccessOrFailPopup(DIALOG_TYPE.ERROR);
        }
        this.props.onFinished();
    };

    displaySuccessOrFailPopup = (type = DIALOG_TYPE.ERROR) => {
        const { onFinished } = this.props;
        const onFinish = FAIL_CASES.includes(type) ? this.handleNextDevice : onFinished;
        Modal.createDialog(
            KeySyncSuccessOrFailure,
            { type, onFinished: onFinish },
            'key_share_success_failure',
        );
    };

    sendANewCode = () => {
        MatrixClientPeg.get().trackUserAction({
            formId: 'requestE2EKeys',
            version: 1,
            action: 'new',
        });
        this.onRequestNewKey('new')();
    }

    renderKeyForm = () => {
        const { devices = [] } = this.props;
        const { currentDeviceIndex, error, key, isSubmitting, isGeneratingKey } = this.state;
        const totalNoOfDevices = devices.length;
        const isMultipleDevice = totalNoOfDevices > 1;
        const { display_name: currentDeviceName = _t('unknown device') } = devices[currentDeviceIndex];
        let text = (
            <p>
                {_t('To view your encrypted conversations, enter the verification code sent in the Citadel ' +
                    'application on your second device to which you recently logged on.',
                )}
            </p>
        );
        let footer = <div className="footer-inner" />;

        if (isMultipleDevice) {
            text = (
                <p>
                    {_t('To view your encrypted conversations, enter the verification code sent in the Citadel ' +
                        'application on your other device <br />',
                        {},
                        { br: <br /> },
                    )}
                    {/* eslint-disable-next-line no-irregular-whitespace */}
                    <b>{`« ${currentDeviceName} »`}</b>
                    {'.'}
                </p>
            );

            footer = (
                <div className="footer-inner">
                    <div className="info" key="info">
                        {_t("If the device does not receive a verification code, please try on another registered device.")}
                    </div>
                    <div className="next-device" key="next-device">
                        <a onClick={this.handleNextDevice}>
                            {`${_t('Switch to another device (%(currentDevice)s/%(totalNoOfDevices)s)', {
                                currentDevice: currentDeviceIndex + 1,
                                totalNoOfDevices,
                            })}`}
                        </a>
                    </div>
                </div>
            );
        }

        const isTokenValid = validateToken(key);
        const keyForm = isGeneratingKey
            ? <Spinner className="generating-key-spinner" />
            : (<form onSubmit={this.onSubmit}>
                <CitadelCodeInput
                    className="verification_code"
                    type="tel"
                    fields={6}
                    onChange={this.onKeyChange}
                    value={key}
                    label={_t('Verification code')}
                    error={error}
                />
                <div className="submit_button_wrapper">
                    <div className={cx('button', { disabled: isSubmitting || !isTokenValid || error })}>
                        {!isSubmitting ?
                            <input
                                className="submit-button"
                                type="submit"
                                value={_t('Confirm')}
                                disabled={!isTokenValid || error}
                            /> :
                            <Spinner className="submitting-key-spinner" w={20} h={20} />
                        }
                    </div>
                </div>
            </form>);

        return (
            <div className="mx_Dialog_content">
                {text}
                {keyForm}
                <div className="mx_Dialog_buttons">
                    <button onClick={this.sendANewCode} autoFocus={true}>
                        {_t('Send me a new code')}
                    </button>
                </div>
                <div className="footer">{footer}</div>
            </div>
        );
    };

    renderSkipPopupContent = () => (
        <div className="mx_Dialog_content skip_step">
            <p>
                {_t('If you skip this step your encrypted conversations will not be decrypted on this ' +
                    'device. To access it, enter the code you requested in the previous step.',
                )}
            </p>
            <div className="mx_Dialog_buttons">
                <button className="skip_confirm_btn" onClick={this.onCancelSkip} autoFocus={true}>
                    {_t('Return to code')}
                </button>
                <button className="skip_step_cancel" onClick={this.handleCancelRequest} >
                    {_t('Skip this step')}
                </button>
            </div>
        </div>
    );

    onInfoPopupFinished = () => {
        this.onRequestNewKey('send')();
        MatrixClientPeg.get().trackUserAction({
            formId: 'requestE2EKeys',
            version: 1,
            action: 'send',
        });
    }

    renderInfoPopup = () => {
        const { devices = [] } = this.props;
        const { currentDeviceIndex } = this.state;
        const { display_name: currentDeviceName = _t('unknown device') } = devices[currentDeviceIndex];

        Modal.createDialog(
            KeyRequesterInfoDialog,
            {
                currentDeviceName,
                onFinished: this.onInfoPopupFinished,
            },
            'key_share_info',
        );
    };

    render() {
        const { isSkipPopup } = this.state;
        const title = isSkipPopup ? _t('Do you really want to skip this step?') : _t('Synchronize your encryption keys');

        return (
            <BaseDialog
                className="mx_KeyShareConfirmationDialog"
                onFinished={this.onCloseForm}
                hasCancel={!isSkipPopup}
                title={title}
                contentId="mx_Dialog_content"
            >
                {isSkipPopup ? this.renderSkipPopupContent() : this.renderKeyForm()}
            </BaseDialog>
        );
    }
}

KeyRequesterDialog.propTypes = {
    devices: PropTypes.array,
    mxEvent: PropTypes.object.isRequired,
    onFinished: PropTypes.func.isRequired,
};

KeyRequesterDialog.defaultProps = {
    devices: [],
    onFinished: noop,
};
