import dis from '../dispatcher';
import { Store } from 'flux/utils';
import {isEmpty, noop} from 'lodash';

import MatrixClientPeg from '../MatrixClientPeg';
import Modal from '../Modal';
import NewsLeaveDialog from '../components/views/dialogs/news/NewsLeaveDialog';

const INITIAL_STATE = {
    news: {},
    visibility: '',
};

class NewsStore extends Store {
    constructor() {
        super(dis);

        // Initialise state
        this._newsStore = INITIAL_STATE;
    }

    // Update store
    _updateNewsCreationStore(news) {
        this._newsStore = { ...this._newsStore, news };
        this.__emitChange();
    }

    _updateNewsEditionVisibility(visibility) {
        this._newsStore = { ...this._newsStore, visibility };
        this.__emitChange();
    }

    _loadDefeault() {
        this._newsStore = INITIAL_STATE;
        this.__emitChange();
    }

    clearStore() {
        dis.dispatch({ action: 'clear_news_creation_store' });
    }

    // Return whole store
    getNewsCreationStore() {
        return this._newsStore;
    }

    hasNews() {
        const { news: { content, previewName, title }} = this._newsStore;
        return !isEmpty(title) || !isEmpty(content) || !isEmpty(previewName);
    }

    trackSendAction = (err, resp) => {
        if (!err) {
            const { event_id } = resp;

            MatrixClientPeg.get().trackUserAction({
                formId: 'commMana',
                version: 1,
                event_id,
            });
        }
    };

    sendNews = async (room, onFinished = noop) => {
        const { news } = this._newsStore;
        const { content, title, file } = news;

        onFinished();

        const imageUrl = await MatrixClientPeg.get().uploadContent(file, {
            sendUsingFormData: true,
            endpoint: `/upload/room/${room.roomId}`,
            prefix: '/_matrix/media/r0',
            filename: file.name,
        });
        const messageContent = {
            msgtype: 'm.news',
            body: title,
            newsbody: content,
            newsimg: imageUrl,
            newstitle: title,
        };

        if (room && room.maySendMessage()) {
            MatrixClientPeg.get().sendMessage(room.roomId, messageContent, this.trackSendAction);
        }
        this.clearStore();
    };

    showLeaveDialogIfNeeded(onContinue, onCancel) {
        if (this.hasNews()) {
            Modal.createDialog(
                NewsLeaveDialog,
                { onContinue, clearStore: this.clearStore, onCancel },
                'news_leave_dialog_wrapper',
                true,
            );
            return;
        }
        onContinue();
    }

    __onDispatch(payload) {
        switch (payload.action) {
            case 'update_news_creation_store':
                this._updateNewsCreationStore(payload.news);
                break;
            case 'clear_news_creation_store':
                this._loadDefeault();
                break;
            case 'update_news_visibility':
                this._updateNewsEditionVisibility(payload.visibility);
                break;
            default:
                break;
        }
    }
}

let singletonNewsStore = null;
if (!singletonNewsStore) { singletonNewsStore = new NewsStore(); }

module.exports = singletonNewsStore;
