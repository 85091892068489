import { _t } from '../../../languageHandler';
import CitadelToggle from '../elements/CitadelToggle';
import React from 'react';
import PropTypes from 'prop-types';

export const powerLevelsDescriptors = {
    USER: {
        label: 'All the users',
        value: '0',
    },
    MOD: {
        label: 'Moderators and Administrators',
        value: '50',
    },
    ADMIN: {
        label: 'Administrators',
        value: '100',
    },
};

export const permissionRulesDescriptors = {
    events_default: {
        description: 'Who can send messages?',
        defaultValue: powerLevelsDescriptors.USER.value,
    },
    invite: {
        description: 'Who can invite in this room?',
        defaultValue: powerLevelsDescriptors.MOD.value,
    },
};

export const PowerLevelToggle = ({ powerLevel, checked, disabled, onChange }) => {
    return (
        <div className={checked ? "selected-toggle" : ""}>
            <CitadelToggle
                type="radio"
                label={_t(powerLevelsDescriptors[powerLevel].label)}
                checked={checked}
                disabled={disabled}
                onChange={onChange}
            />
        </div>
    );
};

PowerLevelToggle.propTypes = {
    powerLevel: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

PowerLevelToggle.defaultProps = {
    disabled: false,
    onChange: () => {},
};

