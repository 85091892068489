import React from 'react';
import { noop, map, isEqual, forEach, isEmpty, reduce, omit, debounce } from 'lodash';
import io from 'socket.io-client';
import cx from 'classnames';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import adapter from 'webrtc-adapter';

import CitadexStore, { TYPE_AUDIO, TYPE_VIDEO, TYPE_DESKTOP } from '../../../stores/CitadexStore';
import {
    getProfileInfo, getStreams, getCustomVideoConstraints, removeBandwidthRestriction, updateBandwidthRestriction,
} from '../../../utils/Citadex';
import { WebRTCMedia, StreamAPI } from '../../../utils/WebRtc';
import dis from '../../../dispatcher';
import { _t } from '../../../languageHandler';
import PlatformPeg from '../../../PlatformPeg';
import MatrixClientPeg from "../../../MatrixClientPeg";
import { LIST_TYPE as TOAST_LIST_TYPE } from "../../../stores/ToastStore";

import ParticipantRow from './ParticipantRow';
import ParticipantCard from './ParticipantCard';
import ConferenceBottomBar from './ConferenceBottomBar';
import ConferenceTopBar from "./ConferenceTopBar";
import { errorDialog, ERROR_TYPES } from './ErrorDialogsHelper';
import Stats from './Stats';
import { KeyCode } from '../../../Keyboard';
import Modal from '../../../Modal';
import KickParticipantDialog from '../dialogs/conference/KickParticipantDialog';

const FULL_SCREEN_ELEMENT_CLASSNAME = 'cards-container';
const IGNORED_SOCKET_ERRORS = ['WATCH_VIDEO_ERROR', 'NOT_PUBLISHING', 'ALREADY_WATCHING'];
const LOCAL_STREAM_STATS = {
    audio: [],
    video: ['frameRate', 'height', 'width'],
};
const MUTED_TOAST_TYPE = 'MUTED';
const AUDIO_ELEMENT_IDS = ['citadel-join', 'citadel-leave'];

class CitadexCall extends React.Component {
    static propTypes = {
        audioSourceId: PropTypes.string,
        audioSourceLabel: PropTypes.string,
        callInProgress: PropTypes.object.isRequired,
        isAudioEnabled: PropTypes.bool.isRequired,
        isMinimized: PropTypes.bool,
        isVideoEnabled: PropTypes.bool.isRequired,
        matrixUserId: PropTypes.string.isRequired,
        onChangeDevices: PropTypes.func.isRequired,
        onEndCallClick: PropTypes.func.isRequired,
        onSettingsClick: PropTypes.func.isRequired,
        outputId: PropTypes.string,
        roomId: PropTypes.number.isRequired,
        roomName: PropTypes.string.isRequired,
        stopElementTracks: PropTypes.func.isRequired,
        type: PropTypes.string.isRequired,
        videoSourceId: PropTypes.string,
        isSoundNotificationOn: PropTypes.bool,
        isVideoStreamSubscriptionDisabled: PropTypes.bool,
    };
    wasCameraEnabled = null;
    videoSubscribersIds = {};
    localAudioStream = null;

    constructor(props, context) {
        super(props, context);
        const { isAudioEnabled, isVideoEnabled } = props;
        this.state = {
            audioStreams: {},
            cameraQualitySetting: 'auto',
            debugMode: false,
            isAudioEnabled,
            isCameraEnabled: isVideoEnabled,
            isDmRoom: CitadexStore.getIsDmRoom(),
            isOffline: this.props.isOffline,
            isNetworkIssuePersisting: this.props.isOffline,
            muteToastId: null,
            previousCameraQualitySetting: 'auto',
            screenSharerStreamId: null,
            screenshareQualitySetting: 'auto',
            showTopAndBottomBar: true,
            totalBitrateInbound: 0,
            totalBitrateOutbound: 0,
            videoStreams: {},
            isVideoPublished: false,
            isAudioPublished: false,
            waitingForVideoToBePublished: true && isVideoEnabled,
        };
        this.debouncedSetState = debounce(this.setState, 4000);
        this.promisifiedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));
        this.debouncedApplyConstraints = debounce(this.applyConstraints, 2000);
        this.bitrate = {
            inbound: {},
            outbound: {},
        };
        this.shouldCloseTab = true;
        this.isUserKicked = false;
    }

    async componentDidMount() {
        CitadexStore.log(`Call type: ${this.props.type}`);
        if (MatrixClientPeg.get()) {
            await this.joinJanusRoom();
        }
        this.dispatcherRef = dis.register(this.onAction);
        window.addEventListener('keydown', this.onKeyDown);

        this.toggleConferenceMenu({
            mic: this.state.isAudioEnabled,
            cam: this.state.isCameraEnabled,
            screenshare: this.isShareScreenEnabled(),
        });

        dis.dispatch({ action: "reset_toast_store", payload: { listType: TOAST_LIST_TYPE.CITADEX }});
    }

    async componentDidUpdate(prevProps, prevState) {
        const {
            audioStreams: prevAudioStreams = {},
            videoStreams: prevVideoStreams = {},
            screenSharerStreamId: prevScreenSharerStreamId,
        } = prevState;
        const {
            audioSourceId: prevAudioSourceId,
            videoSourceId: prevVideoSourceId,
            outputId: prevOutputId,
            isVideoStreamSubscriptionDisabled: prevIsVideoStreamSubscriptionDisabled,
            audioSourceLabel: prevAudioSourceLabel,
        } = prevProps;
        const {
            audioStreams,
            videoStreams,
            screenSharerStreamId,
            cameraQualitySetting,
            screenshareQualitySetting,
        } = this.state;
        const {
            audioSourceId,
            videoSourceId,
            outputId,
            onChangeDevices,
            type,
            isVideoStreamSubscriptionDisabled,
            roomId,
            audioSourceLabel,
        } = this.props;

        if (prevProps.citadexKey < this.props.citadexKey) {
            this.setState({
                isNetworkIssuePersisting: false,
            });
        }
        if (!prevProps.isOffline && this.props.isOffline) {
            dis.dispatch({
                action: 'audio-toggle',
                value: false,
            });
        }

        this.attachMediaStreamIfNeeded(TYPE_VIDEO, videoStreams, prevVideoStreams);
        this.attachMediaStreamIfNeeded(TYPE_AUDIO, audioStreams, prevAudioStreams);

        if (isVideoStreamSubscriptionDisabled !== prevIsVideoStreamSubscriptionDisabled) {
            if (isVideoStreamSubscriptionDisabled) {
                this.handler.closeIncomingVideos();
            } else {
                Object.keys(this.videoSubscribersIds).forEach((videoStreamId) => {
                    if (this.videoSubscribersIds.hasOwnProperty(videoStreamId) && !!videoStreamId) {
                        this.subscribeParticipant(
                            roomId,
                            parseInt(videoStreamId),
                            this.videoSubscribersIds[videoStreamId],
                        );
                    }
                });
            }
        }

        if (screenSharerStreamId !== prevScreenSharerStreamId) {
            dis.dispatch({
                action: 'screen_sharer_changed',
                screenSharerStreamId,
                shouldHideTopBar: !!screenSharerStreamId && screenSharerStreamId !== this.publisherId,
            });
        }

        if (audioSourceId &&
            prevAudioSourceId &&
            audioSourceLabel &&
            prevAudioSourceLabel &&
            audioSourceLabel !== prevAudioSourceLabel) {
            this.replaceStream(TYPE_AUDIO, prevAudioSourceId, prevVideoSourceId);
        }

        if (videoSourceId && prevVideoSourceId && videoSourceId !== prevVideoSourceId) {
            this.replaceStream(TYPE_VIDEO, prevAudioSourceId, prevVideoSourceId);
        }

        if (outputId && prevOutputId && outputId !== prevOutputId) {
            const element = document.getElementById(`audio-element-${this.handler.media.audio.pub_id}`);
            if (element) {
                try {
                    CitadexStore.log(`try to switch output device to (${outputId})`);
                    StreamAPI.attachSinkId(element, outputId);
                    this.changeAudioElementsOutput(outputId);
                } catch (error) {
                    CitadexStore.error(error);
                    CitadexStore.log(`switch output device to previous one(${prevOutputId})`);
                    onChangeDevices({ audioSourceId, videoSourceId, outputId: prevOutputId });
                }
            }
        }

        if (((Object.keys(prevAudioStreams).length !== Object.keys(audioStreams).length && (type === TYPE_VIDEO)) ||
            prevState.cameraQualitySetting !== this.state.cameraQualitySetting ||
            prevState.screenshareQualitySetting !== this.state.screenshareQualitySetting
        )
            && (this.isShareScreenEnabled() || type === TYPE_VIDEO)) {
            const videoConstraints = getCustomVideoConstraints(
                Object.keys(audioStreams).length,
                this.isShareScreenEnabled(),
                cameraQualitySetting,
                screenshareQualitySetting,
            );
            this.debouncedApplyConstraints(videoConstraints);
        }
    }

    componentWillUnmount() {
        this.debouncedSetState.cancel();
        this.handler.closeBroadcast && this.handler.closeBroadcast();
        if (this.dispatcherRef) {dis.unregister(this.dispatcherRef);}
        this.debouncedApplyConstraints.cancel();
        this.toggleConferenceMenu();
        window.removeEventListener('keydown', this.onKeyDown);
        this.networkIssuesTimeout && clearTimeout(this.networkIssuesTimeout);
    }

    handler = {};
    userId = null;
    broadcastId = null;
    publisherId;
    localVideoStream = null;
    shouldDisplayWarning = true;
    attachedStreams = {};
    justJoinedCall = true;
    currentSettings = {
        audio: {},
        video: {},
    }

    onAction = async (payload) => {
        const { muteToastId } = this.state;
        if (payload.action === 'toast_added' && payload.value.type === MUTED_TOAST_TYPE) {
            this.setState({ muteToastId: payload.value.id });
        }
        if (payload.action === 'toast_removed' && payload.value.id === muteToastId) {
            this.setState({ muteToastId: null });
        }
        if (payload.action === 'conference-event') {
            const { data } = payload;
            if (data) {
                const { type, status } = data;
                switch (type) {
                    case 'microphone':
                        this.onMute(status);
                        break;
                    case 'camera':
                        this.onHideCamera(status);
                        break;
                    case 'screenshare':
                        // if the screen selector modal is open, don't do anything
                        // in this case the event will be consumed by the modal
                        if (!document.querySelector('.electron-screen-sharing-dialog')) {
                            this.handleShareScreenClick(false, true);
                        }
                }
            }
        }
        if (payload.action === 'client_started') {
            await this.joinJanusRoom();
        }
    }

    toggleConferenceMenu = (payload) => {
        const { type } = this.props;
        const platform = PlatformPeg.get();
        if (!platform.isDesktop()) return;
        if (payload) {
            const newPayload = {
                microphone: payload.mic,
                screenshare: payload.screenshare,
            };
            if (type === 'video') {
                newPayload.camera = payload.cam;
            }
            platform.toggleConferenceMenu(newPayload);
        } else {
            platform.toggleConferenceMenu();
        }
    }

    changeAudioElementsOutput = (outputId) => {
        AUDIO_ELEMENT_IDS.forEach((id) => StreamAPI.attachSinkId(document.getElementById(id), outputId));
    }

    onKeyDown = (e) => {
        if ((!e.altKey && !e.ctrlKey && !e.metaKey && !e.shiftKey) ||
            e.key === "Meta" || e.key === "Shift" || e.key === "Control" || e.key === "alt"
        ) {
            return;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode === KeyCode.KEY_D) {
            this.setState(prevState => ({ debugMode: !prevState.debugMode }));
        }
    }

    applyConstraints = (constraints) => {
        if (this.state.isOffline) return;
        if (!this.handler.media.video) return;
        const sender = this.handler.media.video.pc.getSenders().find(
            (s) => s.track.kind === TYPE_VIDEO,
        );

        const videoConstraints = {
            ...omit(sender.track.getConstraints(), ['width', 'height']),
            ...omit(constraints, ['bitrate']),
        };

        const bandwidth = constraints.bitrate;
        CitadexStore.log('try to apply new video constraints', videoConstraints);
        sender.track.applyConstraints(videoConstraints).then(() => {
            this.currentSettings = sender.track.getSettings();
        }).then(() => {
            CitadexStore.log(`max resolution changed to
                    width: ${videoConstraints.width.max} height: ${videoConstraints.height.max}`);
            this.currentSettings.video = sender.track.getSettings();
            },
            ).catch(e => {
                CitadexStore.error('something went wrong', e);
            });

        // In Chrome, use RTCRtpSender.setParameters to change bandwidth without
        // (local) renegotiation. Note that this will be within the envelope of
        // the initial maximum bandwidth negotiated via SDP.
        if ((adapter.browserDetails.browser === 'chrome' ||
            adapter.browserDetails.browser === 'safari' ||
            (adapter.browserDetails.browser === 'firefox' &&
                adapter.browserDetails.version >= 64)) &&
            'RTCRtpSender' in window &&
            'setParameters' in window.RTCRtpSender.prototype) {
            const parameters = { ...sender.getParameters() };
            if (!parameters.encodings || !parameters.encodings[0]) {
                parameters.encodings = [{}];
            }
            parameters.encodings[0].maxBitrate = bandwidth * 1000;
            sender.setParameters(parameters)
                .then(() => {
                    CitadexStore.log(`Max bitrate changed to: ${bandwidth} kbps`);
                    this.currentSettings.video = sender.track.getSettings();
                })
                .catch(e => CitadexStore.error(e));
            return;
        }

        // Fallback to the SDP munging with local renegotiation way of limiting
        // the bandwidth.

        sender.createOffer()
            .then(offer => sender.setLocalDescription(offer))
            .then(() => {
                const desc = {
                    type: sender.remoteDescription.type,
                    sdp: bandwidth === 'unlimited' ?
                        removeBandwidthRestriction(sender.remoteDescription.sdp) :
                        updateBandwidthRestriction(sender.remoteDescription.sdp, bandwidth),
                };
                CitadexStore.log('Applying bandwidth restriction to setRemoteDescription:\n' + desc.sdp);
                return sender.setRemoteDescription(desc);
            })
            .then(() => {
                CitadexStore.log(`Max bitrate changed to: ${bandwidth} kbps`);
                this.setState({ waitingForVideoToBePublished: false });
                this.currentSettings.video = sender.track.getSettings();
            })
            .catch((err) => CitadexStore.log('Failed to set session description: ' + err.toString()));
    }

    attachMediaStreamIfNeeded = (type, streams, prevStreams) => {
        const { outputId } = this.props;
        const isVideo = type === TYPE_VIDEO;
        if (!isEqual(streams, prevStreams)) {
            forEach(streams, (stream) => {
                if (!!stream.stream
                    && (!prevStreams[stream.id] || !isEqual(stream.stream, prevStreams[stream.id].stream))
                    && ((isVideo && !this.attachedStreams[stream.id]) || (!isVideo))
                ) {
                    const element = document.getElementById(`${type}-element-${stream.id}`);
                    if (isVideo) this.attachedStreams[stream.id] = true;
                    StreamAPI.attachMediaStream(element, stream.stream);
                    if (!isVideo && outputId) {
                        try {
                            StreamAPI.attachSinkId(element, outputId);
                            this.changeAudioElementsOutput(outputId);
                        } catch (error) {
                            CitadexStore.error(error);
                        }
                    }
                }
            });
        }
    };

    replaceStream = (type, prevAudioSourceId, prevVideoSourceId) => {
        if (this.state.isOffline) return;
        const { audioSourceId, videoSourceId, onChangeDevices, outputId, stopElementTracks } = this.props;
        const isAudioStream = type === TYPE_AUDIO;
        const newInputId = isAudioStream ? audioSourceId : videoSourceId;
        CitadexStore.log(`try to switch ${type} input device to (${newInputId})`);
        getStreams({
            type,
            callback: (stream) => {
                const track = isAudioStream ? stream.getAudioTracks()[0] : stream.getVideoTracks()[0];
                const sender = this.handler.media[type].pc.getSenders().find(
                    (s) => s.track.kind === track.kind,
                );
                this.currentSettings[type] = sender.track.getSettings();
                if (sender) {
                    sender.replaceTrack(track).then(() => {
                        const { isAudioEnabled, isCameraEnabled } = this.state;
                        const enabled = isAudioStream ? isAudioEnabled : isCameraEnabled;
                        this.handler.media[type].set_media(stream);
                        this.handler.toggleMedia(type, enabled);
                        if (!isAudioStream) {
                            this.localVideoStream = stream;
                            const element = document.getElementById(
                                `video-element-${this.handler.media.video.pub_id}`,
                            );
                            if (element) {
                                try {
                                    stopElementTracks(element.srcObject, element);
                                    StreamAPI.attachMediaStream(element, stream);
                                } catch (error) {
                                    CitadexStore.error(error);
                                }
                            }
                        } else {
                            this.localAudioStream.stop();
                            this.localAudioStream = track;
                        }
                        this.handler.sendState(type);
                    });
                }
            },
            onCancel: noop,
            onError: (error) => {
                const selectedDevices = { audioSourceId, videoSourceId, outputId };
                if (isAudioStream) {
                    selectedDevices.audioSourceId = prevAudioSourceId;
                } else {
                    selectedDevices.videoSourceId = prevVideoSourceId;
                }
                CitadexStore.error(`can not get stream from new selected ${type} input`, error);
                CitadexStore.log(`switch output ${type} input to previous one(${newInputId})`);
                onChangeDevices(selectedDevices);
            },
            isShareScreen: false,
            selectedDevices: { audioSourceId, videoSourceId },
        });
    }

    handleLeave = () => {
        if (!this.shouldCloseTab) return;
        // do nothing when we are offline, wait for a reconnect
        if (this.state.isOffline) return;

        this.onEndCall()();
    }

    joinJanusRoom = async () => {
        const { roomId, type, videoSourceId } = this.props;
        const { isCameraEnabled } = this.state;
        const url = CitadexStore.getVisioServer();
        this.broadcastId = roomId; // broadcastId is the same as roomId
        CitadexStore.log('BroadcastId/RoomId', this.broadcastId);

        try {
            const myId = localStorage.getItem('mx_user_id');
            this.publisherId = await CitadexStore.joinRoom(this.broadcastId, myId);
            CitadexStore.log('My publisher id is:', this.publisherId);
            const wrapperAddress = CitadexStore.getWrapperAddress();
            if (!wrapperAddress) {
                CitadexStore.log('Wrapper address not found: End call');
                errorDialog({ onContinue: this.onEndCall(), type: ERROR_TYPES.GENERIC });
                return;
            }

            CitadexStore.log('Wrapper address:', wrapperAddress);

            const wsUrl = { url, resource: '/avwrapper/' + wrapperAddress + '/socket.io' };
            this.addWrapper(wsUrl, () => {
                // once connection was completed, start broadcast
                CitadexStore.log('Remote wrapper connected');
                let streamType = TYPE_AUDIO;
                if (type === TYPE_VIDEO && !!videoSourceId && isCameraEnabled) streamType = TYPE_VIDEO;
                this.getLocalStreams(streamType);
                CitadexStore.setIsCitadexOpened(true);
                dis.dispatch({
                    action: 'toggle-preview-conference',
                    value: false,
                });
                dis.dispatch({
                    action: 'reset-retry-process',
                });
            }, this.handleLeave);
        } catch (e) {
            // sometimes we get a cors error if we try to connect to the conference and we're offline
            if (e.message.includes('CORS')) {
                return;
            } else {
                CitadexStore.error(`Can not join room ${e}`);
                errorDialog({ onContinue: this.onEndCall(true), type: ERROR_TYPES.GENERIC });
                // error modal shown in LoggedInView in RoomStateEvent
            }
        }
    };

    initializeHandler = () => {
        if (!this.broadcastId || !this.publisherId) return;

        this.userId = `s_${Math.floor(Number.MAX_SAFE_INTEGER * Math.random())}`;
        this.handler = {
            user_id: this.userId,
            room: this.broadcastId,
            publisherId: this.publisherId,
            media: { incomings: {} },
        };
    };

    isShareScreenEnabled = () => this.state.screenSharerStreamId === this.publisherId;

    addWrapper = async (wrapper, connectionCallback, disconnectionCallback) => {
        const { matrixUserId, type } = this.props;
        connectionCallback = connectionCallback || noop;
        disconnectionCallback = disconnectionCallback || noop;
        this.initializeHandler();

        const { jwt: token } = await CitadexStore.getPublishingToken(this.handler);
        const roomId = this.handler.room;

        this.handler.token = token;
        this.handler.socket = io(wrapper.url, {
            path: wrapper.resource,
            query: {
                id: this.handler.user_id,
                name: this.handler.user_id,
                token: this.handler.token || token,
                room: this.handler.room,
                description: `communication-channel-${this.handler.user_id}`,
            },
            rejectUnauthorized: false,
            reconnection: false,
        });

        this.handler.on = this.handler.socket.on;
        this.handler.off = this.handler.socket.off;

        this.handler.socket.on('connect', () => {
            CitadexStore.log('Socket connected');
            // send a message
            this.handler.send = (type, data, token) => {
                const msgId = Math.random().toString(36).slice(2);
                const message = { _id: msgId };
                if (token) message.token = token;
                message.data = data || null;
                this.handler.socket.emit(type, message);
            };

            this.handler.closeBroadcast = () => {
                if (this.handler.socket) {
                    this.handler.socket.close();
                    CitadexStore.log('socket connection was closed');
                }
                this.handler.closeMedia();
            };

            this.handler.closeMedia = () => {
                const media = this.handler.media;
                const audio = media.audio || { close: noop };
                const video = media.video || { close: noop };

                Object.values(media.incomings).forEach((item) => {
                    item.close && item.close();
                });

                audio.close();
                video.close();
                CitadexStore.log('all media connection are closed');
            };

            this.handler.closeIncomingVideos = () => {
                const media = this.handler.media;
                const { screenSharerStreamId } = this.state;

                Object.values(media.incomings).forEach((item) => {
                    if (item.pub_id !== screenSharerStreamId) {
                        item.close && item.close();
                        delete this.attachedStreams[item.pub_id];
                    }
                });

                CitadexStore.log('all incoming videos are closed');
            };

            this.handler.closeVideoMedia = () => {
                const media = this.handler.media;
                const video = media.video || { close: noop };

                video.close();
                this.handler.media.video = null;
            };

            this.handler.toggleMedia = (type, enabled) => {
                CitadexStore.log(`Toggle media ${type} ${enabled ? 'enabled' : 'disabled'}`);
                if (type === TYPE_AUDIO) {
                    this.handler.media[type].mute(!enabled, this.handler.publisherId);
                } else {
                    this.handler.media[type] && this.handler.media[type].mute(!enabled);
                }
            };

            this.handler.shareScreen = (enabled) => {
                if (enabled) {
                    // save the camera state when turning on the screen sharing
                    // in case the screen sharing is hijacked
                    this.wasCameraEnabled = this.wasCameraEnabled !== null
                        ? this.wasCameraEnabled
                        : this.state.isCameraEnabled;
                }
                CitadexStore.log(`${enabled ? 'enabled' : 'disabled'} share screen`);
                const data = {
                    id: parseInt(this.publisherId),
                    room: parseInt(roomId),
                    enabled: true,
                    display: matrixUserId,
                    isShareScreenEnabled: enabled,
                };
                this.handler.send('configured-video', data, this.handler.token);
            };

            this.handler.sendState = (type) => {
                const { audio, video } = this.handler.media;
                const isScreenSharingEnabled = this.isShareScreenEnabled();

                switch (type) {
                    case TYPE_AUDIO: audio && audio.stream && audio.sendState(this.publisherId); break;
                    case TYPE_VIDEO: {
                        if (isScreenSharingEnabled) {
                            video && video.stream && video.sendState(undefined, isScreenSharingEnabled);
                        } else {
                            video && video.stream && video.sendState();
                        }
                        break;
                    }
                    default: {
                        audio && audio.stream && audio.sendState(this.publisherId);
                        if (isScreenSharingEnabled) {
                            video && video.stream && video.sendState(undefined, isScreenSharingEnabled);
                        } else {
                            video && video.stream && video.sendState();
                        }
                    }
                }
            };

            connectionCallback();
        });

        this.handler.socket.on('disconnect', () => {
            if (this.isUserKicked) return;
            // if you were sharing your screen, close the local screen share
            const isSharingScreen = this.isShareScreenEnabled();
            isSharingScreen && this.handleMediaEvent(TYPE_VIDEO, { id: this.publisherId, isShareScreenEnabled: false });
            if (this.handler.media) this.handler.closeMedia();
            this.networkIssuesTimeout = setTimeout(async () => {
                this.setState({
                    isNetworkIssuePersisting: true,
                    audioStreams: {},
                });
            }, 4000); // 4 seconds for the banner to appear
            this.setState({ isOffline: true, isCameraButtonDisabled: true });
            dis.dispatch({
                action: 'start_retry_process',
                value: true,
            });
        });
        this.handler.socket.on('avroom-error', (error) => {
            if (IGNORED_SOCKET_ERRORS.includes(error.error)) return;

            // prevent showing errors if we are in a reconnecting network state
            if (this.props.retryProcessStarted) return;
            CitadexStore.error('avroom error:', error);
            errorDialog({ onContinue: this.onEndCall(), type: ERROR_TYPES.GENERIC });
        });
        this.handler.socket.on('published-audio', (msg) => {
            const { isAudioEnabled } = this.state;
            if (msg.data.jsep) {
                // is an answer to our publish request
                CitadexStore.log('My audio was published', msg);
                this.handler.media.audio.handle_media((audioEvent) => {
                    CitadexStore.log('audioEvent', audioEvent);
                    // change session identifier of the published feed to the correct one
                    this.handler.media.audio.pub_id = msg.data.id;
                    this.handleStream(
                        TYPE_AUDIO,
                        msg.data.id,
                        { ...msg.data, streams: audioEvent.streams, videoSessionId: this.publisherId, isMe: true },
                        matrixUserId,
                    );
                    if (!isAudioEnabled) {
                        this.onMute(!isAudioEnabled);
                    } else {
                        this.handler.sendState(TYPE_AUDIO);
                    }
                    // once we published the audio, we're sure the conference is open
                    // it needs to be here because we only get here if we are connected to internet, if we are in a reconnecting state
                    this.setState({ isAudioPublished: true, isNetworkIssuePersisting: false, isOffline: false });
                });
                this.handler.media.audio.set_remote_description(msg.data.jsep);
                // retrieving already ongoing audio participants
                CitadexStore.log('Handle ongoing audio streams', msg.data.participants);
                this.handleMultipleAudioStreams(msg.data.participants);
            } else if (msg.data) {
                CitadexStore.log('Another audio stream was published', msg);
                const { id, display } = msg.data;
                this.handleStream(TYPE_AUDIO, id, msg.data, display);
                this.handler.sendState();
            } else {
                CitadexStore.error('Another kind of audio published message was received', msg);
            }
        });

        this.handler.socket.on('published-video', (msg) => {
            CitadexStore.log('Published video', msg);
            if (msg.data.jsep) { // is an answer to our publish request
                const isVideoEnabled = this.isShareScreenEnabled() || this.state.isCameraEnabled;
                CitadexStore.log('My video was published', msg);
                this.handler.media.video.set_remote_description(msg.data.jsep);
                // at this point we can display the local video stream since it was published
                // change session identifier of the published feed to the correct one
                this.handler.media.video.pub_id = msg.data.id;
                this.handleStream(
                    TYPE_VIDEO,
                    msg.data.id,
                    { ...msg.data, streams: [this.localVideoStream], isVideoEnabled },
                    matrixUserId,
                );

                this.handler.sendState(TYPE_VIDEO);

                // retrieving already ongoing video
                CitadexStore.log('Handle ongoing video streams', msg.data.participants);
                msg.data.participants.forEach(({ id, display }) => {
                    this.videoSubscribersIds[id] = display;
                    // subscribe only if we are not yet watching the stream
                    if (!this.attachedStreams[id]) this.subscribeParticipant(roomId, id, display);
                });
            } else {
                if (msg.data.id !== this.publisherId) {
                    this.videoSubscribersIds[msg.data.id] = msg.data.display;
                    this.subscribeParticipant(roomId, msg.data.id, matrixUserId);
                }
            }
        });

        this.handler.socket.on('publish-video-complete', (msg) => {
            this.setState({ isVideoPublished: true, waitingForVideoToBePublished: false });
        });

        this.handler.socket.on('offer-subscriber-video', async (msg) => {
            this.handler.media.incomings[msg.data.id].handle_media((videoEvent) => {
                CitadexStore.log('New participant', msg.data, videoEvent);
                this.handleStream(TYPE_VIDEO, msg.data.id, videoEvent, msg.data.display);
            });
            await this.handler.media.incomings[msg.data.id].set_remote_description(msg.data.jsep)
                .then(this.handler.media.incomings[msg.data.id].send_answer)
                .then(this.handler.media.incomings[msg.data.id].set_local_description)
                .then(this.handler.media.incomings[msg.data.id].start_subscriber_media)
                .catch((e) => CitadexStore.error(`Can not subscribe to the incoming stream ${e}`));
            this.handler.sendState();
        });

        this.handler.socket.on('unpublished-video', (msg) => {
            delete this.handler.media.incomings[msg.data.id];
            this.videoSubscribersIds[msg.data.id] = null;
            this.handleStream(TYPE_VIDEO, msg.data.id, undefined, msg.data.display);
            if (!this.state.isCameraEnabled) {
                this.setState({ waitingForVideoToBePublished: false });
            }
            if (msg.data.id === this.publisherId) {
                this.setState({ isVideoPublished: false });
            }
        });

        this.handler.socket.on('unpublished-audio', (msg) => {
            delete this.handler.media.incomings[msg.data.id];
            this.handleStream(TYPE_AUDIO, msg.data.id, undefined, msg.data.display);

            // in case the person who is sharing the screen leaves the conference
            // the cameraQualitySetting and camera state should be restored
            if (
                type === TYPE_VIDEO
                && msg.data.videoSessionId === this.state.screenSharerStreamId
                && msg.data.videoSessionId !== this.publisherId
            ) {
                this.setState({
                    cameraQualitySetting: this.state.previousCameraQualitySetting,
                    isCameraEnabled: this.wasCameraEnabled,
                });
                this.handler.toggleMedia(type, this.wasCameraEnabled);
            }
        });

        this.handler.socket.on('configured-audio', (msg) => {
            CitadexStore.log('Received configured audio event', msg.data.data);
            this.handleMediaEvent(TYPE_AUDIO, msg.data.data);
        });

        this.handler.socket.on('configured-video', (msg) => {
            CitadexStore.log('Received configured video event', msg.data.data);
            this.handleMediaEvent(TYPE_VIDEO, msg.data.data);
        });

        this.handler.socket.on('mute-all', (ev) => {
            this.onMute(true);
            dis.dispatch({
                action: 'add_toast',
                value: {
                    emitToastData: true,
                    forceAdd: true,
                    listType: TOAST_LIST_TYPE.CITADEX,
                    message: _t('If you want to be heard, please reactivate your microphone.'),
                    onlyOnce: true,
                    title: _t('You have been muted'),
                    type: MUTED_TOAST_TYPE,
                },
            });
        });

        this.handler.socket.on('kicked-acl', (msg) => {
            if (msg.data.reason && msg.data.reason === 'close-conference') {
                CitadexStore.destroySession();
                this.shouldCloseTab = false;
                errorDialog({
                    onContinue: () => this.onEndCall(true)(true),
                    type: ERROR_TYPES.CLOSE_CONFERENCE,
                    autoCloseAfter: 3000, // ms
                });
            }
            if (msg.data.reason && msg.data.reason === 'kicked') {
                this.isUserKicked = true;
                this.shouldCloseTab = false;
                errorDialog({
                    onContinue: () => this.onEndCall(true)(true),
                    type: ERROR_TYPES.KICKED,
                });
            }
        });

        this.handler.socket.on('talking', (msg) => {
            CitadexStore.log('Received talking event', msg.data);
            this.handleMediaEvent(TYPE_AUDIO, msg.data);
        });

        this.handler.socket.on('destroyed-audio', (msg) => this.handleRoomDestroy(TYPE_AUDIO, msg.data.room));
        this.handler.socket.on('destroyed-video', (msg) => this.handleRoomDestroy(TYPE_VIDEO, msg.data.room));
    };

    handleRoomDestroy = (roomType, room) => {
        const { type: callType } = this.props;
        CitadexStore.log(`Audio room ${room} was destroyed`);
        if (callType === roomType) {
            CitadexStore.log('End call');
            this.onEndCall(true)();
        } else {
            // not sure what to do in this case or even if it's possible to get here
            CitadexStore.log(`Use only ${TYPE_AUDIO}`);
        }
    };

    getLocalStreams = async (streamType, withoutAudio = false) => {
        const { audioSourceId, videoSourceId } = this.props;
        if (!streamType || (streamType === TYPE_VIDEO && !videoSourceId)) return;
        const isShareScreen = streamType === TYPE_DESKTOP;
        await getStreams({
            callback: this.gotOwnStream,
            type: streamType,
            onCancel: this.handleShareScreenClick,
            onError: (error) => {
                CitadexStore.error('can not get stream', error);
                if (isShareScreen) {
                    this.handleShareScreenClick(false, false, error);
                } else {
                    errorDialog({ onContinue: this.onEndCall(), type: ERROR_TYPES.NOT_ALLOWED });
                }
            },
            isShareScreen,
            selectedDevices: { audioSourceId, videoSourceId },
            withoutAudio,
        });
    };

    gotOwnStream = (stream, isShareScreen) => {
        const { matrixUserId, type, iceTransportPolicy } = this.props;
        const { audioStreams, cameraQualitySetting, screenshareQualitySetting } = this.state;
        const roomId = this.broadcastId;
        const localStream = stream;
        CitadexStore.log('Got own stream - local stream', stream);
        try {
            const audioStream = new MediaStream(localStream.getAudioTracks());
            if (audioStream && audioStream.active) {
                this.localAudioStream = localStream.getAudioTracks()[0];
                this.currentSettings.audio = localStream.getAudioTracks()[0].getSettings();
                this.handler.media.audio = new WebRTCMedia(
                    roomId, StreamAPI.TYPES.AUDIO, this.publisherId, matrixUserId, null, iceTransportPolicy,
                );
                this.handler.media.audio.set_media(audioStream);
                this.handler.media.audio.configure(this.handler.send);
                this.handler.media.audio.start_offer(this.handler.token).then(() => {
                }).catch(CitadexStore.log);
            }
            const videoTracks = localStream.getVideoTracks();
            if (videoTracks.length > 0) {
                this.currentSettings.video = videoTracks[0].getSettings();
                // if the user stop the share screen from the browser dedicated button
                if (isShareScreen) {
                    videoTracks[0].onended = () => this.handleShareScreenClick(false, true);
                }
                const videoStream = new MediaStream(videoTracks);
                this.handler.media.video = new WebRTCMedia(
                    roomId, StreamAPI.TYPES.VIDEO, this.publisherId, matrixUserId, null, iceTransportPolicy,
                );
                this.handler.media.video.set_media(videoStream);

                // we save the local video stream so that when the video is publish we can display it to the current user
                this.localVideoStream = videoStream;
                this.handler.media.video.configure(this.handler.send);
                this.handler.media.video.start_offer(this.handler.token).catch(CitadexStore.log);
                if (Object.keys(audioStreams).length && (type === TYPE_VIDEO || screenshareQualitySetting !== 'auto')) {
                    const videoConstraints = getCustomVideoConstraints(
                        Object.keys(audioStreams).length,
                        isShareScreen,
                        cameraQualitySetting,
                        screenshareQualitySetting,
                    );
                    this.debouncedApplyConstraints(videoConstraints);
                }
            }
        } catch (e) {
            CitadexStore.error('publishing error', e);
            errorDialog({ onContinue: this.onEndCall(), type: ERROR_TYPES.FLOWS });
        }
    };

    subscribeParticipant = (roomId, senderId, display) => {
        const { iceTransportPolicy, isVideoStreamSubscriptionDisabled } = this.props;
        const { screenSharerStreamId } = this.state;

        if (this.attachedStreams[senderId]) return;
        if (!isVideoStreamSubscriptionDisabled || (isVideoStreamSubscriptionDisabled && senderId === screenSharerStreamId)) {
        this.handler.media.incomings[senderId] =
            new WebRTCMedia(roomId, StreamAPI.TYPES.REMOTEVIDEO, senderId, display, null, iceTransportPolicy);
        this.handler.media.incomings[senderId].configure(this.handler.send);

            this.handler.send('watch-subscriber-video', { room: roomId, id: senderId }, this.handler.token);
            CitadexStore.log('Participant is subscribed', senderId);
        }
    };

    subscribeParticipantIfNeeded = (id, display) => {
        const { roomId } = this.props;
        if (this.justJoinedCall) {
            this.justJoinedCall = false;
            this.subscribeParticipant(roomId, id, display);
        }
    };

    handleMediaEvent = async (type, evt) => {
        const { matrixUserId, type: callType } = this.props;
        const { id, enabled, display, videoSessionId, talking } = evt;
        let displayName = '';

        if (display) displayName = await getProfileInfo(display);
        const { audioStreams, videoStreams } = this.state;
        const newAudioStream = { ...audioStreams };
        const newVideoStream = { ...videoStreams };

        CitadexStore.log(`Media state event of type ${type}${display === matrixUserId ? '(own)' : ''}`, evt);
        if (type === TYPE_AUDIO) {
            const videoStreamId = videoSessionId;
            if (typeof talking === "boolean") {
                newAudioStream[id] = {
                    ...audioStreams[id],
                    talking,
                };
            } else {
                newAudioStream[id] = {
                    ...audioStreams[id],
                    display,
                    displayName,
                    videoStreamId,
                    isAudioEnabled: enabled,
                };
            }

            // we are adding the stream only in the video call because on the audio call
            // if we are going to do this it will add the video element even if we don't have a stream
            if (callType === TYPE_VIDEO) {
                newVideoStream[videoStreamId] = {
                    ...videoStreams[videoStreamId],
                    isVideoEnabled: newVideoStream[videoStreamId]
                        ? newVideoStream[videoStreamId].isVideoEnabled : false,
                };
            }
        }

        if (type === TYPE_VIDEO) {
            const isVideoEnabled = enabled;
            let shouldAddStream = false;
            if (evt.hasOwnProperty('isShareScreenEnabled')) {
                // if we are sharing our screen and we got a new screenSharer we must switch our stream to webcam
                if (this.isShareScreenEnabled() && evt.id !== this.publisherId && evt.isShareScreenEnabled) {
                    this.wasCameraEnabled = this.wasCameraEnabled !== null
                        ? this.wasCameraEnabled
                        : this.state.isCameraEnabled;
                    await this.promisifiedSetState({ cameraQualitySetting: 'screenShareOn'});
                    if (this.wasCameraEnabled) {
                        this.handleShareScreenClick(false, true);
                    } else {
                        this.handler.closeVideoMedia();
                    }
                }
                if (evt.isShareScreenEnabled) {
                    // screen sharing started
                    this.wasCameraEnabled = this.wasCameraEnabled !== null
                        ? this.wasCameraEnabled
                        : this.state.isCameraEnabled;
                    this.setState({ cameraQualitySetting: 'screenShareOn' }, () => {
                        if (evt.id !== this.publisherId && !this.isShareScreenEnabled() && this.wasCameraEnabled) {
                            this.handler.toggleMedia(type, !this.wasCameraEnabled);
                        }
                    });
                } else {
                    const isCameraEnabled = this.wasCameraEnabled !== null
                        ? this.wasCameraEnabled
                        : this.state.isCameraEnabled;
                    this.setState({
                            cameraQualitySetting: this.state.previousCameraQualitySetting,
                            isCameraEnabled,
                        }, () => {
                            if (evt.id !== this.publisherId && this.state.isVideoPublished && isCameraEnabled) {
                                this.onHideCamera(!isCameraEnabled, true);
                            }
                        },
                    );
                    this.wasCameraEnabled = null;
                }

                // we are going to change the screenSharerStreamId only if it's a new participant that
                // is sharing the screen or if the old participant has stopped his screen share
                const screenSharerStreamId = evt.isShareScreenEnabled
                    ? evt.id
                    : this.state.screenSharerStreamId !== evt.id
                        ? this.state.screenSharerStreamId
                        : null;

                if (screenSharerStreamId === this.state.screenSharerStreamId && evt.isShareScreenEnabled) return;

                this.setState({ screenSharerStreamId }, () => {
                    if (!evt.isShareScreenEnabled) {
                        delete this.attachedStreams[id];
                        delete newVideoStream[id];
                    } else {
                        shouldAddStream = true;
                        this.subscribeParticipantIfNeeded(id, display);
                    }
                });
            } else {
                shouldAddStream = true;
                this.subscribeParticipant(this.props.roomId, id, display);
            }
            if (shouldAddStream) {
                if (!newVideoStream[id]) {
                    newVideoStream[id] = { id, isVideoEnabled, display, displayName };
                } else {
                    newVideoStream[id] = { ...newVideoStream[id], isVideoEnabled, display, displayName };
                }
            }
        }
        this.setState({ audioStreams: newAudioStream, videoStreams: newVideoStream });
    };

    handleMultipleAudioStreams = async (streams) => {
        const { audioStreams } = this.state;
        // we don't care about the audio stream, since we are using the WebRtc mix audio one
        if (isEmpty(streams)) return;

        const newStreamsPromises = map(streams, async ({ id, display, videoSessionId }) => {
            const displayName = await getProfileInfo(display);
            const isAudioEnabled = audioStreams[id] ? audioStreams[id].isAudioEnabled : true;
            return { id, display, displayName, isAudioEnabled, videoStreamId: videoSessionId };
        });

        Promise.all(newStreamsPromises).then(streamList => {
            const newStreams = reduce(
                streamList,
                (acc, stream) => ({ ...acc, [stream.id]: { ...stream, videoStreamId: stream.videoSessionId } }),
                {},
            );
            this.setState({ audioStreams: { ...this.state.audioStreams, ...newStreams } });
        });
    };

    handleStream = async (type, streamId, event, display) => {
        const { type: callType, isSoundNotificationOn } = this.props;
        if (!event) {
            const { [`${type}Streams`]: streams, screenSharerStreamId } = this.state;
            const newStreams = { ...streams };
            // if we get an unpublished event for user audio stream, that means that de user is no longer present
            // in this case if the user was displaying his screen, we will reset the value to null
            let newScreenSharerStreamId = screenSharerStreamId;
            if (type === TYPE_AUDIO) {
                const { videoStreamId } = newStreams[streamId];
                if (videoStreamId === screenSharerStreamId) {
                    newScreenSharerStreamId = null;
                    delete this.attachedStreams[videoStreamId];
                    delete this.handler.media.incomings[videoStreamId];
                    this.setState({ videoStreams: { ...omit(this.state.videoStreams, [videoStreamId]) } });
                }
            }

            if (screenSharerStreamId === streamId && callType === TYPE_VIDEO) {
                newStreams[streamId] = { isVideoEnabled: true };
            } else {
                delete newStreams[streamId];
            }
            delete this.attachedStreams[streamId];
            CitadexStore.log(`The ${type} stream with id ${streamId} was unpublished`);
            if (type === TYPE_AUDIO) {
                const displayName = await getProfileInfo(display);
                dis.dispatch({
                    action: 'add_toast',
                    value: {
                        audioElementId: isSoundNotificationOn ? 'citadel-leave' : '',
                        autoClose: 4000,
                        listType: TOAST_LIST_TYPE.CITADEX,
                        message: `${displayName} ${_t('left')}`,
                    },
                });
            }
            this.setState({ [`${type}Streams`]: newStreams, screenSharerStreamId: newScreenSharerStreamId });
            return;
        }

        CitadexStore.log(`New ${type} stream was published`, streamId, event);

        const displayName = await getProfileInfo(display);

        const { [`${type}Streams`]: streams } = this.state;
        const newStreams = { ...streams };

        newStreams[streamId] = {
            ...streams[streamId],
            id: streamId,
            stream: event && event.streams ? event.streams[0] : null,
            display,
            displayName,
        };

        if (type === TYPE_AUDIO) {
            newStreams[streamId] = {
                ...newStreams[streamId],
                isAudioEnabled: streams[streamId] ? streams[streamId].isAudioEnabled : true,
                videoStreamId: event.videoSessionId,
            };
            if (!event.isMe) {
                dis.dispatch({
                    action: 'add_toast',
                    value: {
                        audioElementId: isSoundNotificationOn ? 'citadel-join' : '',
                        autoClose: 4000,
                        listType: TOAST_LIST_TYPE.CITADEX,
                        message: `${displayName} ${_t('joined')}`,
                    },
                });
            }
        }

        if (type === TYPE_VIDEO) {
            let isVideoEnabled;
            if (event.hasOwnProperty('isVideoEnabled')) {
                isVideoEnabled = event.isVideoEnabled;
            } else {
                isVideoEnabled = streams[streamId] ? streams[streamId].isVideoEnabled : true;
            }
            newStreams[streamId].isVideoEnabled = isVideoEnabled;
        }

        this.setState({ [`${type}Streams`]: newStreams });
    };

    onMute = (enabled) => {
        const { matrixUserId } = this.props;
        const { muteToastId } = this.state;
        const isAudioEnabled = typeof enabled === 'boolean' ? enabled : this.state.isAudioEnabled;
        if (!isAudioEnabled && !!muteToastId) {
            dis.dispatch({
                action: 'close_toast',
                value: { id: muteToastId, listType: TOAST_LIST_TYPE.CITADEX }},
            );
        }
        if (this.handler.toggleMedia) {
            this.handler.toggleMedia(TYPE_AUDIO, !isAudioEnabled);
            const muteEvent = {
                id: this.handler.media.audio.pub_id,
                enabled: !isAudioEnabled,
                display: matrixUserId,
                videoSessionId: this.publisherId,
            };

            this.handleMediaEvent(TYPE_AUDIO, muteEvent);
            this.setState({ isAudioEnabled: !isAudioEnabled });

            this.toggleConferenceMenu({
                mic: !isAudioEnabled,
                cam: this.state.isCameraEnabled,
                screenshare: this.isShareScreenEnabled(),
            });
        }
    };

    onHideCamera = async (enabled, shouldMuteOnly = false) => {
        const { matrixUserId, videoSourceId, type } = this.props;
        const isCameraEnabled = typeof enabled === 'boolean' ? enabled : this.state.isCameraEnabled;
        if (type === 'audio') return;
        if (shouldMuteOnly) {
            this.handler.toggleMedia(TYPE_VIDEO, !isCameraEnabled);
            await this.handleMediaEvent(TYPE_VIDEO, {
                id: this.handler.media.video.pub_id,
                enabled: !isCameraEnabled,
                display: matrixUserId,
            });
        } else {
            if (isCameraEnabled) {
                this.handler.closeVideoMedia();
            } else {
                this.setState({ waitingForVideoToBePublished: true });
                !!videoSourceId && await this.getLocalStreams(TYPE_VIDEO, true);
            }
        }
        this.setState({ isCameraEnabled: !isCameraEnabled });
        this.toggleConferenceMenu({
            mic: this.state.isAudioEnabled,
            cam: !isCameraEnabled,
            screenshare: this.isShareScreenEnabled(),
        });
    };

    handleShareScreenClick = (isCancel, isStop, error) => {
        const { type } = this.props;
        const { isCameraEnabled } = this.state;
        const isSharingScreen = this.isShareScreenEnabled();
        const screenSharerStreamId = !isSharingScreen ? this.publisherId : null;
        this.handler.shareScreen(!isSharingScreen);
        isSharingScreen && this.handleMediaEvent(TYPE_VIDEO, { id: this.publisherId, isShareScreenEnabled: false });
        this.justJoinedCall = false;
        this.handler.closeVideoMedia();

        if (!isSharingScreen) {
            this.getLocalStreams(TYPE_DESKTOP, true);
        } else {
            if (type === TYPE_VIDEO && isCameraEnabled) {
                this.setState({ waitingForVideoToBePublished: true });
                this.getLocalStreams(TYPE_VIDEO, true);
            }
        }

        this.setState({ screenSharerStreamId, isVideoPublished: false });

        this.toggleConferenceMenu({
            mic: this.state.isAudioEnabled,
            cam: this.state.isCameraEnabled,
            screenshare: !!screenSharerStreamId,
        });
        let trackUserQueryParams = {
            action: 'startScreenSharing',
            formId: this.props.type === TYPE_AUDIO ? 'audioConference' : 'videoConference',
            version: 1,
        };
        if (isCancel) trackUserQueryParams = { ...trackUserQueryParams, action: 'cancelScreenSharing' };
        if (isStop) trackUserQueryParams = { ...trackUserQueryParams, action: 'stopScreenSharing' };
        if (error) {
            trackUserQueryParams = {
                ...trackUserQueryParams,
                action: 'error',
                step: 'confirmScreenSharing',
                type: 'start',
                conferenceID: this.broadcastId,
                room_id: CitadexStore.getMatrixRoomId(),
                reason: `can not get stream: ${error}`,
            };
        }
        MatrixClientPeg.get().trackUserAction(trackUserQueryParams);
    };

    onEndCall = (isIntentionalEnd) => async (isRoomClosed, isDisconnected) => {
        // onEndCall can be triggered because of multiple causes,
        // so if the procedure was already started we will just exit
        const { onEndCallClick } = this.props;
        this.shouldDisplayWarning = false;
        if (this.broadcastId && this.publisherId && !isRoomClosed && !isDisconnected) {
            try {
                await CitadexStore.deleteConferenceUser(this.broadcastId, this.publisherId);
                CitadexStore.log(`participant ${this.broadcastId} was deleted`);
            } catch (err) {
                CitadexStore.error(`participant deletion failed (${err})`);
            }
        }

        this.handler.closeBroadcast && this.handler.closeBroadcast();

        dis.dispatch({
            action: 'toggle-preview-conference',
            value: true,
        });
        dis.dispatch({
            action: 'reset-retry-process',
            intentional: true,
        });
        dis.dispatch({
            action: 'audio-toggle',
            value: true,
        });

        if (isDisconnected || this.state.isOffline) {
            if (isIntentionalEnd) {
                onEndCallClick();
                CitadexStore.destroySession();
            }
        } else {
            onEndCallClick();
            CitadexStore.destroySession();
        }
    };

    onStopCall = () => {
        const myId = localStorage.getItem('mx_user_id');
        try {
            CitadexStore.updateParticipantList([myId], myId, this.broadcastId, 'close-conference');
        } finally {
            this.onEndCall(true)();
        }
    }

    getParticipantsStreams = (type) => {
        const { type: callType } = this.props;
        const { audioStreams, videoStreams } = this.state;
        if (type === TYPE_AUDIO) return audioStreams;

        if (callType === TYPE_AUDIO) {
            const newVideStreams = { ...videoStreams };
            const updatedVideoStreams = Object.values(audioStreams).reduce((videoStreamsAcc, audioStream) => {
                const videoStreamId = audioStream.videoStreamId;
                const videoStream = videoStreams[videoStreamId];
                if (videoStream) {
                  videoStreamsAcc[videoStreamId] = {
                    ...videoStream,
                    talking: audioStream.talking,
                  };
                }
                return videoStreamsAcc;
              }, newVideStreams);
            return updatedVideoStreams;
        }

        const audioBasedStreams =
            reduce(
                map(
                    audioStreams,
                    (audioStream) => {
                        const videoStream = videoStreams[audioStream.videoStreamId];
                        return ({
                            id: audioStream.videoStreamId,
                            ...videoStream,
                            ...omit(audioStream, ['id', 'videoStreamId', 'stream']),
                            isVideoEnabled: videoStream ? videoStream.isVideoEnabled : true,
                        });
                    },
                ),
                (acc, stream) => {
                    if (!stream.id) return acc;
                    return ({ ...acc, [stream.id]: stream });
                },
                {},
            );
        return {
            ...audioBasedStreams,
            ...reduce(videoStreams, (acc, stream) => {
                if (!stream.id || audioBasedStreams[stream.id]) return acc;
                return { ...acc, [stream.id]: stream };
            },
                {},
            ),
        };
    };

    muteParticipants = (id) => () => {
        const list = id ? [id] : [];
        this.handler.send(
            'mute-all',
            {
                room: this.broadcastId,
                streams: list,
            },
        );
    }

    onKickUser = (userId) => () => {
        const { callInProgress } = this.props;
        const { attendees_id: participants, room_janus_id: broadcastId, inviter_id: inviterId } = callInProgress;
        const newParticipants = participants.filter(participant => participant !== userId);

        CitadexStore.log(`Kick participant with id: ${userId}`);
        CitadexStore.updateParticipantList(newParticipants, inviterId, broadcastId, 'kicked');
    }

    kickUserHandler = (userId, displayName) => () => {
        if (!userId || !displayName) return;

        Modal.createDialog(
            KickParticipantDialog,
            {
                onFinished: () => { },
                onKickUser: this.onKickUser(userId),
                displayName: displayName,
            },
            'kick_participant_dialog',
        );
    }

    handleBitrate = (streamId, bitrate, isInbound) => {
        let totalBitrate = 0;
        if (isInbound) {
            this.bitrate = { ...this.bitrate, inbound: { ...this.bitrate.inbound, [streamId]: bitrate } };
            forEach(this.bitrate.inbound, (bitrate) => totalBitrate += bitrate);
            this.setState({ totalBitrateInbound: `${totalBitrate}  kbits/sec` });
            return;
        }

        this.bitrate = { ...this.bitrate, outbound: { ...this.bitrate.outbound, [streamId]: bitrate } };
        forEach(this.bitrate.outbound, (bitrate) => totalBitrate += bitrate);
        this.setState({ totalBitrateOutbound: `${totalBitrate}  kbits/sec` });
    }

    renderParticipantsCards = (type) => {
        const { type: callType, isMinimized } = this.props;
        const { screenSharerStreamId, debugMode } = this.state;
        const streams = this.getParticipantsStreams(type);
        const amISharingMyScreen = this.isShareScreenEnabled();
        if (isEmpty(streams)) return null;
        if (type === TYPE_AUDIO) {
            let participantsCard = null;

            if (callType === TYPE_AUDIO) {
                participantsCard = map(streams, (participant) => {
                    const { id, display, displayName, isAudioEnabled = true, videoStreamId, talking } = participant;
                    return (
                        <ParticipantRow
                            key={id || videoStreamId}
                            muted={!isAudioEnabled}
                            onKick={this.kickUserHandler(display, displayName)}
                            onMute={this.muteParticipants(id)}
                            userId={display}
                            userName={displayName}
                            talking={talking}
                        />
                    );
                });
            }
            const isAudio = callType === TYPE_AUDIO;
            return (
                <div className={cx(
                    'audio-participants-container',
                    { 'mx_JanusRoom': isAudio },
                    { 'share_screen_enabled': isAudio && screenSharerStreamId && !amISharingMyScreen })}
                >
                    {participantsCard}
                    {map(streams, (stream) =>
                        stream.stream ? (
                            <video
                                className='audio-element'
                                id={`audio-element-${stream.id}`}
                                key={`video-element-${stream.id}`}
                                autoPlay
                                playsInline
                            />)
                            : null)
                    }
                </div>
            );
        }
        return (
            <div className={cx('participant-cards', {
                'share_screen_enabled': !!screenSharerStreamId &&
                    !amISharingMyScreen ||
                    callType === TYPE_AUDIO,
                'audio': amISharingMyScreen && callType === TYPE_AUDIO,
                'dual_mode': Object.keys(streams).length === 2,
            })}>
                {
                    map(streams, (stream) => {
                        const {
                            display, displayName, isAudioEnabled = true, id, isVideoEnabled = true, talking,
                        } = stream;
                        const { isVideoStreamSubscriptionDisabled } = this.props;
                        const audioStream = Object.values(this.state.audioStreams)
                            .find((audioStream) => audioStream.videoStreamId === id);
                        const { id: audioId } = audioStream || {};

                        return (
                            <ParticipantCard
                                amISharingTheScreen={amISharingMyScreen}
                                debugMode={debugMode && !isMinimized}
                                display={displayName}
                                isAudioEnabled={isAudioEnabled}
                                isMe={id === this.publisherId}
                                isContextMenuDisabled={isMinimized}
                                isScreenSharingLoading={isVideoEnabled && !stream.stream}
                                isVideoEnabled={
                                    // Video is enabled for all subscribers if video stream subscription is enabled
                                    (isVideoEnabled && !!stream.stream && !isVideoStreamSubscriptionDisabled)
                                    // Video is enabled for current user if its camera is enabled and video stream subscription is disabled
                                    || (isVideoStreamSubscriptionDisabled && id === this.publisherId && isVideoEnabled)
                                    // Video is enabled for screen sharer if video stream subscription is disabled
                                    || (screenSharerStreamId === id)
                                }
                                key={id}
                                onBitrateChange={this.handleBitrate}
                                onKick={this.kickUserHandler(display, displayName)}
                                onMute={audioId ? this.muteParticipants(audioId) : noop}
                                RTCPeerConnection={
                                    id === this.publisherId ? null : this.handler.media?.incomings?.[id]?.pc
                                }
                                screenSharerStreamId={screenSharerStreamId}
                                streamId={id}
                                talking={talking}
                                userId={display}
                            />
                        );
                    })
                }
            </div>
        );
    };

    isShareScreenButtonDisabled = () => {
        const { isVideoPublished, isAudioPublished, videoStreams, screenSharerStreamId, isCameraEnabled, isOffline } = this.state;
        if (isOffline) {
            return true;
        }
        // this has to be first, as when we have only audio calls, we need to check for it first
        // in order to not disable the share screen button when someone else shares the screen
        if (this.props.type === 'audio') {
            return !isAudioPublished;
        }

        if (screenSharerStreamId && screenSharerStreamId !== this.publisherId && isCameraEnabled) {
          return !isVideoPublished || !videoStreams[screenSharerStreamId] || !videoStreams[screenSharerStreamId].stream;
        }

        return isCameraEnabled ? !isVideoPublished : isCameraEnabled;
    };

    toggleBottomBar = (visible) => () => {
        let isVisible = true;
        if (!this.props.isSharingScreen && this.state.screenSharerStreamId) {
            isVisible = visible;
        }

        if (!isVisible) {
            this.debouncedSetState({ showTopAndBottomBar: isVisible });
            return;
        }
        this.setState({ showTopAndBottomBar: isVisible }, () => {
            if (this.state.showTopAndBottomBar && !this.isShareScreenEnabled() && !!this.state.screenSharerStreamId) {
                this.debouncedSetState({ showTopAndBottomBar: false });
            }
        });
    }

    renderCurrentSettings = (type) => {
        if (!LOCAL_STREAM_STATS || isEmpty(this.currentSettings[type]) || !LOCAL_STREAM_STATS[type].length) return null;
        const settings = [];
        Object.keys(this.currentSettings[type]).forEach(key => {
            if (LOCAL_STREAM_STATS[type].includes(key)) {
                settings.push(<div key={key}>{`${key}: ${Math.floor(this.currentSettings[type][key])}`}</div>);
            }
        });
        return (
            <React.Fragment>
                <div key={'title'}>{`Current ${type} track Settings: `}</div>
                {settings}
                {'--'}
            </React.Fragment>
        );
    }

    setManualVideoQuality = (level) => () => {
        if (level !== 'screenShareOn') {
            this.setState({ cameraQualitySetting: level, previousCameraQualitySetting: level });
        } else {
            this.setState({ cameraQualitySetting: level });
        }
    }

    setScreensharingVideoQuality = (level) => () => this.setState({ screenshareQualitySetting: level });

    changeConferenceDisplayState = () => dis.dispatch({ action: 'minimize_conference', isMinimized: false });

    render() {
        const { type, onSettingsClick, roomName, isMinimized } = this.props;
        const { isAudioEnabled, isCameraEnabled, audioStreams, showTopAndBottomBar,
            screenSharerStreamId, totalBitrateInbound, totalBitrateOutbound,
            cameraQualitySetting, screenshareQualitySetting, debugMode,
            waitingForVideoToBePublished, isOffline, isNetworkIssuePersisting,
        } = this.state;
        const isMuteDisabled = Object.keys(audioStreams).length === 0;
        const amISharingMyScreen = this.isShareScreenEnabled();
        return (
            <div className='citadex_visio_page_container'>
                <div id="conference_HintOverlayContainer" />
                {debugMode && !isMinimized &&
                    <div className='current-settings' key={this.currentSettings ? this.currentSettings.height : 1}>
                        {this.renderCurrentSettings(TYPE_AUDIO)}
                        {this.renderCurrentSettings(TYPE_VIDEO)}
                        <Stats
                            RTCPeerConnection={this.handler.media?.audio?.pc}
                            streamId={'audio'}
                            onBitrateChange={this.handleBitrate}
                        />
                        <Stats
                            RTCPeerConnection={this.handler.media?.video?.pc}
                            streamId={this.publisherId}
                            onBitrateChange={this.handleBitrate}
                        />
                        <div>{'Total'}<span className='arrow'>{'⬆️'}</span>{totalBitrateOutbound}</div>
                        <div>{'Total'}<span className='arrow'>{'⬇️'}</span>{totalBitrateInbound}</div>
                    </div>
                }
                <div
                    className={FULL_SCREEN_ELEMENT_CLASSNAME}
                    onMouseEnter={this.toggleBottomBar(true)}
                    onMouseLeave={this.toggleBottomBar(false)}
                    onMouseMove={this.toggleBottomBar(true)}
                >
                    <ConferenceTopBar
                        amISharingMyScreen={amISharingMyScreen}
                        isVisible={showTopAndBottomBar}
                        onKick={this.kickUserHandler}
                        onMute={this.muteParticipants}
                        participants={this.state.audioStreams}
                        roomName={roomName}
                        screenSharerStreamId={screenSharerStreamId}
                        type={type}
                    />
                    {
                        isMinimized
                            ? <div className={'minimized_overlay'} onClick={this.changeConferenceDisplayState}>
                                <div className={'minimized_overlay_text'}>
                                    {_t('Expand the conference')}
                                </div>
                            </div>
                            : null
                    }
                    {(isOffline && !isNetworkIssuePersisting) ? <div className='reconnect_banner'>
                        {_t('It seems that you are not connected to the internet. We are trying to reconnect you...')}
                    </div> : null}
                    {isNetworkIssuePersisting ?
                        <div className='reconnecting_overlay'>
                            <img className='reconnecting_gif' src={require('../../../../res/img/citadel-loading.gif')} />
                            <span className='reconnecting_text'>{_t('We are trying to reconnect you')}</span>
                        </div> : null}
                    {this.renderParticipantsCards(TYPE_AUDIO)}
                    {this.renderParticipantsCards(TYPE_VIDEO)}
                    <ConferenceBottomBar
                        amISharingMyScreen={amISharingMyScreen}
                        audioEnabled={isAudioEnabled}
                        cameraQualitySetting={cameraQualitySetting}
                        containerClassName={FULL_SCREEN_ELEMENT_CLASSNAME}
                        isCameraButtonDisabled={isOffline || (!!screenSharerStreamId || waitingForVideoToBePublished)}
                        isMuteDisabled={isMuteDisabled || isOffline}
                        isShareScreenButtonDisabled={this.isShareScreenButtonDisabled()}
                        isVideoCall={type === TYPE_VIDEO}
                        isVisible={showTopAndBottomBar}
                        onAudioClick={this.onMute}
                        onEndCallClick={this.onEndCall(true)}
                        onScreenShare={this.handleShareScreenClick}
                        onSettingsClick={onSettingsClick}
                        onStopCall={this.onStopCall}
                        onVideoClick={this.onHideCamera}
                        screenSharerStreamId={screenSharerStreamId}
                        screenshareQualitySetting={screenshareQualitySetting}
                        setManualVideoQuality={this.setManualVideoQuality}
                        setScreensharingVideoQuality={this.setScreensharingVideoQuality}
                        videoEnabled={isCameraEnabled && !screenSharerStreamId}
                        callInProgress={this.props.callInProgress}
                    />
                </div>
            </div>
        );
    }
}

export default CitadexCall;
