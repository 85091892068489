import classNames from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { _t } from '../../../languageHandler';
import { getDomain, requestToken } from '../../../utils/CitadelRequests';
import { generateClientSecret, validateEmail } from '../../../utils/CitadelUtils';
import CitadelInput from '../../views/elements/CitadelInput';
import AuthBody from '../../views/auth/AuthBody';
import CitadexStore from '../../../stores/CitadexStore';

class EmailValidationStep extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: props.initialEmail || '',
            portalUrl: props.defaultPortalUrl,
            error: { type: '', text: '' },
        };
        CitadexStore.setTestMode(false);
    }

    onInputChange = (ev) => {
        const { name, value } = ev.target;

        const { devPortalUrl, defaultPortalUrl } = this.props;
        let { portalUrl } = this.state;

        portalUrl = (value === 'thereisnospoon') ? devPortalUrl :
            (value === 'whiterabbit') ? defaultPortalUrl : portalUrl;

        value === 'thereisnospoon' && CitadexStore.setTestMode(true);
        value === 'whiterabbit' && CitadexStore.setTestMode(false);

        this.setState({
            [name]: (value === 'thereisnospoon' || value === 'whiterabbit') ? '' : value.trim(),
            portalUrl,
            error: { type: '', text: '' },
        });
    };

    handleError = (err) => {
        if (err.message) {
            switch (err.message) {
                case 'Invalid email address':
                    this.setState({
                        error: {
                            type: 'email',
                            text: _t('This doesn\'t appear to be a valid email address'),
                        },
                    });
                    break;
                case 'Email not found':
                    this.setState({
                        error: {
                            type: 'email',
                            text: _t('This email address was not found'),
                        },
                    });
                    break;
                default:
                    console.error(err);
                    this.setState({
                        error: {
                            type: 'system',
                            text: _t('An error has occurred.'),
                        },
                    });
                    break;
            }
        } else {
            console.error(err);
            this.setState({
                error: {
                    type: 'system',
                    text: _t('An error has occurred.'),
                },
            });
        }
    };

    onSubmit = (ev) => {
        ev.preventDefault();
        const { portalUrl, email, error } = this.state;

        if (!error.text) {
            getDomain(portalUrl, email)
                .then((res) => {
                    if (res.domain) {
                        const domain = res.domain;
                        const clientSecret = generateClientSecret();

                        requestToken(`https://${domain}`, clientSecret, email, 1, 'reset')
                            .then((res) => {
                                if (res.success) {
                                    this.props.onNext(email, `https://${domain}`, clientSecret, res.sid);
                                } else {
                                    this.handleError(res);
                                }
                            })
                            .catch((err) => {
                                this.handleError(err);
                            });
                    } else {
                        this.setState({
                            error: {
                                type: 'email',
                                text: _t('Couldn\'t find any related homeserver'),
                            },
                        });
                    }
                })
                .catch((err) => {
                    this.setState({
                        error: {
                            type: 'email',
                            text: _t('Couldn\'t find any related homeserver'),
                        },
                    });
                });
        }
    };

    renderCommonSection = () => {
        const { devPortalUrl } = this.props;
        const { portalUrl } = this.state;

        const testModeClassNames = classNames({
            'test-mode': true,
            'hide': (portalUrl !== devPortalUrl),
        });

        return (
            <div className='common'>
                <div className={testModeClassNames}>
                    <p>{_t('Test mode enabled')}</p>
                </div>
            </div>
        );
    };

    getCaption = () => {
        const { error } = this.state;

        if (error.type === 'email') {
            return { type: 'error', text: error.text };
        }
    };

    render() {
        const { email } = this.state;
        const title = _t('Change Password');
        const subtitle = _t('We are about to send you an email that will allow you to reset your password');

        const validInputs = (validateEmail(email));

        return (
            <div className="mx_TokenValidationStep">
                <span onClick={this.props.onBack} className="back-button" />
                <AuthBody>
                    <div className="password-validation wrapper">
                        <div className="header">
                            <div className="title">
                                {title}
                            </div>
                            <div className="subtitle">
                                {subtitle}
                            </div>
                        </div>
                        {this.renderCommonSection()}
                        <form onSubmit={this.onSubmit}>
                            <CitadelInput
                                label={_t('Email address')}
                                name='email'
                                caption={this.getCaption()}
                                onChange={this.onInputChange}
                                value={email}
                                autoFocus={true}
                            />

                            <div className="button_wrapper">
                                <div className="button">
                                    <input className="mx_Login_submit"
                                           type="submit"
                                           value={_t('Confirm')}
                                           disabled={!validInputs}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </AuthBody>
            </div>
        );
    }
}

EmailValidationStep.propTypes = {
    onNext: PropTypes.func,
    onBack: PropTypes.func,
    initialEmail: PropTypes.string,
    devPortalUrl: PropTypes.string.isRequired,
    defaultPortalUrl: PropTypes.string.isRequired,
};

export default EmailValidationStep;
