import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PhoneNumberUtil } from 'google-libphonenumber';

import { _t } from '../../../languageHandler';

class CitadelPhoneInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            focused: false,
        };
    }

    onInput = (event) => {
        const { prefix, onChange } = this.props;
        const { value } = event.target;
        const dirtyNumber = prefix + value;
        try {
            const phoneUtil = PhoneNumberUtil.getInstance();
            const valid = phoneUtil.isValidNumber(phoneUtil.parse(dirtyNumber));
            valid ? onChange(this.getValidNumber(dirtyNumber), valid) :
                onChange(value, valid);
        } catch (e) {
            onChange(value, false);
        }
        value.length > 6 && this.validatePhoneNumber(dirtyNumber);
      }

    onFocus = () => this.setState({focused: true});

    onBlur = () => this.setState({focused: false});

    validatePhoneNumber = (phoneNumber) => {
        let valid;
        try {
            const phoneUtil = PhoneNumberUtil.getInstance();
            valid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber));
        } catch (e) {
            valid = false;
        }
        this.setState({error: !valid});
    }

    getValidNumber = (phoneNumber) => {
        const phoneUtil = PhoneNumberUtil.getInstance();
        const parsedNumber = phoneUtil.parse(phoneNumber);
        return phoneUtil.format(parsedNumber, this.props.code);
    }

    render() {
        const {
            label,
            prefix,
            name,
            placeholder,
            value,
            disabled,
            autoComplete,
        } = this.props;
        const {
            focused,
            error,
        } = this.state;

        const classes = classNames({
            mx_CitadelInput: true,
            mx_CitadelInput_focused: focused,
            mx_CitadelInput_error: error,
            mx_CitadelInput_disabled: disabled,
        });

        return (
            <div className={classes}>
                { label && (<div className="label">{label}</div>)}
                <div className="input-wrapper">
                    <div className="input-box">
                        <div className="input-prefix">
                            {prefix}
                        </div>
                        <input
                            className="input"
                            type="phone"
                            name={name}
                            onChange={disabled ? null : this.onInput}
                            onBlur={disabled ? null : this.onBlur}
                            onFocus={disabled ? null : this.onFocus}
                            placeholder={placeholder}
                            value={disabled ? '' : value}
                            autoComplete={autoComplete}
                            disabled={disabled}
                        />
                        <div className="error-wrapper">
                            {error && <img src="" alt="" />}
                        </div>
                    </div>
                </div>
                <div className="info-wrapper">
                    {error && <div className="error">{_t('The phone number does not match your country code.')}</div>}
                </div>
            </div>
        );
    }
}

CitadelPhoneInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    autoComplete: PropTypes.string,
    code: PropTypes.string,
    prefix: PropTypes.string,
};

CitadelPhoneInput.defaultProps = {
    placeholder: '',
    value: '',
    disabled: false,
    coveredWithOpacity: false,
    prefix: '',
    code: '',
    autoComplete: 'false',
};

export default CitadelPhoneInput;
