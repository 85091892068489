import * as Lifecycle from '../Lifecycle';
import url from 'url';
import SdkConfig from '../SdkConfig';

export const getDomain = async (portalUrl, email) => {
    if (!portalUrl) {
        portalUrl =
            localStorage.getItem('mx_portal_url') || SdkConfig.get().default_portal_url;
    }

    const options = {
        method: 'GET',
    };

    const finalUrl = url.resolve(`${portalUrl}`, `api/get_domain.php?email=${email}`);
    const res = await fetch(finalUrl, options);

    if (!res.ok) throw new Error(res.status);
    return await res.json();
};

export const getCompanyName = async (tenant, language = 'en') => {
    const portalUrl =
        localStorage.getItem('mx_portal_url') || SdkConfig.get().default_portal_url;

    const options = {
        method: 'GET',
    };

    const finalUrl = url.resolve(`${portalUrl}`, `api/get_infra_name.php?tenant=${tenant}&lang=${language}`);
    const res = await fetch(finalUrl, options);

    if (!res.ok) throw new Error(res.status);
    return res.json();
};

export const getCurrentTermsVersion = async () => {
    const homeserverUrl = localStorage.getItem('mx_hs_url');
    const accessToken = localStorage.getItem('mx_access_token');

    const options = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
        },
    };

    const finalUrl = url.resolve(`${homeserverUrl}`, '/_matrix/client/api/v1/citadel/consent/version');
    const res = await fetch(finalUrl, options);

    if (!res.ok) throw new Error(res.status);
    return await res.json();
};

export const getAcceptedTermsVersion = async () => {
    const homeserverUrl = localStorage.getItem('mx_hs_url');
    const accessToken = localStorage.getItem('mx_access_token');

    const options = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
        },
    };

    const finalUrl = url.resolve(`${homeserverUrl}`, '/_matrix/client/api/v1/citadel/consent');
    const res = await fetch(finalUrl, options);

    if (!res.ok) throw new Error(res.status);
    return await res.json();
};

export const updateTerms = async (version) => {
    const homeserverUrl = localStorage.getItem('mx_hs_url');
    const accessToken = localStorage.getItem('mx_access_token');

    const options = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ version }),
    };

    const finalUrl = url.resolve(`${homeserverUrl}`, '/_matrix/client/api/v1/citadel/consent');
    const res = await fetch(finalUrl, options);

    if (!res.ok) await Lifecycle.handleTokenError(res, updateTerms, version);
    return await res.json();
};

export const requestToken = async (homeserverUrl, clientSecret, email, sendAttempt, requestType, mfaMedium = 'email') => {
    const nextLink = (requestType === 'register') ? '' : 'pattern=resetpwd';
    const api = (requestType === 'register') ? requestType : 'account/password';
    let normalizedMfaMedium = mfaMedium;
    if (mfaMedium === 'totp') normalizedMfaMedium = 'otp';
    const body = {
        client_secret: clientSecret,
        email: email,
        send_attempt: sendAttempt,
        next_link: nextLink,
        id_server: homeserverUrl.slice(homeserverUrl.indexOf('//') + 2, homeserverUrl.length),
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    };

    const finalUrl = url.resolve(`${homeserverUrl}`, `_matrix/client/r0/${api}/${normalizedMfaMedium}/requestToken`);
    const res = await fetch(finalUrl, options);

    const data = await res.json();
    if (data.errcode) {
        throw new Error(data.error);
    } else {
        return data;
    }
};

export const submitToken = async (homeserverUrl, clientSecret, sid, token) => {
    const body = {
        sid,
        client_secret: clientSecret,
        token,
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    };

    const finalUrl = url.resolve(`${homeserverUrl}`, '_matrix/identity/api/v1/validate/email/submitToken');
    const res = await fetch(finalUrl, options);

    if (!res.ok) throw new Error(res.status);
    return await res.json();
};

export const register = async (homeserverUrl, clientSecret, sid, userId, password, deviceName, displayname) => {
    const body = {
        auth: {
            type: 'm.login.email.identity',
            threepid_creds: {
                sid,
                client_secret: clientSecret,
                id_server: homeserverUrl.slice(homeserverUrl.indexOf('//') + 2, homeserverUrl.length),
            },
        },
        username: userId,
        password,
        bind_email: true,
        guest_access_token: null,
        initial_device_display_name: deviceName,
        displayname,
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    };

    const finalUrl = url.resolve(`${homeserverUrl}`, '_matrix/client/r0/register');
    const res = await fetch(finalUrl, options);

    if (!res.ok) throw new Error(res.status);
    return await res.json();
};

export const reset = async (homeserverUrl, clientSecret, sid, password) => {
    const body = {
        auth: {
            type: 'm.login.email.identity',
            threepid_creds: {
                sid,
                client_secret: clientSecret,
                id_server: homeserverUrl.slice(homeserverUrl.indexOf('//') + 2, homeserverUrl.length),
            },
        },
        new_password: password,
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    };

    const finalUrl = url.resolve(`${homeserverUrl}`, '_matrix/client/r0/account/password');
    const res = await fetch(finalUrl, options);

    if (!res.ok) throw new Error(res.status);
    return await res.json();
};

export const setDisplayName = async (homeserverUrl, accessToken, userId, displayName) => {
    const body = {
        displayname: displayName,
    };

    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(body),
    };

    const finalUrl = url.resolve(`${homeserverUrl}`, `_matrix/client/r0/profile/${userId}/displayname`);
    const res = await fetch(finalUrl, options);

    if (!res.ok) throw new Error(res.status);
    return await res.json();
};

export const getDisplayName = async (userId) => {
    if (!userId) throw new Error('Failed to fetch display name: missing user id');

    const homeserverUrl = localStorage.getItem('mx_hs_url');
    const token = localStorage.getItem('mx_access_token');
    const finalUrl = url.resolve(`${homeserverUrl}`, `_matrix/client/r0/profile/${userId}/displayname`);
    const res = await fetch(finalUrl,
        {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

    if (!res.ok) throw new Error(res.status);
    return res.json();
};

export const getInfraMessages = async (tenant) => {
    const portalUrl =
        localStorage.getItem('mx_portal_url') || SdkConfig.get().default_portal_url;

    const finalUrl = url.resolve(`${portalUrl}`, `api/infra_messages?tenant=${tenant}`);
    const options = {
        method: 'GET',
    };

    const res = await fetch(finalUrl, options);
    if (!res.ok) throw new Error(res.status);
    return res.json();
};
