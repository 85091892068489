import React, { Fragment, useMemo, useState } from "react";
import cx from 'classnames';
import { noop } from 'lodash';

import CitadelButton from '../views/elements/CitadelButton';
import { _t } from '../../languageHandler';
import UserStore from "../../stores/UserStore";
import { getDisplayName } from '../../utils/Citadex';
import Modal from "../../Modal";
import E2eKeyShareDialog from "../views/dialogs/keyShare/E2eKeyShareDialog";
import SuccessErrorDialog from '../views/dialogs/SuccessErrorDialog';
import ExternalReceiverCodeDialog from '../views/dialogs/device_verification/ExternalReceiverCodeDialog';

import reduceImg from '../../../res/img/reduce.svg';
import e2eKeySent from '../../../res/img/e2eKeySent.svg';
import MatrixClientPeg from "../../MatrixClientPeg";
import closeImg from '../../../res/img/close-e2e-share.svg';

const EncryptionKeysRequestBar = ({
    isMinimized,
    onMinimize,
    roomId,
    encryptionKeysRequest,
    hasSentE2eRetrievalEvent,
    hasSentE2eKeysToMembers,
    laterKeysToDecrypt,
}) => {
    const [isCancelLoading, setIsCancelLoading] = useState(false);
    const numberOfRequests = encryptionKeysRequest.requesters.length;

    const getRequestersStrings = () => {
        switch (encryptionKeysRequest.requesters.length) {
            case 1:
                return <span className="username" key={`${0}-name`}>
                    {getDisplayName(UserStore.getUserById(encryptionKeysRequest.requesters[0].user_id))}
                </span>;
            case 2:
                return <span className="username" key={`${1}-name`}>
                    {getDisplayName(UserStore.getUserById(encryptionKeysRequest.requesters[0].user_id)) + ' ' + _t("and")
                        + ' ' + getDisplayName(UserStore.getUserById(encryptionKeysRequest.requesters[1].user_id))}
                </span>;
            default:
                return <span className="username" key={`${2}-name`}>
                    {getDisplayName(UserStore.getUserById(encryptionKeysRequest.requesters[0].user_id)) + ' ' + _t("and %(numberOfUsers)s other people", {
                        numberOfUsers: encryptionKeysRequest.requesters.length - 1,
                    })}
                </span>;
        }
    };

    const label = useMemo(() => {
        const data = [];
        data.push(<span key="chatEnc">{_t("This chat is encrypted.") + ' '}</span>);
        data.push(getRequestersStrings());
        data.push(
            <span key="rest">{
                numberOfRequests === 1
                    ? ` ${_t('is requesting your help to be able to read encrypted messages')}`
                    : ` ${_t('are requesting your help to be able to read encrypted messages')}`
            }</span>,
        );
        return data;
    }, [encryptionKeysRequest]);

    const cancelRequest = (action) => async () => {
        if (laterKeysToDecrypt && !!laterKeysToDecrypt.length) {
            const allLaterKeysToDecrypt = JSON.parse(localStorage.getItem("allLaterKeysToDecrypt") || "{}");
            allLaterKeysToDecrypt[roomId].pop();
            if (allLaterKeysToDecrypt[roomId].length === 0) {
                delete allLaterKeysToDecrypt[roomId];
            }
            localStorage.setItem("allLaterKeysToDecrypt", JSON.stringify(allLaterKeysToDecrypt));
            window.dispatchEvent(new Event('storage'));
            MatrixClientPeg.get().trackUserAction({
                formId: 'E2EKeysSharingInRoom',
                version: 1,
                action: action,
                room: roomId,
            });
        } else {
            if (isCancelLoading) return;
            setIsCancelLoading(true);
            const cli = MatrixClientPeg.get();
            const myUserId = UserStore.getMe().userId;
            const myRequest = encryptionKeysRequest.requesters.find(req => req.user_id === myUserId);
            try {
                await cli.deleteRoomKeyRequest(roomId, myRequest.device_id, myRequest.event_id);
                MatrixClientPeg.get().trackUserAction({
                    formId: 'E2EKeysSharingInRoom',
                    version: 1,
                    action: action,
                    room: roomId,
                });
            } catch (error) {
                Modal.createDialog(
                    SuccessErrorDialog,
                    {
                        onFinished: noop,
                        title: _t("Cancellation failed"),
                        message: _t("Cancellation of request failed, please try later."),
                        isFailure: true,
                    },
                    'success_error_dialog',
                );
                setIsCancelLoading(false);
            }
        }
    };

    const shareMyKeys = () => {
        MatrixClientPeg.get().trackUserAction({
            formId: 'E2EKeysSharingInRoom',
            version: 1,
            action: 'share',
            nbRecipients: encryptionKeysRequest.requesters.length,
            room: roomId,
        });
        Modal.createDialog(E2eKeyShareDialog,
            { roomId, requesters: encryptionKeysRequest.requesters, onFinished: noop }, 'mx_KeySharingDialog_wrapper',
        );
    };

    const denySharingKeys = (action) => () => {
        const e2eKeySharedList = JSON.parse(localStorage.getItem("e2eKeySharedList")) || {};
        e2eKeySharedList[roomId] = { isKeyDenied: true };
        localStorage.setItem("e2eKeySharedList", JSON.stringify(e2eKeySharedList));
        window.dispatchEvent(new Event('storage'));
        MatrixClientPeg.get().trackUserAction({
            formId: 'E2EKeysSharingInRoom',
            version: 1,
            action: action,
            room: roomId,
        });
    };

    const onMinimizeHandler = () => {
        let action;
        if (isMinimized) {
            action = hasSentE2eRetrievalEvent ? 'maximizeRequest' : 'maximizeShare';
        } else {
            action = hasSentE2eRetrievalEvent ? 'minimizeRequest' : 'minimizeShare';
        }
        MatrixClientPeg.get().trackUserAction({
            formId: 'E2EKeysSharingInRoom',
            version: 1,
            action,
            room: roomId,
        });
        onMinimize();
    };

    const getTitleForSentE2eKeys = () => {
        const listOfE2eReceivers = JSON.parse(localStorage.getItem("listOfE2eReceivers"));
        if (listOfE2eReceivers.length === 1) {
            return _t("You shared your encryption keys. We’re waiting for %(name)s to decrypt the encrypted messages", {
                name: listOfE2eReceivers[0].displayName,
            });
        } else if (listOfE2eReceivers.length === 2) {
            return _t("You shared your encryption keys. We’re waiting for %(name)s and 1 other person to decrypt the encrypted messages", {
                name: listOfE2eReceivers[0].displayName,
            });
        } else if (listOfE2eReceivers.length > 2) {
            return _t("You shared your encryption keys. We’re waiting for %(name)s and %(numberOfPeople)s other persons to decrypt the encrypted messages", {
                name: listOfE2eReceivers[0].displayName,
                numberOfPeople: listOfE2eReceivers.length - 1,
            });
        }
    };

    const onExternalReceiverCodeDialogFinished = (successful = true, intentionalClose) => {
        if (!successful) {
            Modal.createDialog(
                SuccessErrorDialog,
                {
                    onFinished: noop,
                    title: _t("Decryption failure"),
                    message: _t("The messages could not be decrypted."),
                    isFailure: true,
                },
                'success_error_dialog',
            );
        } else if (!intentionalClose) {
            Modal.closeAll(true);
        }
    };

    const decryptMessages = () => {
        const { mxc, secret, sender, senderDisplayName, eventId, timestamp } = laterKeysToDecrypt[laterKeysToDecrypt.length - 1];
        const timestampForRequest = Date.parse(timestamp);
        const cli = MatrixClientPeg.get();
        const serverConfig = cli.getServerConfig();
        // the request has expired
        // TOOD: Don't use Date.now() as it uses client. use server timestamp instead
        const expirationDurationInMiliseconds = serverConfig.e2e.expiration_duration * 60 * 60 * 1000;
        if (Date.now() > (timestampForRequest + expirationDurationInMiliseconds)) {
            Modal.createDialog(
                SuccessErrorDialog,
                {
                    onFinished: noop,
                    title: _t("Failed to decrypt messages"),
                    message: _t("The encryption keys received from %(userName)s are expired, please make a new request.", {userName: senderDisplayName}),
                    isFailure: true,
                },
                'success_error_dialog',
            );
            if (laterKeysToDecrypt && !!laterKeysToDecrypt.length) {
                const allLaterKeysToDecrypt = JSON.parse(localStorage.getItem("allLaterKeysToDecrypt") || "{}");
                allLaterKeysToDecrypt[roomId].pop();
                if (allLaterKeysToDecrypt[roomId].length === 0) {
                    delete allLaterKeysToDecrypt[roomId];
                }
                localStorage.setItem("allLaterKeysToDecrypt", JSON.stringify(allLaterKeysToDecrypt));
                window.dispatchEvent(new Event('storage'));
            }
        } else {
            Modal.createDialog(ExternalReceiverCodeDialog, {
                eventId,
                mxc,
                onFinished: onExternalReceiverCodeDialogFinished,
                roomId,
                secret,
                sender,
                senderDisplayName,
                timestamp,
                renderWelcomeMessage: false,
            });
            MatrixClientPeg.get().trackUserAction({
                formId: 'E2EKeysSharingInRoom',
                version: 1,
                action: 'decrypt3',
                room: roomId,
            });
        }
    };

    return (
        <div
            className={cx("encryptionKeysRequestBar", { isMinimized })}
            onClick={() => isMinimized ? onMinimizeHandler() : noop}
        >
            <div className="encryptionKeysRequestBar_icons_container">
                {isMinimized
                    ? <Fragment>
                        <div className="numberOfRequests">{numberOfRequests}</div>
                        <div className="info">{_t('Encrypted keys requests')}</div>
                        <div className="maximize" onClick={onMinimize} />
                      </Fragment>
                    : <div className="min_close_icons_container">
                        <img className="minimize" src={reduceImg} alt="reduce" onClick={onMinimizeHandler} />
                        {!hasSentE2eKeysToMembers &&
                            <img className="close" src={closeImg} alt="close" onClick={hasSentE2eRetrievalEvent ? cancelRequest('closeRequest') : denySharingKeys('closeShare')} />}
                      </div>
                }
            </div>
            {!isMinimized ?
                hasSentE2eKeysToMembers ?
                    <Fragment>
                        <div className="hasSentE2eKeysContainer">
                            <img src={e2eKeySent} />
                            <span>{getTitleForSentE2eKeys()}</span>
                        </div>
                    </Fragment>
                : !hasSentE2eRetrievalEvent ? <Fragment>
                    <div className="label">
                        {label}
                    </div>
                    <div className="action_buttons_container">
                        <div className="button_container">
                            <CitadelButton
                                onClick={shareMyKeys}
                                primary
                                text={_t('Share my encryption keys')}
                            />
                        </div>
                        <span className="deny_button" onClick={denySharingKeys('deny')}>{_t("Deny")}</span>
                    </div>
                  </Fragment>
                        : hasSentE2eRetrievalEvent &&
                            laterKeysToDecrypt && !!laterKeysToDecrypt.length ?
                            <Fragment>
                                <div className="label">
                                    <span>
                                        {_t("%(userName)s sent the encryption keys. You can now decrypt your messages.", { userName: laterKeysToDecrypt[laterKeysToDecrypt.length - 1]?.senderDisplayName })}
                                    </span>
                                </div>
                                <div className="e2eKeysButtonsContainer">
                                    <CitadelButton
                                        onClick={decryptMessages}
                                        primary
                                        text={_t('Decrypt my messages')}
                                    />
                                            <p
                                        className="cancelRequestButton"
                                        onClick={cancelRequest('cancelRequest2')}
                                        isSecondary
                                        isDisabled={isCancelLoading}>{_t("Cancel")}</p>
                                </div>
                            </Fragment>
                                :
                            <Fragment>
                                <div className="label">
                                    <span>{_t("The request to decrypt the encrypted messages is still being processed")}</span>
                                </div>
                                <div className="button_container">
                                    <CitadelButton
                                        onClick={cancelRequest('cancelRequest')}
                                        primary
                                        text={_t('Cancel request')}
                                        isDisabled={isCancelLoading}
                                    />
                                </div>
                            </Fragment>
            : null}
        </div>
    );
};

export default EncryptionKeysRequestBar;
