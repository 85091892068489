import React, { useState, useMemo, useCallback } from 'react';
import cx from 'classnames';

import { _t } from '../../../../languageHandler';
import MatrixClientPeg from '../../../../MatrixClientPeg';
import UserStore from '../../../../stores/UserStore';

import Spinner from '../Spinner';
import BaseDialog from '../../dialogs/BaseDialog';
import ConferencePlanInvitedPills from './components/ConferencePlanInvitedPills';
import ConferencePlanInviteesList from './components/ConferencePlanInviteesList';
import AccessibleButton from '../AccessibleButton';
import SearchBox from '../../../structures/SearchBox';

const ConferencePlanDialogInvitees = (props) => {
    const {
        calendarConferenceType,
        calendarDate,
        calendarDescription,
        calendarEndTime,
        calendarRoomName,
        calendarStartTime,
        calendarTitle,
        maxParticipants: {
            video: videoMaxParticipants,
            audio: audioMaxParticipants,
        },
        onChangeStep,
        onDownloadCalendarFile,
        onFinished,
        roomId,
        url,
    } = props;
    const [allMembersInvited, setAllMembersInvited] = useState(false);
    const [maxLimitReached, setMaxLimitReached] = useState(false);
    const [membersToInvite, setMembersToInvite] = useState(props.membersToInvite);
    const [sendingTheInvites, setSendingTheInvites] = useState('');
    const [membersListFiltered, setMembersListFiltered] = useState(false);

    const getNumberOfInvitedMembers = useCallback((membersToInvite) =>
            membersToInvite.filter(member => member.invited && member.display).length,
    []);

    const numberOfInvitedMembers = useMemo(() => getNumberOfInvitedMembers(membersToInvite), [membersToInvite]);

    const maxMembersBasedOnConference = calendarConferenceType === 'Audio'
        ? audioMaxParticipants
        : videoMaxParticipants;

    const sendInvitesButtonClassname = cx("mx_ConferencePlanDialog sendInvites", {
        disabled: (numberOfInvitedMembers === 0) || (numberOfInvitedMembers > maxMembersBasedOnConference),
    });

    const onSelectAllMembers = (someMembersInvited) => () => {
        const newMembers = membersToInvite.map(member =>
            ({...member, invited: member.display ? !(allMembersInvited || someMembersInvited) : member.invited }));

        setMembersToInvite(newMembers);
        setAllMembersInvited((prev) => prev === true ? false : !someMembersInvited);
        setMaxLimitReached((prev) => someMembersInvited ? false : prev);
    };

    const onChangeInviteeStatus = (index) => () => {
        const maxInviteesNumber = calendarConferenceType === 'Audio' ? audioMaxParticipants : videoMaxParticipants;

        const newMembersToInvite = [...membersToInvite];
        if (maxLimitReached && !newMembersToInvite[index].invited) return;

        newMembersToInvite[index].invited = !newMembersToInvite[index].invited;
        const membersInvited = newMembersToInvite.filter(member => member.invited);

        setAllMembersInvited(membersInvited.length === membersToInvite.length);
        setMaxLimitReached(membersInvited.length >= maxInviteesNumber);
        setMembersToInvite(newMembersToInvite);
    };

    const onUnInviteUser = (index) => () => {
        setMembersToInvite(prev => {
            const newMembersToInvite = [...prev];
            newMembersToInvite[index].invited = false;
            return newMembersToInvite;
        });
        setMaxLimitReached(false);
        setAllMembersInvited(false);
    };

    const onChangeSearchInput = (value) => {
        if (value !== '') {
            const filteredMembers = membersToInvite.map((item) => {
                const newItem = item;
                newItem.display = false;
                if (item.member.name.toLowerCase().includes(value.toLowerCase()) ||
                    item.member.user.email.includes(value.toLowerCase())) {
                    newItem.display = true;
                }
                return newItem;
            });
            setMembersListFiltered(true);
            setMembersToInvite(filteredMembers);
        } else {
            setMembersListFiltered(false);
            setMembersToInvite(prev => prev.map(item => ({...item, display: true })));
        }
    };

    const onCloseDialog = () => {
        onFinished();
        MatrixClientPeg.get().trackUserAction({
            formId: 'planConference',
            version: 1,
            action: 'cancel',
        });
    };

    const onSendInvitesPress = async () => {
        setSendingTheInvites(true);
        const invitedMembers = membersToInvite.reduce((prevValue, member) => {
            if (member.invited) prevValue.push(member.member.userId);
            return prevValue;
        }, []);
        const room = MatrixClientPeg.get().getRoom(roomId);
        const myUserId = UserStore.getMe().userId;
        const me = room.getMember(myUserId);
        const calendarFormat = calendarDate.format('DD/MM/YYYY');
        try {
            await MatrixClientPeg.get().sendConferenceInvites({
                attendees_id: invitedMembers,
                configuration: {
                    description: calendarDescription.replace(/\n|\r/g, "\\n"),
                    end_time: calendarFormat + ` ${calendarEndTime}:00`,
                    location: `${calendarConferenceType}conference in room "${calendarRoomName}" - ${url}`,
                    organizer_email: me.user.email,
                    organizer_name: me.name,
                    start_time: calendarFormat + ` ${calendarStartTime}:00`,
                    summary: calendarTitle,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
                inviter: me.userId,
                room_id: roomId,
                type: calendarConferenceType.toLowerCase(),
            });
            setSendingTheInvites(false);
            onChangeStep(1)();
            MatrixClientPeg.get().trackUserAction({
                formId: 'planConference',
                version: 1,
                action: 'invite',
            });
        } catch (error) {
            setSendingTheInvites(false);
            onChangeStep(2)();
        }
    };

    const onInfraPillFilterPress = (infra) => {
        const filteredMembers = membersToInvite.map((item) => {
            const newItem = item;
            if (infra === 'All') {
                setMembersListFiltered(false);
                newItem.display = true;
            } else {
                setMembersListFiltered(true);
                newItem.display = false;
                if (item.member.user.email.includes(infra.toLowerCase())) {
                    newItem.display = true;
                }
            }
            return newItem;
        });

        getNumberOfInvitedMembers(filteredMembers) === membersToInvite.length ?
        setAllMembersInvited(true) : setAllMembersInvited(false);
        setMembersToInvite(filteredMembers);
    };

    return (
        <BaseDialog
            className="mx_ConferencePlanDialog"
            title={_t('Who would you like to invite to %(meetingName)s ?', {
                meetingName: calendarTitle,
            })}
            titleClass={'mx_ConferencePlanDialog_title'}
            onFinished={onCloseDialog}
        >

            <div className="mx_ConferencePlanDialog_searchBoxContainer">
                <SearchBox
                    onSearch={onChangeSearchInput}
                    placeholder={ _t('Filter by name or email address') }
                    className="mx_ConferencePlanDialog_searchBox"
                />
            </div>

            <ConferencePlanInviteesList
                allMembersInvited={allMembersInvited}
                maxLimitReached={maxLimitReached}
                maxMembersBasedOnConference={maxMembersBasedOnConference}
                membersListFiltered={membersListFiltered}
                membersToInvite={membersToInvite}
                numberOfInvitedMembers={numberOfInvitedMembers}
                onChangeInviteeStatus={onChangeInviteeStatus}
                onInfraPillFilterPress={onInfraPillFilterPress}
                onSelectAllMembers={onSelectAllMembers}
            />

            <div className='mx_ConferencePlanDialog_separator' />

            <ConferencePlanInvitedPills
                calendarConferenceType={calendarConferenceType}
                maxMembersBasedOnConference={maxMembersBasedOnConference}
                membersToInvite={membersToInvite}
                numberOfInvitedMembers={numberOfInvitedMembers}
                onUnInviteUser={onUnInviteUser}
            />

            <div className='mx_ConferencePlanDialog_separator' />
            <div className="mx_ConferencePlanDialog_actionButtonsContainer">
                    <span className='mx_ConferencePlanDialog_previousButton' onClick={onChangeStep(-1)}>
                        {_t('Previous')}
                    </span>
                <AccessibleButton
                    className="mx_ConferencePlanDialog downloadInvitation"
                    onClick={onDownloadCalendarFile}
                >
                    {_t('Download invitation')}
                </AccessibleButton>
                <AccessibleButton
                    className={sendInvitesButtonClassname}
                    disabled={(numberOfInvitedMembers === 0) ||
                         (numberOfInvitedMembers > maxMembersBasedOnConference)}
                    kind="primary"
                    onClick={onSendInvitesPress}
                >
                    {sendingTheInvites
                        ? <Spinner className="mx_ConferencePlanDialog spinner" />
                        : _t('Send invitation')
                    }
                </AccessibleButton>
            </div>
        </BaseDialog>
    );
};

export default ConferencePlanDialogInvitees;
