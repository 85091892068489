import * as React from 'react';

const MinifiedNewsEdition = ({ onClick, title = '' }) => (
    <div className="mx_MinifiedNewsEdition_wrapper" onClick={onClick}>
        <div className="mx_MinifiedNewsEdition_news-icon" />
        <div className="mx_MinifiedNewsEdition_text">
            {title}
        </div>
        <div className="mx_MinifiedNewsEdition_expand" />
    </div>
);

export default MinifiedNewsEdition;
