import utils from '../utils';

/**
 * Internal module. Management of the new room key sharing process
 * implemented through Citadel clients.
 * Key requester side.
 *
 * @module
 */

/** possible states for a Citadel room key request
 *
 *  The state machine looks like:
 *
 *     (init) <--------------------------.----------------------------------.
 *      |                                |                                  |
 *      |  (out: citadel.code_start)     |                                  |
 *      |  - new key sharing request     |                                  |
 *      |  - same key sharing request,   |                                  |
 *      |    holder is asked to generate |                                  |
 *      |    a new code                  |                                  |
 *      |                                |  (in: citadel.code_cancel)       |
 *      V                                |                                  |
 *     REQ_SENT                          |                                  |
 *      |                                |                                  |
 *      |--------------------------------'                                  |  (in: citadel.code_cancel)
 *      |                                                                   |  submitted code invalidated and
 *      |  (in: citadel.code_ready)                                         |  maximum number of attempts reached
 *      |                                                                   |
 *      V                                                                   |
 *     REQ_RECEIVED <--------------------.                                  |
 *      |                                |                                  |
 *      |                                |                                  |
 *      |                                |                                  |
 *      |  (out: citadel.code_submit)    |  (in: citadel.code_retry)        |
 *      |                                |  submitted code invalidated but  |
 *      V                                |  maximum number of attempts      |
 *     CODE_SENT                         |  is not reached yet              |
 *      |                                |                                  |
 *      |--------------------------------'                                  |
 *      |-------------------------------------------------------------------'
 *      |
 *      |  (in: citadel.code_valid)
 *      V
 *     CODE_VALIDATED --> (init)
 */

const EASY_KEY_REQUEST_STATES = {
    // init, nothing has been requested yet
    INIT: null,

    // request sent but not received yet
    REQ_SENT: 0,

    // request sent and received, code asked for submission
    REQ_RECEIVED: 1,

    // code sent but not validated yet
    CODE_SENT: 2,

    // code validated
    CODE_VALIDATED: 3,

    // request sent and received, code asked for submission
    EXTERNAL_REQ_RECEIVED: 4,
};

export default class EasyKeyRequesterManager {
    constructor(crypto) {
        this._crypto = crypto;
        this.reset();
    }

    reset() {
        this._state = EASY_KEY_REQUEST_STATES.INIT;
        this._timestamp = '';
        this._sessionId = '';
        this._remoteDeviceInfo = null;
    }

    verifySession(remoteSessionId) {
        return (remoteSessionId === this._sessionId);
    }

    onCodeReadyReceived({ content }) {
        const { sessionId } = content;
        if (this.verifySession(sessionId)) {
            this._state = EASY_KEY_REQUEST_STATES.REQ_RECEIVED;
        }

        // FIXME: should we send a cancel here ?
    }

    onExternalCodeReadyReceived({ content }) {
        const { sessionId } = content;
        if (this.verifySession(sessionId)) {
            this._state = EASY_KEY_REQUEST_STATES.EXTERNAL_REQ_RECEIVED;
        }

        // FIXME: should we send a cancel here ?
    }

    onCancelReceived({ content: { sessionId } }) {
        if (this.verifySession(sessionId)) {
            this.reset();

            return true;
        }
        return false;
    }

    async startKeyRequest(props) {
        const {
            expiration,
            infra,
            mxc,
            remoteDeviceId,
            remoteUserId,
            secret,
            type,
            eventId,
            roomId,
        } = props;
        const remoteDeviceInfo = this._crypto.getDeviceInfo(remoteDeviceId, remoteUserId);
        if (!remoteDeviceInfo) return;

        this._timestamp = utils.getTimestamp();
        this._sessionId = utils.generateAlphaNumString(30);
        this._remoteDeviceInfo = remoteDeviceInfo;

        await this._crypto.encryptedSendToSingleDevice(
            this._remoteDeviceInfo,
            {
                type,
                content: {
                    timestamp: this._timestamp,
                    sessionId: this._sessionId,
                    mxc,
                    expiration,
                    secret,
                    infra,
                    eventId,
                    roomId,
                },
            },
            remoteUserId,
        );

        this._state = EASY_KEY_REQUEST_STATES.REQ_SENT;
    }

    async submitCode(code) {
        await this._crypto.encryptedSendToSingleDevice(
            this._remoteDeviceInfo,
            {
                type: 'citadel.code_submit',
                content: {
                    timestamp: this._timestamp,
                    sessionId: this._sessionId,
                    code,
                },
            },
        );

        this._state = EASY_KEY_REQUEST_STATES.CODE_SENT;
    }

    async cancelKeyRequest(remoteDeviceId) {
        const remoteDeviceInfo = this._crypto.getDeviceInfo(remoteDeviceId);

        if (!remoteDeviceInfo) return;

        await this.sendCancel(remoteDeviceInfo);
    }

    /**
     * Sends a cancel message to a requester device.
     *
     * @param {string} deviceInfo If undefined, the request will be sent
     *                 to the requester device the provider device is
     *                 having an ongoing session with.
     */
    async sendCancel(deviceInfo) {
        await this._crypto.encryptedSendToSingleDevice(
            deviceInfo || this._remoteDeviceInfo,
            {
                type: 'citadel.code_cancel',
                content: {
                    timestamp: this._timestamp,
                    sessionId: this._sessionId,
                },
            },
        );
    }
}
