import React from 'react';
import PropTypes from 'prop-types';
import {_t} from '../../../languageHandler';
import dis from '../../../dispatcher';

import aloneSvg from '../../../../res/img/alone.svg';
import ignoreSvg from '../../../../res/img/feather-customised/cancel.svg';

// TODO: should be a pure functional class
class TimelineIsAloneCard extends React.Component {
    onInviteClick = (ev) => {
        ev.preventDefault();
        dis.dispatch({
            action: 'view_invite',
            roomId: this.props.roomId,
        });
    };

    onIgnore = (ev) => {
        ev.preventDefault();
        this.props.onStopAloneWarningClick();
    };

    render() {
        return (
            <div className="mx_TimelineIsAloneCard">
                <div className="cancel" onClick={this.onIgnore}>
                    <img src={ignoreSvg} alt="ignore" />
                </div>
                <div className="content">
                    <div className="icon">
                        <img src={aloneSvg} alt="" />
                    </div>
                    <div className="text">
                        {_t('You are currently the only member of this room')}
                    </div>
                    <div className="button" onClick={this.onInviteClick}>
                        {_t('Invite members')}
                    </div>
                </div>
            </div>
        );
    }
}

TimelineIsAloneCard.propTypes = {
    onStopAloneWarningClick: PropTypes.func.isRequired,
    roomId: PropTypes.string.isRequired,
};

export default TimelineIsAloneCard;
