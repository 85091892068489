import React from 'react';

import { _t } from '../../languageHandler';

import hourglass from '../../../res/img/hourglass.svg';

const AccountPending = ({ onLogout }) => {
    const userEmail = localStorage.getItem('mx_user_email');
    return (
        <div className='mxAccountPending_container'>
            <div className='mxAccountPending_card'>
                <h1 className='mxAccountPending_header'>{_t("Validation pending")}</h1>
                <div className='mxAccountPending_descriptionContainer'>
                    <span className='mxAccountPending_firstDescription'>{
                        _t("Your registration has been taken into account. " +
                        "We are waiting for the validation of your administrator to give you access to Citadel.")}
                    </span>
                    <div className='mxAccountPending_secondDescription'>
                        <span>{_t("You will receive the validation on the email indicated during the registration")}</span>
                        <span>{` (${userEmail})`}</span>
                    </div>
                </div>
                <img src={hourglass} className='mxAccountPending_image' />
                <span onClick={onLogout} className='mxAccountPending_logout'>{_t("Back to Login page")}</span>
            </div>
        </div>
    );
};

export default AccountPending;
