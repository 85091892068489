//@flow

import { Store } from 'flux/utils';
import dis from '../dispatcher';
import { User } from 'matrix-js-sdk';
import MatrixClientPeg from "../MatrixClientPeg";

class UserStore extends Store {
    constructor() {
        super(dis);
    }

    /**
     * Calls MemoryStore.getUsers
     * Retrieve a User by its' user ID.
     * @param {string} userId The user ID.
     * @return {User | {}} The user or {}.
     */
    getUserById(userId: string): User {
        const cli = MatrixClientPeg.get();
        return (cli && cli.getUser(userId)) || {};
    }

    /**
     * Calls MemoryStore.getUsers
     * Retrieve a User by its' user email.
     * @param {string} email The user email address.
     * @return {User | {}} The user or {}.
     */
    getUserByEmail(email: string): User {
        return this.getUsers().find(u => u.email === email) || {};
    }

    /**
     * Calls MemoryStore.getUsers
     * Retrieve all known users.
     * @return {User[]} A list of users, which may be empty.
     */
    getUsers(): [User] {
        const cli = MatrixClientPeg.get();
        return cli && cli.getUsers();
    }

    /**
     * Return currently signed in user.
     * @returns {User | null} The signed in user or null.
     */
    getMe(): User {
        const users = this.getUsers();
        return users.find(user => user.isMe) ||
            this.getUserById(localStorage.getItem('mx_user_id')) || null;
    }

    /**
     * Returns a user's attribute by key or null if not found.
     * @param {string} userId
     * @param {string} propKey
     * @returns {any} The user's attribute
     */
    getUserProp(userId: string, propKey: string): any {
        const user = this.getUserById(userId);
        if (!user || !user[propKey]) return null;
        return user[propKey];
    }

    /**
     * Returns a user's attribute by key or null if not found.
     * @param {string} userId
     * @returns {object | undefined} The user's firstname and lastname or undefined
     */
    getNames = async (userId) => {
        let fullName = this.getUserProp(userId, 'displayName');
        if (fullName === userId || fullName === null) {
            const cli = MatrixClientPeg.get();
            try {
                let user = await cli.getProfileInfo(userId);
                user = { ...user, userId };
                this._saveUser(user, false);
                const { displayname } = user;
                fullName = displayname;
            } catch (e) {
                console.log('Can not get user info', e);
            }
        }

        if (!fullName) return;

        const parts = fullName.split(' ');
        let firstName = parts[0];
        for (let i = 1; i < parts.length; i ++) {
            if (parts[i] === parts[i].toUpperCase()) {
                break;
            }

            firstName = `${firstName} ${parts[i]}`;
        }
        const lastName = fullName.slice(firstName.length + 1);
        return {
            firstName,
            lastName,
        };
    }

    /**
     * Calculates a rawDisplayName from a (possibly disambiguated) displayName.
     * @param {string} displayName
     * @returns {string} rawDisplayName
     */
    getRawDisplayName(displayName: string): string {
        return (displayName && displayName.split(/ \(/)[0]) || '';
    }

    /**
     * Calculates a new displayName from member displayName by replacing
     * the userId with the user email address
     * @param {string} userId
     * @param {string} displayName
     * @returns {string} the new disambiguated displayName, possibly unchanged.
     */
    getDisambiguatedNameWithEmail(userId: string, displayName: string): string {
        const user = this.getUserById(userId);
        if (!user || !user.email) return displayName;

        if (this.getDisambiguatedInfo(displayName)) {
            const rawDisplayName = this.getRawDisplayName(displayName);
            return this._getDisambiguatedName(rawDisplayName, user.email);
        } else {
            return displayName;
        }
    }

    /**
     * Get the disambiguated info added to the rawDisplayName to disambiguate
     * a user displayName (might be an userId or an email address)
     * @param {string} displayName
     * @returns {string} disambiguatedInfo
     */
    getDisambiguatedInfo(displayName: string): string {
        return displayName ? displayName.split(/[()]/)[1] : '';
    }

    _getDisambiguatedName(displayName: string, disambiguateInfo: string): string {
        return displayName + " (" + disambiguateInfo + ")";
    }

    _saveUser(user: User, emitChange: boolean = true) {
        const cli = MatrixClientPeg.get() || null;
        if (!cli || !user) return;

        const storedUser = this.getUserById(user.userId);

        const { userId, ...data } = user;
        const { displayName, email } = data;
        if (!storedUser || (storedUser && Object.keys(storedUser).length === 0)) {
            const newUser = new User(userId, data);
            cli.storeUser(newUser);
        } else {
            const shouldReplaceRawDisplayName = displayName && displayName !== storedUser.rawDisplayName;
            const shouldReplaceEmail = email && email !== storedUser.email;

            let newDisplayName = storedUser.displayName;
            let newRawDisplayName = storedUser.rawDisplayName;

            // disambiguateInfo being defined tells us that displayName has been disambiguated ...
            const disambiguateInfo = this.getDisambiguatedInfo(storedUser.displayName) || '';

            if (shouldReplaceRawDisplayName) {
                newRawDisplayName = this.getRawDisplayName(displayName);
                storedUser.setRawDisplayName(newRawDisplayName);

                newDisplayName = disambiguateInfo ?
                    this._getDisambiguatedName(newRawDisplayName, disambiguateInfo) : newRawDisplayName;
            }

            if (shouldReplaceEmail) {
                storedUser.setEmailAddress(email);

                if (disambiguateInfo && disambiguateInfo !== email) {
                    newDisplayName = this._getDisambiguatedName(newRawDisplayName, email);
                }
            }

            if (newDisplayName !== storedUser.displayName) {
                storedUser.setDisplayName(newDisplayName);
            }
        }

        if (emitChange) {
            this.__emitChange();
        }
    }

    _saveUsers(users: [User]) {
        for (const user of users) {
            this._saveUser(user, false);
        }

        this.__emitChange();
    }

    __onDispatch(payload: { [string]: any }) {
        switch (payload.action) {
            case 'USER_STORE_SAVE_USER':
                this._saveUser(payload.user);
                break;
            case 'USER_STORE_SAVE_USERS':
                this._saveUsers(payload.users);
                break;
            default:
                return;
        }
    }
}

module.exports = new UserStore();
