import React from 'react';
import PropTypes from 'prop-types';
import Spinner from './Spinner';
import cx from 'classnames';

const CitadelButton = ({ className, text, onClick, isSecondary, isDisabled, isLoading, minWidth, width, type = "button" }) => {
    const newClassName = cx('mx_CitadelButton', className, {
        primary: !isSecondary,
        secondary: isSecondary,
        disabled: isDisabled,
        loading: isLoading,
    });
    const style = { minWidth: minWidth - 43 };
    if (width) style.width = width;
    return <button
        className={newClassName}
        disabled={isDisabled || isLoading}
        style={style}
        onClick={onClick}
        type={type}
    >
        {!isLoading ? text : <Spinner w={20} h={20} />}
    </button>;
};

CitadelButton.propTypes = {
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isSecondary: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
};

CitadelButton.defaultProps = {
    className: '',
    isDisabled: false,
    isLoading: false,
    isSecondary: false,
    minWidth: 20,
};

export default CitadelButton;
