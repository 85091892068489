import React from 'react';
import { ContentRepo } from 'matrix-js-sdk';
import PropTypes from 'prop-types';

import dis from '../../../dispatcher';
import MatrixClientPeg from '../../../MatrixClientPeg';
import Modal from '../../../Modal';
import { fetchUrlWithToken } from '../../../utils/MediaUtils';
import { getSanitizedContentWithFQDNInjected } from '../../../HtmlUtils';
import MxcBlobStore from '../../../stores/MxcBlobStore';
import NewsDialog from '../dialogs/news/NewsDialog';

import ffffff from "../../../../res/img/ffffff.png";

export default class NewsBody extends React.Component {
  static displayName = 'NewsBody';

  static propTypes = {
    mxEvent: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      imageUrl: ffffff,
    };
    this._mxcblobStore = MxcBlobStore;
  }

  preview = null;

  componentDidMount() {
    this.unmounted = false;
    const { mxEvent } = this.props;
    const imageMxc = mxEvent.getContent().newsimg;

    if (!imageMxc) return;

    const blob = this._mxcblobStore.getBlobFromMxc(imageMxc);

    if (blob) {
      if (!this.unmounted) {
        this.setState({
          imageUrl: blob,
        });
      }
    } else {
      fetchUrlWithToken(this.getImageUrl(imageMxc))
      .then((blob) => {
        if (blob) {
          dis.dispatch({
            action: 'update_blob_store',
            mxc: imageMxc,
            blob: blob,
          });

          if (!this.unmounted) {
            this.setState({
              imageUrl: blob,
            });
          }
        }
      })
      .catch((err) => {
        console.error('Could not retrieve url content ', err);
      });
    }
    this.handleEllipsis();
  }

  componentWillUnmount() {
    this.unmounted = true;
  }


  getImageUrl = (imageMxc) => ContentRepo.getHttpUriForMxc(
      MatrixClientPeg.get().getHomeserverUrl(),
      imageMxc,
      Math.floor(80 * window.devicePixelRatio),
      Math.floor(80 * window.devicePixelRatio),
      "crop",
  );

  handleEllipsis = () => {
      const { mxEvent } = this.props;
      const newsContent = mxEvent.getContent();
      const content = this.htmlDecode(getSanitizedContentWithFQDNInjected(newsContent.newsbody));

      this.initialWordsArray = content.split(' ');
      this.addEllipsis();
  };

  addEllipsis = () => {
      const words = [...this.initialWordsArray].slice(0, 30);

      this.preview = words.join(' ') + '...';
  };

  htmlDecode = (input) => {
    const doc = new DOMParser().parseFromString(input, "text/html");
    const secondCheck = new DOMParser().parseFromString(doc.documentElement.textContent, "text/html");

    return secondCheck.documentElement.textContent;
  };

  showNews = () => {
    const { mxEvent } = this.props;
    this.trackClick();
    Modal.createTrackedDialog(
        'Display News',
        '',
        NewsDialog,
        { mxEvent: mxEvent },
        'news_message_dialog',
        );
  };

  trackClick = () => {
    const { mxEvent } = this.props;
    const eventId = mxEvent.getId();
    MatrixClientPeg.get().increaseNewsMessageOpenCounter(eventId);
  }

  render() {
    const { mxEvent } = this.props;
    const { imageUrl } = this.state;
    const newsContent = mxEvent.getContent();
    const newsTitle = getSanitizedContentWithFQDNInjected(newsContent.newstitle).replace(/&amp;/g, '&');

    return (
      <div className="mx_NewsBody" onClick={this.showNews}>
        <div className="mx_NewsBody_image">
          <img className="mx_NewsBody_thumbnail" src={imageUrl} ref="image" alt={newsTitle} />
        </div>
        <div className="mx_NewsBody_content">
          <div className="mx_NewsBody_title">
            { newsTitle }
          </div>
          <div className="mx_NewsBody_preview">
            {this.props.forExport
                ? this.htmlDecode(getSanitizedContentWithFQDNInjected(newsContent.newsbody))
                : this.preview
            }
          </div>
        </div>
      </div>
    );
  }
}
