import BaseDialog from "../BaseDialog";
import DialogButtons from "../../elements/DialogButtons";
import { _t } from "../../../../languageHandler";

const RoomExportCancelWarningDialog = ({ onFinished, onContinue }) => (
    <BaseDialog
        title={_t("Warning!")}
        className="mx_ExportDialog"
        contentId="mx_Dialog_content"
        onFinished={onFinished}
        fixedWidth={true}
    >
        <p>{_t("export_chat|confirm_stop")}</p>
        <DialogButtons
            primaryButton={_t("export_chat|Stop")}
            primaryButtonClass="danger"
            hasCancel={true}
            cancelButton={_t("Continue")}
            onCancel={onFinished}
            onPrimaryButtonClick={onContinue}
        />
    </BaseDialog>
);

export default RoomExportCancelWarningDialog;
