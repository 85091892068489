import React, { Component } from 'react';
import PropTypes from 'prop-types';
import tinymce from 'tinymce';
import { noop, isEqual } from 'lodash';

import 'tinymce/models/dom/model.min';
import 'tinymce/themes/silver';
import 'tinymce/plugins/link';
import 'tinymce/plugins/table';
import 'tinymce/plugins/code';
import 'tinymce/plugins/emoticons';
import 'tinymce/icons/default';
import './emoticons';

import langFr from './lang/fr_FR';
// import { iconPackName, iconPack } from './iconPack';
import { getCurrentLanguage, _t } from '../../../languageHandler';

export default class Editor extends Component {
    constructor() {
        super();
        this.state = { editor: null };
    }

    componentDidMount() {
        const { content, id, onChange } = this.props;
        const lang = getCurrentLanguage();
        const config = {
            selector: `#${id}`,
            fixed_toolbar_container: "#citadel-editor-toolbar",
            inline: true,
            // icons: iconPackName,
            // icons_url: iconPack,
            plugins: 'link table code emoticons',
            skin: false,
            setup: editor => {
                this.setState({ editor });
                editor.on('keyup change', () => {
                    const content = editor.getContent();
                    this.setState({ editor });
                    onChange(content);
                });
                editor.on('blur', (e) => e.stopImmediatePropagation()); // prevent the toolbar to hide
            },
            statusbar: false, // disables the status bar.
            toolbar: [
                'undo redo bold italic underline strikethrough alignleft aligncenter alignright alignjustify indent ' +
                'outdent table link code emoticons',
            ],
            menubar: false,
            init_instance_callback: (editor) => {
                editor.fire('focus'); // trigger focus so that the toolbar is rendered
                content && editor.setContent(content);
            },
            resize: false,
            // next lines are required for preventing users from pasting images
            paste_data_images: false,
            paste_preprocess: (plugin, args) => {
                const { content } = args;
                if (content.includes('<img') || content.startsWith('data:')) args.content = '';
            },
            smart_paste: false,
            table_toolbar: {},
            // force links to open in a new tab to avoid problems on electron application
            // otherwise the electron app will open links in the main window
            default_link_target: "_blank",
            extended_valid_elements: "a[href|target=_blank]",
            link_title: false,
            target_list: [{ text: _t('New window'), value: '_blank' }],
        };

        if (lang === 'fr') {
            config.language = 'fr_FR';
            config.language_url = langFr;
        }

        tinymce.init(config);
    }

    componentDidUpdate({ content: prevContent }) {
        const { content } = this.props;
        const { editor } = this.state;

        if (!isEqual(content, prevContent) && !isEqual(content, editor.getContent())) {
            editor.setContent(content);
        }
    }

    componentWillUnmount() {
        const { editor } = this.state;
        tinymce.remove(editor);
    }

    render() {
        const { id, placeholder = _t('Write your message') } = this.props;

        return (
            <div className="citadel-editor-container">
                <div id="citadel-editor-toolbar" />
                <div className='citadel-editor-inner' >
                    <div id={id} className='citadel-editor' placeholder={placeholder} />
                </div>
            </div>
        );
    }
}

Editor.propTypes = {
    content: PropTypes.string,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
};

Editor.defaultProps = {
    id: 'citadel-editor',
    onChange: noop,
};
