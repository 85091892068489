/*
Copyright 2015, 2016 OpenMarket Ltd
Copyright 2017 Vector Creations Ltd
Copyright 2017, 2018 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React, { Component } from 'react';
import Promise from 'bluebird';
import debounce from 'lodash';

import { _t, getCurrentLanguage } from '../../../languageHandler';
import SdkConfig from '../../../SdkConfig';
import dis from '../../../dispatcher';
import { getCompanyName } from '../../../utils/CitadelRequests';
import AutoHideScrollbar from '../../structures/AutoHideScrollbar';
import { isValid3pidInvite } from '../../../RoomInvite';
import DMRoomMap from '../../../utils/DMRoomMap';
import MemberListTenantFilter from './MemberListTenantFilter';
import UserStore from '../../../stores/UserStore';
import { MemberInvite } from './MemberInvite';
import { HintedComponent, ARROW_POS } from '../elements/HintedComponent';
import EntityTile from './EntityTile';
import BaseAvatar from '../avatars/BaseAvatar';
import MemberTile from './MemberTile';
import Spinner from '../elements/Spinner';
import SearchBox from '../../structures/SearchBox';
import TruncatedList from '../elements/TruncatedList';
import PlatformPeg from "../../../PlatformPeg";

import MatrixClientPeg from '../../../MatrixClientPeg';
import rate_limited_func from '../../../ratelimitedfunc';
import CallHandler from '../../../CallHandler';

const INITIAL_LOAD_NUM_MEMBERS = 30;
const INITIAL_LOAD_NUM_INVITED = 5;
const SHOW_MORE_INCREMENT = 100;

export default class MemberList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.getInitialState(),
            e2eMaxMembers: null,
            isSearchActive: false,
        };
        this.matrixClient = MatrixClientPeg.get();
    }

    getInitialState = () => {
        const cli = MatrixClientPeg.get();
        if (cli.hasLazyLoadMembersEnabled()) {
            // show an empty list
            return this._getMembersState([], []);
        } else {
            return this._getMembersState(this.roomMembers(), this.roomMembers(true));
        }
    }

    async componentDidMount() {
        this._mounted = true;
        const cli = this.matrixClient;
        const room = await cli.getRoom(this.props.roomId);
        const me = room.getMember(localStorage.getItem('mx_user_id'));
        const { hps, e2e: { users_limit: e2eMaxMembers } } = await cli.getServerConfig();
        this.setState({
            hasRequiredPower: me.powerLevel >= 50 || !hps,
            e2eMaxMembers,
            isSearchActive: false,
        });
        if (cli.hasLazyLoadMembersEnabled()) {
            this._showMembersAccordingToMembershipWithLL();
            cli.on('Room.myMembership', this.onMyMembership);
        } else {
            this._listenForMembersChanges();
        }
        cli.on('Room', this.onRoom); // invites & joining after peek
        const enablePresenceByHsUrl = SdkConfig.get()['enable_presence_by_hs_url'];
        const hsUrl = MatrixClientPeg.get().baseUrl;
        this._showPresence = false;
        if (enablePresenceByHsUrl && enablePresenceByHsUrl[hsUrl] !== undefined) {
            this._showPresence = enablePresenceByHsUrl[hsUrl];
        }
        const syncMembersLength = Object.values(room.currentState.members)
            .filter(member => member.membership === 'join').length;
        const membersLengthEndpoint = this._getTotalMembers();
        PlatformPeg.get().getAppVersion().then(
            (ver) => {
                const version = ver || '';
                if ((syncMembersLength !== membersLengthEndpoint) && version.includes("beta")) {
                    console.warn("Member list has a sync issue, it differs from the endpoint, solve by clearing cache", room.currentState.members);
                    MatrixClientPeg.get().trackUserAction({
                        formId: 'memberList',
                        version: 1,
                        action: 'error',
                        step: 'memberComputation',
                        code: 0,
                        reason: `Sync value is: ${syncMembersLength} and _getTotalMembers endpoint value is ${membersLengthEndpoint}`,
                    });
                }
            }).catch((e) => {
            console.error('Error getting vector version: ', e);
        });
    }

    _listenForMembersChanges = () => {
        const cli = MatrixClientPeg.get();
        cli.on('RoomState.members', this.onRoomStateMember);
        cli.on('RoomMember.name', this.onRoomMemberName);
        cli.on('RoomState.events', this.onRoomStateEvent);
        // We listen for changes to the lastPresenceTs which is essentially
        // listening for all presence events (we display most of not all of
        // the information contained in presence events).
        cli.on('User.lastPresenceTs', this.onUserPresenceChange);
        cli.on('User.presence', this.onUserPresenceChange);
        cli.on('User.currentlyActive', this.onUserPresenceChange);
        // cli.on("Room.timeline", this.onRoomTimeline);
    }

    componentDidUpdate() {
        this.hintDebouncer = debounce(this.calculateIsHintDisplayed(), 2000);
    }

    componentWillUnmount() {
        this._mounted = false;
        const cli = MatrixClientPeg.get();
        if (cli) {
            cli.removeListener('RoomState.members', this.onRoomStateMember);
            cli.removeListener('RoomMember.name', this.onRoomMemberName);
            cli.removeListener('Room.myMembership', this.onMyMembership);
            cli.removeListener('RoomState.events', this.onRoomStateEvent);
            cli.removeListener('Room', this.onRoom);
            cli.removeListener('User.lastPresenceTs', this.onUserPresenceChange);
            cli.removeListener('User.presence', this.onUserPresenceChange);
            cli.removeListener('User.currentlyActive', this.onUserPresenceChange);
        }

        // cancel any pending calls to the rate_limited_funcs
        this._updateList.cancelPendingCall();
        this.hintDebouncer = null;
    }

    /**
     * If lazy loading is enabled, either:
     * show a spinner and load the members if the user is joined,
     * or show the members available so far if the user is invited
     */
    _showMembersAccordingToMembershipWithLL = async () => {
        const cli = MatrixClientPeg.get();
        if (cli.hasLazyLoadMembersEnabled()) {
            const room = cli.getRoom(this.props.roomId);
            const membership = room && room.getMyMembership();
            if (membership === 'join') {
                if (this._mounted) {
                    this.setState({ loading: true });
                }
                try {
                    await room.loadMembersIfNeeded();
                } catch (ex) {/* already logged in RoomView */}
                if (this._mounted) {
                    this.setState(this._getMembersState(this.roomMembers(), this.roomMembers(true)));
                    this._listenForMembersChanges();
                }
            } else if (membership === 'invite') {
                // show the members we've got when invited
                if (this._mounted) {
                    this.setState(this._getMembersState(this.roomMembers(), this.roomMembers(true)));
                }
            } else if (!membership) {
                const historyVisibility = room.currentState.getStateEvents("m.room.history_visibility", "");
                if (historyVisibility && historyVisibility.getContent().history_visibility === "world_readable" && this._mounted) {
                    this.setState(this._getMembersState(this.roomMembers(), this.roomMembers(true)));
                }
            }
        }
    }

    _getNamesakes = members => {
        const emailsToFetch = members.filter(m => {
            return (m.name && UserStore.getDisambiguatedInfo(m.name));
        });

        Promise.map(emailsToFetch, async (m) => {
            const email = UserStore.getUserProp(m.userId, 'email');
            if (!email) {
                const { address } = await MatrixClientPeg.get().getProfileInfo(m.userId, '3pid');
                dis.dispatch({
                    action: 'USER_STORE_SAVE_USER',
                    user: {
                        userId: m.userId,
                        email: address,
                    },
                });
            }
        });

        return emailsToFetch;
    }

    _getCompanies = members =>{
        if (!members.length) return {};

        if (this.state && !this.state.isRoom) return {};

        const companies = {};
        const tenantsToFetch = new Set([]);
        const tenantsCount = {};
        const currentLanguage = getCurrentLanguage();

        for (const member of members) {
            const tenant = member.userId.split(/:(.*?)\./)[1];
            if (!tenant) continue;

            if (!tenantsCount[tenant]) {
                tenantsCount[tenant] = 1;
            } else {
                tenantsCount[tenant]++;
            }

            tenantsToFetch.add(tenant);
        }

        Promise.map([...tenantsToFetch], async (tenant) => {
            const companyName = await getCompanyName(tenant, currentLanguage);
            return { tenant, name: companyName.tenant };
        }).then((data) => {
            if (data.length) {
                for (const item of data) {
                    const { tenant, name } = item;
                    if (Object.keys(companies).includes(name)) {
                        const tenants = companies[name].tenants;
                        if (!tenants.length || !tenants.includes(tenant)) {
                            tenants.push(tenant);
                        }
                        companies[name].count += tenantsCount[tenant];
                    } else {
                        companies[name] = {
                            tenants: [tenant],
                            count: tenantsCount[tenant],
                            selected: false,
                        };
                    }
                }
                if (this._mounted) {
                    this.setState({ companies });
                }
            }
        });

        return companies;
    }

    _getSelectedTenants = companies => {
        const selectedTenants = [];

        Object.keys(companies).forEach(name => {
           if (companies[name].selected) {
               selectedTenants.push(...companies[name].tenants);
           }
        });

        return selectedTenants;
    }

    _onCompanyClick = clickedCompany => {
        const companies = { ...this.state.companies };
        Object.keys(companies).forEach(company => {
            if (company === clickedCompany) {
                companies[company].selected = !companies[company].selected;
            }
        });

        const selectedTenants = this._getSelectedTenants(companies);

        this.setState({
            companies,
            selectedTenants,
            ...this._filterMembers(this.state.members, this.state.searchQuery, selectedTenants),
        });
    }

    _onResetFiltersClick = () => {
        const companies = { ...this.state.companies };
        Object.keys(companies).forEach(company => {
            companies[company].selected = false;
        });

        this.setState({
            companies,
            selectedTenants: [],
            ...this._filterMembers(this.state.members, this.state.searchQuery, []),
        });
    }

    _saveUsersToStore = members => {
        if (!members || !members.length) return;

        const myUserId = MatrixClientPeg.get().credentials.userId;
        const users = members.map(m => ({
            isMe: m.userId === myUserId,
            userId: m.userId,
            displayName: m.user ? m.user.displayName : m.rawDisplayName || m.name,
            email: '',
        }));

        setTimeout(() => {
            dis.dispatch({
                action: 'USER_STORE_SAVE_USERS',
                users,
            }, true);
        }, 50);
    }

    _getMembersState = (members, joinedMembers) => {
        this._saveUsersToStore(members);

        const dmRoomMap = DMRoomMap.shared();
        const isRoom = !dmRoomMap.getUserIdForRoomId(this.props.roomId);

        const namesakes = this._getNamesakes(members);
        const companies = isRoom ? this._getCompanies(joinedMembers) : {};
        // set the state after determining _showPresence to make sure it's
        // taken into account while rerendering
        const totalPossibleMembers = members.length + this._getPending3PidInvites().length;
        return {
            loading: false,
            members,
            namesakes,
            ...this._filterMembers(members),

            // ideally we'd size this to the page height, but
            // in practice I find that a little constraining
            truncateAtJoined: INITIAL_LOAD_NUM_MEMBERS,
            truncateAtInvited: INITIAL_LOAD_NUM_INVITED,
            searchQuery: '',
            isRoom,
            companies,
            selectedTenants: this._getSelectedTenants(companies),
            totalPossibleMembers,
        };
    }

    calculateIsHintDisplayed = () => {
        const isHintDisplayed = !MatrixClientPeg.get()
            .getInAppMessageFlag('has_shown_invite_members_button');

        const inviteElement = document.querySelector('.invite');
        const inviteElementTopPos = inviteElement && inviteElement.getBoundingClientRect().top;
        const top = inviteElementTopPos + 70;

        const hintStyle = this.state.hintStyle || {};
        if (top !== hintStyle.top) {
            if (this._mounted) {
                this.setState({
                    isHintDisplayed,
                    hintStyle: {
                        position: 'absolute',
                        right: 185,
                        top,
                    },
                });
            }
        }
    }

    onUserPresenceChange = (event, user) => {
        // Attach a SINGLE listener for global presence changes then locate the
        // member tile and re-render it. This is more efficient than every tile
        // ever attaching their own listener.
        const tile = this.refs[user.userId];
        // console.log(`Got presence update for ${user.userId}. hasTile=${!!tile}`);
        if (tile) {
            this._updateList(); // reorder the membership list
        }
    }

    onRoom = room => {
        if (room.roomId !== this.props.roomId) {
            return;
        }
        // We listen for room events because when we accept an invite
        // we need to wait till the room is fully populated with state
        // before refreshing the member list else we get a stale list.
        this._showMembersAccordingToMembershipWithLL();
    }

    onMyMembership = (room, membership, oldMembership) => {
        if (room.roomId === this.props.roomId && membership === 'join') {
            this._showMembersAccordingToMembershipWithLL();
        }
    }

    onRoomStateMember = (ev, state, member) => {
        if (member.roomId !== this.props.roomId) {
            return;
        }
        this._updateList();
    }

    onRoomMemberName = (ev, member) => {
        if (member.roomId !== this.props.roomId) {
            return;
        }
        this._updateList();
    }

    onRoomStateEvent = async (event, state) => {
        if (event.getRoomId() === this.props.roomId &&
            event.getType() === 'm.room.third_party_invite') {
            this._updateList();
        }
        if (event.getRoomId() === this.props.roomId &&
            event.getType() === 'm.room.power_levels') {
            const cli = MatrixClientPeg.get();
            const userId = localStorage.getItem('mx_user_id');
            const { hps } = await cli.getServerConfig();
            this.setState({ hasRequiredPower: event.getContent().users[userId] >= 50 || !hps });
        }
    }

    _updateList = rate_limited_func(() => {
        this._updateListNow();
    }, 500)

    _updateListNow = () => {
        const members = this.roomMembers();
        const namesakes = this._getNamesakes(members);
        const companies = this._getCompanies(members);
        const selectedTenants = this._getSelectedTenants(companies);

        const newState = {
            loading: false,
            members,
            namesakes,
            companies,
            selectedTenants,
            ...this._filterMembers(members, this.state.searchQuery, selectedTenants),
        };
        if (this._mounted) {
            this.setState(newState);
        }
        this._saveUsersToStore(members);
    }

    getMembersWithUser = () => {
        if (!this.props.roomId) return [];
        const cli = MatrixClientPeg.get();
        const room = cli.getRoom(this.props.roomId);
        if (!room) return [];

        const allMembers = Object.values(room.currentState.members);
        allMembers.forEach(function(member) {
            // work around a race where you might have a room member object
            // before the user object exists.  This may or may not cause
            // https://github.com/vector-im/vector-web/issues/186
            if (member.user === null) {
                member.user = cli.getUser(member.userId);
            }

            // XXX: this user may have no lastPresenceTs value!
            // the right solution here is to fix the race rather than leave it as 0
        });
        return allMembers;
    }

    roomMembers = (withoutInvitedUsers) => {
        const ConferenceHandler = CallHandler.getConferenceHandler();

        const allMembers = this.getMembersWithUser();
        const filteredAndSortedMembers = allMembers.filter((m) => {
            return (
                m.membership === 'join' || (!withoutInvitedUsers && m.membership === 'invite')
            ) && (
                !ConferenceHandler ||
                (ConferenceHandler && !ConferenceHandler.isConferenceUser(m.userId))
            );
        });
        filteredAndSortedMembers.sort(this.memberSort);
        return filteredAndSortedMembers;
    }

    _createOverflowTileJoined = (overflowCount, totalCount) => {
        return this._createOverflowTile(overflowCount, totalCount, this._showMoreJoinedMemberList);
    }

    _createOverflowTileInvited = (overflowCount, totalCount) => {
        return this._createOverflowTile(overflowCount, totalCount, this._showMoreInvitedMemberList);
    }

    _createOverflowTile = (overflowCount, totalCount, onClick) => {
        // For now we'll pretend this is any entity. It should probably be a separate tile.
        const text = _t('and %(count)s others...', { count: overflowCount });
        return (
            <EntityTile className="mx_EntityTile_ellipsis" avatarJsx={
                <BaseAvatar url={require('../../../../res/img/ellipsis.svg')} name="..." width={24} height={24} />
            } name={text} presenceState="online" suppressOnHover={true}
                        onClick={onClick} />
        );
    }

    _showMoreJoinedMemberList = () => {
        this.setState({
            truncateAtJoined: this.state.truncateAtJoined + SHOW_MORE_INCREMENT,
        });
    }

    _showMoreInvitedMemberList = () => {
        this.setState({
            truncateAtInvited: this.state.truncateAtInvited + SHOW_MORE_INCREMENT,
        });
    }

    memberString = member => {
        if (!member) {
            return '(null)';
        } else {
            const u = member.user;
            return '(' + member.name + ', ' + member.powerLevel + ', ' + (u ? u.lastActiveAgo : '<null>') + ', ' + (u ? u.getLastActiveTs() : '<null>') + ', ' + (u ? u.currentlyActive : '<null>') + ', ' + (u ? u.presence : '<null>') + ')';
        }
    }

    // returns negative if a comes before b,
    // returns 0 if a and b are equivalent in ordering
    // returns positive if a comes after b.
    memberSort = (memberA, memberB) => {
        // order by presence, with "active now" first.
        // ...and then by power level
        // ...and then by last active
        // ...and then alphabetically.
        // We could tiebreak instead by "last recently spoken in this room" if we wanted to.

        // console.log(`Comparing userA=${this.memberString(memberA)} userB=${this.memberString(memberB)}`);

        const userA = memberA.user;
        const userB = memberB.user;

        // if (!userA) console.log("!! MISSING USER FOR A-SIDE: " + memberA.name + " !!");
        // if (!userB) console.log("!! MISSING USER FOR B-SIDE: " + memberB.name + " !!");

        if (!userA && !userB) return 0;
        if (userA && !userB) return -1;
        if (!userA && userB) return 1;

        // First by presence
        if (this._showPresence) {
            const convertPresence = (p) => p === 'unavailable' ? 'online' : p;
            const presenceIndex = p => {
                const order = ['active', 'online', 'offline'];
                const idx = order.indexOf(convertPresence(p));
                return idx === -1 ? order.length : idx; // unknown states at the end
            };

            const idxA = presenceIndex(userA.currentlyActive ? 'active' : userA.presence);
            const idxB = presenceIndex(userB.currentlyActive ? 'active' : userB.presence);
            // console.log(`userA_presenceGroup=${idxA} userB_presenceGroup=${idxB}`);
            if (idxA !== idxB) {
                // console.log("Comparing on presence group - returning");
                return idxA - idxB;
            }
        }

        // Second by power level
        if (memberA.powerLevel !== memberB.powerLevel) {
            // console.log("Comparing on power level - returning");
            return memberB.powerLevel - memberA.powerLevel;
        }

        // Third by last active
        if (this._showPresence && userA.getLastActiveTs() !== userB.getLastActiveTs()) {
            // console.log("Comparing on last active timestamp - returning");
            return userB.getLastActiveTs() - userA.getLastActiveTs();
        }

        // Fourth by name (alphabetical)
        const nameA = memberA.name[0] === '@' ? memberA.name.substr(1) : memberA.name;
        const nameB = memberB.name[0] === '@' ? memberB.name.substr(1) : memberB.name;
        // console.log(`Comparing userA_name=${nameA} against userB_name=${nameB} - returning`);
        return nameA.localeCompare(nameB);
    }

    onSearchQueryChanged = (searchQuery) => {
        this.setState({
            isSearchActive: searchQuery !== '',
            searchQuery,
            ...this._filterMembers(this.state.members, searchQuery, this.state.selectedTenants),
        });
    }

    _onPending3pidInviteClick = inviteEvent => {
        dis.dispatch({
            action: 'view_3pid_invite',
            event: inviteEvent,
        });
    }

    _filterMembers = (members, query, selectedTenants = []) => {
        if (!members || !members.length) {
            return { filteredJoinedMembers: [], filteredInvitedMembers: [] };
        }

        const filteredJoinedMembers = [];
        const filteredInvitedMembers = [];

        for (let i = 0; i < members.length; i++) {
            const member = members[i];
            const memberTenant = member.userId.split(/:(.*?)\./)[1];

            if (selectedTenants.length && !selectedTenants.includes(memberTenant)) continue;

            if (query) {
                query = query.toLowerCase();
                const matchesName = member.name.toLowerCase().indexOf(query) !== -1;
                const matchesId = member.userId.toLowerCase().indexOf(query) !== -1;

                if (!matchesName && !matchesId) {
                    continue;
                }
            }

            if (member.membership === 'invite') {
                filteredInvitedMembers.push(member);
            } else if (member.membership === 'join') {
                filteredJoinedMembers.push(member);
            }
        }

        if (!selectedTenants.length) {
            filteredInvitedMembers.push(...this._getPending3PidInvites());
        }

        return { filteredJoinedMembers, filteredInvitedMembers };
    }

    _getPending3PidInvites = () => {
        // include 3pid invites (m.room.third_party_invite) state events.
        // The HS may have already converted these into m.room.member invites so
        // we shouldn't add them if the 3pid invite state key (token) is in the
        // member invite (content.third_party_invite.signed.token)
        const room = MatrixClientPeg.get().getRoom(this.props.roomId);

        if (room) {
            return room.currentState.getStateEvents('m.room.third_party_invite').filter(function(e) {
                if (!isValid3pidInvite(e)) return false;

                // discard all invites which have a m.room.member event since we've
                // already added them.
                const memberEvent = room.currentState.getInviteForThreePidToken(e.getStateKey());
                if (memberEvent) return false;
                return true;
            });
        }
    }

    _makeMemberTiles = (members, membership) => {
        const memberList = members.map((m) => {
            if (m.userId) {
                m.name = UserStore.getDisambiguatedNameWithEmail(m.userId, m.name);
                return (
                    <MemberTile key={m.userId} member={m} ref={m.userId} showPresence={this._showPresence} />
                );
            } else {
                return <EntityTile key={m.getStateKey()}
                    name={m.getContent().display_name}
                    suppressOnHover={true}
                    onClick={() => this._onPending3pidInviteClick(m)}
                />;
            }
        });

        // XXX: surely this is not the right home for this logic.
        // Double XXX: Now it's really, really not the right home for this logic:
        // we shouldn't even be passing in the 'membership' param to this function.
        // Ew, ew, and ew.
        // Triple XXX: This violates the size constraint, the output is expected/desired
        // to be the same length as the members input array.
        //
        // For now we don't want to display 3pid invites when filtering per tenant is enabled
        // since it's not possible to directly map email adresses to tenant (this needs an enhancement
        // of the current behaviour).
        return memberList;
    }

    _getChildrenJoined = (start, end) => {
        return this._makeMemberTiles(this.state.filteredJoinedMembers.slice(start, end));
    }

    _getChildCountJoined = () => {
        return this.state.filteredJoinedMembers.length;
    }

    _getChildrenInvited = (start, end) => {
        return this._makeMemberTiles(this.state.filteredInvitedMembers.slice(start, end), 'invite');
    }

    _getChildCountInvited = () => {
        // For now we don't want to display 3pid invites when filtering per tenant is enabled
        // since it's not possible to directly map email adresses to tenant (this needs an enhancement
        // of the current behaviour).
        const { filteredInvitedMembers, selectedTenants } = this.state;
        if (selectedTenants.length) return filteredInvitedMembers.length;
        else return filteredInvitedMembers.length;
    }

    _getTotalMembers = () => {
        const cli = MatrixClientPeg.get();
        const room = cli.getRoom(this.props.roomId);

        if (!room) return 0;

        return room.getJoinedMemberCount();
    }

    onInviteButtonClick = () => {
        if (MatrixClientPeg.get().isGuest()) {
            dis.dispatch({ action: 'require_registration' });
            return;
        }

        // call AddressPickerDialog
        dis.dispatch({
            action: 'view_invite',
            roomId: this.props.roomId,
        });
    }

    render() {
        const { loading,
            truncateAtInvited, truncateAtJoined,
            companies, selectedTenants,
            isHintDisplayed, hintStyle,
            hasRequiredPower, totalPossibleMembers,
            e2eMaxMembers, isSearchActive } = this.state;
        if (loading) {
            return <div className="mx_MemberList"><Spinner /></div>;
        }

        const { roomId } = this.props;
        const cli = MatrixClientPeg.get();
        const room = cli.getRoom(roomId);
        const dmRoomMap = DMRoomMap.shared();
        const isDirectChat = dmRoomMap.getUserIdForRoomId(roomId);
        const isInviteDisabled = cli.isRoomEncrypted(roomId) && e2eMaxMembers && totalPossibleMembers >= e2eMaxMembers;

        let inviteButton;
        if (room && room.getMyMembership() === 'join' && !isDirectChat && hasRequiredPower) {
            inviteButton = <HintedComponent
                flag='has_shown_invite_members_button'
                isHintDisplayed={isHintDisplayed}
                hintInfo={{
                    title: _t('Add your colleagues in a conversation'),
                    description: _t('Enter the email address or the name and surname of your colleague in the search bar.'),
                }}
                hintStyle={hintStyle}
                hintArrow={{
                    isDisplayed: true,
                    position: ARROW_POS.TOP_RIGHT,
                }}
            >
                <MemberInvite
                    roomId={roomId}
                    isDisabled={isInviteDisabled}
                    expectedNumberOfMembers={totalPossibleMembers}
                    maxMembers={e2eMaxMembers}
                />
            </HintedComponent>;
        }

        let invitedHeader;
        let invitedSection;
        if (this._getChildCountInvited() > 0) {
            invitedHeader =
                <div className="header" >
                    <div className="title">
                        {_t('Pending')}
                    </div>
                    <div className="count">
                        {`(${this._getChildCountInvited()})`}
                    </div>
                </div>;
            invitedSection = <TruncatedList className="mx_MemberList_section mx_MemberList_invited"
                                            truncateAt={truncateAtInvited}
                                            createOverflowElement={this._createOverflowTileInvited}
                                            getChildren={this._getChildrenInvited}
                                            getChildCount={this._getChildCountInvited}
            />;
        }
        return (
            <div className="mx_MemberList">
                <div className="header">
                    <div className="title">
                        {_t('Members')}
                    </div>
                    <div className="count">
                        {`(${this._getTotalMembers()})`}
                    </div>
                </div>
                {!isDirectChat &&
                <MemberListTenantFilter
                    companies={companies}
                    onCompanyClick={this._onCompanyClick}
                />
                }
                <SearchBox className="mx_MemberList_query mx_textinput_icon mx_textinput_search"
                           placeholder={_t('Filter room members')}
                           onSearch={this.onSearchQueryChanged}
                           type="right"
                           />
                <div className='mx_searchResults'>
                {
                    isSearchActive
                        ? _t(
                            'Search results %(searchResultsCount)s',
                            { searchResultsCount: this.state.filteredJoinedMembers.length },
                        )
                        : ''
                 }
                </div>
                {inviteButton}
                <AutoHideScrollbar>
                    <div className="mx_MemberList_wrapper">
                        <TruncatedList className="mx_MemberList_section mx_MemberList_joined"
                                       truncateAt={truncateAtJoined}
                                       createOverflowElement={this._createOverflowTileJoined}
                                       getChildren={this._getChildrenJoined}
                                       getChildCount={this._getChildCountJoined} />
                    </div>
                    {invitedHeader}
                    <div className="mx_MemberList_wrapper">
                        {invitedSection}
                    </div>
                </AutoHideScrollbar>
                {selectedTenants.length > 0 &&
                <div className="reset-filters">
                    <a onClick={this._onResetFiltersClick}>{_t('Reset filters')}</a>
                </div>
                }
            </div>
        );
    }
}
