import React from 'react';
import PropTypes from 'prop-types';

import { _t } from '../../../../../languageHandler';

const ConferencePlanInvitedPills = (props) => {
    const {
        membersToInvite,
        numberOfInvitedMembers,
        maxMembersBasedOnConference,
        calendarConferenceType,
        onUnInviteUser,
    } = props;

    const renderInvitedMember = (item, index) => {
        const { name } = item.member;
        if (item.invited) {
            return (
                <div
                    className='mx_ConferencePlanDialog inviteesList_invitedMembersPillContainer'
                    key={`invitedMember-${index}`}
                >
                    <span className='mx_ConferencePlanDialog inviteesList_invitedMembersPillName'>{name}</span>
                    <div
                        onClick={onUnInviteUser(index)}
                        className='mx_ConferencePlanDialog inviteesList_invitedMembersPillCancelImage'
                    />
                </div>
            );
        }
        return null;
    };

    return (
        <div className='mx_ConferencePlanDialog_pillsContainer'>
            <div className='mx_ConferencePlanDialog_invitedPillsInnerContainer'>
                {membersToInvite.map(renderInvitedMember)}
            </div>
            <span className='mx_ConferencePlanDialog_invitedMembersTrack'>
                {`${numberOfInvitedMembers}/${maxMembersBasedOnConference}` +
                    `${numberOfInvitedMembers >= maxMembersBasedOnConference
                        ? " - " + _t(
                            "You have reached the member limit for a %(conferenceType)s.",
                        { conferenceType: calendarConferenceType === 'Audio'
                            ? _t("audioconference")
                            : _t("videoconference"),
                        })
                    : ''}`
                }
            </span>
        </div>
    );
};

ConferencePlanInvitedPills.propTypes = {
    membersToInvite: PropTypes.array.isRequired,
    numberOfInvitedMembers: PropTypes.number.isRequired,
    maxMembersBasedOnConference: PropTypes.number.isRequired,
    calendarConferenceType: PropTypes.string.isRequired,
    onUnInviteUser: PropTypes.func.isRequired,
};

export default ConferencePlanInvitedPills;
