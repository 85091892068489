import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MatrixClientPeg from '../../../MatrixClientPeg';
import { _t } from '../../../languageHandler';
import dis from '../../../dispatcher';
import HintManager from '../../../HintManager';

class TimelineMessageSuggestions extends React.Component {
    constructor(props) {
        super();

        const { historyVisibility, otherMemberName } = props;
        const thanksText = historyVisibility === 'joined' ? _t('Thank you for the invitation') : _t('Thank you');
        const helloText = otherMemberName ? _t('Hi %(otherMemberName)s', { otherMemberName }) : _t('Hi everyone');

        this.state = {
            closed: false,
            hover: false,
            buttonsText: {
                thanks: thanksText,
                hello: helloText,
                ok: _t('Understood'),
            },
        };
    }

    componentDidMount(): void {
        this.dispatcherRef = dis.register(this.onAction);
    }

    componentWillUnmount(): void {
        dis.unregister(this.dispatcherRef);
    }

    onAction = (payload) => {
        if (payload.action === 'message_sent' && payload.roomId === this.props.roomId) {
            this.onClose();
        }
    };

    onHover = () => {
        this.setState({ hover: true });
    };

    onClose = () => {
        const { roomId, isCurrentlyHinted, closeHint } = this.props;

        this.setState({ closed: true });
        isCurrentlyHinted && closeHint();
        MatrixClientPeg.get().setMessageSentFlag(roomId, true);
    };

    onSuggestionClick = (ev) => {
        const { buttonsText } = this.state;
        const { roomId } = this.props;

        const content = {
            msgtype: 'm.text',
            body: buttonsText[ev.target.id],
        };

        MatrixClientPeg.get().sendMessage(roomId, content).then(this.onClose);
        HintManager.checkPendingHints();
    };

    render() {
        const { hover, closed, buttonsText } = this.state;
        const { thanks, hello, ok } = buttonsText;
        const { historyVisibility, isCurrentlyHinted } = this.props;

        const className = classNames(
            'mx_TimelineMessageSuggestions',
            { 'mx_TimelineMessageSuggestions_closed': closed },
        );

        const buttonsClassName = classNames(
            'buttons',
            { 'hinted-buttons': isCurrentlyHinted },
        );

        const displayCloseLink = hover && !isCurrentlyHinted;
        return (<div className={className}>
            <div className={buttonsClassName}>
                <div onMouseEnter={this.onHover} onClick={this.onSuggestionClick} id="hello">{hello}</div>
                <div onMouseEnter={this.onHover} onClick={this.onSuggestionClick} id="thanks">{thanks}</div>
                {(historyVisibility !== 'joined') && <div onMouseEnter={this.onHover} onClick={this.onSuggestionClick} id="ok">{ok}</div>}
            </div>
            { displayCloseLink && <div className="close" onClick={this.onClose}>
                {_t("Close")}
            </div>}
        </div>);
    }
}

TimelineMessageSuggestions.propTypes = {
    historyVisibility: PropTypes.string.isRequired,
    isCurrentlyHinted: PropTypes.bool,
    closeHint: PropTypes.func,
    otherMemberName: PropTypes.string,
    roomId: PropTypes.string.isRequired,
};

export default TimelineMessageSuggestions;
