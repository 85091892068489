import React from 'react';

import { _t } from '../../../../languageHandler';

import BaseDialog from '../../dialogs/BaseDialog';
import AccessibleButton from '../AccessibleButton';

const ConferencePlanDialogInviteFailed = (props) => {
    const {
        onDownloadCalendarFile,
        onFinished,
    } = props;
    return (
        <BaseDialog
            onFinished={onFinished}
            className="mx_ConferencePlanDialog inviteSuccessful"
            title=""
        >
            <div className='mx_ConferencePlanDialog alignCenter'>
                <div className='mx_ConferencePlanDialog errorWhileSendingImage' />
                <span className='mx_ConferencePlanDialog errorWhileSendingTextHeading'>
                    {_t("Error while sending")}
                </span>
                <span className='mx_ConferencePlanDialog errorWhileSendingTextDescription'>
                    {_t("An error has occurred, please try again later or download the invitation file.")}</span>
                <AccessibleButton
                    className={"mx_ConferencePlanDialog"}
                    onClick={onDownloadCalendarFile}
                    kind="primary"
                >
                    {_t("Download invitation")}
                </AccessibleButton>
                <span className='mx_ConferencePlanDialog_closeErrorModal' onClick={onFinished}>
                    {_t("Close")}
                </span>
            </div>
        </BaseDialog>
    );
};

export default ConferencePlanDialogInviteFailed;
