import { isEmpty } from "lodash";
import PlatformPeg from "./PlatformPeg";
import Modal from "./Modal";
import PromoteNewFeaturesDialog from "./components/views/dialogs/promoteNewFeatures/PromoteNewFeaturesDialog";
import MatrixClientPeg from "./MatrixClientPeg";

class PromoteNewFeatures {
    constructor(newFeaturesData) {
        this.newFeatureData = newFeaturesData;
        this.version = null;
    }

    init = async () => {
        try {
            this.version = await PlatformPeg.get().getAppVersion();
        } catch (e) {
            console.error('Error getting current version: ', e);
        }
    }

    getVersionWithoutHash = (version) => {
        return (version || '').split('-')[0];
    };

    convertVersionToNumber = (version) => {
        return parseInt(this.getVersionWithoutHash(version).split('.').join('') || '0');
    };

    shouldDisplayDialogBasedOnVersion = () => {
        const accountDataVariable = PlatformPeg.get().isDesktop() ? 'prev_dc_version' : 'prev_wc_version';
        const lastUsedVersionEv = MatrixClientPeg.get().getAccountData(accountDataVariable);
        const lastUsedVersionNormalized = lastUsedVersionEv
            ? this.convertVersionToNumber(lastUsedVersionEv.getContent().version)
            : 0;
        const normalizedVersion = this.convertVersionToNumber(this.version);
        return normalizedVersion > lastUsedVersionNormalized;
    };

    containsSlides = () =>{
        const { [this.getVersionWithoutHash(this.version)]: versionData } = this.newFeatureData || {};
        return !isEmpty(versionData?.content);
    }

    show = () => {
        const versionWithoutHash = this.getVersionWithoutHash(this.version);
        const { [versionWithoutHash]: versionData, bugFixes } = this.newFeatureData;
        const accountDataVariable = PlatformPeg.get().isDesktop() ? 'prev_dc_version' : 'prev_wc_version';
        const className = 'new_features_dialog';
        Modal.createDialog(PromoteNewFeaturesDialog, {
            className,
            onFinished: () => {
                MatrixClientPeg.get().setAccountData(accountDataVariable, { version: this.version });
                },
            slides: versionData ? versionData.content : bugFixes,
        }, className);
    }

    showIfNeeded = async (forceDisplay = false) => {
        if (!this.version) {
            await this.init();
        }
        const shouldBeDisplayed = this.shouldDisplayDialogBasedOnVersion();
        const containsSlides = this.containsSlides();

        if (forceDisplay) {
            this.show();
            return forceDisplay;
        }

        if (containsSlides && shouldBeDisplayed) this.show();
        return containsSlides && shouldBeDisplayed;
    };
}

export default PromoteNewFeatures;
