import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import dis from "../../../dispatcher";

class ToastElement extends Component {
    constructor(props) {
        super(props);
        this.state = { show: false };
    }

    componentDidMount() {
        const { data: { autoClose: autoCloseProp, audioElementId, hideCloseButton } } = this.props;
        let autoClose = autoCloseProp;
        if (!autoClose && hideCloseButton) autoClose = 4000;
        if (autoClose) {
            this.autoCloseRef = setTimeout(this.handleClose, autoClose + 600);
        }
        this.showRef = setTimeout(() => this.setState({ show: true }));
        if (audioElementId) {
            const audio = document.getElementById(audioElementId);
            if (audio) {
                audio.load(); // audio.currentTime = 0;
                audio.play();
            }
        }
        this.dispatcherRef = dis.register(this.onAction);
    }

    componentDidUpdate(prevProps, { show: prevShow}) {
        const { show } = this.state;
        const { onClose } = this.props;
        if (prevShow && !show) setTimeout(onClose, 300);
    }

    componentWillUnmount() {
        if (this.autoCloseRef) clearTimeout(this.autoCloseRef);
        if (this.showRef) clearTimeout(this.showRef);
        dis.unregister(this.dispatcherRef);
    }

    onAction = (payload) => {
        const { id, listType } = this.props.data;
        if (payload.action === 'close_toast'
            && !this.closing
            && payload.value.id === id
            && listType === payload.value.listType
        ) {
            this.closing = true;
            this.handleClose();
        }
    }

    handleClose = () => {
        if (this.autoCloseRef) clearTimeout(this.autoCloseRef);
        this.setState({ show: false });
    }

    render() {
        const { data: { message, title, icon, hideCloseButton = false } } = this.props;
        const { show } = this.state;
        return (
            <div className={cx('toast', { show, withIcon: !!icon })}>
                {hideCloseButton ? null : <div className="toast__close" onClick={this.handleClose} />}
                {title ? <div className="toast__title">{title}</div> : null}
                <div className="toast__description_container">
                    {icon ? <img src={icon} alt='icon' /> : null}
                    {message ? <div className="toast__description">{message}</div> : null}
                </div>
            </div>
        );
    }
}

ToastElement.propTypes = {
    onClose: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
};

export default ToastElement;
