export function fetchPureBlobWithToken(url) {
    const token = localStorage.getItem('mx_access_token');
    return Promise
        .resolve(fetch(url, {
            method: 'GET',
            headers: new Headers({
                Authorization: 'Bearer ' + token,
            }),
        }))
        .then(function(response) {
            if (response.status !== 200) {
                return null;
            } else return response.arrayBuffer();
        });
}

export function fetchUrlWithToken(url) {
    return fetchUrlWithTokenAsBlob(url).then(function(blob) {
            if (blob) {
                const type = (blob.type !== 'image/svg+xml') ? blob.type : 'application/octet-stream';
                const newBlob = new Blob([blob], {type});
                return URL.createObjectURL(newBlob);
            } else return null;
        });
}

export function fetchUrlWithTokenAsBlob(url) {
    const token = localStorage.getItem('mx_access_token');
    return Promise
        .resolve(fetch(url, {
            method: 'GET',
            headers: new Headers({
                Authorization: 'Bearer ' + token,
            }),
        }))
        .then(function(response) {
            if (response.status !== 200) {
                return null;
            } else return response.blob();
        })
        .then(function(blob) {
            return blob;
        });
}

export function getMxcFromUrl(url) {
    const lastIndex = url.lastIndexOf('?');
    return lastIndex > -1 ?
        url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('?')) :
        url.slice(url.lastIndexOf('/') + 1);
}

export function shouldSaveTheBlob(url) {
    // we do not store blobs under a specific size to avoid
    // resolution / blur issues
    // returns the size if it's ok to store the blob associated to the given url
    let size = url.slice(url.indexOf('width') + 6, url.indexOf('&'));
    size = parseInt(size, 10);
    if (size >= 36) {
        return size;
    } else {
        return null;
    }
}
