import React from 'react';
import MatrixClientPeg from '../../../MatrixClientPeg';
import Modal from '../../../Modal';
import SurveyDialog from '../dialogs/conference/SurveyDialog';

//TODO: Remove this and the survey dialog if not needed

export default class extends React.Component {
    trackUses = ({key, newValue}) => {
        if (key === 'callEnds' && newValue === 'true') {
            const data = MatrixClientPeg.get().getAccountData('citadel.conference');
            const { event: { content: { citadel_meet_use_count = 0, has_seen_survey = false }}} = data;
            if (citadel_meet_use_count >= 10 && !has_seen_survey ) {
                Modal.createTrackedDialog('Survey Dialog', '', SurveyDialog);
                MatrixClientPeg.get().setAccountData('citadel.conference', {
                    ...data.event.content,
                    ['has_seen_survey']: true,
                });
            }
        }
    }

    componentDidMount() {
        window.addEventListener('storage', this.trackUses);
    }

    componentWillUnmount() {
        window.removeEventListener('storage', this.trackUses);
    }

    render() {
        return null;
    }
}
