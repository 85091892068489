import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import MatrixClientPeg from '../../../../MatrixClientPeg';
import { _t } from '../../../../languageHandler';
import { getSanitizedContentWithFQDNInjected } from "../../../../HtmlUtils";
import ffffff from "../../../../../res/img/ffffff.png";

export default class NewsDialogContent extends Component {
    static displayName = "NewsDialogContent";

    static propTypes = {
        content: PropTypes.string.isRequired,
        eventId: PropTypes.string,
        imageUrl: PropTypes.string,
        onGoBack: PropTypes.func,
        onPostNews: PropTypes.func,
        subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
        title: PropTypes.string.isRequired,
    };

    static defaultProps = {
        content: '',
        imageUrl: ffffff,
        title: '',
        subtitle: '',
    };
    state = {};
    scrollPercent = 0;
    duration = 0;

    componentDidMount() {
        const { eventId, content, title } = this.props;
        if (eventId) {
            document.querySelector('.news_body').addEventListener('scroll', this.setScrollPercent);
            this.setScrollPercent();
            this.intervalRef = setInterval(() => this.duration += 1, 1000);
        }

        const sanitizedContent = getSanitizedContentWithFQDNInjected(content);
        const sanitizedTitle = getSanitizedContentWithFQDNInjected(title);

        this.setState({ content: sanitizedContent, title: sanitizedTitle.replace(/&amp;/g, '&') });
    }

    componentWillUnmount() {
        const { eventId } = this.props;

        document.querySelector('.news_body').removeEventListener('scroll', this.setScrollPercent);
        if (eventId) this.sendStats();
        if (this.intervalRef) clearInterval(this.intervalRef);
    }

    sendStats = () => {
        const { eventId } = this.props;

        MatrixClientPeg.get().trackUserAction({
            formId: 'commMana',
            version: 1,
            event_id: eventId,
            action: 'open',
            scrollPercent: this.scrollPercent === null ? 'null' : this.scrollPercent,
            duration: this.duration,
        });
    };

    setScrollPercent = () => {
        const { scrollHeight, clientHeight, scrollTop } = document.querySelector('.news_body');
        if (scrollHeight === clientHeight) {
            this.scrollPercent = null;
        } else {
            const currentScrollPercent = Math.floor(scrollTop/(scrollHeight - clientHeight) * 100);

            if (this.scrollPercent < currentScrollPercent) this.scrollPercent = currentScrollPercent;
        }
    };

    render() {
        const { imageUrl, onGoBack, onPostNews, readCountDisplay, subtitle } = this.props;
        const { content, title } = this.state;

        let footer;
        const isPreview = !!onPostNews;

        if (onPostNews) {
            footer = (
                <Fragment>
                    <button onClick={onGoBack}>{_t('Back')}</button>
                    <button
                        className="news_send_btn"
                        onClick={onPostNews}
                        autoFocus={true}
                    >
                        {_t('Send')}
                    </button>
                </Fragment>
            );
        }

        return (
            <div className="news_container">
                <div className={cx('news_header', { 'is_preview': isPreview })}>
                    <div className="news_title">
                        {title || ''}
                    </div>
                    <div className="news_subtitle">{subtitle}</div>
                    <div className="news_subtitle">{readCountDisplay}</div>

                </div>
                <div className={cx('news_body', { 'is_preview': isPreview })}>
                    <div className="news_image_container">
                        <img src={imageUrl} ref="image" />
                    </div>
                    <div className="news_message_body" dangerouslySetInnerHTML={{ __html: content }} />
                    <div className={cx('news_bottom_mask', { 'is_preview': isPreview })} />
                </div>
                <div className={cx('news_footer', { 'is_preview': isPreview })}>
                    {footer}
                </div>
            </div>
        );
    }
}
