import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class CitadelAccordionItem extends Component {
    onClick = () => {
        this.props.onClick(this.props.id);
    };

    renderHeader = () =>
        <div onClick={this.onClick} className="item-header-container">
            <div className="item-header">
                <div className="item-arrow" />
                <div className="item-label">{this.props.label}</div>
            </div>
        </div>;

    renderContent = () =>
        <div className="item-content">
            {this.props.children}
        </div>;

    render() {
    const classes = classNames('mx_CitadelAccordionItem', {
        'is-open': this.props.isOpen,
    });
        return (
            <div className={classes}>
                {this.renderHeader()}
                {this.props.isOpen && this.renderContent()}
            </div>
        );
    }
}

CitadelAccordionItem.propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default CitadelAccordionItem;
