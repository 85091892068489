import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import UserStore from '../../../../stores/UserStore';
import MatrixClientPeg from '../../../../MatrixClientPeg';
import BaseDialog from '../BaseDialog';
import * as MegolmExportEncryption from '../../../../utils/MegolmExportEncryption';

import { fetchPureBlobWithToken } from '../../../../utils/MediaUtils';
import { validateE2eToken, hashKeyWithMethod, getCorrectHashFunction } from '../../../../utils/CitadelUtils';
import CitadelCodeInput from '../../elements/CitadelCodeInput';
import CitadelButton from '../../elements/CitadelButton';
import { _t } from '../../../../languageHandler';

import successReceived from '../../../../../res/img/e2e/share-key-success-received.svg';

const ExternalReceiverCodeDialog = ({
    onFinished,
    mxc,
    secret,
    sender,
    senderDisplayName,
    eventId,
    roomId,
    timestamp,
    renderWelcomeMessage = true,
    }) => {
    const [firstTimeEnter, setFirstTimeEnter] = useState(renderWelcomeMessage);
    const cli = useMemo(() => MatrixClientPeg.get(), []);
    const serverConfig = cli.getServerConfig();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [token, setToken] = useState();
    const [tokenError, setTokenError] = useState();
    const [nrOfDownloadAttempts, setNrOfDownloadAttempts] = useState(0);
    const { email } = UserStore.getMe(); // my email address
    const isTokenValid = validateE2eToken(token);
    const deviceId = localStorage.getItem("mx_device_id");
    const room = MatrixClientPeg.get().getRoom(roomId);

    const onTokenChange = (token) => {
        setToken(token);
        setTokenError(false);
    };

    const startImport = async (mxc, passphrase, hashedToken) => {
        const cli = MatrixClientPeg.get();
        const url = cli.mxcUrlToHttpForE2eKeySharing(mxc);
        const arrayBuffer = await fetchPureBlobWithToken(`${url}/${hashedToken}`);
        const keys = await MegolmExportEncryption.decryptMegolmKeyFile(arrayBuffer, passphrase);
        await cli.importRoomKeys(JSON.parse(keys));
    };

    const checkIfRequestExpired = () => {
        const timestampForRequest = Date.parse(timestamp);
        if (Date.now() < (timestampForRequest + serverConfig.e2e.expiration_duration)) {
            onFinished(false, false); // the request has expired
        }
    };

    const submitCode = useCallback(async () => {
        try {
            checkIfRequestExpired();
            setIsSubmitting(true);
            const hashedToken = await hashKeyWithMethod(token, getCorrectHashFunction(serverConfig.e2e.hash_function));
            await startImport(mxc, secret, hashedToken);
            setIsSubmitting(false);
            await cli.deleteRoomKeyRequest(roomId, deviceId, eventId);
            MatrixClientPeg.get().trackUserAction({
                formId: 'E2EKeysSharingInRoom',
                version: 1,
                action: 'decrypt2',
                room: roomId,
            });
            const allLaterKeysToDecrypt = JSON.parse(localStorage.getItem("allLaterKeysToDecrypt") || "{}");
            if (allLaterKeysToDecrypt[roomId]) {
                delete allLaterKeysToDecrypt[roomId];
            }
            localStorage.setItem("allLaterKeysToDecrypt", JSON.stringify(allLaterKeysToDecrypt));
            onFinished(true, false);
        } catch (error) {
            if (error.httpStatus === 403 || error.httpStatus === 401) {
                setNrOfDownloadAttempts(nrOfDownloadAttempts + 1);
            }
            setIsSubmitting(false);
            setTokenError(_t("The verification code is invalid."));
            MatrixClientPeg.get().trackUserAction({
                formId: 'E2EKeysSharingInRoom',
                version: 1,
                action: 'error',
                room: roomId,
                step: "decrypt",
                code: error.httpStatus,
                reason: error.message,
            });
        }
    }, [token, sender, mxc]);

    const showNextModal = () => {
        setFirstTimeEnter(false);
        MatrixClientPeg.get().trackUserAction({
            formId: 'E2EKeysSharingInRoom',
            version: 1,
            action: 'decrypt',
            room: roomId,
        });
    };

    const onLaterImport = () => {
        MatrixClientPeg.get().trackUserAction({
            formId: 'E2EKeysSharingInRoom',
            version: 1,
            action: 'later',
            room: roomId,
        });
        const allLaterKeysToDecrypt = JSON.parse(localStorage.getItem("allLaterKeysToDecrypt") || "{}");
        const laterKeysToDecrypt = allLaterKeysToDecrypt[roomId] || [];
        laterKeysToDecrypt.push({ mxc, secret, sender, senderDisplayName, eventId, roomId, timestamp });
        localStorage.setItem("allLaterKeysToDecrypt", JSON.stringify({...allLaterKeysToDecrypt, [roomId]: laterKeysToDecrypt}));
        MatrixClientPeg.get().trackUserAction({
            formId: 'E2EKeysSharingInRoom',
            version: 1,
            action: 'later',
            room: roomId,
        });
        window.dispatchEvent(new Event('storage'));
        onFinished(true, true);
    };

    const onCancelImport = () => {
        MatrixClientPeg.get().trackUserAction({
            formId: 'E2EKeysSharingInRoom',
            version: 1,
            action: 'cancelKeys',
            room: roomId,
        });
        onFinished(true, true);
    };

    if (nrOfDownloadAttempts >= serverConfig.e2e.dl_attempts) {
        onFinished(false, false);
    }

    return (
        <BaseDialog
            className="mx_ExternalReceiverCodeDialog"
            title={firstTimeEnter ? '' : _t('Verify your identity')}
            hasCancel={true}
            onFinished={() => onFinished(true, true)}
            fixedWidth={false}
        >
            {firstTimeEnter ?
                    <div className='mx_ExternalReceiverCodeDialog_welcomeContainer'>
                        <img src={successReceived} alt='image' height={80} />
                        <span className='mx_ExternalReceiverCodeDialog_welcomeModalDescription'>{_t("You received encryption keys from %(userName)s for the room %(roomName)s", { userName: senderDisplayName, roomName: room.name })}</span>
                        <CitadelButton
                            className="mx_ExternalReceiverCodeDialog_confirmButton"
                            text={_t('Start recovering my messages')}
                            onClick={showNextModal}
                        />
                        <span className="mx_ExternalReceiverCodeDialog_cancelButton" onClick={onLaterImport}>{_t('Later')}</span>
                    </div>
                :
                <div className='container'>
                    <span className='description'>{_t("Enter the 8 digit security code sent by email to %(email)s", { email: email })}</span>
                    <CitadelCodeInput
                        fields={8}
                        onChange={onTokenChange}
                        value={token}
                        label={_t('Security code')}
                        groupSize={4}
                        error={tokenError}
                        type="number"
                    />
                    <div className='mx_ExteranlReceiverCodeDialog_buttonsContainer'>
                        <span className='cancelButton' onClick={onCancelImport}>
                            {_t('Cancel')}
                        </span>
                        <CitadelButton
                            text={_t("Import the keys")}
                            onClick={submitCode}
                            isDisabled={!!tokenError || isSubmitting || !isTokenValid}
                        />
                    </div>
                </div>}
        </BaseDialog>
    );
};

ExternalReceiverCodeDialog.propTypes = {
    onFinished: PropTypes.func,
};

ExternalReceiverCodeDialog.defaultProps = {
    onFinished: () => {},
};

export default ExternalReceiverCodeDialog;
