import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import MatrixClientPeg from '../../MatrixClientPeg';
import { _t, getCurrentLanguage } from '../../languageHandler';
import classNames from 'classnames';
import { noop } from 'lodash';
import {
    canSendEvent,
    getHistoryVisibility,
    getJoinRules,
    getPowerLevels,
    getRoomName,
    getRoomTopic,
    isDirectMessageRoom,
    isEncryptedRoom,
    isFederatedRoom,
    shouldHideServiceMessages,
} from '../../Rooms';
import * as RoomNotifs from '../../RoomNotifs';
import LabelledToggleSwitch from '../views/elements/LabelledToggleSwitch';
import Modal from '../../Modal';
import QuestionDialog from '../views/dialogs/QuestionDialog';
import CitadelRoomAvatar from '../views/avatars/CitadelRoomAvatar';
import CitadelEditableText from '../views/elements/CitadelEditableText';
import CitadelAccordion from './accordion/CitadelAccordion';
import CitadelAccordionSection from './accordion/CitadelAccordionSection';
import CitadelToggle from '../views/elements/CitadelToggle';
import dis from '../../dispatcher';
import {
    powerLevelsDescriptors,
    permissionRulesDescriptors,
    PowerLevelToggle,
} from '../views/room_settings/PowerLevelSettings';
import { NotificationToggle } from '../views/room_settings/NotificationSettings';
import ExportE2eKeysDialog from '../../async-components/views/dialogs/ExportE2eKeysDialog';
import CopyTextButton from '../views/elements/CopyTextButton';
import CitadelButton from "../views/elements/CitadelButton";
import { isValid3pidInvite } from '../../RoomInvite';
import GenericToolTip from './GenericToolTip';
import { isRoomOnHomeServer } from '../../utils/CitadelUtils';
import RoomExportDialog from "../views/dialogs/roomExport/RoomExportDialog";
import RoomExportStore from "../../stores/RoomExportStore";

const FAQLinkEN = 'https://support.citadel.team/kb/guide/en/end-to-end-encryption-information-hU9Xi8mfcU/Steps/33982';
const FAQLinkFR = 'https://support.citadel.team/kb/guide/fr/informations-sur-le-chiffrement-de-bout-en-bout-hU9Xi8mfcU/Steps/33982';

class RoomSettingsPanel extends React.Component {
    constructor(props) {
        super(props);

        const room = MatrixClientPeg.get().getRoom(props.roomId);
        this.state = {
            room,
            roomName: getRoomName(room),
            roomTopic: getRoomTopic(room),
            isFederatedRoom: isFederatedRoom(room),
            isDmRoom: isDirectMessageRoom(room).isDmRoom,
            isEncrypted: isEncryptedRoom(room),
            isPublicRoom: (getJoinRules(room) === 'public'),
            historyVisibility: getHistoryVisibility(room),
            powerLevels: getPowerLevels(room),
            notificationState: RoomNotifs.getRoomNotifsState(props.roomId),
            editableSections: {
                name: canSendEvent(room, 'm.room.name'),
                topic: canSendEvent(room, 'm.room.topic'),
                encryption: canSendEvent(room, 'm.room.encryption'),
                joinRules: canSendEvent(room, 'm.room.join_rules'),
                historyVisibility: canSendEvent(room, 'm.room.history_visibility'),
                powerLevels: canSendEvent(room, 'm.room.power_levels'),
                delete: false,
            },
            e2eMaxMembers: null,
        };
    }

    async componentDidMount() {
        const { roomId } = this.props;
        const cli = MatrixClientPeg.get();
        const serverConfig = cli.getServerConfig();
        cli.on('RoomState.events', this._onRoomStateEvent);
        cli.on('accountData', this._onAccountData);
        const {
            e2e: { users_limit: e2eMaxMembers },
            shared: homeServerShared,
        } = await cli.getServerConfig();
        const room = cli.getRoom(roomId);
        const me = room.getMember(localStorage.getItem('mx_user_id'));
        const hasRequiredPower = me.powerLevel === 100;
        const hideServiceMessages = shouldHideServiceMessages(room);
        const isExportRoomDisabled = typeof serverConfig.export_room === 'boolean' ? !serverConfig.export_room : false;

        this.setState(prevState => ({
            editableSections: {
                ...prevState.editableSections,
                name: hasRequiredPower,
                topic: hasRequiredPower,
                encryption: hasRequiredPower,
                joinRules: hasRequiredPower,
                delete: hasRequiredPower,
            },
            e2eMaxMembers,
            homeServerShared,
            hideServiceMessages,
            isExportRoomDisabled,
        }));

        this.dispatcherRef = dis.register(this.onAction);
    }

    componentWillUnmount() {
        const cli = MatrixClientPeg.get();
        if (cli) {
            cli.removeListener('RoomState.events', this._onRoomStateEvent);
            cli.removeListener('accountData', this._onAccountData);
        }
        dis.unregister(this.dispatcherRef);
    }

    onAction = (payload) => {
        if (payload.action === 'setting_updated' && ['enabled_public_read_only_rooms',
            'enabled_private_rooms'].includes(payload.settingName)) {
                const { roomId } = this.props;
                const room = MatrixClientPeg.get().getRoom(roomId);
                const hideServiceMessages = shouldHideServiceMessages(room);
                this.setState({ hideServiceMessages });
        }
    }

    _onRoomStateEvent = ev => {
        const eventType = ev.getType();
        const eventContent = ev.getContent();

        let stateKey;
        let newValue;
        let powerLevelsHaveChanged = false;
        switch (eventType) {
            case 'm.room.name':
                stateKey = 'roomName';
                newValue = eventContent.name;
                break;
            case 'm.room.topic':
                stateKey = 'roomTopic';
                newValue = eventContent.topic;
                break;
            case 'm.room.encryption':
                stateKey = 'isEncrypted';
                newValue = true;
                break;
            case 'm.room.history_visibility':
                stateKey = 'historyVisibility';
                newValue = eventContent.history_visibility;
                break;
            case 'm.room.join_rules':
                stateKey = 'isPublicRoom';
                newValue = (eventContent.join_rule === 'public');
                break;
            case 'm.room.power_levels':
                stateKey = 'powerLevels';
                newValue = eventContent;
                powerLevelsHaveChanged = (this.state[stateKey].events_default !== newValue.events_default) ||
                    (this.state[stateKey].invite !== newValue.invite);
                break;
        }

        if (!stateKey) return;

        if (powerLevelsHaveChanged || this.state[stateKey] !== newValue) {
            this.setState({
                [stateKey]: newValue,
            });
        }
        if (powerLevelsHaveChanged) {
            const me = localStorage.getItem('mx_user_id');
            const hasRequiredPower = event.getContent().users[me] >= 50;
            this.setState(prevState => ({
                editableSections: {
                    ...prevState.editableSections,
                    name: hasRequiredPower,
                    topic: hasRequiredPower,
                    encryption: hasRequiredPower,
                    joinRules: hasRequiredPower,
                },
            }));
        }
    };

    _onAccountData = ev => {
        if (ev.getType() === 'm.push_rules') {
            this.setState({
                notificationState: RoomNotifs.getRoomNotifsState(this.props.roomId),
            });
        }
    };

    _onRoomNameEdit = async roomName => {
        if (roomName !== this.state.roomName && roomName.trim().length !== 0) {
            const cli = MatrixClientPeg.get();
            const res = await cli.setRoomName(this.props.roomId, roomName);
            if (res && res.event_id) {
                this.setState({ roomName });
            }
        }
    };

    _onRoomTopicEdit = async roomTopic => {
        if (roomTopic !== this.state.roomTopic) {
            const cli = MatrixClientPeg.get();
            const res = await cli.setRoomTopic(this.props.roomId, roomTopic);
            if (res && res.event_id) {
                this.setState({ roomTopic });
            }
        }
    };

    _onEncryptionChange = () => {
        Modal.createTrackedDialog('Enable encryption', '', QuestionDialog, {
            title: _t('End-to-end encryption'),
            description: _t('This feature makes this conversation more secure. ' +
                'The activation of end-to-end encryption is irreversible.',
            ),
            onFinished: async confirm => {
                if (!confirm) return;
                const { historyVisibility } = this.state;

                this.setState({
                    isEncrypted: true,
                    historyVisibility: 'joined',
                });

                const res = await MatrixClientPeg.get()
                    .sendStateEvent(this.props.roomId, 'm.room.encryption', { algorithm: 'm.megolm.v1.aes-sha2' });

                if (!res || !res.event_id) {
                    this.setState({
                        isEncrypted: false,
                        historyVisibility,
                    });
                }
            },
        });
    };

    _onJoinRuleToggle = (roomType) => async () => {
        const { isEncrypted, isPublicRoom, homeServerShared } = this.state;

        if (roomType === 'public' && (isEncrypted || homeServerShared)) return;

        this.setState({ isPublicRoom: (roomType === 'public') });

        const res = await MatrixClientPeg.get()
            .sendStateEvent(this.props.roomId, 'm.room.join_rules', { join_rule: roomType }, '');

        if (!res || !res.event_id) {
            this.setState({ isPublicRoom });
        } else {
            this._onHistoryVisibilityToggle((roomType === 'public') ? 'world_readable' : 'shared');
        }
    };

    _onHistoryVisibilityToggle = async newHistoryVisibility => {
        const { historyVisibility } = this.state;

        // We might be updating historyVisibility while changing joinRule value, in this case updatingValue is already
        // set to true ...
        this.setState({
            historyVisibility: newHistoryVisibility,
        });

        const res = await MatrixClientPeg.get()
            .sendStateEvent(this.props.roomId, 'm.room.history_visibility', { history_visibility: newHistoryVisibility }, '');

        if (!res || !res.event_id) {
            this.setState({
                historyVisibility,
            });
        }
    };

    _onPermissionToggle = async (permissionRule, powerLevel) => {
        const {
            state: { powerLevels },
            props: { roomId },
        } = this;

        let eventsValue = powerLevels.events_default || permissionRulesDescriptors[permissionRule].defaultValue;
        let inviteValue = powerLevels.invite || permissionRulesDescriptors[permissionRule].defaultValue;

        if (powerLevel) {
            if (permissionRule === 'events_default') {
                eventsValue = powerLevel;
            } else if (permissionRule === 'invite') {
                inviteValue = powerLevel;
            }
        }

        const newPowerLevels = {
            ...powerLevels,
            'events_default': parseInt(eventsValue),
            'invite': parseInt(inviteValue),
        };

        this.setState({
            powerLevels: newPowerLevels,
        });

        const res = await MatrixClientPeg.get()
            .sendStateEvent(roomId, 'm.room.power_levels', newPowerLevels);

        if (!res || !res.event_id) {
            this.setState({
                powerLevels,
            });
        }
    };

    _onNotificationToggle = async newNotificationState => {
        const {
            state: { notificationState },
            props: { roomId },
        } = this;

        this.setState({
            notificationState: newNotificationState,
        });

        RoomNotifs.setRoomNotifsState(roomId, newNotificationState)
            .catch(() => {
                this.setState({
                    notificationState,
                });
            });
    };

    onHideServiceMessagesToggle = (value) => {
        const { roomId } = this.props;
        const currentData = JSON.parse(localStorage.getItem('show_service_messages_for_rooms'));

        localStorage.setItem('show_service_messages_for_rooms', JSON.stringify({...currentData, [roomId]: value}));
        this.setState({ hideServiceMessages: !value });
        dis.dispatch({ action: 'show_service_messages', roomId });
    }

    _onDeleteClick = () => {
        const { roomId } = this.props;

        MatrixClientPeg.get().trackUserAction({
            formId: 'closeRoom',
            version: 1,
            action: 'settings',
            room: roomId,
        });
        // Leave room
        dis.dispatch({
            action: 'delete_room',
            room_id: roomId,
        });
    };

    _onLeaveClick = () => {
        const { isDmRoom } = this.state;
        const { roomId } = this.props;
        MatrixClientPeg.get().trackUserAction({
            formId: 'leaveRoom',
            version: 1,
            action: 'settings',
            room: roomId,
        });
        const modalTitle = isDmRoom ? _t('Are you sure you want to leave this chat?') :
            _t('Are you sure you want to leave this room?');
        const modalDescription = isDmRoom ? _t('You will not be able to receive any more message or access this chat.') :
            _t('You will not be able to receive any more message or access this room.');

        Modal.createTrackedDialog('Leave Room', '', QuestionDialog, {
            title: modalTitle,
            description: modalDescription,
            onFinished: (confirm) => {
                if (!confirm) {
                    return;
                }
                dis.dispatch({
                    action: 'leave_room',
                    room_id: roomId,
                });
            },
        });
    };

    onFinishedExportKeys = (exported, cancelClicked) => {
        if (exported) {
            MatrixClientPeg.get().trackUserAction({
                formId: 'roomMemberE2EKeys',
                version: 1,
                action: 'confirm',
            });
        }
        if (!exported && cancelClicked) {
            MatrixClientPeg.get().trackUserAction({
                formId: 'roomMemberE2EKeys',
                version: 1,
                action: 'cancel2',
            });
        } else if (!exported && !cancelClicked) {
            MatrixClientPeg.get().trackUserAction({
                formId: 'roomMemberE2EKeys',
                version: 1,
                action: 'cancel1',
            });
        }
    }

    onExportKeysError = (error) => {
        MatrixClientPeg.get().trackUserAction({
            formId: 'roomMemberE2EKeys',
            version: 1,
            action: 'error',
            step: 'export',
            code: error.httpStatus || 0,
            reason: error.friendlyText,
        });
    }

    onExportKeys = () => {
        const { isDmRoom } = this.state;
        MatrixClientPeg.get().trackUserAction({
            formId: 'roomMemberE2EKeys',
            version: 1,
            action: 'export',
        });
        Modal.createTrackedDialog('Export E2E Keys', '',
            ExportE2eKeysDialog,
            {
                matrixClient: MatrixClientPeg.get(),
                roomId: this.props.roomId,
                type: isDmRoom ? 'chat' : 'room',
                onFinished: this.onFinishedExportKeys,
                onExportError: this.onExportKeysError,
            },
        );
    }

    renderNameTopicSection = () => {
        const { roomName, roomTopic, editableSections } = this.state;

        return (
            <div className="profile-editable-texts">
                <CitadelEditableText
                    placeholder={_t('Add a name...')}
                    value={roomName}
                    isHeader={true}
                    allowEmptyText={false}
                    editable={editableSections.name}
                    onStopEditing={editableSections.name ? this._onRoomNameEdit : () => {}}
                />
                <CitadelEditableText
                    placeholder={_t('Add a description...')}
                    value={roomTopic}
                    editable={editableSections.topic}
                    onStopEditing={editableSections.topic ? this._onRoomTopicEdit : () => {}}
                />
            </div>
        );
    };
    getNumberOfRoomMembers = () => {
        const room = MatrixClientPeg.get().getRoom(this.props.roomId);
        const members = Object.values(room.currentState.members)
            .filter(member => member.membership === 'join' || member.membership === 'invite');
        const invitations = room.currentState.getStateEvents('m.room.third_party_invite').filter(function(e) {
            if (!isValid3pidInvite(e)) return false;

            // discard all invites which have a m.room.member event since we've
            // already added them.
            const memberEvent = room.currentState.getInviteForThreePidToken(e.getStateKey());
            if (memberEvent) return false;
            return true;
        });
        return members.length + invitations.length;
    }


    renderE2ESection = () => {
        const { isPublicRoom, isEncrypted, editableSections, isDmRoom, e2eMaxMembers } = this.state;
        const e2eLabel = _t('Encryption');
        const e2eStatus = `${_t('End-to-end encryption')} : ${
            isEncrypted ? _t('enabled') : _t('disabled')
        }`;
        const FAQLink = getCurrentLanguage() === 'fr' ? FAQLinkFR : FAQLinkEN;
        const numberOfRoomMembers = this.getNumberOfRoomMembers();
        const isDisabled = isEncrypted || (e2eMaxMembers && numberOfRoomMembers > e2eMaxMembers);

        return (
            <CitadelAccordionSection label={e2eLabel} id="e2e-section">
                <div className={classNames('e2e sub-section', { on: isEncrypted })}>
                    {
                        editableSections.encryption && !isPublicRoom &&
                        <div className="toggle">
                            <LabelledToggleSwitch
                                toggleInFront
                                value={isEncrypted}
                                onChange={this._onEncryptionChange}
                                label={_t('End-to-end encryption')}
                                disabled={isDisabled}
                            />
                        </div>
                    }
                    <div className="description e2e">
                        {!editableSections.encryption && !isPublicRoom && <span>{e2eStatus}</span>}
                        <span>
                            {_t('End-to-end conversation encryption is used to add an extra layer of security.')}
                            {!isDmRoom && e2eMaxMembers && <Fragment>
                                <br></br>
                                {_t('Once activated, you will no longer be able to disable it, and your room will be limited to up to %(maxMembers)s members.', {
                                    maxMembers: e2eMaxMembers,
                                })}
                            </Fragment>}
                        </span>
                        {_t('Would you like to know more? <FAQLink>See our FAQ</FAQLink>',
                            {},
                            { 'FAQLink': (sub) => <a href={FAQLink} target="_blank">{sub}</a> },
                        )}
                    </div>
                    {isEncrypted ?
                        <React.Fragment>
                            <span>
                                {isDmRoom
                                    ? _t('Did your contact lose its chat encryption keys?')
                                    : _t('Did someone lose its room encryption keys')
                                }
                            </span>
                            <CitadelButton
                                className="export_e2e_keys"
                                key="exportKeys"
                                onClick={this.onExportKeys}
                                text={_t('Export encryption keys')}
                            >
                                {_t('Export encryption keys')}
                            </CitadelButton>
                        </React.Fragment>
                        : null
                    }
                </div>
            </CitadelAccordionSection>
        );
    }

    renderJoinRuleOptSection = roomType => {
        const { isPublicRoom, isEncrypted, editableSections, homeServerShared } = this.state;

        const label = (roomType === 'public') ? _t('public') : _t('private');
        const description = (roomType === 'public') ?
            _t('All members of my company can join this room.') :
            _t('Only invited members can join this room.');
        const checked = (roomType === 'public' && isPublicRoom) || (roomType === 'private' && !isPublicRoom);

        const optSectionClassNames = classNames({
            'opt-section': true,
            'editable-opt-section': editableSections.joinRules,
            'selected-opt-section': checked,
        });

        return (
            <div className={optSectionClassNames} key={roomType}>
                {
                    editableSections.joinRules &&
                    <CitadelToggle
                        type="radio"
                        label={label}
                        checked={checked}
                        disabled={roomType === 'public' && (isEncrypted || homeServerShared)}
                        onChange={this._onJoinRuleToggle(roomType)}
                    />
                }
                {
                    (editableSections.joinRules || checked) &&
                    <div className="description">
                        {description}
                    </div>
                }
            </div>
        );
    };

    renderJoinRulesSection = () => {
        const { isPublicRoom, editableSections } = this.state;

        const visibilityLabel = `${_t('Visibility')} : ${isPublicRoom ? _t('public') : _t('private')}`;

        return (
            <CitadelAccordionSection label={visibilityLabel} id="join-rules-section">
                <div className="join-rules sub-section">
                    {this.renderJoinRuleOptSection('public')}
                    {editableSections.joinRules && <div className="divider" />}
                    {this.renderJoinRuleOptSection('private')}
                </div>
            </CitadelAccordionSection>
        );
    };

    renderHistoryVisibilitySection = () => {
        const { historyVisibility: currentValue } = this.state;
        const historyVisibilityDescriptors = {
            'world_readable': _t('The history of the room is visible to all users of my company.'),
            'shared': _t('The history of the room is visible to all users.'),
            'invited': _t('New members will not be able to see messages prior to their invitation.'),
            'joined': _t('New members will not be able to see messages prior to their arrival.'),
        };
        return (
            <CitadelAccordionSection label={_t('History management')} id="history-visibility-section">
                <div className="history-visibility sub-section opt-section">
                    {historyVisibilityDescriptors[currentValue]}
                </div>
            </CitadelAccordionSection>
        );
    };

    renderActivityMessageSection = () => {
        const { hideServiceMessages } = this.state;

        return (
            <CitadelAccordionSection label={_t('Activity Messages')} id="activity-message-section">
                <div className="activity-message sub-section">
                    <div className="toggle">
                        <LabelledToggleSwitch
                            toggleInFront
                            value={!hideServiceMessages}
                            onChange={this.onHideServiceMessagesToggle}
                            label={_t('Show activity messages')}
                        />
                    </div>
                </div>
            </CitadelAccordionSection>
        );
    };

    renderPermissionSection = () => {
        const { powerLevels } = this.state;

        const renderPowerLevelToggle = (permissionRule, powerLevel) => {
            const powerLevelValue = powerLevelsDescriptors[powerLevel].value;
            const permissionLevelValue = (typeof powerLevels[permissionRule] !== 'undefined') ?
                powerLevels[permissionRule].toString() :
                permissionRulesDescriptors[permissionRule].defaultValue;

            return (
                <PowerLevelToggle
                    key={`${permissionRule}-${powerLevel}`}
                    permissionRule={permissionRule}
                    powerLevel={powerLevel}
                    checked={permissionLevelValue === powerLevelValue}
                    onChange={() => {
                        this._onPermissionToggle(permissionRule, powerLevelValue);
                    }}
                />
            );
        };

        return (
            <CitadelAccordionSection label={_t('Permission rules')} id="permissions-section">
                <div className="permissions sub-section">
                    {
                        Object.keys(permissionRulesDescriptors).map(permissionRule => {
                            return (
                                <div className="opt-section" key={permissionRule}>
                                    <div className="description">
                                        {_t(permissionRulesDescriptors[permissionRule].description)}
                                    </div>
                                    <div className="toggles">
                                        {
                                            Object.keys(powerLevelsDescriptors).map(powerLevel => {
                                                return renderPowerLevelToggle(permissionRule, powerLevel);
                                            })
                                        }
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </CitadelAccordionSection>
        );
    };

    renderNotificationSection = () => {
        const { notificationState } = this.state;

        const notificationToggle = notificationLevel => {
            return (
                <NotificationToggle
                    notificationLevel={notificationLevel}
                    checked={notificationLevel === notificationState}
                    onChange={() => this._onNotificationToggle(notificationLevel)}
                />
            );
        };

        return (
            <CitadelAccordionSection label={_t('Messages')} id="notifications-section">
                <div className="notifications sub-section">
                    {notificationToggle(RoomNotifs.ALL_MESSAGES)}
                    {notificationToggle(RoomNotifs.MENTIONS_ONLY)}
                    {notificationToggle(RoomNotifs.MUTE)}
                </div>
            </CitadelAccordionSection>
        );
    };

    exportRoom = () => {
        Modal.createDialog(RoomExportDialog, { roomId: this.state.room.roomId });
    }


    render() {
        const { room, editableSections, isDmRoom, isFederatedRoom, isExportRoomDisabled } = this.state;
        const { roomId } = this.props;
        const leaveButtonText = isDmRoom ? _t('Leave chat') : _t('Leave room');
        const isDeleteRoomDisabled = !isRoomOnHomeServer(roomId);
        const deleteRoomClassName = classNames("delete section", {
            'disabled': isDeleteRoomDisabled,
        });
        const roomLink = `${localStorage.getItem('mx_portal_url')}${window.location.pathname}${window.location.hash}?deeplink=true`;
        const roomExportStatus = RoomExportStore.getRoomExportStatus(room.roomId);
        const roomExportInProgress = ['started', 'pending', 'completed'].includes(roomExportStatus);

        return (
            <div className="mx_RoomSettingsPanel">
                <div className="sections">
                    <div className="profile section">
                        <CitadelRoomAvatar room={room} />
                        {!isDmRoom && this.renderNameTopicSection()}
                        <CopyTextButton
                            copiedLabel={_t('Link copied!')}
                            copyLabel={_t('Copy the room link')}
                            text={roomLink}
                            type={'room-link'}
                        />
                        {!isFederatedRoom &&
                            <div className="federation sub-section">
                                <div className="title">
                                    {_t('Limited access')}
                                </div>
                                <div className="description">
                                    {_t('Only members of your company can be invited to this room.')}
                                </div>
                            </div>
                        }
                    </div>
                    <div className="security section">
                        <CitadelAccordion allowMultipleOpen={false} title={_t('Security')}>
                            {this.renderE2ESection()}
                            {!isDmRoom && this.renderJoinRulesSection()}
                            {!isDmRoom && this.renderHistoryVisibilitySection()}
                            {this.renderActivityMessageSection()}
                        </CitadelAccordion>
                    </div>
                    <div className="permissions section">
                        {!isDmRoom && editableSections.powerLevels &&
                            <CitadelAccordion allowMultipleOpen={false} title={_t('Permissions')}>
                                {this.renderPermissionSection()}
                            </CitadelAccordion>
                        }
                    </div>
                    <div className="notifications section">
                        <CitadelAccordion allowMultipleOpen={false} title={_t('Notifications')}>
                            {this.renderNotificationSection()}
                        </CitadelAccordion>
                    </div>
                    <div className="leave section" onClick={this._onLeaveClick}>
                        <div className="leave-icon" /> {leaveButtonText}
                    </div>
                    <GenericToolTip
                        label={isDeleteRoomDisabled ? _t("You are not the owner of the room") : ''}
                        labelStyle={"delete_tooltiptext"}
                    >
                        {editableSections.delete && <div className={deleteRoomClassName} onClick={isDeleteRoomDisabled ? null : this._onDeleteClick}>
                            <div className="delete-icon" />  {_t('Delete room')}
                        </div>}
                    </GenericToolTip>
                </div>
                <GenericToolTip
                    label={isExportRoomDisabled ? _t('export_chat|tooltip_export_room_disabled') : ''}
                    labelStyle={"export_room_tooltip"}
                >
                <div
                    className={classNames('export', { disabled: isExportRoomDisabled || roomExportInProgress })}
                    onClick={isExportRoomDisabled || roomExportInProgress ? noop : this.exportRoom}
                    aria-describedby={'export-room-tooltip'}
                >
                    <div className="export-icon" />
                    {_t('export_chat|Export room content')}
                </div>
                </GenericToolTip>
            </div>
        );
    }
}

RoomSettingsPanel.propTypes = {
    roomId: PropTypes.string.isRequired,
};

export default RoomSettingsPanel;
