import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../../Modal';
import NewsStore from '../../../../stores/NewsStore';
import dis from '../../../../dispatcher';

import NewsEditionDialog from './NewsEditionDialog';
import NewsPreviewDialog from './NewsPreviewDialog';

class NewsDialogMain extends React.Component {
    static displayName = "NewsPreviewDialogMain";

    static propTypes = { room: PropTypes.object.isRequired };

    static defaultProps = { room: null };

    state = { initialDate: new Date() };

    componentDidMount() {
        const { onFinished, room } = this.props;

        if (!room) onFinished();

        this._newsStoreToken = NewsStore.addListener(this.onNewsStoreUpdate);
        window.addEventListener('beforeunload', this.pageRefreshHandler);
    }

    componentWillUnmount() {
        if (this._newsStoreToken) this._newsStoreToken.remove();
        window.removeEventListener('beforeunload', this.pageRefreshHandler);
    }

    pageRefreshHandler = (event) => {
        const newsStore = NewsStore.getNewsCreationStore();
        const { visibility } = newsStore;

        if (visibility !== '') {
            // Cancel the event as stated by the standard.
            event.preventDefault();
            // Chrome requires returnValue to be set.
            event.returnValue = '';
        }
    };

    onNewsStoreUpdate = () => {
        const newsStore = NewsStore.getNewsCreationStore();
        const { visibility, news } = newsStore;

        if (this.visibility === visibility) return;

        this.visibility = visibility;
        switch (visibility) {
            case 'main':
                this.setState({ news }, this.openMainDialog);
                break;
            case 'preview':
                this.setState({ news }, this.openPreviewDialog);
                break;
            default:
                break;
        }
    };

    handleGoBack = () => dis.dispatch({ action: 'update_news_visibility', visibility: 'main' });

    handleSendNewsMain = (onFinished) => {
        const { room } = this.props;

        NewsStore.sendNews(room, onFinished);
        NewsStore.clearStore();
    };

    handleSendNews = (onFinished) => () => this.handleSendNewsMain(onFinished);

    handleCloseMain = (onFinished) => () => {
        NewsStore.clearStore();
        onFinished();
    };

    openPreviewDialog = () => {
        const { news } = this.state;
        const { content, previewSrc: imageUrl, title } = news;

        Modal.createDialog(
            NewsPreviewDialog,
            { content, imageUrl, title, onSend: this.handleSendNews, onGoBack: this.handleGoBack },
            'news_message_dialog',
            true,
        );
    };

    openMainDialog = () => {
        const { news } = this.state;

        Modal.createDialog(
            NewsEditionDialog,
            { news, onSend: this.handleSendNewsMain, onClose: this.handleCloseMain },
            'mx_NewsEditionDialog_wrapper',
            true,
        );
    };

    render() {
        return null;
    }
}

export default NewsDialogMain;
