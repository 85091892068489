import Modal from "../../../Modal";
import VersionObsolete from "../dialogs/VersionObsolete";
import PlatformPeg from "../../../PlatformPeg";
import MatrixClientPeg from "../../../MatrixClientPeg";

/**
 * request versions
 * @param {string} a version a
 * @param {string} b version b
 * @return {number}
 * 0: version strings are equal
 * 1: version a is greater than b
 * -1: version b is greater than a
**/
const compare = (a, b) => {
    return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
};

export const versionHasCorrectFormat = (version) =>
    !!version && typeof version === 'string' && /[0-9]+\.[0-9]+\.[0-9]+/.test(version);
export const isVersionSmaller = (a, b) => compare(a, b) < 0;

const showObsoleteVersionModal = () => {
    Modal.createDialog(
        VersionObsolete,
        {},
        'version_obsolete_dialog_content',
    );
};

export const displayVersionObsoleteDialogIfNeeded = async () => {
    let shouldNotDisplay = true;
    try {
        shouldNotDisplay = await checkIfVersionIsSupported();
    } catch (e) {
        console.log(`ObsoleteVersionCheck: ${e.toString()}`);
    }
    console.log(`ObsoleteVersionCheck: result - ${!shouldNotDisplay}`);
    !shouldNotDisplay && showObsoleteVersionModal();
};

export const checkIfVersionIsSupported = async () => {
    const platform = PlatformPeg.get();
    if (!platform.isDesktop()) return true;
    const os = platform.getPlatformFriendlyName();
    const fullVersion = await platform.getAppFullVersion();
    const portalUri = localStorage.getItem('mx_portal_url');
    const [version, environment] = (fullVersion || '').split('-');
    let env = environment;
    if (env === 'HASH') {
        env = 'beta';
    }
    console.log(`ObsoleteVersionCheck: Check if current version is supported: ${version}, build type ${env} running on ${os}`);

    const currentVersionHasCorrectFormat = versionHasCorrectFormat(version);
    if (!version || !currentVersionHasCorrectFormat) {
        throw new Error(`ObsoleteVersionCheck: Current version has wrong format: ${version} abort verification`);
    }
    let minimumRequiredVersion;
    try {
        const versionData = await MatrixClientPeg.get().getVersion(portalUri, os, env);
        minimumRequiredVersion = versionData.minimum_version;
    } catch (e) {
        throw new Error(`ObsoleteVersionCheck: Can not get minimum required version: ${e.toString()}`, e);
    }

    if (!versionHasCorrectFormat(minimumRequiredVersion)) {
        throw new Error(`ObsoleteVersionCheck: Minimum required version has wrong format: ${minimumRequiredVersion} abort verification`);
    } else {
        console.log(`ObsoleteVersionCheck: Minimum required version: ${minimumRequiredVersion}`);
    }

    return !isVersionSmaller(version, minimumRequiredVersion);
};
