import * as React from 'react';

import { _t } from '../../../languageHandler';

const AddImageButton = ({ onChange, previewSrc, previewName, onRemove, label, required = false }) => (
    <div className="mx_AddImageButton_wrapper">
        { label && (<div className="label">
            {label}
            {required && (<span className="required_mark">*</span>)}
        </div>)
        }
        <div className="button_container">
            <label
                className="mx_AddImageButton"
                htmlFor="addImage"
            >
                <div className="mx_AddImageButton_icon" />
                {_t('Add an image')}
                <input
                    className="mx_AddImageButton_input"
                    type="file"
                    onChange={onChange}
                    name="addImage"
                    accept="image/*"
                    id="addImage"
                />
            </label>
            {previewSrc && <div className="mx_AddImageButton_preview">
                <div className="mx_AddImageButton_preview-img" style={{backgroundImage: `url(${previewSrc})`}} />
                <div className="mx_AddImageButton_preview-name" >
                    {previewName}
                </div>
                <div className="mx_AddImageButton_preview-remove" onClick={onRemove} />
            </div>}
        </div>
    </div>
);

export default AddImageButton;
