import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import noop from 'lodash';

import HintManager from '../../../HintManager';
import { DIALOG_FLAGS } from '../../../HintManager';
import dis from '../../../dispatcher';
import MatrixClientPeg from '../../../MatrixClientPeg';

export const ARROW_POS = {
    TOP_LEFT: 'top_left',
    TOP_RIGHT: 'top_right',
    BOTTOM_LEFT: 'bottom_left',
    BOTTOM_RIGHT: 'bottom_right',
    CENTER_LEFT: 'center_left',
    CENTER_RIGHT: 'center_right',
};

class Hint extends React.Component {
    render() {
        const { hintsDialogContent, hintInfo, hintArrow, hintStyle } = this.props;
        const { title, description } = hintInfo;
        const {
            isDisplayed: isArrowDisplayed = true,
            position: arrowPosition = ARROW_POS.TOP_LEFT,
        } = hintArrow;

        const finalHintStyle = hintsDialogContent ? {
            ...hintStyle,
            zIndex: 5000,
        } : hintStyle;

        return (<div className="hint" style={finalHintStyle}>
            <div className="title">{title}</div>
            <div className="description">{description}</div>
            {isArrowDisplayed && <div className={cx('tooltip-arrow', arrowPosition.split('_'))} />}
        </div>);
    }
}

export class HintOverlay extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isHintDisplayed: props.isHintDisplayed,
        };
    }

    componentDidMount() {
        this.dispatcherRef = dis.register(this.onAction);
    }

    componentWillUnmount() {
        dis.unregister(this.dispatcherRef);
    }

    onAction = payload => {
        // Only for hint overlays used upon dialogs
        if (payload.action === 'display_dialog_hint_overlay') {
            this.setState({
                isHintDisplayed: true,
            });
        } else if (payload.action === 'close_dialog_hint_overlay') {
            this.setState({
                isHintDisplayed: false,
            });
        }
    };

    onOverlayClick = () => {
        dis.dispatch({ action: 'close_hint', flag: this.props.flag });

        // We make sure the other hint won't be displayed until the process
        // related to the action of the hinted component isn't finished or aborted.
        // As the user clicked on the overlay, no process has began so we can directly
        // provoke the display of any pending hints.
        !this.props.isOverlayingDialog && HintManager.checkPendingHints();
    }

    render() {
        const { overlayExtraStyle } = this.props;
        const { isHintDisplayed } = this.state;

        return isHintDisplayed ? <div className="mx_HintOverlay"
            style={overlayExtraStyle}
            onClick={this.onOverlayClick} /> : null;
    }
}

export class HintedComponent extends React.Component {
    constructor(props) {
        super(props);

        const isOnTopOfDialog = DIALOG_FLAGS.includes(props.flag);
        const dialog = document.querySelector('.mx_Dialog');

        this.state = {
            dialogParent: isOnTopOfDialog && dialog,
            isHintDisplayed: props.isHintDisplayed,
        };
    }

    componentDidMount() {
        this.dispatcherRef = dis.register(this.onAction);
    }

    componentWillUnmount() {
        dis.unregister(this.dispatcherRef);
    }

    componentDidUpdate(prevProps) {
        const { isHintDisplayed } = this.props;

        if (prevProps.isHintDisplayed !== isHintDisplayed) {
            this.setState({ isHintDisplayed });
        }

        const isDisplayAllowed = this.isDisplayAllowed();
        isDisplayAllowed && this.prepareHintRendering();
    }

    onAction = payload => {
        if (payload.flag === this.props.flag) {
            if (payload.action === 'allow_hint_display') {
                this.forceUpdate();
            } else if (payload.action === 'close_hint') {
                this.closeHint();
            }
        }
    };

    isDisplayAllowed = () => {
        const { flag } = this.props;
        const { isHintDisplayed } = this.state;
        return isHintDisplayed && HintManager.prepareHintDisplay(flag);
    };

    prepareHintRendering = () => {
        // Prevent scroll (when elements from the timeline, the search result or the room directory list are highlighted)
        const scrollPanel = document.querySelector('.mx_ScrollPanel');
        if (scrollPanel) scrollPanel.style.overflow = 'hidden';

        // Hide jumpToButton and topUnreadMessagesBar (actually not a bar but another button) components
        // so those buttons will never displayed on top of the overlay
        const jumpToButton = document.querySelector('.mx_JumpToBottomButton');
        if (jumpToButton) jumpToButton.style.visibility = 'hidden';
        const topUnreadButton = document.querySelector('.mx_TopUnreadMessagesBar');
        if (topUnreadButton) topUnreadButton.style.visibility = 'hidden';

        const rightPanel = document.querySelector('.mx_RightPanel');
        if (rightPanel) rightPanel.style.position = 'unset';

        // HintOverlay is also rendered in Modal to ensure that it will be used upon
        // DialogWrapper, while allowing dialog contents to be highlighted.log');
        const { dialogParent } = this.state;
        if (dialogParent) {
            dialogParent.style.zIndex = 'unset';
            dis.dispatch({ action: 'display_dialog_hint_overlay' });
        } else this.renderHintOverlay();
    }

    achieveHintUnrendering = () => {
        const scrollPanel = document.querySelector('.mx_ScrollPanel');
        if (scrollPanel) scrollPanel.style.overflow = '';

        const jumpToButton = document.querySelector('.mx_JumpToBottomButton');
        if (jumpToButton) jumpToButton.style.visibility = 'visible';
        const topUnreadButton = document.querySelector('.mx_TopUnreadMessagesBar');
        if (topUnreadButton) topUnreadButton.style.visibility = 'visible';

        const rightPanel = document.querySelector('.mx_RightPanel');
        if (rightPanel) rightPanel.style.position = 'relative';

        const { dialogParent } = this.state;
        if (dialogParent) {
            dialogParent.style.zIndex = 4012;
            dis.dispatch({ action: 'close_dialog_hint_overlay' });
        } else ReactDOM.unmountComponentAtNode(this.getOrCreateOverlayContainer());
    }

    getOrCreateOverlayContainer = () => {
        const { targetId } = this.props;
        const DIALOG_CONTAINER_ID = targetId || "mx_HintOverlayContainer";
        let container = document.getElementById(DIALOG_CONTAINER_ID);

        if (!container) {
            container = document.createElement("div");
            container.id = DIALOG_CONTAINER_ID;
            document.body.appendChild(container);
        }

        return container;
    };

    closeHint = () => {
        if (!this.state.isHintDisplayed) return;

        this.setState({ isHintDisplayed: false }, this.achieveHintUnrendering);

        // Set has_shown flag to true and do extra operations if needed.
        const { flag, onHintClose } = this.props;
        MatrixClientPeg.get().setInAppMessageFlag({[flag]: true});
        onHintClose();
    };

    renderHintOverlay = () => {
        const container = this.getOrCreateOverlayContainer();
        const overlay = <HintOverlay flag={this.props.flag} isHintDisplayed={true} />;
        ReactDOM.render(overlay, container);
    };

    render() {
        const { children, className, hintArrow, hintInfo, hintStyle, renderWithoutChildren } = this.props;

        if (!children && !renderWithoutChildren) return null;

        const isDisplayAllowed = this.isDisplayAllowed();
        return <div className={cx('mx_HintedComponent', className, { visible: isDisplayAllowed })}>
            {isDisplayAllowed && <Hint
                hintsDialogContent={this.state.dialogParent}
                hintInfo={hintInfo}
                hintArrow={hintArrow}
                hintStyle={hintStyle} />
            }
            {children && React.cloneElement(children, {
                mightBeHinted: true,
                isCurrentlyHinted: isDisplayAllowed,
                closeHint: this.closeHint,
            })}
        </div>;
    }
}

HintedComponent.propTypes = {
    className: PropTypes.string,
    flag: PropTypes.string.isRequired,
    isHintDisplayed: PropTypes.bool,
    onHintClose: PropTypes.func,
    hintInfo: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    }),
    hintArrow: PropTypes.shape({
        isDisplayed: PropTypes.bool.isRequired,
        position: PropTypes.oneOf([
            ARROW_POS.TOP_LEFT, ARROW_POS.TOP_RIGHT,
            ARROW_POS.BOTTOM_LEFT, ARROW_POS.BOTTOM_RIGHT,
            ARROW_POS.CENTER_LEFT, ARROW_POS.CENTER_RIGHT,
        ]),
    }),
    hintStyle: PropTypes.object,
    children: PropTypes.node,
    renderWithoutChildren: PropTypes.bool,
    targetId: PropTypes.string,
};

HintedComponent.defaultProps = {
    className: '',
    isHintDisplayed: false,
    onHintClose: noop,
    hintInfo: {
        title: '',
        description: '',
    },
    hintArrow: {
        isDisplayed: true,
        position: ARROW_POS.BOTTOM_LEFT,
    },
    hintStyle: {},
    targetId: null,
};
