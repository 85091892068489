/*
Copyright 2017 Michael Telatynski <7t3chguy@gmail.com>

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React from 'react';
import PropTypes from 'prop-types';
import sdk from '../../../index';
import SdkConfig from '../../../SdkConfig';
import MatrixClientPeg from '../../../MatrixClientPeg';
import { _t } from '../../../languageHandler';
import classNames from 'classnames';

import CitadelInput from '../../../components/views/elements/CitadelInput';
import CitadelToggle from '../../../components/views/elements/CitadelToggle';
import E2eQualifiedToggle from "../../structures/E2eQualifiedToggle";

const historyEnum = {
    SHARED: 'shared',
    INVITED: 'invited',
};

export default class extends React.Component {
    static displayName = 'CreateRoomDialog';

    static propTypes = {
        onFinished: PropTypes.func.isRequired,
    };

    state = {
        name: '',
        topic: '',
        federate: SdkConfig.get().default_federate,
        showAdvancedOptions: true,
        historyVisibility: historyEnum.SHARED,
        isEncrypted: false,
        isInfraFederated: false,
    };

    async componentDidMount() {
        const { shared: isInfraFederated } = await MatrixClientPeg.get().getServerConfig();
        this.setState({ isInfraFederated });
    }

    onOk = () => {
        const { name, topic, federate, historyVisibility, isEncrypted } = this.state;
        this.props.onFinished(true, name, topic, federate, historyVisibility, isEncrypted);
    };

    onCancel = () => {
        this.props.onFinished(false);
    };

    onInputChange = (ev) => {
        this.setState({ [ev.target.name]: ev.target.value });
    };

    changeFederationSetting = (value) => () => this.setState({federate: value})

    changeAdvancedSectionVisibility = () => {
        this.setState((prevState) => ({
            showAdvancedOptions: !prevState.showAdvancedOptions,
        }));
    };

    onEncryptionChange = (val) => this.setState({ isEncrypted: val });

    renderAdvancedOptions = () => {
        const { isInfraFederated } = this.state;
        return (
            <div className="advanced">
                {this.renderE2eSelect()}
                {!isInfraFederated && this.renderFederateSelect()}
                {this.renderHistoryVisibilitySection()}
            </div>
        );
    };

    renderE2eSelect = () => <E2eQualifiedToggle onChange={this.onEncryptionChange} />

    renderFederateSelect = () => <div className="section federate" >
        <div className="section-title">
            {_t('Room Access')}
        </div>
        <div className="section-description">
            {_t('Choose the access for your members. Your decision is irreversible.')}
        </div>
        <CitadelToggle
            type="radio"
            label={_t("Standard Access")}
            description={_t("People from other organizations can be invited")}
            checked={this.state.federate}
            onChange={this.changeFederationSetting(true)}
        />
        <CitadelToggle
            type="radio"
            label={_t("Limited Access")}
            description={_t("Only people in your organization can be invited")}
            checked={!this.state.federate}
            onChange={this.changeFederationSetting(false)}
        />
    </div>

    renderHistoryVisibilitySection = () => {
        const { historyVisibility: currentValue } = this.state;

        return (
            <div className="section">
                <div className="section-title">
                    {_t('History Management')}
                </div>
                <div className="section-description">
                    {_t('Choose the room’s history visibility of your new members. Your decision is irreversible.')}
                </div>
                <CitadelToggle
                    type="radio"
                    label={_t('The history of the room will be visible to all users')}
                    checked={(currentValue === historyEnum.SHARED)}
                    onChange={() => this._onHistoryVisibilityToggle(historyEnum.SHARED)}
                />
                <CitadelToggle
                    type="radio"
                    label={_t('New members will not be able to view the message history')}
                    checked={(currentValue === historyEnum.INVITED)}
                    onChange={() => this._onHistoryVisibilityToggle(historyEnum.INVITED)}
                />
            </div>
        );
    };

    _onHistoryVisibilityToggle = newHistoryVisibility => this.setState({
        historyVisibility: newHistoryVisibility,
    });

    render() {
        const BaseDialog = sdk.getComponent('views.dialogs.BaseDialog');
        const DialogButtons = sdk.getComponent('views.elements.DialogButtons');

        const { name, topic } = this.state;

        const arrowClassName = classNames({
            'arrow': true,
            'arrow-up': this.state.showAdvancedOptions,
        });

        return (
            <BaseDialog
                className="mx_CreateRoomDialog"
                onFinished={this.props.onFinished}
                title={_t('Create Room')}
                fixedWidth={true}
            >
                <form onSubmit={this.onOk}>
                    <div className="mx_Dialog_content">
                        <CitadelInput
                            label={_t('Room Name')}
                            placeholder={_t('For example: Executive Committee, Marketing Team')}
                            name='name'
                            onChange={this.onInputChange}
                            value={name}
                            autoFocus={true}
                        />
                        <CitadelInput
                            label={_t('Description (optional)')}
                            placeholder={_t('For example: Coordination of projects...')}
                            name='topic'
                            onChange={this.onInputChange}
                            coveredWithOpacity={true}
                            value={topic}
                        />
                        <div className="section">
                            <div className="title">
                                <div className="text" onClick={this.changeAdvancedSectionVisibility}>{_t('Advanced options')}</div>
                                <span className={arrowClassName} onClick={this.changeAdvancedSectionVisibility} />
                            </div>
                            {this.state.showAdvancedOptions && this.renderAdvancedOptions()}
                        </div>
                    </div>
                </form>
                <DialogButtons
                    primaryButton={_t('Create Room')}
                    disabled={name.trim().length === 0}
                    onPrimaryButtonClick={this.onOk}
                    hasCancel={false}
                    onCancel={this.onCancel} />
            </BaseDialog>
        );
    }
}
