import React from 'react';

import BaseDialog from '../BaseDialog';
import { _t } from '../../../../languageHandler';
import CitadelButton from '../../elements/CitadelButton';
import MatrixClientPeg from '../../../../MatrixClientPeg';
import dis from '../../../../dispatcher';

import mfaMandatorySvg from '../../../../../res/img/illustrations-settings-mfa-mail.svg';

export default class MandatoryMFADialog extends React.Component {
    componentDidMount() {
        MatrixClientPeg.get().setAccountData('citadel.mfa', { has_shown_mfa_mandatory_popup: true });
    }
    onViewSettingsClick = () => {
        dis.dispatch({
            action: 'view_user_settings',
            initialTabIndex: 3,
        });
        this.props.onFinished();
    }
    render() {
        const { onFinished } = this.props;
        return <BaseDialog
            className="mx_MandatoryMFADialog"
            fixedWidth={false}
            onFinished={onFinished}
            title=""
        >
            <div className="mx_MandatoryMFADialog_container">
                <div className="mx_MandatoryMFADialog_image">
                    <img src={mfaMandatorySvg} />
                </div>
                <div className="mx_MandatoryMFADialog_title">
                    {_t('Your security has been strenghtened')}
                </div>
                <div className="mx_MandatoryMFADialog_content">
                    <div>
                        {_t('The')} <span className='mx_MandatoryMFADialog_weightedLabel'>{_t('two-step verification')}</span>  {_t('has been activated by your administrator. You will now receive authentication codes via your email address when you log in.')}
                    </div>
                </div>

                <div className="mx_MandatoryMFADialog_recommendationContainer">
                    <span className='mx_MandatoryMFADialog_recommendedLabel'>{_t('Recommended')}</span>
                    <p className="mx_MandatoryMFADialog_recommendationText">
                        {_t('Add a second')} <span className='mx_MandatoryMFADialog_recommendationTextBold'>{_t('authentication method')}</span> {_t('for an even more secured login.')}
                    </p>
                </div>
                <div className="mx_MandatoryMFADialog_button">
                    <CitadelButton text={_t('Access settings')} onClick={this.onViewSettingsClick} minWidth={238} />
                </div>
            </div>
        </BaseDialog>;
    }
}
