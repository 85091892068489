import React, { Component } from 'react';

const isNum = num => typeof num === 'number';

class LinearLoader extends Component {
    render() {
        const { progress } = this.props;

        return (
            <div className="linear_loader_container">
                {isNum(progress) ? (
                    <div
                        className="progress"
                        style={{ width: `${progress}%` }}
                    />
                ) : (
                    <div className="indeterminate" />
                )}
            </div>
        );
    }
}

export default LinearLoader;
