import React, { useEffect, useState } from 'react';

import MatrixClientPeg from '../../../../MatrixClientPeg';
import BaseDialog from '../BaseDialog';
import { _t } from '../../../../languageHandler';
import CitadelButton from '../../elements/CitadelButton';

import mfaOnboardingSvg from '../../../../../res/img/mfa-onboarding.svg';

const MfaOnboardingDialog = ({onFinished}) => {
    const onSmsMfaClick = () => { onFinished('msisdn'); };
    const onEmailMfaClick = () => { onFinished('email'); };
    const onAppMfaClick = () => { onFinished('totp'); };
    const [totp, setTotp] = useState(null);
    const [email, setEmail] = useState(null);
    const [msisdn, setMsisdn] = useState(null);

    const getConfig = async () => {
        const {
            mfa_methods: {
                email,
                msisdn,
                totp,
            },
        } = await MatrixClientPeg.get().getServerConfig();
        setTotp(totp);
        setEmail(email);
        setMsisdn(msisdn);
    };

    useEffect(() => {
        getConfig();
    }, []);

    return <BaseDialog
        className="mx_MfaOnboardingDialog"
        fixedWidth={false}
        onFinished={(isActivated) => onFinished(undefined, isActivated)}
        title=""
    >
        <img src={mfaOnboardingSvg} alt="" />
        <div className="content">
            <span className="title">{_t('Multi-factor authentication')}</span>
            <span className="subtitle">{_t('Secure your account with your mobile phone number or email address.')}</span>
        </div>
        <div className="buttons">
            {totp && <CitadelButton text={_t('Activate authentication app verification')} onClick={onAppMfaClick} minWidth={283} />}
            {msisdn && <CitadelButton text={_t('Activate SMS verification')} onClick={onSmsMfaClick} minWidth={283} /> }
            {email && <CitadelButton isSecondary={true} text={_t('Activate Email verification')} onClick={onEmailMfaClick} minWidth={283} /> }
        </div>
    </BaseDialog>;
};

export default MfaOnboardingDialog;
