/*
Copyright 2017 Vector Creations Ltd
Copyright 2018 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import Promise from 'bluebird';

/**
 * Internal module. in-memory storage for e2e.
 *
 * @module
 */

/**
 * @implements {module:crypto/store/base~CryptoStore}
 */
export default class MemoryCryptoStore {
    constructor() {
        this._outgoingRoomKeyRequests = [];
        this._account = null;

        // Map of {devicekey -> {sessionId -> session pickle}}
        this._sessions = {};
        // Map of {senderCurve25519Key+'/'+sessionId -> session data object}
        this._inboundGroupSessions = {};
        // Opaque device data object
        this._deviceData = null;
        // roomId -> Opaque roomInfo object
        this._rooms = {};
        // Set of {senderCurve25519Key+'/'+sessionId}
        this._sessionsNeedingBackup = {};
    }

    /**
     * Delete all data from this store.
     *
     * @returns {Promise} Promise which resolves when the store has been cleared.
     */
    deleteAllData() {
        return Promise.resolve();
    }

    // Olm Account

    getAccount(txn, func) {
        func(this._account);
    }

    storeAccount(txn, newData) {
        this._account = newData;
    }

    // Olm Sessions

    countEndToEndSessions(txn, func) {
        return Object.keys(this._sessions).length;
    }

    getEndToEndSession(deviceKey, sessionId, txn, func) {
        const deviceSessions = this._sessions[deviceKey] || {};
        func(deviceSessions[sessionId] || null);
    }

    getEndToEndSessions(deviceKey, txn, func) {
        func(this._sessions[deviceKey] || {});
    }

    getAllEndToEndSessions(txn, func) {
        for (const deviceSessions of Object.values(this._sessions)) {
            for (const sess of Object.values(deviceSessions)) {
                func(sess);
            }
        }
    }

    storeEndToEndSession(deviceKey, sessionId, sessionInfo, txn) {
        let deviceSessions = this._sessions[deviceKey];
        if (deviceSessions === undefined) {
            deviceSessions = {};
            this._sessions[deviceKey] = deviceSessions;
        }
        deviceSessions[sessionId] = sessionInfo;
    }

    // Inbound Group Sessions

    getEndToEndInboundGroupSession(senderCurve25519Key, sessionId, txn, func) {
        func(this._inboundGroupSessions[senderCurve25519Key+'/'+sessionId] || null);
    }

    getAllEndToEndInboundGroupSessions(txn, func) {
        for (const key of Object.keys(this._inboundGroupSessions)) {
            // we can't use split, as the components we are trying to split out
            // might themselves contain '/' characters. We rely on the
            // senderKey being a (32-byte) curve25519 key, base64-encoded
            // (hence 43 characters long).

            func({
                senderKey: key.substr(0, 43),
                sessionId: key.substr(44),
                sessionData: this._inboundGroupSessions[key],
            });
        }
        func(null);
    }

    addEndToEndInboundGroupSession(senderCurve25519Key, sessionId, sessionData, txn) {
        const k = senderCurve25519Key+'/'+sessionId;
        if (this._inboundGroupSessions[k] === undefined) {
            this._inboundGroupSessions[k] = sessionData;
        }
    }

    storeEndToEndInboundGroupSession(senderCurve25519Key, sessionId, sessionData, txn) {
        this._inboundGroupSessions[senderCurve25519Key+'/'+sessionId] = sessionData;
    }

    // Device Data

    getEndToEndDeviceData(txn, func) {
        func(this._deviceData);
    }

    storeEndToEndDeviceData(deviceData, txn) {
        this._deviceData = deviceData;
    }

    // E2E rooms

    storeEndToEndRoom(roomId, roomInfo, txn) {
        this._rooms[roomId] = roomInfo;
    }

    getEndToEndRooms(txn, func) {
        func(this._rooms);
    }

    getSessionsNeedingBackup(limit) {
        const sessions = [];
        for (const session in this._sessionsNeedingBackup) {
            if (this._inboundGroupSessions[session]) {
                sessions.push({
                    senderKey: session.substr(0, 43),
                    sessionId: session.substr(44),
                    sessionData: this._inboundGroupSessions[session],
                });
                if (limit && session.length >= limit) {
                    break;
                }
            }
        }
        return Promise.resolve(sessions);
    }

    countSessionsNeedingBackup() {
        return Promise.resolve(Object.keys(this._sessionsNeedingBackup).length);
    }

    unmarkSessionsNeedingBackup(sessions) {
        for (const session of sessions) {
            const sessionKey = session.senderKey + '/' + session.sessionId;
            delete this._sessionsNeedingBackup[sessionKey];
        }
        return Promise.resolve();
    }

    markSessionsNeedingBackup(sessions) {
        for (const session of sessions) {
            const sessionKey = session.senderKey + '/' + session.sessionId;
            this._sessionsNeedingBackup[sessionKey] = true;
        }
        return Promise.resolve();
    }

    // Session key backups

    doTxn(mode, stores, func) {
        return Promise.resolve(func(null));
    }
}
