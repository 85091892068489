import React from 'react';

import BaseDialog from './BaseDialog';
import { _t } from '../../../languageHandler';
import versionObsoleteIcon from '../../../../res/img/version-absolete.svg';

const VersionObsolete = ({ onFinished }) => (
    <BaseDialog
        onFinished={onFinished}
        title=""
        hasCancel={false}
        fixedWidth={false}
    >
        <div className='content'>
            <img className="image" src={versionObsoleteIcon} />
            <div className="title">
                {_t('Your version of Citadel is obsolete')}
            </div>
            <div className="description">
                <div>{_t('Want to continue enjoying a secure experience?')}</div>
                <div>{_t('Please contact your administrator to obtain a more recent version of Citadel.')}</div>
            </div>
        </div>
    </BaseDialog>
);
 export default VersionObsolete;
