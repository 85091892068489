import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';


const CitadexContextButton = ({onClick, hasBackground, children}) => {
    return <div className={cx("citadex_context_button", { has_background: hasBackground})} onClick={onClick} >
        {children}
    </div>;
};

CitadexContextButton.propTypes = {
    hasBackground: PropTypes.bool,
    onClick: PropTypes.func,
};

export default CitadexContextButton;
