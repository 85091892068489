import classNames from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { _t } from '../../../languageHandler';

const FILTERS_COUNT = 3;

class MemberListTenantFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showingAll: false,
        };

        this.sortedCompanies = null;
    }

    static defaultProps = {
        companies: {},
    };

    sortCompanies = () => {
        const { companies } = this.props;

        const arr = [];

        for (const p in companies) {
            if (!companies.hasOwnProperty(p)) continue;

            const obj = companies[p];
            obj.name = p;
            arr.push(obj);
        }

        arr.sort((a, b) => a.count > b.count ? -1 : a.count < b.count ? 1 : 0);

        this.sortedCompanies = arr;

        return arr;
    };

    getCompaniesToDisplay = () => {
        const { companies } = this.props;

        // Do not render when there is only one company (mine)
        if (!companies || Object.keys(companies).length < 2) return [];

        const sortedCompanies = this.sortCompanies();

        return this.state.showingAll ? sortedCompanies : sortedCompanies.slice(0, FILTERS_COUNT);
    };

    toggleShowAll = () => {
        this.setState((prevState) => ({ showingAll: !prevState.showingAll }));
    };

    render() {
        const { companies, onCompanyClick } = this.props;

        const totalCompaniesCount = Object.keys(companies).length;

        const companiesToDisplay = this.getCompaniesToDisplay();

        if (!companiesToDisplay.length) return null;

        return (
            <div>
                <div className="mx_TenantFilter">
                    {
                        companiesToDisplay.map((c) => {
                            const className = classNames({
                                'mx_TenantPill': true,
                                'mx_TenantPillSelected': c.selected,
                            });

                            return (
                                <div key={c.name} className={className} onClick={() => onCompanyClick(c.name)}>
                                    <div className="name">{c.name}</div>
                                    <div className="count">{` (${c.count})`}</div>
                                </div>
                            );
                        })
                    }
                </div>
                {totalCompaniesCount > FILTERS_COUNT ?
                    <div className="mx_TenantFilter_ShowMore" onClick={this.toggleShowAll}>
                        <a>{this.state.showingAll ? _t('Show less') : _t('Show more')}</a>
                    </div> : null
                }
            </div>
        );
    }
}

MemberListTenantFilter.propTypes = {
    companies: PropTypes.object,
    onCompanyClick: PropTypes.func,
};

export default MemberListTenantFilter;
