/*
Copyright 2017 Vector Creations Ltd
Copyright 2018 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React from 'react';
import PropTypes from 'prop-types';

import sdk from '../../../index';
import { _t } from '../../../languageHandler';
import MatrixClientPeg from '../../../MatrixClientPeg';
import AccessibleButton from '../elements/AccessibleButton';
import Unread from '../../../Unread';
import RoomTile from '../rooms/RoomTile';
import { getDirectMessageRooms } from '../../../RoomInvite';
import createSvg from '../../../../res/img/create-big.svg';
import E2eQualifiedToggle from "../../structures/E2eQualifiedToggle";

export default class ChatCreateOrReuseDialog extends React.Component {
    constructor(props) {
        super(props);
        this.onFinished = this.onFinished.bind(this);
        this.onRoomTileClick = this.onRoomTileClick.bind(this);

        this.state = { tiles: [], isEncrypted: false };
    }

    componentDidMount() {
        const client = MatrixClientPeg.get();
        const { address } = this.props;

        // At this point we're sure that this shouldnt return an empty array.
        getDirectMessageRooms(address).then(rooms => {
            const tiles = [];
            for (const roomId of rooms) {
                const room = client.getRoom(roomId);
                if (room) {
                    const isInvite = room.getMyMembership() === "invite";
                    const highlight = room.getUnreadNotificationCount('highlight') > 0 || isInvite;
                    tiles.push(
                        <RoomTile key={room.roomId} room={room}
                                  hideContextMenuButton={true}
                                  transparent={true}
                                  collapsed={false}
                                  selected={false}
                                  unread={Unread.doesRoomHaveUnreadMessages(room)}
                                  highlight={highlight}
                                  isInvite={isInvite}
                                  onClick={this.onRoomTileClick}
                        />,
                    );
                }
            }

            this.setState({ tiles });
        });
    }

    onRoomTileClick(roomId) {
        this.props.onExistingRoomSelected(roomId);
    }

    onFinished() {
        this.props.onFinished(false);
    }

    onEncryptionChange = (val) => this.setState({ isEncrypted: val });

    onCreate = () => {
        const { onNewDMClick } = this.props;
        const { isEncrypted } = this.state;

        onNewDMClick(isEncrypted);
    }

    render() {
        const { isNew } = this.props;
        const { tiles } = this.state;

        const startNewChat = <AccessibleButton
            className="mx_MemberInfo_createRoom"
            onClick={this.onCreate}
        >
            <div className="mx_RoomTile_avatar">
                <img src={createSvg} width="26" height="26" />
            </div>
            <div className="mx_MemberInfo_createRoom_label mx_RoomTile_name"><i>{ _t("Start new chat") }</i></div>
        </AccessibleButton>;

        const BaseDialog = sdk.getComponent('views.dialogs.BaseDialog');
        return (
            <BaseDialog className='mx_ChatCreateOrReuseDialog'
                onFinished={this.onFinished}
                title={isNew ? _t('Start new chat') : _t('Open the existing chat')}
                contentId='mx_Dialog_content'
            >
                <div className="mx_Dialog_content" id='mx_Dialog_content'>
                    {isNew ? null : _t('You already have existing direct chats with this user:')}
                    <div className="mx_ChatCreateOrReuseDialog_tiles">
                        {tiles}
                        <E2eQualifiedToggle onChange={this.onEncryptionChange} withAdvancedSection isVisible={false} />
                        {isNew && startNewChat}
                    </div>
                </div>
            </BaseDialog>
        );
    }
}

ChatCreateOrReuseDialog.propTypes = {
    address: PropTypes.string.isRequired,
    // Called when clicking outside of the dialog
    isNew: PropTypes.bool,
    onExistingRoomSelected: PropTypes.func.isRequired,
    onFinished: PropTypes.func.isRequired,
    onNewDMClick: PropTypes.func.isRequired,
};
