import React from 'react';
import Lottie from 'react-lottie';
import authSuccessAnimationData from '../../../../res/animations/auth-success';

export const AuthSuccessAnimation = () => {
    const options = {
        loop: false,
        autoplay: true,
        animationData: authSuccessAnimationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <Lottie
            options={options}
            width={150}
        />
    );
};
