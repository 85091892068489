// this file contains duplicated code from MessagePanel
// and it is used for the room export

import MatrixClientPeg from "../MatrixClientPeg";
import EventTile from "../components/views/rooms/EventTile";
import DMRoomMap from "./DMRoomMap";
import shouldHideEvent from "../shouldHideEvent";

const shouldShowEventAfterRoomCreation = (roomId, events, index, mxEv) => {
    const previousEventType = (index > 0) ? events[index-1].getType() : '';
    const currentEventType = mxEv.getType();

    if (previousEventType === 'm.room.create' && currentEventType === 'm.room.member') return false;

    const blackListedEventTypes = [
        'm.room.history_visibility',
        'm.room.name',
    ];

    const dmRoomMap = DMRoomMap.shared();
    if (dmRoomMap.getUserIdForRoomId(roomId)) {
        blackListedEventTypes.push('m.room.join_rules');
    }

    return !(blackListedEventTypes.includes(currentEventType) && index < 9);
};
export const shouldShowEvent = (room, events, mxEv, index, highlightedEventId) => {
    const currentEventType = mxEv.getType();
    const currentEventContent = mxEv.getContent();

    // Usually, when a new room is created, the following events are sent as first events of the room:

    // m.room.create : hidden if the room is not a dm room && the current user is not the room creator
    // m.room.member (join) : always hidden
    // m.room.power_levels : always hidden
    // m.room.join_rules : hidden if the room is a dm room && the current user is not the room creator (always
    //                     displayed later in timeline if the associated value changes)
    // m.room.history_visibility : always hidden as an initial event (always displayed later in timeline if the
    //                             associated value changes)
    // m.room.guest_access : always hidden (guests will never be allowed anyway)
    if (mxEv.sender && MatrixClientPeg.get().isUserIgnored(mxEv.sender.userId)) {
        return false; // ignored = no show (only happens if the ignore happens after an event was received)
    }

    if (!EventTile.haveTileForEvent(mxEv)) {
        return false; // no tile = no show
    }

    // Always show highlighted event
    if (highlightedEventId === mxEv.getId()) return true;

    // In a DM ROOM / CHAT configuration, invitation and join events are always hidden.
    const dmRoomMap = DMRoomMap.shared();
    const isInviteOrJoinEvent = (currentEventType === 'm.room.member' &&
        (currentEventContent.membership === 'invite' || currentEventContent.membership === 'join'));

    if (dmRoomMap.getUserIdForRoomId(room.roomId) && isInviteOrJoinEvent) {
        return false;
    }

    if (shouldHideEvent(mxEv, room)) {
        return false;
    }
    const result = shouldShowEventAfterRoomCreation(room.roomId, events, index, mxEv);
    return result;
};
