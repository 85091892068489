import React from 'react';
import PropTypes from 'prop-types';

import { _t } from '../../../../languageHandler';
import BaseDialog from '../BaseDialog';
import CitadelButton from '../../elements/CitadelButton';

import sendE2eIcon from '../../../../../res/img/sendE2eIcon.svg';
import failureImg from '../../../../../res/img/closeE2eShare.svg';

const E2eSendFailureDialog = (
    { onFinished, title, listOfRequesters },
) => {
    return (
        <BaseDialog
            className="E2eSendFailureDialog"
            fixedWidth={false}
            hasCancel={true}
            onFinished={onFinished}
            title={title}
        >
            <div className='container'>
                {listOfRequesters ?
                    <div className='requesters_list'>
                        {listOfRequesters.map((requester, index) => {
                            const requesterClassname = requester.isSent ? 'requester_name_success' : 'requester_name_failure';
                            return (
                                <div className='requester_container' key={`${index}-e2e-failure`}>
                                    <img className={requesterClassname} src={requester.isSent ? sendE2eIcon : failureImg} alt='image' height={16} />
                                    <span className={requesterClassname}>{requester.isSent ? _t("Sent to %(name)s", { name: requester.displayName })
                                        : _t("Failed to send to %(name)s", { name: requester.displayName })}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                    : null
                }
            </div>
            <CitadelButton
                onClick={onFinished}
                text={_t('Close')}
                width={'100%'}
            />
        </BaseDialog>
    );
};

E2eSendFailureDialog.propTypes = {
    onFinished: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    listOfRequesters: PropTypes.array.isRequired,
};

export default E2eSendFailureDialog;
