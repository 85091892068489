import utils from 'matrix-js-sdk/lib/utils';
import { _t } from "../languageHandler";
import { createPNG, downloadBase64Image } from './ImageDataToBase64';
import keysExportLogo from '../../res/img/e2e/keysExportLogo.svg';

const MASK_WIDTH = 320;
const MASK_HEIGHT = 160;
const MASK_SURFACE = MASK_WIDTH * MASK_HEIGHT;
const WIDTH_HEIGHT_RATIO = MASK_WIDTH / MASK_HEIGHT;

const LOGO_WIDTH = 88;
const LOGO_HEIGHT = 92;
const LOGO_SURFACE = LOGO_WIDTH * LOGO_HEIGHT;

const CURRENT_VERSION = 1;
const DECORATION_ALPHA = 254;
const DATA_ALPHA = 255;
const DECORATION_MIN_VAL = 0;
const DECORATION_MAX_VAL = 150;

const generateMetadata = (data, hint, isSingleRoom) => {
    const metadata = { payload_size: data.length };
    if (hint) metadata.hint = hint;
    if (isSingleRoom) metadata.isSingleRoom = isSingleRoom;
    return JSON.stringify(metadata);
};

const getImageSize = (data) => {
    const LOGO_USAGE_PERCENTAGE = (100 * LOGO_SURFACE) / MASK_SURFACE;
    let dataQuantity = data.length / 3; // number of pixels needed
    dataQuantity += Math.ceil(dataQuantity * LOGO_USAGE_PERCENTAGE / 100); // add pixels needed for logo

    const calculatedHeight = Math.ceil(Math.sqrt(dataQuantity / WIDTH_HEIGHT_RATIO));
    const height = Math.max(calculatedHeight, MASK_HEIGHT);
    const width = WIDTH_HEIGHT_RATIO * height;

    return { height, width };
};

const getRandomValue = (min = DECORATION_MIN_VAL, max = DECORATION_MAX_VAL) =>
    Math.floor(Math.random() * ( max - min) + min);

export const encodeDataToImage = (data, hint, isSingleRoom) => {
    const metadata = generateMetadata(data, hint, isSingleRoom);
    const completeData = metadata + data;
    const { height, width } = getImageSize(completeData);
    const ratio = height / MASK_HEIGHT;
    const img = new Image();
    img.onload = () => {
        const keysImageCanvas = document.createElement('canvas');
        const keysImageContext = keysImageCanvas.getContext('2d');
        // get the coordinates for the logo
        const dx = (width - img.width * ratio)/2;
        const dy = (height - img.height * ratio)/2;
        keysImageCanvas.width = width;
        keysImageCanvas.height = height;
        // write the logo image a coordinates dx/dy and scale it according to the ratio
        keysImageContext.drawImage(img, dx, dy, LOGO_WIDTH * ratio, LOGO_HEIGHT * ratio);
        const imageCreatorData = keysImageContext.getImageData(0, 0, width, height);
        let dataIndex = 0;
        for (let i = 0; i < imageCreatorData.data.length; i += 4) {
            if (imageCreatorData.data[i + 3] === 0) {
                if (i === 0) {
                    imageCreatorData.data[i] = CURRENT_VERSION;
                    imageCreatorData.data[i + 1] = completeData.charCodeAt(dataIndex);
                    imageCreatorData.data[i + 2] = completeData.charCodeAt(dataIndex + 1);
                    imageCreatorData.data[i + 3] = DATA_ALPHA;
                    dataIndex += 2;
                } else {
                    // No logo pixel
                    if (dataIndex < completeData.length) {
                        imageCreatorData.data[i] = completeData.charCodeAt(dataIndex);
                        imageCreatorData.data[i + 1] = completeData.charCodeAt(dataIndex + 1) || getRandomValue();
                        imageCreatorData.data[i + 2] = completeData.charCodeAt(dataIndex + 2) || getRandomValue();
                        imageCreatorData.data[i + 3] = DATA_ALPHA;
                        dataIndex += 3;
                    } else {
                        imageCreatorData.data[i] = getRandomValue();
                        imageCreatorData.data[i + 1] = getRandomValue();
                        imageCreatorData.data[i + 2] = getRandomValue();
                        imageCreatorData.data[i + 3] = DECORATION_ALPHA;
                    }
                }
            } else {
                // logo pixels
                // in case the mask alpha is equal with DATA_ALPHA we will change it to DECORATION_ALPHA
                if (imageCreatorData.data[i + 3] === DATA_ALPHA) {
                    imageCreatorData.data[i + 3] = DECORATION_ALPHA;
                }
            }
        }

        const date = new Date();
        const informationImageData = getHeaderAndFooterImageData(width, height, date);
        const finalImageCanvas = document.createElement('canvas');
        const finalImageContext = finalImageCanvas.getContext('2d');
        finalImageCanvas.width = width;
        finalImageCanvas.height = height + (height * 3);
        const finalImageData = finalImageContext.getImageData(0, 0, width, finalImageCanvas.height);

        for (let i = 0; i < informationImageData.header.data.length; i += 4) {
            finalImageData.data[i] = informationImageData.header.data[i];
            finalImageData.data[i + 1] = informationImageData.header.data[i + 1];
            finalImageData.data[i + 2] = informationImageData.header.data[i + 2];
            finalImageData.data[i + 3] = DECORATION_ALPHA;
        }

        let offset = informationImageData.header.data.length;
        for (let i = 0; i < imageCreatorData.data.length; i++) {
            finalImageData.data[i + offset] = imageCreatorData.data[i];
        }

        offset += imageCreatorData.data.length;
        for (let i = 0; i < informationImageData.footer.data.length; i += 4) {
            finalImageData.data[i + offset] = informationImageData.footer.data[i];
            finalImageData.data[i + offset + 1] = informationImageData.footer.data[i + 1];
            finalImageData.data[i + offset + 2] = informationImageData.footer.data[i + 2];
            finalImageData.data[i + offset + 3] = DECORATION_ALPHA;
        }

        const base64Image = createPNG(finalImageData.data, width, finalImageCanvas.height);
        const month = date.getMonth() + 1;
        const normalizedMonth = `${month}`.length === 1 ? `0${month}` : month;
        const fullDate = `${date.getFullYear()}${normalizedMonth}${date.getDate()}`;
        downloadBase64Image(base64Image, `citadel_export_${fullDate}.png`);
        keysImageCanvas.remove();
        finalImageCanvas.remove();
    };
    img.src = keysExportLogo;
};

const getHeaderAndFooterImageData = (width, height, date) => {
    const month = date.getMonth() + 1;
    const normalizedMonth = `${month}`.length === 1 ? `0${month}` : month;
    const fullDate = `${date.getDate()}/${normalizedMonth}/${date.getFullYear().toString().slice(2)}`;
    const color = {
        accentColor: '#49C4DB',
        background: '#394595',
        borderColor: '#A4ACD3',
    };
    const ratio = height / MASK_HEIGHT;
    const halfWidth = width / 2;
    const lineHeight = 30 * ratio;
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height * 3;
    const ctx = canvas.getContext("2d");

    // set background
    ctx.fillStyle = color.background;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    canvas.style.opacity = (254/255).toString();

    // add header info
    ctx.font = `bold ${22 * ratio}px sans-serif`;
    ctx.fillStyle = 'white';
    ctx.textAlign = 'center';
    ctx.fillText(_t('Encryption keys'), halfWidth, (height / 2) - lineHeight);
    ctx.fillText('Citadel Team', halfWidth, (height / 2));

    ctx.font = `${13 * ratio}px sans-serif`;
    ctx.fillStyle = color.accentColor;
    ctx.fillText(_t('Export of :') + ' ' + fullDate, halfWidth, (height / 2) + lineHeight);

    // add footer info
    ctx.font = `${13 * ratio}px sans-serif`;
    ctx.fillStyle = 'white';
    ctx.fillText(_t('Import me, I contain the keys to read end-to-end'), halfWidth, height + 20 * ratio);
    ctx.fillText(_t('encrypted conversations.'), halfWidth, height + 20 * ratio * 2);

    ctx.beginPath();
    ctx.strokeStyle = 'white';
    ctx.moveTo(15, height + 20 * ratio * 3);
    ctx.lineTo(width - 15, height + 20 * ratio * 3);
    ctx.stroke();

    let infoCurrentPositionY = height + lineHeight * 3 + 20;

    ctx.font = `bold ${13 * ratio}px sans-serif`;
    ctx.textAlign = 'left';
    ctx.fillText(_t('How?'), 15, infoCurrentPositionY);

    ctx.font = `${13 * ratio}px sans-serif`;
    ctx.fillStyle = 'white';

    let position = 0;

    const writeInfo = (text) => {
        position ++;
        infoCurrentPositionY += lineHeight + 10 * ratio;
        ctx.beginPath();
        ctx.arc(25 * ratio, infoCurrentPositionY, 10 * ratio, 0, Math.PI * 2);
        ctx.fillStyle = color.accentColor;
        ctx.fill();
        ctx.font = `${13 * ratio}px sans-serif`;
        ctx.fillStyle = 'white';
        ctx.fillText(position, 21 * ratio, infoCurrentPositionY + 4 * ratio);
        ctx.fillText(text, 40 * ratio, infoCurrentPositionY + 4 * ratio);
    };

    writeInfo(_t('Go to your profile'));
    writeInfo(_t('Click Advanced Settings'));
    writeInfo(_t('Click on Security'));
    writeInfo(_t('Then import your encryption keys'));

    const header = ctx.getImageData(0, 0, width, height);
    const footer = ctx.getImageData(0, height, width, height * 3);

    canvas.remove();

    return ({ header, footer });
};

const loadImage = (src) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = src;
    });
};

export const decodeImage = async (url) => {
    const img = await loadImage(url);
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = img.width;
    canvas.height = img.height;
    context.drawImage(img, 0, 0);
    const imageData = context.getImageData(0, 0, img.width, img.height);
    canvas.remove();
    return decodeImageFromPixels(imageData.data);
};

export const decodeImageFromPixels = (pixels) => {
    // Loop over each pixel and get the chars
    let imageDataString = '';
    let versionByte;
    for (let i = 0, n = pixels.length; i < n; i += 4) {
        // pixels[i]  - red; pixels[i+1] - green; pixels[i+2] - blue; pixels[i+3] - alpha
        if (pixels[i+3] === 255) {
            // first byte is the version
            if (!versionByte) {
                versionByte = pixels[i];
                if (versionByte !== CURRENT_VERSION) {
                    console.error(`Decoder version not supported: current = ${CURRENT_VERSION}, found ${versionByte}`);
                    throw new Error(_t('Selected image does not match'));
                }
            } else {
                imageDataString += String.fromCharCode(pixels[i]);
            }
            imageDataString += String.fromCharCode(pixels[i+1]) + String.fromCharCode(pixels[i+2]);
        }
    }
    const HEADER_LINE = '-----BEGIN MEGOLM SESSION DATA-----';
    const imageDataStringArray = imageDataString.split(HEADER_LINE);
    // imageDataStringArray[0] contains a json with the hint and payload_size
    const metaData = utils.jsonParseSafe(imageDataStringArray[0]);
    const restImageData = `${HEADER_LINE}${imageDataStringArray[1]}`.slice(0, metaData.payload_size);
    if (restImageData.length !== metaData.payload_size) {
        console.warn('Possible error may occur on keys import ' +
            `(payload size = ${metaData.payload_size}, found: ${restImageData.length})`,
        );
    }

    return ({ data: restImageData, metaData, type: 'png' });
};
