import React from 'react';

import Modal from '../../../../Modal';
import MatrixClientPeg from '../../../../MatrixClientPeg';
import MandatoryMFADialog from './MandatoryMFADialog';

export default class MfaMandatoryChecker extends React.Component {
    async componentDidMount() {
        const cli = MatrixClientPeg.get();
        const { mfa_mandatory_mode, mfa } = await cli.getServerConfig();
        const mfaAccountDataEvent = await cli.getAccountData('citadel.mfa');
        const { has_shown_mfa_mandatory_popup, mfaActivated } = mfaAccountDataEvent ? mfaAccountDataEvent.getContent() : {};
        if ( !!mfa && !!mfa_mandatory_mode && !has_shown_mfa_mandatory_popup && !mfaActivated ) {
            Modal.createDialog(
                MandatoryMFADialog,
                {},
                'mx_MandatoryMFADialog',
            );
        }
    }

    render() {
        return null;
    }
}
