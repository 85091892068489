import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import ConferenceInfraFilterPills from './ConferenceInfraFilterPills';

import { _t } from '../../../../../languageHandler';
import MemberAvatar from '../../../avatars/MemberAvatar';

const ConferencePlanInvieesList = (props) => {
    const {
        allMembersInvited,
        maxLimitReached,
        maxMembersBasedOnConference,
        membersListFiltered,
        membersToInvite,
        numberOfInvitedMembers,
        onChangeInviteeStatus,
        onInfraPillFilterPress,
        onSelectAllMembers,
    } = props;

    const disabledSelectAll = useMemo(() => {
        membersToInvite.length > maxMembersBasedOnConference;
    }, [membersToInvite, maxMembersBasedOnConference]);

    const someMembersInvited = useMemo(() =>
        numberOfInvitedMembers !== 0 && (numberOfInvitedMembers !== membersToInvite.length)
    , [numberOfInvitedMembers, membersToInvite]);

    const allFilteredMembersInvited = useMemo(() =>
        membersToInvite.filter(item => item.display).length === numberOfInvitedMembers
    , [membersToInvite, numberOfInvitedMembers]);

    const maxInviteesNumber = useMemo(() => {
       return membersToInvite.filter(item => item.display).length;
    }, [membersToInvite]);

    const selectAllClassname = cx('mx_ConferencePlanDialog_inviteesSelectAll', { disabled: disabledSelectAll });
    const checkboxClassname = cx(
        someMembersInvited
            ? allFilteredMembersInvited
            ? 'mx_ConferencePlanDialog inviteesList_checkedBox'
            : membersListFiltered
                ? 'mx_ConferencePlanDialog inviteesList_uncheckedBox'
                : 'mx_ConferencePlanDialog inviteesList_uncheckBox'
            : allMembersInvited
                ? 'mx_ConferencePlanDialog inviteesList_checkedBox'
                : 'mx_ConferencePlanDialog inviteesList_uncheckedBox',
        { disabled: disabledSelectAll },
    );

    const renderInviteeItem = useCallback((item, index) => {
        const { member, invited, display } = item;
        const firstPartClassname = cx('mx_ConferencePlanDialog inviteesList_row firstPart', {
            disabledAvatar: !invited && maxLimitReached,
        });
        const checkboxClassname = cx(invited ?
            'mx_ConferencePlanDialog inviteesList_checkedBox' :
            'mx_ConferencePlanDialog inviteesList_uncheckedBox', {
                disabled: !invited && maxLimitReached,
            },
        );
        const nameClassname = cx('mx_ConferencePlanDialog inviteesList_itemName', {
            disabled: !invited && maxLimitReached,
        });
        const emailClassname = cx('mx_ConferencePlanDialog inviteesList_itemEmail', {
            disabled: !invited && maxLimitReached,
        });


        if (display) {
            return (
                <div
                    className='mx_ConferencePlanDialog inviteesList_itemContainer'
                    key={`invitee-${index}`}
                >
                    <div className='mx_ConferencePlanDialog inviteesList_innerItemContainer'>
                        <div className={firstPartClassname}>
                            <MemberAvatar member={member} width={40} height={40} />
                            <span className={nameClassname}>{member.name}</span>
                        </div>
                        <div className='mx_ConferencePlanDialog inviteesList_row secondPart'>
                            <span className={emailClassname}>{member.user?.email || ''}</span>
                            <div onClick={onChangeInviteeStatus(index)} className={checkboxClassname} />
                        </div>
                    </div>
                    {index !== membersToInvite.length - 1 ? <div className='mx_ConferencePlanDialog_separator' /> : null}
                </div>
            );
        }
        return null;
    }, [membersToInvite, onChangeInviteeStatus]);

    return (
        <>
            <div className='mx_ConferencePlanDialog_inviteesSelectAllContainer'>
                <ConferenceInfraFilterPills
                    membersToInvite={membersToInvite}
                    onFilterPress={onInfraPillFilterPress}
                />
                <div className='mx_ConferencePlanDialog_inviteesFilters'>
                    <span className={selectAllClassname}>
                        {_t("Select all (%(maxInviteesNumber)s)", {
                            maxInviteesNumber,
                        })}
                    </span>
                    <div className={checkboxClassname} onClick={onSelectAllMembers(someMembersInvited)} />
                </div>
            </div>
            <div className='mx_ConferencePlanDialog_separator' />
            <div className='mx_ConferencePlanDialog_inviteeslistContainer'>
                {membersToInvite.map(renderInviteeItem)}
            </div>
        </>
    );
};

ConferencePlanInvieesList.propTypes = {
    allMembersInvited: PropTypes.bool.isRequired,
    maxLimitReached: PropTypes.bool.isRequired,
    maxMembersBasedOnConference: PropTypes.number.isRequired,
    membersToInvite: PropTypes.array.isRequired,
    numberOfInvitedMembers: PropTypes.number.isRequired,
    onChangeInviteeStatus: PropTypes.func.isRequired,
    onSelectAllMembers: PropTypes.func.isRequired,
};

export default ConferencePlanInvieesList;
