import { noop } from 'lodash';

import Modal from "../../../Modal";
import ErrorDialogContent from './ErrorDialogContent';
import { ERROR_TYPES } from './ErrorDialogContent';

const errorDialog = (props) => {
    const { onContinue = noop, type = ERROR_TYPES.FLOWS, appTheme = false, autoCloseAfter } = props;

    Modal.createTrackedDialog(
        '',
        '',
        ErrorDialogContent,
        { hasCancelButton: false, onContinue, type, autoCloseAfter },
        (appTheme ? 'app_theme ' : '') + 'citadex_error_dialog',
    );
};

export { errorDialog, ERROR_TYPES };
