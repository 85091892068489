import React from 'react';
import { noop } from 'lodash';

import sdk from '../../../../../index';
import { _t, getCurrentLanguage } from '../../../../../languageHandler';
import AccessibleButton from '../../../elements/AccessibleButton';
import Modal from '../../../../../Modal';

const showTermsDialog = () => {
    const homeserverUrl = localStorage.getItem('mx_hs_url');
    const TermsOfUseDialog = sdk.getComponent('dialogs.TermsOfUseDialog');
    Modal.createTrackedDialog('Display Terms of Use', '', TermsOfUseDialog, {
        onFinished: noop,
        displayOnly: true,
        homeserverUrl: homeserverUrl,
    });
};

const showPersonalDataDialog = () => {
    const lang = getCurrentLanguage();
    const homeserverUrl = localStorage.getItem('mx_hs_url');
    const personalDataLink = document.getElementById('personal-data-link');
    personalDataLink.href = `${homeserverUrl}/_matrix/client/v1/citadel/consent/personal-data?lang=${lang}`;
    personalDataLink.click();
};

const showCreditsDialog = () => {
    const CreditsDialog = sdk.getComponent('dialogs.CreditsDialog');
    Modal.createTrackedDialog('Third-Party Notices', '', CreditsDialog, {
        title: _t('Third-Party Notices'),
        description: renderCredits(),
    });
};

const renderCredits = () => {
    // Note: This is not translated because it is legal text.
    // Also, &nbsp; is ugly but necessary.
    return (
        <div className="mx_SettingsTab_section">
            <ul>
                <li>
                    The <a href="https://github.com/vector-im/riot-web" rel="noopener" target="_blank">
                    Riot Web Client</a> is (C)&nbsp;
                    <a href="https://vector.im" rel="noopener" target="_blank">New Vector Ltd.</a>{' '}
                    used under the terms of&nbsp;
                    <a href="http://www.apache.org/licenses/LICENSE-2.0" rel="noopener" target="_blank">
                        Apache License Version 2.0</a>.
                </li>
                <li>
                    The <a href="https://github.com/matrix-org/matrix-js-sdk" rel="noopener" target="_blank">
                    Matrix Javascript SDK</a> is (C)&nbsp;
                    <a href="https://matrix.org" rel="noopener" target="_blank">The Matrix.org Foundation</a>{' '}
                    used under the terms of&nbsp;
                    <a href="http://www.apache.org/licenses/LICENSE-2.0" rel="noopener" target="_blank">
                        Apache License Version 2.0</a>.
                </li>
                <li>
                    The <a href="https://github.com/matrix-org/matrix-react-sdk" rel="noopener" target="_blank">
                    Matrix React SDK</a> is (C)&nbsp;
                    <a href="https://matrix.org" rel="noopener" target="_blank">The Matrix.org Foundation</a>{' '}
                    used under the terms of&nbsp;
                    <a href="http://www.apache.org/licenses/LICENSE-2.0" rel="noopener" target="_blank">
                        Apache License Version 2.0</a>.
                </li>
                <li>
                    <a href="https://github.com/joypixels/emojione#emojione-version-2" rel="noopener" target="_blank">
                        EmojiOne Version 2
                    </a> is (C)&nbsp;
                    <a href="https://www.joypixels.com" rel="noopener" target="_blank">JoyPixels Inc</a>{' '}
                    used under the terms of&nbsp;
                    <a href="https://creativecommons.org/licenses/by/4.0/" rel="noopener" target="_blank">
                        Creative Commons Attribution 4.0 International License
                    </a>.
                </li>
            </ul>
        </div>
    );
};

const LegalInfoUserSettingsTab = () => (
    <div className="mx_SettingsTab mx_LegalInfoUserSettingsTab">
        <div className="mx_SettingsTab_heading">{_t('Legal information')}</div>
        <div className="mx_SettingsTab_section">
            <div className="button">
                <AccessibleButton onClick={showTermsDialog} kind="primary">
                    {_t('Terms of Use')}
                </AccessibleButton>
            </div>
            <div className="button">
                <a id="personal-data-link" target="_blank" style={{visibility: 'hidden'}} />
                <AccessibleButton onClick={showPersonalDataDialog} kind="primary">
                    {_t('Personal Data')}
                </AccessibleButton>
            </div>
            <div className="button">
                <AccessibleButton onClick={showCreditsDialog} kind="primary">
                    {_t('Third-Party Notices')}
                </AccessibleButton>
            </div>
        </div>
    </div>
);

export default LegalInfoUserSettingsTab;
