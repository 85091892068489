import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import cx from 'classnames';
import fscreen from 'fscreen';

import MatrixClientPeg from '../../../MatrixClientPeg';
import dis from "../../../dispatcher";

import ConferenceButton from "../elements/ConferenceButton";
import CitadexButtonOptions from './CitadexButtonOptions';
import { _t } from '../../../languageHandler';
import { KeyCode } from "../../../Keyboard";
import StopCallDialog from '../dialogs/StopCallDialog';
import Modal from '../../../Modal';
import SettingsStore, { SettingLevel } from "../../../settings/SettingsStore";
import { getBottomBarIconPack } from './utils/Icons';

class ConferenceBottomBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isCameraHovered: false,
            isEndCallDisabled: false,
            isFullScreen: false,
            isMinimized: false,
            isSharingHovered: false,
            iconPack: getBottomBarIconPack(
                SettingsStore.getValueAt(SettingLevel.ACCOUNT, "theme"),
                false,
            ),
        };
    }

    componentDidMount() {
        this.dispatcherRef = dis.register(this.onAction);
        document.addEventListener('keydown', this.handleKeyPress);
        fscreen.onfullscreenchange = this.toggleFullScreenState;
    }

    componentDidUpdate() {
        const { screenSharerStreamId } = this.props;
        const { isFullScreen } = this.state;
        // disabled full screen if the screen sharing is stopped
        if (isFullScreen && !screenSharerStreamId) {
            this.toggleFullScreen();
        }
    }

    componentWillUnmount() {
        dis.unregister(this.dispatcherRef);
        document.removeEventListener('keydown', this.handleKeyPress);
        fscreen.onfullscreenchange = null;
    }

    onAction = (payload) => {
        const { isMinimized } = this.state;
        if (payload.action === 'conference_toggle_fullscreen') {
            this.toggleFullScreen();
        }

        if (payload.action === 'minimize_conference') {
            this.setState({
                isMinimized: payload.isMinimized,
                iconPack: getBottomBarIconPack(
                    SettingsStore.getValueAt(SettingLevel.ACCOUNT, "theme"),
                    payload.isMinimized,
                ),
            });
        }

        if (payload.action === 'set_theme') {
            this.setState({ iconPack: getBottomBarIconPack(payload.value, isMinimized ) });
        }
    }

    toggleFullScreenState = () => {
        const { isFullScreen } = this.state;

        this.setState({ isFullScreen: !isFullScreen });
        dis.dispatch({ action: 'conference_full_screen', isFullScreen: !isFullScreen });
    }

    handleKeyPress = (event) => {
        // Since Firefox 65, the keydown and keyup events are now fired during IME composition (bug 354358).
        // To ignore all keydown events that are part of composition, do something like this
        // (229 is a special value set for a keyCode relating to an event that has been processed by an IME):
        if (event.isComposing || event.keyCode === 229 || !event.ctrlKey) {
            return;
        }

        const { onAudioClick, onVideoClick, isCameraButtonDisabled, isVideoCall } = this.props;
        switch (event.keyCode) {
            case KeyCode.KEY_M:
                onAudioClick();
                break;
            case KeyCode.KEY_C:
                isVideoCall && !isCameraButtonDisabled && onVideoClick();
                break;
        }
    }

    handleEndCallClick = () => {
        const { isFullScreen } = this.state;
        const callEventContent = this.props.callInProgress;
        const isInviter = callEventContent && localStorage.getItem('mx_user_id') === callEventContent.inviter_id;
        if (isInviter) {
            isFullScreen && this.toggleFullScreen();
            Modal.createDialog(
                StopCallDialog,
                {
                    onEndCall: this.endCall,
                    onStopCall: this.props.onStopCall,
                    onFinished: () => { },
                },
                'mx_StopCallDialog',
                true,
            );
        } else {
            this.endCall();
        }
    }

    endCall = () => {
        const { onEndCallClick } = this.props;
        const data = MatrixClientPeg.get().getAccountData('citadel.conference');

        if (data) {
            const {
                event: {
                    content: { citadel_meet_use_count: count = 0, has_seen_survey: hasSeenSurvey = false },
                },
            } = data;
            if (!hasSeenSurvey) {
                const newCount = count + 1;
                MatrixClientPeg.get().setAccountData('citadel.conference', {
                    ...data.event.content,
                    ['citadel_meet_use_count']: newCount,
                });
                localStorage.setItem('callEnds', true);
                localStorage.setItem('callEnds', '');
            }
        }

        this.setState({ isEndCallDisabled: true }, onEndCallClick);
    };

    toggleFullScreen = () => {
        const container = document.querySelector('.cards-container');
        if (fscreen.fullscreenElement !== null) {
            fscreen.exitFullscreen();
        } else {
            fscreen.requestFullscreen(container);
        }
    }

    onHoverCameraButton = (isCameraHovered) => {
        this.setState({ isCameraHovered });
    }

    onHoverSharingButton = (isSharingHovered) => {
        this.setState({ isSharingHovered });
    }

    handleScreenShare = () => {
        const { amISharingMyScreen, onScreenShare } = this.props;
        onScreenShare(false, amISharingMyScreen);
    };

    render() {
        const { screenSharerStreamId } = this.props;
        const {
            iconPack,
            isCameraHovered,
            isEndCallDisabled,
            isFullScreen,
            isMinimized,
            isSharingHovered,
        } = this.state;
        const {
            amISharingMyScreen,
            audioEnabled,
            cameraQualitySetting,
            isCameraButtonDisabled,
            isMuteDisabled,
            isShareScreenButtonDisabled,
            isVideoCall,
            isVisible,
            onAudioClick,
            onSettingsClick,
            onVideoClick,
            screenshareQualitySetting,
            setManualVideoQuality,
            setScreensharingVideoQuality,
            videoEnabled,
        } = this.props;

        return (
            <div className={cx('citadex_visio_conference_buttons', { isFullScreen, isVisible })}>
                <div className="citadex_visio_buttons_container">
                    <ConferenceButton
                        className={'audio_button'}
                        onClick={onAudioClick}
                        icon={audioEnabled ? iconPack.audioOn : iconPack.audioOff}
                        iconHover={audioEnabled ? iconPack.audioOnHover : iconPack.audioOffHover}
                        disabled={isMuteDisabled}
                        tooltip={audioEnabled ? _t("Turn microphone off") : _t("Turn microphone on")}
                    />
                    {isVideoCall ?
                        <div className="citadex_complex_button_container">
                            <CitadexButtonOptions
                                disabled={!videoEnabled || isCameraButtonDisabled || isMinimized}
                                onChange={setManualVideoQuality}
                                value={cameraQualitySetting}
                                isHovered={isCameraHovered}
                            />
                            <ConferenceButton
                                className="video_button"
                                onClick={onVideoClick}
                                icon={videoEnabled ? iconPack.videoOn : iconPack.videoOff}
                                iconHover={videoEnabled ? iconPack.videoOnHover : iconPack.videoOffHover}
                                disabled={isCameraButtonDisabled}
                                onHover={this.onHoverCameraButton}
                                tooltip={videoEnabled ? _t("Turn camera off") : _t("Turn camera on")}
                            />
                        </div>
                        : null
                    }
                    <ConferenceButton
                        className={cx('end_call_button', { audio: !isVideoCall, video: isVideoCall })}
                        onClick={this.handleEndCallClick}
                        icon={iconPack.endCall}
                        iconHover={iconPack.endCallHover}
                        disabled={isEndCallDisabled}
                    />
                    <div className="citadex_complex_button_container">
                        <CitadexButtonOptions
                            disabled={!amISharingMyScreen || isShareScreenButtonDisabled || isMinimized}
                            onChange={setScreensharingVideoQuality}
                            value={screenshareQualitySetting}
                            isHovered={isSharingHovered}
                        />
                        <ConferenceButton
                            className="share_button"
                            onClick={this.handleScreenShare}
                            tooltip={amISharingMyScreen ? _t("Stop screen sharing") : _t('Start screen sharing')}
                            icon={amISharingMyScreen ? iconPack.shareOn : iconPack.shareOff}
                            iconHover={amISharingMyScreen ? iconPack.shareOnHover : iconPack.shareOffHover}
                            disabled={isShareScreenButtonDisabled}
                            onHover={this.onHoverSharingButton}
                        />
                    </div>
                    {isFullScreen
                        ? null
                        : <ConferenceButton
                            className="parameters"
                            onClick={onSettingsClick}
                            tooltip={_t('Settings')}
                            icon={iconPack.parameters}
                            iconHover={iconPack.parametersHover}
                          />
                    }
                    {!amISharingMyScreen && screenSharerStreamId
                        ? <ConferenceButton
                            className={cx('fullScreen', { isFullScreen })}
                            onClick={this.toggleFullScreen}
                            icon={!isFullScreen ? iconPack.fullscreenOn : iconPack.fullscreenOff}
                            iconHover={!isFullScreen ? iconPack.fullscreenOnHover : iconPack.fullscreenOffHover}
                        />
                        : null
                    }
                </div>
            </div>
        );
    }
}

ConferenceBottomBar.defaultProps = {
    amISharingMyScreen: false,
    cameraQualitySetting: 'auto',
    isMuteDisabled: true,
    isVideoCall: false,
    isVisible: true,
    onScreenShare: noop,
    onVideoClick: noop,
    screenshareQualitySetting: 'auto',
    setManualVideoQuality: noop,
    setScreensharingVideoQuality: noop,
    videoEnabled: false,
};

ConferenceBottomBar.propTypes = {
    amISharingMyScreen: PropTypes.bool,
    audioEnabled: PropTypes.bool.isRequired,
    cameraQualitySetting: PropTypes.string.isRequired,
    isCameraButtonDisabled: PropTypes.bool,
    isMuteDisabled: PropTypes.bool,
    isShareScreenButtonDisabled: PropTypes.bool,
    isVideoCall: PropTypes.bool,
    isVisible: PropTypes.bool.isRequired,
    onAudioClick: PropTypes.func.isRequired,
    onEndCallClick: PropTypes.func.isRequired,
    onScreenShare: PropTypes.func,
    onVideoClick: PropTypes.func,
    screenSharerStreamId: PropTypes.number,
    screenshareQualitySetting: PropTypes.string.isRequired,
    setManualVideoQuality: PropTypes.func.isRequired,
    setScreensharingVideoQuality: PropTypes.func.isRequired,
    videoEnabled: PropTypes.bool,
    callInProgress: PropTypes.object,
};

export default ConferenceBottomBar;
