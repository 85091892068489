import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import BaseDialog from '../BaseDialog';
import { _t } from '../../../../languageHandler';
import AccessibleButton from '../../elements/AccessibleButton';
import UserStore from '../../../../stores/UserStore';
import MatrixClientPeg from '../../../../MatrixClientPeg';
import Modal from '../../../../Modal';
import RequestSuccessDialog from './RequestSuccessDialog';
import Spinner from '../../elements/Spinner';

class RequestDialog extends React.Component {
    state = {
        isLoading: false,
    }

    returnDomain = (domainString) => {
        if (!domainString) return;
        return domainString.split(':')[1];
    }

    onSuccess = () => {
        const { isTrialLicenseEnabled } = this.props;
        this.setState({ isLoading: false });
        MatrixClientPeg.get().trackUserAction({
            formId: isTrialLicenseEnabled ? 'rightsConference' : 'rightsConferenceNoTrial',
            version: 1,
            action: 'request',
        });
        Modal.createDialog(RequestSuccessDialog, {
            onFinished: this.props.onFinished,
        }, 'mx_ConferenceRequestSuccessDialog mx_NewVersionDialog');
    }

    onClick = async () => {
        const cli = MatrixClientPeg.get();
        const userId = localStorage.getItem('mx_user_id');
        const { email } = UserStore.getUserById(userId);
        const names = await UserStore.getNames(userId);
        const domain = this.returnDomain(userId);
        this.setState({ isLoading: true });
        const portalUrl = localStorage.getItem('mx_portal_url');

        cli.requestConferenceRights(portalUrl, {
            ...names,
            email,
            domain,
        })
        .then(() => { this.onSuccess(); })
        .catch((err) => {
            MatrixClientPeg.get().trackUserAction({
                formId: 'rightsConference',
                version: 1,
                action: 'error',
                step: 'request',
                code: err.code,
                reason: err.text,
            });
            console.log('Conference rights request failed');
        });
    }

    trialContent = () => {
        const { maxParticipants: { audio, video }, type } = this.props;
        const imageClass = type === 'video' ? 'video' : 'audio';

        return (
            <>
                <div className={`mx_ConferenceRequestDialog_image ${imageClass}`} />
                <span className="mx_Dialog_title">
                    {_t('Citadel Conference')}
                </span>
                <p className="mx_ConferenceRequestDialog_p">
                    {
                        this.props.type === 'video' ?
                            _t("The level of service linked to your Citadel account does not allow you to "
                                + "initiate a video conference with attendees of this show.")
                            : ( this.props.type === 'audio' ?
                                _t("The level of service linked to your Citadel account does not allow you to " +
                                    "initiate an audio conference with attendees of this show.")
                                :
                                _t("The level of service linked to your Citadel account does not allow you to " +
                                    "initiate a conference with attendees of this show."))
                    }
                </p>
                <p className="mx_ConferenceRequestDialog_p">
                    {_t(
                        "<b>Citadel Conference</b> allows you to organize, <c>in complete confidentiality</c> and with any Citadel users:",
                        {},
                        {
                            b: sub => <b>{sub}</b>,
                            c: sub => <b>{sub}</b>,
                        },
                    )}
                </p>
                <ul className="mx_ConferenceRequestDialog_ul">
                    <li>{_t(
                        "<b>Video conferences</b> + screen sharing for up to %(video)s participants",
                        { video },
                        { b: sub => <b>{sub}</b> },
                    )}</li>
                    <li>{_t(
                        "<b>Audio conferences</b> + screen sharing for up to %(audio)s participants",
                        { audio },
                        { b: sub => <b>{sub}</b> },
                    )}</li>
                </ul>
                <p className="mx_ConferenceRequestDialog_p">
                    {
                        _t("Test Citadel Conference for free for 30 days by clicking on the button below.")
                    }
                </p>
            </>
        );
    }

    noTrialContent = () => {
        return <>
            <div className={`mx_ConferenceRequestDialog_image`} />
            <span className="mx_Dialog_title">
                {_t('Want to try out Citadel Conference?')}
            </span>
            <p className="mx_ConferenceRequestDialog_p">
                {_t("You do not currently have the subscription required to start a Citadel conference.")}
                <br />
                {_t("You can request this service to your administrator.")}
            </p>
        </>;
    }

    render() {
        const { isTrialLicenseEnabled } = this.props;

        return <BaseDialog
            className={cx('mx_ConferenceRequestDialog', { 'no_trial': !isTrialLicenseEnabled })}
            title=""
            {...this.props}
        >
            <div className="mx_ConferenceRequestDialog_container">
                {isTrialLicenseEnabled ? this.trialContent() : this.noTrialContent()}
                <div className="mx_ConferenceRequestDialog_button_container">
                    <AccessibleButton
                        className="mx_ConferenceRequestDialog_button mx_AccessibleButton mx_AccessibleButton_hasKind mx_AccessibleButton_kind_primary"
                        onClick={this.onClick}
                    >
                        {
                            this.state.isLoading === true ? <Spinner />
                                : isTrialLicenseEnabled ? _t('Test for free') : _t('Request activation of Citadel services')
                        }
                    </AccessibleButton>
                </div>
            </div>
        </BaseDialog>;
    }
}

RequestDialog.defaultProps = {
    onFinished: () => { },
    type: 'audio',
    maxParticipants: {
        audio: 30,
        video: 10,
    },
};

RequestDialog.propTypes = {
    onFinished: PropTypes.func,
    type: PropTypes.string.isRequired,
    maxParticipants: PropTypes.shape({
        audio: PropTypes.number.isRequired,
        video: PropTypes.number.isRequired,
    }).isRequired,
};

export default RequestDialog;
