import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import BaseDialog from '../BaseDialog';
import { _t } from '../../../../languageHandler';
import doneSvg from '../../../../../res/img/done.svg';

const KeyRequesterInfoDialog = (props) => {
    const { currentDeviceName, onFinished } = props;

    const renderStep = (text) => <div className="step">
        <img src={doneSvg} alt="" />
        <div className="text">{text}</div>
    </div>;

    return (
        <BaseDialog
            className="mx_KeyRequesterInfoDialog"
            contentId="mx_Dialog_content"
            fixedWidth={false}
            hasCancel={false}
            onFinished={onFinished}
            title=""
        >
            <div className="icon" />
            <div className="title">{_t('Synchronize your devices')}</div>
            {renderStep(_t('A code will be sent to you on ' +
                'the « %(currentDeviceName)s » device.', { currentDeviceName }))}
            {renderStep(_t('Open the Citadel application on this device'))}
            {renderStep(_t('When you are ready, press the button below'))}
            <button onClick={onFinished}>{_t('Send my code')}</button>
        </BaseDialog>
    );
};

KeyRequesterInfoDialog.propTypes = {
    currentDeviceName: PropTypes.string.isRequired,
    onFinished: PropTypes.func.isRequired,
};

KeyRequesterInfoDialog.defaultProps = {
    currentDeviceName: '',
    onFinished: noop,
};

export default KeyRequesterInfoDialog;
