import * as React from 'react';

import BaseDialog from '../BaseDialog';
import { _t } from '../../../../languageHandler';
import CitadelInput from '../../elements/CitadelInput';
import AddImageButton from '../../elements/AddImageButton';
import DialogButtons from '../../elements/DialogButtons';
import dis from '../../../../dispatcher';
import NewsEditor from './NewsEditor';
import NewsStore from '../../../../stores/NewsStore';

class NewsEditionDialog extends React.Component {
    constructor(props) {
        super(props);
        const { news } = props;

        this.state = {
            content: news?.content || '',
            file: news?.file || '',
            previewName: news?.previewName || '',
            previewSrc: news?.previewSrc || '',
            title: news?.title || '',
        };
        this.fr = new FileReader();
    }

    componentDidMount() {
        const { news } = this.props;

        this.fr.onload = this._onReaderLoad;
        this.setState({ ...news });
    }

    onUpload = (e) => {
        const { files } = e.target;
        this.fr.readAsDataURL(files[0]);
        this.setState({
            file: files[0],
            previewName: files[0].name,
        });
    };

    _removeFile = () => this.setState({ file: '', previewSrc: '', previewName: '' });

    _onTitleChange = (e) => this.setState({ title: e.target.value });

    _onReaderLoad = () => {
        const url = this.fr.result;

        this.setState({ previewSrc: url });
    };

    saveNewsInStore = () => {
        const { content, title, file, previewSrc, previewName } = this.state;

        dis.dispatch(
            { action: 'update_news_creation_store', news: { content, file, previewName, previewSrc, title } },
            true,
        );
    };

    handleVisibilityChange = (visibility) => () => {
        const { onFinished } = this.props;

        this.saveNewsInStore();
        dis.dispatch({ action: 'update_news_visibility', visibility });
        if (visibility === 'reduced') onFinished();
    };

    handleEditorChange = (content) => this.setState({ content });

    handleSendNews = () => {
        const { onFinished, onSend } = this.props;

        this.saveNewsInStore();
        onSend(onFinished);
    };

    handleOnClose = () => {
        const { onClose, onFinished } = this.props;
        this.handleVisibilityChange('reduced')();
        NewsStore.showLeaveDialogIfNeeded(onClose(onFinished), this.handleVisibilityChange('main'));
    };

    render() {
        const { title, content, previewSrc, previewName } = this.state;

        return <BaseDialog
            className="mx_NewsEditionDialog"
            title={_t('Create news')}
            onFinished={this.handleOnClose}
            hasReduce
            onReduce={this.handleVisibilityChange('reduced')}
        >
            <CitadelInput
                label={_t('Put a title')}
                placeholder={_t('For example : Marketing News')}
                onChange={this._onTitleChange}
                value={title}
                required={true}
            />
            <AddImageButton
                onChange={this.onUpload}
                previewSrc={previewSrc}
                previewName={previewName}
                onRemove={this._removeFile}
                label={_t('Add an image')}
                required={true}
            />
            <NewsEditor content={content} onChange={this.handleEditorChange} />
            <div className="mandatory_field_info">
                <span className="required_mark">*</span>
                {_t("Mandatory field.")}
            </div>
            <DialogButtons
                disabled={!title || !content || !previewSrc}
                primaryButton={_t('Send')}
                cancelButton={_t('Preview')}
                onPrimaryButtonClick={this.handleSendNews}
                onCancel={this.handleVisibilityChange('preview')}
            />
        </BaseDialog>;
    }
}

export default NewsEditionDialog;
