/*
Copyright 2017 Travis Ralston
Copyright 2019 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import * as React from 'react';
import PropTypes from 'prop-types';

import { _t } from '../../languageHandler';
import MatrixClientPeg from '../../MatrixClientPeg';

import redDotSvg from '../../../res/img/red-dot.svg';

/**
 * Represents a tab for the TabbedView.
 */
export class Tab {
    /**
     * Creates a new tab.
     * @param {string} tabId The id for the tab..
     * @param {string} tabLabel The untranslated tab label.
     * @param {string} tabIconClass The class for the tab icon. This should be a simple mask.
     * @param {string} tabJsx The JSX for the tab container.
     */
    constructor(tabLabel, tabIconClass, tabJsx, tabId) {
        this.label = tabLabel;
        this.icon = tabIconClass;
        this.body = tabJsx;
        this.id = tabId;
    }
}

export class TabbedView extends React.Component {
    static propTypes = {
        // The tabs to show
        tabs: PropTypes.arrayOf(PropTypes.instanceOf(Tab)).isRequired,
        initialTabIndex: PropTypes.number,
    };

    constructor(props) {
        super(props);

        this.state = {
            activeTabIndex: props.initialTabIndex || 0,
        };
    }

    _getActiveTabIndex() {
        if (!this.state || !this.state.activeTabIndex) return 0;
        return this.state.activeTabIndex;
    }

    /**
     * Shows the given tab
     * @param {Tab} tab the tab to show
     * @private
     */
    _setActiveTab(tab) {
        const { tabs, redMarker } = this.props;

        const idx = tabs.indexOf(tab);
        if (idx !== -1) {
            if (redMarker.tabId === tab.id && redMarker.topic === 'mfa') {
                MatrixClientPeg.get().setAccountData('citadel.mfa', {
                    has_shown_settings_section: true,
                });
            }
            this.setState({activeTabIndex: idx});
        } else {
            console.error("Could not find tab " + tab.label + " in tabs");
        }
    }

    _renderTabLabel(tab) {
        const { tabs, redMarker } = this.props;

        let classes = "mx_TabbedView_tabLabel ";
        const idx = tabs.indexOf(tab);
        if (idx === this._getActiveTabIndex()) classes += "mx_TabbedView_tabLabel_active";

        const onClickHandler = () => this._setActiveTab(tab);

        return (
            <span className={classes} key={"tab_label_" + tab.label}
                  onClick={onClickHandler}>
                {tab.icon && <span className={`mx_TabbedView_maskedIcon ${tab.icon}`} />}
                <span className="mx_TabbedView_tabLabel_text">
                    {_t(tab.label)}
                </span>
                {redMarker.tabId === tab.id && <img src={redDotSvg} alt="" />}
            </span>
        );
    }

    _renderTabPanel(tab) {
        return (
            <div className="mx_TabbedView_tabPanel" key={"mx_tabpanel_" + tab.label}>
                <div className='mx_TabbedView_tabPanelContent'>
                    {tab.body}
                </div>
            </div>
        );
    }

    render() {
        const labels = this.props.tabs.map(tab => this._renderTabLabel(tab));
        const panel = this._renderTabPanel(this.props.tabs[this._getActiveTabIndex()]);

        return (
            <div className="mx_TabbedView">
                <div className="mx_TabbedView_tabLabels">
                    {labels}
                </div>
                {panel}
            </div>
        );
    }
}
