/*
Copyright 2015, 2016 OpenMarket Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { MatrixClient } from 'matrix-js-sdk';

import { KeyCode } from '../../Keyboard';
import sdk from '../../index';
import dis from '../../dispatcher';
import VectorConferenceHandler from '../../VectorConferenceHandler';
import TagPanelButtons from './TagPanelButtons';
import SettingsStore from '../../settings/SettingsStore';
import { _t } from '../../languageHandler';
import Analytics from '../../Analytics';
import MatrixClientPeg from '../../MatrixClientPeg';
import PlatformPeg from '../../PlatformPeg';
import CitadexStore from '../../stores/CitadexStore';


class LeftPanel extends React.Component {
    static displayName = 'LeftPanel';

    // NB. If you add props, don't forget to update
    // shouldComponentUpdate!
    static propTypes = {
        mutualizedHsWithPublicRoomList: PropTypes.array,
        collapsed: PropTypes.bool.isRequired,
        isHPS: PropTypes.bool.isRequired,
    };

    static contextTypes = {
        matrixClient: PropTypes.instanceOf(MatrixClient),
    };

    state = {
        breadcrumbs: false,
        displayPublicRoomButton: false,
        isConferenceMinimized: false,
        searchFilter: '',
        isSearchActive: false,
        searchResultsCount: 0,
    };

    componentWillUnmount() {
        SettingsStore.unwatchSetting(this._settingWatchRef);
        dis.unregister(this.dispatcherRef);
    }

    componentDidMount() {
        this.focusedElement = null;

        this._settingWatchRef = SettingsStore.watchSetting(
            "feature_room_breadcrumbs", null, this._onBreadcrumbsChanged);

        const useBreadcrumbs = SettingsStore.isFeatureEnabled("feature_room_breadcrumbs");
        Analytics.setBreadcrumbs(useBreadcrumbs);
        this.setState({breadcrumbs: useBreadcrumbs});
        const cli = MatrixClientPeg.get();
        const platform = PlatformPeg.get();
        cli.getSharedConf()
            .then(shared => {
                if (!shared) {
                    cli.publicRooms({ limit: 1 })
                        // eslint-disable-next-line camelcase
                        .then(({ total_room_count_estimate }) => {
                            this.setState({ displayPublicRoomButton: Boolean(total_room_count_estimate) });
                        });
                }
            });

        if (platform.isDesktop()) {
            if (SettingsStore.getValue("Conference.openConferenceInTab")) {
                CitadexStore.setIsCitadexOpened(false);
            } else {
                CitadexStore.clearSession();
            }
        }
        this.dispatcherRef = dis.register(this.onAction);
    }

    shouldComponentUpdate(nextProps, nextState) {
        // MatrixChat will update whenever the user switches
        // rooms, but propagating this change all the way down
        // the react tree is quite slow, so we cut this off
        // here. The RoomTiles listen for the room change
        // events themselves to know when to update.
        // We just need to update if any of these things change.
        if (
            this.props.collapsed !== nextProps.collapsed ||
            this.props.disabled !== nextProps.disabled ||
            this.state.searchFilter !== nextState.searchFilter ||
            this.state.isConferenceMinimized !== nextState.isConferenceMinimized ||
            this.state.displayPublicRoomButton !== nextState.displayPublicRoomButton ||
            this.state.searchResultsCount !== nextState.searchResultsCount ||
            this.state.isSearchActive !== nextState.isSearchActive
        ) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.breadcrumbs !== this.state.breadcrumbs) {
            Analytics.setBreadcrumbs(this.state.breadcrumbs);
        }
    }

    onAction = (payload) => {
        if (payload.action === 'minimize_conference') {
            if (!SettingsStore.getValue("Conference.openConferenceInTab")) {
                this.setState({ isConferenceMinimized: payload.isMinimized });
            }
        }
    }

    _onBreadcrumbsChanged = (settingName, roomId, level, valueAtLevel, value) => {
        // Features are only possible at a single level, so we can get away with using valueAtLevel.
        // The SettingsStore runs on the same tick as the update, so `value` will be wrong.
        this.setState({breadcrumbs: valueAtLevel});

        // For some reason the setState doesn't trigger a render of the component, so force one.
        // Probably has to do with the change happening outside of a change detector cycle.
        this.forceUpdate();
    };

    _onFocus = (ev) => {
        this.focusedElement = ev.target;
    };

    _onBlur = (ev) => {
        this.focusedElement = null;
    };

    _onKeyDown = (ev) => {
        if (!this.focusedElement) return;
        let handled = true;

        switch (ev.keyCode) {
            case KeyCode.TAB:
                this._onMoveFocus(ev.shiftKey);
                break;
            case KeyCode.UP:
                this._onMoveFocus(true);
                break;
            case KeyCode.DOWN:
                this._onMoveFocus(false);
                break;
            case KeyCode.ENTER:
                this._onMoveFocus(false);
                if (this.focusedElement) {
                    this.focusedElement.click();
                }
                break;
            default:
                handled = false;
        }

        if (handled) {
            ev.stopPropagation();
            ev.preventDefault();
        }
    };

    _onMoveFocus = (up) => {
        let element = this.focusedElement;

        // unclear why this isn't needed
        // var descending = (up == this.focusDirection) ? this.focusDescending : !this.focusDescending;
        // this.focusDirection = up;

        let descending = false; // are we currently descending or ascending through the DOM tree?
        let classes;

        do {
            const child = up ? element.lastElementChild : element.firstElementChild;
            const sibling = up ? element.previousElementSibling : element.nextElementSibling;

            if (descending) {
                if (child) {
                    element = child;
                } else if (sibling) {
                    element = sibling;
                } else {
                    descending = false;
                    element = element.parentElement;
                }
            } else {
                if (sibling) {
                    element = sibling;
                    descending = true;
                } else {
                    element = element.parentElement;
                }
            }

            if (element) {
                classes = element.classList;
                if (classes.contains("mx_LeftPanel")) { // we hit the top
                    element = up ? element.lastElementChild : element.firstElementChild;
                    descending = true;
                }
            }
        } while (element && !(
            classes.contains("mx_RoomTile") ||
            classes.contains("mx_textinput_search")));

        if (element) {
            element.focus();
            this.focusedElement = element;
            this.focusedDescending = descending;
        }
    };

    onHideClick = () => {
        dis.dispatch({
            action: 'hide_left_panel',
        });
    };

    onSearch = (term) => {
        this.setState({ searchFilter: term, isSearchActive: term !== '' });
    };

    onSearchCleared = (source) => {
        if (source === "keyboard") {
            dis.dispatch({action: 'focus_composer'});
        }
        this.setState({ isSearchActive: false });
    };

    collectRoomList = (ref) => {
        this._roomList = ref;
    };

    onSearchResultsCount = (searchResultsData) => {
        this.setState({searchResultsCount: searchResultsData});
    }

    render() {
        const RoomList = sdk.getComponent('rooms.RoomList');
        const RoomBreadcrumbs = sdk.getComponent('rooms.RoomBreadcrumbs');
        const TagPanel = sdk.getComponent('structures.TagPanel');
        const CustomRoomTagPanel = sdk.getComponent('structures.CustomRoomTagPanel');
        const TopLeftMenuButton = sdk.getComponent('structures.TopLeftMenuButton');
        const SearchBox = sdk.getComponent('structures.SearchBox');
        const CallPreview = sdk.getComponent('voip.CallPreview');
        const { isHPS } = this.props;
        const { displayPublicRoomButton, isConferenceMinimized } = this.state;
        const tagPanelEnabled = false;
        let tagPanelContainer;

        const isCustomTagsEnabled = SettingsStore.isFeatureEnabled("feature_custom_tags");

        if (tagPanelEnabled) {
            tagPanelContainer = (<div className="mx_LeftPanel_tagPanelContainer">
                <TagPanel />
                { isCustomTagsEnabled ? <CustomRoomTagPanel /> : undefined }
                <TagPanelButtons />
            </div>);
        }

        const containerClasses = cx(
            "mx_LeftPanel_container", "mx_fadable",
            {
                "collapsed": false,
                "mx_LeftPanel_container_hasTagPanel": tagPanelEnabled,
                "mx_fadable_faded": this.props.disabled,
            },
        );

        const searchBox = (
            <SearchBox
                collapsed={false}
                isHPS={isHPS}
                onCleared={ this.onSearchCleared }
                onSearch={ this.onSearch }
                placeholder={ _t('Filter room names') }
            />
        );

        let breadcrumbs;
        if (this.state.breadcrumbs) {
            breadcrumbs = (<RoomBreadcrumbs collapsed={false} />);
        }

        const publicRoomButton = displayPublicRoomButton
            ? (
                <div
                    className={"publicRoomButton"}
                    onClick={() => {
                        MatrixClientPeg.get().trackUserAction({
                            formId: 'publicRoom',
                            version: 1,
                            action: 'start',
                        });
                        dis.dispatch({action: 'view_room_directory'});
                    }}
                >
                    { _t('Browse list of public rooms') }
                </div>
            ) : null;


        return (
            <div className={containerClasses}>
                { tagPanelContainer }
                <aside
                    className={cx('mx_LeftPanel',
                        { 'with-conference-minimized': isConferenceMinimized,
                          'with-public-button': displayPublicRoomButton,
                        })
                    }
                    onKeyDown={ this._onKeyDown }
                    onFocus={ this._onFocus }
                    onBlur={ this._onBlur }
                >
                    <TopLeftMenuButton collapsed={false} isHPS={isHPS} />
                    { breadcrumbs }
                    { searchBox }
                    <div className='mx_searchResults'>
                    {
                        this.state.isSearchActive
                            ? _t(
                                'Search results %(searchResultsCount)s',
                                { searchResultsCount: this.state.searchResultsCount },
                            )
                            : ''
                    }
                    </div>
                    <CallPreview ConferenceHandler={VectorConferenceHandler} />
                    <RoomList
                        ref={this.collectRoomList}
                        resizeNotifier={this.props.resizeNotifier}
                        collapsed={false}
                        searchFilter={this.state.searchFilter}
                        searchResultsCount={this.onSearchResultsCount}
                        ConferenceHandler={VectorConferenceHandler} />
                    { publicRoomButton }
                </aside>
            </div>
        );
    }
}

module.exports = LeftPanel;
