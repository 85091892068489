import React from 'react';
import PropTypes from 'prop-types';

import { _t } from '../../../../languageHandler';

import BaseDialog from '../BaseDialog';
import PromoteNewFeaturesDialogContent from "./PromoteNewFeaturesDialogContent";

const PromoteNewFeaturesDialog = ({ onFinished, slides }) => {
    return (
        <BaseDialog
            className="new_features_dialog"
            fixedWidth={false}
            onFinished={onFinished}
            title={_t("What's new?").toUpperCase()}
        >
            <PromoteNewFeaturesDialogContent slides={slides} onFinished={onFinished} />
        </BaseDialog>
    );
};

PromoteNewFeaturesDialog.propTypes = {
    onFinished: PropTypes.func.isRequired,
    slides: PropTypes.arrayOf(Object).isRequired,
};

export default PromoteNewFeaturesDialog;
