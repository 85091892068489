/*
Copyright 2015, 2016 OpenMarket Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React from "react";
import PropTypes from 'prop-types';
import {ContentRepo} from "matrix-js-sdk";
import { isEqual } from 'lodash';
import MatrixClientPeg from "../../../MatrixClientPeg";
import Modal from '../../../Modal';
import sdk from "../../../index";
import { fetchUrlWithToken, getMxcFromUrl } from '../../../utils/MediaUtils';
import dis from '../../../dispatcher';
import MxcBlobStore from '../../../stores/MxcBlobStore';
import { isUserExternal } from '../../../utils/CitadelUtils';
import { isDirectMessageRoom } from '../../../Rooms';

module.exports = class extends React.Component {
    static displayName = 'RoomAvatar';

    // Room may be left unset here, but if it is,
    // oobData.avatarUrl should be set (else there
    // would be nowhere to get the avatar from)
    static propTypes = {
        room: PropTypes.object,
        oobData: PropTypes.object,
        width: PropTypes.number,
        height: PropTypes.number,
        resizeMethod: PropTypes.string,
        viewAvatarOnClick: PropTypes.bool,
    };

    static defaultProps = {
        width: 36,
        height: 36,
        resizeMethod: 'crop',
        oobData: {},
    };

    componentDidMount() {
        MatrixClientPeg.get().on("RoomState.events", this.onRoomStateEvents);
    }

    componentWillUnmount() {
        const cli = MatrixClientPeg.get();
        if (cli) {
            cli.removeListener("RoomState.events", this.onRoomStateEvents);
        }
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps, this.props)) {
            this.setState({
                urls: this.getImageUrls(this.props),
            });
        }
    }

    onRoomStateEvents = (ev) => {
        if (!this.props.room ||
            ev.getRoomId() !== this.props.room.roomId ||
            ev.getType() !== 'm.room.avatar'
        ) return;

        this.setState({
            urls: this.getImageUrls(this.props),
        });
    };

    getImageUrls = (props) => {
        return [
            ContentRepo.getHttpUriForMxc(
                MatrixClientPeg.get().getHomeserverUrl(),
                props.oobData.avatarUrl,
                Math.floor(props.width * window.devicePixelRatio),
                Math.floor(props.height * window.devicePixelRatio),
                props.resizeMethod,
            ), // highest priority
            this.getRoomAvatarUrl(props),
            this.getOneToOneAvatar(props), // lowest priority
        ].filter(function(url) {
            return (url != null && url != "");
        });
    };

    getRoomAvatarUrl = (props) => {
        if (!props.room) return null;

        return props.room.getAvatarUrl(
            MatrixClientPeg.get().getHomeserverUrl(),
            Math.floor(props.width * window.devicePixelRatio),
            Math.floor(props.height * window.devicePixelRatio),
            props.resizeMethod,
            false,
        );
    };

    getOtherMemberId = () => {
        const { room } = this.props;
        const { isDmRoom } = isDirectMessageRoom(room);
        if (!isDmRoom) return;

        const myUserId = room.myUserId;
        return Object.keys(room.currentState.members).filter(member => member !== myUserId)[0];
    }

    getOneToOneAvatar = (props) => {
        const room = props.room;
        if (!room) {
            return null;
        }
        let otherMember = null;
        const otherUserId = this.getOtherMemberId();
        if (otherUserId) {
            otherMember = room.getMember(otherUserId);
        } else {
            // if the room is not marked as a 1:1, but only has max 2 members
            // then still try to show any avatar (pref. other member)
            otherMember = room.getAvatarFallbackMember();
        }
        if (otherMember) {
            return otherMember.getAvatarUrl(
                MatrixClientPeg.get().getHomeserverUrl(),
                Math.floor(props.width * window.devicePixelRatio),
                Math.floor(props.height * window.devicePixelRatio),
                props.resizeMethod,
                false,
            );
        }
        return null;
    };

    onRoomAvatarClick = () => {
        const avatarUrl = this.props.room.getAvatarUrl(
            MatrixClientPeg.get().getHomeserverUrl(),
            null, null, null, false);
        if (!avatarUrl) return;

        const ImageView = sdk.getComponent("elements.ImageView");
        const mxc = getMxcFromUrl(avatarUrl);
        const blob = MxcBlobStore.getBlobFromMxc(mxc);

        if (blob) {
            const params = {
                src: blob,
                name: this.props.room.name,
            };
            Modal.createDialog(ImageView, params, "mx_Dialog_lightbox");
        } else {
            fetchUrlWithToken(avatarUrl)
            .then((blob) => {
                if (blob) {
                    dis.dispatch({
                        action: 'update_blob_store',
                        mxc: mxc,
                        blob: blob,
                    });
                    const params = {
                        src: blob,
                        name: this.props.room.name,
                    };
                    Modal.createDialog(ImageView, params, "mx_Dialog_lightbox");
                }
            })
            .catch((err) => {
                console.error('Could not retrieve url content', err);
            });
        }
    };

    state = {
        urls: this.getImageUrls(this.props),
    };

    render() {
        const BaseAvatar = sdk.getComponent("avatars.BaseAvatar");

        /*eslint no-unused-vars: ["error", { "ignoreRestSiblings": true }]*/
        const {room, oobData, viewAvatarOnClick, ...otherProps} = this.props;

        const roomName = room ? room.name : oobData.name;
        let userId; let isExternal = false;
        const { isDmRoom } = isDirectMessageRoom(this.props.room);
        if (isDmRoom) {
            userId = this.getOtherMemberId();
            isExternal = userId && isUserExternal(userId);
        }
        return (
            <BaseAvatar {...otherProps} isExternal={!!isExternal} name={roomName}
                idName={room ? room.roomId : null}
                urls={this.state.urls}
                onClick={this.props.viewAvatarOnClick ? this.onRoomAvatarClick : null}
                disabled={!this.state.urls[0]} />
        );
    }
};
