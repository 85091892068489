import React from 'react';
import cx from 'classnames';

const GenericToolTip = ({ label, children, labelStyle = '', disabled }) => {
    return (
        <div className={cx('generic_tooltip', { disabled })}>
            {children}
            {label ? <span className={`generic_tooltip_text ${labelStyle}`}>{label}</span> : null}
        </div>
    );
};

export default GenericToolTip;
