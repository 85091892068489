import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import CitadelAccordionItem from './CitadelAccordionItem';

class CitadelAccordion extends Component {
    static propTypes = {
        className: PropTypes.string,
        allowMultipleOpen: PropTypes.bool,
        children: PropTypes.instanceOf(Object).isRequired,
        title: PropTypes.string.isRequired,
    };

    static defaultProps = {
        allowMultipleOpen: false,
    };

    constructor(props) {
        super(props);

        const { children } = this.props;

        const openSections = {};
        const components = children.length ? children : [children];

        for (const component of components) {
            if (!component || !component.props) continue;
            if (component.props.isOpen) {
                openSections[component.props.id] = true;
            }
        }

        this.state = { openSections };
    }

    onClick = (id) => {
        const {
            props: { allowMultipleOpen },
            state: { openSections },
        } = this;

        const isOpen = Boolean(openSections[id]);

        if (allowMultipleOpen) {
            this.setState({
                openSections: {
                    ...openSections,
                    [id]: !isOpen,
                },
            });
        } else {
            this.setState({
                openSections: {
                    [id]: !isOpen,
                },
            });
        }
    };

    renderHeader = () => {
        const {title} = this.props;

        if (!title) return null;

        return (
            <div className="header">
                <div className="title">
                    {title}
                </div>
            </div>
        );
    }


    renderAccordionItems = () => {
        const {
            onClick,
            state: { openSections },
            props: { children },
        } = this;

        const components = children.length ? children : [children];

        return Object.values(components).map(({ props }) => (
            props ?
            <CitadelAccordionItem
                isOpen={Boolean(openSections[props.id])}
                label={props.label}
                onClick={onClick}
                id={props.id}
                key={props.id}
            >
                {props.children}
            </CitadelAccordionItem> : null
        ));
    };

    render() {
        const { className } = this.props;

        return (
            <div className={cx('mx_CitadelAccordion', className)}>
                {this.renderHeader()}
                {this.renderAccordionItems()}
            </div>
        );
    }
}

export default CitadelAccordion;
