import React from 'react';
import PropTypes from 'prop-types';
import { _t } from '../../../languageHandler';
import emailNotifIcon from '../../../../res/img/email-sent.svg';
import BaseDialog from '../dialogs/BaseDialog';

class EmailTokenSentDialog extends React.Component {
    componentDidMount() {
        setTimeout(this.props.onFinished, 2000);
    }

    render() {
        const { email } = this.props;

        const text = _t('A new verification code has been sent to you at ');

        const dialogContent = (
            <div className="mx_EmailTokenSentDialog">
                <div className="icon">
                    <img src={emailNotifIcon} />
                </div>
                <div className="title">
                    { _t('New code sent') }
                </div>
                <div className="text">
                    { text }
                    <span>
                        { email }
                    </span>
                </div>
            </div>
        );

        return (
            <BaseDialog
                title=''
                onFinished={()=>{}}
                hasCancel={false}
                isFocusable={false}
            >
                { dialogContent }
            </BaseDialog>
        );
    }
}

EmailTokenSentDialog.propTypes = {
    email: PropTypes.string.isRequired,
};

export default EmailTokenSentDialog;
