import React from 'react';
import ReactDOM from 'react-dom';
import { map } from 'lodash';
import PropTypes from 'prop-types';

import ParticipantRow from './ParticipantRow';
import { _t } from '../../../languageHandler';
import micOff from '../../../../res/img/conference/mic-off-no-bg.svg';
import SettingsStore from '../../../settings/SettingsStore';

export default class InConferenceFullList extends React.Component {
    componentDidMount() {
        const scrollPanel = document.querySelector('.mx_ScrollPanel');

        if (scrollPanel) scrollPanel.style.overflow = 'hidden';

        this.renderOverlay();
    }

    componentWillUnmount() {
        const scrollPanel = document.querySelector('.mx_ScrollPanel');

        if (scrollPanel) scrollPanel.style.overflow = '';

        ReactDOM.unmountComponentAtNode(this.getOrCreateOverlayContainer());
    }

    getOrCreateOverlayContainer = () => {
        let container;

        if (SettingsStore.getValue("Conference.openConferenceInTab")) {
            const DIALOG_CONTAINER_ID = 'citadex_visio_full_list_overlay_container';
            container = document.getElementById(DIALOG_CONTAINER_ID);
            if (!container) {
                container = document.createElement('div');
                container.id = DIALOG_CONTAINER_ID;
                document.body.appendChild(container);
            }
        } else {
            const DIALOG_CONTAINER_ID = 'citadex_overlay_container';
            const targetElement = document.getElementsByClassName('conference-element')[0];
            container = document.getElementById(DIALOG_CONTAINER_ID);
            if (!container) {
                container = document.createElement('div');
                container.id = DIALOG_CONTAINER_ID;
                targetElement.appendChild(container);
            }
        }
        return container;
    };

    renderOverlay = () => {
        const conferenceInNewTab = SettingsStore.getValue("Conference.openConferenceInTab");
        const container = this.getOrCreateOverlayContainer();
        const overlay = conferenceInNewTab ? <div className='citadex_visio_full_list_overlay' onClick={this.props.onClose} /> :
        <div className='citadex_overlay' onClick={this.props.onClose} />;

        ReactDOM.render(overlay, container);
    };

    render() {
        const { onMute, onKick, participants, handleMuteAll, isInviter } = this.props;
        const numberOfParticipants = Object.keys(participants).length;

        return <div className='citadex_visio_full_list_container'>
            <div className="citadex_visio_full_list_participants">
                Participants ({numberOfParticipants})
            </div>
            {isInviter && <div className="citadex_visio_full_list_mute_all" onClick={handleMuteAll}>
                <img src={micOff} className="citadex_visio_full_list_mute_icon" />
                {_t('Mute all participants')}
            </div>}
            {map(participants, ({ id, display, displayName, isAudioEnabled, talking }) =>
                    <ParticipantRow
                        isSmall
                        key={id}
                        muted={!isAudioEnabled}
                        userId={display}
                        userName={displayName}
                        onMute={onMute(id)}
                        onKick={onKick(display, displayName)}
                        talking={talking}
                    />,
                )
            }
        </div>;
    }
}

InConferenceFullList.propTypes = {
    handleMuteAll: PropTypes.func.isRequired,
    isInviter: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onKick: PropTypes.func.isRequired,
    onMute: PropTypes.func.isRequired,
    participants: PropTypes.object.isRequired,
};
