import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ContentRepo } from 'matrix-js-sdk';

import { fetchUrlWithToken } from '../../../../utils/MediaUtils';
import MatrixClientPeg from '../../../../MatrixClientPeg';
import { _t } from '../../../../languageHandler';
import { getDateAndTime } from '../../../../DateUtils';
import UserStore from '../../../../stores/UserStore';
import { isUserMxId } from '../../../../UserAddress';
import BaseDialog from '../BaseDialog';
import NewsDialogContent from './NewsDialogContent';

class NewsDialog extends Component {
    static displayName = "NewsDialog";

    static propTypes = {
        mxEvent: PropTypes.object.isRequired,
        onFinished: PropTypes.func.isRequired,
    };

    state = {
        imageUrl: null,
        readCount: 0,
    };

    componentDidMount() {
        const { mxEvent } = this.props;
        const imageMxc = mxEvent.getContent().newsimg;

        if (!imageMxc) return;

        const imageUriForMxc = ContentRepo.getHttpUriForMxc(
            MatrixClientPeg.get().getHomeserverUrl(),
            imageMxc,
        );

        fetchUrlWithToken(imageUriForMxc)
        .then((blob) => {
            if (blob) {
                this.setState({
                    imageUrl: blob,
                });
            }
        })
        .catch((err) => {
            console.error('Could not retrieve url content ', err);
        });

        this.getReadCount();
    }

    getReadCount = () => {
        const { mxEvent } = this.props;
        const eventId = mxEvent.getId();
        MatrixClientPeg.get().getNewsMessageOpenCounter(eventId)
            .then(res => this.setState({readCount: res.user_readings}));
    }

    render() {
        const { mxEvent, onFinished } = this.props;
        const { imageUrl, readCount } = this.state;

        const content = mxEvent.getContent();
        const senderUserId = mxEvent.getSender();
        const eventId = mxEvent.getId();
        const senderFromStore = UserStore.getUserById(senderUserId).displayName;
        const name = (senderFromStore && !isUserMxId(senderFromStore)) ?
            senderFromStore : (mxEvent.sender && mxEvent.sender.name) || senderUserId;
        const subtitle = `${_t('Published by %(name)s on', { name })} ${getDateAndTime(new Date(mxEvent.getTs()))}`;
        const readCountDisplay = _t('Read by : %(readCount)s person(s)', { readCount });

        return (
            <BaseDialog
                title=""
                hasCancel={true}
                onFinished={onFinished}
            >
                <NewsDialogContent
                    content={content.newsbody}
                    imageUrl={imageUrl}
                    subtitle={subtitle}
                    readCountDisplay={readCountDisplay}
                    title={content.newstitle}
                    eventId={eventId}
                />
            </BaseDialog>
        );
    }
}

export default NewsDialog;
