import React from 'react';

import BaseDialog from './BaseDialog';
import { _t } from '../../../languageHandler';
import updateSvg from '../../../../res/img/delete-success.svg';

class RoomDeleteSuccessDialog extends React.Component {
    componentDidMount() {
        setTimeout(this.props.onFinished, 4000);
    }
    render() {
        const { className, onFinished } = this.props;
        return (
            <BaseDialog
                className={className}
                fixedWidth={false}
                hasCancel={false}
                onFinished={onFinished}
                title=""
            >
                <div className="content">
                    <div className="header">
                        <img src={updateSvg} alt="" />
                        <div className="title">
                            {_t('Room successfully deleted')}
                        </div>
                    </div>
                </div>
            </BaseDialog>
        );
    }
}

export default RoomDeleteSuccessDialog;
