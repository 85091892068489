export const getBrowserName = () => {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
        case agent.indexOf("electron") > -1: return "electron"; // electron app
        case agent.indexOf("edge") > -1: return "edgeOld"; // legacy chrome
        case agent.indexOf("edg") > -1: return "edge"; // chromium based
        case agent.indexOf("opr") > -1 && !!window.opr: return "opera"; // chromium based
        case agent.indexOf("chrome") > -1 && !!window.chrome: return "chrome";
        case agent.indexOf("firefox") > -1: return "firefox";
        case agent.indexOf("safari") > -1: return "safari";
        default: return "other";
    }
};
