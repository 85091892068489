import { Store } from 'flux/utils';

import dis from '../dispatcher';

const MAX_TOAST_LIST_LENGTH = 4;
const ERROR_TYPES = {
    MESSAGE: 'message',
    ID: 'id',
};

export const LIST_TYPE = {
    DEFAULT: 'default',
    CITADEX: 'citadex',
};

class ToastStore extends Store {
    constructor() {
        super(dis);

        this.nextId = {};
        this.toastList = {};
        this.lastAddedTimeStamp = {};

        for (const listType in LIST_TYPE) {
            if (LIST_TYPE.hasOwnProperty(listType)) {
                this.init(LIST_TYPE[listType]);
            }
        }
    }

    init = (listType) => {
        this.nextId[listType] = 1;
        this.toastList[listType] = [];
        this.lastAddedTimeStamp[listType] = null;
    };

    isEventOk = (type, value) => {
        if (type === ERROR_TYPES.MESSAGE) {
            if (!value.message && !value.title) {
                console.log('Can not add toast: message or title is required');
                return false;
            }
        }
        if (type === ERROR_TYPES.ID) {
            if (!value[ERROR_TYPES.ID]) {
                console.log('Can not remove toast: id is missing');
                return false;
            }
        }
        return true;
    }

    getId = (listType) => this.nextId[listType];

    getFirstElPositionWithoutAutoClose = (listType) => this.toastList[listType].findIndex((el) => el.autoClose);

    addToast = (data) => {
        const { listType } = data;
        const currentId = this.nextId[listType];
        this.nextId[listType] ++;
        if (this.toastList[listType].length >= MAX_TOAST_LIST_LENGTH) {
            // in order to keep the maxim length we need to remove one element
            const elIndexWithoutAutoClose = this.getFirstElPositionWithoutAutoClose(listType);
            if (elIndexWithoutAutoClose > -1) {
                // remove the first element that has autoClose
                this.toastList[listType].splice(elIndexWithoutAutoClose, 1);
            } else {
                // just delete the oldest one
                this.toastList[listType].shift();
            }
        }
        const newToast = { id: currentId, ...data };
        this.toastList[listType].push(newToast);
        if (data.emitToastData) dis.dispatch({ action: 'toast_added', value: newToast });

        this.__emitChange();
    }

    removeToast = (data) => {
        const { listType } = data;
        let toast;
        if (data.emitToastData) {
            const toastIndex = this.toastList[listType].findIndex((el) => el.id === data.id);
            if (toastIndex >= 0) {
                toast = this.toastList[listType][toastIndex];
            }
        }
        this.toastList[listType] = this.toastList[listType].filter(({id}) => id !== data.id);

        if (toast) dis.dispatch({ action: 'toast_removed', value: toast });
        if (!this.toastList[listType].length) this.lastAddedTimeStamp[listType] = null;
        this.__emitChange();
    }

    getToastList = (listType = 'default') => this.toastList[listType];

    alreadyHasToast = (checkBy, value, listType) =>
        this.toastList[listType].findIndex((el) => el[checkBy] === value) > -1;

    __onDispatch(payload) {
        const { action, value: payloadValue } = payload;
        const value = { ...payloadValue };
        if (!value.listType) {
            value.listType = LIST_TYPE.DEFAULT;
        }
        switch (action) {
            case "reset_toast_store":
                this.init(value.listType);
                break;
            case 'add_toast':
                if (this.isEventOk(ERROR_TYPES.MESSAGE, value)) {
                    let shouldAdd = false;
                    if (!value.onlyOnce) {
                        shouldAdd = true;
                    }
                    if (value.onlyOnce && !this.alreadyHasToast('title', value.title, value.listType)) {
                        shouldAdd = true;
                    }
                    if (shouldAdd) {
                        const eventTimeStamp = Date.now();
                        if (this.toastList[value.listType].length > 0 && !value.forceAdd) {
                            let delay = 1000;
                            if (this.toastList[value.listType].length >= MAX_TOAST_LIST_LENGTH) {
                                const elIndexWithoutAutoClose = this.getFirstElPositionWithoutAutoClose();
                                delay += eventTimeStamp - this.lastAddedTimeStamp[value.listType];
                                elIndexWithoutAutoClose > -1 && setTimeout(() => {
                                    dis.dispatch({
                                        action: 'close_toast',
                                        value: {
                                            id: this.toastList[elIndexWithoutAutoClose].id,
                                            listType: value.listType,
                                        },
                                    });
                                });
                            }
                            setTimeout(() => {
                                dis.dispatch({
                                    action: 'add_toast',
                                    value: { ...value, forceAdd: true },
                                });
                            }, delay);
                            break;
                        } else {
                            this.lastAddedTimeStamp[value.listType] = Date.now();
                            this.addToast({ ...value, timeStamp: this.lastAddedTimeStamp[value.listType] });
                        }
                    }
                }
                break;
            case 'remove_toast':
                this.removeToast(value);
                break;
        }
    }
}

let singletonToastStore = null;
if (!singletonToastStore) {
    singletonToastStore = new ToastStore();
}

export default singletonToastStore;

