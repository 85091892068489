/*
Copyright 2017 Vector Creations Ltd
Copyright 2018 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { Component } from 'react';
import dis from '../../../dispatcher';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { Room, RoomMember, MatrixClient } from 'matrix-js-sdk';
import PropTypes from 'prop-types';
import MatrixClientPeg from '../../../MatrixClientPeg';
import linkifyMatrix from '../../../linkify-matrix';
import { getDisplayAliasForRoom } from '../../../Rooms';
import FlairStore from "../../../stores/FlairStore";

const REGEX_STANDARD = new RegExp(linkifyMatrix.STANDARD_URL_PATTERN);
// For URLs of matrix.to links in the timeline which have been reformatted by
// HttpUtils transformTags to relative links. This excludes event URLs (with `[^\/]*`)
const REGEX_LOCAL_MATRIXTO = /^#\/(?:user|room|group)\/(([#!@+])[^/]*)$/;

class Pill extends Component {
    static isPillUrl = (url) => {
        return !!REGEX_STANDARD.exec(url);
    }
    static isMessagePillUrl = (url) => {
        return !!REGEX_LOCAL_MATRIXTO.exec(url);
    }
    static roomNotifPos = (text) => {
        const AtRoom = text.indexOf("@room");
        const AtAll = text.indexOf("@all");
        if ((AtRoom === -1)&&(AtAll === -1)) return -1;
        else {
            if ((AtRoom === -1)&&(AtAll >= 0)) return AtAll;
            if ((AtRoom >= 0)&&(AtAll === -1)) return AtRoom;
            if (AtAll>AtRoom) return AtRoom;
            else return AtAll;
        }
    }
    static roomNotifLen = (text) => {
        const AtRoom = text.indexOf("@room");
        const AtAll = text.indexOf("@all");
        if (AtRoom >= 0) return "@room".length;
        if (AtAll >= 0) return "@all".length;
        return "@room".length;
    }
    static TYPE_USER_MENTION = 'TYPE_USER_MENTION'
    static TYPE_ROOM_MENTION = 'TYPE_ROOM_MENTION'
    static TYPE_GROUP_MENTION = 'TYPE_GROUP_MENTION'
    static TYPE_AT_ROOM_MENTION = 'TYPE_AT_ROOM_MENTION' // '@room' mention
    static TYPE_AT_ALL_MENTION = 'TYPE_AT_ALL_MENTION' // '@all' mention

    static propTypes = {
        // The Type of this Pill. If url is given, this is auto-detected.
        type: PropTypes.string,
        // The URL to pillify (no validation is done, see isPillUrl and isMessagePillUrl)
        url: PropTypes.string,
        // Whether the pill is in a message
        inMessage: PropTypes.bool,
        // The room in which this pill is being rendered
        room: PropTypes.instanceOf(Room),
        // Whether to include an avatar in the pill
        shouldShowPillAvatar: PropTypes.bool,
        // Whether to render this pill as if it were highlit by a selection
        isSelected: PropTypes.bool,
    }

    static childContextTypes = {
        matrixClient: PropTypes.instanceOf(MatrixClient),
    }

    getChildContext = () => ({ matrixClient: this._matrixClient })

    constructor(props) {
        super(props);
        const defaultState = {
            // ID/alias of the room/user
            resourceId: null,
            // Type of pill
            pillType: null,

            // The member related to the user pill
            member: null,
            // The group related to the group pill
            group: null,
            // The room related to the room pill
            room: null,
        };
        this.state = this.processProps(this.props, defaultState);
    }
    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps, this.props)) {
            const newState = this.processProps(this.props, this.state);
            this.setState(newState);
        }
    }

    processProps(nextProps) {
        let regex = REGEX_STANDARD;
        if (nextProps.inMessage) {
            regex = REGEX_LOCAL_MATRIXTO;
        }

        let matrixToMatch;
        let resourceId;
        let prefix;

        if (nextProps.url) {
            // Default to the empty array if no match for simplicity
            // resource and prefix will be undefined instead of throwing
            matrixToMatch = regex.exec(nextProps.url) || [];

            resourceId = matrixToMatch[1]; // The room/user ID
            prefix = matrixToMatch[2]; // The first character of prefix
        }

        const pillType = nextProps.type || {
            '@': Pill.TYPE_USER_MENTION,
            '#': Pill.TYPE_ROOM_MENTION,
            '!': Pill.TYPE_ROOM_MENTION,
            '+': Pill.TYPE_GROUP_MENTION,
        }[prefix];

        let member;
        let room;
        switch (pillType) {
            case Pill.TYPE_AT_ROOM_MENTION: {
                room = nextProps.room;
            }
                break;
            case Pill.TYPE_AT_ALL_MENTION: {
                room = nextProps.room;
            }
                break;
            case Pill.TYPE_USER_MENTION: {
                const localMember = nextProps.room.getMember(resourceId);
                member = localMember;
                if (!localMember) {
                    member = new RoomMember(null, resourceId);
                    this.doProfileLookup(resourceId, member);
                }
            }
                break;
            case Pill.TYPE_ROOM_MENTION: {
                const localRoom = resourceId[0] === '#' ?
                    MatrixClientPeg.get().getRooms().find((r) => {
                        return r.getAliases().includes(resourceId);
                    }) : MatrixClientPeg.get().getRoom(resourceId);
                room = localRoom;
                if (!localRoom) {
                    // TODO: This would require a new API to resolve a room alias to
                    // a room avatar and name.
                    // this.doRoomProfileLookup(resourceId, member);
                }
            }
                break;
            case Pill.TYPE_GROUP_MENTION: {
                this.handleGroupMention(resourceId);
            }
        }
        return {
            resourceId,
            pillType,
            member,
            room,
        };
    }

    handleGroupMention = async (resourceId) => {
        const cli = MatrixClientPeg.get();
        let group;
        try {
            group = FlairStore.getGroupProfileCached(cli, resourceId);
        } catch (e) {
                // if FlairStore failed, fall back to just groupId
            group = {
                groupId: resourceId,
                avatarUrl: null,
                name: null,
            };
        }
        this.setState({ group });
    }

    async componentDidMount() {
        this._unmounted = false;
        this._matrixClient = MatrixClientPeg.get();
        const newState = await this.processProps(this.props, this.state);
        this.setState(newState);
    }

    componentWillUnmount() {
        this._unmounted = true;
    }

    doProfileLookup = (userId, member) => {
        MatrixClientPeg.get().getProfileInfo(userId).then((resp) => {
            if (this._unmounted) {
                return;
            }
            member.name = resp.displayname;
            member.rawDisplayName = resp.displayname;
            member.events.member = {
                getContent: () => {
                    return {avatar_url: resp.avatar_url};
                },
                getDirectionalContent: function() {
                    return this.getContent();
                },
            };
            this.setState({member});
        }).catch((err) => {
            console.error('Could not retrieve profile data for ' + userId + ':', err);
        });
    }

    onUserPillClicked = () => {
        dis.dispatch({
            action: 'view_user',
            member: this.state.member,
        });
    }

    render() {
        const resource = this.state.resourceId;

        let linkText = resource;
        let pillClass;
        let userId;
        let href = this.props.url;
        let onClick;
        switch (this.state.pillType) {
            case Pill.TYPE_AT_ROOM_MENTION: {
                const room = this.props.room;
                if (room) {
                    linkText = "@room";
                    pillClass = 'mx_AtRoomPill';
                }
            }
                break;
            case Pill.TYPE_AT_ALL_MENTION: {
                const room = this.props.room;
                if (room) {
                    linkText = "@all";
                    pillClass = 'mx_AtRoomPill';
                }
            }
                break;
            case Pill.TYPE_USER_MENTION: {
                    // If this user is not a member of this room, default to the empty member
                    const member = this.state.member;
                    if (member) {
                        userId = member.userId;
                        member.rawDisplayName = member.rawDisplayName || '';
                        linkText = member.rawDisplayName;
                        pillClass = 'mx_UserPill';
                        href = null;
                        onClick = this.onUserPillClicked;
                    }
            }
                break;
            case Pill.TYPE_ROOM_MENTION: {
                const room = this.state.room;
                if (room) {
                    linkText = (room ? getDisplayAliasForRoom(room) : null) || resource;
                    pillClass = 'mx_RoomPill';
                }
            }
                break;
            case Pill.TYPE_GROUP_MENTION: {
                if (this.state.group) {
                    const { groupId } = this.state.group;
                    linkText = groupId;
                    pillClass = 'mx_GroupPill';
                }
            }
                break;
        }

        const classes = classNames("mx_Pill", pillClass, {
            "mx_UserPill_me": userId === MatrixClientPeg.get().credentials.userId,
            "mx_UserPill_selected": this.props.isSelected,
        });

        if (this.state.pillType) {
            return this.props.inMessage ?
                <a className={classes} href={href} onClick={onClick} title={resource} data-offset-key={this.props.offsetKey}>
                    { linkText }
                </a> :
                <span className={classes} title={resource} data-offset-key={this.props.offsetKey}>
                    { linkText }
                </span>;
        } else {
            // Deliberately render nothing if the URL isn't recognised
            return null;
        }
    }
}

export default Pill;
