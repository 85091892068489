/*
Copyright 2016 OpenMarket Ltd
Copyright 2017, 2018 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React from 'react';

import MatrixClientPeg from './MatrixClientPeg';
import MultiInviter from './utils/MultiInviter';
import Modal from './Modal';
import { getAddressType } from './UserAddress';
import createRoom from './createRoom';
import sdk from './';
import dis from './dispatcher';
import DMRoomMap from './utils/DMRoomMap';
import { _t } from './languageHandler';
import HintManager from './HintManager';
import UserStore from './stores/UserStore';
import { validateEmail } from "./utils/CitadelUtils";

/**
 * Invites multiple addresses to a room
 * Simpler interface to utils/MultiInviter but with
 * no option to cancel.
 *
 * @param {string} roomId The ID of the room to invite to
 * @param {string[]} addrs Array of strings of addresses to invite. May be matrix IDs or 3pids.
 * @returns {Promise} Promise
 */
function inviteMultipleToRoom(roomId, addrs) {
    const inviter = new MultiInviter(roomId);
    return inviter.invite(addrs).then(states => Promise.resolve({states, inviter}));
}

export function showStartChatInviteDialog() {
    const AddressPickerDialog = sdk.getComponent("dialogs.AddressPickerDialog");

    Modal.createTrackedDialog('Start a chat', '', AddressPickerDialog, {
        title: _t('Start a chat'),
        description: _t("Who would you like to communicate with?"),
        placeholder: _t("Email, name or Citadel ID"),
        validAddressTypes: ['mx-user-id', 'email'],
        button: _t("Start Chat"),
        dialogType: 'chat',
        onFinished: _onStartChatFinished,
    });
}

export function showRoomInviteDialog(roomId) {
    const AddressPickerDialog = sdk.getComponent("dialogs.AddressPickerDialog");
    Modal.createTrackedDialog('Chat Invite', '', AddressPickerDialog, {
        title: _t('Invite new room members'),
        description: _t('Who would you like to add to this room?'),
        button: _t('Send Invites'),
        placeholder: _t("Email, name or Citadel ID"),
        roomId: roomId,
        dialogType: 'room',
        onFinished: (shouldInvite, addrs) => {
            HintManager.checkPendingHints();
            _onRoomInviteFinished(roomId, shouldInvite, addrs);
        },
    });
}

/**
 * Checks if the given MatrixEvent is a valid 3rd party user invite.
 * @param {MatrixEvent} event The event to check
 * @returns {boolean} True if valid, false otherwise
 */
export function isValid3pidInvite(event) {
    if (!event || event.getType() !== "m.room.third_party_invite") return false;

    // any events without these keys are not valid 3pid invites, so we ignore them
    const requiredKeys = ['key_validity_url', 'public_key', 'display_name'];
    for (let i = 0; i < requiredKeys.length; ++i) {
        if (!event.getContent()[requiredKeys[i]]) return false;
    }

    // Valid enough by our standards
    return true;
}

export async function getDirectMessageRooms(addr) {
    const isEmail = getAddressType(addr) === 'email';
    const user = isEmail ? UserStore.getUserByEmail(addr) :
        UserStore.getUserById(addr);

    if (user.userId && !user.email) {
        const { address: email } = await MatrixClientPeg.get().getProfileInfo(user.userId);
        user.email = email;

        dis.dispatch({
            action: 'USER_STORE_SAVE_USER',
            user,
        });
    }

    // Double check existing dm rooms with email address then with userId
    // Depending on the address type invitation, dm rooms
    // are stored in the account data either with the email address
    // or the userId.

    const _getDirectMessageRooms = address => {
        const dmRoomMap = new DMRoomMap(MatrixClientPeg.get());
        const dmRooms = dmRoomMap.getDMRoomsForUserAddress(address);
        return dmRooms.filter((dmRoom) => {
            const room = MatrixClientPeg.get().getRoom(dmRoom);
            if (room) {
                return room.getMyMembership() === 'join';
            }
        });
    };

    const dmRoomsByUserId = user.userId ? _getDirectMessageRooms(user.userId) : [];
    const dmRoomsByEmail = user.email ? _getDirectMessageRooms(user.email) : [];

    return [...dmRoomsByUserId, ...dmRoomsByEmail];
}

async function _onStartChatFinished(shouldInvite, addrs, isEncrypted = false) {
    if (!shouldInvite) {
        MatrixClientPeg.get().trackUserAction({
            formId: 'chatCreation',
            version: 1,
            action: 'cancel',
        });
        HintManager.checkPendingHints();
        return;
    }

    const addrTexts = addrs.map((addr) => addr.address);
    const createNewDMRoom = () => {
        createRoom({ dmUserId: addrTexts[0], createOpts: { isEncrypted } })
            .catch((err) => {
                MatrixClientPeg.get().trackUserAction({
                    formId: 'chatCreation',
                    version: 1,
                    action: 'error',
                    code: err.code,
                    reason: err.message,
                    step: 'start',
                });
                const ErrorDialog = sdk.getComponent("dialogs.ErrorDialog");
                Modal.createTrackedDialog('Failed to invite user', '', ErrorDialog, {
                    title: _t("Failed to invite user"),
                    description: ((err && err.message) ? err.message : _t("Operation failed")),
                });
            });
        HintManager.checkPendingHints();
    };

    if (_isDmChat(addrTexts)) {
        const rooms = await getDirectMessageRooms(addrTexts[0]);
        if (rooms.length > 0) {
            // A Direct Message room already exists for this user, so select a
            // room from a list that is similar to the one in MemberInfo panel
            const ChatCreateOrReuseDialog = sdk.getComponent("views.dialogs.ChatCreateOrReuseDialog");
            const close = Modal.createTrackedDialog('Create or Reuse', '', ChatCreateOrReuseDialog, {
                address: addrTexts[0],
                onFinished: () => {
                    HintManager.checkPendingHints();
                },
                onNewDMClick: () => {
                    HintManager.checkPendingHints();
                    dis.dispatch({
                        action: 'start_chat',
                        user_id: addrTexts[0],
                    });
                    close(true);
                },
                onExistingRoomSelected: (roomId) => {
                    HintManager.checkPendingHints();
                    dis.dispatch({
                        action: 'view_room',
                        room_id: roomId,
                    });
                    close(true);
                },
            }).close;
        } else createNewDMRoom();
    }
}

function _onRoomInviteFinished(roomId, shouldInvite, addrs) {
    if (!shouldInvite) return;

    const addrTexts = addrs.map((addr) => addr.address);

    // Invite new users to a room
    inviteMultipleToRoom(roomId, addrTexts).then((result) => {
        const room = MatrixClientPeg.get().getRoom(roomId);
        return _showAnyInviteErrors(result.states, room, result.inviter);
    }).catch((err) => {
        console.error(err.stack);
        const ErrorDialog = sdk.getComponent("dialogs.ErrorDialog");
        Modal.createTrackedDialog('Failed to invite', '', ErrorDialog, {
            title: _t("Failed to invite"),
            description: ((err && err.message) ? err.message : _t("Operation failed")),
        });
    });
}

function _isDmChat(addrTexts) {
    const addressType = getAddressType(addrTexts[0]);
    return addrTexts.length === 1 && (addressType === 'mx-user-id' || addressType === 'email');
}

async function _showAnyInviteErrors(addrs, room, inviter) {
    // Show user any errors
    const failedUsers = Object.keys(addrs).filter(a => addrs[a] === 'error');

    if ((failedUsers.length === 1 && inviter.fatal) || (failedUsers.length === 1 && inviter.isUnfederatable)) {
        const genericTitle = _t('Failed to invite users to the room:', {roomName: room.name});
        const unFederatableTitle = _t('Not allowed to invite an external user');
        const errorDialogTitle = inviter.isUnfederatable ? unFederatableTitle : genericTitle;

        // Just get the first message because there was a fatal problem on the first
        // user. This usually means that no other users were attempted, making it
        // pointless for us to list who failed exactly.
        const ErrorDialog = sdk.getComponent('dialogs.ErrorDialog');
        Modal.createTrackedDialog('Failed to invite users to the room', '', ErrorDialog, {
            title: errorDialogTitle,
            description: inviter.getErrorText(failedUsers[0]),
        });
    } else {
        const errorList = [];
        const cli = MatrixClientPeg.get();
        for (const addr of failedUsers) {
            if (addrs[addr] === 'error') {
                let reason = inviter.getErrorText(addr);
                let displayName = UserStore.getUserProp(addr, 'displayName');
                if (displayName === addr || displayName === null) {
                    const addrType = getAddressType(addr);
                    displayName = addr;
                    try {
                        let mxId;
                        if (addrType === 'mx-user-id') {
                            mxId = addr;
                        }
                        if (addrType === 'email' && validateEmail(addr)) {
                            const res = await MatrixClientPeg.get().lookupThreePid(addrType, addr);
                            if (res !== null && !!res.mxid) {
                                mxId = res.mxid;
                            }
                        }
                        if (mxId) {
                            const profileInfo = await cli.getProfileInfo(mxId);
                            if (profileInfo?.displayname) {
                                displayName = profileInfo.displayname;
                            }
                        }
                    } catch (e) {
                        console.log(`Failed to fetch profile for address: ${addr}`, e);
                    }
                }
                const errorCode = inviter.getErrorCode(addr);
                if (errorCode === 'C_ALREADY_INVITED') {
                    reason = `${_t("is already invited in the room")} ${room.name}`;
                }
                errorList.push(
                    <div className="error" key={addr}>
                        {displayName + ": " + reason}
                    </div>,
                );
            }
        }

        if (errorList.length > 0) {
            const ErrorDialog = sdk.getComponent('dialogs.ErrorDialog');
            Modal.createTrackedDialog('Failed to invite the following users to the room', '', ErrorDialog, {
                title: _t('Failed to invite the following users to the %(roomName)s room:', {roomName: room.name}),
                description: errorList,
            });
        }
    }

    return addrs;
}
