import Promise from 'bluebird';
import PropTypes from 'prop-types';
import MatrixClientPeg from '../MatrixClientPeg';

export const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

export const generateClientSecret = () => {
  const dictionnary = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

  let result = '';
  for (let i = 0; i < 32; i++) {
    result += dictionnary.charAt(Math.floor(Math.random() * dictionnary.length));
  }

  return result;
};

export const generateUserHash = () => {
  const dictionnary = 'abcdefghijklmnopqrstuvwxyz';

  let result = '';
  for (let i = 0; i < 18; i++) {
    result += dictionnary.charAt(Math.floor(Math.random() * dictionnary.length));
  }

  return result;
};

const capitalize = (name) => {
  if (name) return name[0].toUpperCase() + name.slice(1);
};

export const guessNames = (email) => {
    const base = email.slice(0, email.indexOf('@'));
    let firstName = '';
    let lastName = '';

    const namesExtractor = (base, index) => {
        firstName = capitalize(base.slice(0, index));
        lastName = capitalize(base.slice(index+1, base.length));
    };

    let index = base.indexOf('.');
    if (index > 0) namesExtractor(base, index);
    else {
        index = base.indexOf('-');
        if (index > 0) namesExtractor(base, index);
        else {
            index = base.indexOf('_');
            if (index > 0) namesExtractor(base, index);
            else firstName = base;
        }
    }

  return { firstName, lastName };
};

export const validateEmail = (email: string) => {
    // emailSplit[0] is local part of email and emailSplit[1] is domain part of email
    //eslint-disable-next-line
    const validLocalWord = new RegExp("^[a-zA-Z0-9_'-]+(?:\.[a-zA-Z0-9_'-]+)*$"); // checks for 2 consecutive dots inside the word as well as start and end dot
    //eslint-disable-next-line
    const validDomainWord = new RegExp("^[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"); // checks for 2 consecutive dots inside the word as well as start and end dot
    const validDomainCharacters = new RegExp('^[A-Za-z0-9.-]+$'); // checks for valid chars in domain
    const validLocalCharacters = new RegExp("^[A-Za-z0-9._'-]+$"); // checks for valid chars in local
    const emailSplit = email.split("@");

    if (emailSplit.length <= 1) {
        return false;
    }

    // checks for the length of local to be less than 64 and lenght of domain less than 255
    if ((emailSplit[0].length < 1 || emailSplit[0] > 64) | (emailSplit[1].length < 1 || emailSplit[1] > 255)) {
        return false;
    } else if (!validLocalWord.test(emailSplit[0]) || !validDomainWord.test(emailSplit[1])) { // invalidate if we have two consecutive strings in domain and local
        return false;
    } else if (!validLocalCharacters.test(emailSplit[0]) || !validDomainCharacters.test(emailSplit[1])) { // invalidate if we have forbidden chars
        return false;
    }
    return true;
};

export const validateToken = (token) => {
    return (token && token.length === 6 && /^[0-9]*$/.test(token));
};

export const validateE2eToken = (token) => {
    return (token && token.length === 8 && /^[0-9]*$/.test(token));
};

export const validateName = (name) => {
    // protect allowed special characters (apostrophe, accentuated characters, space and hyphen)
    const nameToValidate = name.replace(/[' ÀÂÆÁÄÃÅĀÉÈÊËĘĖĒŸÛÙÜÚŪÎÏÌÍĮĪÔŒÖÒÓÕØŌÇĆČÑŃéèêëęėēÿüûùúūçćčàäâæãåāìíįīïîöôœòóõøōñń-]/g, '');

    const hasUnauthorizedChar = ((name[0] === '-' || name[0] === '\'') || (/\d/.test(nameToValidate) || /\W/.test(nameToValidate)));
    const isUnconventional = (name.length > 0 && name.length < 3);

    return {
        error: hasUnauthorizedChar,
        warning: isUnconventional,
    };
};

export const validatePassword = (password) => {
    const tempPassword = password.replace(/[`\-/:;()€&@.,?!'[\]{}#%^*+=_|~<>$£¥°"]/g, '');

    const hasUnauthorizedChar = (/[^A-ZÀÂÆÁÄÃÅĀÉÈÊËĘĖĒŸÛÙÜÚŪÎÏÌÍĮĪÔŒÖÒÓÕØŌÇĆČÑŃa-zéèêëęėēÿüûùúūçćčàäâæãåāìíįīïîöôœòóõøōñń0-9]/.test(tempPassword));
    const matchEntirePolicy = (/^(?=.*[a-zéèêëęėēÿüûùúūçćčàäâæãåāìíįīïîöôœòóõøōñń])(?=.*[A-ZÀÂÆÁÄÃÅĀÉÈÊËĘĖĒŸÛÙÜÚŪÎÏÌÍĮĪÔŒÖÒÓÕØŌÇĆČÑŃ])(?=.*[0-9])(?=.*[`\-/:;()€&@.,?!\\'[\]{}#%^*+=_|~<>$£¥°"])(?=.{12,})/.test(password));

    return {
        lower: (/[a-zéèêëęėēÿüûùúūçćčàäâæãåāìíįīïîöôœòóõøōñń]/.test(password)),
        upper: (/[A-ZÀÂÆÁÄÃÅĀÉÈÊËĘĖĒŸÛÙÜÚŪÎÏÌÍĮĪÔŒÖÒÓÕØŌÇĆČÑŃ]/.test(password)),
        digit: (/\d/.test(password)),
        special: (/[`\-/:;()€&@.,?!'[\]{}#%^*+=_|~<>$£¥°"]/.test(password)),
        length: (password.length >= 12),
        unauthorized: hasUnauthorizedChar,
        valid: !hasUnauthorizedChar && matchEntirePolicy,
    };
};

export const spreadSheetFileTypes = [
    'text/csv',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/csv',
    'text/comma-separated-values',
    '.csv',
];

// eslint-disable-next-line no-useless-escape
const emailLookupRegex = /[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/g;

export const removeArrayDuplicates = (arr) => {
    return [...new Set(arr)];
};

export const getEmailsFromSpreadSheet = (file) => {
    return new Promise((resolve, reject) => {
        if (!spreadSheetFileTypes.includes(file.type)) {
            return reject('WRONG_FORMAT_FILE');
        }

        const fileReader = new FileReader();
        fileReader.onload = (event) => {
            const content = event.target.result || '';
            const emails = content.match(emailLookupRegex) || [];

            if (emails.length) {
                resolve(removeArrayDuplicates(emails));
            } else {
                reject('EMPTY_FILE');
            }
        };

        fileReader.onerror = () => {
            reject('UNKNOWN');
        };

        fileReader.readAsText(file, 'utf-8');
    });
};

export const isUserExternal = (userId) => {
    const homeServerUrl = localStorage.getItem('mx_hs_url');
    const homeServer = homeServerUrl && homeServerUrl.split('https://')[1];

    return homeServer && (userId.indexOf(homeServer) < 0);
};

export const citadelUrls = PropTypes.shape({
    portal: PropTypes.string.isRequired,
    services: PropTypes.string.isRequired,
});

export const isRoomOnHomeServer = (roomId) => {
    // detects if the room is from the user's homeserver

    const homeServer = MatrixClientPeg.getHomeServerName();

    return roomId.includes(homeServer);
};

export const hashKeyWithMethod = async (token, method) => {
    const utf8 = new TextEncoder().encode(token);
    const hashBuffer = await crypto.subtle.digest(method, utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
        .map((bytes) => bytes.toString(16).padStart(2, '0'))
        .join('');
    return hashHex;
};

export const getCorrectHashFunction = (hashFunctionFromServer) => {
    switch (hashFunctionFromServer) {
        case 'sha256':
            return 'SHA-256';
    }
};
