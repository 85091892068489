import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import AccessibleButton from './AccessibleButton';
import GenericToolTip from '../../structures/GenericToolTip';

export default class extends React.Component {
    static displayName = 'ConferenceButton';

    static propTypes = {
        className: PropTypes.string,
        closeHint: PropTypes.func,
        disabled: PropTypes.bool,
        icon: PropTypes.string.isRequired,
        iconHover: PropTypes.string,
        isCurrentlyHinted: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
        onHover: PropTypes.func,
        tooltip: PropTypes.string,
    };

    constructor(props, context) {
        super(props, context);
        this.state = { hover: false };
    }

    toggleHover = () => {
        this.setState({ hover: !this.state.hover });
        if (this.props.onHover) this.props.onHover(!this.state.hover);
    }

    handleOnClick = () => {
        const { closeHint, onClick, isCurrentlyHinted } = this.props;
        if (isCurrentlyHinted) closeHint();
        onClick();
    }

    render() {
        const { className, disabled, icon, iconHover, tooltip, label } = this.props;
        const { hover } = this.state;

        return (
            <AccessibleButton
                aria-label={label}
                className={cx('conference-button', className)}
                disabled={disabled}
                onClick={this.handleOnClick}
                onMouseEnter={this.toggleHover}
                onMouseLeave={this.toggleHover}
            >
                <GenericToolTip
                    disabled={disabled}
                    label={!disabled && tooltip ? tooltip : ''}
                    labelStyle={"tooltiptext"}
                >
                    <img src={hover && iconHover && !disabled ? iconHover : icon} />
                </GenericToolTip>
            </AccessibleButton>
        );
    }
}
