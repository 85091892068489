import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';

import arrowRight from '../../../../../../res/img/arrow-right.svg';
import arrowRightActive from '../../../../../../res/img/arrow-right-active.svg';

const ConferenceInfraFilterPills = ({membersToInvite, onFilterPress}) => {
    const [availableInfras, setAvailableInfras] = useState([]);
    const [position, setPosition] = useState(0);
    const [reachedEnd, setReachedEnd] = useState(false);
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const ELEMENT_SCROLL_STEP = 50;

    const scrollableInfraList = document.getElementById("scrollableInfraList");

    const getAvailableInfras = useCallback(() => {
        const newSet = new Set(['All']);
        membersToInvite.map(item => {
            const stringAfterEmail = item.member.user.email.split("@")[1];
            const infraName = stringAfterEmail.slice(0, stringAfterEmail.indexOf('.'));
            const infraNameCapitalized = capitalizeFirstLetter(infraName);
            newSet.add(infraNameCapitalized);
        });
        const infras = [];
        newSet.forEach((value) => {
            infras.push({name: value, active: value === 'All' ? true : false});
        });
        return infras;
    }, [membersToInvite]);

    const onInfraPillPress = (infra, index) => () => {
        const newArr = availableInfras.map(element => {
            element.active = false;
            return element;
        });
        newArr[index].active = true;
        setAvailableInfras(newArr);
        onFilterPress(infra.name);
    };

    const onLeftClick = () => {
        if (position >= ELEMENT_SCROLL_STEP) {
            scrollableInfraList.scroll({ left: position - ELEMENT_SCROLL_STEP });
            setPosition(prev => (prev >= ELEMENT_SCROLL_STEP) ? prev - ELEMENT_SCROLL_STEP : prev);
            setReachedEnd(false);
        }
    };

    const onRightClick = () => {
        if (!reachedEnd) {
            scrollableInfraList.scroll({ left: position + ELEMENT_SCROLL_STEP });
            setPosition(prev => prev + ELEMENT_SCROLL_STEP);
        }
    };

    const rightArrowClassname = useMemo(() => cx("rightArrowInfra", {
        canClick: !reachedEnd,
        rotate: reachedEnd,
    }));

    const leftArrowClassname = useMemo(() => cx("arrowInfra", {
        canClick: position >= ELEMENT_SCROLL_STEP,
        rotate: position >= ELEMENT_SCROLL_STEP,
    }));

    const handleScroll = (e) => {
        const end = e.target.scrollWidth - Math.ceil(e.target.scrollLeft) <= e.target.clientWidth;
        if (end) {
            setReachedEnd(true);
        }
    };

    useEffect(() => {
        const infras = getAvailableInfras();
        setAvailableInfras(infras);
    }, []);

    useEffect(() => {
        if (scrollableInfraList) {
            const end = scrollableInfraList.scrollWidth -
                Math.ceil(scrollableInfraList.scrollLeft) <= scrollableInfraList.clientWidth;
            if (end) {
                setReachedEnd(true);
            }
        }
    }, [scrollableInfraList]);

    return (
        <div className='conference-infra-container'>
            <img
                className={leftArrowClassname}
                onClick={onLeftClick}
                src={position >= ELEMENT_SCROLL_STEP ? arrowRightActive : arrowRight}
            />
                <div
                    className='infraFilterContainer'
                    id="scrollableInfraList"
                    onScroll={handleScroll}
                >
                    <div className='infraFilterPillsContainer'>
                        {availableInfras?.length && availableInfras.map((infra, index) => {
                            const infraPillClassname = cx("infraPill", {
                                active: infra.active,
                                firstItem: index === 0,
                            });
                            return (
                                <div onClick={onInfraPillPress(infra, index)} key={`infra-${index}`}>
                                    <span className={infraPillClassname}>{infra.name}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            <img
                className={rightArrowClassname}
                onClick={onRightClick}
                src={!reachedEnd ? arrowRightActive : arrowRight}
            />
        </div>
    );
};

export default ConferenceInfraFilterPills;
