import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import BaseDialog from './BaseDialog';
import { _t } from '../../../languageHandler';

import warningIcon from '../../../../res/img/warning-icon.svg';

export default class StopCallDialog extends React.Component {
    static propTypes = {
        onFinished: PropTypes.func.isRequired,
        onEndCall: PropTypes.func.isRequired,
        onStopCall: PropTypes.func.isRequired,
    };

    static defaultProps = {
        onFinished: noop,
        onEndCall: noop,
        onStopCall: noop,
    };

    handleStopCall = () => {
        const { onFinished, onStopCall } = this.props;
        onStopCall();
        onFinished();
    }

    handleEndCall = () => {
        const { onEndCall, onFinished } = this.props;
        onEndCall();
        onFinished();
    }

    render() {
        return (
            <BaseDialog
                className="mx_StopCallDialog"
                onFinished={this.props.onFinished}
                title=""
                contentId='mx_Dialog_content'
            >
                <div className="mx_Dialog_content" id='mx_Dialog_content'>
                    <div className="mx_Dialog_title">
                        {_t('Terminate conference')}
                    </div>
                    <div className="mx_Dialog_status">
                        {_t('Would you like to terminate the conference or just leave it ?')}
                    </div>
                    <div className="mx_Dialog_error">
                        <div className="mx_Dialog_error_inner">
                            <div className="mx_Dialog_warning">
                                <img className="mx_Dialog_warning_icon" src={warningIcon} />
                                <div>
                                    {_t('Warning')}
                                </div>
                            </div>
                            <div className="mx_Dialog_warning_text">
                                {_t('If you terminate it, all participants will be removed from the conference.')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mx_Dialog_buttons">
                    <button className="mx_Dialog_primary" onClick={this.handleEndCall} >
                        { _t('Leave conference') }
                    </button>
                    <button className="mx_Dialog_primary severe" onClick={this.handleStopCall} >
                        { _t('Terminate conference') }
                    </button>
                </div>
            </BaseDialog>
        );
    }
}
