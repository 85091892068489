/*
Copyright 2018 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import cx from 'classnames';

import * as ContextualMenu from './ContextualMenu';
import {TopLeftMenu} from '../views/context_menus/TopLeftMenu';
import AccessibleButton from '../views/elements/AccessibleButton';
import BaseAvatar from '../views/avatars/BaseAvatar';
import MatrixClientPeg from '../../MatrixClientPeg';
import Avatar from '../../Avatar';
import { _t } from '../../languageHandler';
import UserStore from '../../stores/UserStore';
import dis from '../../dispatcher';
import MfaManager from '../views/dialogs/mfa/MfaManager';

import hps_branding_icon from '../../../res/img/branding/hps_branding_top_left_menu.svg';

const AVATAR_SIZE = 32;

export default class TopLeftMenuButton extends React.Component {
    static propTypes = {
        collapsed: PropTypes.bool.isRequired,
        isHPS: PropTypes.bool.isRequired,
    };

    static displayName = 'TopLeftMenuButton';

    constructor() {
        super();
        this.state = {
            menuDisplayed: false,
            profileInfo: null,
            redMarker: {},
        };

        this.onToggleMenu = this.onToggleMenu.bind(this);
    }

    async _getProfileInfo() {
        const cli = MatrixClientPeg.get();

        const { userId, rawDisplayName: displayName, avatarUrl, email } = UserStore.getMe();
        let shouldUpdateUserStore = false;

        let profileInfo = {};
        if ((displayName === userId) || !email) {
            profileInfo = await cli.getProfileInfo(userId);
            shouldUpdateUserStore = true;
        }

        const userToStore = {
            userId,
            displayName: profileInfo.displayname || displayName,
            email: profileInfo.address || email,
        };

        if (shouldUpdateUserStore) {
            // Avoid any avatarUrl replacement in the user storage. We should let the js-sdk manage this part for the moment,
            // so we don't have to worry about getting thumbnails instead of full-width avatars.
            dis.dispatch({
                action: 'USER_STORE_SAVE_USER',
                user: userToStore,
            });
        }

        const avatarUrlForUser = Avatar.avatarUrlForUser(
            { avatarUrl: avatarUrl || profileInfo.avatar_url },
            AVATAR_SIZE, AVATAR_SIZE, "crop");

        return {
            ...userToStore,
            avatarUrl: avatarUrlForUser,
        };
    }

    componentDidMount() {
        this.getProfileImage();
        this.calculateRedMarker();
        this.userStoretoken = UserStore.addListener(this.getProfileImage);
        MatrixClientPeg.get().on('accountData', this._onAccountData);
    }
    hps_branding_top_left_menu
    componentWillUnmount() {
        this.userStoretoken.remove();
        const cli = MatrixClientPeg.get();
        cli && cli.removeListener('accountData', this._onAccountData);
    }

    _onAccountData = ev => {
        if (ev.getType() === 'citadel.mfa') {
            this.calculateRedMarker(ev);
        }
    };

    getProfileImage = async () => {
        try {
            const profileInfo = await this._getProfileInfo();
            if (!isEqual(this.state.profileInfo, profileInfo)) this.setState({ profileInfo });
        } catch (ex) {
            console.log("could not fetch profile");
            console.error(ex);
        }
    };

    calculateRedMarker = async ev => {
        // If anything new added in the UserSettings needs to be
        // notified to the server via a red dote

        // MFA Feature
        const mfaStatus = await MfaManager.getMfaStatus();
        const mfaAccountDataEvent = ev || MatrixClientPeg.get().getAccountData('citadel.mfa');
        const mfaAccountDataEventContent = mfaAccountDataEvent ? mfaAccountDataEvent.getContent() : {};
        const hasCheckedMFAFeature = mfaAccountDataEventContent['has_shown_settings_section'];

        if (!mfaStatus && !hasCheckedMFAFeature) {
            this.setState({
                redMarker: {
                    tabId: 'security',
                    topic: 'mfa',
                },
            });
        }
    };

    _getDisplayName() {
        if (MatrixClientPeg.get().isGuest()) {
            return _t("Guest");
        } else if (this.state.profileInfo) {
            return this.state.profileInfo.displayName;
        } else {
            return MatrixClientPeg.get().getUserId();
        }
    }

    render() {
        const { isHPS } = this.props;
        const name = this._getDisplayName();
        let nameElement;
        const classname = cx('mx_TopLeftMenuButton', { hps_branding: isHPS });
        const hpsIcon = isHPS ? <img className='mx_TopLeftMenuButton_branding' src={hps_branding_icon} alt="" /> : null;

        if (!this.props.collapsed) {
            nameElement = <div className="mx_TopLeftMenuButton_name">
                { name }
            </div>;
        }

        return (
            <AccessibleButton className={classname} onClick={this.onToggleMenu}>
                <BaseAvatar
                    idName={(this.state.profileInfo && this.state.profileInfo.displayName) ||
                        MatrixClientPeg.get().getUserId()}
                    name={name}
                    url={this.state.profileInfo && this.state.profileInfo.avatarUrl}
                    width={AVATAR_SIZE}
                    height={AVATAR_SIZE}
                    resizeMethod="crop"
                />
                { nameElement }
                <span className="mx_TopLeftMenuButton_chevron" />
                {hpsIcon}
            </AccessibleButton>
        );
    }

    onToggleMenu(e) {
        e.preventDefault();
        e.stopPropagation();

        const elementRect = e.currentTarget.getBoundingClientRect();
        const x = elementRect.left;
        const y = elementRect.top + elementRect.height;
        const { redMarker, profileInfo } = this.state;
        const { isHPS } = this.props;
        ContextualMenu.createMenu(TopLeftMenu, {
            chevronFace: "none",
            left: x,
            top: y,
            email: profileInfo && profileInfo.email,
            displayName: this._getDisplayName(),
            redMarker: redMarker,
            isHPS: isHPS,
            onFinished: () => {
                this.setState({ menuDisplayed: false });
            },
        });
        this.setState({ menuDisplayed: true });
    }
}
