import React, { Component } from 'react';
import { _t } from '../../../languageHandler';
import { guessNames, validateName } from '../../../utils/CitadelUtils';
import CitadelInput from '../../views/elements/CitadelInput';
import AuthBody from '../../views/auth/AuthBody';

class PasswordValidationStep extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: (props.email) ? guessNames(props.email).firstName : '',
            lastName: (props.email) ? guessNames(props.email).lastName : '',
        };
    }

    onInputChange = (ev) => {
        const { name, value } = ev.target;

        this.setState({ [name]: value });
    };

    onSubmit = (ev) => {
        ev.preventDefault();

        const { firstName, lastName } = this.state;
        const { onNext } = this.props;

        onNext(firstName.trim(), lastName.trim());
    };

    getCaptions = (name) => {
        const errorText = _t('Invalid name');
        const warningText = _t('Your first and last name will be your display name as seen by your contacts.');

        const validateNameResult = validateName(name.trim());
        if (validateNameResult.error) {
            return {
                type: 'error',
                text: errorText,
            };
        } else if (validateNameResult.warning) {
            return {
                type: 'warning',
                text: warningText,
            };
        } else {
            return {
                type: '',
                text: '',
            };
        }
    };

    render() {
        const { firstName, lastName } = this.state;

        const title = _t('Your profile');
        const subtitle = _t('Complete your profile information so your contacts can find you.');

        const firstNameCaption = this.getCaptions(firstName);
        let lastNameCaption = this.getCaptions(lastName);

        if (firstNameCaption.type === 'warning' && lastNameCaption.type === 'warning') lastNameCaption = { type: '', text: '' };
        const validInputs = (firstName.trim() && lastName.trim() && firstNameCaption.type !== 'error' && lastNameCaption.type !== 'error');

        return (
            <div className="mx_ProfileValidationStep">
                <AuthBody>
                    <div className="password-validation wrapper">
                        <div className="header">
                            <div className="title">
                                {title}
                            </div>
                            <div className="subtitle">
                                {subtitle}
                            </div>
                        </div>
                        <form onSubmit={this.onSubmit}>
                            <CitadelInput
                                label={_t('First name')}
                                name="firstName"
                                caption={firstNameCaption}
                                onChange={this.onInputChange}
                                value={firstName}
                                autoFocus={true}
                            />
                            <CitadelInput
                                label={_t('Last name')}
                                name="lastName"
                                caption={lastNameCaption}
                                onChange={this.onInputChange}
                                value={lastName}
                            />
                            <div className="button_wrapper">
                                <div className="button">
                                    <input className="mx_Login_submit"
                                           type="submit"
                                           value={_t('Save')}
                                           disabled={!validInputs}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </AuthBody>
            </div>
        );
    }
}

export default PasswordValidationStep;
