import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const CitadexContextOption = ({ children, className = '', onClick }) =>
    <div className="citadex_context_option" onClick={onClick}>
        <div className={cx('option_icon_container', className )} />
        {children}
    </div>;

CitadexContextOption.propTypes = {
    onClick: PropTypes.func,
};

export default CitadexContextOption;
