import React from 'react';
import PropTypes from 'prop-types';

import sdk from '../../../index';
import dis from '../../../dispatcher';
import { _t } from '../../../languageHandler';

import CitadelToggle from '../../views/elements/CitadelToggle';
import CitadelButton from '../../views/elements/CitadelButton';
import MfaManager from '../../views/dialogs/mfa/MfaManager';
import CodeVerificationForm from '../../views/dialogs/mfa/CodeVerificationForm';
import CitadelLoginPassword from '../../views/auth/CitadelLoginPassword';

import backSvg from '../../../../res/img/back-black.svg';

const STEPS = {
    MFA_SELECTION: 'MFA-SELECTION-FORM',
    TOKEN: 'TOKEN-FORM',
    PASSWORD: 'PASSWORD-FORM',
};

export default class CitadelLogin extends React.Component {
    static displayName = "CitadelLogin";

    state = {
        step: '',
        checkedToggle: '',
        checkedToggleTemp: '',
    }

    componentDidMount() {
        const { mfaStatus, email, phoneNumber, phoneCountryInfo } = MfaManager.getTemporaryValues();

        let step;
        let checkedToggle = '';

        if (!mfaStatus) {
            step = STEPS.PASSWORD;
        } else if (mfaStatus.includes('totp')) { // authentication by app
            step = STEPS.TOKEN;
            checkedToggle = 'totp';
            MfaManager.requestLoginToken('totp', { email });
        } else if (mfaStatus.includes('msisdn')) { // authentication by sms
            step = STEPS.TOKEN;
            checkedToggle = 'msisdn';
            MfaManager.requestLoginToken('msisdn', { email });
        } else if (mfaStatus.includes('email')) { // authentication by sms
            step = STEPS.TOKEN;
            checkedToggle = 'email';
            MfaManager.requestLoginToken('email', { email });
        }

        this.setState({
            mfaStatus,
            email,
            phoneNumber,
            phoneCountryInfo,
            step,
            checkedToggle,
        });
    }

    onBack = () => {
        const { step } = this.state;
        switch (step) {
            case STEPS.MFA_SELECTION: {
                this.setState({ checkedToggleTemp: '', step: STEPS.TOKEN});
                break;
            }
            case STEPS.TOKEN:
                MfaManager.resetTemporaryValues();
                dis.dispatch({ action: 'start_login' });
        }
    }

    onGoToMfaSelectionForm = () => {
        this.setState({ checkedToggleTemp: this.state.checkedToggle, step: STEPS.MFA_SELECTION });
    }

    onNextStep = () => {
        const { step, email, checkedToggleTemp } = this.state;

        const isMfaSelectionStep = (step === STEPS.MFA_SELECTION);
        isMfaSelectionStep && MfaManager.requestLoginToken(checkedToggleTemp, { email });
        let newPartialState = {
            step: STEPS.PASSWORD,
        };
        if (isMfaSelectionStep) {
            newPartialState = {
                step: STEPS.TOKEN,
                checkedToggle: checkedToggleTemp,
                checkedToggleTemp: '',
            };
        }
        this.setState({ ...newPartialState });
    }

    onToggleCheck = (type) => () => this.setState({ checkedToggleTemp: type });

    onPhoneNumberUnavailable = () => {
        MfaManager.requestLoginToken('email', { email: this.state.email, isResend: true });
        this.setState({ checkedToggle: 'email' });
    }

    renderMfaToggles = () => {
        const { email, phoneNumber, phoneCountryInfo, checkedToggleTemp, mfaStatus } = this.state;

        return <div>
            <img className="back" src={backSvg} onClick={this.onBack} alt="Back" />
            <div className="title">{_t('Multi-factor authentication')}</div>
            <div className="description">
                <span className="info">{_t('You have to verify your account with one of the ways you set up.')}</span>
            </div>
            <form>
                <div className="toggles">
                    {mfaStatus.includes('totp')
                        ? <CitadelToggle
                            type="radio"
                            label={_t('Send by authentication application')}
                            checked={checkedToggleTemp === 'totp'}
                            onChange={this.onToggleCheck('totp')}
                        />
                        : null
                    }
                    {mfaStatus.includes('msisdn')
                        ? <CitadelToggle
                            type="radio"
                            label={_t('Send by SMS')}
                            description={`${phoneCountryInfo.code}${phoneNumber}`}
                            checked={checkedToggleTemp === 'msisdn'}
                            onChange={this.onToggleCheck('msisdn')}
                        />
                        : null
                    }
                    <CitadelToggle
                        type="radio"
                        label={_t('Send by email')}
                        description={email}
                        checked={checkedToggleTemp === 'email'}
                        onChange={this.onToggleCheck('email')}
                    />
                </div>
                <CitadelButton text={_t('Continue')} onClick={this.onNextStep} minWidth={200} />
            </form>
        </div>;
    }

    renderCodeVerificationForm = () => {
        const { checkedToggle, mfaStatus } = this.state;
        let title = _t('Check your emails');

        if (checkedToggle === 'msisdn') title = _t('Check your SMS');
        if (checkedToggle === 'totp') title = _t('Check your authentication app');

        return <div>
            <img className="back" src={backSvg} onClick={this.onBack} alt="Back" />
            <div className="title">{title}</div>
            <CodeVerificationForm
                type={checkedToggle}
                isAdd3pidTokenForm={false}
                hasSubtitle={false}
                onPhoneNumberUnavailable={this.onPhoneNumberUnavailable}
                onSuccess={this.onNextStep}
                onChangeMfaSelection={mfaStatus.length > 1 ? this.onGoToMfaSelectionForm : null}
                buttonText={_t('Send a new confirmation code')}
                shouldResetTemp={false}
            />
        </div>;
    }

    renderPasswordForm = () => <CitadelLoginPassword {...this.props} medium={this.state.checkedToggle} />;

    render() {
        const AuthPage = sdk.getComponent('auth.AuthPage');
        const AuthHeader = sdk.getComponent('auth.AuthHeader');
        const AuthBody = sdk.getComponent('auth.AuthBody');

        const { step } = this.state;

        return <AuthPage>
            <AuthHeader />
            <AuthBody>
                <div className="mx_CitadelLogin">
                    {step === STEPS.MFA_SELECTION && this.renderMfaToggles()}
                    {step === STEPS.TOKEN && this.renderCodeVerificationForm()}
                    {step === STEPS.PASSWORD && this.renderPasswordForm()}
                </div>
            </AuthBody>
        </AuthPage>;
    }
}

CitadelLogin.propTypes = {
    defaultDeviceDisplayName: PropTypes.string.isRequired,
    onLoggedIn: PropTypes.func.isRequired,
    onLogout: PropTypes.func,   // we have this function only if we are in soft logout state
    portalUrl: PropTypes.string.isRequired,
    servicesUrl: PropTypes.string.isRequired,
};
