import React, { Component, Fragment } from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import CitadexContextMenu from './CitadexContextMenu';
import CitadelToggle from '../elements/CitadelToggle';

import arrowImg from '../../../../res/img/arrow-black.svg';
import { _t } from '../../../languageHandler';

export default class CitadexButtonOptions extends Component {
    static propTypes = {
        disabled: Proptypes.bool.isRequired,
        onChange: Proptypes.func.isRequired,
        value: Proptypes.string.isRequired,
        isHovered: Proptypes.bool,
    }

    constructor(props) {
        super(props);
        this.state = {
            contextOpen: false,
        };
    }

    toggleContext = (e) => {
        const x = e.clientX;
        const y = e.clientY - 40;
        this.setState(prevState => ({
            contextOpen: !prevState.contextOpen,
            mouseX: x,
            mouseY: y,
        }));
    };

    renderContextMenu = () => {
        const { contextOpen, mouseX, mouseY } = this.state;
        const { onChange, value } = this.props;

        if (!contextOpen) return null;

        return (
            <CitadexContextMenu
                className="conference_button_context"
                mouseX={mouseX}
                mouseY={mouseY}
                onClose={this.toggleContext}
                upward
            >
                <div className="citadex_complex_title">{_t('Stream quality')}</div>
                <div className="citadex_complex_option" onClick={onChange('auto')}>
                    <CitadelToggle checked={value === 'auto'} />
                    {_t('Auto (Recommended)')}
                </div>
                <div className="citadex_complex_option" onClick={onChange('high')}>
                    <CitadelToggle checked={value === 'high'} />
                    {_t('High')}
                </div>
                <div className="citadex_complex_option" onClick={onChange('medium')}>
                    <CitadelToggle checked={value === 'medium'} />
                    {_t('Medium')}
                </div>
                <div className="citadex_complex_option" onClick={onChange('low')}>
                    <CitadelToggle checked={value === 'low'} />
                    {_t('Low')}
                </div>
            </CitadexContextMenu>
        );
    }

    render() {
        const { contextOpen } = this.state;
        const { disabled, isHovered } = this.props;
        return (
            <Fragment>
                <div
                    className={cx('citadex_complex_button_cta', { open: contextOpen, disabled: disabled, hidden: isHovered })}
                    onClick={this.toggleContext}
                >
                    <img src={arrowImg} />
                </div>
                {this.renderContextMenu()}
            </Fragment>
        );
    }
}
