/*
Copyright 2017 Vector Creations Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import * as Matrix from 'matrix-js-sdk';
import utils from 'matrix-js-sdk/lib/utils';
import * as MegolmExportEncryption from '../../../utils/MegolmExportEncryption';
import sdk from '../../../index';
import { _t } from '../../../languageHandler';
import CitadelButton from '../../../components/views/elements/CitadelButton';

import CitadelInput from '../../../components/views/elements/CitadelInput';
import { decodeImage } from "../../../utils/E2eKeysUtils";
import { KeyCode } from "../../../Keyboard";

const PHASE_EDIT = 1;
const PHASE_IMPORTING = 2;

function readFileAsArrayBuffer(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            resolve(e.target.result);
        };
        reader.onerror = reject;

        reader.readAsArrayBuffer(file);
    });
}

export default class extends React.Component {
    static displayName = 'ImportE2eKeysDialog';

    static propTypes = {
        matrixClient: PropTypes.instanceOf(Matrix.MatrixClient).isRequired,
        onFinished: PropTypes.func.isRequired,
        onImportError: PropTypes.func,
    };

    state = {
        enableSubmit: false,
        errorText: null,
        fileData: null,
        password: '',
        phase: PHASE_EDIT,
    };

    componentDidMount() {
        this._unmounted = false;
    }

    componentWillUnmount() {
        this._unmounted = true;
    }

    onInputChange = (ev) => {
        const files = this.refs.file.files || [];
        this.setState({
            password: ev.target.value,
            enableSubmit: (ev.target.value !== "" && files.length > 0),
        });
    };

    _onFormChange = () => {
        const files = this.refs.file.files || [];
        if (files[0]) {
            this.getFileData(files[0]);
        } else {
            this.setState({ fileData: null, errorText: null });
        }
        this.setState({
            enableSubmit: (this.state.password !== "" && files.length > 0),
        });
    };

    _onFormSubmit = (ev) => {
        ev && ev.preventDefault();
        this._startImport(this.state.password);
        return false;
    };

    getFileData = (file) => {
        const { name } = file || {};
        if (file && name) {
            const fileNameList = name.split('.');
            const ext = fileNameList[fileNameList.length - 1];
            let fileData;
            switch (ext) {
                case 'png':
                    this._getDataFromPng(file);
                    break;
                case 'txt':
                    fileData = {
                        data: file,
                        type: 'txt',
                    };
                    this.setState({ fileData, errorText: null });
                    break;
                default: {
                    this.setState({ errorText: _t('Selected image does not match') });
                    break;
                }
            }
        }
    }

    _startImport = (passphrase) => {
        const { fileData = {} } = this.state;
        const { data, type } = fileData;
        if (fileData.data && fileData.type) {
            if (type === 'png') this._processPngFile(data, passphrase);
            if (type === 'txt') this._processTxtFile(data, passphrase);
        }
    };

    _getDataFromPng = async (file) => {
        readFileAsArrayBuffer(file).then(async (arrayBuffer) => {
            const blob = new Blob([arrayBuffer], { type: "image/png" });
            const url = URL.createObjectURL(blob);
            try {
                const data = await decodeImage(url);
                this.setState({ fileData: data, errorText: null });
            } catch (e) {
                this.setState({
                    errorText: e.toString(),
                    phase: PHASE_EDIT,
                });
            }
        });
    };

    _processPngFile = (data, passphrase) => {
        const { fileData: { metaData }} = this.state;
        const arrayBuffer = new TextEncoder().encode(data);
        const newPassphrase = metaData.isSingleRoom ? passphrase : `${passphrase}${this.props.matrixClient.getUserId()}`;
        this.startImport(arrayBuffer, newPassphrase);
    }

    _processTxtFile = (file, passphrase) => {
        readFileAsArrayBuffer(file).then((arrayBuffer) => {
            this.startImport(arrayBuffer, passphrase);
        });
    };

    startImport = (data, passphrase) => {
        const { onImportError } = this.props;
        this.setState({
            errorText: null,
            phase: PHASE_IMPORTING,
        });

        MegolmExportEncryption.decryptMegolmKeyFile(data, passphrase).then((keys) => {
            return this.props.matrixClient.importRoomKeys(utils.jsonParseSafe(keys));
        }).then(() => {
            // TODO: it would probably be nice to give some feedback about what we've imported here.
            this.props.onFinished(true);
        }).catch((e) => {
            console.error("Error importing e2e keys:", e);
            if (this._unmounted) {
                return;
            }
            const msg = e.friendlyText || _t('Unknown error');
            this.setState({
                errorText: msg,
                phase: PHASE_EDIT,
            });
            onImportError && onImportError(e);
        });
    };

    _onCancelClick = (ev) => {
        ev.preventDefault();
        this.props.onFinished(false, true);
        return false;
    };

    onPasswordInputKeyDown = (ev) => {
        if (ev.keyCode === KeyCode.ENTER) {
            ev.stopPropagation();
            ev.preventDefault();
            this._onFormSubmit();
        }
    }

    render() {
        const BaseDialog = sdk.getComponent('views.dialogs.BaseDialog');
        const { enableSubmit, errorText, fileData, password, phase } = this.state;
        const { onFinished } = this.props;
        const hint = fileData?.metaData?.hint;

        const importing = (phase === PHASE_IMPORTING);

        return (
            <BaseDialog className="mx_ImportE2eKeysDialog"
                onFinished={onFinished}
                title={_t('Import encryption keys')}
                cancelButtonTabIndex={-1}
            >
                <form onSubmit={this._onFormSubmit} autoComplete="off">
                    <div className="mx_Dialog_content">
                        <p>
                            {_t('This action allows you to import encryption keys that you have previously' +
                                ' exported from another Citadel Team client. You will then be able to decrypt any ' +
                                'messages that the other client could decrypt.',
                            )}
                        </p>
                        <p>
                            {_t('Enter the password of the encryption keys to decrypt the file.')}
                        </p>
                        <div className="error errorText">
                            {errorText}
                        </div>
                        <div>
                            <div className="importField">
                                <div className="label">
                                    <label htmlFor="importFile">
                                        {_t('File to import')}
                                    </label>
                                </div>
                                <div className='input'>
                                    <input
                                        accept="image/png, .txt"
                                        className="importFile"
                                        id="importFile"
                                        onChange={this._onFormChange}
                                        ref="file"
                                        type="file"
                                    />
                                </div>
                            </div>
                            <div>
                                <CitadelInput
                                    label={_t('Password')}
                                    name='password'
                                    type='password'
                                    onChange={this.onInputChange}
                                    value={password}
                                    autoComplete='false'
                                    onKeyDown={this.onPasswordInputKeyDown}
                                />
                            </div>
                            <div className="encryption-file-hint">
                                {hint
                                    ? <Fragment>
                                        <span className='label'>{_t('Hint') + ' :'}</span>
                                        <span className='hint'>{hint}</span>
                                    </Fragment>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="mx_Dialog_buttons">
                        <button disabled={importing} onClick={this._onCancelClick} tabIndex={-1}>
                            {_t('Cancel')}
                        </button>
                        <CitadelButton className="mx_Dialog_primary" type="submit" text={_t('Import encryption keys')}
                            isDisabled={!enableSubmit || importing}
                            tabIndex={1}
                            isLoading={importing}
                            width={200}
                        />
                    </div>
                </form>
            </BaseDialog>
        );
    }
}
