import React from 'react';
import {_t} from "../../../../languageHandler";
import CitadelInput from "../../elements/CitadelInput";

import warning from '../../../../../res/img/warning-mfa.svg';

const MfaPasswordDialogContent = ({ error, mfaType, onChange, value, isDeactivation = false }) => {
    const isEmail = mfaType === 'email';

    return (
        <>
            <div className="description">
                {isDeactivation
                    ? _t('Please enter your password to confirm the deactivation of your two-step authentication.')
                    : _t('Please enter your password to confirm the activation of your two-step authentication.')
                }
            </div>
            {isDeactivation && isEmail
                ? <div className="warning-container">
                    <div className="warning-title">
                        <img className="warning-title-icon" src={warning} width="20" height="23" alt="" />
                        <div className="warning-title-text">{_t('Warning')}</div>
                    </div>
                    <div className="warning-description">
                        {_t('The deactivation of the email verification automatically leads to the deactivation by SMS and authenticator app.')}
                    </div>
                  </div>
                : null
            }
            <CitadelInput
                autoComplete="none"
                autoFocus={true}
                caption={{ type: error ? 'error' : '', text: error }}
                label={_t('Password')}
                name="currentPassword"
                onChange={onChange}
                type="password"
                value={value}
            />
        </>
    );
};

export default MfaPasswordDialogContent;
