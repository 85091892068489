import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { _t } from '../../../languageHandler';
import { validatePassword } from '../../../utils/CitadelUtils';
import CitadelInput from '../../views/elements/CitadelInput';
import PasswordValidation from '../../views/auth/PasswordValidation';
import AuthBody from '../../views/auth/AuthBody';

class PasswordValidationStep extends Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
        };
    }

    onInputChange = (ev) => {
        const { name, value } = ev.target;

        this.setState({
            [name]: value,
        });
    };

    onSubmit = (ev) => {
        ev.preventDefault();

        const { password } = this.state;
        const { onNext } = this.props;

        onNext(password);
    };

    render() {
        const { password } = this.state;
        const validInputs = validatePassword(password).valid;
        const title = _t('Configure your password');
        const subtitle = _t('You will not have to enter it each time you open the application.');

        return (
            <div className="mx_PasswordValidationStep">
                <AuthBody>
                    <div className="password-validation wrapper">
                        <div className="header">
                            <div className="title">
                                {title}
                            </div>
                            <div className="subtitle">
                                {subtitle}
                            </div>
                        </div>
                        <form onSubmit={this.onSubmit}>
                            <CitadelInput
                                label={_t('Password')}
                                name="password"
                                type="password"
                                onChange={this.onInputChange}
                                value={password}
                                autoFocus={true}
                            />
                            <PasswordValidation
                                passwordPolicy={validatePassword(password)}
                            />
                            <div className="button_wrapper">
                                <div className="button">
                                    <input className="mx_Login_submit"
                                           type="submit"
                                           value={_t('Confirm')}
                                           disabled={!validInputs}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </AuthBody>
            </div>
        );
    }
}

PasswordValidationStep.propTypes = {
    onNext: PropTypes.func,
};

export default PasswordValidationStep;
