/*
Copyright 2015, 2016 OpenMarket Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

'use strict';

import SettingsStore from "../../../settings/SettingsStore";

import React from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';
import dis from '../../../dispatcher';
import { _t } from '../../../languageHandler';
import { isUserExternal } from '../../../utils/CitadelUtils';
import MemberAvatar from '../avatars/MemberAvatar';
import EntityTile from './EntityTile';

module.exports = class extends React.Component {
    static displayName = 'MemberTile';

    static propTypes = {
        member: PropTypes.any.isRequired, // RoomMember
        showPresence: PropTypes.bool,
    };

    static defaultProps = {
        showPresence: true,
    };

    componentDidMount() {
        if (!SettingsStore.isFeatureEnabled("feature_custom_status")) {
            return;
        }
        const { user } = this.props.member;
        if (!user) {
            return;
        }
        user.on("User._unstable_statusMessage", this._onStatusMessageCommitted);
    }

    componentWillUnmount() {
        const { user } = this.props.member;
        if (!user) {
            return;
        }
        user.removeListener(
            "User._unstable_statusMessage",
            this._onStatusMessageCommitted,
        );
    }

    getStatusMessage = () => {
        const { user } = this.props.member;
        if (!user) {
            return "";
        }
        return user._unstable_statusMessage;
    };

    _onStatusMessageCommitted = () => {
        // The `User` object has observed a status message change.
        this.setState({
            statusMessage: this.getStatusMessage(),
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (
            this.member_last_modified_time === undefined ||
            this.member_last_modified_time < nextProps.member.getLastModifiedTime()
        ) {
            return true;
        }
        if (
            nextProps.member.user &&
            (this.user_last_modified_time === undefined ||
            this.user_last_modified_time < nextProps.member.user.getLastModifiedTime())
        ) {
            return true;
        }
        return false;
    }

    onClick = (e) => {
        dis.dispatch({
            action: 'view_user',
            member: this.props.member,
        });
    };

    getPowerLabel = () => {
        return _t("%(userName)s (power %(powerLevelNumber)s)", {
            userName: this.props.member.userId,
            powerLevelNumber: this.props.member.powerLevel,
        });
    };

    state = {
        statusMessage: this.getStatusMessage(),
    };

    render() {
        const member = this.props.member;
        const { name, userId } = member;

        const isExternal = isUserExternal(userId);

        const avatar =
            <MemberAvatar member={member} width={24} height={24} />;

        if (member.user) {
            this.user_last_modified_time = member.user.getLastModifiedTime();
        }
        this.member_last_modified_time = member.getLastModifiedTime();

        const powerStatusMap = new Map([
            [100, EntityTile.POWER_STATUS_ADMIN],
            [50, EntityTile.POWER_STATUS_MODERATOR],
        ]);

        // Find the nearest power level with a badge
        let powerLevel = this.props.member.powerLevel;
        for (const [pl] of powerStatusMap) {
            if (this.props.member.powerLevel >= pl) {
                powerLevel = pl;
                break;
            }
        }

        const powerStatus = powerStatusMap.get(powerLevel);
        const src = {
            [EntityTile.POWER_STATUS_MODERATOR]: require("../../../../res/img/mod.svg"),
            [EntityTile.POWER_STATUS_ADMIN]: require("../../../../res/img/admin.svg"),
        }[powerStatus];
        const alt = {
            [EntityTile.POWER_STATUS_MODERATOR]: _t("Moderator"),
            [EntityTile.POWER_STATUS_ADMIN]: _t("Admin"),
        }[powerStatus];
        const power = src && <img src={src} className="mx_EntityTile_power" width="16" height="17" alt={alt} />;

        return (
            <div className="mx_MemberTile" onClick={this.onClick}>
                <div className="avatar">
                    {avatar}
                    {power}
                </div>
                <div className="name">
                    <Truncate lines={1}>
                        {name}
                    </Truncate>
                </div>
                {isExternal && <div className="bubble">{_t('Person external to your organization.')}</div>}
            </div>
        );
    }
};
