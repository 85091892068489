import React from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { isEqual } from 'lodash';

import UserStore from '../../../stores/UserStore';
import Avatar from '../../../Avatar';
import BaseAvatar from '../../views/avatars/BaseAvatar';
import { getDisplayName } from '../../../utils/Citadex';

import share from '../../../../res/img/conference/share-small.svg';


const AVATAR_SIZE = {
    small: 34,
    medium: 42,
    big: 120,
};

const getDisplayNameMemo = memoize((user) => getDisplayName(user), isEqual);
const getAvatarMemo = memoize((user, size) =>
    Avatar.avatarUrlForUser({ avatarUrl: user.avatarUrl }, size, size, 'crop'));

class ParticipantAvatar extends React.Component {
    render() {
        const { userId, userName, size = 'medium', isShareScreenEnabled = false } = this.props;
        const user = UserStore.getUserById(userId);
        const avatarSize = AVATAR_SIZE[size];
        const avatarUrlForUser = getAvatarMemo(user, avatarSize);
        const displayName = getDisplayNameMemo(user);

        return (
            <span className='avatar-container'>
                <BaseAvatar
                    key='avatar'
                    idName={displayName}
                    title={userName || userId}
                    name={userName || userId}
                    url={avatarUrlForUser}
                    width={avatarSize}
                    height={avatarSize}
                    resizeMethod='crop'
                />
                {isShareScreenEnabled ? <img className='share-icon' src={share} key='share-screen' /> : null}
            </span>
        );
    }
}

ParticipantAvatar.propTypes = {
    userId: PropTypes.string.isRequired,
    userName: PropTypes.string,
    size: PropTypes.string,
    isShareScreenEnabled: PropTypes.bool,
};

export default ParticipantAvatar;
