import { saveAs } from "file-saver";
import sanitizeFilename from "sanitize-filename";

import { decryptFile } from "../DecryptFile";
import { formatFullDateNoDay, formatFullDateNoDayISO } from "../../DateUtils";
import { _t } from "../../languageHandler";
import SdkConfig from "../../SdkConfig";
import MatrixClientPeg from "../../MatrixClientPeg";
import { ContentRepo } from "matrix-js-sdk";
import { fetchUrlWithTokenAsBlob } from "../MediaUtils";
import moment from "moment";
import { normalizeDate } from "./exportUtils";
import RoomExportStore from "../../stores/RoomExportStore";

const Direction = {
    Backward: "b",
    Forward: "f",
};

export default class Exporter {
    constructor(room, exportType, exportOptions, setProgressText) {
        if (
            exportOptions.maxSize < 1 * 1024 * 1024 || // Less than 1 MB
            exportOptions.maxSize > 8000 * 1024 * 1024 // More than 8 GB
        ) {
            throw new Error("Invalid export options");
        }
        window.addEventListener("beforeunload", this.onBeforeUnload);
        this.files = [];
        this.cancelled = false;
    }

    destinationFileName() {
        return this.makeFileNameNoExtension(SdkConfig.get().brand) + ".zip";
    }

    onBeforeUnload(e) {
        e.preventDefault();
        return (e.returnValue = _t("export_chat|unload_confirm"));
    }

    updateProgress(progress, log = true, show = true) {
        if (log) RoomExportStore.logger.log(progress.description || progress.title);
        if (show) this.setProgressText(progress);
    }

    addFile(filePath, blob) {
        const file = {
            name: filePath,
            blob,
        };
        this.files.push(file);
    }

    makeFileNameNoExtension(brand = "Citadel") {
        // First try to use the real name of the room, then a translated copy of a generic name,
        // then finally hardcoded default to guarantee we'll have a name.
        const safeRoomName =
            sanitizeFilename(this.room.name ? this.room.name : _t("export_chat|unnamed_room")).trim();
        const safeDate = formatFullDateNoDayISO(new Date()).replace(/:/g, "-"); // ISO format automatically removes a lot of stuff for us
        const safeBrand = sanitizeFilename(brand);
        return `${safeBrand} - ${safeRoomName} - Chat Export - ${safeDate}`;
    }

    async downloadZIP() {
        const filename = this.destinationFileName();
        const filenameWithoutExt = filename.substring(0, filename.lastIndexOf(".")); // take off the extension
        const { default: JSZip } = await import("jszip");

        const zip = new JSZip();
        // Create a writable stream to the directory
        if (!this.cancelled) {
            this.updateProgress({
                title: _t('export_chat|started_part_2'),
                description: _t("export_chat|generating_zip"),
            });
        } else return this.cleanUp();

        for (const file of this.files) {zip.file(filenameWithoutExt + "/" + file.name, file.blob);}

        const content = await zip.generateAsync({ type: "blob" });
        saveAs(content, filenameWithoutExt + ".zip");
    }

    cleanUp() {
        RoomExportStore.logger.log('Cleaning up...');
        window.removeEventListener("beforeunload", this.onBeforeUnload);
        return "";
    }

    async cancelExport() {
        RoomExportStore.logger.log('Cancelling export...');
        this.cancelled = true;
    }

    downloadPlainText(fileName, text) {
        const content = new Blob([text], { type: "text/plain" });
        saveAs(content, fileName);
    }

    setEventMetadata(event) {
        const roomState = this.room.currentState;
        const sender = event.getSender();
        event.sender = (!!sender && roomState?.getSentinelMember(sender)) || null;
        if (event.getType() === "m.room.member") {
            event.target = roomState?.getSentinelMember(event.getStateKey()) ?? null;
        }
        return event;
    }

    getLimit() {
        return 10 ** 8;
    }

    async getRequiredEvents() {
        const cli = MatrixClientPeg.get();
        const eventMapper = cli.getEventMapper();
        let prevToken = null;

        const events = [];
        let limit = this.getLimit();
        while (limit) {
            const eventsPerCrawl = Math.min(limit, 1000);
            const res = await MatrixClientPeg.get()._createMessagesRequest(
                this.room.roomId,
                prevToken,
                eventsPerCrawl,
                Direction.Backward,
            );

            if (this.cancelled) {
                this.cleanUp();
                return [];
            }

            if (res.chunk.length === 0) break;

            limit -= res.chunk.length;

            const matrixEvents = res.chunk.map(eventMapper);

            for (const mxEv of matrixEvents) {
                if (this.exportOptions.startDate && moment(mxEv.getTs()).isBefore(this.exportOptions.startDate)) {
                    // Once the last message received is older than the start date, we break out of both the loops
                    limit = 0;
                    break;
                }
                if (!this.exportOptions.endDate
                    || (this.exportOptions.endDate
                        && normalizeDate(moment(mxEv.getTs())).isSameOrBefore(this.exportOptions.endDate))
                ) {
                        events.push(mxEv);
                }
            }

            this.updateProgress({
                title: _t('export_chat|started_part_1'),
                description: _t(`export_chat|fetched_n_events${events.length === 1 ? '_one' : '_other'}`, {
                    count: events.length,
                }),
            });

            prevToken = res.end ?? null;
        }
        // Reverse the events so that we preserve the order
        events.reverse();

        const decryptionPromises = events
            .filter(event => event.isEncrypted())
            .map(event => {
                return MatrixClientPeg.get().decryptEventIfNeeded(event, {
                    isRetry: true,
                    emit: false,
                });
            });

        // Wait for all the events to get decrypted.
        await Promise.all(decryptionPromises);

        for (let i = 0; i < events.length; i++) this.setEventMetadata(events[i]);

        return events;
    }

    /**
     * Decrypts if necessary, and fetches media from a matrix event
     * @param event - matrix event with media event content
     * @resolves when media has been fetched
     * @throws if media was unable to be fetched
     */
    async getMediaBlob(event) {
        let blob = undefined;
        try {
            const isEncrypted = event.isEncrypted();
            const content = event.getContent();
            const shouldDecrypt =
                isEncrypted &&
                content.hasOwnProperty("file") &&
                event.getType() !== "m.sticker";
            if (shouldDecrypt) {
                blob = await decryptFile(content.file);
            } else {
                // from old implementation
                const imageUriForMxc = ContentRepo.getHttpUriForMxc(
                    MatrixClientPeg.get().getHomeserverUrl(),
                    content.url,
                );
                blob = await fetchUrlWithTokenAsBlob(imageUriForMxc);
            }
        } catch (err) {
            RoomExportStore.logger.log('Error decrypting media');
        }
        if (!blob) {
            throw new Error("Unable to fetch file");
        }
        return blob;
    }

    splitFileName(file) {
        const lastDot = file.lastIndexOf(".");
        if (lastDot === -1) return [file, ""];
        const fileName = file.slice(0, lastDot);
        const ext = file.slice(lastDot + 1);
        return [fileName, "." + ext];
    }

    getFilePath(event) {
        const mediaType = event.getContent().msgtype;
        let fileDirectory;
        switch (mediaType) {
            case "m.image":
                fileDirectory = "images";
                break;
            case "m.video":
                fileDirectory = "videos";
                break;
            case "m.audio":
                fileDirectory = "audio";
                break;
            default:
                fileDirectory = event.getType() === "m.sticker" ? "stickers" : "files";
        }
        const fileDate = formatFullDateNoDay(new Date(event.getTs()));
        // eslint-disable-next-line prefer-const
        let [fileName, fileExt] = this.splitFileName(event.getContent().body);

        if (event.getType() === "m.sticker") fileExt = ".png";

        return fileDirectory + "/" + fileName + "-" + fileDate + fileExt;
    }

    isReply(event) {
        const isEncrypted = event.isEncrypted();
        // If encrypted, in_reply_to lies in event.event.content
        const content = isEncrypted ? event.event.content : event.getContent();
        const relatesTo = content["m.relates_to"];
        return !!(relatesTo && relatesTo["m.in_reply_to"]);
    }

    isAttachment(mxEv) {
        const attachmentTypes = [
            "m.sticker",
            "m.image",
            "m.file",
            "m.video",
            "m.audio",
        ];
        return (
            mxEv.getType() === attachmentTypes[0] ||
            attachmentTypes.includes(mxEv.getContent().msgtype)
        );
    }
}
