/*
Copyright 2019 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React from 'react';
import PropTypes from 'prop-types';

import { Tab, TabbedView } from '../../structures/TabbedView';
import { _t, _td } from '../../../languageHandler';

import sdk from '../../../index';
import GeneralUserSettingsTab from '../settings/tabs/user/GeneralUserSettingsTab';
import SettingsStore from '../../../settings/SettingsStore';
import LabsUserSettingsTab from '../settings/tabs/user/LabsUserSettingsTab';
import SecurityUserSettingsTab from '../settings/tabs/user/SecurityUserSettingsTab';
import NotificationUserSettingsTab from '../settings/tabs/user/NotificationUserSettingsTab';
import PreferencesUserSettingsTab from '../settings/tabs/user/PreferencesUserSettingsTab';
import HelpUserSettingsTab from '../settings/tabs/user/HelpUserSettingsTab';
import LegalInfoUserSettingsTab from '../settings/tabs/user/LegalInfoUserSettingsTab';

export default class UserSettingsDialog extends React.Component {
    static propTypes = {
        redMarker: PropTypes.object,
        initialTabIndex: PropTypes.number,
        onFinished: PropTypes.func.isRequired,
    };

    static defaultProps = {
        redMarker: {},
        initialTabIndex: 0,
    }

    _getTabs() {
        const tabs = [];
        tabs.push(new Tab(
            _td("General"),
            "mx_UserSettingsDialog_settingsIcon",
            <GeneralUserSettingsTab handleCloseSettings={this.props.onFinished} />,
            'general',
        ));
        tabs.push(new Tab(
            _td("Notifications"),
            "mx_UserSettingsDialog_bellIcon",
            <NotificationUserSettingsTab />,
            'notifications',
        ));
        tabs.push(new Tab(
            _td("Preferences"),
            "mx_UserSettingsDialog_preferencesIcon",
            <PreferencesUserSettingsTab />,
            'preferences',
        ));
        tabs.push(new Tab(
            _td("Security & Privacy"),
            "mx_UserSettingsDialog_securityIcon",
            <SecurityUserSettingsTab />,
            'security',
        ));
        if (SettingsStore.getLabsFeatures().length > 0) {
            tabs.push(new Tab(
                _td("Labs"),
                "mx_UserSettingsDialog_labsIcon",
                <LabsUserSettingsTab />,
                'labs',
            ));
        }
        tabs.push(new Tab(
            _td("Help & About"),
            "mx_UserSettingsDialog_helpIcon",
            <HelpUserSettingsTab handleCloseSettings={this.props.onFinished} />,
            'help',
        ));
        tabs.push(new Tab(
            _td("Legal information"),
            "mx_UserSettingsDialog_legalIcon",
            <LegalInfoUserSettingsTab />,
            'legal',
        ));

        return tabs;
    }

    render() {
        const BaseDialog = sdk.getComponent('views.dialogs.BaseDialog');
        return (
            <BaseDialog className="mx_UserSettingsDialog" hasCancel={true}
                        onFinished={this.props.onFinished} title={_t("Settings")}>
                <div className='ms_SettingsDialog_content'>
                    <TabbedView
                        tabs={this._getTabs()}
                        initialTabIndex={this.props.initialTabIndex}
                        redMarker={this.props.redMarker}
                    />
                </div>
            </BaseDialog>
        );
    }
}
