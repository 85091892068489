import React from 'react';
import PropTypes from 'prop-types';

import MatrixClientPeg from '../../../MatrixClientPeg';

import { _t } from '../../../languageHandler';

class AboutE2EButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            infoStyle: {},
        };

        this.aboutE2eRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('resize', this.onCloseInfo);
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onCloseInfo);
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    onIconClick = () => {
        this.setState({ infoStyle: this.getInfoStyle() });
        MatrixClientPeg.get().trackUserAction({
            formId: 'E2EKeysSharingInRoom',
            version: 1,
            action: 'help',
            room: this.props.roomId,
        });
    }

    onCloseInfo = () => {
        this.setState({ infoStyle: {} });
    }

    onLinkClick = (e) => {
        window.open('https://join.citadel.team/faq/e2e/troubleshooting/');
    }

    handleClickOutside = (e) => {
        if (this.aboutE2eRef && !this.aboutE2eRef.current.contains(e.target)) {
          this.onCloseInfo();
        }
      }

    getInfoStyle = () => {
        const timelineElement = document.querySelector('.mx_RoomView_timeline');
        const { height: timelineHeight, top: topStart } =
            timelineElement ? timelineElement.getBoundingClientRect() : {};

        if (timelineHeight && topStart) {
            const { eventTs } = this.props;
            const topMid = timelineHeight / 2 + topStart;

            const e2eAboutIconElement = document.getElementById(`about-e2e-${eventTs}`);
            const { top: aboutTop, bottom: aboutBottom, left: aboutLeft } = e2eAboutIconElement &&
                e2eAboutIconElement.getBoundingClientRect();

            const margin = 6;
            const shouldElementBeAbove = topMid < aboutTop;
            return {
                left: aboutLeft,
                top: shouldElementBeAbove ? 'unset' : aboutBottom + margin,
                bottom: shouldElementBeAbove ? (window.innerHeight - aboutTop) + margin : 'unset',
            };
        }

        return {};
    }

    render() {
        const { eventTs } = this.props;
        const { infoStyle } = this.state;
        const isInfoDisplayed = Object.keys(infoStyle).length > 0;

        return <div ref={this.aboutE2eRef} className="about-e2e">
            <div id={`about-e2e-${eventTs}`} className="image" onClick={this.onIconClick} />
            {isInfoDisplayed && <div className="info" style={infoStyle}>
                <div className="title">{_t("End-to-end encryption")}</div>
                <div className="description">
                    <div>{_t("End-to-end encryption is designed to protect your most confidential exchanges.")}</div>
                    <div>{_t("Wondering why you can't decipher your messages?")}</div>
                    <div onClick={this.onLinkClick}>{_t("Consult our FAQ")}</div>
                </div>
            </div>}
        </div>;
    }
}

AboutE2EButton.propTypes = {
    eventTs: PropTypes.number.isRequired,
};

export default AboutE2EButton;
