import React from 'react';
import PropTypes from 'prop-types';

import CitadelCodeInput from '../../elements/CitadelCodeInput';
import { _t } from '../../../../languageHandler';
import { validateToken } from '../../../../utils/CitadelUtils';
import MfaManager from './MfaManager';
import CitadelButton from '../../elements/CitadelButton';

export default class CodeVerificationForm extends React.Component {
    static displayName = "CodeVerificationForm";

    static propTypes = {
        buttonText: PropTypes.string,
        hasSubtitle: PropTypes.bool,
        isAdd3pidTokenForm: PropTypes.bool,
        onChangeMfaSelection: PropTypes.func || null,
        onChangePhoneNumber: PropTypes.func,
        onPhoneNumberUnavailable: PropTypes.func,
        onSendNewToken: PropTypes.func,
        onSuccess: PropTypes.func.isRequired,
        shouldResetTemp: PropTypes.bool,
        type: PropTypes.string,
    };

    static defaultProps = {
        type: 'msisdn',
        isAdd3pidTokenForm: true,
        hasSubtitle: true,
        shouldResetTemp: true,
    }

    state = {
        token: '',
        isSubmitting: false,
        error: null,
    };

    onTokenChange = (token) => {
        this.setState({ token, error: null });
    };

    submitCode = async (evt) => {
        evt.preventDefault();
        const { isAdd3pidTokenForm, shouldResetTemp, type } = this.props;
        const { token } = this.state;

        if (token) {
            this.setState({
                isSubmitting: true,
            });

            let error;
            const res = isAdd3pidTokenForm ? await MfaManager.submitAddToken(token) :
                await MfaManager.submitToken(type, token, shouldResetTemp);

            if (!res) {
                error = (
                    <div>
                        <div>{_t("The verification code is invalid.")}</div>
                        {type !== 'totp'
                            ? <div>{_t("Please request a new code.")}</div>
                            :null
                        }
                    </div>
                );
                this.setState({
                    isSubmitting: false,
                    error,
                });
                if (type === 'totp') this.requestNewToken();
            } else {
                this.props.onSuccess();
            }
        }
    };

    requestNewToken = async () => {
        const { isAdd3pidTokenForm, type, onSendNewToken } = this.props;
        const { email, phoneNumber, phoneCountryInfo } = MfaManager.getTemporaryValues();
        if (isAdd3pidTokenForm) {
            // isAdd3pidTokenForm is true only on activation, not on login
            await MfaManager.resetMsisdn();
            MfaManager.requestAddToken({ isResend: true });
        } else {
            MfaManager.requestLoginToken(type, {
                email,
                phoneNumber: type === 'msisdn' ? `${phoneCountryInfo.code}${phoneNumber}` : '',
                isResend: type !== 'totp',
            });
        }
        if (type !== 'totp') {
            this.onTokenChange('');
        }
        onSendNewToken && onSendNewToken();
    }

    renderDescription = () => {
        const { type, onChangePhoneNumber, onPhoneNumberUnavailable } = this.props;
        const { email, phoneNumber, phoneCountryInfo } = MfaManager.getTemporaryValues();

        if (type === 'msisdn') {
            let modifyButton;
            if (onChangePhoneNumber) {
                modifyButton = <div className="button-modify" onClick={onChangePhoneNumber}>
                    {_t('Modify number')}
                </div>;
            } else if (onPhoneNumberUnavailable) {
                modifyButton = <div className="button-modify" onClick={onPhoneNumberUnavailable}>
                    {_t('You don\'t have access to this number ?')}
                </div>;
            }

            return <div className="description">
                <div>{_t('A 6-digit verification code has just been sent to')}</div>
                <b>{` ${phoneCountryInfo.code}${phoneNumber}`}</b>
                {modifyButton}
            </div>;
        } else if (type === 'totp') {
            return <div className="description">
                <div>{_t('Please enter the verification code you got on your authentication app')}</div>
            </div>;
        } else {
            return <div className="description">
                <div>{_t('A verification code has been sent to you at')}</div>
                <b>{email}</b>
            </div>;
        }
    }

    render() {
        const { hasSubtitle, buttonText, onChangeMfaSelection, type } = this.props;
        const { token, isSubmitting, error } = this.state;
        const isTokenValid = validateToken(token);
        const isTotp = type === 'totp';

        return <div className="mx_CodeVerificationDialog">
            {hasSubtitle && <div className="subtitle">{_t('Verification by SMS')}</div>}
            {this.renderDescription()}
            <CitadelCodeInput
                className="verification-code"
                type="tel"
                fields={6}
                onChange={this.onTokenChange}
                value={token}
                label={_t('Verification code')}
                error={error}
            />
            {onChangeMfaSelection
                ? <div className="mfa-selection button" onClick={onChangeMfaSelection}>
                    {_t('Switch to other verifications')}
                </div>
                : null
            }
            <CitadelButton
                text={_t('Confirm')}
                onClick={this.submitCode}
                isDisabled={isSubmitting || !isTokenValid || !!error}
                isLoading={isSubmitting}
                minWidth={200}
            />
            {!isTotp
                ? <div className="new-code button" onClick={this.requestNewToken}>
                    {buttonText || _t('Send a new code')}
                </div>
                : null
            }
        </div>;
    }
}
