import React from 'react';
import cx from 'classnames';
import { debounce, isEqual } from 'lodash';
import PropTypes from "prop-types";

import MatrixClientPeg from '../../../MatrixClientPeg';
import PlatformPeg from '../../../PlatformPeg';
import dis from '../../../dispatcher';
import { _t } from "../../../languageHandler";
import CitadexCall from "./CitadexCall";
import CitadelButton from '../elements/CitadelButton';
import CitadexStore, { TYPE_AUDIO, TYPE_VIDEO } from '../../../stores/CitadexStore';
import { ERROR_TYPES, errorDialog } from "./ErrorDialogsHelper";
import CitadelRoomName from "../elements/CitadelRoomName";
import { getStreams, getProfileInfo } from "../../../utils/Citadex";
import { StreamAPI } from "../../../utils/WebRtc";
import ParticipantCard from "./ParticipantCard";
import ConferenceButton from "../elements/ConferenceButton";
import ToggleSwitch from "../elements/ToggleSwitch";
import Modal from '../../../Modal';
import ConferenceDeviceSettingsDialog from './ConferenceDeviceSettingsDialog';
import Toast from '../toast';
import ConferenceDeviceSettings from './ConferenceDeviceSettings';
import SettingsStore, { SettingLevel } from "../../../settings/SettingsStore";
import { getBottomBarIconPack } from './utils/Icons';
import closeImage from '../../../../res/img/close.svg';
import ErrorDialogContent from './ErrorDialogContent';

import SdkConfig from "../../../SdkConfig";
import { KeyCode } from "../../../Keyboard";
import CitadelToggle from "../elements/CitadelToggle";
import { LIST_TYPE as TOAST_LIST_TYPE } from "../../../stores/ToastStore";

const CONFERENCE_ENABLED = ['enabled', 'trial'];
const HPS_PORTAL_URL = 'https://join.citadel.sch.restricted.trustnest.io';
const ICE_TRANSPORT_POLICY = { all: 'all', relay: 'relay' };

class CitadexPage extends React.Component {
    static propTypes = { isMinimized: PropTypes.bool };

    constructor(props, context) {
        super(props, context);
        const callInProgress = CitadexStore.getCallInProgress();
        this.state = {
            audioSourceLabel: null,
            audioSourceId: null,
            callInProgress,
            debugModePreview: false,
            hasCameraPermissions: undefined,
            hasMicrophonePermissions: undefined,
            inviterName: null,
            isAudioEnabled: this.props.isConferenceAudioEnabled,
            isDmRoom: CitadexStore.getIsDmRoom(),
            isLoading: true,
            isPreview: this.props.isConferencePreview,
            isVideoCall: false,
            isVideoEnabled: false,
            matrixRoomId: CitadexStore.getMatrixRoomId(),
            matrixUserId: null,
            outputId: null,
            roomName: CitadexStore.getSessionName() || 'unknown room',
            type: CitadexStore.getSessionType() || TYPE_AUDIO,
            videoSourceId: null,
            iconPack: getBottomBarIconPack(
                SettingsStore.getValueAt(SettingLevel.ACCOUNT, "theme"),
                false,
            ),
            isSoundNotificationOn: true,
            isVideoStreamSubscriptionDisabled: false,
            onContinuePressed: false,
        };
        this.lastCallEvent = callInProgress ? callInProgress : null;
        this.debouncedSetState = debounce(this.setState, 500);
    }

    _isMounted = false;

    async componentDidMount() {
        const { type } = this.state;
        this._isMounted = true;
        this.dispatcherRef = dis.register(this.onAction);
        const parentElement = document.getElementById('matrixchat');
        parentElement.style.backgroundColor = '#16171e';
        if (!PlatformPeg.get().isDesktop()) {
            window.addEventListener('beforeunload', this.onBeforeUnload);
        }
        window.addEventListener('unload', this.onUnload);
        window.addEventListener('storage', this.onStorage);
        window.addEventListener('keydown', this.onKeyDown);

        await this.requestRights();
        navigator.mediaDevices.ondevicechange = this.updateDeviceList;
        if (type === TYPE_VIDEO) this.setState({ isVideoCall: true });

        const cli = MatrixClientPeg.get();
        // onRoomStateEvents will be called only on new events
        cli.on('RoomState.events', this.onRoomStateEvents);
        this.setState({ iceTransportPolicy: isHPS ? ICE_TRANSPORT_POLICY.relay : ICE_TRANSPORT_POLICY.all });
        const isHPS = SdkConfig.get().default_portal_url === HPS_PORTAL_URL;
    }

    async componentDidUpdate(
        prevProps,
        {
            callInProgress: prevCallInProgress,
            hasCameraPermissions: prevHasCameraPermissions,
            hasMicrophonePermissions: prevHasMicrophonePermissions,
            isVideoCall: prevIsVideoCall,
        }) {
        const { callInProgress, hasMicrophonePermissions, hasCameraPermissions, isVideoCall } = this.state;

        let updateDevicesAndGetStreamsWasCalled = false;

        if (!isEqual(callInProgress, prevCallInProgress)) {
            if (callInProgress) {
                const content = callInProgress.getContent() || callInProgress.event.content;
                this.setInviterName(content);
                if (isVideoCall && !prevIsVideoCall) this.requestRights(TYPE_VIDEO);
            } else {
                this.setState({ inviterName: null });
            }
            updateDevicesAndGetStreamsWasCalled = true;
            await this.updateDevicesAndGetStreams();
        }

        if (!updateDevicesAndGetStreamsWasCalled &&
            ((prevHasMicrophonePermissions === undefined && hasMicrophonePermissions)
                || (prevHasCameraPermissions === undefined && hasCameraPermissions))) {
            await this.updateDevicesAndGetStreams();
        }
     }

    componentWillUnmount() {
        dis.unregister(this.dispatcherRef);
        const videoElement = document.getElementById('video-element-preview');
        videoElement && videoElement.removeEventListener('contextmenu', this.videoRightClick);
        window.removeEventListener('beforeunload', this.onBeforeUnload);
        window.removeEventListener('unload', this.onUnload);
        window.removeEventListener('storage', this.onStorage);
        window.removeEventListener('keydown', this.onKeyDown);
        this.debouncedSetState.cancel();
        this._isMounted = false;
        CitadexStore.setIsCitadexOpened(false);
    }

    shouldDisplayWarning = true;

    onBeforeUnload = (event) => {
        if (this.shouldDisplayWarning) {
            event.preventDefault();
            event.returnValue = '';
        } else {
            delete event['returnValue'];
        }
    };

    onKeyDown = (e) => {
        if ((!e.altKey && !e.ctrlKey && !e.metaKey && !e.shiftKey) ||
            e.key === "Meta" || e.key === "Shift" || e.key === "Control" || e.key === "alt"
        ) {
            return;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode === KeyCode.KEY_D) {
            this.setState(prevState => ({ debugModePreview: !prevState.debugModePreview }));
        }
    }
    closeNoInternetModal = () => {
        this.stopStreams();
        CitadexStore.destroySession();
    }

    onUnload = () => {
        CitadexStore.setIsCitadexOpened(false);
    };

    updateDevicesAndGetStreams = async () => {
        const { onContinuePressed } = this.state;
        await this.updateDeviceList();
        !onContinuePressed && this.getLocalStreams();
    }

    isLastEvent = (event) => {
        if (!this.lastCallEvent) return true;

        const lastCallEventTimeStamp = this.lastCallEvent.getTs && this.lastCallEvent.getTs()
            || this.lastCallEvent.event.origin_server_ts;

        return event.getTs() >= lastCallEventTimeStamp;
    }

    onStorage = () => {
        if (!!localStorage.getItem("mx_refresh_token") && this.state.onContinuePressed && this.state.isLoading) {
            this.onContinue(!!this.state.callInProgress)();
        }
    }

    onRoomStateEvents = async (event) => {
        const { isDmRoom, matrixRoomId } = this.state;
        if (event.getRoomId() !== CitadexStore.getMatrixRoomId()) {
            return;
        }
        if (event.getType() === 'citadel.conference') {
            if (!this.isLastEvent(event)) return;
            let callInProgress = event;
            const callInProgressContent = event.getContent();
            // we need also to change the type of the call if someone started a call with a different type
            // in the case the call is close before we join, we are switching to the initial call type
            const {
                callInProgress: prevCallInProgress,
                hasCameraPermissions,
                isVideoCall: prevIsVideoCall,
                isVideoEnabled: prevIsVideoEnabled,
                roomName,
                type: prevType,
            } = this.state;
            const { type: currentCallType } = callInProgressContent;
            const isCallOpen = ['open', 'empty'].includes(callInProgressContent.status);
            let isVideoCall = prevIsVideoCall;
            let isVideoEnabled = prevIsVideoEnabled;
            let type = prevType;
            if (!isEqual(prevCallInProgress, callInProgressContent)) {
                if (isCallOpen) {
                    if (currentCallType === TYPE_VIDEO) {
                        isVideoCall = true;
                    } else {
                        isVideoCall = false;
                        isVideoEnabled = false;
                    }
                    type = currentCallType;
                } else {
                    type = CitadexStore.getSessionInitialType();
                    isVideoCall = type === TYPE_VIDEO;
                    isVideoEnabled = isVideoCall && hasCameraPermissions;
                    callInProgress = undefined;
                }
                // make sure that we have only the last event
                if (this.isLastEvent(event)) {
                    this.lastCallEvent = event;
                } else {
                    return;
                }
                this.debouncedSetState({ callInProgress, type, isVideoCall, isVideoEnabled }, () => {
                    // in case the user refreshes the page
                    if (callInProgress) {
                        CitadexStore.log('new call was started', callInProgressContent);
                    } else {
                        CitadexStore.log('previous call has ended');
                    }
                    !isVideoCall && this.stopStreams();
                    const initialSessionType = CitadexStore.getSessionType();
                    CitadexStore.prepareDataForConference(
                        type, matrixRoomId, roomName, callInProgress, isDmRoom, initialSessionType,
                    );
                });
            }
        }
    }

    setInviterName = async (callInProgress) => {
        let inviterName;
        if (callInProgress) {
            inviterName = await getProfileInfo(callInProgress.inviter_id);
        }
        if (this.state.inviterName !== inviterName) {
            this.setState({ inviterName });
        }
    }

    onAction = async (payload) => {
        if (payload.action === 'toggle_conference' && payload.isOpen) {
            const { callInProgress } = this.state;
            !!callInProgress && this.setInviterName(callInProgress.event.content);
            const conferenceRightsEvent = MatrixClientPeg.get().getAccountData('citadex.conference_rights');
            let userVideoRights = false;
            let userAudioRights = false;
            if (conferenceRightsEvent) {
                const content = conferenceRightsEvent.getContent();
                if (content) {
                    userAudioRights = CONFERENCE_ENABLED.includes(content.visio);
                    userVideoRights = CONFERENCE_ENABLED.includes(content.bridge);
                }
            }

            this.setState({
                clientIsStarted: true,
                matrixUserId: localStorage.getItem('mx_user_id'),
                userAudioRights,
                userVideoRights,
            });
            const videoElement = document.getElementById('video-element-preview');
            if (videoElement) {
                videoElement.addEventListener('contextmenu', this.videoRightClick);
            }
        }

        if (payload.action === 'toggle_conference') {
            this.shouldDisplayWarning = payload.isOpen;
        }

        payload.action === 'toggle_conference' && payload.isOpen === false && window.close();
    };

    videoRightClick = (ev) => ev.preventDefault();

    beforeEndCall = () => {
        this.shouldDisplayWarning = false;
        CitadexStore.setIsCitadexOpened(false);
    };

    requestRights = async (type) => {
        const currentType = type ? type : this.state.type;
        let hasCameraPermissions = this.state.hasCameraPermissions ? this.state.hasCameraPermissions : undefined;
        let hasMicrophonePermissions = this.state.hasMicrophonePermissions
            ? this.state.hasMicrophonePermissions
            : false;
        if (!hasMicrophonePermissions) {
            await getStreams({
                type: 'audio',
                callback: () => {
                    hasMicrophonePermissions = true;
                },
                onError: (err) => {
                    hasMicrophonePermissions = false;
                    this.showPermissionDialog();
                    CitadexStore.error('can not get audio stream', err);
                },
                closeStream: true,
            });
        }

        if (currentType === 'video' && !hasCameraPermissions) {
            await getStreams({
                requestingVideoRights: true,
                type: 'video',
                callback: () => {
                    hasCameraPermissions = true;
                },
                onError: (err) => {
                    hasCameraPermissions = false;
                    CitadexStore.error('can not get video stream', err);
                },
                closeStream: true,
            });
        }

        this.setState({ hasCameraPermissions, hasMicrophonePermissions });
        return { hasCameraPermissions, hasMicrophonePermissions };
    }

    showPermissionDialog = () => errorDialog({
        onContinue: () => {
            this.beforeEndCall();
            CitadexStore.destroySession();
        },
        type: ERROR_TYPES.NOT_ALLOWED,
    });

    getLocalStreams = async (newSelectedDevices, shouldVideoPreviewUpdate) => {
        this.setState({ isLoading: true });
        const {
            audioSourceId, audioSourceLabel, hasCameraPermissions, videoDevices = [], videoSourceId, isVideoCall,
        } = this.state;
        let selectedDevices;

        if (!isVideoCall && (!videoDevices.length || !hasCameraPermissions)) {
            this.setState({ isLoading: false });
            return;
        }

        if (!newSelectedDevices && audioSourceId && videoSourceId) {
            selectedDevices = { audioSourceId, videoSourceId, audioSourceLabel };
        }

        if (newSelectedDevices && newSelectedDevices.videoSourceId) {
            selectedDevices = selectedDevices || {};
            selectedDevices.videoSourceId = newSelectedDevices.videoSourceId;
        }
        getStreams({
            type: TYPE_VIDEO,
            callback: (stream) => {
                if (this.lastSavedStream) {
                    this.stopElementTracks(this.lastSavedStream);
                    this.stopStreams();
                }
                this.lastSavedStream = stream;
                this.setState({ isLoading: false });
                if (!newSelectedDevices || shouldVideoPreviewUpdate) {
                    this.gotStream(stream);
                }
            },
            onError: (error) => {
                this.setState({ isLoading: false, isVideoEnabled: false });
                CitadexStore.error('can not get video stream', error);
            },
            selectedDevices,
            closeStream: !this.props.isConferencePreview,
        });
    };

    gotStream = (stream) => {
        if (!this._isMounted) {
            this.stopElementTracks(stream);
            return;
        }

        const { isPreview } = this.state;

        if (stream.getVideoTracks()) {
            if (!isPreview) return;

            const videoElement = document.getElementById('video-element-preview');
            StreamAPI.attachMediaStream(videoElement, stream);
            this.setState({ isVideoEnabled: true });
        }
    }

    stopElementTracks = (stream, element) => {
        if (!stream) return;

        StreamAPI.stopTracks(stream.getTracks());
        if (element) element.srcObject = null;
    }

    stopStreams = () => {
        const videoElement = document.getElementById('video-element-preview');
        StreamAPI.stopElementStream(videoElement);
    }

    onAudioClick = () => this.setState({ isAudioEnabled: !this.state.isAudioEnabled })
    onVideoClick = () => {
        const { isVideoEnabled } = this.state;
        this.setState({ isVideoEnabled: !isVideoEnabled }, () => {
            if (!this.state.isVideoEnabled) {
                this.stopStreams();
            } else {
                this.getLocalStreams();
            }
        });
    }

    onContinueClick = () => {
        this.setState({ isLoading: true, onContinuePressed: true });
        if (localStorage.getItem("mx_refresh_token")) {
            this.onContinue(!!this.state.callInProgress)();
        }
    }
    onContinue = (isCallOpen) => async () => {
        const { type, matrixRoomId, userAudioRights, userVideoRights } = this.state;
        const accountRights = type === 'audio' ? userAudioRights : userVideoRights;
        try {
            if (!isCallOpen && !accountRights) {
                const { visio, bridge } = await MatrixClientPeg.get().getServices();
                const serviceRights = type === 'audio' ? bridge : visio;
                if (!CONFERENCE_ENABLED.includes(serviceRights)) {
                    this.setState({ isLoading: false });
                    CitadexStore.log(`no rights to start a call of type ${type}`);
                    errorDialog({
                        onContinue: CitadexStore.destroySession,
                        type: ERROR_TYPES.GENERIC,
                    });
                    return;
                }
            }
            this.stopStreams();

            if (!isCallOpen) {
                    await CitadexStore.createRoom(type, matrixRoomId);
                    this.setState({ isPreview: false });
            } else {
                this.joinConference();
            }
            this.setState({ isLoading: false });
        } catch (e) {
            if (e.message.includes('CORS')) {
                const type = ERROR_TYPES.NO_INTERNET;
                Modal.createTrackedDialog(
                '',
                '',
                ErrorDialogContent,
                { hasCancelButton: false, onContinue: this.closeNoInternetModal, type },
                'citadex_error_dialog',
                );
            }
        }
    }

    joinConference = async () => {
        const { callInProgress, matrixRoomId } = this.state;
        const { room_janus_id: roomId, type, room_janus_url: url } = callInProgress.event.content;
        try {
            await CitadexStore.prepareSessionCreation(roomId, type, url, matrixRoomId);
            this.setState({ isPreview: false });
        } catch (e) {
            this.shouldDisplayWarning = false;
            errorDialog({
                onContinue: () => {
                    CitadexStore.destroySession();
                    this.props.conferenceInNewTab && window.close();
                },
                type: e.toString(),
            });
        }
    }

    onChangeDevices = async (selectedDevices) => {
        const { isPreview } = this.state;
        navigator.mediaDevices.ondevicechange = this.updateDeviceList;
        let newSelectedDevices = {
            audioSourceLabel: this.state.audioSourceLabel,
            audioSourceId: this.state.audioSourceId,
            videoSourceId: this.state.videoSourceId,
        };
        const shouldVideoPreviewUpdate = this.state.videoSourceId !== selectedDevices.videoSourceId;
        if (selectedDevices) {
            const { audioSourceId, videoSourceId, outputId, audioSourceLabel } = selectedDevices;
            newSelectedDevices = { audioSourceId, videoSourceId, audioSourceLabel };
            this.setState({ audioSourceId, videoSourceId, outputId, audioSourceLabel });
        }
        await this.requestRights();
        isPreview && this.getLocalStreams(newSelectedDevices, shouldVideoPreviewUpdate);
        await this.updateDevicesAndGetStreams();
    }

    checkIfDeviceIsConnected = (deviceId, list) => list.findIndex((device) => device.deviceId === deviceId) >= 0;

    getDeviceIdIfDisconnected = (deviceId, list) => {
        if (this.checkIfDeviceIsConnected(deviceId, list)) {
            return deviceId;
        } else {
            if (list.length) {
                return list[0].deviceId;
            } else {
                return null;
            }
        }
    }

    getDeviceLabelIfDisconnected = (deviceId, label, list) => {
        if (this.checkIfDeviceIsConnected(deviceId, list)) {
            return label;
        } else {
            if (list.length) {
                return list[0].label;
            } else {
                return null;
            }
        }
    }

    updateDeviceList = async () => {
        const {
            audioSourceId: prevAudioSourceId,
            videoSourceId: prevVideoSourceId,
            outputId: prevOutputId,
            audioSourceLabel: prevAudioSourceLabel,
            isVideoCall,
        } = this.state;
        const devices = await navigator.mediaDevices.enumerateDevices(); // deviceId, groupId, kind, label
        const audioDevices = [];
        const videoDevices = [];
        const outputDevices = [];
        let audioSourceId = prevAudioSourceId;
        let videoSourceId = prevVideoSourceId;
        let audioSourceLabel = prevAudioSourceLabel;
        let outputId = prevOutputId;

        devices.forEach(device => {
            switch (device.kind) {
                case 'audioinput':
                    if (!audioSourceId && !!device.deviceId) {
                        audioSourceId = device.deviceId;
                        audioSourceLabel = device.label;
                    }
                    audioDevices.push(device);
                    break;
                case 'videoinput':
                    if (!videoSourceId && !!device.deviceId && isVideoCall) {
                        videoSourceId = device.deviceId;
                    }
                    videoDevices.push(device);
                    break;
                case 'audiooutput':
                    if (!outputId && !!device.deviceId) {
                        outputId = device.deviceId;
                    }
                    outputDevices.push(device);
                    break;
                default:
                    CitadexStore.log('Some other kind of source/device: ', device);
            }
        });

        // in case that the device was disconnected we change the id with the first in the list
        audioSourceId = this.getDeviceIdIfDisconnected(audioSourceId, audioDevices);
        videoSourceId = this.getDeviceIdIfDisconnected(videoSourceId, videoDevices);
        outputId = this.getDeviceIdIfDisconnected(outputId, outputDevices);
        audioSourceLabel = this.getDeviceLabelIfDisconnected(audioSourceLabel, audioSourceId, audioDevices);

        const partialState = {
            audioDevices, audioSourceId, audioSourceLabel, outputDevices, outputId, videoDevices, videoSourceId,
        };
        if (!videoSourceId) {
            partialState.isVideoEnabled = false;
        }
        this.setState({ ...partialState });
        return partialState;
    }

    onSettingsClick = () => {
        const {
            audioSourceId,
            hasCameraPermissions,
            isPreview,
            isSoundNotificationOn,
            isVideoStreamSubscriptionDisabled,
            isVideoCall,
            matrixUserId,
            outputId,
            videoSourceId,
            audioSourceLabel,
        } = this.state;
        const selectedDevices = { audioSourceId, videoSourceId, outputId, audioSourceLabel };
        isPreview && this.stopStreams();
        Modal.createTrackedDialog(
            'Conference Settings Dialog',
            '',
            ConferenceDeviceSettingsDialog,
            {
                onFinished: this.onChangeDevices,
                stopStream: this.stopElementTracks,
                isVideoCall,
                userId: matrixUserId,
                selectedDevices,
                videoRightClick: this.videoRightClick,
                getDeviceListsAndIds: this.updateDeviceList,
                hasCameraPermissions,
                isSoundNotificationOn,
                isVideoStreamSubscriptionDisabled,
                onChangeSoundNotifications: this.toggleSoundNotifications,
                onChangeVideoStreamSubscription: this.toggleVideoStreamSubscription,
            },
            'mx_ConferenceDeviceSettingsDialog',
        );
    }

    getTitle = (isCallOpen) => {
        const { roomName, isDmRoom } = this.state;
        let textsForAction;
        if (isDmRoom) {
            textsForAction = {
                joining: 'Join a conference with',
                starting: 'Start a conference with',
            };
        } else {
            textsForAction = {
                joining: 'Joining',
                starting: 'Starting',
            };
        }
        return isCallOpen
            ? _t(`${textsForAction.joining} %(roomName)s`, { roomName })
            : _t(`${textsForAction.starting} %(roomName)s`, { roomName });
    }

    getDescription = () => {
        const { inviterName } = this.state;
        return inviterName ? _t('Started by %(name)s', { name: inviterName }) : null;
    }

    getOverlayText = () => {
        const { isAudioEnabled, isVideoEnabled, isVideoCall, videoSourceId, hasCameraPermissions } = this.state;
        let text = _t(`Microphone ${isAudioEnabled ? 'on' : 'off'}`);
        if (isVideoCall && !!videoSourceId && hasCameraPermissions) {
            text = `${text} ${_t(`and camera ${isVideoEnabled ? 'on' : 'off'}`)}`;
        }
        if (isVideoCall && (!videoSourceId || !hasCameraPermissions)) {
            text = `${text} ${_t('and')} ${_t('no camera')}`;
        }
        return text;
    }

    renderButtons = () => {
        const {
            isAudioEnabled, isVideoEnabled, isVideoCall, videoSourceId,
            hasCameraPermissions, iconPack,
        } = this.state;

        return (
            <div className='button-bar'>
                <div className='mic-cam'>
                    <ConferenceButton
                        className='citadex-preview-btn audio'
                        onClick={this.onAudioClick}
                        tooltip={isAudioEnabled ? _t('Turn microphone off') : _t('Turn microphone on')}
                        icon={isAudioEnabled ? iconPack.audioOn : iconPack.audioOff}
                        iconHover={isAudioEnabled ? iconPack.audioOnHover : iconPack.audioOffHover}
                    />
                    {isVideoCall ?
                        <ConferenceButton
                            className='citadex-preview-btn audio'
                            onClick={this.onVideoClick}
                            disabled={!videoSourceId || !hasCameraPermissions}
                            tooltip={isVideoEnabled ? _t('Turn camera off') : _t('Turn camera on')}
                            icon={isVideoEnabled ? iconPack.videoOn : iconPack.videoOff}
                            iconHover={isVideoEnabled ? iconPack.videoOnHover : iconPack.videoOffHover}
                        />
                        : null
                    }
                </div>
            </div>
        );
    }

    handleClosePreview = (isLoading) => () => {
        if (isLoading) return;
        this.stopStreams();
        CitadexStore.destroySession();
    }

    toggleSoundNotifications = (isSoundNotificationOn) => {
        this.setState({ isSoundNotificationOn: isSoundNotificationOn });
        MatrixClientPeg.get().trackUserAction({
            formId: 'preConference',
            version: 1,
            action: isSoundNotificationOn ? "unmuteNotifications" : "muteNotifications",
        });
    }

    toggleIceTransportPolicy = (iceTransportPolicy) => () => {
        this.setState({ iceTransportPolicy });
    }

    toggleVideoStreamSubscription = (isVideoStreamSubscriptionDisabled) => {
        this.setState({ isVideoStreamSubscriptionDisabled });
    }

    renderPreview = (isCallOpen) => {
        const {
            audioSourceId,
            audioSourceLabel,
            debugModePreview,
            hasCameraPermissions,
            iceTransportPolicy,
            isLoading,
            isSoundNotificationOn,
            isVideoCall,
            isVideoEnabled,
            isVideoStreamSubscriptionDisabled,
            matrixUserId,
            outputId,
            videoSourceId,
        } = this.state;
        const selectedDevices = { audioSourceId, videoSourceId, outputId, audioSourceLabel };
        const description = this.getDescription();
        return (
            <div className='preview-container'>

                <div className='preview'>
                    <CitadelRoomName className='title' roomName={this.getTitle(isCallOpen)} width={780} />

                    <div className="description">{description}</div>
                    <div className="video-and-settings-container">
                        <div className='video-container'>
                            <div className='status-overlay'>{this.getOverlayText()}</div>
                            <ParticipantCard
                                avatarSize={'medium'}
                                isAudioEnabled={true}
                                isMe={true}
                                isPreview={true}
                                isVideoEnabled={!!(isVideoCall && isVideoEnabled)}
                                streamId={'preview'}
                                userId={matrixUserId}
                            />
                            {this.renderButtons()}
                        </div>
                        <ConferenceDeviceSettings
                            getDeviceListsAndIds={this.updateDeviceList}
                            hasCameraPermissions={hasCameraPermissions}
                            isVideoCall={isVideoCall}
                            stopStream={this.stopElementTracks}
                            changeDevices={this.onChangeDevices}
                            selectedDevices={selectedDevices}
                        />
                    </div>
                    {isVideoCall
                        ? (
                            <div className='conference-subscribe-video-container'>
                                <ToggleSwitch
                                    checked={isVideoStreamSubscriptionDisabled}
                                    onChange={this.toggleVideoStreamSubscription}
                                />
                                <span className='conference-subscribe-video-text'>
                                    {_t("Disable incoming video streams")}
                                </span>
                            </div>
                        )
                        : null
                    }
                    <div className='conference-sound-notification-container'>
                        <ToggleSwitch checked={isSoundNotificationOn} onChange={this.toggleSoundNotifications} />
                        <span className='conference-sound-notification-text'>
                            {_t("Notification sound when someone joins or leaves the conference")}
                        </span>
                    </div>
                    {debugModePreview ?
                        <div className="conference-ice-transport-relay">
                            <span>{'Ice transport policy:'}</span>
                            <CitadelToggle
                                type="radio"
                                checked={iceTransportPolicy === ICE_TRANSPORT_POLICY.all}
                                label="all"
                                onChange={this.toggleIceTransportPolicy(ICE_TRANSPORT_POLICY.all)}
                            >
                                <label htmlFor="all">all</label>
                            </CitadelToggle>
                            <CitadelToggle
                                type="radio"
                                checked={iceTransportPolicy === ICE_TRANSPORT_POLICY.relay}
                                label="relay"
                                onChange={this.toggleIceTransportPolicy(ICE_TRANSPORT_POLICY.relay)}
                            >

                            </CitadelToggle>
                        </div>
                        : null
                    }
                    <div className='conference-start-btn-container'>
                        <CitadelButton
                            className="conference-start-btn"
                            isLoading={isLoading || !this.state.isPreview}
                            isDisabled={isLoading || !this.state.isPreview}
                            onClick={this.onContinueClick}
                            text={isCallOpen ? _t('Join the conference') : _t('Start the conference')}
                        />
                    </div>
                </div>
            </div>
        );
    }


    render() {
        const {
            audioSourceId,
            audioSourceLabel,
            callInProgress,
            hasCameraPermissions,
            iceTransportPolicy,
            isAudioEnabled,
            isDmRoom,
            isLoading,
            isPreview,
            isSoundNotificationOn,
            isVideoCall,
            isVideoEnabled,
            isVideoStreamSubscriptionDisabled,
            outputId,
            roomName,
            type,
            videoSourceId,
        } = this.state;
        const { isMinimized } = this.props;
        const callInProgressContent = callInProgress ? callInProgress.event.content : undefined;
        const conferenceRoomId = callInProgressContent ? callInProgressContent.room_janus_id : undefined;

        return (
            <div className='citadex_visio_room'>
                {isPreview || !conferenceRoomId
                    ? (
                        <div>
                            <img
                                className={cx('preview_close', { disabled: isLoading })}
                                onClick={this.handleClosePreview(isLoading)}
                                src={closeImage}
                            />
                            {this.renderPreview(!!callInProgress)}
                        </div>
                    )
                    : <CitadexCall
                        key={this.props.citadexKey}
                        id={this.props.citadexKey}
                        audioSourceId={audioSourceId}
                        audioSourceLabel={audioSourceLabel}
                        callInProgress={callInProgressContent}
                        iceTransportPolicy={iceTransportPolicy}
                        isAudioEnabled={isAudioEnabled}
                        isDmRoom={isDmRoom}
                        isMinimized={isMinimized}
                        isSoundNotificationOn={isSoundNotificationOn}
                        isVideoEnabled={isVideoCall && isVideoEnabled && hasCameraPermissions}
                        isVideoStreamSubscriptionDisabled={isVideoStreamSubscriptionDisabled}
                        matrixUserId={localStorage.getItem("mx_user_id")}
                        onChangeDevices={this.onChangeDevices}
                        onEndCallClick={this.beforeEndCall}
                        onSettingsClick={this.onSettingsClick}
                        outputId={outputId}
                        roomId={conferenceRoomId}
                        roomName={roomName}
                        stopElementTracks={this.stopElementTracks}
                        type={type}
                        videoSourceId={hasCameraPermissions && isVideoCall ? videoSourceId : undefined}
                        url={callInProgress.event.content.room_janus_url}
                        isOffline={this.props.isOffline}
                        retryProcessStarted={this.props.retryProcessStarted}
                        citadexKey={this.props.citadexKey}
                    />
                }
                <Toast listType={TOAST_LIST_TYPE.CITADEX} />
            </div>
        );
    }
}

export default CitadexPage;
