import moment from 'moment';

export const getPlanConferenceCalendarTimeFormat = () => {
    const minutesArr = [0, 15, 30, 45];
    const hours = [];

    for (let hour = 0; hour < 24; hour++) {
        const m = moment().utcOffset(0);
        for (const minutes of minutesArr) {
            m.set({ hour, minute: minutes, second: 0, millisecond: 0 });
            hours.push(m.format('HH:mm'));
        }
    }
    return hours;
};

