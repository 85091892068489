import React from 'react';
import PropTypes from "prop-types";

const Slider = ({ length, onPositionChange, position = 0 }) => {
    const handlePositionChange = (newPosition) => () => {
        onPositionChange(newPosition);
    };

    const dotContainers = Array.from({ length }, (_, i) => (
        <div className='dot-container' key={i} onClick={handlePositionChange(i)}>
            {i === position ? <div className='dot' /> : null }
        </div>
    ));

    return (
        <div className={'slider'}>
            <div className="dot-containers">
                {dotContainers}
            </div>
        </div>
    );
};

Slider.propTypes = {
    length: PropTypes.number.isRequired,
    onPositionChange: PropTypes.func.isRequired,
    position: PropTypes.number,
};

export default Slider;
