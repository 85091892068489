import InfoDialog from "../InfoDialog";

const RoomExportInfoDialog = ({ onFinished, title, description }) => (
    <InfoDialog
        title={title}
        description={description}
        hasCloseButton={true}
        onFinished={onFinished}
    />
);

export default RoomExportInfoDialog;
