import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { _t } from '../../../../languageHandler';
import BaseDialog from '../BaseDialog';
import DialogButtons from '../../elements/DialogButtons';

export default class NewsLeaveDialog extends Component {
    static displayName = "NewsLeaveDialog";

    static propTypes = {
        clearStore: PropTypes.func.isRequired,
        onCancel: PropTypes.func,
        onFinished: PropTypes.func.isRequired,
        onContinue: PropTypes.func.isRequired,
    };

    static defaultProps = {
        clearStore: noop,
        onFinished: noop,
        onContinue: noop,
    };

    onContinueClick = () => {
        const { clearStore, onContinue, onFinished } = this.props;
        clearStore();
        onContinue();
        onFinished();
    };

    onCancelClick = () => {
        const { onCancel, onFinished } = this.props;

        if (onCancel) {
            onCancel();
            return;
        }

        onFinished();
    };

    render() {
        const { onFinished } = this.props;

        return (
            <BaseDialog
                title={
                    <Fragment>
                        <div>{_t('You were writing a superb News.')}</div>
                        <div>{_t('Are you sure you want to continue ?')}</div>
                    </Fragment>
                }
                hasCancel={true}
                onFinished={onFinished}
            >
                <div className="news_leave_note">{_t('Your changes will be lost.')}</div>
                <div className="news_leave_buttons">
                    <DialogButtons
                        primaryButton={_t('Cancel')}
                        cancelButton={_t('Continue')}
                        onPrimaryButtonClick={this.onCancelClick}
                        onCancel={this.onContinueClick}
                    />
                </div>
            </BaseDialog>
        );
    }
}
