import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from 'classnames';

import ToastStore, { LIST_TYPE } from '../../../stores/ToastStore';
import ToastElement from './ToastElement';
import dis from "../../../dispatcher";

class ToastList extends Component {
    static propTypes = {
        listType: PropTypes.string,
    }

    static defaultProps = {
        listType: LIST_TYPE.DEFAULT,
    };

    constructor(props) {
        super(props);
        const { listType } = this.props;
        this.state = { toastList: [] };
        ToastStore.init(listType);
    }

    componentDidMount() {
        this.toastStoreToken = ToastStore.addListener(() => {
            this.setState({ toastList: ToastStore.getToastList(this.props.listType)});
        });
    }

    componentWillUnmount() {
        if (this.toastStoreToken) {
            this.toastStoreToken.remove();
        }
    }

    onClose = (id) => (ev) => {
        const { listType } = this.props;
        ev && ev.preventDefault();
        dis.dispatch({action: 'remove_toast', value: { id, listType }});
    }

    render() {
        const { toastList } = this.state;
        const { listType } = this.props;
        return (
            <div className={cx('toast-container', { [listType]: !!listType })}>
                {toastList.map((el) => <ToastElement key={el.id} data={el} onClose={this.onClose(el.id)} />)}
            </div>
        );
    }
}

export default ToastList;
