import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import sdk from '../../../index';
import dis from '../../../dispatcher';
import { generateUserHash } from '../../../utils/CitadelUtils';
import { register } from '../../../utils/CitadelRequests';
import TokenValidationStep from './TokenValidationStep';
import PasswordValidationStep from './PasswordValidationStep';
import ProfileValidationStep from './ProfileValidationStep';

const PHASE_TOKEN = 1;
const PHASE_PASSWORD = 2;
const PHASE_PROFILE = 3;

class CitadelRegistration extends React.Component {
    constructor(props) {
        super(props);

        const { sid, clientSecret } = props;
        this.state = {
            sid,
            clientSecret,
            token: '',
            password: '',
            phase: PHASE_TOKEN,
            disableRegister: false,
        };
    }

    register = (firstName, lastName) => {
        const {
            onLoggedIn,
            defaultDeviceDisplayName,
            portalUrl,
            servicesUrl,
            homeserverUrl,
        } = this.props;
        const { password, clientSecret, sid, disableRegister } = this.state;
        if (disableRegister) return;
        this.setState({ disableRegister: true });

        const userId = generateUserHash();

        const formattedFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
        const formattedLastName = lastName.toUpperCase();
        const displayName = `${formattedFirstName} ${formattedLastName}`;

        register(homeserverUrl, clientSecret, sid, userId, password, defaultDeviceDisplayName, displayName)
            .then((res) => {
                const data = {
                    homeserverUrl: homeserverUrl,
                    identityServerUrl: homeserverUrl,
                    userId: res.user_id,
                    deviceId: res.device_id,
                    accessToken: res.access_token,
                    portalUrl,
                    servicesUrl,
                };
                onLoggedIn(data, 'register');
                localStorage.setItem('mx_user_email', this.props.email);
            })
            .catch((err) => {
                this.setState({disableRegister: false});
                this.handleError(err);
            });
    };

    handleError = (err) => {
        this.setState({ disableRegister: false });
        console.error(err);
    };

    onTokenValidationSuccess = (clientSecret, sid) => {
        this.setState({
            clientSecret,
            sid,
            phase: PHASE_PASSWORD,
        });
    };

    onPasswordValidationSuccess = (password) => {
        this.setState({
            password,
            phase: PHASE_PROFILE,
        });
    };

    onBack = () => {
        dis.dispatch({ action: 'start_registration' });
    };

    renderStepBar = () => {
        const { phase } = this.state;

        const tokenStepClasses = classNames({
            token: true,
            highlight: (phase >= PHASE_TOKEN),
        });

        const passwordStepClasses = classNames({
            password: true,
            highlight: (phase >= PHASE_PASSWORD),
        });

        const profileStepClasses = classNames({
            profile: true,
            highlight: (phase >= PHASE_PROFILE),
        });

        return (
            <div className="steps">
                <div className={tokenStepClasses} />
                <div className={passwordStepClasses} />
                <div className={profileStepClasses} />
            </div>
        );
    };

    renderForms = () => {
        const { phase } = this.state;
        const { email, homeserverUrl, sid, clientSecret } = this.props;

        switch (phase) {
            case PHASE_TOKEN:
                return (
                    <TokenValidationStep
                        onNext={this.onTokenValidationSuccess}
                        onBack={this.onBack}
                        email={email}
                        homeserverUrl={homeserverUrl}
                        sid={sid}
                        clientSecret={clientSecret}
                    />
                );
            case PHASE_PASSWORD:
                return (
                    <PasswordValidationStep
                        onNext={this.onPasswordValidationSuccess}
                    />
                );
            case PHASE_PROFILE:
                return (
                    <ProfileValidationStep
                        onNext={this.register}
                        email={email}
                    />
                );
        }
    };

    render() {
        const AuthPage = sdk.getComponent('auth.AuthPage');

        return (
            <AuthPage>
                <div className="mx_CitadelRegistration">
                    {this.renderStepBar()}
                    {this.renderForms()}
                </div>
            </AuthPage>
        );
    }
}

CitadelRegistration.propTypes = {
    portalUrl: PropTypes.string.isRequired,
    servicesUrl: PropTypes.string.isRequired,
    onLoggedIn: PropTypes.func.isRequired,
    defaultDeviceDisplayName: PropTypes.string.isRequired,
    email: PropTypes.string,
    homeserverUrl: PropTypes.string,
    sid: PropTypes.string,
    clientSecret: PropTypes.string,
};

export default CitadelRegistration;
