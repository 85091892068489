import React from 'react';

import BaseDialog from '../BaseDialog';
import { _t } from '../../../../languageHandler';
import updateSvg from '../../../../../res/img/sign-up-email-sent.svg';

class RequestSuccessDialog extends React.Component {
    componentDidMount() {
        setTimeout(this.props.onFinished, 5000);
    }
    render() {
        const { className, onFinished } = this.props;
        return (
            <BaseDialog
                className={className}
                fixedWidth={false}
                hasCancel={false}
                onFinished={onFinished}
                title=""
            >
                <div className="content">
                    <div className="header">
                        <img src={updateSvg} alt="" />
                        <div className="title">
                            {_t('Request sent')}
                        </div>
                    </div>
                    <div className="description">
                        {_t('A message has been sent to the support team, you will receive a confirmation email when the service will be activated.')}
                    </div>
                </div>
            </BaseDialog>
        );
    }
}

export default RequestSuccessDialog;
