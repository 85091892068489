import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import BaseDialog from '../BaseDialog';
import CitadelButton from '../../elements/CitadelButton';

export default class MfaSuccessErrorDialog extends Component {
    componentDidMount() {
        const { autoCloseAfter = 0, onFinished } = this.props;

        if (autoCloseAfter) this.timeout = setTimeout(onFinished, autoCloseAfter);
    }

    componentWillUnmount() {
        if (this.timeout) clearTimeout(this.timeout);
    }

    render() {
        const { icon, title, text, buttonText, onFinished } = this.props;

        return (
            <BaseDialog
                className="mx_ActivationSuccessOrErrorDialog"
                contentId="mx_Dialog_content"
                fixedWidth={false}
                hasCancel={false}
                onFinished={this.props.onFinished}
                title=""
                isFocusable={!!buttonText}
            >
                {!!icon && <img className="icon" src={icon} alt="" />}
                <div className="title">{title}</div>
                <div className="text">{text}</div>
                {!!buttonText && <CitadelButton onClick={onFinished} text={buttonText} minWidth={200} />}
            </BaseDialog>
        );
    }
}

MfaSuccessErrorDialog.propTypes = {
    icon: PropTypes.string.isRequired,
    onFinished: PropTypes.func,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
    title: PropTypes.string.isRequired,
};

MfaSuccessErrorDialog.defaultProps = {
    icon: '',
    onFinished: noop,
    text: '',
    title: '',
};
