import PropTypes from 'prop-types';

const CitadelAccordionSection = ({ children }) => ({ children });

CitadelAccordionSection.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
};

CitadelAccordionSection.defaultProps = {
    label: '',
    isOpen: false,
};

export default CitadelAccordionSection;
