/*
Copyright 2015, 2016 OpenMarket Ltd
Copyright 2018 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React from 'react';
import PropTypes from 'prop-types';
import Promise from 'bluebird';
import classNames from 'classnames';
import MatrixClientPeg from '../../../MatrixClientPeg';
import AvatarLogic from '../../../Avatar';
import dis from '../../../dispatcher';
import AccessibleButton from '../elements/AccessibleButton';
import { fetchUrlWithToken, getMxcFromUrl, shouldSaveTheBlob } from '../../../utils/MediaUtils';
import MxcBlobStore from '../../../stores/MxcBlobStore';
import EmojiText from '../elements/EmojiText';

import ffffff from '../../../../res/img/ffffff.png';

export default class extends React.Component {
    static displayName = 'BaseAvatar';

    static propTypes = {
        forExport: PropTypes.bool,
        name: PropTypes.string.isRequired, // The name (first initial used as default)
        idName: PropTypes.string, // ID for generating hash colours
        title: PropTypes.string, // onHover title text
        url: PropTypes.string, // highest priority of them all, shortcut to set in urls[0]
        urls: PropTypes.array, // [highest_priority, ... , lowest_priority]
        width: PropTypes.number,
        height: PropTypes.number,
        // XXX resizeMethod not actually used.
        resizeMethod: PropTypes.string,
        defaultToInitialLetter: PropTypes.bool, // true to add default url
    };

    static defaultProps = {
        width: 40,
        height: 40,
        resizeMethod: 'crop',
        defaultToInitialLetter: true,
    };

    constructor(props) {
        super(props);
        this.unmounted = true;
        this.state = this._getState(props);
        this.cli = MatrixClientPeg.get();
        this.cli.on('sync', this.onClientSync);
    }

    componentDidMount() {
        this.unmounted = false;
        const { imageUrls } = this.state;
        this.tokenizeUrls(imageUrls);
    }

    componentDidUpdate(prevProps) {
        // work out if we need to call setState (if the image URLs array has changed)
        const newState = this._getState(this.props);
        const newImageUrls = newState.imageUrls;
        const oldImageUrls = this.state.imageUrls;

        let shouldUpdate = false;

        if (!oldImageUrls || newImageUrls.length !== oldImageUrls.length) {
            this.tokenizeUrls(newImageUrls);
            shouldUpdate = true;
        } else {
            // check each one to see if they are the same
            for (let i = 0; i < newImageUrls.length; i++) {
                if (oldImageUrls[i] !== newImageUrls[i]) {
                    this.tokenizeUrls(newImageUrls);
                    shouldUpdate = true;
                    break;
                }
            }

            if (prevProps.name !== this.props.name) {
                shouldUpdate = true;
            }
        }

        if (shouldUpdate) this.setState(newState);
    }

    componentWillUnmount() {
        this.unmounted = true;
        this.cli.removeListener('sync', this.onClientSync);
    }

    tokenizeUrls = (urls) => {
        const tokenizedUrls = [];

        Promise.map(urls, (url, i) => {
            if (url.indexOf("/_matrix/media/") > 0) {
                const mxc = getMxcFromUrl(url);
                const blob = MxcBlobStore.getBlobFromAvatarMxc(mxc);
                if (blob) {
                    tokenizedUrls[i] = blob;
                } else {
                    return fetchUrlWithToken(url)
                        .then((blob) => {
                            if (blob) {
                                const size = shouldSaveTheBlob(url);
                                if (size) {
                                    dis.dispatch({
                                        action: 'update_avatar_blob_store',
                                        mxc: mxc,
                                        blob: blob,
                                        size: size,
                                    });
                                }
                                tokenizedUrls[i] = blob;
                            }
                        })
                        .catch((err) => {
                            console.error('Could not retrieve url content ', err);
                        });
                }
            } else {
                tokenizedUrls[i] = url;
            }
        })
            .then(() => {
                !this.unmounted && this.setState({
                    tokenizedUrls: tokenizedUrls,
                });
            });
    };

    onClientSync = (syncState, prevState) => {
        if (this.unmounted) return;

        // Consider the client reconnected if there is no error with syncing.
        // This means the state could be RECONNECTING, SYNCING, PREPARED or CATCHUP.
        const reconnected = syncState !== "ERROR" && prevState !== syncState;
        if (reconnected &&
            // Did we fall back?
            this.state.urlsIndex > 0
        ) {
            // Start from the highest priority URL again
            this.setState({
                urlsIndex: 0,
            });
        }
    };

    _getState = (props) => {
        // work out the full set of urls to try to load. This is formed like so:
        // imageUrls: [ props.url, props.urls, default image ]

        const urls = props.urls || [];
        if (props.url) {
            urls.unshift(props.url); // put in urls[0]
        }

        let defaultImageUrl = null;
        if (props.defaultToInitialLetter) {
            defaultImageUrl = AvatarLogic.defaultAvatarUrlForString(
                props.idName || props.name,
            );
            urls.push(defaultImageUrl); // lowest priority
        }

        return {
            imageUrls: urls,
            defaultImageUrl,
            initialLetter: this._getInitialLetter(props.name),
        };
    };

    /**
     * returns the first (non-sigil) character of 'name',
     * converted to uppercase
     */
    _getInitialLetter = (name) => {
        if (name.length < 1) {
            return undefined;
        }

        let idx = 0;
        const initial = name[0];
        if ((initial === '@' || initial === '#' || initial === '+') && name[1]) {
            idx++;
        }

        // string.codePointAt(0) would do this, but that isn't supported by
        // some browsers (notably PhantomJS).
        let chars = 1;
        const first = name.charCodeAt(idx);

        // check if it’s the start of a surrogate pair
        if (first >= 0xD800 && first <= 0xDBFF && name[idx+1]) {
            const second = name.charCodeAt(idx+1);
            if (second >= 0xDC00 && second <= 0xDFFF) {
                chars++;
            }
        }

        const firstChar = name.substring(idx, idx+chars);
        return firstChar.toUpperCase();
    };

    getImageUrl = () => {
        const { imageUrls, tokenizedUrls, defaultImageUrl } = this.state;
        let imageUrl = null;

        if (tokenizedUrls && !this.props.forExport) {
            let index = 0;
            while (!imageUrl) {
                if (tokenizedUrls[index]) {
                    imageUrl = tokenizedUrls[index];
                } else {
                    index++;
                }
            }
        } else {
            if (imageUrls && imageUrls[0] === defaultImageUrl) {
                imageUrl = defaultImageUrl;
            } else {
                const blob = MxcBlobStore.getBlobFromAvatarMxc(imageUrls[0]);
                if (blob) {
                    imageUrl = blob;
                } else {
                    if (this.props.forExport && this.props.url) {
                        imageUrl = this.props.url;
                    } else {
                        imageUrl = ffffff;
                    }
                }
            }
        }

        return imageUrl;
    };

    render() {
        const {
          defaultImageUrl,
          initialLetter,
         } = this.state;

        const {
            // eslint-disable-next-line no-unused-vars
            name, idName, title, url, urls, width, height, isExternal, forExport,
            // eslint-disable-next-line no-unused-vars
            resizeMethod, defaultToInitialLetter, onClick, memberAvatar, disabled,
            ...otherProps
        } = this.props;

        const imageUrl = this.getImageUrl();

        if (imageUrl === defaultImageUrl) {
            const textNode = (
                <EmojiText className="mx_BaseAvatar_initial" aria-hidden="true"
                    style={{ fontSize: (width * 0.65) + "px", width: "100%", height: "100%" }}
                >
                    { initialLetter }
                </EmojiText>
            );
            const imgClassNames = classNames({
                "mx_BaseAvatar_image": true,
                "mx_BaseAvatar_external": isExternal,
            });
            const imgNode = (
                <img className={imgClassNames} src={imageUrl}
                    alt="" title={title}
                    width={width-2} height={height-2} />
            );
            if (onClick != null) {
                return (
                    <AccessibleButton element='span' className="mx_BaseAvatar"
                        onClick={onClick} {...otherProps}
                    >
                        { textNode }
                        { imgNode }
                    </AccessibleButton>
                );
            } else {
                return (
                    <span className="mx_BaseAvatar" {...otherProps}>
                        { textNode }
                        { imgNode }
                    </span>
                );
            }
        }
        if (onClick != null) {
            const imgClassNames = classNames({
                "mx_BaseAvatar": true,
                "mx_BaseAvatar_image": true,
                "mx_BaseAvatar_external": isExternal,
            });
            return (
                <AccessibleButton className={imgClassNames}
                    element='img'
                    src={imageUrl}
                    onClick={onClick}
                    width={width-2} height={height-2}
                    title={title} alt=""
                    {...otherProps} />
            );
        } else {
            const imgClassNames = classNames({
                "mx_BaseAvatar": true,
                "mx_BaseAvatar_image": true,
                "mx_BaseAvatar_external": isExternal,
            });
            return (
                <img className={imgClassNames} src={imageUrl}
                    width={width-2} height={height-2}
                    title={title} alt=""
                    {...otherProps} />
            );
        }
    }
}
