import dis from '../dispatcher';
import { Store } from 'flux/utils';

class MxcBlobStore extends Store {
    constructor() {
        super(dis);

        // Initialise state
        this._blobStore = {};
        this._avatarBlobStore = {};
        this._avatarSizeStore = {};
    }

    // Update store
    _updateBlobStore(mxc, blob, emitChange) {
        this._blobStore[mxc] = blob;
        emitChange && this.__emitChange();
    }
    _updateAvatarBlobStore(mxc, blob, size) {
        // if mxc doesn't have any associated blob, add new entry
        // if mxc already have an associated blob, keep the biggest blob
        if (!this._avatarBlobStore[mxc] || (this._avatarBlobStore[mxc] && size > this._avatarSizeStore[mxc])) {
            this._avatarBlobStore[mxc] = blob;
            this._avatarSizeStore[mxc] = size;
        }
    }

    // Return whole store
    getBlobStore() { return this._blobStore; }
    getAvatarBlobStore() { return this._avatarBlobStore; }
    getAvatarSizeStore() { return this._avatarSizeStore; }

    // Return specific blob
    getBlobFromMxc(mxc) { return this._blobStore[mxc]; }
    getBlobFromAvatarMxc(mxc) { return this._avatarBlobStore[mxc]; }

    __onDispatch(payload) {
        switch (payload.action) {
            case 'update_blob_store':
                this._updateBlobStore(payload.mxc, payload.blob, payload.emitChange);
                break;
            case 'update_avatar_blob_store':
                this._updateAvatarBlobStore(payload.mxc, payload.blob, payload.size);
                break;
        }
    }
}

module.exports = new MxcBlobStore();
