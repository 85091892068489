/*
Copyright 2016 OpenMarket Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React from 'react';
import PropTypes from 'prop-types';

import sdk from '../../../index';
import Analytics from '../../../Analytics';
import MatrixClientPeg from '../../../MatrixClientPeg';
import * as Lifecycle from '../../../Lifecycle';
import { _t } from '../../../languageHandler';
import CitadelInput from '../elements/CitadelInput';

export default class DeactivateAccountDialog extends React.Component {
    constructor(props, context) {
        super(props, context);

        this._onOk = this._onOk.bind(this);
        this._onCancel = this._onCancel.bind(this);
        this._onPasswordFieldChange = this._onPasswordFieldChange.bind(this);
        this._onEraseFieldChange = this._onEraseFieldChange.bind(this);

        this.state = {
            password: '',
            confirmButtonEnabled: false,
            busy: false,
            shouldErase: false,
            errStr: null,
        };
    }

    _onPasswordFieldChange(ev) {
        this.setState({
            password: ev.target.value,
            confirmButtonEnabled: Boolean(ev.target.value),
            errStr: null,
        });
    }

    _onEraseFieldChange(ev) {
        this.setState({
            shouldErase: ev.target.checked,
        });
    }

    async _onOk() {
        this.setState({busy: true});

        try {
            // This assumes that the HS requires password UI auth
            // for this endpoint. In reality it could be any UI auth.
            const auth = {
                type: 'm.login.password',
                user: MatrixClientPeg.get().credentials.userId,
                password: this.state.password,
            };
            await MatrixClientPeg.get().deactivateAccount(auth, this.state.shouldErase);
        } catch (err) {
            let errStr = _t('Unknown error');
            // https://matrix.org/jira/browse/SYN-744
            if (err.httpStatus == 401 || err.httpStatus == 403) {
                errStr = _t('Incorrect password');
            }
            this.setState({
                busy: false,
                errStr: errStr,
            });
            return;
        }

        Analytics.trackEvent('Account', 'Deactivate Account');
        this.props.onFinished(true);
        Lifecycle.onLoggedOut();
    }

    _onCancel() {
        this.props.onFinished(false);
    }

    render() {
        const BaseDialog = sdk.getComponent('views.dialogs.BaseDialog');
        const Loader = sdk.getComponent("elements.Spinner");
        const { password } = this.state;

        const okLabel = _t('Deactivate Account');
        const okEnabled = this.state.confirmButtonEnabled && !this.state.busy;

        let cancelButton = null;
        if (!this.state.busy) {
            cancelButton = <button onClick={this._onCancel} autoFocus={true}>
                { _t("Cancel") }
            </button>;
        }

        const inputCaption = {
            type: this.state.errStr ? 'error' : '',
            text: this.state.errStr,
        };

        return (
            <BaseDialog className="mx_DeactivateAccountDialog"
                onFinished={this.props.onFinished}
                onEnterPressed={this.onOk}
                titleClass="danger"
                title={_t("Deactivate Account")}>

               <div className="mx_Dialog_content">
                    <p>
                        { _t("This will make your account permanently unusable and you will no longer be able to reconnect.")}
                    </p>
                    <p>
                        { _t("Your account will leave all the rooms in which it participates in and the information associated with your profile will be removed.") }
                    </p>
                    <p>
                        { _t("Please note that account deactivation does not remove shared messages until the rooms are not deleted by their administrators or all attendees have left.")}
                    </p>
                    <p>
                        {_t("<b>This action is irreversible.</b>", {}, {b: (sub) => <b> {sub}</b>})}
                    </p>
                    <div className="mx_DeactivateAccountDialog_input_section">
                        <p>{ _t("To continue, please enter your password:") }</p>
                        <CitadelInput
                            caption={inputCaption}
                            onChange={this._onPasswordFieldChange}
                            placeholder={_t("password")}
                            type="password"
                            value={password}
                        />
                    </div>
                </div>
                { this.state.busy && <Loader /> }
                {
                    !this.state.busy && <div className="mx_Dialog_buttons">
                        <button
                            className="mx_Dialog_primary danger"
                            onClick={this._onOk}
                            disabled={!okEnabled}
                        >
                            {okLabel}
                        </button>

                        {cancelButton}
                    </div>
                }
            </BaseDialog>
        );
    }
}

DeactivateAccountDialog.propTypes = {
    onFinished: PropTypes.func.isRequired,
};
