import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { _t } from '../../../languageHandler';
import CitadelButton from '../../views/elements/CitadelButton';
import CitadelInput from '../../views/elements/CitadelInput';
import MfaManager from '../dialogs/mfa/MfaManager';
import Login from '../../../Login';
import dis from '../../../dispatcher';
import { KeyCode } from '../../../Keyboard';

export default class CitadelLoginPassword extends React.Component {
    static displayName = "CitadelLoginPassword";

    componentWillUnmount() {
        this.unmounted = true;
    }

    state = { password: '', error: null, busy: false };

    onPasswordChanged = (ev) => this.setState({ password: ev.target.value, error: null });

    onForgotPasswordClick = () => {
        dis.dispatch({ action: 'start_password_recovery', params: { email: this.state.email } });
    };

    handleError = (err) => {
        if (!navigator.onLine) {
            this.setState({
                error: {
                    type: 'system',
                    text: _t("No network, check your connectivity."),
                },
            });
            return;
        }

        switch (err.httpStatus) {
            case 500:
                this.setState({
                    error: {
                        type: 'system',
                        text: _t('Server error, please try to connect later.'),
                    },
                });
                break;
            case 403:
                this.setState({
                    error: {
                        type: 'password',
                        text: _t('Incorrect password'),
                    },
                });
                break;
            default:
                this.setState({
                    error: {
                        type: 'system',
                        text: _t('An error has occurred.'),
                    },
                });
                break;
        }
   };

    getCaptions = () => {
        const { error } = this.state;

        return { type: 'error', text: error.text };
    };

    submitPassword = async () => {
        const { defaultDeviceDisplayName, medium, onLoggedIn, onLogout, portalUrl, servicesUrl } = this.props;
        const { busy, error, password } = this.state;

        if (!password || busy || error) return;

        this.setState({ busy: true });

        const { email: username, homeServerUrl, clientSecret, sid } = MfaManager.getTemporaryValues();
        const mfa = { client_secret: clientSecret, id_server: homeServerUrl.split('//')[1], medium, sid, bind: true };
        const loginLogic = new Login(homeServerUrl, defaultDeviceDisplayName);
        const clearStorage = !onLogout;

        loginLogic.loginViaPassword(username, { password, mfa }, null)
            .then((data) => {
                data.portalUrl = portalUrl;
                data.servicesUrl = servicesUrl;
                onLoggedIn(data, 'login', clearStorage);
                MfaManager.resetTemporaryValues();
            }, (err) => {
                this.handleError(err);
            })
            .finally(() => {
                !this.unmounted && this.setState({
                    busy: false,
                });
                localStorage.setItem("mx_user_email", username);
            });
    };

    onKeyDown = (e) => {
        if (e.keyCode === KeyCode.ENTER) {
            e.stopPropagation();
            e.preventDefault();
            this.submitPassword();
        }
    };

    renderLogoutButton = () => {
        const { onLogout } = this.props;

        return onLogout ? (
            <a className="log_out"
               onClick={onLogout}
               href="#">
                {_t('Log out')}
            </a>
        ) : null;
    }

    renderHeader = () => {
        const { onLogout } = this.props;
        return (
            <div>
                <div className="login_password_title">
                    {onLogout ? _t('Login again'): _t('Log In')}
                </div>
                <div className="login_password_subtitle">
                    {onLogout
                        ? _t('You have changed your password, please type it to log in again.')
                        : _t('Enter your password to access your account.')
                    }
                </div>
            </div>
        );
    }

    render() {
        const { onLogout } = this.props;
        const { busy, error = true, password } = this.state;
        let caption;

        if (error) {
            caption = this.getCaptions();
        }

        return (
            <div className="login_password_screen">
                {this.renderHeader()}
                <form onSubmit={this.submitPassword} >
                    <CitadelInput
                        autoComplete='password'
                        autoFocus
                        caption={caption}
                        className={cx('login_password_input', { 'login_again': !!onLogout })}
                        label={_t('Password')}
                        name="password"
                        onChange={this.onPasswordChanged}
                        onKeyDown={this.onKeyDown}
                        type="password"
                        value={password}
                    />
                    <span className={cx('login_password_forgot_wrapper', { 'login_again': !!onLogout })}>
                        {_t('<a>Forgot your password?</a>', {}, {
                            a: sub => <a className="login_password_forgot"
                                         onClick={this.onForgotPasswordClick}
                                         href="#"
                            >
                                {sub}
                            </a>,
                        })}
                    </span>
                    <CitadelButton
                        isDisabled={Boolean(!password || busy || error)}
                        isLoading={busy}
                        minWidth={200}
                        onClick={this.submitPassword}
                        text={onLogout ? _t('Login again to my session') : _t('Continue')}
                    />
                </form>
                {this.renderLogoutButton()}
            </div>
        );
    }
}

CitadelLoginPassword.propTypes = {
    defaultDeviceDisplayName: PropTypes.string.isRequired,
    onLoggedIn: PropTypes.func.isRequired,
    onLogout: PropTypes.func,  // we have this function only if we are in soft logout state
    portalUrl: PropTypes.string.isRequired,
    servicesUrl: PropTypes.string.isRequired,
    medium: PropTypes.string.isRequired,
};
