import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { _t } from '../../../languageHandler';
import MatrixClientPeg from '../../../MatrixClientPeg';
import { fetchUrlWithToken } from '../../../utils/MediaUtils';
import MxcBlobStore from '../../../stores/MxcBlobStore';
import dis from '../../../dispatcher';
import RoomListActions from '../../../actions/RoomListActions';
import { isDirectMessageRoom, getRoomName, canSendEvent } from '../../../Rooms';

import uploadIcon from '../../../../res/img/upload.svg';
import favIconOn from '../../../../res/img/fav-on.svg';
import favIconOff from '../../../../res/img/fav-off.svg';

class CitadelRoomAvatar extends React.Component {
    constructor(props) {
        super(props);

        const cli = MatrixClientPeg.get();
        const roomCurrentState = props.room.currentState;

        const roomName = getRoomName(props.room);

        let avatarMxc = '';
        let avatarUrl = '';
        let avatarBlob = null;

        const { isDmRoom, dmUserIdOrEmail } = isDirectMessageRoom(props.room);
        if (!isDmRoom) {
            const avatarEvent = roomCurrentState.getStateEvents('m.room.avatar', '');
            avatarMxc = (avatarEvent && avatarEvent.getContent() && avatarEvent.getContent()['url']) || '';
        } else {
            const otherMember = props.room.getMember(dmUserIdOrEmail);
            avatarMxc = otherMember && otherMember.getMxcAvatarUrl();
        }

        if (avatarMxc) {
            // We read the blob store in an attempt to immediately display the full resolution avatar.
            avatarUrl = cli.mxcUrlToHttp(avatarMxc);
            avatarBlob = (avatarMxc && MxcBlobStore.getBlobFromMxc(avatarMxc)) || null;
        }

        this.state = {
            isLowPriority: props.room.tags.hasOwnProperty('m.lowpriority'),
            isFavourite: props.room.tags.hasOwnProperty('m.favourite'),
            roomName,
            roomAvatar: {
                mxc: avatarMxc,
                url: avatarUrl,
                blob: avatarBlob,
            },
            canSetAvatar: !isDmRoom && canSendEvent(props.room, 'm.room.avatar'),
        };
    }

    componentWillUnmount() {
        const cli = MatrixClientPeg.get();
        if (cli) {
            cli.removeListener('Room.name', this._onRoomName);
            cli.removeListener('RoomState', this._onRoomStateEvent);
            cli.removeListener('Room.tags', this._onRoomTags);
        }
    }

    async componentDidMount() {
        const cli = MatrixClientPeg.get();
        cli.on('Room.name', this._onRoomName);
        cli.on('RoomState.events', this._onRoomStateEvent);
        cli.on('Room.tags', this._onRoomTags);
        const me = this.props.room.getMember(localStorage.getItem('mx_user_id'));
        const { isDmRoom } = isDirectMessageRoom(this.props.room);
        const hasRequiredPowerAvatar = me.powerLevel === 100;
        this.setState({canSetAvatar: !isDmRoom && hasRequiredPowerAvatar});
        const { mxc, url, blob } = this.state.roomAvatar;
        if (!blob) {
            fetchUrlWithToken(url)
                .then(blob => {
                    if (blob) {
                        this.updateRoomAvatar(mxc, url, blob);
                    }
                })
                .catch(() => {});
        }
    }

    updateRoomAvatar = (mxc, url, blob) => {
        dis.dispatch({
            action: 'update_blob_store',
            mxc,
            blob,
        });

        this.setState({
            roomAvatar: {
                url,
                mxc,
                blob,
            },
        });
    };

    _onRoomName = () => {
        this.setState({
            roomName: getRoomName(this.props.room),
        });
    };

    _onRoomStateEvent = async (ev) => {
        if (ev.getType() === 'm.room.avatar' && ev.getRoomId() === this.props.room.roomId) {
            const mxc = ev.getContent() && ev.getContent().url;

            if (mxc) {
                const cli = MatrixClientPeg.get();
                const url = cli.mxcUrlToHttp(mxc);
                const blob = await fetchUrlWithToken(url);

                this.updateRoomAvatar(url, mxc, blob);
            }
        }
    };

    _onRoomTags = () => {
        const isFavourite = this.props.room.tags.hasOwnProperty('m.favourite');

        this.setState({
            isFavourite,
        });
    };

    // Duplicated logic from RoomTileContextMenu
    onFavouriteClick = () => {
        const { isFavourite, isLowPriority } = this.state;

        if (!isFavourite && isLowPriority) {
            this.setState({
                isFavourite: true,
                isLowPriority: false,
            });
            this._toggleTag("m.favourite", "m.lowpriority");
        } else if (isFavourite) {
            this.setState({
                isFavourite: false,
            });
            this._toggleTag(null, "m.favourite");
        } else if (!isFavourite) {
            this.setState({
                isFavourite: true,
            });
            this._toggleTag("m.favourite");
        }
    };

    _toggleTag = (tagNameOn, tagNameOff) => {
        dis.dispatch(RoomListActions.tagRoom(
            MatrixClientPeg.get(),
            this.props.room,
            tagNameOff, tagNameOn,
            undefined, 0,
        ), true);
    };

    onUploadClick = () => {
        const { canSetAvatar } = this.state;
        canSetAvatar && this.refs.avatarUpload.click();
    };

    _onAvatarChanged = (e) => {
        const { room } = this.props;
        const files = e.target.files;

        if (files && files.length) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = async () => {
                const cli = MatrixClientPeg.get();
                const mxc = await cli.uploadContent(file, {
                    endpoint: `/upload/room/${room.roomId}`,
                    prefix: '/_matrix/media/r0',
                    includeFilename: true,
                    name: file.name,
                    sendUsingFormData: true,
                });

                await cli.sendStateEvent(room.roomId, 'm.room.avatar', { url: mxc }, '');
                const url = cli.mxcUrlToHttp(mxc);
                const blob = await fetchUrlWithToken(url);

                this.updateRoomAvatar(mxc, url, blob);
            };
            reader.readAsDataURL(file);
        }
    };

    renderRoomAvatar = () => {
        const { roomName, roomAvatar, canSetAvatar } = this.state;
        const { url, blob } = roomAvatar;

        const avatarClassNames = classNames({
           'avatar': true,
           'default-avatar': !url,
        });

        const initialClassNames = classNames({
            'initial': true,
            'can-set-avatar-initial': canSetAvatar,
        });

        return (
            <div className={avatarClassNames} onClick={this.onUploadClick}>
                {url ? <img src={blob || url} alt="" /> : <div className={initialClassNames}>{ roomName[0] }</div>}
            </div>
        );
    };

    render() {
        const { isFavourite, roomAvatar, canSetAvatar } = this.state;
        const { url } = roomAvatar;

        const wraperClassNames = classNames({
            'CitadelRoomAvatar': true,
            'CitadelRoomAvatar-clickable': canSetAvatar,
            'CitadelRoomAvatar-default': !url,
        });

        const uploadClassNames = classNames({
            'upload': true,
            'default-upload': !url,
        });

        return (
            <div className={wraperClassNames}>
                <div className="favourite" onClick={this.onFavouriteClick}>
                    <img src={isFavourite ? favIconOn : favIconOff} alt="" />
                </div>
                {this.renderRoomAvatar()}
                {canSetAvatar &&
                    <div className={uploadClassNames} onClick={this.onUploadClick}>
                        <input type="file" ref="avatarUpload" className="upload-input"
                               onChange={this._onAvatarChanged} accept="image/*" />
                        <img src={uploadIcon} alt="" />
                        <div className="text">{_t('Choose a room photo')}</div>
                    </div>
                }
            </div>
        );
    }
}

CitadelRoomAvatar.propTypes = {
    room: PropTypes.object,
};

export default CitadelRoomAvatar;
