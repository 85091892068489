import React from 'react';
import BaseDialog from '../dialogs/BaseDialog';
import { _t } from '../../../languageHandler';
import PropTypes from 'prop-types';

class MuteAllDialog extends React.Component {
    onMuteClick = () => {
        this.props.onMute();
        this.props.onFinished();
    }

    render() {
        return <BaseDialog
            title={_t("Mute all participants")}
            onFinished={this.props.onFinished}
        >
            <div>
            <div className="mx_Dialog_content" id='mx_Dialog_content'>
                { _t("You are going to mute everyone and every new participant. They will be able to unmute themselves. Are you sure ?") }
            </div>
            <div className="mx_Dialog_buttons">
                <button onClick={this.props.onFinished} className="mx_Dialog_secondary">
                    {_t("Cancel")}
                </button>
                <button onClick={this.onMuteClick} className="mx_Dialog_primary">
                    {_t("Mute everyone")}
                </button>
            </div>
        </div>
        </BaseDialog>;
    }
}

MuteAllDialog.propTypes = {
    onMute: PropTypes.func,
    onFinished: PropTypes.func,
};

export default MuteAllDialog;
