/*
Copyright 2019 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { _t } from '../../../../../languageHandler';
import MatrixClientPeg from '../../../../../MatrixClientPeg';
import AccessibleButton from '../../../elements/AccessibleButton';
import SdkConfig from '../../../../../SdkConfig';
import createRoom from '../../../../../createRoom';
import Modal from '../../../../../Modal';
import UpdateHandler from '../../../elements/UpdateHandler';
import dis from '../../../../../dispatcher';

const sdk = require('../../../../..');
const PlatformPeg = require('../../../../../PlatformPeg');

const FAQ_URL = 'https://support.citadel.team';
const VIDEOS_URL = 'https://www.youtube.com/channel/UClOPUVXARmF6ls5rG42dxcw/featured';

export default class HelpUserSettingsTab extends React.Component {
    static propTypes = {
        handleCloseSettings: PropTypes.func.isRequired,
    };

    constructor() {
        super();

        this.state = {
            vectorVersion: null,
            isUpdateEnabled: SdkConfig.get().extraUserAgent !== 'CitadelTeam-MDM',
        };
    }

    componentDidMount(): void {
        PlatformPeg.get().getAppVersion().then(
            (ver) => {
                const version = ver || '';
                this.setState({ vectorVersion: version.split('-prod')[0] });
            }).catch((e) => {
            console.error('Error getting vector version: ', e);
        });
        dis.dispatch({
            action: 'view_help_settings',
        });
    }

    componentWillUnmount() {
        dis.dispatch({
            action: 'close_help_settings',
        });
    }

    _onClearCacheAndReload = (e) => {
        if (!PlatformPeg.get()) return;

        MatrixClientPeg.get().stopClient();
        MatrixClientPeg.get().store.deleteAllData().done(() => {
            PlatformPeg.get().reload();
        });
    };

    _onBugReport = (e) => {
        const BugReportDialog = sdk.getComponent('dialogs.BugReportDialog');
        if (!BugReportDialog) {
            return;
        }
        Modal.createTrackedDialog('Bug Report Dialog', '', BugReportDialog, {});
    };

    _onStartBotChat = (e) => {
        this.props.handleCloseSettings();
        createRoom({
            dmUserId: SdkConfig.get().welcomeUserId,
            andView: true,
        });
    };

    _showSpoiler = (event) => {
        const target = event.target;
        target.innerHTML = target.getAttribute('data-spoiler');

        const range = document.createRange();
        range.selectNodeContents(target);

        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
    };

    _renderLegal() {
        const tocLinks = SdkConfig.get().terms_and_conditions_links;
        if (!tocLinks) return null;

        const legalLinks = [];
        for (const tocEntry of SdkConfig.get().terms_and_conditions_links) {
            legalLinks.push(<div key={tocEntry.url}>
                <a href={tocEntry.url} rel="noopener" target="_blank">{tocEntry.text}</a>
            </div>);
        }

        return (
            <div className="mx_SettingsTab_section mx_HelpUserSettingsTab_versions">
                <span className="mx_SettingsTab_subheading">{_t('Legal')}</span>
                <div className="mx_SettingsTab_subsectionText">
                    {legalLinks}
                </div>
            </div>
        );
    }

    render() {
        const { isUpdateEnabled } = this.state;
        const vectorVersion = this.state.vectorVersion || 'unknown';

        let olmVersion = MatrixClientPeg.get().olmVersion;
        olmVersion = olmVersion ? `${olmVersion[0]}.${olmVersion[1]}.${olmVersion[2]}` : '<not-enabled>';

        return (
            <div className="mx_SettingsTab mx_HelpUserSettingsTab">
                <div className="mx_SettingsTab_heading">{_t('Help & About')}</div>
                <div className="mx_SettingsTab_section mx_HelpUserSettingsTab_support">
                    <span className="mx_SettingsTab_subheading">{_t('FAQ and video tutorials')}</span>
                    <div className="mx_SettingsTab_subsectionText">
                        <div>{_t('Need some help? Thanks to our guide, you will find the answers to all your questions.')}</div>
                        <div>{_t('Don\'t hesitate to consult our tutorial videos!')}</div>
                        <div className="mx_SettingsTab_subsectionText_support_buttons">
                            <a href={FAQ_URL} rel="noopener" target="_blank">
                            <AccessibleButton onClick={noop} kind="primary">
                                {_t('Access support')}
                            </AccessibleButton>
                            </a>
                            <a href={VIDEOS_URL} rel="noopener" target="_blank">
                            <AccessibleButton onClick={noop} kind="primary">
                                {_t('Watch the videos')}
                            </AccessibleButton>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="mx_SettingsTab_section mx_HelpUserSettingsTab_versions">
                    <span className="mx_SettingsTab_subheading">{_t('Versions')}</span>
                    <div className="mx_SettingsTab_subsectionText">
                        {_t('citadel-web version:')} {vectorVersion}<br />
                        {_t('olm version:')} {olmVersion}<br />
                    </div>
                    {isUpdateEnabled && <UpdateHandler />}
                </div>
                {this._renderLegal()}
                <div className="mx_SettingsTab_section mx_HelpUserSettingsTab_versions">
                    <span className="mx_SettingsTab_subheading">{_t('Advanced')}</span>
                    <div className="mx_SettingsTab_subsectionText">
                        {_t('Homeserver is')} <code>{MatrixClientPeg.get().getHomeserverUrl()}</code><br />
                        {_t('Identity Server is')} <code>{MatrixClientPeg.get().getIdentityServerUrl()}</code><br />
                    </div>
                    <div className="mx_HelpUserSettingsTab_debugButton">
                        <AccessibleButton onClick={this._onClearCacheAndReload} kind="danger">
                            {_t('Clear Cache and Reload')}
                        </AccessibleButton>
                    </div>
                </div>
            </div>
        );
    }
}
