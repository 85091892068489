import dis from '../dispatcher';
import { Store } from 'flux/utils';
import { isEmpty } from "lodash";

import MatrixClientPeg from '../MatrixClientPeg';
import { _t } from "../languageHandler";
import Modal from '../Modal';
import {ExportFormat, ExportType} from "../utils/exportUtils/exportUtils";
import HTMLExporter from "../utils/exportUtils/HtmlExport";
import PlainTextExporter from "../utils/exportUtils/PlainTextExport";

import RoomExportCancelWarningDialog from "../components/views/dialogs/roomExport/RoomExportCancelWarningDialog";
import RoomExportInfoDialog from "../components/views/dialogs/roomExport/RoomExportInfoDialog";

const SIMULTANEOUS_DOWNLOADS_LIMIT = 1;

class RoomExportStore extends Store {
    constructor() {
        super(dis);

        // Initialise state
        this.exportsInProgress = {}; // data of room exports in progress
        this.waitingList = []; // data of exports
        this.exporters = {}; // current running exporters
        this.completedExports = {}; // completed exports
        this.displayConsoleLogs = true;
    }

    logger = ({
        log: (text) => console.log(`Export_Room: ${text}`),
        info: (text) => console.info(`Export_Room: ${text}`),
        warn: (text) => console.warn(`Export_Room: ${text}`),
        error: (text) => console.error(`Export_Room: ${text}`),
    });

    addRoomExport(payload) {
        const { exportData } = payload;

        if (!exportData) return;

        const { exportFormat, exportOptions, exportType, roomId } = exportData;

        if (Object.keys(this.exportsInProgress).length < SIMULTANEOUS_DOWNLOADS_LIMIT) {
            this.exportsInProgress[roomId] = payload.exportData;
            const room = MatrixClientPeg.get().getRoom(roomId);
            let exporter;
            switch (exportFormat) {
                case ExportFormat.Html:
                    exporter = new HTMLExporter(
                        room,
                        ExportType[exportType],
                        exportOptions,
                        this.setExportProgressText(roomId),
                    );
                    break;
                case ExportFormat.PlainText:

                    exporter = new PlainTextExporter(
                        room,
                        ExportType[exportType],
                        exportOptions,
                        this.setExportProgressText(roomId),
                    );
                    break;
                default:
                    this.logger.error("Unknown export format");
                    return;
            }
            if (exporter) {
                this.exporters[roomId] = exporter;
                exporter.export().then(() => {
                    if (exporter.cancelled) {
                        dis.dispatch({action: 'room_export_cancel_success', roomId});
                    } else {
                        this.completedExports[roomId] = this.exportsInProgress[roomId];
                        dis.dispatch({action: 'room_export_success', roomId});
                        this.playAudioNotification();
                    }
                    delete this.exporters[roomId];
                    delete this.exportsInProgress[roomId];
                    // start next export if there is one in the waiting list
                    if (!isEmpty(this.waitingList)) {
                        const newExportData = this.waitingList.shift();
                        this.addRoomExport({ exportData: newExportData });
                    }
                });
            }
        } else {
            this.waitingList.push(payload.exportData);
        }
    }

    playAudioNotification = (ev, room) => {
    const audioEl = document.getElementById('messageAudio');
    if (audioEl) {
            audioEl.play();
        }
    }

    cancelExport = (roomId) => async () => {
        if (this.exporters[roomId] || this.exportsInProgress[roomId]) {
            await this.exporters[roomId]?.cancelExport();
        }

        const waitingListIndex = this.waitingList.findIndex((exportData) => exportData.roomId === roomId);
        if (waitingListIndex >= 0) {
            this.waitingList.splice(waitingListIndex, 1);
            this.cleanUp(roomId)();
        }

        Modal.createDialog(
            RoomExportInfoDialog,
            {
                description: _t("export_chat|cancelled_detail"),
                title: _t("export_chat|cancelled"),
            },
            '',
            true,
        );
    }

    cleanUp = (roomId) => () => {
        // just make sure that we don't have anything left related to this room id
        delete this.exporters[roomId];
        delete this.exportsInProgress[roomId];
        const waitingListIndex = this.waitingList.findIndex((exportData) => exportData.roomId === roomId);
        if (waitingListIndex >= 0) {
            this.waitingList.splice(waitingListIndex, 1);
        }
        dis.dispatch({ action: 'room_export_cancel_success', roomId });
    }

    showCancelExportWarning = (roomId) => {
        if (!roomId) return;

        if (this.completedExports[roomId]) {
            delete this.completedExports[roomId];
            // in order to update the room list and the RoomView room_export_success event will be sent again
            dis.dispatch({ action: 'room_export_success', roomId });
            return;
        }

        const waitingListIndex = this.waitingList.findIndex((exportData) => exportData.roomId === roomId);

        if (
            (!this.exporters[roomId] && waitingListIndex < 0)
            || (this.exporters[roomId] && this.exporters[roomId].cancelled)
        ) {
            console.log('export was already canceled');
            return;
        }

        Modal.createDialog(
            RoomExportCancelWarningDialog,
            { onContinue: this.cancelExport(roomId) },
            '',
            true,
        );
    }

    setExportProgressText = (roomId) => (exportProgressText) => {
        this.exportsInProgress[roomId] = {...this.exportsInProgress[roomId], exportProgressText};
        dis.dispatch({
            action: 'update_room_export_progress_text',
            exportProgressText,
            roomId,
        });
    }

    getRoomExportStatus = (roomId) => {
        if (this.exporters[roomId] !== undefined) return 'started';
        if (this.completedExports[roomId] !== undefined) return 'completed';
        const isInWaitingList = this.waitingList.findIndex((roomExpData) => roomExpData.roomId === roomId) >= 0;

        return isInWaitingList ? 'pending' : null;
    }

    getRoomExportProgressText = (roomId) => {
        if (this.exporters[roomId] !== undefined) return this.exportsInProgress[roomId].exportProgressText;
        if (this.completedExports[roomId] !== undefined) return this.completedExports[roomId].exportProgressText;

        const isInWaitingList = this.waitingList.findIndex((roomExpData) => roomExpData.roomId === roomId) >= 0;
        if (isInWaitingList) return { title: _t('Pending'), description: '' };

        return '';
    }

    cancelAllExports = () => {
        this.exportsInProgress = {};
        this.waitingList = [];
        for (const roomId in this.exporters) {
            if (Object.prototype.hasOwnProperty.call(this.exporters, roomId)) {
                this.exporters[roomId]?.cancelExport();
            }
        }
        this.exporters = {};
    }

    __onDispatch(payload) {
        switch (payload.action) {
            case 'room_export_start':
                this.addRoomExport(payload);
                break;
            case 'room_export_cancel':
                this.showCancelExportWarning(payload.roomId);
                break;
            case 'room_export_cancel_all':
                this.cancelAllExports();
                break;
            default:
                break;
        }
    }
}

let singletonRoomExportStore = null;
if (!singletonRoomExportStore) { singletonRoomExportStore = new RoomExportStore(); }

export default singletonRoomExportStore;
