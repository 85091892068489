import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import BaseDialog from '../dialogs/BaseDialog';
import { _t } from '../../../languageHandler';
import ParticipantCard from './ParticipantCard';
import ConferenceDeviceSettings from './ConferenceDeviceSettings';
import { getStreams } from '../../../utils/Citadex';
import { StreamAPI } from '../../../utils/WebRtc';
import CitadelButton from '../elements/CitadelButton';
import ToggleSwitch from '../elements/ToggleSwitch';
import MatrixClientPeg from '../../../MatrixClientPeg';


class ConferenceDeviceSettingsDialog extends Component {
    constructor(props, context) {
        super(props, context);
        const { isSoundNotificationOn, isVideoStreamSubscriptionDisabled } = this.props;

        this.state = {
        videoSourceId: null,
        currentlySelectedDevices: {},
        isSoundNotificationOn,
        isVideoStreamSubscriptionDisabled,
        };
    }

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
        const { isVideoCall, selectedDevices, videoRightClick } = this.props;
        const videoElement = document.getElementById('video-element-settings');

        videoElement.addEventListener('contextmenu', videoRightClick);
        this.setState({ currentlySelectedDevices: selectedDevices }, () => {
            isVideoCall && this.getVideoStream();
        });
    }

    componentWillUnmount() {
        const { isVideoCall, videoRightClick, stopStream } = this.props;
        const videoElement = document.getElementById('video-element-settings');
        this._isMounted = false;
        if (isVideoCall) {
            stopStream(this.videoStream);
        }
        videoElement.removeEventListener('contextmenu', videoRightClick);
    }

    getVideoStream = async () => {
        const { audioSourceId, videoSourceId } = this.state.currentlySelectedDevices;
        await getStreams({
            callback: (stream) => this.attachVideoStream(stream),
            type: 'video',
            selectedDevices: { audioSourceId, videoSourceId },
        });
    }

    attachVideoStream = (stream) => {
        if (!this._isMounted) {
            const { stopStream } = this.props;
            stopStream(stream);
            return;
        }
        const videoElement = document.getElementById(`video-element-settings`);
        StreamAPI.attachMediaStream(videoElement, stream);
        this.videoStream = stream;
    }

    onDeviceSelect = (selectedDevices) => {
        const { isVideoCall } = this.props;
        const { currentlySelectedDevices: { videoSourceId } } = this.state;
        const shouldVideoStreamUpdate = videoSourceId !== selectedDevices.videoSourceId;
        this.setState({ currentlySelectedDevices: selectedDevices }, () => {
            isVideoCall && shouldVideoStreamUpdate && this.getVideoStream();
        });
    }

    onSave = () => {
        const { currentlySelectedDevices, isSoundNotificationOn, isVideoStreamSubscriptionDisabled } = this.state;
        this.props.onChangeSoundNotifications(isSoundNotificationOn);
        this.props.onChangeVideoStreamSubscription(isVideoStreamSubscriptionDisabled);
        this.onClose(currentlySelectedDevices);
    }

    onClose = (selectedDevices) => {
        const { onFinished, stopStream, isVideoCall } = this.props;

        if (isVideoCall) {
            const videoElement = document.getElementById(`video-element-settings`);
            stopStream(this.videoStream, videoElement);
        }
        onFinished(selectedDevices);
    }

    toggleSoundNotifications = () => {
        const { isSoundNotificationOn } = this.state;
        this.setState({ isSoundNotificationOn: !isSoundNotificationOn });
        MatrixClientPeg.get().trackUserAction({
            formId: 'conferenceSettings',
            version: 1,
            action: isSoundNotificationOn ? "muteNotifications" : "unmuteNotifications",
        });
    }

    toggleVideoStreamSubscription = () => {
        const { isVideoStreamSubscriptionDisabled } = this.state;
        this.setState({ isVideoStreamSubscriptionDisabled: !isVideoStreamSubscriptionDisabled });
    }

    render() {
        const {
            isVideoCall,
            userId,
            hasCameraPermissions,
            getDeviceListsAndIds,
            stopStream,
            selectedDevices,
        } = this.props;
        const {
            currentlySelectedDevices: { videoSourceId },
            isSoundNotificationOn,
            isVideoStreamSubscriptionDisabled,
        } = this.state;

        return (
            <BaseDialog
                className="mx_ConferenceDeviceSettingsDialog"
                title={_t("Conference Settings")}
                onFinished={this.onClose}
            >
                <div>
                <div className="mx_ConferenceDeviceSettingsDialog_container">
                    <div className="mx_ConferenceDeviceSettingsDialog_participant-box">
                        <ParticipantCard
                            avatarSize={'big'}
                            isAudioEnabled={true}
                            isPreview={true}
                            isMe={true}
                            isScreenSharingLoading={false}
                            isVideoEnabled={isVideoCall && !!videoSourceId && hasCameraPermissions}
                            streamId={'settings'}
                            userId={userId}
                        />
                    </div>


                    <ConferenceDeviceSettings
                        getDeviceListsAndIds={getDeviceListsAndIds}
                        hasCameraPermissions={hasCameraPermissions}
                        isVideoCall={isVideoCall}
                        stopStream={stopStream}
                        changeDevices={this.onDeviceSelect}
                        selectedDevices={selectedDevices}
                    />
                    {isVideoCall
                        ? (
                        <div className='conference-subscribe-video-container'>
                            <ToggleSwitch checked={isVideoStreamSubscriptionDisabled} onChange={this.toggleVideoStreamSubscription} />
                            <span className='conference-subscribe-video-text'>{_t("Disable incoming video streams")}</span>
                        </div>
                        )
                        : null
                    }
                    <div className="mx_ConferenceDeviceSettingsDialog_separator" />
                    <span className='mx_ConferenceDeviceSettingsDialog_notifications-title'>{_t("Notifications").toUpperCase()}</span>
                    <div className='conference-sound-notification-container'>
                        <ToggleSwitch checked={isSoundNotificationOn} onChange={this.toggleSoundNotifications} />
                        <span className='mx_ConferenceDeviceSettingsDialog_conference-sound-notification-text'>{_t("Notification sound when someone joins or leaves the conference")}</span>
                    </div>
                    <CitadelButton
                        className="mx_ConferenceDeviceSettings_button"
                        onClick={this.onSave}
                        text={_t('Save')}
                        width={188}
                    />
                </div>

            </div>
        </BaseDialog>
        );
    }
}

ConferenceDeviceSettingsDialog.defaultProps = {
    getDeviceListsAndIds: noop,
    hasCameraPermissions: false,
    isVideoCall: false,
    onFinished: noop,
    stopStream: noop,
    userId: '',
    videoRightClick: noop,
};

ConferenceDeviceSettingsDialog.propTypes = {
    getDeviceListsAndIds: PropTypes.func.isRequired,
    hasCameraPermissions: PropTypes.bool,
    isVideoCall: PropTypes.bool.isRequired,
    onFinished: PropTypes.func.isRequired,
    selectedDevices: PropTypes.object,
    stopStream: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    videoRightClick: PropTypes.func,
};

export default ConferenceDeviceSettingsDialog;
