import React, { useState } from "react";
import moment from "moment";

import { _t } from "../../../../languageHandler";
import BaseDialog from "../BaseDialog";
import DialogButtons from "../../elements/DialogButtons";
import StyledRadioGroup from "../../elements/StyledRadioGroup";
import DatePicker from "../../elements/DatePicker";
import CitadelToggle from "../../elements/CitadelToggle";
import dis from '../../../../dispatcher';
import {
    ExportFormat,
    ExportType,
    normalizeDate,
    textForFormat,
    textForType,
} from "../../../../utils/exportUtils/exportUtils";

const useExportFormState = () => {
    const [exportFormat, setExportFormat] = useState(ExportFormat.Html);
    const [exportType, setExportType] = useState(ExportType.Beginning);
    const [includeAttachments, setAttachments] = useState(false);
    const [sizeLimit, setSizeLimit] = useState(8000); // 8 GB

    return {
        exportFormat,
        exportType,
        includeAttachments,
        sizeLimit,
        setExportFormat,
        setExportType,
        setSizeLimit,
        setAttachments,
    };
};

const ExportDialog = ({ roomId, onFinished }) => {
    const {
        exportFormat,
        exportType,
        includeAttachments,
        sizeLimit,
        setExportFormat,
        setExportType,
        setAttachments,
    } = useExportFormState();

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const startExport = () => {
        dis.dispatch({
            action: 'room_export_start',
            exportData: {
                exportFormat,
                exportOptions: {
                    attachmentsIncluded: includeAttachments,
                    maxSize: sizeLimit * 1024 * 1024,
                    startDate: startDate,
                    endDate: endDate,
                },
                exportType,
                roomId,
            },
        });
    };

    const onExportClick = async () => {
        await startExport();
        onFinished();
    };

    const isDateValid = (type) => (date) => {
        const normalizedDate = normalizeDate(date);
        if (!date) return true;
        const today = normalizeDate(moment());
        if (type === 'start') {
            return normalizedDate.isSameOrBefore(endDate ? endDate : today);
        } else {
            return startDate
                ? (normalizedDate.isSame(startDate)
                    || (normalizedDate.isSameOrAfter(startDate) && normalizedDate.isSameOrBefore(today))
                ) : normalizedDate.isSameOrBefore(today);
        }
    };

    const isExportDisabled = () => exportType === ExportType.BetweenDates && (!startDate || !endDate);

    const exportFormatOptions = Object.values(ExportFormat).map(format => ({
        value: format,
        label: textForFormat(format),
    }));

    const exportTypeOptions = Object.values(ExportType).map(type => ({
        value: type,
        label: textForType(type),
    }));

    return (
        <BaseDialog
            title={_t("export_chat|title")}
            className={"mx_ExportDialog"}
            contentId="mx_Dialog_content"
            hasCancel={true}
            onFinished={onFinished}
        >
            <p>{_t("export_chat|select_option")}</p>

            <div className="mx_ExportDialog_options">
                {!!setExportFormat && (
                    <>
                        <span className="mx_ExportDialog_subheading">
                            {_t("export_chat|format")}
                        </span>
                        <StyledRadioGroup
                            name="exportFormat"
                            value={exportFormat}
                            onChange={key => setExportFormat(ExportFormat[key])}
                            definitions={exportFormatOptions}
                        />
                    </>
                )}
                {setAttachments && (
                    <>
                        <CitadelToggle
                            className="mx_ExportDialog_attachments-checkbox"
                            type='checkbox'
                            id="include-attachments"
                            checked={includeAttachments}
                            onChange={() => setAttachments(!includeAttachments)}
                            label={_t("export_chat|include_attachments")}
                        />
                    </>
                )}

                <>
                    <span className="mx_ExportDialog_subheading">
                            {_t("export_chat|period")}
                    </span>
                    <StyledRadioGroup
                        name="exportType"
                        value={exportType}
                        onChange={key => setExportType(ExportType[key])}
                        definitions={exportTypeOptions}
                    />
                    <div className="export-period">
                        <DatePicker
                            date={startDate}
                            onChangeDate={(date) => setStartDate(normalizeDate(date))}
                            isValidDate={isDateValid('start')}
                            inputProps={{
                                disabled: exportType !== ExportType.BetweenDates,
                                placeholder: _t('export_chat|start_date'),
                                required: exportType === ExportType.BetweenDates,
                            }}
                            withIcon
                            timeFormat={false}
                        />
                        <div className="text-container">{` ${_t('to')} `}</div>
                        <DatePicker
                            date={endDate}
                            onChangeDate={(date) => setEndDate(normalizeDate(date))}
                            isValidDate={isDateValid('end')}
                            inputProps={{
                                disabled: exportType !== ExportType.BetweenDates,
                                placeholder: _t('export_chat|end_date'),
                                required: exportType === ExportType.BetweenDates,
                            }}
                            withIcon
                            timeFormat={false}
                        />
                    </div>
                </>
            </div>
                <DialogButtons
                    primaryButton={_t("export_chat|title")}
                    onPrimaryButtonClick={onExportClick}
                    onCancel={() => onFinished(false)}
                    primaryDisabled={isExportDisabled()}
                />
        </BaseDialog>
    );
};

export default ExportDialog;
